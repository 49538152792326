import { cloneDeep, debounce } from 'lodash';
import moment from 'moment';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { HookStateValue, useHookState } from '../../Common/Hooks/StateHook';
import { AuthContext } from '../../Contexts/AuthContext';
import { ILoading } from '../../Events/Interfaces/Buyer/SharedRFQ.model';
import {
    CreateDocumentErrorFieldEnum,
    DocumentStateEnum,
    IDocumentDetails,
    IDocumentDetailsErrors,
    INewDocument,
    updateCustomDocumentDetailsActionType,
} from '../../SRM/Constants/Document.model';
import {
    InitialCreateDocumentValue,
    InitialDocumentResponseValue,
} from '../../SRM/Constants/InitializerValues';
import { IDocumentPageMode } from '../../SRM/Constants/Interfaces/documentAccess';
import {
    appendDocumentTemplateCustomFieldsInPayload,
    getAttachmentIdsFromCF,
} from '../../SRM/Constants/documentHelpers';
import {
    translateDocumentResponsetoPayload,
    validateDocumentDetails,
} from '../../SRM/Constants/helperfunctions';
import { useDocumentTemplate } from '../../SRM/Hooks/UseDocumentTemplate';
import {
    deleteDocument,
    updateDocumentAPI,
    updateDocumentStateAPI,
    updateDocumentVersionAPI,
    useGetDocumentDetailsQuery,
} from '../../SRM/Services/documents.service';
// import {
//     CustomFieldType,
//     ICustomSection,
// } from '../../Global/Interfaces/TemplateInterface';

export const useDraftDocument = (document_id: string) => {
    const { authData } = useContext(AuthContext);

    const history = useHistory();

    // THIS IS WHAT BE SENDS FROM /document/document_id
    const [documentDetails, setDocumentDetails] = useState<IDocumentDetails>(
        InitialDocumentResponseValue()
    );

    // THIS IS WHAT THE UNCHANGED VERSION OF PAYLOAD TO TRACK CHANGES
    const [originalDocumentPayload, setOriginalDocumentPayload] =
        useState<INewDocument>(InitialCreateDocumentValue());

    // THIS IS WHAT THE FE WILL SEND THE BE
    const [documentPayload, setDocumentPayload] = useState<INewDocument>(
        InitialCreateDocumentValue()
    );

    const [errors, setErrors] = useState<IDocumentDetailsErrors>({});

    const { hookState, updateHookState } = useHookState(HookStateValue.INITIAL);

    const [documentEssentials, setDocumentEssentials] = useState<{
        entityName: string;
        templateName: string;
    }>({
        entityName: '',
        templateName: '',
    });

    const {
        data: DocumentDetailsResponse,
        refetch: refetchDocumentDetailsById,
    } = useGetDocumentDetailsQuery(
        {
            document_id: document_id,
        },
        {
            skip: !Boolean(document_id),
        }
    );

    const [loadingState, setLoadingState] = useState<ILoading>({
        loading: false,
        error: false,
        saved: true,
        error_msg: '',
    });

    useEffect(() => {
        try {
            refetchDocumentDetailsById();
        } catch (e) {}
    }, [refetchDocumentDetailsById, document_id]);

    const { templateDetails, hookState: templateHookStatus } =
        useDocumentTemplate(
            DocumentDetailsResponse?.additional_details.template_id ?? '',
            DocumentDetailsResponse?.buyer_entity_id ?? ''
        );

    // useEffect(() => {
    //     if (
    //         templateDetails &&
    //         templateDetails.sections.DOCUMENT_DETAIL.fieldList
    //     ) {
    //         console.log('templateDetails', templateDetails);
    //     }
    // }, [templateDetails]);

    // INITIALLY ALL VALUES ARE SET UP ONLY WHEN DOCUMENT RESPONSE IS THERE AND TEMPLATE DETAILS ARE OBTAINED
    useEffect(() => {
        updateHookState(HookStateValue.LOADING);
        if (
            DocumentDetailsResponse &&
            templateHookStatus.state === HookStateValue.READY &&
            templateDetails.sections.DOCUMENT_DETAIL.fieldList
        ) {
            updateHookState(HookStateValue.LOADING);
            let newDocumentDetails = cloneDeep(DocumentDetailsResponse);
            const { customFieldNegInDocument, customFieldsNonNegInDocument } =
                appendDocumentTemplateCustomFieldsInPayload(
                    DocumentDetailsResponse,
                    templateDetails
                );

            newDocumentDetails.custom_fields = customFieldsNonNegInDocument;
            newDocumentDetails.custom_fields_negotiate =
                customFieldNegInDocument;

            setDocumentPayload(
                translateDocumentResponsetoPayload(
                    newDocumentDetails,
                    authData.details?.enterprise_id
                )
            );
            setOriginalDocumentPayload(
                translateDocumentResponsetoPayload(
                    newDocumentDetails,
                    authData.details?.enterprise_id
                )
            );

            setDocumentEssentials({
                templateName: templateDetails.templateName,
                entityName: newDocumentDetails.buyer_entity_name,
            });

            // let customSection: ICustomSection = {
            //     name: templateDetails.sections.DOCUMENT_DETAIL.label,
            //     section_type: 'OTHER',
            //     custom_fields:
            //         templateDetails.sections.DOCUMENT_DETAIL.fieldList
            //             .filter(
            //                 (fieldDetails: any) =>
            //                     fieldDetails.fieldType === 'CUSTOM'
            //             )
            //             .map((data) => {
            //                 let fieldDetails =
            //                     templateDetails.fieldSettings.customFields[
            //                         data.field as any
            //                     ];

            //                 return {
            //                     name: fieldDetails.label,
            //                     type:
            //                         fieldDetails.type ===
            //                             CustomFieldType.CHOICE &&
            //                         fieldDetails.constraints.fieldType ===
            //                             CustomFieldType.CHOICE &&
            //                         fieldDetails.constraints.choiceType ===
            //                             'MULTI_SELECT'
            //                             ? CustomFieldType.MULTI_CHOICE
            //                             : fieldDetails.type,
            //                     value:
            //                         (fieldDetails.type ===
            //                             CustomFieldType.CHOICE &&
            //                             fieldDetails.constraints.fieldType ===
            //                                 CustomFieldType.CHOICE &&
            //                             fieldDetails.constraints.choiceType ===
            //                                 'MULTI_SELECT') ||
            //                         fieldDetails.constraints.fieldType ===
            //                             CustomFieldType.ATTACHMENT ||
            //                         fieldDetails.constraints.fieldType ===
            //                             CustomFieldType.TEMPLATE
            //                             ? []
            //                             : null,
            //                     is_locked: fieldDetails.buyerSettings.required,
            //                     is_visible:
            //                         fieldDetails.buyerSettings.showField,
            //                     description: fieldDetails.description,
            //                     is_required:
            //                         fieldDetails.sellerSettings.isMandatory,
            //                     is_negotiable:
            //                         fieldDetails.sellerSettings.negotiable,
            //                 };
            //             }),
            // };

            // console.log('customSection', customSection);

            // newDocumentDetails.custom_sections = [customSection];

            setDocumentDetails(newDocumentDetails);
            updateHookState(HookStateValue.READY);
        }
    }, [
        DocumentDetailsResponse,
        authData.details?.enterprise_id,
        templateDetails,
        templateHookStatus.state,
        updateHookState,
    ]);

    const saveDocumentDetailsDebounced = useMemo(
        () =>
            debounce(
                async (_details: INewDocument) => {
                    let details = cloneDeep(_details);

                    details.attachment_ids = getAttachmentIdsFromCF(details);

                    details.document_validity_datetime = moment(
                        details.document_validity_datetime
                    ).toISOString();

                    setLoadingState((prev) => ({ ...prev, loading: true }));

                    if (details.seller_entity_id === '')
                        details.seller_entity_id = null;

                    try {
                        await updateDocumentAPI(
                            documentDetails.document_id,
                            details
                        );
                        setLoadingState(() => ({
                            saved: true,
                            loading: false,
                            error: false,
                            error_msg: '',
                        }));
                    } catch (err) {
                        setLoadingState((prev) => ({
                            saved: false,
                            loading: false,
                            error: true,
                            error_msg: "Couldn't save data",
                        }));
                    }
                    setLoadingState((prev) => ({ ...prev, loading: false }));

                    // .then((res) => {

                    // })
                    // .catch((err) => {

                    // });
                    try {
                        refetchDocumentDetailsById();
                    } catch (e) {}
                },
                documentDetails.document_id === '' ? 0 : 2500
            ),
        [documentDetails.document_id, refetchDocumentDetailsById]
    );

    const MemoizedSaveDocumentDetails = useCallback(
        async (newDocumentDetails: INewDocument) => {
            setLoadingState((prev) => ({ ...prev, saved: false }));
            await saveDocumentDetailsDebounced(newDocumentDetails);
        },
        [saveDocumentDetailsDebounced]
    );

    const updateDocumentPayload = useCallback(
        async (field: updateCustomDocumentDetailsActionType, value: any) => {
            setDocumentPayload((prev) => {
                let newPrev = cloneDeep(prev);

                let validationField: CreateDocumentErrorFieldEnum | undefined;

                if (field in CreateDocumentErrorFieldEnum) {
                    validationField = field as CreateDocumentErrorFieldEnum;
                }

                newPrev[field] = value;

                if (validationField) {
                    setErrors(
                        validateDocumentDetails({
                            data: newPrev,
                            errors,
                            templateDetails,
                            field: validationField,
                            side: 'BUYER',
                        })
                        // allExistingEventNames: allExistingEventNames,
                    );
                }
                MemoizedSaveDocumentDetails(newPrev);

                return newPrev;
            });
        },
        [MemoizedSaveDocumentDetails, errors, templateDetails]
    );

    const [pageMode, setPageMode] = useState<IDocumentPageMode>('VIEW');

    const updatePageMode = useCallback(
        (mode: IDocumentPageMode) => setPageMode(mode),
        []
    );

    const ENTITY_LEVEL_PERMISSION = useMemo(() => {
        if (
            authData.modulePermissions.BUYER &&
            authData.modulePermissions.BUYER.SUPPLIER_RELATIONSHIP_MANAGEMENT &&
            authData.modulePermissions.BUYER.SUPPLIER_RELATIONSHIP_MANAGEMENT
                .EDIT &&
            authData.modulePermissions.BUYER.SUPPLIER_RELATIONSHIP_MANAGEMENT.EDIT.has(
                documentDetails?.buyer_entity_id
            )
        ) {
            return 'EDIT';
        }

        return 'VIEW';
    }, [authData.modulePermissions.BUYER, documentDetails?.buyer_entity_id]);

    const deleteDocumentById = useCallback(async (documentId: string) => {
        try {
            await deleteDocument(documentId);
            toast.success('Document deleted successfully');
        } catch (err) {
            toast.error('Failed to delete document');
        }
    }, []);

    const submitDocument = useMemo(
        () =>
            debounce(() => {
                let details = cloneDeep(documentPayload);

                details.attachment_ids = getAttachmentIdsFromCF(details);

                setLoadingState((prev) => ({ ...prev, loading: true }));

                if (details.seller_entity_id === '')
                    details.seller_entity_id = null;

                if (documentDetails.state === DocumentStateEnum.DRAFT) {
                    updateDocumentStateAPI(
                        document_id,
                        DocumentStateEnum.SUBMITTED
                    )
                        .then((res) => {
                            setLoadingState(() => ({
                                saved: true,
                                loading: false,
                                error: false,
                                error_msg: '',
                            }));
                            toast.success('Document submitted successfully');
                            // history.push(`/seller/profile/documents/`);
                        })
                        .catch((err) => {
                            setLoadingState((prev) => ({
                                saved: false,
                                loading: false,
                                error: true,
                                error_msg: "Couldn't save data",
                            }));
                            toast.error('Failed to submit document');
                        });
                    history.push(`/buyer/srm/documents/${document_id}`);
                } else {
                    updateDocumentVersionAPI(document_id, details, null)
                        .then((res) => {
                            setLoadingState(() => ({
                                saved: true,
                                loading: false,
                                error: false,
                                error_msg: '',
                            }));
                            toast.success('Document submitted successfully');
                            history.push(
                                `/buyer/srm/documents/${res.document_id}`
                            );
                        })
                        .catch((err) => {
                            setLoadingState((prev) => ({
                                saved: false,
                                loading: false,
                                error: true,
                                error_msg: "Couldn't save data",
                            }));
                            toast.error('Failed to submit document');
                        });
                    updatePageMode('VIEW');
                }
            }, 10),
        [
            documentDetails.state,
            documentPayload,
            document_id,
            history,
            updatePageMode,
        ]
    );

    const validateAllFields = useCallback(() => {
        setErrors((prev) => {
            return validateDocumentDetails({
                data: documentPayload,
                errors: prev,
                field: 'ALL',
                templateDetails,
                side: 'BUYER',
            });
        });
    }, [documentPayload, templateDetails]);

    useEffect(() => {
        validateAllFields();
    }, [validateAllFields]);

    return {
        documentDetails,
        originalDocumentPayload,
        documentPayload,
        documentEssentials,
        updateDocumentPayload,
        pageMode,
        templateDetails,
        errors,
        updatePageMode,
        ENTITY_LEVEL_PERMISSION,
        deleteDocumentById,
        hookState,
        loadingState,
        submitDocument,
    };
};
