import { AxiosResponse } from 'axios';
import { cloneDeep, isEmpty } from 'lodash';
import moment from 'moment';
import { convertAdditionalCostFrontendToBackend } from '../../../AdditionalCost/helpers';
import {
    CostModuleEnum,
    IAdditionalCostInformation,
} from '../../../AdditionalCost/models/AdditionalCost.model';
import {
    transformPaymentTermsFromBackendToFrontend,
    transformPaymentTermsFromFrontendToBackend,
} from '../../../Global/Helpers/paymentTermsHelpers';
import { JsonNumsToStrings } from '../../../Global/Services/JsonProcessingService';
import {
    ICustomAttributeNew,
    ICustomAttributePayloadNew,
} from '../../../Models/Attributes.model';
import { IPurchaseOrderItem } from '../../../Models/AwardsAndPO.model';
import { IMeasurementUnitDetails } from '../../../Models/MeasurementUnit.model';
import { IGetRFQItemDetails, IRFQList } from '../../../Models/RFQ.model';
import { IEventApproval } from '../../../Models/RFQSummary.model';
import {
    IGetSellerRFQBid,
    ISellerRFQDetails,
} from '../../../Models/SellerRFQ.model';
import { baseApiSlice } from '../../../Redux/Slices/BaseApiSlice';
import { VerificationStatus } from '../../../Redux/Slices/VerificationSlice';
import { getLatestPoItem } from '../../../Services/purchaseOrders/purchaseOrderOperations';
import { del, get, post, put } from '../../../Utils/api';
// import { translateFrontEndCustomFieldSectionsToBackend } from '../../Constants/CustomFieldHelpers';
import { modifyCustomSectionForUpdate } from '../../../Global/Helpers/templateHelpers';
import {
    IBuyerEnterpriseItemDetails,
    ICustomEventDetails,
    ICustomEventItemDetails,
    IEventDetails,
    IEventItemDetails,
    IVendorForEventItem,
} from '../../Interfaces/Buyer/RFQ.model';
import { INextApprover } from '../../Interfaces/Buyer/eventAccess.interface';
import { IEventApprovalLogRow } from '../../Interfaces/Buyer/eventApprovalAndUserRoles.interface';
// import { costCenterTemplateStructure } from '../../../ProjectGlCostCenter/Template/costCenterTemplate';

export const fetchEventDetails = (
    rfq_event_id: string,
    enterprise_type: 'BUYER' | 'SELLER',
    type: 'DIRECT' | 'FROM_EVENT'
): Promise<IEventDetails> => {
    return new Promise(async (resolve, reject) => {
        if (type === 'DIRECT') {
            try {
                let rfq_resp = await get<any>(
                    `/events/po_group/${rfq_event_id}/?enterprise_type=${enterprise_type}`
                );

                const defaultPaymentTerms =
                    transformPaymentTermsFromBackendToFrontend({
                        prepayment_percentage:
                            rfq_resp.data.prepayment_percentage,
                        payment_terms: rfq_resp.data.payment_terms,
                        deliverables_payment_terms:
                            rfq_resp.data.deliverables_payment_terms,
                    });

                // if (defaultPaymentTerms)

                let resp = {
                    ...rfq_resp.data,
                    rfq_entry_id: rfq_resp.data.po_group_entry_id,
                    rfq_event_creator: rfq_resp.data.po_group_event_creator,
                    rfq_default_information: {
                        ...rfq_resp.data.default_information,
                        defaultPaymentTerms: defaultPaymentTerms,
                    },
                    event_additional_details: {
                        ...rfq_resp.data.event_additional_details,
                        access: 'ACCESS_RESTRICTED',
                    },
                };
                //TODO: Change to service layer
                JsonNumsToStrings(resp);

                resolve(resp);
            } catch (err) {
                reject();
            }
        } else {
            try {
                let rfq_resp = await get<IEventDetails>(
                    `/events/rfq/${rfq_event_id}/?enterprise_type=${enterprise_type}`
                );
                let resp = rfq_resp.data;

                const defaultPaymentTerms =
                    transformPaymentTermsFromBackendToFrontend({
                        prepayment_percentage:
                            rfq_resp.data.rfq_default_information
                                .default_event_prepayment_percentage,
                        payment_terms:
                            rfq_resp.data.rfq_default_information
                                .default_event_payment_terms,
                        deliverables_payment_terms:
                            rfq_resp.data.rfq_default_information
                                .default_event_deliverables_payment_terms,
                    });
                resp.rfq_default_information.defaultPaymentTerms =
                    defaultPaymentTerms;
                //TODO: Change to service layer
                JsonNumsToStrings(resp);
                if (resp.additional_details) {
                    if (resp.additional_details.parent_rfq_id === 'None') {
                        resp.additional_details.parent_rfq_id = null;
                    }
                    if (resp.additional_details.child_rfq_id === 'None') {
                        resp.additional_details.child_rfq_id = null;
                    }
                }
                resolve({ ...rfq_resp.data });
            } catch (err) {
                reject();
            }
        }
    });
};

export const fetchLinkedEventDetails = (
    rfq_event_id: string
): Promise<IEventDetails[] | null> => {
    return new Promise(async (resolve, reject) => {
        try {
            let rfq_resp = await get<IEventDetails[]>(
                `/events/rfq/${rfq_event_id}/link/?enterprise_type=BUYER`
            );
            let resp = rfq_resp.data;

            if (!resp || resp.length === 0) {
                resolve(null);
            }

            const processedEvents = resp.map((event) => {
                const defaultPaymentTerms =
                    transformPaymentTermsFromBackendToFrontend({
                        prepayment_percentage:
                            event.rfq_default_information
                                .default_event_prepayment_percentage,
                        payment_terms:
                            event.rfq_default_information
                                .default_event_payment_terms,
                        deliverables_payment_terms:
                            event.rfq_default_information
                                .default_event_deliverables_payment_terms,
                    });

                event.rfq_default_information.defaultPaymentTerms =
                    defaultPaymentTerms;
                //TODO: Change to service layer
                JsonNumsToStrings(event);

                if (event.additional_details) {
                    if (event.additional_details.parent_rfq_id === 'None') {
                        event.additional_details.parent_rfq_id = null;
                    }
                    if (event.additional_details.child_rfq_id === 'None') {
                        event.additional_details.child_rfq_id = null;
                    }
                }

                return event;
            });

            resolve(processedEvents);
        } catch (err) {
            reject(err);
        }
    });
};

export const { useFetchEventDetailsQuery } = baseApiSlice.injectEndpoints({
    endpoints: (build) => ({
        fetchEventDetails: build.query<
            IEventDetails,
            {
                rfq_event_id: string;
                enterprise_type: 'BUYER' | 'SELLER';
                type: 'DIRECT' | 'FROM_EVENT';
            }
        >({
            queryFn: async ({ enterprise_type, rfq_event_id, type }) => {
                try {
                    if (type === 'DIRECT') {
                        let rfq_resp = await get<any>(
                            `/events/po_group/${rfq_event_id}/?enterprise_type=${enterprise_type}`
                        );

                        const defaultPaymentTerms =
                            transformPaymentTermsFromBackendToFrontend({
                                prepayment_percentage:
                                    rfq_resp.data.prepayment_percentage,
                                payment_terms: rfq_resp.data.payment_terms,
                                deliverables_payment_terms:
                                    rfq_resp.data.deliverables_payment_terms,
                            });

                        // if (defaultPaymentTerms)

                        let resp = {
                            ...rfq_resp.data,
                            rfq_entry_id: rfq_resp.data.po_group_entry_id,
                            rfq_event_creator:
                                rfq_resp.data.po_group_event_creator,
                            rfq_default_information: {
                                ...rfq_resp.data.default_information,
                                defaultPaymentTerms: defaultPaymentTerms,
                            },
                            event_additional_details: {
                                ...rfq_resp.data.event_additional_details,
                                access: 'ACCESS_RESTRICTED',
                            },
                        };
                        //TODO: Change to service layer
                        JsonNumsToStrings(resp);

                        return { data: resp };
                    } else {
                        let rfq_resp = await get<IEventDetails>(
                            `/events/rfq/${rfq_event_id}/?enterprise_type=${enterprise_type}`
                        );
                        let resp = rfq_resp.data;

                        const defaultPaymentTerms =
                            transformPaymentTermsFromBackendToFrontend({
                                prepayment_percentage:
                                    rfq_resp.data.rfq_default_information
                                        .default_event_prepayment_percentage,
                                payment_terms:
                                    rfq_resp.data.rfq_default_information
                                        .default_event_payment_terms,
                                deliverables_payment_terms:
                                    rfq_resp.data.rfq_default_information
                                        .default_event_deliverables_payment_terms,
                            });
                        resp.rfq_default_information.defaultPaymentTerms =
                            defaultPaymentTerms;
                        //TODO: Change to service layer
                        JsonNumsToStrings(resp);
                        if (resp.additional_details) {
                            if (
                                resp.additional_details.parent_rfq_id === 'None'
                            ) {
                                resp.additional_details.parent_rfq_id = null;
                            }
                            if (
                                resp.additional_details.child_rfq_id === 'None'
                            ) {
                                resp.additional_details.child_rfq_id = null;
                            }
                        }

                        return { data: { ...rfq_resp.data } };
                    }
                } catch (err: any) {
                    return {
                        error: err,
                    };
                }
            },
        }),
    }),
});

export const LinkedEventDetails = baseApiSlice.injectEndpoints({
    endpoints: (build) => ({
        fetchLinkedEventDetails: build.query<
            IEventDetails[],
            {
                rfq_event_id: string;
                enterprise_type: 'BUYER' | 'SELLER';
                type: 'DIRECT' | 'FROM_EVENT';
            }
        >({
            queryFn: async ({ enterprise_type, rfq_event_id, type }) => {
                try {
                    if (type === 'DIRECT') {
                        let rfq_resp = await get<any>(
                            `/events/po_group/${rfq_event_id}/?enterprise_type=${enterprise_type}`
                        );
                        const defaultPaymentTerms =
                            transformPaymentTermsFromBackendToFrontend({
                                prepayment_percentage:
                                    rfq_resp.data.prepayment_percentage,
                                payment_terms: rfq_resp.data.payment_terms,
                                deliverables_payment_terms:
                                    rfq_resp.data.deliverables_payment_terms,
                            });

                        // if (defaultPaymentTerms)

                        let resp = {
                            ...rfq_resp.data,
                            rfq_entry_id: rfq_resp.data.po_group_entry_id,
                            rfq_event_creator:
                                rfq_resp.data.po_group_event_creator,
                            rfq_default_information: {
                                ...rfq_resp.data.default_information,
                                defaultPaymentTerms: defaultPaymentTerms,
                            },
                            event_additional_details: {
                                ...rfq_resp.data.event_additional_details,
                                access: 'ACCESS_RESTRICTED',
                            },
                        };
                        //TODO: Change to service layer
                        JsonNumsToStrings(resp);

                        return { data: resp };
                    } else {
                        let rfq_resp = await get<IEventDetails>(
                            `/events/rfq/${rfq_event_id}/link/?enterprise_type=${enterprise_type}`
                        );
                        let resp = rfq_resp.data;

                        const defaultPaymentTerms =
                            transformPaymentTermsFromBackendToFrontend({
                                prepayment_percentage:
                                    rfq_resp.data.rfq_default_information
                                        .default_event_prepayment_percentage,
                                payment_terms:
                                    rfq_resp.data.rfq_default_information
                                        .default_event_payment_terms,
                                deliverables_payment_terms:
                                    rfq_resp.data.rfq_default_information
                                        .default_event_deliverables_payment_terms,
                            });
                        resp.rfq_default_information.defaultPaymentTerms =
                            defaultPaymentTerms;
                        //TODO: Change to service layer
                        JsonNumsToStrings(resp);
                        if (resp.additional_details) {
                            if (
                                resp.additional_details.parent_rfq_id === 'None'
                            ) {
                                resp.additional_details.parent_rfq_id = null;
                            }
                            if (
                                resp.additional_details.child_rfq_id === 'None'
                            ) {
                                resp.additional_details.child_rfq_id = null;
                            }
                        }

                        return { data: { ...rfq_resp.data } };
                    }
                } catch (err: any) {
                    return {
                        error: err,
                    };
                }
            },
        }),
    }),
});

export const { useFetchLinkedEventDetailsQuery } = LinkedEventDetails;

export const fetchRFQItemsList = (
    rfq_event_id: string,
    enterprise_type: 'BUYER' | 'SELLER',
    type: 'DIRECT' | 'FROM_EVENT'
): Promise<IEventItemDetails[]> => {
    return new Promise(async (resolve, reject) => {
        if (type === 'DIRECT') {
            try {
                let rfq_resp = await get<any[]>(
                    `/events/po_group/${rfq_event_id}/items/?enterprise_type=${enterprise_type}`
                );
                //TODO: Change to service layer
                JsonNumsToStrings(rfq_resp.data);
                for (let item of rfq_resp.data) {
                    if (isEmpty(item.delivery_information)) {
                        item.delivery_information = [];
                    }
                    item.custom_fields_negotiate =
                        item.custom_fields_negotiate || {
                            section_list: [],
                        };
                    item.paymentTerms =
                        transformPaymentTermsFromBackendToFrontend({
                            prepayment_percentage: item.prepayment_percentage,
                            payment_terms: item.payment_terms,
                            deliverables_payment_terms:
                                item.deliverables_payment_terms,
                        });
                }
                resolve(
                    rfq_resp.data.map((item) => ({
                        ...item,
                        rfq_item_entry_id: item.po_group_item_entry_id,
                    }))
                );
            } catch (err) {
                reject();
            }
        } else {
            try {
                let rfq_resp = await get<any[]>(
                    `/events/rfq/${rfq_event_id}/items/?enterprise_type=${enterprise_type}`
                );
                //TODO: Change to service layer
                JsonNumsToStrings(rfq_resp.data);
                for (let item of rfq_resp.data) {
                    item.paymentTerms =
                        transformPaymentTermsFromBackendToFrontend({
                            prepayment_percentage: item.prepayment_percentage,
                            payment_terms: item.payment_terms,
                            deliverables_payment_terms:
                                item.deliverables_payment_terms,
                        });
                }
                resolve(rfq_resp.data);
            } catch (err) {
                reject(err);
            }
        }
    });
};

export const { useFetchRFQItemsListQuery } = baseApiSlice.injectEndpoints({
    endpoints: (build) => ({
        fetchRFQItemsList: build.query<
            IEventItemDetails[],
            {
                rfq_event_id: string;
                enterprise_type: 'BUYER' | 'SELLER';
                type: 'DIRECT' | 'FROM_EVENT';
                enterprise_bom_id?: string;
                sub_bom_item_id?: string;
                requisition_id?: string;
            }
        >({
            queryFn: async ({
                enterprise_type,
                rfq_event_id,
                type,
                enterprise_bom_id,
                sub_bom_item_id,
                requisition_id,
            }) => {
                try {
                    let items: IEventItemDetails[] = [];
                    let res: any = await post('/dashboard/', {
                        dashboard_view: 'rfq_event_item_buyer',
                        tab: 'all',
                        sort_fields: [],
                        search_text: '',
                        items_per_page: 20,
                        page_number: 1,
                        query_data: {
                            rfq_event_id: rfq_event_id,
                            // seller_search: ,
                            enterprise_bom_id,
                            sub_bom_item_id,
                            requisition_id,
                        },
                    });

                    items = res.data.data;
                    let page = 2;
                    while (res.data.metadata.has_next) {
                        res = await post('/dashboard/', {
                            dashboard_view: 'rfq_event_item_buyer',
                            tab: 'all',
                            sort_fields: [],
                            search_text: '',
                            items_per_page: 20,
                            page_number: page,
                            query_data: {
                                rfq_event_id: rfq_event_id,
                                // seller_search: ,
                                enterprise_bom_id,
                                sub_bom_item_id,
                                requisition_id,
                            },
                        });

                        items = items.concat(...res.data.data);
                        page++;
                    }

                    if (type === 'DIRECT') {
                        let rfq_resp = items;
                        //TODO: Change to service layer
                        JsonNumsToStrings(rfq_resp);
                        for (let item of rfq_resp) {
                            if (isEmpty(item.delivery_information)) {
                                item.delivery_information = [];
                            }
                            item.paymentTerms =
                                transformPaymentTermsFromBackendToFrontend({
                                    prepayment_percentage:
                                        item.prepayment_percentage,
                                    payment_terms: item.payment_terms,
                                    deliverables_payment_terms:
                                        item.deliverables_payment_terms,
                                });
                        }

                        return {
                            data: rfq_resp.map((item: any) => ({
                                ...item,
                                delivery_information: item.delivery_schedule,
                                rfq_item_entry_id: item.po_group_item_entry_id,
                            })),
                        };
                    } else {
                        let rfq_resp = items;
                        //TODO: Change to service layer
                        JsonNumsToStrings(rfq_resp);
                        for (let item of rfq_resp) {
                            item.delivery_information = item.delivery_schedule;
                            item.paymentTerms =
                                transformPaymentTermsFromBackendToFrontend({
                                    prepayment_percentage:
                                        item.prepayment_percentage,
                                    payment_terms: item.payment_terms,
                                    deliverables_payment_terms:
                                        item.deliverables_payment_terms,
                                });
                        }

                        return { data: rfq_resp };
                    }
                } catch (err: any) {
                    return {
                        error: err,
                    };
                }
            },
        }),
    }),
});

export const fetchRFQItemDetails = (
    rfq_event_id: string,
    rfq_item_id: string,
    enterprise_type: 'BUYER' | 'SELLER',
    type: 'DIRECT' | 'FROM_EVENT'
): Promise<IEventItemDetails> => {
    return new Promise(async (resolve, reject) => {
        if (type === 'DIRECT') {
            try {
                let rfq_resp = await get<any>(
                    `/events/po_group/${rfq_event_id}/items/${rfq_item_id}/?enterprise_type=${enterprise_type}`
                );
                //TODO: Change to service layer
                JsonNumsToStrings(rfq_resp.data);
                resolve({
                    ...rfq_resp.data,
                    rfq_item_entry_id: rfq_resp.data.po_group_item_entry_id,
                });
            } catch (err) {
                reject();
            }
        } else {
            try {
                let rfq_resp = await get<IEventItemDetails>(
                    `/events/rfq/${rfq_event_id}/items/${rfq_item_id}/?enterprise_type=${enterprise_type}`
                );
                //TODO: Change to service layer
                JsonNumsToStrings(rfq_resp.data);
                resolve({ ...rfq_resp.data });
            } catch (err) {
                reject();
            }
        }
    });
};

export const saveEventDetailsApi = (
    event_details: ICustomEventDetails,
    type: 'DIRECT' | 'FROM_EVENT',
    abortController: AbortController
): Promise<{
    error: boolean;
    sub_event_id: string;
}> => {
    const paymentTerms = event_details.paymentTerms;

    const {
        payment_type: default_event_payment_type,
        prepayment_percentage: default_event_prepayment_percentage,
        payment_terms: default_event_payment_terms,
        deliverables_payment_terms: default_event_deliverables_payment_terms,
    } = transformPaymentTermsFromFrontendToBackend({ paymentTerms });

    if (
        default_event_payment_terms &&
        default_event_payment_terms.applied_from === undefined
    ) {
        default_event_payment_terms.applied_from = 'INVOICE_DATE';
    }

    return new Promise(async (resolve, reject) => {
        try {
            // let payload = {
            //     event_name: event_details.event_name,
            //     custom_fields: event_details.custom_fields,
            //     custom_fields_negotiate: event_details.custom_fields_negotiate,
            //     default_event_item_additional_information:
            //         event_details.default_event_item_additional_information !==
            //         ''
            //             ? event_details.default_event_item_additional_information
            //             : '',
            //     default_event_quantity_tolerance_percentage:
            //         event_details.default_event_quantity_tolerance_percentage,

            //     event_entity_id: event_details.entity_id,
            //     event_end_datetime:
            //         event_details.event_deadline === ''
            //             ? null
            //             : event_details.event_deadline,
            //     buyer_billing_address_id:
            //         event_details.billing_id === ''
            //             ? null
            //             : event_details.billing_id,
            //     buyer_shipping_address_id:
            //         event_details.shipping_id === ''
            //             ? null
            //             : event_details.shipping_id,
            //     default_reminder_required:
            //         event_details.default_reminder_required,
            //     default_initial_reminder_hours:
            //         event_details.default_initial_reminder_hours,
            //     default_max_reminders: event_details.default_max_reminders,
            //     default_reminder_interval:
            //         event_details.default_reminder_interval,
            //     default_reminder_module: event_details.default_reminder_module,
            //     default_event_payment_type,
            //     default_event_prepayment_percentage,
            //     default_event_payment_terms,
            //     default_event_deliverables_payment_terms,
            //     default_event_incoterm_id:
            //         type === 'DIRECT'
            //             ? 'bbb27fda-712f-4aa2-b42b-386fde9c2b50'
            //             : event_details.incoterm_id,
            //     default_event_lead_time:
            //         type === 'DIRECT'
            //             ? null
            //             : isEmpty(event_details.lead_time)
            //             ? null
            //             : event_details.lead_time,
            //     default_event_lead_time_period:
            //         type === 'DIRECT' ? null : event_details.lead_time_period,
            //     default_currency_id: event_details.currency_id,
            //     default_delivery_date:
            //         event_details.delivery_date === ''
            //             ? null
            //             : event_details.delivery_date,

            //     default_event_project_id:
            //         type === 'DIRECT'
            //             ? null
            //             : event_details.default_event_project_id
            //             ? event_details.default_event_project_id
            //             : null,
            //     default_event_cost_centre_id:
            //         type === 'DIRECT'
            //             ? null
            //             : event_details.default_event_cost_centre_id
            //             ? event_details.default_event_cost_centre_id
            //             : null,
            //     default_event_gl_id:
            //         type === 'DIRECT'
            //             ? null
            //             : event_details.default_event_gl_id
            //             ? event_details.default_event_gl_id
            //             : null,
            //     event_gl_id:
            //         type === 'DIRECT'
            //             ? event_details.event_gl_id
            //                 ? event_details.event_gl_id
            //                 : null
            //             : null,
            //     event_project_id:
            //         type === 'DIRECT'
            //             ? event_details.event_project_id
            //                 ? event_details.event_project_id
            //                 : null
            //             : null,
            //     event_cost_centre_id:
            //         type === 'DIRECT'
            //             ? event_details.event_cost_centre_id
            //                 ? event_details.event_cost_centre_id
            //                 : null
            //             : null,
            //     incoterm_id:
            //         type === 'DIRECT'
            //             ? event_details.incoterm_id
            //                 ? event_details.incoterm_id
            //                 : 'bbb27fda-712f-4aa2-b42b-386fde9c2b50'
            //             : 'bbb27fda-712f-4aa2-b42b-386fde9c2b50',
            //     lead_time:
            //         type === 'DIRECT'
            //             ? event_details.lead_time
            //                 ? event_details.lead_time
            //                 : null
            //             : null,
            //     lead_time_period:
            //         type === 'DIRECT'
            //             ? event_details.lead_time_period
            //                 ? event_details.lead_time_period
            //                 : null
            //             : null,

            //     requisition_information:
            //         event_details.requisition_no.trim() === ''
            //             ? []
            //             : [
            //                   {
            //                       requisition_number:
            //                           event_details.requisition_no,
            //                       requisition_approved:
            //                           event_details.requisition_approved,
            //                   },
            //               ],
            //     access: event_details.is_event_confidential
            //         ? 'ACCESS_RESTRICTED'
            //         : 'ACCESS_DEFAULT',
            //     standard_terms_type: 'NONE',
            //     template_id: event_details.template_id,
            // };
            let payload: any = {};
            type === 'DIRECT'
                ? (payload = {
                      event_name: event_details.event_name,
                      custom_sections: modifyCustomSectionForUpdate(
                          event_details.custom_sections
                      ),
                      //   custom_fields: event_details.custom_fields,
                      //   custom_fields_negotiate:
                      //       event_details.custom_fields_negotiate,
                      default_event_item_additional_information:
                          event_details.default_event_item_additional_information !==
                          ''
                              ? event_details.default_event_item_additional_information
                              : '',
                      additional_costs: convertAdditionalCostFrontendToBackend(
                          event_details.additional_costs
                      ),
                      taxes: convertAdditionalCostFrontendToBackend(
                          event_details.taxes
                      ),
                      discounts: convertAdditionalCostFrontendToBackend(
                          event_details.discounts
                      ),
                      default_event_quantity_tolerance_percentage:
                          event_details.gr_tolerance ?? '0',
                      event_entity_id: event_details.entity_id,
                      event_end_datetime:
                          event_details.event_deadline === ''
                              ? null
                              : event_details.event_deadline,
                      buyer_billing_address_id:
                          event_details.billing_id === ''
                              ? null
                              : event_details.billing_id,
                      buyer_shipping_address_id:
                          event_details.shipping_id === ''
                              ? null
                              : event_details.shipping_id,
                      default_reminder_required:
                          event_details.default_reminder_required,
                      default_initial_reminder_hours:
                          event_details.default_initial_reminder_hours,
                      default_max_reminders:
                          event_details.default_max_reminders,
                      default_reminder_interval:
                          event_details.default_reminder_interval,
                      default_reminder_module:
                          event_details.default_reminder_module,
                      default_event_payment_type,
                      default_event_prepayment_percentage,
                      default_event_payment_terms,
                      default_event_deliverables_payment_terms,
                      payment_type: default_event_payment_type,
                      prepayment_percentage:
                          default_event_prepayment_percentage,
                      payment_terms: default_event_payment_terms,
                      deliverables_payment_terms:
                          default_event_deliverables_payment_terms,
                      default_event_incoterm_id: event_details.incoterm_id,
                      default_event_lead_time: isEmpty(event_details.lead_time)
                          ? null
                          : event_details.lead_time,
                      default_event_lead_time_period:
                          event_details.lead_time_period,
                      default_currency_id: event_details.currency_id,
                      default_delivery_date:
                          event_details.delivery_date === ''
                              ? null
                              : event_details.delivery_date,

                      default_event_project_id:
                          event_details.default_event_project_id
                              ? event_details.default_event_project_id
                              : null,
                      default_event_cost_centre_id:
                          event_details.default_event_cost_centre_id
                              ? event_details.default_event_cost_centre_id
                              : null,
                      default_event_gl_id: event_details.default_event_gl_id
                          ? event_details.default_event_gl_id
                          : null,
                      gl_id: event_details.gl_id ? event_details.gl_id : null,

                      project_id: event_details.project_id
                          ? event_details.project_id
                          : null,

                      cost_centre_id: event_details.cost_centre_id
                          ? event_details.cost_centre_id
                          : null,

                      incoterm_id: event_details.incoterm_id
                          ? event_details.incoterm_id
                          : 'bbb27fda-712f-4aa2-b42b-386fde9c2b50',
                      lead_time: event_details.lead_time
                          ? event_details.lead_time
                          : null,

                      lead_time_period: event_details.lead_time_period
                          ? event_details.lead_time_period
                          : null,
                      custom_additional_information:
                          event_details.custom_additional_information ?? '',
                      gr_tolerance:
                          event_details.gr_tolerance ??
                          event_details.default_event_quantity_tolerance_percentage ??
                          '',
                      requisition_information:
                          event_details.requisition_no.trim() === ''
                              ? []
                              : [
                                    {
                                        requisition_number:
                                            event_details.requisition_no,
                                        requisition_approved:
                                            event_details.requisition_approved,
                                    },
                                ],
                      access: event_details.is_event_confidential
                          ? 'ACCESS_RESTRICTED'
                          : 'ACCESS_DEFAULT',
                      standard_terms_type: 'NONE',
                      template_id: event_details.template_id,
                      item_total: +Number(event_details.item_total).toFixed(10),
                      item_subtotal: event_details.item_subtotal,
                      item_additional_costs_total:
                          event_details.item_additional_costs_total,
                      item_taxes_total: event_details.item_taxes_total,
                      item_discounts_total: event_details.item_discounts_total,
                      vendor_item_subtotal: event_details.vendor_item_subtotal,
                      vendor_item_total: event_details.vendor_item_total,
                      vendor_item_additional_costs_total:
                          event_details.vendor_item_additional_costs_total,
                      vendor_item_taxes_total:
                          event_details.vendor_item_taxes_total,
                      vendor_item_discounts_total:
                          event_details.vendor_item_discounts_total,
                      total_price: event_details.total,
                      vendor_total_price: event_details.total,
                  })
                : (payload = {
                      event_name: event_details.event_name,
                      custom_sections: modifyCustomSectionForUpdate(
                          event_details.custom_sections
                      ),
                      custom_fields: event_details.custom_fields,
                      custom_fields_negotiate:
                          event_details.custom_fields_negotiate,
                      additional_costs: convertAdditionalCostFrontendToBackend(
                          event_details.additional_costs
                      ),
                      taxes: convertAdditionalCostFrontendToBackend(
                          event_details.taxes
                      ),
                      discounts: convertAdditionalCostFrontendToBackend(
                          event_details.discounts
                      ),
                      attachment_ids: event_details.attachments?.map(
                          (attachment) => attachment.attachment_id
                      ),
                      default_event_item_additional_information:
                          event_details.default_event_item_additional_information !==
                          ''
                              ? event_details.default_event_item_additional_information
                              : '',
                      default_event_quantity_tolerance_percentage:
                          event_details.gr_tolerance ?? '0',
                      event_entity_id: event_details.entity_id,
                      event_end_datetime:
                          event_details.event_deadline === ''
                              ? null
                              : event_details.event_deadline,
                      buyer_billing_address_id:
                          event_details.billing_id === ''
                              ? null
                              : event_details.billing_id,
                      buyer_shipping_address_id:
                          event_details.shipping_id === ''
                              ? null
                              : event_details.shipping_id,
                      customer_entity_id: event_details.customer_entity_id,
                      default_customer_entity_id:
                          event_details.default_customer_entity_id,

                      default_reminder_required:
                          event_details.default_reminder_required,
                      default_initial_reminder_hours:
                          event_details.default_initial_reminder_hours,
                      default_max_reminders:
                          event_details.default_max_reminders,
                      default_reminder_interval:
                          event_details.default_reminder_interval,
                      default_reminder_module:
                          event_details.default_reminder_module,
                      default_event_payment_type,
                      default_event_prepayment_percentage,
                      default_event_payment_terms,
                      default_event_deliverables_payment_terms,
                      default_event_incoterm_id: event_details.incoterm_id,
                      default_event_lead_time: isEmpty(event_details.lead_time)
                          ? null
                          : event_details.lead_time,
                      default_event_lead_time_period:
                          event_details.lead_time_period,
                      default_currency_id: event_details.currency_id,
                      default_delivery_date:
                          event_details.delivery_date === ''
                              ? null
                              : event_details.delivery_date,

                      default_event_project_id:
                          event_details.default_event_project_id
                              ? event_details.default_event_project_id
                              : null,
                      project_id: event_details.project_id
                          ? event_details.project_id
                          : null,
                      gr_tolerance: event_details.gr_tolerance,
                      default_event_cost_centre_id:
                          event_details.default_event_cost_centre_id
                              ? event_details.default_event_cost_centre_id
                              : null,
                      default_event_gl_id: event_details.default_event_gl_id
                          ? event_details.default_event_gl_id
                          : null,
                      requisition_information:
                          event_details.requisition_no.trim() === ''
                              ? []
                              : [
                                    {
                                        requisition_number:
                                            event_details.requisition_no,
                                        requisition_approved:
                                            event_details.requisition_approved,
                                    },
                                ],
                      access: event_details.is_event_confidential
                          ? 'ACCESS_RESTRICTED'
                          : 'ACCESS_DEFAULT',
                      standard_terms_type: 'NONE',
                      template_id: event_details.template_id,
                      item_total: event_details.item_total,
                      item_subtotal: event_details.item_subtotal,
                      item_additional_costs_total:
                          event_details.item_additional_costs_total,
                      item_taxes_total: event_details.item_taxes_total,
                      item_discounts_total: event_details.item_discounts_total,
                      vendor_item_subtotal: event_details.vendor_item_subtotal,
                      vendor_item_total: event_details.vendor_item_total,
                      vendor_item_additional_costs_total:
                          event_details.vendor_item_additional_costs_total,
                      vendor_item_taxes_total:
                          event_details.vendor_item_taxes_total,
                      vendor_item_discounts_total:
                          event_details.vendor_item_discounts_total,
                      total_price: event_details.total,
                      vendor_total_price: event_details.total,
                  });
            if (event_details.default_item_terms === 'FROM_PO')
                payload['standard_terms_type'] = 'LOAD_FROM_PO';
            else if (event_details.default_item_terms === 'CUSTOM')
                payload['standard_terms_type'] = 'CUSTOM';
            else payload['standard_terms_type'] = 'NONE';
            // if rfq_event_id is not passed, create a RFQ else update the RFQ
            if (
                !event_details.sub_event_id ||
                event_details.sub_event_id === ''
            ) {
                if (type === 'DIRECT') {
                    let resp = await post<
                        any,
                        {
                            po_group_id: string;
                        }
                    >('/events/po_group/create/', payload, {
                        signal: abortController.signal,
                    });
                    resolve({
                        error: false,
                        sub_event_id: resp.data.po_group_id,
                    });
                } else {
                    let resp = await post<
                        any,
                        {
                            rfq_event_id: string;
                        }
                    >('/events/rfq/create/', payload, {
                        signal: abortController.signal,
                    });
                    resolve({
                        error: false,
                        sub_event_id: resp.data.rfq_event_id,
                    });
                }
            } else {
                if (type === 'DIRECT') {
                    await put<any, void>(
                        `/events/po_group/${event_details.sub_event_id}/update/`,
                        {
                            ...payload,
                            custom_sections: payload.custom_sections.filter(
                                (section: any) =>
                                    section.section_type === 'OTHER'
                            ),
                        },
                        {
                            signal: abortController.signal,
                        }
                    );
                    resolve({
                        error: false,
                        sub_event_id: event_details.sub_event_id,
                    });
                } else {
                    await put<any, void>(
                        `/events/rfq/${event_details.sub_event_id}/update/`,
                        {
                            ...payload,
                            custom_sections: payload.custom_sections.filter(
                                (section: any) =>
                                    section.section_type === 'OTHER'
                            ),
                        },
                        {
                            signal: abortController.signal,
                        }
                    );
                    resolve({
                        error: false,
                        sub_event_id: event_details.sub_event_id,
                    });
                }
            }
        } catch (err) {
            reject({
                error: true,
                rfq_event_id: null,
            });
        }
    });
};

export const { useCreateNewEventMutation } = baseApiSlice.injectEndpoints({
    endpoints: (builder) => ({
        createNewEvent: builder.mutation<
            { error: boolean; sub_event_id: string },
            {
                event_details: ICustomEventDetails;
                type: 'DIRECT' | 'FROM_EVENT';
                abortController: AbortController;
            }
        >({
            queryFn: async ({ event_details, type, abortController }) => {
                try {
                    const paymentTerms = event_details.paymentTerms;

                    const {
                        payment_type: default_event_payment_type,
                        prepayment_percentage:
                            default_event_prepayment_percentage,
                        payment_terms: default_event_payment_terms,
                        deliverables_payment_terms:
                            default_event_deliverables_payment_terms,
                    } = transformPaymentTermsFromFrontendToBackend({
                        paymentTerms,
                    });

                    if (
                        default_event_payment_terms &&
                        default_event_payment_terms.applied_from === undefined
                    ) {
                        default_event_payment_terms.applied_from =
                            'INVOICE_DATE';
                    }

                    try {
                        let payload: any = {};
                        type === 'DIRECT'
                            ? (payload = {
                                  event_name: event_details.event_name,

                                  custom_sections:
                                      event_details.custom_sections,
                                  default_event_item_additional_information:
                                      event_details.default_event_item_additional_information !==
                                      ''
                                          ? event_details.default_event_item_additional_information
                                          : '',
                                  additional_costs:
                                      convertAdditionalCostFrontendToBackend(
                                          event_details.additional_costs
                                      ),
                                  taxes: convertAdditionalCostFrontendToBackend(
                                      event_details.taxes
                                  ),
                                  discounts:
                                      convertAdditionalCostFrontendToBackend(
                                          event_details.discounts
                                      ),
                                  default_event_quantity_tolerance_percentage:
                                      event_details.gr_tolerance ?? '0',
                                  event_entity_id: event_details.entity_id,
                                  event_end_datetime:
                                      event_details.event_deadline === ''
                                          ? null
                                          : event_details.event_deadline,
                                  buyer_billing_address_id:
                                      event_details.billing_id === ''
                                          ? null
                                          : event_details.billing_id,
                                  buyer_shipping_address_id:
                                      event_details.shipping_id === ''
                                          ? null
                                          : event_details.shipping_id,
                                  default_reminder_required:
                                      event_details.default_reminder_required,
                                  default_initial_reminder_hours:
                                      event_details.default_initial_reminder_hours,
                                  default_max_reminders:
                                      event_details.default_max_reminders,
                                  default_reminder_interval:
                                      event_details.default_reminder_interval,
                                  default_reminder_module:
                                      event_details.default_reminder_module,
                                  default_event_payment_type,
                                  default_event_prepayment_percentage,
                                  default_event_payment_terms,
                                  default_event_deliverables_payment_terms,
                                  payment_type: default_event_payment_type,
                                  prepayment_percentage:
                                      default_event_prepayment_percentage,
                                  payment_terms: default_event_payment_terms,
                                  deliverables_payment_terms:
                                      default_event_deliverables_payment_terms,
                                  default_event_incoterm_id:
                                      event_details.incoterm_id,
                                  default_event_lead_time: isEmpty(
                                      event_details.lead_time
                                  )
                                      ? null
                                      : event_details.lead_time,
                                  default_event_lead_time_period:
                                      event_details.lead_time_period,
                                  default_currency_id:
                                      event_details.currency_id,
                                  default_delivery_date:
                                      event_details.delivery_date === ''
                                          ? null
                                          : event_details.delivery_date,

                                  default_event_project_id:
                                      event_details.default_event_project_id
                                          ? event_details.default_event_project_id
                                          : null,
                                  default_event_cost_centre_id:
                                      event_details.default_event_cost_centre_id
                                          ? event_details.default_event_cost_centre_id
                                          : null,
                                  default_event_gl_id:
                                      event_details.default_event_gl_id
                                          ? event_details.default_event_gl_id
                                          : null,
                                  gl_id: event_details.gl_id
                                      ? event_details.gl_id
                                      : null,

                                  project_id: event_details.project_id
                                      ? event_details.project_id
                                      : null,

                                  cost_centre_id: event_details.cost_centre_id
                                      ? event_details.cost_centre_id
                                      : null,

                                  incoterm_id: event_details.incoterm_id
                                      ? event_details.incoterm_id
                                      : 'bbb27fda-712f-4aa2-b42b-386fde9c2b50',
                                  lead_time: event_details.lead_time
                                      ? event_details.lead_time
                                      : null,

                                  lead_time_period:
                                      event_details.lead_time_period
                                          ? event_details.lead_time_period
                                          : null,
                                  custom_additional_information:
                                      event_details.custom_additional_information ??
                                      '',
                                  gr_tolerance:
                                      event_details.gr_tolerance ?? '',
                                  requisition_information:
                                      event_details.requisition_no.trim() === ''
                                          ? []
                                          : [
                                                {
                                                    requisition_number:
                                                        event_details.requisition_no,
                                                    requisition_approved:
                                                        event_details.requisition_approved,
                                                },
                                            ],
                                  access: event_details.is_event_confidential
                                      ? 'ACCESS_RESTRICTED'
                                      : 'ACCESS_DEFAULT',
                                  standard_terms_type: 'NONE',
                                  template_id: event_details.template_id,
                                  item_total: +Number(
                                      event_details.item_total
                                  ).toFixed(10),
                                  item_subtotal: event_details.item_subtotal,
                                  item_additional_costs_total:
                                      event_details.item_additional_costs_total,
                                  item_taxes_total:
                                      event_details.item_taxes_total,
                                  item_discounts_total:
                                      event_details.item_discounts_total,
                                  vendor_item_subtotal:
                                      event_details.vendor_item_subtotal,
                                  vendor_item_total:
                                      event_details.vendor_item_total,
                                  vendor_item_additional_costs_total:
                                      event_details.vendor_item_additional_costs_total,
                                  vendor_item_taxes_total:
                                      event_details.vendor_item_taxes_total,
                                  vendor_item_discounts_total:
                                      event_details.vendor_item_discounts_total,
                                  total_price: event_details.total,
                                  vendor_total_price: event_details.total,
                              })
                            : (payload = {
                                  event_name: event_details.event_name,
                                  custom_sections:
                                      event_details.custom_sections,
                                  additional_costs:
                                      convertAdditionalCostFrontendToBackend(
                                          event_details.additional_costs
                                      ),
                                  taxes: convertAdditionalCostFrontendToBackend(
                                      event_details.taxes
                                  ),
                                  discounts:
                                      convertAdditionalCostFrontendToBackend(
                                          event_details.discounts
                                      ),
                                  default_event_item_additional_information:
                                      event_details.default_event_item_additional_information !==
                                      ''
                                          ? event_details.default_event_item_additional_information
                                          : '',
                                  default_event_quantity_tolerance_percentage:
                                      event_details.gr_tolerance ?? '0',
                                  event_entity_id: event_details.entity_id,
                                  event_end_datetime:
                                      event_details.event_deadline === ''
                                          ? null
                                          : event_details.event_deadline,
                                  buyer_billing_address_id:
                                      event_details.billing_id === ''
                                          ? null
                                          : event_details.billing_id,
                                  buyer_shipping_address_id:
                                      event_details.shipping_id === ''
                                          ? null
                                          : event_details.shipping_id,
                                  customer_entity_id:
                                      event_details.customer_entity_id,
                                  default_customer_entity_id:
                                      event_details.default_customer_entity_id,

                                  default_reminder_required:
                                      event_details.default_reminder_required,
                                  default_initial_reminder_hours:
                                      event_details.default_initial_reminder_hours,
                                  default_max_reminders:
                                      event_details.default_max_reminders,
                                  default_reminder_interval:
                                      event_details.default_reminder_interval,
                                  default_reminder_module:
                                      event_details.default_reminder_module,
                                  default_event_payment_type,
                                  default_event_prepayment_percentage,
                                  default_event_payment_terms,
                                  default_event_deliverables_payment_terms,
                                  default_event_incoterm_id:
                                      event_details.incoterm_id,
                                  default_event_lead_time: isEmpty(
                                      event_details.lead_time
                                  )
                                      ? null
                                      : event_details.lead_time,
                                  default_event_lead_time_period:
                                      event_details.lead_time_period,
                                  default_currency_id:
                                      event_details.currency_id,
                                  default_delivery_date:
                                      event_details.delivery_date === ''
                                          ? null
                                          : event_details.delivery_date,

                                  default_event_project_id:
                                      event_details.default_event_project_id
                                          ? event_details.default_event_project_id
                                          : null,
                                  project_id: event_details.project_id
                                      ? event_details.project_id
                                      : null,
                                  default_event_cost_centre_id:
                                      event_details.default_event_cost_centre_id
                                          ? event_details.default_event_cost_centre_id
                                          : null,
                                  default_event_gl_id:
                                      event_details.default_event_gl_id
                                          ? event_details.default_event_gl_id
                                          : null,
                                  requisition_information:
                                      event_details.requisition_no.trim() === ''
                                          ? []
                                          : [
                                                {
                                                    requisition_number:
                                                        event_details.requisition_no,
                                                    requisition_approved:
                                                        event_details.requisition_approved,
                                                },
                                            ],
                                  access: event_details.is_event_confidential
                                      ? 'ACCESS_RESTRICTED'
                                      : 'ACCESS_DEFAULT',
                                  standard_terms_type: 'NONE',
                                  template_id: event_details.template_id,
                                  item_total: event_details.item_total,
                                  item_subtotal: event_details.item_subtotal,
                                  item_additional_costs_total:
                                      event_details.item_additional_costs_total,
                                  item_taxes_total:
                                      event_details.item_taxes_total,
                                  item_discounts_total:
                                      event_details.item_discounts_total,
                                  vendor_item_subtotal:
                                      event_details.vendor_item_subtotal,
                                  vendor_item_total:
                                      event_details.vendor_item_total,
                                  vendor_item_additional_costs_total:
                                      event_details.vendor_item_additional_costs_total,
                                  vendor_item_taxes_total:
                                      event_details.vendor_item_taxes_total,
                                  vendor_item_discounts_total:
                                      event_details.vendor_item_discounts_total,
                                  total_price: event_details.total,
                                  vendor_total_price: event_details.total,
                              });
                        if (event_details.default_item_terms === 'FROM_PO')
                            payload['standard_terms_type'] = 'LOAD_FROM_PO';
                        else if (event_details.default_item_terms === 'CUSTOM')
                            payload['standard_terms_type'] = 'CUSTOM';
                        else payload['standard_terms_type'] = 'NONE';
                        // if rfq_event_id is not passed, create a RFQ else update the RFQ
                        if (
                            !event_details.sub_event_id ||
                            event_details.sub_event_id === ''
                        ) {
                            if (type === 'DIRECT') {
                                let resp = await post<
                                    any,
                                    {
                                        po_group_id: string;
                                    }
                                >('/events/po_group/create/', payload, {
                                    signal: abortController.signal,
                                });
                                return {
                                    data: {
                                        error: false,
                                        sub_event_id: resp.data.po_group_id,
                                    },
                                };
                            } else {
                                let resp = await post<
                                    any,
                                    {
                                        rfq_event_id: string;
                                    }
                                >('/events/rfq/create/', payload, {
                                    signal: abortController.signal,
                                });
                                return {
                                    data: {
                                        error: false,
                                        sub_event_id: resp.data.rfq_event_id,
                                    },
                                };
                            }
                        } else {
                            if (type === 'DIRECT') {
                                await put<any, void>(
                                    `/events/po_group/${event_details.sub_event_id}/update/`,
                                    payload,
                                    {
                                        signal: abortController.signal,
                                    }
                                );
                                return {
                                    data: {
                                        error: false,
                                        sub_event_id:
                                            event_details.sub_event_id,
                                    },
                                };
                            } else {
                                await put<any, void>(
                                    `/events/rfq/${event_details.sub_event_id}/update/`,
                                    payload,
                                    {
                                        signal: abortController.signal,
                                    }
                                );
                                return {
                                    data: {
                                        error: false,
                                        sub_event_id:
                                            event_details.sub_event_id,
                                    },
                                };
                            }
                        }
                    } catch (err) {
                        return {
                            error: {
                                error: true,
                                rfq_event_id: null,
                            } as any,
                        };
                    }
                } catch (e) {
                    return {
                        error: {
                            error: true,
                            rfq_event_id: null,
                        } as any,
                    };
                }
            },
        }),
    }),
});

export const deleteLinkedEventDetailsApi = (event_id: string): Promise<any> => {
    return new Promise(async (resolve, reject) => {
        try {
            let resp = await del(`/events/rfq/${event_id}/link/delete/`);
            resolve(resp.data);
        } catch (err) {
            reject();
        }
    });
};

export const saveLinkedEventDetailsApi = (
    event_details: ICustomEventDetails,
    abortController: AbortController,
    vendor: string | null
): Promise<{
    error: boolean;
    sub_event_id: string;
}> => {
    const paymentTerms = event_details.paymentTerms;

    const {
        payment_type: default_event_payment_type,
        prepayment_percentage: default_event_prepayment_percentage,
        payment_terms: default_event_payment_terms,
        deliverables_payment_terms: default_event_deliverables_payment_terms,
    } = transformPaymentTermsFromFrontendToBackend({ paymentTerms });

    if (
        default_event_payment_terms &&
        default_event_payment_terms.applied_from === undefined
    ) {
        default_event_payment_terms.applied_from = 'INVOICE_DATE';
    }

    return new Promise(async (resolve, reject) => {
        try {
            let payload: any = {};
            payload = {
                event_name: event_details.event_name,
                custom_fields: event_details.custom_fields,
                custom_fields_negotiate: event_details.custom_fields_negotiate,
                default_event_item_additional_information:
                    event_details.default_event_item_additional_information !==
                    ''
                        ? event_details.default_event_item_additional_information
                        : '',
                default_event_quantity_tolerance_percentage:
                    event_details.gr_tolerance ?? '0',
                event_entity_id: event_details.entity_id,
                event_end_datetime:
                    event_details.event_deadline === ''
                        ? null
                        : event_details.event_deadline,
                buyer_billing_address_id:
                    event_details.billing_id === ''
                        ? null
                        : event_details.billing_id,
                buyer_shipping_address_id:
                    event_details.shipping_id === ''
                        ? null
                        : event_details.shipping_id,
                default_reminder_required:
                    event_details.default_reminder_required,
                default_initial_reminder_hours:
                    event_details.default_initial_reminder_hours,
                default_max_reminders: event_details.default_max_reminders,
                default_reminder_interval:
                    event_details.default_reminder_interval,
                default_reminder_module: event_details.default_reminder_module,
                default_event_payment_type,
                default_event_prepayment_percentage,
                default_event_payment_terms,
                default_event_deliverables_payment_terms,
                default_event_incoterm_id: event_details.incoterm_id,
                default_event_lead_time: isEmpty(event_details.lead_time)
                    ? null
                    : event_details.lead_time,
                default_event_lead_time_period: event_details.lead_time_period,
                default_currency_id: event_details.currency_id,
                default_delivery_date:
                    event_details.delivery_date === ''
                        ? null
                        : event_details.delivery_date,

                default_event_project_id: event_details.default_event_project_id
                    ? event_details.default_event_project_id
                    : null,
                default_event_cost_centre_id:
                    event_details.default_event_cost_centre_id
                        ? event_details.default_event_cost_centre_id
                        : null,
                default_event_gl_id: event_details.default_event_gl_id
                    ? event_details.default_event_gl_id
                    : null,
                requisition_information:
                    event_details.requisition_no.trim() === ''
                        ? []
                        : [
                              {
                                  requisition_number:
                                      event_details.requisition_no,
                                  requisition_approved:
                                      event_details.requisition_approved,
                              },
                          ],
                access: event_details.is_event_confidential
                    ? 'ACCESS_RESTRICTED'
                    : 'ACCESS_DEFAULT',
                standard_terms_type: 'NONE',
                template_id: event_details.template_id,
                item_total: event_details.item_total,
                item_subtotal: event_details.item_subtotal,
                item_additional_costs_total:
                    event_details.item_additional_costs_total,
                item_taxes_total: event_details.item_taxes_total,
                item_discounts_total: event_details.item_discounts_total,
                vendor_item_subtotal: event_details.vendor_item_subtotal,
                vendor_item_total: event_details.vendor_item_total,
                vendor_item_additional_costs_total:
                    event_details.vendor_item_additional_costs_total,
                vendor_item_taxes_total: event_details.vendor_item_taxes_total,
                vendor_item_discounts_total:
                    event_details.vendor_item_discounts_total,
                total_price: event_details.total,
                vendor_total_price: event_details.total,
                linked_vendor: vendor ?? null,
            };
            if (event_details.default_item_terms === 'FROM_PO')
                payload['standard_terms_type'] = 'LOAD_FROM_PO';
            else if (event_details.default_item_terms === 'CUSTOM')
                payload['standard_terms_type'] = 'CUSTOM';
            else payload['standard_terms_type'] = 'NONE';
            // if rfq_event_id is not passed, create a RFQ else update the RFQ
            if (
                !event_details.sub_event_id ||
                event_details.sub_event_id === ''
            ) {
                let resp = await post<
                    any,
                    {
                        rfq_event_id: string;
                    }
                >(
                    `/events/rfq/${event_details.event_id}/create/link/`,
                    payload,
                    {
                        signal: abortController.signal,
                    }
                );
                resolve({
                    error: false,
                    sub_event_id: resp.data.rfq_event_id,
                });
            } else {
                await put<any, void>(
                    `/events/rfq/${event_details.event_id}/link/update/`,
                    payload,
                    {
                        signal: abortController.signal,
                    }
                );
                resolve({
                    error: false,
                    sub_event_id: event_details.sub_event_id,
                });
            }
        } catch (err) {
            reject({
                error: true,
                rfq_event_id: null,
            });
        }
    });
};

export const fetchBuyerEnterpriseItemDetails = (
    enterprise_item_id: string,
    entity_id: string
): Promise<IBuyerEnterpriseItemDetails> => {
    return new Promise(async (resolve, reject) => {
        try {
            let item_details = await get<IBuyerEnterpriseItemDetails>(
                `/organization/items/${enterprise_item_id}/entity/${entity_id}/`
            );

            if (isEmpty(item_details.data.pricing_information)) {
                delete item_details.data.pricing_information;
            }
            resolve(item_details.data);
        } catch (err) {
            console.error('Factwise', err);
            reject();
        }
    });
};

export const fetchItemAdditionalCostList = (): Promise<
    IAdditionalCostInformation[]
> => {
    return new Promise(async (resolve, reject) => {
        // replace any asap
        try {
            let additionalCostInfo = await get<IAdditionalCostInformation[]>(
                `/additional_cost/`
            );

            resolve(
                additionalCostInfo.data.map((cost) => ({
                    ...cost,
                    formula:
                        cost.formula === null
                            ? null
                            : {
                                  formula_id: cost.formula.formula_id,
                                  formula_name: cost.formula.formula_name,
                                  formula: {
                                      formula_string:
                                          cost.formula.formula.formula_string ??
                                          '',
                                      operands:
                                          cost.formula.formula.operands ?? [],
                                  },
                              },
                }))
            );
        } catch (err) {
            reject();
        }
    });
};

export interface IMultipleCurrencyConversionRes {
    from_currency_id: string;
    to_currency_id: string;
    conversion_rate: number;
}

export const getManyToManyCurrencyConversion = async (
    payload: {
        currency_from_id: string;
        currency_to_id: string;
    }[]
) => {
    try {
        const response = await post<any, IMultipleCurrencyConversionRes[]>(
            `/backbone/currency_conversions/`,
            payload
        );

        let res: {
            [currency_id: string]: number;
        } = {};

        response.data.forEach((curr) => {
            res[curr.from_currency_id] = curr.conversion_rate;
        });

        return res;
    } catch (err) {
        throw err;
    }
};

export const { useFetchItemAdditionalCostListQuery } =
    baseApiSlice.injectEndpoints({
        endpoints: (build) => ({
            fetchItemAdditionalCostList: build.query<
                IAdditionalCostInformation[],
                { filterModule: CostModuleEnum | undefined }
            >({
                query: () => `/additional_cost/`,
                transformResponse: (
                    baseQueryReturnValue: IAdditionalCostInformation[],
                    meta,
                    arg
                ) => {
                    if (arg.filterModule)
                        return baseQueryReturnValue.filter((cost) =>
                            cost.modules.includes(arg.filterModule!)
                        );
                    else return baseQueryReturnValue;
                },
            }),
        }),
    });

export const fetchBuyerEnterpriseMultipleItemDetails = async (
    enterprise_item_id: string[],
    entity_id: string
) => {
    try {
        const response = await get<
            {
                entity_item_id: string;
                preferred_vendors_count: number;
                enterprise_item: IBuyerEnterpriseItemDetails;
                created_datetime: string;
                modified_datetime: string;
                deleted_datetime: any;
                status: string;
                created_by_user: string;
                modified_by_user: string;
                deleted_by_user: any;
                buyer_enterprise: string;
                buyer_entity: string;
            }[]
        >(
            `/organization/items/entity/${entity_id}/?${enterprise_item_id
                .map((id) => `item_ids[]=${id}`)
                .join('&')}`
        );

        const result: {
            [enterprise_item_id: string]: IBuyerEnterpriseItemDetails;
        } = {};

        response.data.forEach((item) => {
            result[item.enterprise_item.enterprise_item_id] =
                item.enterprise_item;
        });

        return result;
    } catch (err) {
        throw err;
    }
};

export const { useFetchBuyerEnterpriseMultipleItemDetailsQuery } =
    baseApiSlice.injectEndpoints({
        endpoints: (build) => ({
            fetchBuyerEnterpriseMultipleItemDetails: build.query<
                {
                    [enterprise_item_id: string]: IBuyerEnterpriseItemDetails;
                },
                {
                    enterprise_item_id: string[];
                    entity_id: string;
                }
            >({
                queryFn: async ({ enterprise_item_id, entity_id }) => {
                    try {
                        const response = await get<
                            {
                                entity_item_id: string;
                                preferred_vendors_count: number;
                                enterprise_item: IBuyerEnterpriseItemDetails;
                                created_datetime: string;
                                modified_datetime: string;
                                deleted_datetime: any;
                                status: string;
                                created_by_user: string;
                                modified_by_user: string;
                                deleted_by_user: any;
                                buyer_enterprise: string;
                                buyer_entity: string;
                            }[]
                        >(
                            `/organization/items/entity/${entity_id}/?${enterprise_item_id
                                .map((id) => `item_ids[]=${id}`)
                                .join('&')}`
                        );

                        const result: {
                            [
                                enterprise_item_id: string
                            ]: IBuyerEnterpriseItemDetails;
                        } = {};

                        response.data.forEach((item) => {
                            result[item.enterprise_item.enterprise_item_id] =
                                item.enterprise_item;
                        });

                        return {
                            data: result,
                        };
                    } catch (err: any) {
                        return {
                            error: err,
                        };
                    }
                },
            }),
        }),
    });

export const postRFQItem = (
    event_id: string,
    obj: ICustomEventItemDetails,
    type: 'DIRECT' | 'FROM_EVENT'
): Promise<IEventItemDetails> => {
    return new Promise(async (resolve, reject) => {
        let item_entry_id = obj.event_item_entry_id;

        const paymentTerms = obj.paymentTerms;

        const {
            payment_type,
            prepayment_percentage,
            payment_terms,
            deliverables_payment_terms,
        } = transformPaymentTermsFromFrontendToBackend({ paymentTerms });
        let _payload = {
            custom_sections: modifyCustomSectionForUpdate(
                obj.custom_sections
            ).filter((section) => section.section_type === 'ITEM'),
            attachment_ids: obj.attachments.map(
                (attachment) => attachment.attachment_id
            ),
            item_id: obj.enterprise_item_id,
            item_additional_details: obj.item_additional_information,
            requisition_information:
                obj.requisition_number !== ''
                    ? [
                          {
                              requisition_number: obj.requisition_number,
                              requisition_approved: obj.requisition_approved,
                          },
                      ]
                    : [],
            attributes: obj.attributes
                .filter((attr) => attr.attribute_id === null)
                .map((attr) => {
                    return {
                        attribute_linkage_id: attr.attribute_linkage_id,
                        attribute_id: attr.attribute_id,
                        attribute_name: attr.attribute_name,
                        attribute_type: attr.attribute_type,
                        attribute_values: attr.attribute_values.map((val) => {
                            return {
                                attribute_value_linkage_id:
                                    val.attribute_value_linkage_id,
                                attribute_value_id:
                                    val.attribute_value_id !== ''
                                        ? val.attribute_value_id
                                        : null,
                                value: val.value,
                                measurement_unit_id:
                                    val.measurement_unit?.measurement_unit_id,
                                currency_id: val.currency?.entry_id,
                            };
                        }),
                    } as ICustomAttributePayloadNew;
                }),

            properties: [],
            quantity:
                typeof obj.quantity === 'string' && isEmpty(obj.quantity)
                    ? null
                    : obj.quantity,
            quantity_tolerance_percentage:
                obj.quantity_tolerance_percentage === ''
                    ? null
                    : +obj.quantity_tolerance_percentage,
            custom_item_name: '',
            measurement_unit_id: obj.measurement_unit_id,
            desired_price:
                typeof obj.price === 'string' && isEmpty(obj.price)
                    ? null
                    : +obj.price,
            currency_code_id: obj.currency_code_id,

            is_price_confidential: obj.is_price_confidential,

            delivery_schedule: obj.delivery_schedule.map((del) => {
                let newDel: any = cloneDeep(del);
                if (isEmpty(del.delivery_date)) {
                    newDel.delivery_date = null;
                } else {
                    newDel.delivery_date = moment(del.delivery_date).format(
                        'yyyy-MM-DD'
                    );
                }
                if (typeof del.quantity === 'string' && isEmpty(del.quantity)) {
                    newDel.quantity = null;
                }
                newDel = {
                    ...newDel,
                    parent_delivery_schedule_item_id:
                        newDel.parent_delivery_schedule_item
                            ?.delivery_schedule_item_id,
                    bom_item_id:
                        newDel.bom_item?.bom_item_id ??
                        newDel.bom_item?.entry_id ??
                        null,
                };
                delete newDel.parent_delivery_schedule_item;
                return newDel;
            }),
            allow_substitutes: false,
            incoterm_id: obj.incoterm_id,
            lead_time:
                typeof obj.lead_time === 'string' && isEmpty(obj.lead_time)
                    ? null
                    : +obj.lead_time,
            lead_time_period: obj.lead_time_period,
            payment_type,
            prepayment_percentage,
            payment_terms,
            deliverables_payment_terms,
            shipping_per_unit:
                typeof obj.shipping === 'string' && isEmpty(obj.shipping)
                    ? null
                    : +obj.shipping,
            additional_charges: obj.additional_charges.filter(
                (charge) =>
                    !isEmpty(charge.charge_value) && +charge.charge_value > 0
            ),
            // custom_fields: translateFrontEndCustomFieldSectionsToBackend(
            //     obj.custom_fields_sections,
            //     'NON_NEGOTIABLE'
            // ),
            // custom_fields_negotiate:
            //     translateFrontEndCustomFieldSectionsToBackend(
            //         obj.custom_fields_sections,
            //         'NEGOTIABLE'
            //     ),
            additional_costs: convertAdditionalCostFrontendToBackend(
                obj.additional_costs
            ),
            taxes: convertAdditionalCostFrontendToBackend(obj.taxes),
            discounts: convertAdditionalCostFrontendToBackend(obj.discounts),
            total: +Number(obj.total).toFixed(10),
        };
        if (!item_entry_id || item_entry_id === 'NEW') {
            if (type === 'DIRECT') {
                await post<any, IEventItemDetails>(
                    `/events/po_group/${event_id}/items/create/`,
                    _payload
                )
                    .then((resp) => {
                        const response = cloneDeep(resp.data);

                        response.paymentTerms =
                            transformPaymentTermsFromBackendToFrontend({
                                prepayment_percentage: (response as any)
                                    .prepayment_percentage,
                                payment_terms: (response as any).payment_terms,
                                deliverables_payment_terms: (response as any)
                                    .deliverables_payment_terms,
                            });

                        response.rfq_item_entry_id = (
                            response as any
                        ).po_group_item_entry_id;

                        resolve(response);
                    })
                    .catch((err) => {
                        reject();
                    });
            } else {
                await post<any, IEventItemDetails>(
                    `/events/rfq/${event_id}/items/create/`,
                    _payload
                )
                    .then((resp) => {
                        const response = cloneDeep(resp.data);

                        response.paymentTerms =
                            transformPaymentTermsFromBackendToFrontend({
                                prepayment_percentage: (response as any)
                                    .prepayment_percentage,
                                payment_terms: (response as any).payment_terms,
                                deliverables_payment_terms: (response as any)
                                    .deliverables_payment_terms,
                            });
                        resolve(response);
                    })
                    .catch((err) => {
                        reject();
                    });
            }
        } else {
            if (type === 'DIRECT') {
                await put<any, IEventItemDetails>(
                    `/events/po_group/${event_id}/items/${item_entry_id}/update/`,
                    _payload
                )
                    .then((resp) => {
                        const response = cloneDeep(resp.data);

                        response.paymentTerms =
                            transformPaymentTermsFromBackendToFrontend({
                                prepayment_percentage: (response as any)
                                    .prepayment_percentage,
                                payment_terms: (response as any).payment_terms,
                                deliverables_payment_terms: (response as any)
                                    .deliverables_payment_terms,
                            });

                        response.rfq_item_entry_id = (
                            response as any
                        ).po_group_item_entry_id;

                        resolve(response);
                    })
                    .catch((err) => {
                        reject();
                    });
            } else {
                await put<any, IEventItemDetails>(
                    `/events/rfq/${event_id}/items/${item_entry_id}/update/`,
                    _payload
                )
                    .then((resp) => {
                        const response = cloneDeep(resp.data);

                        response.paymentTerms =
                            transformPaymentTermsFromBackendToFrontend({
                                prepayment_percentage: (response as any)
                                    .prepayment_percentage,
                                payment_terms: (response as any).payment_terms,
                                deliverables_payment_terms: (response as any)
                                    .deliverables_payment_terms,
                            });

                        resolve(response);
                    })
                    .catch((err) => {
                        reject();
                    });
            }
        }
    });
};

export const deleteRFQItem = (
    event_id: string,
    rfq_item_entry_id: string,
    type: 'DIRECT' | 'FROM_EVENT'
): Promise<void> => {
    if (type === 'DIRECT') {
        return new Promise(async (resolve, reject) => {
            del<void>(
                `/events/po_group/${event_id}/items/${rfq_item_entry_id}/delete/`
            )
                .then(() => {
                    resolve();
                })
                .catch(() => {
                    reject();
                });
        });
    } else {
        return new Promise(async (resolve, reject) => {
            del<void>(
                `/events/rfq/${event_id}/items/${rfq_item_entry_id}/delete/`
            )
                .then(() => {
                    resolve();
                })
                .catch(() => {
                    reject();
                });
        });
    }
};

export const fetchAllVendorsForRFQItem = (
    entity_id: string,
    item_id: string
): Promise<IVendorForEventItem[]> => {
    return new Promise(async (resolve, reject) => {
        try {
            let vendor_resp = await get<any[]>(
                `/organization/vendor_master/entity/${entity_id}/item/${item_id}/`
            );
            const preferredVendors: IVendorForEventItem[] = vendor_resp.data
                .filter((vendor) => vendor.status === 'PREFERRED')
                .map((vendor, idx) => ({
                    id: vendor.seller_entity.entity_id,
                    entity_vendor_master_id: vendor.entity_vendor_master_id,
                    enterprise_item: vendor.enterprise_item,
                    buyer_enterprise: vendor.buyer_enterprise,
                    status: vendor.status,
                    seller_entity: vendor.seller_entity,
                    seller_contacts: [vendor.primary_vendor_contact],
                    vendor_code: vendor.enterprise_vendor_master.vendor_code,
                    no: idx,
                }));
            resolve(preferredVendors);
        } catch (err) {
            console.error('Factwise', err);
            reject();
        }
    });
};

export const getCurrencyConversion = async (
    currency_from_id: string,
    currency_to_id: string
) => {
    try {
        let res = await get<{ rate: number }>(
            `/backbone/currency_code/${currency_from_id}/to/${currency_to_id}/`
        );

        return res.data;
    } catch (err) {}
};

export const getMultipleCurencyConversion = async (
    eventid: string,
    payload: {
        currency_from_id: string;
        currency_to_id: string;
        seller_entity_id: string | null;
    }[]
) => {
    try {
        const resp = await post<
            any,
            {
                currency_from_id: string;
                currency_to_id: string;
                seller_entity_id: string | null;
                rate: number;
                precision: number;
            }[]
        >(`/events/rfq/${eventid}/conversions/`, payload);

        return resp.data;
    } catch (err) {
        throw err;
    }
};

export const {
    useFetchAllVendorsForRFQItemQuery,
    useFetchAllVendorsForRFQMultipleItemQuery,
} = baseApiSlice.injectEndpoints({
    endpoints: (build) => ({
        fetchAllVendorsForRFQItem: build.query<
            IVendorForEventItem[],
            { entity_id: string; item_id: string }
        >({
            queryFn: async ({ entity_id, item_id }) => {
                try {
                    let vendor_resp = await get<any[]>(
                        `/organization/vendor_master/entity/${entity_id}/item/${item_id}/`
                    );
                    const preferredVendors: IVendorForEventItem[] =
                        vendor_resp.data
                            .filter((vendor) => vendor.status === 'PREFERRED')
                            .map((vendor, idx) => ({
                                id: vendor.seller_entity.entity_id,
                                entity_vendor_master_id:
                                    vendor.entity_vendor_master_id,
                                enterprise_item: vendor.enterprise_item,
                                buyer_enterprise: vendor.buyer_enterprise,
                                status: vendor.status,
                                seller_entity: vendor.seller_entity,
                                seller_contacts: [
                                    vendor.primary_vendor_contact,
                                ],
                                vendor_code:
                                    vendor.enterprise_vendor_master.vendor_code,
                                no: idx,
                            }));
                    return {
                        data: preferredVendors,
                    };
                } catch (error: any) {
                    return { error };
                }
            },
        }),
        fetchAllVendorsForRFQMultipleItem: build.query<
            {
                [item_id: string]: IVendorForEventItem[];
            },
            { entity_id: string; item_id: string[] }
        >({
            queryFn: async ({ entity_id, item_id }) => {
                try {
                    const res = await get<
                        IBackendVendorMasterMultipleItemResponse[]
                    >(
                        `/organization/vendor_master/entity/${entity_id}/items/?${item_id
                            .map((id) => `item_ids[]=${id}`)
                            .join('&')}`
                    );

                    const response: {
                        [item_id: string]: IVendorForEventItem[];
                    } = {};

                    for (let id of item_id) {
                        response[id] = [];
                    }

                    for (let vendorItem of res.data) {
                        if (
                            response[
                                vendorItem.enterprise_item.enterprise_item_id
                            ] === undefined
                        ) {
                            response[
                                vendorItem.enterprise_item.enterprise_item_id
                            ] = [];
                        }
                        response[
                            vendorItem.enterprise_item.enterprise_item_id
                        ].push({
                            buyer_enterprise: vendorItem.buyer_enterprise,
                            enterprise_item: vendorItem.buyer_enterprise,
                            entity_vendor_master_id:
                                vendorItem.entity_vendor_master_id,
                            seller_contacts: [
                                // {
                                //     ...vendorItem.enterprise_vendor_master
                                //         .primary_vendor_contact,
                                //     emails:
                                //         vendorItem.enterprise_vendor_master
                                //             .primary_vendor_contact.emails ??
                                //         [],
                                // },
                                ...vendorItem.enterprise_vendor_master.secondary_vendor_contacts
                                    .filter((v) => v.buyer_entity === entity_id)
                                    .map((c) => ({
                                        ...c,
                                        emails: c.emails ?? [],
                                    })),
                            ],
                            seller_entity: vendorItem.seller_entity as any,
                            status: vendorItem.status as
                                | 'PREFERRED'
                                | 'STANDARD',
                            vendor_code:
                                vendorItem.enterprise_vendor_master.vendor_code,
                            no: 0,
                        });
                    }

                    return {
                        data: response,
                    };
                    // eslint-disable-next-line no-unreachable
                } catch (err: any) {
                    return {
                        error: err,
                    };
                }
            },
        }),
    }),
});

export interface IBackendVendorMasterMultipleItemResponse {
    entity_vendor_master_id: string;
    seller_entity: {
        entity_id: string;
        enterprise: string;
        entity_name: string;
        entity_logo: Array<{
            attachment_id: string;
            attachment_type: string;
            storage_id: string;
            resource_id: string;
            file_name: string;
            attachment_status: string;
        }>;
        entity_description?: string;
        buyer_status: string;
        seller_status: string;
        contacts: {
            emails: Array<any>;
            websites: Array<string>;
            phone_numbers: Array<any>;
        };
        verification_status: string;
        is_virtual: boolean;
    };
    enterprise_item: {
        enterprise_item_id: string;
        code: string;
        name: string;
        description: string;
        measurement_units: {
            item_measurement_units: Array<{
                measurement_unit_id: string;
                measurement_unit_category: string;
                measurement_unit_value_type: string;
                measurement_unit_primary_name: string;
            }>;
        };
        attributes: ICustomAttributeNew[];
        custom_ids: {
            custom_ids: Array<{
                name: string;
                value: string;
            }>;
        };
        status: string;
        preferred_vendor_status: string;
    };
    enterprise_vendor_master: {
        enterprise_vendor_master_id: string;
        vendor_code: string;
        vendor_name: string;
        status: string;
        primary_vendor_contact: {
            vendor_contact_id: string;
            buyer_entity: any;
            seller_entity: string;
            full_name: string;
            primary_email: string;
            emails: Array<any>;
            phone_numbers: Array<any>;
            tags: Array<any>;
            is_primary: boolean;
            user: string;
            status: string;
        };
        secondary_vendor_contacts: Array<{
            vendor_contact_id: string;
            buyer_entity: string;
            seller_entity: string;
            full_name: string;
            primary_email: string;
            emails: Array<{
                type: string;
                email: string;
            }>;
            phone_numbers: Array<string>;
            tags: Array<any>;
            is_primary: boolean;
            user: string;
            status: string;
        }>;
    };
    primary_vendor_contact: {
        vendor_contact_id: string;
        buyer_entity: string;
        seller_entity: string;
        full_name: string;
        primary_email: string;
        emails?: Array<any>;
        phone_numbers: Array<any>;
        tags: Array<any>;
        is_primary: boolean;
        user: string;
        status: string;
    };
    created_datetime: string;
    modified_datetime: string;
    deleted_datetime: any;
    status: string;
    created_by_user: string;
    modified_by_user: string;
    deleted_by_user: any;
    buyer_enterprise: string;
    buyer_entity: string;
    seller_enterprise: string;
}
export interface IAddedVendorList {
    entity_id: string;
    entity_name: string;
    entity_logo: string;
    entity_website_url: string;
    enterprise_name: string;
    contacts: { [key: string]: string | string[] };
    is_virtual: boolean;
    verification_status: VerificationStatus;
    seller_contacts: {
        enterprise_vendor_master: {
            vendor_code: string;
        };
        vendor_contact_id: string;
        full_name: string;
        primary_email: string;
        emails: {
            type: string;
            email: string;
        }[];
        phone_numbers: string[];
        tags: string[];
    }[];
    vendor_code: string;
}

export const fetchVendorListAddedToRFQItem = (
    event_id: string,
    rfq_item_id: string,
    type: 'DIRECT' | 'FROM_EVENT'
): Promise<IAddedVendorList[]> => {
    return new Promise(async (resolve, reject) => {
        if (type === 'DIRECT') {
            try {
                let vendor_resp = await get<any[]>(
                    `/events/po_group/${event_id}/items/${rfq_item_id}/sellers/`
                );
                const convertToFrontend = vendor_resp.data.map((vendor) => {
                    return {
                        ...vendor.seller_entity,
                        seller_contacts: vendor.seller_contacts,
                    };
                });
                resolve(convertToFrontend);
            } catch (err) {
                reject();
            }
        } else {
            try {
                let vendor_resp = await get<any[]>(
                    `/events/rfq/${event_id}/items/${rfq_item_id}/sellers/`
                );
                const convertToFrontend = vendor_resp.data.map((vendor) => {
                    return {
                        ...vendor.seller_entity,
                        seller_contacts: vendor.seller_contacts,
                    };
                });
                resolve(convertToFrontend);
            } catch (err) {
                reject();
            }
        }
    });
};

export const { useFetchVendorListAddedToRFQItemQuery } =
    baseApiSlice.injectEndpoints({
        endpoints: (build) => ({
            fetchVendorListAddedToRFQItem: build.query<
                IAddedVendorList[],
                {
                    event_id: string;
                    rfq_item_id: string;
                    type: 'DIRECT' | 'FROM_EVENT';
                }
            >({
                queryFn: async ({ event_id, rfq_item_id, type }) => {
                    if (type === 'DIRECT') {
                        try {
                            let vendor_resp = await get<any[]>(
                                `/events/po_group/${event_id}/items/${rfq_item_id}/sellers/`
                            );
                            const convertToFrontend = vendor_resp.data.map(
                                (vendor) => {
                                    return {
                                        ...vendor.seller_entity,
                                        seller_contacts: vendor.seller_contacts,
                                    };
                                }
                            );
                            return {
                                data: convertToFrontend,
                            };
                        } catch (err: any) {
                            return {
                                error: err,
                            };
                        }
                    } else {
                        try {
                            let vendor_resp = await get<any[]>(
                                `/events/rfq/${event_id}/items/${rfq_item_id}/sellers/`
                            );
                            const convertToFrontend = vendor_resp.data.map(
                                (vendor) => {
                                    return {
                                        ...vendor.seller_entity,
                                        seller_contacts: vendor.seller_contacts,
                                    };
                                }
                            );
                            return {
                                data: convertToFrontend,
                            };
                        } catch (err: any) {
                            return {
                                error: err,
                            };
                        }
                    }
                },
            }),
        }),
    });

export const fetchExistingRFQsContainingItem = (
    buyer_item_id: string
): Promise<IRFQList[]> => {
    return new Promise(async (resolve, reject) => {
        try {
            let resp = await get<IRFQList[]>(
                `/events/rfq/?enterprise_type=BUYER&rfq_event_status=[ONGOING]&buyer_item_ids[]=${buyer_item_id}`
            );
            resolve(resp.data);
        } catch (err) {
            reject();
        }
    });
};

interface IPutRFQVendors {
    sellers_info: {
        seller_entity_id: string;
        vendor_contact_list: string[];
    }[];
    post_to_global_marketplace: boolean;
    action: 'UPDATE_LIVE_SELLER' | 'UPDATE_DRAFT_SELLER';
}

export const postRFQItemVendors = (
    event_id: string,
    item_id: string,
    post_to_marketPlace: boolean,
    vendors: {
        seller_entity_id: string;
        vendor_contact_list: string[];
    }[],
    type: 'DIRECT' | 'FROM_EVENT'
): Promise<{ error: boolean }> => {
    return new Promise(async (resolve, reject) => {
        try {
            if (type === 'DIRECT') {
                let vendor_resp = await put<{ seller_entity: string }, void>(
                    `/events/po_group/${event_id}/items/${item_id}/sellers/update/`,
                    {
                        seller_entity: vendors[0].seller_entity_id,
                        vendor_contact_list: vendors[0].vendor_contact_list,
                    }
                );
                if (vendor_resp.status >= 200 && vendor_resp.status < 300)
                    resolve({ error: false });
            } else {
                let dataToPost: IPutRFQVendors = {
                    sellers_info: vendors,
                    post_to_global_marketplace: post_to_marketPlace,
                    action: 'UPDATE_DRAFT_SELLER',
                };
                let vendor_resp = await put<IPutRFQVendors, void>(
                    `/events/rfq/${event_id}/items/${item_id}/sellers/update/`,
                    dataToPost
                );
                if (vendor_resp.status >= 200 && vendor_resp.status < 300)
                    resolve({ error: false });
            }
        } catch (err) {
            reject({ error: true });
        }
    });
};

export const getRfqApprovalLog = async (
    rfqEventEntryId: string
): Promise<IEventApproval[]> => {
    return new Promise(async (resolve, reject) => {
        try {
            const eventApprovalsResponse = await get<any>(
                `/events/rfq/${rfqEventEntryId}/approvers/`
            );
            const eventApprovalsList: any[] = eventApprovalsResponse.data;
            const eventApprovals: IEventApproval[] = [];

            eventApprovalsList.forEach((eventApproval) => {
                eventApprovals.push({
                    requestorId: eventApproval.requestor,
                    requestorUserFullName: eventApproval.requestor_name,
                    requestorNotes: eventApproval.requestor_notes,
                    approverId: eventApproval.approver,
                    approverUserFullName: eventApproval.approver_name,
                    approverNotes: eventApproval.approver_notes,
                    status: eventApproval.status,
                });
            });
            resolve(eventApprovals);
        } catch (err) {
            reject();
        }
    });
};

export const getNextApproverList = async (
    rfqEventEntryId: string
): Promise<INextApprover[]> => {
    const getAvailableApproversResponse = await get<any>(
        `/events/rfq/${rfqEventEntryId}/next_approvers/`
    );
    const nextApproverRes: any[] = getAvailableApproversResponse.data;
    const nextApproverList: INextApprover[] = [];
    nextApproverRes.forEach((availableApprover) => {
        nextApproverList.push({
            userId: availableApprover.user_id,
            userName: availableApprover.username,
            name: availableApprover.name,
            userRole: availableApprover.user_role,
        });
    });
    return nextApproverList;
};

export const getAllPOsForPOGroupItem = (
    po_group_item_id: string
): Promise<
    {
        po_id: string;
        custom_po_id: string;
        po_status: string;
        po_notes: string;
        po_last_action_notes: string;
        po_seller_notes: string;
    }[]
> => {
    return new Promise(async (resolve, reject) => {
        try {
            let resp = await get<any[]>(
                `/purchase_orders/po_group/po_group_item?po_group_item_id=${po_group_item_id}`
            );
            resolve(
                resp.data.map((po) => {
                    let newData = {
                        po_id: po.purchase_order_id,
                        custom_po_id: po.custom_purchase_order_id,
                        po_status: po.status,
                        po_notes: po.notes,
                        po_last_action_notes:
                            po.additional_information.last_action_information
                                .notes,
                        po_seller_notes: po.seller_notes,
                    };
                    return newData;
                })
            );
        } catch (err) {
            reject();
        }
    });
};

export const getEventApprovalLogApi = async (
    subEventId: string
): Promise<IEventApprovalLogRow[]> => {
    const eventApprovalsResponse = await get<any>(
        `/events/rfq/${subEventId}/approvers/`
    );
    const eventApprovalsList: any[] = eventApprovalsResponse.data;
    const eventApprovals: IEventApprovalLogRow[] = [];

    eventApprovalsList.forEach((eventApproval) => {
        eventApprovals.push({
            requestorId: eventApproval.requestor,
            requestorUserFullName: eventApproval.requestor_name,
            requestorNotes: eventApproval.requestor_notes,
            approverId: eventApproval.approver,
            approverUserFullName: eventApproval.approver_name,
            approverNotes: eventApproval.approver_notes,
            status: eventApproval.status,
        });
    });
    return eventApprovals;
};

export interface IUpdateEventAccessApi {
    user_permissions: {
        user_id: string;
        permissions: string[];
    }[];
    access?: 'ACCESS_DEFAULT' | 'ACCESS_RESTRICTED' | null;
}

export const updateEventAccessApi = async (
    rfqId: string,
    permission_obj: IUpdateEventAccessApi
): Promise<void> => {
    await put<string, any>(
        `/events/rfq/${rfqId}/permissions/update/`,
        permission_obj
    );
};

export const updateVendorContactForRFQItem = async (
    rfq_entry_id: string,
    rfq_item_entry_id: string,
    item_vendors: {
        seller_entity_id: string;
        vendor_contact_list: string[];
    }[],
    type: 'DIRECT' | 'FROM_EVENT',
    action: 'UPDATE_DRAFT_SELLER' | 'UPDATE_LIVE_SELLER' = 'UPDATE_DRAFT_SELLER'
) => {
    if (type === 'FROM_EVENT') {
        let updateUrl = `/events/rfq/${rfq_entry_id}/items/${rfq_item_entry_id}/sellers/contacts/update/`;
        await put<any, void>(updateUrl, {
            sellers_info: item_vendors,
            action: action,
        });
    }
};

export const updateSingleVendorContactForRFQItem = async (
    rfq_entry_id: string,
    rfq_item_entry_id: string,
    seller_entity_id: string,
    vendor_contact_list: string[]
) => {
    await put<any, void>(
        `/events/rfq/${rfq_entry_id}/items/${rfq_item_entry_id}/seller/${seller_entity_id}/update/`,
        {
            vendor_contact_list: vendor_contact_list,
            action: 'UPDATE_DRAFT_SELLER',
        }
    );
};

export type ArgumentsForPaginationApiCall =
    | {
          body: {
              dashboard_view: 'rfq_event_item_buyer';
              tab: 'all';
              sort_fields: any[];
              search_text: string;
              items_per_page: number;
              page_number: number;
              query_data: {
                  rfq_event_id: string;
                  seller_search?: string;
                  enterprise_bom_id?: string;
                  sub_bom_item_id?: string;
              };
          };
          otherInfoRequired: {
              entity_id: string;
              type: 'FROM_EVENT';
              searchQueryType: 'ITEMS' | 'VENDORS';
              poCount: number;
          };
      }
    | {
          body: {
              dashboard_view: 'po_group_item';
              tab: 'all';
              sort_fields: any[];
              search_text: string;
              items_per_page: number;
              page_number: number;
              query_data: {
                  po_group_id: string;
                  seller_search?: string;
                  enterprise_bom_id?: string;
                  sub_bom_item_id?: string;
              };
          };
          otherInfoRequired: {
              entity_id: string;
              type: 'DIRECT';
              searchQueryType: 'ITEMS' | 'VENDORS';
              poCount: number;
          };
      };

export const RFQPaginatedApiSlice = baseApiSlice.injectEndpoints({
    endpoints: (build) => ({
        getListOfRfqItemPaginated: build.mutation<
            {
                data: IEventItemDetails[];
                metadata: {
                    current_page: number;
                    has_next: boolean;
                    has_previous: boolean;
                    total_pages: number;
                    page_range: {
                        start: number;
                        stop: number;
                    };
                    count: number;
                };
                counts: {
                    all: number;
                };
            },
            {
                dashboard_view: 'rfq_event_item_buyer';
                tab: 'all';
                sort_fields: any[];
                search_text: string;
                items_per_page: number;
                page_number: number;
                query_data: {
                    rfq_event_id: string;
                };
            }
        >({
            query: (body) => ({
                url: `/dashboard/`,
                method: 'POST',
                body,
            }),
            transformResponse: (response: any) => {
                JsonNumsToStrings(response);

                response.data.forEach((item: any) => {
                    item.paymentTerms =
                        transformPaymentTermsFromBackendToFrontend({
                            prepayment_percentage: item.prepayment_percentage,
                            payment_terms: item.payment_terms,
                            deliverables_payment_terms:
                                item.deliverables_payment_terms,
                        });
                });

                return response;
            },
        }),
        getListOfRfqItemPaginatedQuery: build.query<
            {
                data: {
                    listOfItems: IEventItemDetails[];
                    all_po_items_are_fullfilled: boolean;
                    lastPoItems: {
                        [key: string]: IPurchaseOrderItem | null;
                    };
                    allItemTags: {
                        [key: string]: string[];
                    };
                    poItemsGroupedByItemEntry: {
                        [event_item_entry_id: string]: {
                            po_id: string;
                            custom_po_id: string;
                            po_status: string;
                            po_notes: string;
                            po_last_action_notes: string;
                            po_seller_notes: string;
                        }[];
                    };
                    measurementUnits: {
                        [key: string]: IMeasurementUnitDetails[];
                    };
                };
                metadata: {
                    current_page: number;
                    has_next: boolean;
                    has_previous: boolean;
                    total_pages: number;
                    page_range: {
                        start: number;
                        stop: number;
                    };
                    count: number;
                };
                counts: {
                    all: number;
                };
            },
            ArgumentsForPaginationApiCall
        >({
            queryFn: async ({ body, otherInfoRequired }) => {
                // if (
                //     store.getState().EventCreationStore.searchQueryType ===
                //     'VENDORS'
                // ) {
                //     body.query_data.seller_search = body.search_text;
                //     body.search_text = '';
                // }

                try {
                    const rfqItemsResponseItems: {
                        data: {
                            data: IEventItemDetails[];
                            metadata: {
                                current_page: number;
                                has_next: boolean;
                                has_previous: boolean;
                                total_pages: number;
                                page_range: {
                                    start: number;
                                    stop: number;
                                };
                                count: number;
                            };
                            counts: {
                                all: number;
                            };
                        };
                    } = await post('/dashboard/', {
                        ...body,
                        filter_bom_ds: false,
                    });

                    if (otherInfoRequired.type === 'DIRECT') {
                        for (let item of rfqItemsResponseItems.data.data) {
                            if (isEmpty(item.delivery_information)) {
                                item.delivery_information = [];
                            }

                            if (item.delivery_schedule)
                                for (let ds of item.delivery_schedule) {
                                    if (ds.bom_item) {
                                        ds.bom_item.bom_item_id =
                                            ds.bom_item.entry_id;
                                    }
                                }

                            item.rfq_item_entry_id = (
                                item as any
                            ).po_group_item_entry_id;
                        }
                    }

                    rfqItemsResponseItems.data.data.forEach((item: any) => {
                        item.paymentTerms =
                            transformPaymentTermsFromBackendToFrontend({
                                prepayment_percentage:
                                    item.prepayment_percentage,
                                payment_terms: item.payment_terms,
                                deliverables_payment_terms:
                                    item.deliverables_payment_terms,
                            });
                    });

                    // this is copied from rfq item hook gotta understand things and refactor it later
                    // let enterprise_data_promise_arr: Promise<IBuyerEnterpriseItemDetails>[] =
                    //     [];
                    let last_po_data_promise_arr: Promise<IPurchaseOrderItem | null>[] =
                        [];
                    let last_po_data_of_this_currency_and_unit_promise_arr: Promise<IPurchaseOrderItem | null>[] =
                        [];
                    let allItemTags: { [key: string]: string[] } = {};
                    let pos_for_each_po_group_item: Promise<
                        {
                            po_id: string;
                            custom_po_id: string;
                            po_status: string;
                            po_notes: string;
                            po_last_action_notes: string;
                            po_seller_notes: string;
                        }[]
                    >[] = [];
                    let event_item_entry_id: string[] = [];
                    let _all_po_items_are_fullfilled = true;
                    for (let item of rfqItemsResponseItems.data.data) {
                        event_item_entry_id.push(item.rfq_item_entry_id);

                        if (otherInfoRequired.type === 'DIRECT') {
                            pos_for_each_po_group_item.push(
                                getAllPOsForPOGroupItem(item.rfq_item_entry_id)
                            );
                        }
                        if (otherInfoRequired.poCount > 0) {
                            last_po_data_promise_arr.push(
                                getLatestPoItem(
                                    item.enterprise_item,
                                    null,
                                    null
                                )
                            );
                            last_po_data_of_this_currency_and_unit_promise_arr.push(
                                getLatestPoItem(
                                    item.enterprise_item,
                                    item.pricing_information.currency_code_id,
                                    item.quantity_information
                                        .measurement_unit_id
                                )
                            );
                        }
                        if (
                            (!item.fulfilment_information
                                .po_item_accepted_quantity ||
                                +item.fulfilment_information
                                    .po_item_accepted_quantity === 0) &&
                            +item.fulfilment_information
                                .po_item_issued_quantity === 0 &&
                            +item.fulfilment_information
                                .po_item_approval_pending_quantity === 0
                        ) {
                            _all_po_items_are_fullfilled = false;
                        }
                    }

                    // setAllPOItemsAreFullfilled(_all_po_items_are_fullfilled);

                    // getting last PO details for each item
                    const lastPoItems: {
                        [key: string]: IPurchaseOrderItem | null;
                    } = {};
                    await Promise.all([
                        Promise.all(last_po_data_promise_arr),
                        Promise.all(
                            last_po_data_of_this_currency_and_unit_promise_arr
                        ),
                    ])
                        .then(([po_data_any_curr, po_data_this_curr]) => {
                            // setLastPoItems((prev) => {
                            //     let newObj = cloneDeep(prev);
                            for (
                                let po_idx = 0;
                                po_idx < po_data_any_curr.length;
                                po_idx++
                            ) {
                                lastPoItems[event_item_entry_id[po_idx]] =
                                    po_data_this_curr[po_idx] ??
                                    po_data_any_curr[po_idx];
                            }
                            //     return newObj;
                            // });
                        })
                        .catch((err) => {});
                    // if d2PO is enabled, we need to get all the POs for each PO Group Item
                    let poItemsGroupedByItemEntry: {
                        [key: string]: {
                            po_id: string;
                            custom_po_id: string;
                            po_status: string;
                            po_notes: string;
                            po_last_action_notes: string;
                            po_seller_notes: string;
                        }[];
                    } = {};

                    if (otherInfoRequired.type === 'DIRECT') {
                        // fetching data for all this po this item to see the it exists or not
                        const po_data = await Promise.all(
                            pos_for_each_po_group_item
                        );

                        let obj: {
                            [key: string]: {
                                po_id: string;
                                custom_po_id: string;
                                po_status: string;
                                po_notes: string;
                                po_last_action_notes: string;
                                po_seller_notes: string;
                            }[];
                        } = {};

                        po_data.forEach((po_items, idx) => {
                            if (po_items.length > 0) {
                                obj[
                                    rfqItemsResponseItems.data.data[
                                        idx
                                    ].rfq_item_entry_id
                                ] = po_items;
                            }
                        });
                        poItemsGroupedByItemEntry = obj;
                    }
                    //We want to await ALL promise resolution before setting data state
                    // getting enterprise details for each item to get their Measurement Units
                    const measurementUnits: {
                        [key: string]: IMeasurementUnitDetails[];
                    } = {};
                    const multipleItemsDetails =
                        rfqItemsResponseItems.data.data.length > 0
                            ? await fetchBuyerEnterpriseMultipleItemDetails(
                                  rfqItemsResponseItems.data.data.map(
                                      (item) => item.enterprise_item
                                  ),
                                  otherInfoRequired.entity_id
                              )
                            : {};

                    event_item_entry_id.forEach(
                        (event_item_entry_single_id, evnt_idx) => {
                            measurementUnits[event_item_entry_single_id] =
                                multipleItemsDetails[
                                    rfqItemsResponseItems.data.data[
                                        evnt_idx
                                    ].enterprise_item
                                ].measurement_units.item_measurement_units;

                            allItemTags[event_item_entry_single_id] =
                                multipleItemsDetails[
                                    rfqItemsResponseItems.data.data[
                                        evnt_idx
                                    ].enterprise_item
                                ].tags;
                        }
                    );

                    return {
                        data: {
                            counts: rfqItemsResponseItems.data.counts,
                            metadata: rfqItemsResponseItems.data.metadata,
                            data: {
                                listOfItems: rfqItemsResponseItems.data.data,
                                all_po_items_are_fullfilled:
                                    _all_po_items_are_fullfilled,
                                lastPoItems,
                                allItemTags,
                                measurementUnits,
                                poItemsGroupedByItemEntry,
                            },
                        },
                    };
                } catch (error: any) {
                    console.error('', error);
                    return { error: error };
                }
            },
            keepUnusedDataFor: 900,
        }),
    }),
});

// const getItemCount = async () => {
//     getItemCount: build.query<

//     {
//         rfq_bid_id: string;
//         seller_entity_id: string;
//     }
// >({
//     query: ({ rfq_bid_id, seller_entity_id }) => ({
//         url: '/dashboard/tab_counts/',
//         method: 'POST',
//         body: {
//             dashboard_view: 'rfq_bid_item_seller',
//             query_data: {
//                 rfq_bid_id,
//                 seller_entity_id,
//             },
//         },
//     }),
// }),
// }

export const getItemCount = async (
    rfq_bid_id: string,
    seller_entity_id: string
) => {
    let body = {
        dashboard_view: 'rfq_bid_item_seller',
        query_data: { rfq_bid_id, seller_entity_id },
    };

    try {
        let res = await post<
            {
                tab_counts: {
                    removed: number;
                    all: number;
                };
            },
            {}
        >(`/dashboard/tab_counts/`, body);

        return res.data;
    } catch (e) {}
};

export const RFQ_ITEM_PER_PAGE = 10;
export const {
    useGetRFQDetailsQuery,
    useGetRFQItemDetailsQuery,
    useGetSellerRFQBidsQuery,
} = baseApiSlice.injectEndpoints({
    endpoints: (build) => ({
        getRFQDetails: build.query<
            ISellerRFQDetails,
            {
                rfq_id: string;
                entity_id: string;
            }
        >({
            query: ({ rfq_id, entity_id }) => ({
                url: `/events/rfq/${rfq_id}/?enterprise_type=SELLER&seller_entity_id=${entity_id}`,

                method: 'GET',
            }),
        }),

        getRFQItemDetails: build.query<
            {
                data: IGetRFQItemDetails[]; // depends on the data obtained from dashboard api
                metadata: {
                    current_page: number;
                    has_next: boolean;
                    has_previous: boolean;
                    total_pages: number;
                    page_range: {
                        start: number;
                        stop: number;
                    };
                    count: number;
                };
                counts: {
                    all: number;
                };
            },
            {
                dashboard_view: 'rfq_event_item_seller'; // change this url as per the dashboard api for fetchRFQItemDetails()
                tab: 'all';
                sort_fields: any[];
                search_text: string;
                items_per_page: number;
                page_number: number;
                query_data: {
                    rfq_event_id: string;
                    seller_entity_id: string;
                };
            }
        >({
            query: (body) => ({
                url: `/dashboard/`,
                method: 'POST',
                body,
            }),
            transformResponse: (response: any) => {
                // JsonNumsToStrings(response);

                return response;
            },
        }),
        getSellerRFQBids: build.query<
            {
                data: IGetSellerRFQBid[]; // depends on the data obtained from dashboard api
                metadata: {
                    current_page: number;
                    has_next: boolean;
                    has_previous: boolean;
                    total_pages: number;
                    page_range: {
                        start: number;
                        stop: number;
                    };
                    count: number;
                };
                counts: {
                    all: number;
                };
            },
            {
                dashboard_view: 'rfq_bid_item_seller'; // change this url as per the dashboard api for fetchRFQItemDetails()
                tab: 'all'; // excluded
                sort_fields: any[];
                search_text: string;
                items_per_page: number;
                page_number: number;
                query_data: {
                    rfq_bid_id: string;
                    seller_entity_id: string;
                };
            }
        >({
            query: (body) => ({
                url: `/dashboard/`,
                method: 'POST',
                body,
            }),
            transformResponse: (response: any) => {
                // JsonNumsToStrings(response);

                return response;
            },
        }),
    }),
});
export const {
    useGetListOfRfqItemPaginatedMutation,
    useGetListOfRfqItemPaginatedQueryQuery,
    useLazyGetListOfRfqItemPaginatedQueryQuery,
    endpoints: { getListOfRfqItemPaginatedQuery },
} = RFQPaginatedApiSlice;
export const updateCachedDataForRfqItemPaginated =
    RFQPaginatedApiSlice.util.updateQueryData;

export interface IRfqSellerBackendResponse {
    po_group_item_id: string;
    rfq_item_id: string; // this the same as rfq_item_entry_id
    seller_entity: {
        entity_id: string;
        entity_name: string;
        entity_logo: Array<{
            attachment_id: string;
            attachment_type: string;
            storage_id: string;
            resource_id: string;
            file_name: string;
            attachment_status: string;
        }>;
        enterprise_name: string;
        contacts: {
            emails: Array<any>;
            websites: Array<string>;
            phone_numbers: Array<any>;
        };
        is_virtual: boolean;
        verification_status: VerificationStatus;
    };
    seller_contacts: Array<{
        vendor_contact_id: string;
        buyer_entity: string;
        seller_entity: string;
        enterprise_vendor_master: {
            vendor_code: string;
        };
        full_name: string;
        primary_email: string;
        emails: Array<{
            type: string;
            email: string;
        }>;
        phone_numbers: Array<string>;
        tags: Array<any>;
        is_primary: boolean;
        user: string;
        status: string;
    }>;
}

export const mergedSellersApiSlice = baseApiSlice.injectEndpoints({
    endpoints: (build) => ({
        getListOfAllSellersForAllRfqItems: build.query<
            {
                [rfq_item_id: string]: IRfqSellerBackendResponse[];
            },
            { rfq_id: string }
        >({
            // query: ({ rfq_id }) => `events/rfq/${rfq_id}/items/sellers`,

            // transformResponse(
            //     baseQueryReturnValue: IRfqSellerBackendResponse[]
            // ) {
            //     let response: {
            //         [rfq_item_id: string]: IRfqSellerBackendResponse[];
            //     } = {};
            //     baseQueryReturnValue.forEach((seller) => {
            //         if (seller.rfq_item_id in response) {
            //             response[seller.rfq_item_id].push({ ...seller });
            //         } else {
            //             response[seller.rfq_item_id] = [seller];
            //         }
            //     });

            //     return response;
            // },
            // {
            //     "dashboard_view": "rfq_event_items_sellers",
            //     "items_per_page": 200,
            //     "page_number": 1,
            //     "search_text": "",
            //     "sort_fields": [],
            //     "query_data": {
            //         "rfq_event_id": "4b300b22-154b-46fa-bffe-aa76725114ea"
            //     },
            //     "tab": "all"
            // }
            queryFn: async ({ rfq_id }) => {
                try {
                    const data: IRfqSellerBackendResponse[] = [];

                    let curResp: AxiosResponse<{
                        data: IRfqSellerBackendResponse[]; // depends on the data obtained from dashboard api
                        metadata: {
                            current_page: number;
                            has_next: boolean;
                            has_previous: boolean;
                            total_pages: number;
                            page_range: {
                                start: number;
                                stop: number;
                            };
                            count: number;
                        };
                        counts: {
                            all: number;
                        };
                    }> = await post<any, any>(`/dashboard/`, {
                        dashboard_view: 'rfq_event_items_sellers',
                        items_per_page: 200,
                        page_number: 1,
                        search_text: '',
                        sort_fields: [],
                        query_data: {
                            rfq_event_id: rfq_id,
                        },
                        tab: 'all',
                    });
                    data.push(
                        ...curResp.data.data.filter(
                            (v) => v.seller_entity !== null
                        )
                    );

                    let page = 2;

                    while (curResp.data.metadata.has_next) {
                        curResp = await post<any, any>(`/dashboard/`, {
                            dashboard_view: 'rfq_event_items_sellers',
                            items_per_page: 200,
                            page_number: page,
                            search_text: '',
                            sort_fields: [],
                            query_data: {
                                rfq_event_id: rfq_id,
                            },
                            tab: 'all',
                        });
                        data.push(
                            ...curResp.data.data.filter(
                                (v) => v.seller_entity !== null
                            )
                        );
                        page++;
                    }

                    let response: {
                        [rfq_item_id: string]: IRfqSellerBackendResponse[];
                    } = {};
                    data.forEach((seller) => {
                        if (seller.rfq_item_id in response) {
                            response[seller.rfq_item_id].push({ ...seller });
                        } else {
                            response[seller.rfq_item_id] = [seller];
                        }
                    });

                    return { data: response };
                } catch (err: any) {
                    return {
                        error: err,
                    };
                }
            },
        }),
        getListOfAllSellersForAllRfqItemsNoModification: build.query<
            IRfqSellerBackendResponse[],
            { rfq_id: string }
        >({
            // query: ({ rfq_id }) => `events/rfq/${rfq_id}/items/sellers`,
            queryFn: async ({ rfq_id }) => {
                try {
                    const data: IRfqSellerBackendResponse[] = [];

                    let curResp: AxiosResponse<{
                        data: IRfqSellerBackendResponse[]; // depends on the data obtained from dashboard api
                        metadata: {
                            current_page: number;
                            has_next: boolean;
                            has_previous: boolean;
                            total_pages: number;
                            page_range: {
                                start: number;
                                stop: number;
                            };
                            count: number;
                        };
                        counts: {
                            all: number;
                        };
                    }> = await post<any, any>(`/dashboard/`, {
                        dashboard_view: 'rfq_event_items_sellers',
                        items_per_page: 200,
                        page_number: 1,
                        search_text: '',
                        sort_fields: [],
                        query_data: {
                            rfq_event_id: rfq_id,
                        },
                        tab: 'all',
                    });
                    data.push(
                        ...curResp.data.data.filter(
                            (v) => v.seller_entity !== null
                        )
                    );

                    let page = 2;

                    while (curResp.data.metadata.has_next) {
                        curResp = await post<any, any>(`/dashboard/`, {
                            dashboard_view: 'rfq_event_items_sellers',
                            items_per_page: 200,
                            page_number: page,
                            search_text: '',
                            sort_fields: [],
                            query_data: {
                                rfq_event_id: rfq_id,
                            },
                            tab: 'all',
                        });
                        data.push(
                            ...curResp.data.data.filter(
                                (v) => v.seller_entity !== null
                            )
                        );
                        page++;
                    }

                    // let response: {
                    //     [rfq_item_id: string]: IRfqSellerBackendResponse[];
                    // } = {};
                    // data.forEach((seller) => {
                    //     if (seller.rfq_item_id in response) {
                    //         response[seller.rfq_item_id].push({ ...seller });
                    //     } else {
                    //         response[seller.rfq_item_id] = [seller];
                    //     }
                    // });

                    return { data: data };
                } catch (err: any) {
                    return {
                        error: err,
                    };
                }
            },
        }),
        getListOfAllSellersForAllD2POItems: build.query<
            {
                [rfq_item_id: string]: IRfqSellerBackendResponse[];
            },
            { po_group_id: string }
        >({
            query: ({ po_group_id }) =>
                `events/po_group/${po_group_id}/items/sellers`,
            transformResponse(
                baseQueryReturnValue: IRfqSellerBackendResponse[]
            ) {
                let response: {
                    [rfq_item_id: string]: IRfqSellerBackendResponse[];
                } = {};
                baseQueryReturnValue.forEach((seller) => {
                    if (seller.po_group_item_id in response) {
                        response[seller.po_group_item_id].push(seller);
                    } else {
                        response[seller.po_group_item_id] = [seller];
                    }
                });

                return response;
            },
        }),
    }),
});

export const {
    useGetListOfAllSellersForAllRfqItemsQuery,
    useGetListOfAllSellersForAllD2POItemsQuery,
    useGetListOfAllSellersForAllRfqItemsNoModificationQuery,
    useLazyGetListOfAllSellersForAllRfqItemsNoModificationQuery,
} = mergedSellersApiSlice;

export const { useGetSingleConfigurationFromAdminQuery } =
    baseApiSlice.injectEndpoints({
        endpoints: (build) => ({
            getSingleConfigurationFromAdmin: build.query<
                IBomConfiugrationDetailsResponeFromAdmin,
                { BomUId: string }
            >({
                query: ({ BomUId }) => `/organization/bom/${BomUId}/admin/`,
            }),
        }),
    });

export interface IBomConfiugrationDetailsResponeFromAdmin {
    enterprise_bom_id: string;
    bom_items: IBomItemConfiugrationDetailsResponeFromAdmin[];
    enterprise_item: EnterpriseItem2;
    entities: Entity2[];
    created_datetime: string;
    modified_datetime: string;
    deleted_datetime: any;
    bom_code: string;
    base_bom_id: string;
    quantity: any;
    total: number;
    locations: any[];
    is_primary: boolean;
    status: string;
    bom_status: string;
    notes: any;
    is_subcontract: boolean;
    additional_details: AdditionalDetails2;
    custom_fields: CustomFields3;
    created_by_user: string;
    modified_by_user: string;
    deleted_by_user: any;
    buyer_enterprise: string;
    measurement_unit: any;
    currency: string;
}

export interface IBomItemConfiugrationDetailsResponeFromAdmin {
    bom_item_id: string;
    sub_bom: IBomItemSubBomItemConfiugrationDetailsResponeFromAdmin | null;
    sub_bom_items: IBomItemConfiugrationDetailsResponeFromAdmin[] | null;
    raw_material_item: IBomRawMatrialItemConfiugrationDetailsResponeFromAdmin | null;
    alternates: any[];
    created_datetime: string;
    modified_datetime: string;
    deleted_datetime: any;
    base_bom_item_id: string;
    description: any;
    quantity: number;
    cost_per_unit: number;
    subtotal: number;
    is_coproduct: boolean;
    is_bulk_material: boolean;
    is_alternate: boolean;
    custom_fields: CustomFields2;
    created_by_user: string;
    modified_by_user: string;
    deleted_by_user: any;
    measurement_unit: string;
    enterprise_bom: string;
    parent_sub_bom_item: any;
    parent_bom_item: any;
}

export interface IBomItemSubBomItemConfiugrationDetailsResponeFromAdmin {
    enterprise_bom_id: string;
    enterprise_item: EnterpriseItem;
    entities: Entity[];
    created_datetime: string;
    modified_datetime: string;
    deleted_datetime: any;
    bom_code: string;
    base_bom_id: string;
    quantity: number;
    total: any;
    locations: any[];
    is_primary: boolean;
    status: string;
    bom_status: string;
    notes: any;
    is_subcontract: boolean;
    additional_details: AdditionalDetails;
    custom_fields: CustomFields;
    created_by_user: string;
    modified_by_user: string;
    deleted_by_user: any;
    buyer_enterprise: string;
    measurement_unit: string;
    currency: string;
}

export interface EnterpriseItem {
    enterprise_item_id: string;
    code: string;
    description: string;
    item_type: string;
    attributes: Attributes;
    custom_ids: CustomIds;
    tags: any[];
    measurement_units: MeasurementUnits;
    name: string;
    is_buyer: boolean;
    buyer_pricing_information: BuyerPricingInformation;
    buyer_additional_costs: any[];
    buyer_taxes: any[];
    is_seller: boolean;
    seller_pricing_information: any;
    seller_additional_costs: any[];
    seller_taxes: any[];
    status: string;
    preferred_vendor_status: string;
}

export interface Attributes {
    attributes: any[];
}

export interface CustomIds {
    custom_ids: any[];
}

export interface MeasurementUnits {
    item_measurement_units: ItemMeasurementUnit[];
}

export interface ItemMeasurementUnit {
    abbreviation: string;
    measurement_unit_id: string;
    measurement_unit_category: string;
    measurement_unit_value_type: string;
    measurement_unit_primary_name: string;
}

export interface BuyerPricingInformation {}

export interface Entity {
    buyer_entity_id: string;
    buyer_entity_name: string;
}

export interface AdditionalDetails {
    template_id: string;
}

export interface CustomFields {
    section_list: any[];
}

export interface IBomRawMatrialItemConfiugrationDetailsResponeFromAdmin {
    enterprise_item_id: string;
    code: string;
    description: string;
    item_type: string;
    attributes: Attributes2;
    custom_ids: CustomIds2;
    tags: string[];
    measurement_units: MeasurementUnits2;
    name: string;
    is_buyer: boolean;
    buyer_pricing_information: BuyerPricingInformation2;
    buyer_additional_costs: any[];
    buyer_taxes: any[];
    is_seller: boolean;
    seller_pricing_information?: SellerPricingInformation;
    seller_additional_costs: any[];
    seller_taxes: any[];
    status: string;
    preferred_vendor_status: string;
}

export interface Attributes2 {
    attributes: Attribute[];
}

export interface Attribute {
    attribute_name: string;
    attribute_value: string[];
    attribute_exclude: boolean;
}

export interface CustomIds2 {
    custom_ids: CustomId[];
}

export interface CustomId {
    name: string;
    value: string;
}

export interface MeasurementUnits2 {
    item_measurement_units: ItemMeasurementUnit2[];
}

export interface ItemMeasurementUnit2 {
    abbreviation: string;
    measurement_unit_id: string;
    measurement_unit_category: string;
    measurement_unit_value_type: string;
    measurement_unit_primary_name: string;
}

export interface BuyerPricingInformation2 {}

export interface SellerPricingInformation {}

export interface CustomFields2 {
    section_list: any[];
}

export interface EnterpriseItem2 {
    enterprise_item_id: string;
    code: string;
    description: string;
    item_type: string;
    attributes: Attributes3;
    custom_ids: CustomIds3;
    tags: any[];
    measurement_units: MeasurementUnits3;
    name: string;
    is_buyer: boolean;
    buyer_pricing_information: BuyerPricingInformation3;
    buyer_additional_costs: any[];
    buyer_taxes: any[];
    is_seller: boolean;
    seller_pricing_information: any;
    seller_additional_costs: any[];
    seller_taxes: any[];
    status: string;
    preferred_vendor_status: string;
}

export interface Attributes3 {
    attributes: any[];
}

export interface CustomIds3 {
    custom_ids: any[];
}

export interface MeasurementUnits3 {
    item_measurement_units: ItemMeasurementUnit3[];
}

export interface ItemMeasurementUnit3 {
    abbreviation: string;
    measurement_unit_id: string;
    measurement_unit_category: string;
    measurement_unit_value_type: string;
    measurement_unit_primary_name: string;
}

export interface BuyerPricingInformation3 {}

export interface Entity2 {
    buyer_entity_id: string;
    buyer_entity_name: string;
}

export interface AdditionalDetails2 {
    template_id: string;
}

export interface CustomFields3 {
    section_list: any[];
}
