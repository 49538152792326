import { cloneDeep } from 'lodash';
import React, { useMemo } from 'react';
import { batch, useDispatch, useSelector } from 'react-redux';
import {
    convertAdditionalCostBackendToFrontend,
    convertAdditionalCostBackendToFrontendSingle,
} from '../../../AdditionalCost/helpers';
import {
    CostSourceEnum,
    CostTypeEnum,
} from '../../../AdditionalCost/models/AdditionalCost.model';
import { getContractDetailsForItem } from '../../../CLM/Services/CLM.service';
import { useRfqTemplate } from '../../../Global/Hooks/useRfqTemplate';
import {
    IPaymentTerms,
    StandardPaymentReferenceDateEnum,
} from '../../../Global/Interfaces/PaymentTermsInterface';
import { PeriodEnum } from '../../../Global/Interfaces/PeriodInterface';
import {
    ITemplatePaymentTermsOptions,
    TemplateStandardFieldsEnum,
} from '../../../Global/Interfaces/TemplateInterface';
import { IPurchaseOrderItem } from '../../../Models/AwardsAndPO.model';
import { IIncotermDetails } from '../../../Models/Incoterms.model';
import { IMeasurementUnitDetails } from '../../../Models/MeasurementUnit.model';
import { useGetAdminSettings } from '../../../Organizations/Admin/Hooks/AdminSettingsHook';
import { ASModuleSetting } from '../../../Organizations/Admin/Interfaces/AdminSettingsInterface';
import { IGlobalAppStore, store } from '../../../Redux/Store';
import { getLatestPoItem } from '../../../Services/purchaseOrders/purchaseOrderOperations';
import { get } from '../../../Utils/api';
import {
    autoFillAdditionalCost,
    translateEnterpriseItemToRFQItem,
    translateRFQItemDetailsToCustomDetails,
    translateRFQItemDetailsToCustomDetailsWithTemplates,
} from '../../Constants/Buyer/RFQHelperFunctions';
import { ICustomEventItemDetails } from '../../Interfaces/Buyer/RFQ.model';
import {
    ArgumentsForPaginationApiCall,
    IBackendVendorMasterMultipleItemResponse,
    RFQPaginatedApiSlice,
    RFQ_ITEM_PER_PAGE,
    fetchAllVendorsForRFQItem,
    fetchBuyerEnterpriseItemDetails,
    fetchItemAdditionalCostList,
    getManyToManyCurrencyConversion,
    postRFQItem,
    postRFQItemVendors,
    useGetListOfAllSellersForAllD2POItemsQuery,
    useGetListOfAllSellersForAllRfqItemsQuery,
} from '../../Services/Buyer/RFQ.service';
import { updateEventDataStore } from '../../Slices/EventCreationSlice';

export default function useAddBuyerMasterItemHook(
    handleClose: () => void,
    incoterms: IIncotermDetails[],
    type: 'FROM_EVENT' | 'DIRECT',
    onSuccess: (
        new_item_event_entry_id: string,
        last_po_details_of_item: IPurchaseOrderItem | null,
        measurement_units: IMeasurementUnitDetails[],
        newItem: ICustomEventItemDetails
    ) => void,
    entity_id: string,
    fromPoRevise: boolean = false
) {
    const [customAllowPriceSharingSetting] = useGetAdminSettings(
        entity_id,
        ASModuleSetting.EVENT_ALLOW_SHARE_TARGET_PRICE
    );
    const { event_details, template_custom_field_sections } = useSelector(
        (state: IGlobalAppStore) => ({
            event_details: state.EventCreationStore.eventDetails,
            template_custom_field_sections:
                state.EventCreationStore.template_custom_field_sections,
        })
    );
    const { refetch: refetchSellerRFQ } =
        useGetListOfAllSellersForAllRfqItemsQuery(
            {
                rfq_id: event_details?.sub_event_id
                    ? event_details?.sub_event_id
                    : '',
            },
            {
                skip:
                    event_details?.sub_event_id === null ||
                    event_details?.sub_event_id === '' ||
                    type === 'DIRECT',
            }
        );
    const { refetch: refetchSellerD2PO } =
        useGetListOfAllSellersForAllD2POItemsQuery(
            {
                po_group_id: event_details?.sub_event_id
                    ? event_details?.sub_event_id
                    : '',
            },
            {
                skip:
                    event_details?.sub_event_id === null ||
                    event_details?.sub_event_id === '' ||
                    type === 'FROM_EVENT',
            }
        );
    const { templateDetails } = useRfqTemplate(
        event_details?.template_id ?? 'DEFAULT',
        event_details?.entity_id ?? ''
    );

    const templatePaymentTerms =
        templateDetails.fieldSettings.standardFields.PAYMENT_TERMS;

    const blankPaymentTerms: IPaymentTerms = useMemo(() => {
        const templatePT = templatePaymentTerms.paymentTermOptions;

        let hasStandardPaymentTerms: boolean = false;
        let standardTerms: StandardPaymentReferenceDateEnum =
            StandardPaymentReferenceDateEnum.INVOICE_DATE;
        if (templatePT.paymentType === 'invoiceItemValues') {
            if (templatePT.standardReferenceDateOptions.INVOICE_DATE.enabled) {
                hasStandardPaymentTerms = true;
                standardTerms = StandardPaymentReferenceDateEnum.INVOICE_DATE;
            } else if (
                templatePT.standardReferenceDateOptions.DISPATCH_DATE.enabled
            ) {
                hasStandardPaymentTerms = true;
                standardTerms = StandardPaymentReferenceDateEnum.DISPATCH_DATE;
            } else if (
                templatePT.standardReferenceDateOptions.RECEIPT_DATE.enabled
            ) {
                hasStandardPaymentTerms = true;
                standardTerms = StandardPaymentReferenceDateEnum.RECEIPT_DATE;
            }
        }

        return templatePT.paymentType === 'invoiceItemValues'
            ? {
                  paymentType: 'invoiceItemValues',
                  prepaymentPercentage: '0',
                  terms: '1',
                  period: PeriodEnum.MONTHS,
                  appliedFrom: hasStandardPaymentTerms
                      ? {
                            referenceDateCategory: 'STANDARD',
                            referenceDateLabel: standardTerms,
                        }
                      : {
                            referenceDateCategory: 'CUSTOM',
                            referenceDateLabel:
                                templatePT.customReferenceDateOptions[0],
                        },
              }
            : {
                  paymentType: 'poItemPercentages',
                  prepaymentPercentage: '0',
                  list: [
                      {
                          poItemPercentage: '100',
                          milestoneType: 'DELIVERABLE',
                          deliverableName: templatePT.deliverableNameOptions[0],
                          terms: '30',
                          period: PeriodEnum.DAYS,
                      },
                  ],
              };
    }, [templatePaymentTerms.paymentTermOptions]);

    const FILTERED_INCOTERMS = incoterms.filter(
        (inco) => inco.incoterm_abbreviation === 'NA'
    )[0];
    const NA_INCOTERM_UUID = FILTERED_INCOTERMS
        ? FILTERED_INCOTERMS.entry_id
        : '';
    const [dataState, setDataState] = React.useState({
        loading: false,
        error: false,
    });
    const [showBomPopup, setShowBomPopup] = React.useState<null | string>(null);
    const dispatch = useDispatch();
    const showWarnings = useSelector(
        (store: IGlobalAppStore) =>
            store.EventCreationStore.eventDetails?.showWarnings ?? false
    );
    const selectedBomForFiltering = useSelector(
        (store: IGlobalAppStore) =>
            store.EventCreationStore.selectedBomForFiltering
    );
    const selectedSubBomForFiltering = useSelector(
        (store: IGlobalAppStore) =>
            store.EventCreationStore.selectedSubBomForFiltering
    );
    const arePoAvailable = useSelector(
        (store: IGlobalAppStore) => store.EventCreationStore.arePoAvailable
    );

    // function runs when user selects an item from the add item popup
    // The items enterprise info is fetched
    // then this data is saved as the new rfq item and we get the rfq_item)entry_id
    // then we fetch the last po info for this item and use it to intialize the rest
    // of rfq_item data (why there are 2 calls for this ask AF or NP)
    // finally we save the data for the rfq item and update the store
    const fetchAndLoadSelectedItemDetails = async (
        enterprise_item_id: string,
        isFinishedGood: boolean
    ) => {
        if (
            isFinishedGood &&
            templateDetails.fieldSettings.standardFields[
                TemplateStandardFieldsEnum.BOM
            ].buyerSettings.showField
        ) {
            setShowBomPopup(enterprise_item_id);
        } else if (
            event_details !== null &&
            template_custom_field_sections !== null
        ) {
            // if all the items from before were already fullfilled
            // since we're adding a new item the state should become false

            // otherwise it's all good
            setDataState({ loading: true, error: false });
            try {
                // 2 getLatestPoItem API calls are done to handle two case:
                // 1. if last PO item with same currency exists copy all details
                // 2. if last PO item with different currency exists copy everythong except price and currency
                const allSettledPromises = await Promise.allSettled([
                    fetchBuyerEnterpriseItemDetails(
                        enterprise_item_id,
                        event_details.entity_id
                    ),
                    fetchAllVendorsForRFQItem(entity_id, enterprise_item_id), // preferred vendors API
                    getContractDetailsForItem(entity_id, enterprise_item_id),
                    get<IBackendVendorMasterMultipleItemResponse[]>(
                        `/organization/vendor_master/entity/${entity_id}/items/?item_ids[]=${enterprise_item_id}`
                    ), // vendor contacts
                    fetchItemAdditionalCostList(),
                ]);
                // The first 2 API calls should definitely be fulfilled
                if (
                    allSettledPromises[0].status === 'rejected' ||
                    allSettledPromises[1].status === 'rejected' ||
                    allSettledPromises[2].status === 'rejected' ||
                    allSettledPromises[3].status === 'rejected' ||
                    allSettledPromises[4].status === 'rejected'
                ) {
                    throw new Error(
                        "Couldn't fetch required information to create item"
                    );
                } else {
                    let po_item_details: IPurchaseOrderItem | null = null;
                    if (arePoAvailable) {
                        const poDataSettledPromises = await Promise.allSettled([
                            getLatestPoItem(
                                enterprise_item_id,
                                event_details.currency_id,
                                allSettledPromises[0].value.measurement_units
                                    .item_measurement_units[0]
                                    ? allSettledPromises[0].value
                                          .measurement_units
                                          .item_measurement_units[0]
                                          .measurement_unit_id
                                    : null,
                                templatePaymentTerms.paymentTermOptions
                                    .paymentType === 'invoiceItemValues'
                                    ? 'PER_INVOICE_ITEM'
                                    : 'PER_DELIVERABLE'
                            ),
                            getLatestPoItem(
                                enterprise_item_id,
                                null,
                                null,
                                templatePaymentTerms.paymentTermOptions
                                    .paymentType === 'invoiceItemValues'
                                    ? 'PER_INVOICE_ITEM'
                                    : 'PER_DELIVERABLE'
                            ),
                        ]);
                        if (
                            poDataSettledPromises[0].status === 'fulfilled' &&
                            poDataSettledPromises[0].value !== null
                        ) {
                            po_item_details = poDataSettledPromises[0].value;
                        } else if (
                            poDataSettledPromises[1].status === 'fulfilled'
                        ) {
                            po_item_details = poDataSettledPromises[1].value;
                        }
                    }

                    const [
                        itemDirectoryDetails,
                        itemVendorsFromAdmin,
                        additionalCostInformation,
                    ] = [
                        allSettledPromises[0].value,
                        allSettledPromises[1].value,
                        allSettledPromises[4].value,
                    ];

                    const costsToBeAutofilled =
                        additionalCostInformation?.filter((adminAC) =>
                            templateDetails?.fieldSettings?.standardFields?.[
                                TemplateStandardFieldsEnum.ADDITIONAL_COSTS
                            ]?.additionalCostOptions?.some(
                                (itemAC) =>
                                    itemAC.additional_cost_id ===
                                    adminAC.additional_cost_id
                            )
                        );

                    const currencyOfCostsWithSourceNonePayload =
                        costsToBeAutofilled
                            .filter(
                                (cost) =>
                                    cost.cost_type ===
                                        CostTypeEnum.ABSOLUTE_VALUE &&
                                    cost.cost_source === CostSourceEnum.NONE
                            )
                            ?.map((cost) => cost.currency)
                            ?.filter(Boolean)
                            .map((curr) => ({
                                currency_from_id: curr ?? '',
                                currency_to_id: event_details.currency_id,
                            }))
                            ?.filter((currPayload) =>
                                Boolean(currPayload.currency_from_id)
                            );
                    const currencyConversions =
                        await getManyToManyCurrencyConversion(
                            currencyOfCostsWithSourceNonePayload
                        );
                    let newItemDirectoryDetails =
                        cloneDeep(itemDirectoryDetails);
                    try {
                        const itemDirectoryDetailsWithAdditionalCost =
                            await autoFillAdditionalCost(
                                itemDirectoryDetails.buyer_additional_costs,
                                additionalCostInformation,
                                itemDirectoryDetails?.buyer_pricing_information
                                    ?.currency_code_id ?? '',
                                event_details.currency_id,
                                templateDetails,
                                currencyConversions
                            );
                        newItemDirectoryDetails.buyer_additional_costs =
                            itemDirectoryDetailsWithAdditionalCost;
                    } catch (err) {
                        console.error(err);
                    }
                    // const abc = autoFillCost()

                    let newItem = translateRFQItemDetailsToCustomDetails(
                        translateEnterpriseItemToRFQItem(
                            newItemDirectoryDetails
                        ),
                        template_custom_field_sections,
                        itemDirectoryDetails.tags,
                        templateDetails
                    );

                    newItem.is_price_confidential =
                        !customAllowPriceSharingSetting.enabled;
                    let _MEASUREMENT_UNITS_LIST =
                        itemDirectoryDetails.measurement_units
                            .item_measurement_units;

                    // fill in information from event level
                    if (
                        event_details.default_event_quantity_tolerance_percentage
                    ) {
                        newItem.quantity_tolerance_percentage =
                            event_details.default_event_quantity_tolerance_percentage;
                    }

                    if (
                        Boolean(
                            event_details.default_event_item_additional_information
                        )
                    ) {
                        newItem.item_additional_information =
                            event_details.default_event_item_additional_information;
                    }

                    // try {
                    //     const paymentAndDeliveryTermCustomFieldsNegotiable =
                    //         event_details?.custom_fields?.section_list?.find(
                    //             (section) =>
                    //                 section.name ===
                    //                 templateDetails.sections
                    //                     .PAYMENT_AND_DELIVERY_TERMS.label
                    //         );
                    //     const paymentAndDeliveryTermCustomFieldsNonNegotialble =
                    //         event_details?.custom_fields_negotiate?.section_list?.find(
                    //             (section) =>
                    //                 section.name ===
                    //                 templateDetails.sections
                    //                     .PAYMENT_AND_DELIVERY_TERMS.label
                    //         );
                    //     if (
                    //         newItem?.custom_fields_sections?.existingSections
                    //             ?.payment_and_delivery_terms?.length > 0
                    //     ) {
                    //         newItem.custom_fields_sections.existingSections.payment_and_delivery_terms =
                    //             newItem?.custom_fields_sections?.existingSections?.payment_and_delivery_terms?.map(
                    //                 (customField) => {
                    //                     let newCustomField =
                    //                         cloneDeep(customField);
                    //                     const customFieldInformationFromEventDetails =
                    //                         paymentAndDeliveryTermCustomFieldsNegotiable?.fields?.find(
                    //                             (field) =>
                    //                                 field.name ===
                    //                                 customField.name
                    //                         ) ??
                    //                         paymentAndDeliveryTermCustomFieldsNonNegotialble?.fields?.find(
                    //                             (field) =>
                    //                                 field.name ===
                    //                                 customField.name
                    //                         );

                    //                     if (
                    //                         customFieldInformationFromEventDetails
                    //                     ) {
                    //                         newCustomField.value =
                    //                             customFieldInformationFromEventDetails.value;
                    //                     }

                    //                     return newCustomField;
                    //                 }
                    //             );
                    //     }

                    //     const additionalDetailsCustomFieldsNegotiable =
                    //         event_details?.custom_fields?.section_list?.find(
                    //             (section) =>
                    //                 section.name ===
                    //                 templateDetails.sections.ADDITIONAL_DETAILS
                    //                     .label
                    //         );
                    //     const additionalDetailsCustomFieldsNonNegotialble =
                    //         event_details?.custom_fields_negotiate?.section_list?.find(
                    //             (section) =>
                    //                 section.name ===
                    //                 templateDetails.sections.ADDITIONAL_DETAILS
                    //                     .label
                    //         );
                    //     if (
                    //         newItem?.custom_fields_sections?.existingSections
                    //             ?.additional_details?.length > 0
                    //     ) {
                    //         newItem.custom_fields_sections.existingSections.additional_details =
                    //             newItem?.custom_fields_sections?.existingSections?.additional_details?.map(
                    //                 (customField) => {
                    //                     let newCustomField =
                    //                         cloneDeep(customField);
                    //                     const customFieldInformationFromEventDetails =
                    //                         additionalDetailsCustomFieldsNegotiable?.fields?.find(
                    //                             (field) =>
                    //                                 field.name ===
                    //                                 customField.name
                    //                         ) ??
                    //                         additionalDetailsCustomFieldsNonNegotialble?.fields?.find(
                    //                             (field) =>
                    //                                 field.name ===
                    //                                 customField.name
                    //                         );

                    //                     if (
                    //                         customFieldInformationFromEventDetails
                    //                     ) {
                    //                         newCustomField.value =
                    //                             customFieldInformationFromEventDetails.value;
                    //                     }
                    //                     return newCustomField;
                    //                 }
                    //             );
                    //     }

                    //     const essentialTermsCustomFieldsNegotiable =
                    //         event_details?.custom_fields?.section_list.find(
                    //             (section) =>
                    //                 section.name ===
                    //                 templateDetails.sections.ESSENTIAL_TERMS
                    //                     .label
                    //         );
                    //     const essentialTermsCustomFieldsNonNegotialble =
                    //         event_details?.custom_fields_negotiate?.section_list.find(
                    //             (section) =>
                    //                 section.name ===
                    //                 templateDetails.sections.ESSENTIAL_TERMS
                    //                     .label
                    //         );
                    //     if (
                    //         newItem?.custom_fields_sections?.existingSections
                    //             ?.essential_terms?.length > 0
                    //     ) {
                    //         newItem.custom_fields_sections.existingSections.essential_terms =
                    //             newItem?.custom_fields_sections?.existingSections?.essential_terms?.map(
                    //                 (customField) => {
                    //                     let newCustomField =
                    //                         cloneDeep(customField);
                    //                     const customFieldInformationFromEventDetails =
                    //                         essentialTermsCustomFieldsNegotiable?.fields?.find(
                    //                             (field) =>
                    //                                 field.name ===
                    //                                 customField.name
                    //                         ) ??
                    //                         essentialTermsCustomFieldsNonNegotialble?.fields?.find(
                    //                             (field) =>
                    //                                 field.name ===
                    //                                 customField.name
                    //                         );

                    //                     if (
                    //                         customFieldInformationFromEventDetails
                    //                     ) {
                    //                         newCustomField.value =
                    //                             customFieldInformationFromEventDetails.value;
                    //                     }
                    //                     return newCustomField;
                    //                 }
                    //             );
                    //     }
                    // } catch (err) {}

                    if (
                        itemDirectoryDetails.buyer_pricing_information &&
                        itemDirectoryDetails.buyer_pricing_information
                            .currency_code_id === event_details.currency_id &&
                        +(
                            itemDirectoryDetails?.buyer_pricing_information
                                ?.price || 0
                        ) > 0
                    ) {
                        //If purchase price is available from item directory, use that instead
                        // copy data from item directory

                        newItem.price =
                            itemDirectoryDetails?.buyer_pricing_information
                                ?.price || '';

                        newItem.price =
                            newItem.price === ''
                                ? ''
                                : (+newItem.price).toString();
                    }

                    if (
                        event_details.default_item_terms === 'FROM_PO' &&
                        po_item_details !== null
                    ) {
                        //Commenting out custom attribute loading for now
                        // newItem.specifications.custom_item_attributes =
                        //     po_item_details.attribute_information.custom_item_attributes;
                        // we search whether the PO item measurement unit is present in the current list or not and initialize evrything based on the answer
                        let foundMeasurementUnit = _MEASUREMENT_UNITS_LIST.find(
                            (measurement_unit) =>
                                measurement_unit.measurement_unit_id ===
                                po_item_details!.quantity_information
                                    .measurement_unit_id
                        );
                        // if measurement unit of previous po item is found in the current list
                        if (foundMeasurementUnit) {
                            // call API to get last PO details and copy data from there
                            // quantity, unit and Delivery schedule
                            newItem.measurement_unit_id =
                                po_item_details.quantity_information.measurement_unit_id;
                            newItem.measurement_unit_name =
                                po_item_details.quantity_information.measurement_unit_name;
                            // currency and price info
                            // if currency_id of previous po item and and current rfq details is the same
                            if (
                                po_item_details.pricing_information
                                    .currency_code_id ===
                                event_details.currency_id
                            ) {
                                newItem.currency_code_id =
                                    po_item_details.pricing_information.currency_code_id;

                                newItem.price = (+po_item_details
                                    .pricing_information.price).toString();
                                newItem.shipping = '0';
                                newItem.additional_charges = [];
                                newItem.currency_code_abbreviation =
                                    po_item_details.pricing_information.currency_code_abbreviation;
                                newItem.currency_code_symbol =
                                    po_item_details.pricing_information.currency_symbol;

                                if (
                                    templateDetails.fieldSettings.standardFields
                                        .DISCOUNT.buyerSettings.showField
                                ) {
                                    newItem.discounts =
                                        convertAdditionalCostBackendToFrontend(
                                            po_item_details.pricing_information
                                                .discounts
                                        );
                                }
                                if (
                                    templateDetails.fieldSettings.standardFields
                                        .TAX.buyerSettings.showField
                                ) {
                                    for (let tax of po_item_details
                                        .pricing_information.taxes) {
                                        for (let taxOption of templateDetails
                                            .fieldSettings.standardFields.TAX
                                            .taxesOptions) {
                                            if (
                                                tax.cost_name ===
                                                taxOption.costName
                                            ) {
                                                newItem.taxes.push(
                                                    convertAdditionalCostBackendToFrontendSingle(
                                                        tax
                                                    )
                                                );
                                            }
                                        }
                                    }
                                }
                                if (
                                    templateDetails.fieldSettings.standardFields
                                        .ADDITIONAL_COSTS.buyerSettings
                                        .showField
                                ) {
                                    for (let cost of po_item_details
                                        .pricing_information.additional_costs) {
                                        for (let costOption of templateDetails
                                            .fieldSettings.standardFields
                                            .ADDITIONAL_COSTS
                                            .additionalCostOptions) {
                                            if (
                                                cost.cost_name ===
                                                costOption.costName
                                            ) {
                                                newItem.additional_costs.push(
                                                    convertAdditionalCostBackendToFrontendSingle(
                                                        {
                                                            additional_cost_id:
                                                                costOption.additional_cost_id,
                                                            allocation_type:
                                                                cost.allocation_type,
                                                            cost_source:
                                                                cost.cost_source,
                                                            cost_name:
                                                                cost.cost_name,
                                                            cost_type:
                                                                cost.cost_type,
                                                            cost_value:
                                                                cost.cost_value,
                                                        }
                                                    )
                                                );
                                            }
                                        }
                                    }
                                }
                            } else {
                                newItem.currency_code_id =
                                    event_details.currency_id;
                                newItem.currency_code_abbreviation =
                                    event_details.currency_code_abbreviation;
                                newItem.currency_code_symbol =
                                    event_details.currency_symbol;
                            }
                        } else {
                            newItem.measurement_unit_id =
                                _MEASUREMENT_UNITS_LIST[0].measurement_unit_id;
                            newItem.measurement_unit_name =
                                _MEASUREMENT_UNITS_LIST[0].measurement_unit_primary_name;
                            newItem.currency_code_id =
                                event_details.currency_id;
                            newItem.currency_code_abbreviation =
                                event_details.currency_code_abbreviation;
                            newItem.currency_code_symbol =
                                event_details.currency_symbol;
                        }
                        // incoterm info
                        newItem.incoterm_id =
                            po_item_details.incoterm ??
                            FILTERED_INCOTERMS.entry_id;
                        newItem.incoterm_name =
                            po_item_details.incoterm_abbreviation;
                        // lead time
                        newItem.lead_time =
                            po_item_details.procurement_information.lead_time;
                        newItem.lead_time_period =
                            po_item_details.procurement_information.lead_time_period;
                        // payment terms
                        if (
                            po_item_details &&
                            po_item_details.paymentTerms &&
                            compareIPaymentTermstoTemplateOptionOfAutoFill(
                                po_item_details.paymentTerms,
                                templatePaymentTerms
                            )
                        ) {
                            newItem.paymentTerms = po_item_details.paymentTerms;
                        } else {
                            newItem.paymentTerms = blankPaymentTerms;
                        }

                        newItem.delivery_schedule[0].project_id =
                            event_details.default_event_project_id
                                ? event_details.default_event_project_id
                                : null;
                        newItem.delivery_schedule[0].cost_centre_id =
                            event_details.default_event_cost_centre_id
                                ? event_details.default_event_cost_centre_id
                                : null;
                        newItem.delivery_schedule[0].general_ledger_id =
                            event_details.default_event_gl_id
                                ? event_details.default_event_gl_id
                                : null;
                    } else if (
                        po_item_details === null ||
                        event_details.default_item_terms === 'CUSTOM'
                    ) {
                        // copy data from rfq details
                        newItem.currency_code_id = event_details.currency_id;
                        newItem.currency_code_abbreviation =
                            event_details.currency_code_abbreviation;
                        newItem.currency_code_symbol =
                            event_details.currency_symbol;
                        newItem.incoterm_id = event_details.incoterm_id;
                        let matchingIncoterm = incoterms.find(
                            (x) => x.entry_id === event_details.incoterm_id
                        );
                        newItem.incoterm_name = matchingIncoterm
                            ? matchingIncoterm.incoterm_full_name
                            : '';
                        newItem.lead_time = event_details.lead_time;
                        newItem.lead_time_period =
                            event_details.lead_time_period ?? 'WEEKS';
                        newItem.paymentTerms = event_details.paymentTerms;

                        newItem.measurement_unit_id =
                            _MEASUREMENT_UNITS_LIST[0].measurement_unit_id;
                        newItem.measurement_unit_name =
                            _MEASUREMENT_UNITS_LIST[0].measurement_unit_primary_name;
                        // requisition info
                        newItem.requisition_number =
                            event_details.requisition_no;
                        newItem.requisition_approved =
                            event_details.requisition_approved;
                        // copy delivery date
                        newItem.delivery_schedule[0].delivery_date =
                            event_details.delivery_date;
                        newItem.delivery_schedule[0].project_id =
                            event_details.default_event_project_id
                                ? event_details.default_event_project_id
                                : null;
                        newItem.delivery_schedule[0].cost_centre_id =
                            event_details.default_event_cost_centre_id
                                ? event_details.default_event_cost_centre_id
                                : null;
                        newItem.delivery_schedule[0].general_ledger_id =
                            event_details.default_event_gl_id
                                ? event_details.default_event_gl_id
                                : null;
                    } else {
                        // copy data from rfq details
                        newItem.currency_code_id = event_details.currency_id;
                        newItem.currency_code_abbreviation =
                            event_details.currency_code_abbreviation;
                        newItem.currency_code_symbol =
                            event_details.currency_symbol;
                        newItem.measurement_unit_id =
                            _MEASUREMENT_UNITS_LIST[0].measurement_unit_id;
                        newItem.measurement_unit_name =
                            _MEASUREMENT_UNITS_LIST[0].measurement_unit_primary_name;
                        // default payment terms

                        newItem.paymentTerms = event_details.paymentTerms;
                        newItem.lead_time_period = 'WEEKS';
                        newItem.incoterm_id = NA_INCOTERM_UUID ?? '';
                        newItem.incoterm_name = 'NA';
                        newItem.delivery_schedule[0].project_id =
                            event_details.default_event_project_id
                                ? event_details.default_event_project_id
                                : null;
                        newItem.delivery_schedule[0].cost_centre_id =
                            event_details.default_event_cost_centre_id
                                ? event_details.default_event_cost_centre_id
                                : null;
                        newItem.delivery_schedule[0].general_ledger_id =
                            event_details.default_event_gl_id
                                ? event_details.default_event_gl_id
                                : null;
                    }

                    // copy data from cheapease contract
                    let listOfcontract = allSettledPromises[2].value;
                    // to only keep one contract item that is relevant
                    listOfcontract = listOfcontract.map((contract) => {
                        contract.contract_items =
                            contract.contract_items.filter(
                                (item) =>
                                    item.enterprise_item === enterprise_item_id
                            );
                        return contract;
                    });

                    listOfcontract = listOfcontract.filter(
                        (contract) =>
                            contract.contract_items[0] &&
                            contract.contract_items[0].rate !== null
                    );
                    listOfcontract.sort(
                        (a, b) =>
                            +a.contract_items[0].rate -
                            +b.contract_items[0].rate
                    );

                    listOfcontract = listOfcontract.filter((contract) => {
                        return (
                            contract.contract_items
                                .map((item) => item.measurement_unit)
                                .includes(newItem.measurement_unit_id) &&
                            contract.contract_items
                                .map((item) => item.currency)
                                .includes(event_details.currency_id)
                        );
                    });

                    if (
                        event_details.default_event_project_id &&
                        listOfcontract.filter((contract) =>
                            contract.project_information
                                ? contract.project_information.project_id ===
                                  event_details.default_event_project_id
                                : false
                        ).length > 0
                    ) {
                        listOfcontract = listOfcontract.filter((contract) =>
                            contract.project_information
                                ? contract.project_information.project_id ===
                                  event_details.default_event_project_id
                                : false
                        );
                    }

                    if (
                        listOfcontract.length > 0 &&
                        listOfcontract[0].contract_items.length > 0
                    ) {
                        const cheapestContract = listOfcontract[0];

                        newItem.price = (+cheapestContract.contract_items[0]
                            .rate).toString();
                        newItem.additional_charges = [];
                        newItem.shipping = '0';

                        newItem.lead_time =
                            cheapestContract.contract_items[0].procurement_information?.lead_time?.toString() ??
                            null;

                        newItem.lead_time_period =
                            cheapestContract.contract_items[0]
                                .procurement_information?.lead_time_period ??
                            null;

                        if (
                            compareIPaymentTermstoTemplateOptionOfAutoFill(
                                cheapestContract.contract_items[0].paymentTerms,
                                templatePaymentTerms
                            )
                        ) {
                            newItem.paymentTerms =
                                cheapestContract.contract_items[0].paymentTerms;
                        }
                    }

                    // save the data

                    newItem.quantity = newItem.quantity ? newItem.quantity : '';

                    if (!fromPoRevise) {
                        const item_resp = await postRFQItem(
                            event_details.sub_event_id,
                            newItem,
                            type
                        );

                        try {
                            if (item_resp !== null) {
                                newItem.event_item_entry_id =
                                    item_resp.rfq_item_entry_id;
                                let vendorIDsToBeAdded: string[] = []; // list of vendors to be pre selected
                                let vendorContactsToBeAdded: string[] = []; // list of vendor contacts to be pre selected

                                for (let contract of listOfcontract) {
                                    if (
                                        !vendorIDsToBeAdded.includes(
                                            contract.seller_entity
                                        )
                                    ) {
                                        vendorIDsToBeAdded = [
                                            contract.seller_entity,
                                            ...vendorIDsToBeAdded,
                                        ];

                                        if (
                                            contract.vendor_contact_information
                                                ?.vendor_contact_id
                                        )
                                            vendorContactsToBeAdded = [
                                                contract
                                                    .vendor_contact_information
                                                    ?.vendor_contact_id ?? '',
                                                ...vendorContactsToBeAdded,
                                            ];
                                    }
                                }

                                for (let vendor of itemVendorsFromAdmin) {
                                    if (vendor.status === 'PREFERRED') {
                                        if (
                                            !vendorIDsToBeAdded.includes(
                                                vendor.seller_entity.entity_id
                                            )
                                        )
                                            vendorIDsToBeAdded.push(
                                                vendor.seller_entity.entity_id
                                            );

                                        for (let contact of vendor.seller_contacts) {
                                            if (
                                                !vendorContactsToBeAdded.includes(
                                                    contact.vendor_contact_id
                                                )
                                            ) {
                                                vendorContactsToBeAdded.push(
                                                    contact.vendor_contact_id
                                                );
                                            }
                                        }

                                        // vendorContactsToBeAdded.push(
                                        //     vendor.seller_contacts[0]
                                        //         .vendor_contact_id
                                        // );
                                    }
                                }

                                for (let contract of listOfcontract) {
                                    if (
                                        !vendorIDsToBeAdded.includes(
                                            contract.seller_entity
                                        )
                                    ) {
                                        vendorIDsToBeAdded = [
                                            contract.seller_entity,
                                            ...vendorIDsToBeAdded,
                                        ];
                                        vendorContactsToBeAdded = [
                                            contract.vendor_contact_information
                                                ?.vendor_contact_id ?? '',
                                            ...vendorContactsToBeAdded,
                                        ];
                                    }
                                }
                                //
                                if (vendorIDsToBeAdded.length !== 0) {
                                    await postRFQItemVendors(
                                        event_details.sub_event_id,
                                        item_resp.rfq_item_entry_id,
                                        false,
                                        vendorIDsToBeAdded.map(
                                            (vendorIDToBeAdded, idx) => {
                                                let vendor_contact_list: string[] =
                                                    [];

                                                if (
                                                    idx <
                                                    itemVendorsFromAdmin.length
                                                ) {
                                                    vendor_contact_list =
                                                        itemVendorsFromAdmin[
                                                            idx
                                                        ].seller_contacts.map(
                                                            (x) =>
                                                                x.vendor_contact_id
                                                        );

                                                    const secondary =
                                                        allSettledPromises[3]
                                                            .status ===
                                                        'fulfilled'
                                                            ? [
                                                                  ...(allSettledPromises[3].value.data
                                                                      .find(
                                                                          (c) =>
                                                                              c
                                                                                  .seller_entity
                                                                                  .entity_id ===
                                                                                  vendorIDToBeAdded &&
                                                                              c.buyer_entity ===
                                                                                  entity_id
                                                                      )
                                                                      ?.enterprise_vendor_master.secondary_vendor_contacts.filter(
                                                                          (c) =>
                                                                              c.buyer_entity ===
                                                                              entity_id
                                                                      )
                                                                      .filter(
                                                                          (c) =>
                                                                              !itemVendorsFromAdmin[
                                                                                  idx
                                                                              ].seller_contacts
                                                                                  .map(
                                                                                      (
                                                                                          scemail
                                                                                      ) =>
                                                                                          scemail.primary_email
                                                                                  )
                                                                                  .includes(
                                                                                      c.primary_email
                                                                                  ) &&
                                                                              c.is_primary ===
                                                                                  false
                                                                      )
                                                                      .map(
                                                                          (c) =>
                                                                              c.vendor_contact_id
                                                                      ) ?? []),
                                                              ]
                                                            : [];

                                                    vendor_contact_list =
                                                        Array.from(
                                                            new Set<string>([
                                                                ...vendor_contact_list,
                                                                ...secondary,
                                                            ])
                                                        );
                                                } else {
                                                    const contract =
                                                        listOfcontract.find(
                                                            (contract) =>
                                                                contract.seller_entity ===
                                                                vendorIDToBeAdded
                                                        );

                                                    if (contract) {
                                                        vendor_contact_list = [
                                                            contract
                                                                .vendorMasterDetails[0]
                                                                .primary_vendor_contact
                                                                .vendor_contact_id,
                                                        ];
                                                    }
                                                }

                                                return {
                                                    seller_entity_id:
                                                        vendorIDToBeAdded,
                                                    vendor_contact_list,
                                                };
                                            }
                                        ) ?? [],
                                        type
                                    );
                                }

                                batch(() => {
                                    dispatch(
                                        updateEventDataStore({
                                            type: 'UPDATE_VENDOR_FOR_ITEM',
                                            value: {
                                                item_entry_id:
                                                    item_resp.rfq_item_entry_id,
                                                vendor:
                                                    type === 'FROM_EVENT'
                                                        ? vendorIDsToBeAdded
                                                        : [
                                                              vendorIDsToBeAdded[0],
                                                          ],
                                            },
                                        })
                                    );
                                    dispatch(
                                        updateEventDataStore({
                                            type: 'UPDATE_OPEN_ADD_ITEM_CONTAINER',
                                            value: false,
                                        })
                                    );
                                    dispatch(
                                        updateEventDataStore({
                                            type: 'UPDATE_ALL_PO_ITEMS_ARE_FULL_fILLED',
                                            value: false,
                                        })
                                    );
                                    dispatch(
                                        updateEventDataStore({
                                            type: 'UPDATE_CACHE_ERRORS',
                                            value: {
                                                type_of_action:
                                                    'UPDATE_SINGLE_ITEM',
                                                rfq_item_entery_id:
                                                    item_resp.rfq_item_entry_id,
                                                itemDetails:
                                                    translateRFQItemDetailsToCustomDetailsWithTemplates(
                                                        item_resp,
                                                        templateDetails,
                                                        []
                                                    ),
                                                itemNo:
                                                    (store.getState()
                                                        .EventCreationStore
                                                        .currentPage -
                                                        1) *
                                                        RFQ_ITEM_PER_PAGE +
                                                    (0 + 1),
                                            },
                                        })
                                    );
                                    let argfor: ArgumentsForPaginationApiCall;

                                    if (type === 'FROM_EVENT') {
                                        argfor = {
                                            body: {
                                                dashboard_view:
                                                    'rfq_event_item_buyer',
                                                items_per_page:
                                                    RFQ_ITEM_PER_PAGE,
                                                page_number:
                                                    store.getState()
                                                        .EventCreationStore
                                                        .currentPage,
                                                query_data: {
                                                    rfq_event_id:
                                                        event_details.sub_event_id,
                                                    enterprise_bom_id:
                                                        selectedBomForFiltering
                                                            ? selectedBomForFiltering
                                                            : undefined,
                                                    sub_bom_item_id:
                                                        selectedSubBomForFiltering
                                                            ? selectedSubBomForFiltering
                                                            : undefined,
                                                },
                                                search_text: '',
                                                sort_fields: [],
                                                tab: 'all',
                                            },
                                            otherInfoRequired: {
                                                entity_id: entity_id,
                                                poCount:
                                                    store.getState()
                                                        .EventCreationStore
                                                        .arePoAvailable &&
                                                    showWarnings
                                                        ? 1
                                                        : 0,
                                                type: 'FROM_EVENT',
                                                searchQueryType: 'ITEMS',
                                            },
                                        };
                                    } else {
                                        argfor = {
                                            body: {
                                                dashboard_view: 'po_group_item',
                                                items_per_page:
                                                    RFQ_ITEM_PER_PAGE,
                                                page_number:
                                                    store.getState()
                                                        .EventCreationStore
                                                        .currentPage,
                                                query_data: {
                                                    po_group_id:
                                                        event_details.sub_event_id,
                                                    enterprise_bom_id:
                                                        selectedBomForFiltering
                                                            ? selectedBomForFiltering
                                                            : undefined,
                                                    sub_bom_item_id:
                                                        selectedSubBomForFiltering
                                                            ? selectedSubBomForFiltering
                                                            : undefined,
                                                },
                                                search_text: '',
                                                sort_fields: [],
                                                tab: 'all',
                                            },
                                            otherInfoRequired: {
                                                entity_id: entity_id,
                                                poCount:
                                                    store.getState()
                                                        .EventCreationStore
                                                        .arePoAvailable &&
                                                    showWarnings
                                                        ? 1
                                                        : 0,
                                                type: 'DIRECT',
                                                searchQueryType: 'ITEMS',
                                            },
                                        };
                                    }

                                    const patches =
                                        RFQPaginatedApiSlice.util.updateQueryData(
                                            'getListOfRfqItemPaginatedQuery',

                                            argfor,
                                            (previousData) => {
                                                if (previousData) {
                                                    let findIndex =
                                                        previousData.data.listOfItems.findIndex(
                                                            (v) =>
                                                                v.rfq_item_entry_id ===
                                                                item_resp.rfq_item_entry_id
                                                        );

                                                    if (findIndex >= 0) {
                                                        previousData.data.listOfItems[
                                                            findIndex
                                                        ] = item_resp;
                                                    } else {
                                                        previousData.data.listOfItems.push(
                                                            item_resp
                                                        );
                                                        previousData.data.allItemTags[
                                                            item_resp.rfq_item_entry_id ??
                                                                (
                                                                    item_resp as any
                                                                )
                                                                    .po_group_item_entry_id
                                                        ] = newItem.tags;
                                                    }

                                                    previousData.data.lastPoItems =
                                                        {
                                                            ...previousData.data
                                                                .lastPoItems,
                                                            [item_resp.rfq_item_entry_id]:
                                                                po_item_details,
                                                        };
                                                    if (
                                                        allSettledPromises[0]
                                                            .status ===
                                                        'fulfilled'
                                                    )
                                                        previousData.data.measurementUnits =
                                                            {
                                                                ...previousData
                                                                    .data
                                                                    .measurementUnits,
                                                                [item_resp.rfq_item_entry_id]:
                                                                    allSettledPromises[0]
                                                                        .value
                                                                        .measurement_units
                                                                        .item_measurement_units,
                                                            };
                                                    previousData.counts.all += 1;

                                                    previousData.metadata.total_pages =
                                                        Math.ceil(
                                                            previousData.counts
                                                                .all /
                                                                RFQ_ITEM_PER_PAGE
                                                        );
                                                }

                                                return previousData;
                                            }
                                        );

                                    let argfor2: ArgumentsForPaginationApiCall;
                                    if (type === 'FROM_EVENT') {
                                        argfor2 = {
                                            body: {
                                                dashboard_view:
                                                    'rfq_event_item_buyer',
                                                items_per_page:
                                                    RFQ_ITEM_PER_PAGE,
                                                page_number:
                                                    store.getState()
                                                        .EventCreationStore
                                                        .currentPage + 1,
                                                query_data: {
                                                    rfq_event_id:
                                                        event_details.sub_event_id,
                                                    enterprise_bom_id:
                                                        selectedBomForFiltering
                                                            ? selectedBomForFiltering
                                                            : undefined,
                                                    sub_bom_item_id:
                                                        selectedSubBomForFiltering
                                                            ? selectedSubBomForFiltering
                                                            : undefined,
                                                },
                                                search_text: '',
                                                sort_fields: [],
                                                tab: 'all',
                                            },
                                            otherInfoRequired: {
                                                entity_id: entity_id,
                                                poCount:
                                                    store.getState()
                                                        .EventCreationStore
                                                        .arePoAvailable &&
                                                    showWarnings
                                                        ? 1
                                                        : 0,
                                                type: 'FROM_EVENT',
                                                searchQueryType: 'ITEMS',
                                            },
                                        };
                                    } else {
                                        argfor2 = {
                                            body: {
                                                dashboard_view: 'po_group_item',
                                                items_per_page:
                                                    RFQ_ITEM_PER_PAGE,
                                                page_number:
                                                    store.getState()
                                                        .EventCreationStore
                                                        .currentPage + 1,
                                                query_data: {
                                                    po_group_id:
                                                        event_details.sub_event_id,
                                                    enterprise_bom_id:
                                                        selectedBomForFiltering
                                                            ? selectedBomForFiltering
                                                            : undefined,
                                                    sub_bom_item_id:
                                                        selectedSubBomForFiltering
                                                            ? selectedSubBomForFiltering
                                                            : undefined,
                                                },
                                                search_text: '',
                                                sort_fields: [],
                                                tab: 'all',
                                            },
                                            otherInfoRequired: {
                                                entity_id: entity_id,
                                                poCount:
                                                    store.getState()
                                                        .EventCreationStore
                                                        .arePoAvailable &&
                                                    showWarnings
                                                        ? 1
                                                        : 0,
                                                type: 'DIRECT',
                                                searchQueryType: 'ITEMS',
                                            },
                                        };
                                    }

                                    const patches2 =
                                        RFQPaginatedApiSlice.util.updateQueryData(
                                            'getListOfRfqItemPaginatedQuery',
                                            argfor2,
                                            (previousData) => {
                                                if (previousData) {
                                                    previousData.counts.all += 1;

                                                    previousData.metadata.total_pages =
                                                        Math.ceil(
                                                            previousData.counts
                                                                .all /
                                                                RFQ_ITEM_PER_PAGE
                                                        );
                                                }

                                                return previousData;
                                            }
                                        );
                                    dispatch(patches as any);
                                    dispatch(patches2 as any);

                                    [
                                        ...Array(
                                            store.getState().EventCreationStore
                                                .currentPage - 1
                                        ).keys(),
                                    ]
                                        .map((idx) => idx + 1)
                                        .forEach((page) => {
                                            let argfor3: ArgumentsForPaginationApiCall;
                                            if ((type = 'FROM_EVENT')) {
                                                argfor3 = {
                                                    body: {
                                                        dashboard_view:
                                                            'rfq_event_item_buyer',
                                                        items_per_page:
                                                            RFQ_ITEM_PER_PAGE,
                                                        page_number: page,
                                                        query_data: {
                                                            rfq_event_id:
                                                                event_details.sub_event_id,
                                                            enterprise_bom_id:
                                                                selectedBomForFiltering
                                                                    ? selectedBomForFiltering
                                                                    : undefined,
                                                            sub_bom_item_id:
                                                                selectedSubBomForFiltering
                                                                    ? selectedSubBomForFiltering
                                                                    : undefined,
                                                        },
                                                        search_text: '',
                                                        sort_fields: [],
                                                        tab: 'all',
                                                    },
                                                    otherInfoRequired: {
                                                        entity_id: entity_id,
                                                        poCount:
                                                            store.getState()
                                                                .EventCreationStore
                                                                .arePoAvailable &&
                                                            showWarnings
                                                                ? 1
                                                                : 0,
                                                        type: 'FROM_EVENT',
                                                        searchQueryType:
                                                            'ITEMS',
                                                    },
                                                };
                                            } else {
                                                argfor3 = {
                                                    body: {
                                                        dashboard_view:
                                                            'po_group_item',
                                                        items_per_page:
                                                            RFQ_ITEM_PER_PAGE,
                                                        page_number: page,
                                                        query_data: {
                                                            po_group_id:
                                                                event_details.sub_event_id,
                                                            enterprise_bom_id:
                                                                selectedBomForFiltering
                                                                    ? selectedBomForFiltering
                                                                    : undefined,
                                                            sub_bom_item_id:
                                                                selectedSubBomForFiltering
                                                                    ? selectedSubBomForFiltering
                                                                    : undefined,
                                                        },
                                                        search_text: '',
                                                        sort_fields: [],
                                                        tab: 'all',
                                                    },
                                                    otherInfoRequired: {
                                                        entity_id: entity_id,
                                                        poCount:
                                                            store.getState()
                                                                .EventCreationStore
                                                                .arePoAvailable &&
                                                            showWarnings
                                                                ? 1
                                                                : 0,
                                                        type: 'DIRECT',
                                                        searchQueryType:
                                                            'ITEMS',
                                                    },
                                                };
                                            }

                                            dispatch(
                                                RFQPaginatedApiSlice.util.updateQueryData(
                                                    'getListOfRfqItemPaginatedQuery',
                                                    argfor3,
                                                    (previousData) => {
                                                        if (previousData) {
                                                            previousData.counts.all += 1;

                                                            previousData.metadata.total_pages =
                                                                Math.ceil(
                                                                    previousData
                                                                        .counts
                                                                        .all /
                                                                        RFQ_ITEM_PER_PAGE
                                                                );
                                                        }

                                                        return previousData;
                                                    }
                                                ) as any
                                            );
                                        });
                                });
                            }
                        } catch (e) {
                            console.error('', e);
                        } finally {
                            setDataState({
                                loading: false,
                                error: false,
                            });
                            try {
                                if (type === 'DIRECT') {
                                    refetchSellerD2PO();
                                } else {
                                    refetchSellerRFQ();
                                }
                            } catch (err) {
                                console.error(err, '');
                            }
                        }
                    } else {
                        onSuccess(
                            newItem.event_item_entry_id,
                            po_item_details,
                            itemDirectoryDetails.measurement_units
                                .item_measurement_units,
                            newItem
                        );

                        setDataState({
                            loading: false,
                            error: false,
                        });
                        try {
                            if (type === 'DIRECT') {
                                refetchSellerD2PO();
                            } else {
                                refetchSellerRFQ();
                            }
                        } catch (err) {
                            console.error(err, '');
                        }
                    }
                }
            } catch (e) {
                console.error(e, '');

                setDataState({
                    loading: false,
                    error: true,
                });
            } finally {
                handleClose();
                window.scrollTo(0, document.body.scrollHeight);
                try {
                    if (type === 'DIRECT') {
                        refetchSellerD2PO();
                    } else {
                        refetchSellerRFQ();
                    }
                } catch (err) {
                    console.error(err, '');
                }
            }
        } else {
            handleClose();
            try {
                if (type === 'DIRECT') {
                    refetchSellerD2PO();
                } else {
                    refetchSellerRFQ();
                }
            } catch (err) {
                console.error(err);
            }
        }
    };

    return {
        fetchAndLoadSelectedItemDetails,
        dataState,
        showBomPopup,
        setShowBomPopup,
    };
}

export const compareIPaymentTermstoTemplateOptionOfAutoFill = (
    paymentTerms: IPaymentTerms,
    templatePaymentTerms: ITemplatePaymentTermsOptions
) => {
    if (
        templatePaymentTerms.paymentTermOptions.paymentType ===
        paymentTerms.paymentType
    ) {
        if (
            templatePaymentTerms.paymentTermOptions.paymentType ===
                'invoiceItemValues' &&
            paymentTerms.paymentType === 'invoiceItemValues'
        ) {
            const standardFields: string[] = [];

            if (
                templatePaymentTerms.paymentTermOptions
                    .standardReferenceDateOptions.DISPATCH_DATE.enabled
            )
                standardFields.push('DISPATCH_DATE');
            if (
                templatePaymentTerms.paymentTermOptions
                    .standardReferenceDateOptions.INVOICE_DATE.enabled
            )
                standardFields.push('INVOICE_DATE');
            if (
                templatePaymentTerms.paymentTermOptions
                    .standardReferenceDateOptions.RECEIPT_DATE.enabled
            )
                standardFields.push('RECEIPT_DATE');

            let contractValue = '';

            if (paymentTerms.appliedFrom !== '') {
                contractValue = paymentTerms.appliedFrom.referenceDateLabel;
            }

            if (
                [
                    ...templatePaymentTerms.paymentTermOptions
                        .customReferenceDateOptions,
                    ...standardFields,
                ].includes(contractValue)
            ) {
                return true;
            }
        }

        if (
            templatePaymentTerms.paymentTermOptions.paymentType ===
                'poItemPercentages' &&
            paymentTerms.paymentType === 'poItemPercentages'
        ) {
            let something = paymentTerms.list
                .map((x) =>
                    x.milestoneType === 'DELIVERABLE' ? x.deliverableName : ''
                )
                .filter((x) => x.length > 0);
            if (
                something.reduce((acc, next: string) => {
                    if (
                        templatePaymentTerms.paymentTermOptions.paymentType ===
                        'poItemPercentages'
                    ) {
                        return (
                            acc &&
                            templatePaymentTerms.paymentTermOptions.deliverableNameOptions.includes(
                                next
                            )
                        );
                    }
                    return true;
                }, true)
            ) {
                return true;
            }
        }
        return false;
    }
};
