import { cloneDeep } from 'lodash';
import { Dispatch, SetStateAction, useCallback, useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import {
    HookStateValue,
    IHookState,
    useHookState,
} from '../../Common/Hooks/StateHook';
import {
    deleteEvent,
    deletePOGroup,
} from '../../Components/Buyer/Events/DraftRfqSummary/helperFunctions';
import {
    IFileResourceType,
    IResourceFields,
} from '../../Components/Shared/UploadFile';
import { useFetchTabCountMutation } from '../../Redux/Slices/tabCountApiSlice';
import { downloadCSVTemplate } from '../Helpers/csvHelpers';
import {
    IBulkImport,
    IBulkImportCSVProcessResponse,
} from '../Interfaces/BulkImportInterface';
import {
    fetchBulkImportCSVHeaders,
    // IBulkImportFields,
    processCSV,
} from '../Services/BulkImportService';

export interface IBulkImportTemplateDownloadProvider {
    hookState: IHookState;
    csvHeaders: string[];
    setCSVHeaders: Dispatch<SetStateAction<string[]>>;
    template: IBulkImport[];
    setTemplate: Dispatch<SetStateAction<IBulkImport[]>>;
    getTemplateHeaders: (
        form: HTMLFormElement,
        templateId: string | undefined,
        templateName: string | undefined,
        onSuccessDownload: () => void,
        import_type?:
            | 'BOM_UPDATE'
            | 'BOM_CREATE'
            | 'BOM_DASHBOARD'
            | 'BOM_FINISHED_GOOD'
    ) => void;
    startCSVImport: (
        type: 'FRESH_UPLOAD' | 'REUPLOAD',
        createNewEvent:
            | ((resourceType: IFileResourceType) => Promise<string | null>)
            | undefined,
        callback: (success: true) => void,
        import_type?:
            | 'BOM_UPDATE'
            | 'BOM_CREATE'
            | 'BOM_DASHBOARD'
            | 'BOM_FINISHED_GOOD'
    ) => Promise<void>;
}

export const useBulkImportTemplateDownload = (
    resourceType: IFileResourceType,
    resource_fields: IResourceFields[],
    additional_information?: {
        [key: string]: any;
    },
    rfqEventId?: string,
    entity_id?: string,
    setEventId?: React.Dispatch<React.SetStateAction<string>>,
    setShowLoader?: Dispatch<SetStateAction<boolean>>
) => {
    const { hookState, updateHookState } = useHookState(HookStateValue.INITIAL);
    const [csvHeaders, setCSVHeaders] = useState<string[]>([]);
    const [template, setTemplate] = useState<IBulkImport[]>([]);
    const history = useHistory();
    const {
        rfq_event_id,
        po_group_id,

        // bulk import of seller opening id
        rfq_bid_id,
        seller_entity_id,
        id: rfq_event_id_for_seller_opening_id,

        bom_id,
    }: any = useParams();
    const location = useLocation();
    const getTemplateHeaders = async (
        form: HTMLFormElement,
        templateId: string | undefined,
        templateName: string | undefined,
        onSuccessDownload: () => void,
        import_type?:
            | 'BOM_UPDATE'
            | 'BOM_CREATE'
            | 'BOM_DASHBOARD'
            | 'BOM_FINISHED_GOOD'
    ) => {
        updateHookState(HookStateValue.LOADING);
        try {
            /* const formInputs = form.querySelectorAll('input');
            const bulkImportFields: IBulkImportFields[] = [];
            formInputs.forEach(({ name, value }) => {
                bulkImportFields.push({
                    name,
                    value,
                });
            }); */

            let clonedResourceFields: IResourceFields[] =
                cloneDeep(resource_fields);

            clonedResourceFields = clonedResourceFields
                .filter((field) => field.key.length > 0)
                .map((field) => ({
                    key: field.key,
                    value: field.value,
                }));

            const csvHeaders = await fetchBulkImportCSVHeaders(
                resourceType,
                clonedResourceFields,
                entity_id,
                templateId,
                import_type
            );
            downloadCSVTemplate(csvHeaders, resourceType, templateName);
            onSuccessDownload();
            setCSVHeaders([]);
            updateHookState(HookStateValue.READY);
        } catch (error) {
            updateHookState(HookStateValue.ERROR);
        }
    };
    const [fetchTabData] = useFetchTabCountMutation({
        fixedCacheKey: 'rfq_event_buyer',
    });

    const startCSVImport = useCallback(
        async (
            type: 'FRESH_UPLOAD' | 'REUPLOAD',
            createNewEvent:
                | ((resourceType: IFileResourceType) => Promise<string | null>)
                | undefined,
            callback,
            import_type?:
                | 'BOM_UPDATE'
                | 'BOM_CREATE'
                | 'BOM_DASHBOARD'
                | 'BOM_FINISHED_GOOD'
        ) => {
            updateHookState(HookStateValue.LOADING);
            try {
                let additional_info_to_use = additional_information;
                if (template.length === 0) {
                    // updateHookState(HookStateValue.ERROR);
                    throw new Error('No data to import');
                } else {
                    const { bulk_import_id } = template[0];

                    // this logic is for creating a new event or POs
                    let responseData: IBulkImportCSVProcessResponse | undefined;
                    if (
                        (resourceType === IFileResourceType.RFQ_ITEM ||
                            resourceType === IFileResourceType.PO_GROUP_ITEM) &&
                        type === 'FRESH_UPLOAD' &&
                        createNewEvent
                    ) {
                        const sub_event_id = await createNewEvent(resourceType);
                        if (setEventId && sub_event_id) {
                            setEventId(sub_event_id);
                        }

                        additional_info_to_use = {
                            [resourceType === IFileResourceType.RFQ_ITEM
                                ? 'rfq_event_id'
                                : 'po_group_id']: sub_event_id,
                        };

                        if (sub_event_id) {
                            responseData = await processCSV(
                                bulk_import_id,
                                additional_info_to_use
                            );
                        }
                    } else {
                        if (import_type === 'BOM_DASHBOARD') {
                            responseData = await processCSV(bulk_import_id);
                        } else {
                            responseData = await processCSV(
                                bulk_import_id,
                                additional_info_to_use
                                    ? additional_info_to_use
                                    : rfq_event_id || po_group_id || rfq_bid_id
                                    ? { rfq_event_id, po_group_id, rfq_bid_id }
                                    : {}
                            );
                        }
                        if (!responseData.rfq_bid_id)
                            additional_info_to_use = additional_info_to_use
                                ? additional_info_to_use
                                : { rfq_bid_id };
                    }

                    // const responseData = await processCSV(
                    //     bulk_import_id,
                    //     additional_info_to_use
                    //         ? additional_info_to_use
                    //         : rfq_event_id || po_group_id
                    //         ? { rfq_event_id, po_group_id }
                    //         : {}
                    // );

                    updateHookState(HookStateValue.READY);
                    if (
                        responseData &&
                        (responseData.response_type === 'DataError' ||
                            responseData.response_type === 'DynamicError')
                    ) {
                        if (responseData?.error === 'EmptyFile') {
                            toast.error(
                                'The uploaded file is empty, please upload a file containing data'
                            );

                            updateHookState(HookStateValue.ERROR);
                            return;
                        }
                        if (
                            resourceType === IFileResourceType.PO_GROUP_ITEM &&
                            (additional_info_to_use || po_group_id)
                        ) {
                            if (
                                history.location.pathname ===
                                `/buyer/purchase_orders/direct/create/bulk-import/${resourceType}/${bulk_import_id}/${
                                    additional_info_to_use?.po_group_id ??
                                    po_group_id
                                }`
                            ) {
                                history.go(0);
                            }

                            history.replace({
                                pathname: `/buyer/purchase_orders/direct/create/bulk-import/${resourceType}/${bulk_import_id}/${
                                    additional_info_to_use?.po_group_id ??
                                    po_group_id
                                }`,
                                state: { responseData, resourceType },
                            });
                        } else if (
                            resourceType === IFileResourceType.RFQ_ITEM &&
                            (additional_info_to_use || rfq_event_id)
                        ) {
                            if (
                                history.location.pathname ===
                                `/buyer/events/create/bulk-import/${resourceType}/${bulk_import_id}/${
                                    additional_info_to_use?.rfq_event_id ??
                                    rfq_event_id
                                }`
                            ) {
                                history.go(0);
                            }
                            history.replace({
                                pathname: `/buyer/events/create/bulk-import/${resourceType}/${bulk_import_id}/${
                                    additional_info_to_use?.rfq_event_id ??
                                    rfq_event_id
                                }`,
                                state: { responseData, resourceType },
                            });
                        } else if (
                            resourceType === IFileResourceType.BID_ITEM_SELLER
                        ) {
                            if (location.pathname.includes('rfqnegotiation')) {
                                // let finalPartofUrl;
                                // if (isValidUUID(rfq_bid_id)) {
                                //     finalPartofUrl = rfq_bid_id;
                                // } else {
                                //     finalPartofUrl = JSON.stringify(
                                //         additional_information
                                //     );
                                // }
                                if (
                                    history.location.pathname ===
                                    `/seller/events/${seller_entity_id}/${rfq_event_id_for_seller_opening_id}/rfqnegotiation/bulk-import/${
                                        IFileResourceType.BID_ITEM_SELLER
                                    }/${bulk_import_id}/${
                                        additional_info_to_use?.rfq_bid_id ??
                                        responseData?.rfq_bid_id
                                    }`
                                ) {
                                    history.go(0);
                                }
                                history.replace({
                                    pathname: `/seller/events/${seller_entity_id}/${rfq_event_id_for_seller_opening_id}/rfqnegotiation/bulk-import/${
                                        IFileResourceType.BID_ITEM_SELLER
                                    }/${bulk_import_id}/${
                                        additional_info_to_use?.rfq_bid_id ??
                                        responseData?.rfq_bid_id
                                    }`,
                                    state: { responseData, resourceType },
                                });
                            } else {
                                if (
                                    history.location.pathname ===
                                    `/seller/events/${seller_entity_id}/${rfq_event_id_for_seller_opening_id}/rfqopeningbid/bulk-import/${IFileResourceType.BID_ITEM_SELLER}/${bulk_import_id}/${rfq_bid_id}`
                                ) {
                                    history.go(0);
                                }
                                history.replace({
                                    pathname: `/seller/events/${seller_entity_id}/${rfq_event_id_for_seller_opening_id}/rfqopeningbid/bulk-import/${IFileResourceType.BID_ITEM_SELLER}/${bulk_import_id}/${rfq_bid_id}`,
                                    state: {
                                        responseData,
                                        resourceType:
                                            IFileResourceType.BID_ITEM_SELLER,
                                    },
                                });
                            }
                        } else if (
                            resourceType === IFileResourceType.BID_ITEM_BUYER
                        ) {
                            if (
                                history.location.pathname ===
                                `/buyer/events/live/${rfq_event_id_for_seller_opening_id}/quotes/bulk-import/${
                                    IFileResourceType.BID_ITEM_BUYER
                                }/${bulk_import_id}/${
                                    additional_info_to_use?.rfq_bid_id ??
                                    responseData?.rfq_bid_id
                                }`
                            ) {
                                history.go(0);
                            }
                            history.replace({
                                // pathname: `/buyer/events/${seller_entity_id}/${rfq_event_id_for_seller_opening_id}/rfqnegotiation/bulk-import/${
                                //     IFileResourceType.BID_ITEM_SELLER
                                // }/${bulk_import_id}/${JSON.stringify(
                                //     additional_information
                                // )}`,
                                pathname: `/buyer/events/live/${rfq_event_id_for_seller_opening_id}/quotes/bulk-import/${
                                    IFileResourceType.BID_ITEM_BUYER
                                }/${bulk_import_id}/${
                                    additional_info_to_use?.rfq_bid_id ??
                                    responseData?.rfq_bid_id
                                }`,
                                state: { responseData, resourceType },
                            });
                        } else if (resourceType === IFileResourceType.BOM) {
                            if (import_type === 'BOM_CREATE') {
                                history.replace({
                                    pathname: `/admin/BOM/create/bulk-import/${resourceType}/${bulk_import_id}/${
                                        additional_information?.finished_good_id
                                    }/${additional_information?.bom_code}/${
                                        additional_information?.bom_name
                                    }/${additional_information?.currency_id}/${
                                        additional_information?.measurement_unit_id
                                    }/${
                                        additional_information?.quantity
                                    }/${JSON.stringify(
                                        additional_information?.entity_ids
                                    )}`,
                                });
                            } else {
                                history.replace({
                                    pathname: `/admin/BOM/edit/${
                                        additional_information?.enterprise_bom_id
                                    }/bulk-import/${resourceType}/${bulk_import_id}/${
                                        additional_information?.finished_good_id
                                    }/${JSON.stringify(
                                        additional_information?.entity_ids
                                    )}`,

                                    state: { responseData, resourceType },
                                });
                            }
                        } else {
                            if (
                                history.location.pathname ===
                                `/admin/bulk-import/${resourceType}/${bulk_import_id}`
                            ) {
                                history.go(0);
                            }
                            history.replace({
                                pathname: `/admin/bulk-import/${resourceType}/${bulk_import_id}`,
                                state: { responseData, resourceType },
                            });
                        }
                        // window.location.reload();
                    } else if (
                        responseData &&
                        responseData.response_type === 'TemplateError'
                    ) {
                        responseData.error === 'IncorrectSellerBidSheet'
                            ? toast.error(
                                  'Incorrect sheet name: Please change the sheet name to Response Sheet'
                              )
                            : responseData.missing
                            ? toast.error(
                                  'Missing column: ' + responseData.missing
                              )
                            : responseData.additional
                            ? toast.error(
                                  'Additional column: ' +
                                      responseData.additional
                              )
                            : toast.error(
                                  'Template format is incorrect, please try again'
                              );
                        updateHookState(HookStateValue.ERROR);
                        if (resourceType === IFileResourceType.RFQ_ITEM) {
                            deleteEvent(
                                additional_info_to_use?.rfq_event_id ??
                                    rfq_event_id
                            ).then(() => {
                                fetchTabData('rfq_event_buyer');
                            });
                        } else if (
                            resourceType === IFileResourceType.PO_GROUP_ITEM
                        ) {
                            deletePOGroup(
                                additional_info_to_use?.po_group_id ??
                                    po_group_id
                            );
                        }
                    } else {
                        callback(true);
                        toast.success(
                            `${
                                resourceType === IFileResourceType.ITEM
                                    ? 'Item'
                                    : resourceType === IFileResourceType.VENDOR
                                    ? 'Vendor'
                                    : resourceType ===
                                      IFileResourceType.PO_GROUP_ITEM
                                    ? 'PO'
                                    : resourceType === IFileResourceType.BUYER
                                    ? 'Customer'
                                    : resourceType ===
                                      IFileResourceType.RFQ_ITEM
                                    ? 'Event'
                                    : resourceType ===
                                      IFileResourceType.BID_ITEM_SELLER
                                    ? 'Item'
                                    : resourceType ===
                                      IFileResourceType.BID_ITEM_BUYER
                                    ? 'Item'
                                    : resourceType === IFileResourceType.BOM
                                    ? 'Item'
                                    : ''
                            }(s) imported successfully`
                        );
                        // setTimeout(() => {
                        //     if (resourceType !== IFileResourceType.RFQ_ITEM) {
                        //         window.location.reload();
                        //     }
                        // }, 1000);
                        if (resourceType === IFileResourceType.ITEM) {
                            history.replace('/admin/items/item-directory');
                            setTimeout(() => {
                                window.location.reload();
                            }, 500);
                        } else if (resourceType === IFileResourceType.VENDOR) {
                            history.replace('/admin/vendors');
                            setTimeout(() => {
                                window.location.reload();
                            }, 500);
                        } else if (
                            resourceType === IFileResourceType.RFQ_ITEM
                        ) {
                            history.replace(
                                `/buyer/events/rfq/${
                                    additional_info_to_use?.rfq_event_id ||
                                    rfqEventId ||
                                    rfq_event_id
                                }?openBom=true`
                            );
                            // setTimeout(() => {
                            //     window.location.reload();
                            // }, 500);
                        } else if (
                            resourceType === IFileResourceType.PO_GROUP_ITEM
                        ) {
                            history.replace(
                                `/buyer/purchase_orders/direct/${
                                    additional_info_to_use?.po_group_id ||
                                    rfqEventId ||
                                    po_group_id
                                }`
                            );
                        } else if (resourceType === IFileResourceType.BOM) {
                            let bom_ids =
                                responseData && responseData.bom_ids
                                    ? responseData.bom_ids
                                    : undefined;

                            if (bom_ids) {
                                bom_ids = Array.from(new Set(bom_ids));
                            }

                            if (
                                (bom_id === 'undefined' ||
                                    bom_id === undefined) &&
                                (bom_ids === undefined || bom_ids.length > 1)
                            ) {
                                history.replace('/admin/bom');
                            } else if (bom_ids && bom_ids.length === 1) {
                                history.replace(
                                    `/admin/BOM/edit/${bom_ids[0]}`
                                );
                            } else {
                                history.replace(`/admin/BOM/edit/${bom_id}`);
                            }

                            // history.replace(`/admin/bom`);
                        } else if (resourceType === IFileResourceType.BUYER) {
                            history.replace(`/admin/buyers`);
                        } else if (
                            resourceType ===
                                IFileResourceType.BID_ITEM_SELLER &&
                            rfq_bid_id &&
                            seller_entity_id &&
                            rfq_event_id_for_seller_opening_id
                        ) {
                            if (location.pathname.includes('rfqnegotiation')) {
                                history.replace(
                                    `/seller/events/${seller_entity_id}/${rfq_event_id_for_seller_opening_id}/rfqnegotiation`
                                );
                            } else {
                                history.replace(
                                    `/seller/events/${seller_entity_id}/${rfq_event_id_for_seller_opening_id}/rfqopeningbid`
                                );
                            }
                        } else if (
                            resourceType === IFileResourceType.BID_ITEM_BUYER
                        ) {
                            history.replace({
                                pathname: `/buyer/events/live/${rfq_event_id_for_seller_opening_id}/quotes`,
                            });
                        }

                        /*  else {
                        window.location.reload();
                    } */
                    }
                }
            } catch (error: any) {
                //TODO: error.response is given as undefined
                //

                if (error.code === 'ECONNABORTED') {
                    toast.error(
                        'The uploaded file has too many rows, please reduce the number of rows and upload again'
                    );
                } else {
                    toast.error(`Process failed, please try again`);
                }
                updateHookState(HookStateValue.ERROR);
            }
        },
        [
            updateHookState,
            additional_information,
            template,
            resourceType,
            setEventId,
            rfq_event_id,
            po_group_id,
            rfq_bid_id,
            history,
            location.pathname,
            seller_entity_id,
            rfq_event_id_for_seller_opening_id,
            fetchTabData,
            rfqEventId,
            bom_id,
        ]
    );

    const bulkImportTemplateDownloadProvider: IBulkImportTemplateDownloadProvider =
        {
            hookState,
            getTemplateHeaders,
            csvHeaders,
            setCSVHeaders,
            template,
            setTemplate,
            startCSVImport,
        };

    return bulkImportTemplateDownloadProvider;
};
