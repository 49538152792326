import { cloneDeep } from 'lodash';
import { IBuyerEnterpriseItemDetails } from '../../Events/Interfaces/Buyer/RFQ.model';
import { transformPaymentTermsFromFrontendToBackend } from '../../Global/Helpers/paymentTermsHelpers';
import { StandardPaymentReferenceDateEnum } from '../../Global/Interfaces/PaymentTermsInterface';
import { PeriodEnum } from '../../Global/Interfaces/PeriodInterface';
import { CustomFieldType } from '../../Global/Interfaces/TemplateInterface';
import { CartItemBlankDeliveryInformation } from '../Constants/CartItemConstants';
import {
    IItemCartTemplate,
    ISectionField,
    ItemCartStandardSections,
} from '../Interfaces/ItemCartTemplateInterfaces';
import {
    CartItemDetails,
    IItemCartLevelCustomField,
    NewCartItem,
} from '../Interfaces/itemCartItemInterfaces';

export const blankItemCartTemplate: IItemCartTemplate = {
    templateId: '',
    templateName: '',
    fieldSettings: {
        standardFields: {
            CUSTOM_SPECIFICATION: {
                standardFieldCategory: 'STANDARD',
                label: 'Quantity',
                description: '',
                buyerSettings: {
                    required: true,
                    showField: true,
                },
                sellerSettings: {
                    negotiable: true,
                    showField: true,
                    isMandatory: false,
                },
            },
            QUANTITY: {
                standardFieldCategory: 'STANDARD',
                label: 'Quantity',
                description: '',
                buyerSettings: {
                    required: true,
                    showField: true,
                },
                sellerSettings: {
                    negotiable: true,
                    showField: true,
                    isMandatory: false,
                },
            },
            DELIVERY_DATE: {
                standardFieldCategory: 'STANDARD',
                label: 'Delivery date',
                description: '',
                buyerSettings: {
                    required: true,
                    showField: true,
                },
                sellerSettings: {
                    negotiable: true,
                    showField: true,
                    isMandatory: false,
                },
            },
            COST_CENTER: {
                standardFieldCategory: 'STANDARD',
                label: 'Cost center',
                description: '',
                buyerSettings: {
                    required: true,
                    showField: true,
                },
                sellerSettings: {
                    negotiable: true,
                    showField: true,
                    isMandatory: false,
                },
            },
            GL: {
                standardFieldCategory: 'STANDARD',
                label: 'GL',
                description: '',
                buyerSettings: {
                    required: true,
                    showField: true,
                },
                sellerSettings: {
                    negotiable: true,
                    showField: true,
                    isMandatory: false,
                },
            },
            SHIPPING_ADDRESS: {
                standardFieldCategory: 'STANDARD',
                label: 'Shipping address',
                description: '',
                buyerSettings: {
                    required: true,
                    showField: true,
                },
                sellerSettings: {
                    negotiable: true,
                    showField: true,
                    isMandatory: false,
                },
            },
            PROJECT: {
                standardFieldCategory: 'STANDARD',
                label: 'Project',
                description: '',
                buyerSettings: {
                    required: true,
                    showField: true,
                },
                sellerSettings: {
                    negotiable: true,
                    showField: true,
                    isMandatory: false,
                },
            },
            TARGET_RATE: {
                standardFieldCategory: 'STANDARD',
                label: 'Target rate',
                description: '',
                buyerSettings: {
                    required: true,
                    showField: true,
                },
                sellerSettings: {
                    negotiable: true,
                    showField: true,
                    isMandatory: false,
                },
            },
            ADDITIONAL_INFORMATION: {
                standardFieldCategory: 'STANDARD',
                label: 'Event type',
                description: '',
                buyerSettings: {
                    required: true,
                    showField: true,
                },
                sellerSettings: {
                    negotiable: true,
                    showField: true,
                    isMandatory: false,
                },
            },
            VENDOR: {
                standardFieldCategory: 'STANDARD',
                label: '',
                description: '',
                buyerSettings: {
                    showField: false,
                    required: false,
                },
                sellerSettings: {
                    showField: false,
                    negotiable: false,
                    isMandatory: false,
                },
            },
            GR_TOLERANCE: {
                standardFieldCategory: 'STANDARD',
                label: '',
                description: '',
                buyerSettings: {
                    showField: false,
                    required: false,
                },
                sellerSettings: {
                    showField: false,
                    negotiable: false,
                    isMandatory: false,
                },
            },
            INCOTERMS: {
                standardFieldCategory: 'STANDARD',
                label: '',
                description: '',
                buyerSettings: {
                    showField: false,
                    required: false,
                },
                sellerSettings: {
                    showField: false,
                    negotiable: false,
                    isMandatory: false,
                },
            },
            LEAD_TIME: {
                standardFieldCategory: 'STANDARD',
                label: '',
                description: '',
                buyerSettings: {
                    showField: false,
                    required: false,
                },
                sellerSettings: {
                    showField: false,
                    negotiable: false,
                    isMandatory: false,
                },
            },

            TAX: {
                standardFieldCategory: 'TAX',
                taxesOptions: [],
                label: '',
                description: '',
                buyerSettings: {
                    showField: false,
                    required: false,
                },
                sellerSettings: {
                    showField: false,
                    negotiable: false,
                    isMandatory: false,
                },
            },

            PAYMENT_TERMS: {
                standardFieldCategory: 'PAYMENT_TERMS',
                label: '',
                description: '',
                buyerSettings: {
                    showField: false,
                    required: false,
                },
                sellerSettings: {
                    showField: false,
                    negotiable: false,
                    isMandatory: false,
                },
                paymentTermOptions: {
                    paymentType: 'poItemPercentages',
                    prepaymentAllowed: true,
                    allowOtherDeliverableNames: false,
                    deliverableNameOptions: [],
                },
            },

            ADDITIONAL_COSTS: {
                standardFieldCategory: 'ADDITIONAL_COSTS',
                label: '',
                buyerSettings: {
                    required: false,
                    showField: false,
                },
                description: '',
                sellerSettings: {
                    negotiable: false,
                    showField: false,
                    isMandatory: false,
                },
                additionalCostOptions: [],
            },
            DISCOUNT: {
                standardFieldCategory: 'DISCOUNT',
                label: '',
                buyerSettings: {
                    required: false,
                    showField: false,
                },
                description: '',
                sellerSettings: {
                    isMandatory: false,
                    negotiable: false,
                    showField: false,
                },
                discountOptions: [],
            },
        },
        customFields: {},
    },
    sections: {
        ESSENTIAL_TERMS: {
            label: '',
            fieldList: [],
        },
        PAYMENT_AND_DELIVERY_TERMS: {
            label: '',
            fieldList: [],
        },
        ADDITIONAL_DETAILS: {
            label: '',
            fieldList: [],
        },
        VENDOR_INFORMATION: {
            label: '',
            fieldList: [],
        },
    },
};

export const convertTemplateFieldToPayload = (
    templateDetails: IItemCartTemplate,
    templateField: ISectionField,
    fieldInfoInPayload: IItemCartLevelCustomField | undefined
) => {
    const fieldInTemplate =
        templateDetails.fieldSettings.customFields[templateField.field];

    switch (fieldInTemplate.constraints.fieldType) {
        case 'SHORTTEXT':
        case 'LONGTEXT':
        case 'DATE':
        case 'DATETIME':
        case 'INTEGER':
        case 'FLOAT':
            return {
                name: fieldInTemplate.label,
                description: fieldInTemplate.description,
                is_negotiable: fieldInTemplate.sellerSettings.negotiable,
                is_required: fieldInTemplate.buyerSettings.required,
                is_visible: fieldInTemplate.sellerSettings.showField,
                is_locked: true,
                type: fieldInTemplate.constraints.fieldType,
                value: fieldInfoInPayload ? fieldInfoInPayload?.value : '',
            } as IItemCartLevelCustomField;
        case 'CHOICE': {
            if (fieldInTemplate.constraints.choiceType === 'DROPDOWN') {
                return {
                    name: fieldInTemplate.label,
                    description: fieldInTemplate.description,
                    is_negotiable: fieldInTemplate.sellerSettings.negotiable,
                    is_required: fieldInTemplate.buyerSettings.required,
                    is_visible: fieldInTemplate.sellerSettings.showField,
                    is_locked: true,
                    type: fieldInTemplate.constraints.fieldType,
                    value: fieldInfoInPayload ? fieldInfoInPayload?.value : '',
                } as IItemCartLevelCustomField;
            } else {
                return {
                    name: fieldInTemplate.label,
                    description: fieldInTemplate.description,
                    is_negotiable: fieldInTemplate.sellerSettings.negotiable,
                    is_required: fieldInTemplate.buyerSettings.required,
                    is_visible: fieldInTemplate.sellerSettings.showField,
                    is_locked: true,
                    type: fieldInTemplate.constraints.fieldType,
                    value: fieldInfoInPayload ? fieldInfoInPayload?.value : [],
                } as IItemCartLevelCustomField;
            }
        }

        case 'BOOLEAN':
            return {
                name: fieldInTemplate.label,
                description: fieldInTemplate.description,
                is_negotiable: fieldInTemplate.sellerSettings.negotiable,
                is_required: fieldInTemplate.buyerSettings.required,
                is_visible: fieldInTemplate.sellerSettings.showField,
                is_locked: true,
                type: fieldInTemplate.constraints.fieldType,
                value: fieldInfoInPayload ? fieldInfoInPayload?.value : null,
            } as IItemCartLevelCustomField;
        default: {
            return {
                name: fieldInTemplate.label,
                description: fieldInTemplate.description,
                is_negotiable: fieldInTemplate.sellerSettings.negotiable,
                is_required: fieldInTemplate.buyerSettings.required,
                is_visible: fieldInTemplate.sellerSettings.showField,
                is_locked: true,
                type: 'SHORTTEXT',
                value: fieldInfoInPayload ? fieldInfoInPayload?.value : '',
            } as IItemCartLevelCustomField;
        }
    }
};

export const convertTemplateDetailsToPayload = (
    templateDetails: IItemCartTemplate,
    items: CartItemDetails[]
) => {
    const newItemsPayload = cloneDeep(items);

    const sectionNamesInTemplate = Object.keys(templateDetails.sections);

    sectionNamesInTemplate.forEach((sectionNameInTemplate) => {
        const sectionNameInPayload =
            templateDetails.sections[
                sectionNameInTemplate as ItemCartStandardSections
            ];

        newItemsPayload.map((item) => {
            let currentSectionInPayload = item.custom_fields.section_list.find(
                (section) => section.name === sectionNameInPayload.label
            );

            if (currentSectionInPayload === undefined) {
                currentSectionInPayload = {
                    name: sectionNameInPayload.label,
                    fields: [],
                };
                item.custom_fields.section_list.push(currentSectionInPayload);
            }

            const customFieldsInTheCurrentSectionFromTemplate =
                sectionNameInPayload.fieldList.filter(
                    (field) => field.fieldType === 'CUSTOM'
                );

            const updatedFieldList =
                customFieldsInTheCurrentSectionFromTemplate.map((field) => {
                    let currentFieldInPayload =
                        currentSectionInPayload?.fields?.find(
                            (fieldInCF) => fieldInCF.name === field.field
                        );

                    const newValue = convertTemplateFieldToPayload(
                        templateDetails,
                        field,
                        currentFieldInPayload
                    );

                    return newValue;
                });

            currentSectionInPayload.fields = cloneDeep(updatedFieldList);

            return currentSectionInPayload;
        });
    });

    return newItemsPayload;
};
export const getCustomSectionsFromTemplateForCart = (
    templateDetails: IItemCartTemplate
) => {
    let itemTerms = {
        name: templateDetails.sections.ESSENTIAL_TERMS.label,
        section_type: 'ITEM' as 'ITEM' | 'OTHER' | 'BOM',
        custom_fields: templateDetails.sections.ESSENTIAL_TERMS.fieldList
            .filter((fieldDetails: any) => fieldDetails.fieldType === 'CUSTOM')
            .map((data) => {
                let fieldDetails =
                    templateDetails.fieldSettings.customFields[
                        data.field as any
                    ];

                return {
                    name: fieldDetails.label,
                    type:
                        fieldDetails.type === CustomFieldType.CHOICE &&
                        fieldDetails.constraints.fieldType ===
                            CustomFieldType.CHOICE &&
                        fieldDetails.constraints.choiceType === 'MULTI_SELECT'
                            ? CustomFieldType.MULTI_CHOICE
                            : fieldDetails.type === 'LONG_TEXT'
                            ? CustomFieldType.LONGTEXT
                            : fieldDetails.type,
                    value:
                        fieldDetails.type === CustomFieldType.CHOICE &&
                        fieldDetails.constraints.fieldType ===
                            CustomFieldType.CHOICE &&
                        fieldDetails.constraints.choiceType === 'MULTI_SELECT'
                            ? []
                            : null,
                    is_locked: true,
                    is_visible: fieldDetails.buyerSettings.showField,
                    description: fieldDetails.description,
                    is_required: fieldDetails.sellerSettings.isMandatory,
                    is_negotiable: fieldDetails.sellerSettings.negotiable,
                };
            }),
    };
    let paymentTerms = {
        name: templateDetails.sections.PAYMENT_AND_DELIVERY_TERMS.label,
        section_type: 'ITEM' as 'ITEM' | 'OTHER' | 'BOM',
        custom_fields:
            templateDetails.sections.PAYMENT_AND_DELIVERY_TERMS.fieldList
                .filter(
                    (fieldDetails: any) => fieldDetails.fieldType === 'CUSTOM'
                )
                .map((data) => {
                    let fieldDetails =
                        templateDetails.fieldSettings.customFields[
                            data.field as any
                        ];
                    return {
                        name: fieldDetails.label,
                        type:
                            fieldDetails.type === CustomFieldType.CHOICE &&
                            fieldDetails.constraints.fieldType ===
                                CustomFieldType.CHOICE &&
                            fieldDetails.constraints.choiceType ===
                                'MULTI_SELECT'
                                ? CustomFieldType.MULTI_CHOICE
                                : fieldDetails.type,
                        value:
                            fieldDetails.type === CustomFieldType.CHOICE &&
                            fieldDetails.constraints.fieldType ===
                                CustomFieldType.CHOICE &&
                            fieldDetails.constraints.choiceType ===
                                'MULTI_SELECT'
                                ? []
                                : null,
                        is_locked: true,
                        is_visible: fieldDetails.buyerSettings.showField,
                        description: fieldDetails.description,
                        is_required: fieldDetails.sellerSettings.isMandatory,
                        is_negotiable: fieldDetails.sellerSettings.negotiable,
                    };
                }),
    };
    let vendorInformation = {
        name: templateDetails.sections.VENDOR_INFORMATION.label,
        section_type: 'ITEM' as 'ITEM' | 'OTHER' | 'BOM',
        custom_fields: templateDetails.sections.VENDOR_INFORMATION.fieldList
            .filter((fieldDetails: any) => fieldDetails.fieldType === 'CUSTOM')
            .map((data) => {
                let fieldDetails =
                    templateDetails.fieldSettings.customFields[
                        data.field as any
                    ];
                return {
                    name: fieldDetails.label,
                    type:
                        fieldDetails.type === CustomFieldType.CHOICE &&
                        fieldDetails.constraints.fieldType ===
                            CustomFieldType.CHOICE &&
                        fieldDetails.constraints.choiceType === 'MULTI_SELECT'
                            ? CustomFieldType.MULTI_CHOICE
                            : fieldDetails.type,
                    value:
                        fieldDetails.type === CustomFieldType.CHOICE &&
                        fieldDetails.constraints.fieldType ===
                            CustomFieldType.CHOICE &&
                        fieldDetails.constraints.choiceType === 'MULTI_SELECT'
                            ? []
                            : null,
                    is_locked: true,
                    is_visible: fieldDetails.buyerSettings.showField,
                    description: fieldDetails.description,
                    is_required: fieldDetails.sellerSettings.isMandatory,
                    is_negotiable: fieldDetails.sellerSettings.negotiable,
                };
            }),
    };
    let additional = {
        name: templateDetails.sections.ADDITIONAL_DETAILS.label,
        section_type: 'ITEM' as 'ITEM' | 'OTHER' | 'BOM',
        custom_fields: templateDetails.sections.ADDITIONAL_DETAILS.fieldList
            .filter((fieldDetails: any) => fieldDetails.fieldType === 'CUSTOM')
            .map((data) => {
                let fieldDetails =
                    templateDetails.fieldSettings.customFields[
                        data.field as any
                    ];
                return {
                    name: fieldDetails.label,
                    type:
                        fieldDetails.type === CustomFieldType.CHOICE &&
                        fieldDetails.constraints.fieldType ===
                            CustomFieldType.CHOICE &&
                        fieldDetails.constraints.choiceType === 'MULTI_SELECT'
                            ? CustomFieldType.MULTI_CHOICE
                            : fieldDetails.type,
                    value:
                        fieldDetails.type === CustomFieldType.CHOICE &&
                        fieldDetails.constraints.fieldType ===
                            CustomFieldType.CHOICE &&
                        fieldDetails.constraints.choiceType === 'MULTI_SELECT'
                            ? []
                            : null,
                    is_locked: true,
                    is_visible: fieldDetails.buyerSettings.showField,
                    description: fieldDetails.description,
                    is_required: fieldDetails.sellerSettings.isMandatory,
                    is_negotiable: fieldDetails.sellerSettings.negotiable,
                };
            }),
    };

    return [itemTerms, paymentTerms, vendorInformation, additional];
};
export const translateEnterpriseItemToCartItem = (
    enterprise_item: IBuyerEnterpriseItemDetails,
    buyerEntityId: string,
    defaultCurrencyId: string,
    cart_id: string | null,
    templateDetails: IItemCartTemplate,
    cartTemplateDetails: IItemCartTemplate
): NewCartItem => {
    return {
        total: 0,
        buyer_entity_id: buyerEntityId,
        enterprise_item_id: enterprise_item.enterprise_item_id,
        custom_item_name: enterprise_item.name,
        cart_id: cart_id ? cart_id : null,
        measurement_unit_id:
            enterprise_item.measurement_units.item_measurement_units[0]
                ?.measurement_unit_id ?? null,
        quantity: 0,
        desired_price: 0,
        delivery_schedule_items: CartItemBlankDeliveryInformation.map(
            (delivery) => ({
                ...delivery,
                quantity: delivery.quantity ? delivery.quantity : 0,
            })
        ),
        quantity_tolerance_percentage: 0,
        payment_terms: {
            applied_from: StandardPaymentReferenceDateEnum.INVOICE_DATE as any,
            period: PeriodEnum.MONTHS,
            term: 1,
        },
        item_additional_details: '',
        payment_type:
            templateDetails.fieldSettings.standardFields['PAYMENT_TERMS']
                .paymentTermOptions.paymentType === 'invoiceItemValues'
                ? 'PER_INVOICE_ITEM'
                : 'PER_DELIVERABLE',
        prepayment_percentage: 0,
        deliverables_payment_terms: [],
        shipping_address_id: null,
        incoterm_id: 'bbb27fda-712f-4aa2-b42b-386fde9c2b50',
        lead_time: 0,
        lead_time_period: 'WEEKS',
        seller_contact_ids: [],
        seller_entity_ids: [],

        currency_id:
            enterprise_item.buyer_pricing_information?.currency_code_id ??
            defaultCurrencyId,
        custom_sections:
            getCustomSectionsFromTemplateForCart(cartTemplateDetails),
    };
};

export const translateCartitemToUpdatePayload = (
    cart_item: CartItemDetails,
    buyerEntityId: string,
    cart_id: string | null,
    defaultCurrencyId: string,
    templateDetails: IItemCartTemplate
): NewCartItem => {
    return {
        buyer_entity_id: buyerEntityId,
        total: +(cart_item.total ?? 0).toFixed(10),
        enterprise_item_id: cart_item.enterprise_item,
        custom_item_name: cart_item.item_information.item_name,
        cart_id: cart_id ? cart_id : null,
        measurement_unit_id: cart_item.measurement_unit,
        quantity: +cart_item.quantity,
        incoterm_id: cart_item.incoterm
            ? cart_item.incoterm
            : 'bbb27fda-712f-4aa2-b42b-386fde9c2b50',
        desired_price: +cart_item.pricing_information.desired_price,
        lead_time: cart_item.procurement_information.lead_time
            ? +cart_item.procurement_information.lead_time
            : 0,
        lead_time_period: cart_item.procurement_information.lead_time_period
            ? cart_item.procurement_information.lead_time_period
            : 'WEEKS',
        item_additional_details: cart_item.item_information
            .item_additional_details
            ? cart_item.item_information.item_additional_details
            : '',
        quantity_tolerance_percentage: +(
            cart_item.quantity_tolerance_percentage ?? 0
        ),
        save_for_later: cart_item.save_for_later,
        delivery_schedule_items: cart_item.delivery_information.map(
            (delivery) => ({
                cost_centre_id: Boolean(delivery.cost_centre_id)
                    ? delivery.cost_centre_id
                    : null,
                delivery_date: Boolean(delivery.delivery_date)
                    ? delivery.delivery_date
                    : null,
                delivery_schedule_item_id: Boolean(
                    delivery.delivery_schedule_item_id
                )
                    ? delivery.delivery_schedule_item_id
                    : null,
                general_ledger_id: Boolean(delivery.general_ledger_id)
                    ? delivery.general_ledger_id
                    : null,
                parent_delivery_schedule_item_id: Boolean(
                    delivery.parent_delivery_schedule_item_id
                )
                    ? delivery.parent_delivery_schedule_item_id
                    : null,
                project_id: Boolean(delivery.project_id)
                    ? delivery.project_id
                    : null,
                quantity: +(delivery.quantity ?? 0),
                requisition_item_id: Boolean(delivery.requisition_item_id)
                    ? delivery.requisition_item_id
                    : null,
            })
        ),
        deliverables_payment_terms:
            transformPaymentTermsFromFrontendToBackend({
                paymentTerms: cart_item.paymentTerms,
            }).deliverables_payment_terms ?? [],
        shipping_address_id: cart_item.shipping_address.shipping_address_id,
        payment_terms: transformPaymentTermsFromFrontendToBackend({
            paymentTerms: cart_item.paymentTerms,
        })?.payment_terms,
        prepayment_percentage: transformPaymentTermsFromFrontendToBackend({
            paymentTerms: cart_item.paymentTerms,
        }).prepayment_percentage,
        payment_type: transformPaymentTermsFromFrontendToBackend({
            paymentTerms: cart_item.paymentTerms,
        }).payment_type,

        seller_contact_ids: cart_item.seller_contacts,
        seller_entity_ids: cart_item.seller_entities,
        currency_id:
            cart_item.pricing_information?.currency_code_id ??
            defaultCurrencyId,
        additional_costs: cart_item.additional_costs,
        discounts: cart_item.discounts,
        taxes: cart_item.taxes,
        questions: cart_item.questions,
        custom_sections: cart_item.custom_sections,
        custom_fields: cart_item.custom_fields,
        attributes: cart_item.attributes
            .filter((attr) => attr.attribute_id === null)
            .map((attr) => {
                return {
                    attribute_linkage_id: attr.attribute_linkage_id,
                    attribute_id: attr.attribute_id,
                    attribute_name: attr.attribute_name,
                    attribute_type: attr.attribute_type,
                    attribute_values: attr.attribute_values,
                };
            }),
    };
};
