import { getTemplateFrontOptionFromListOfTemplateItems } from '../../AdditionalCost/helpers';
import {
    ITemplateListDetailsResponse,
    TemplateItemsResponse,
} from '../../BuyerTemplates/Interfaces/TemplateAPIData';
import {
    TemplateData,
    TemplateItemTypes,
    TemplateStatus,
    templateType,
} from '../../BuyerTemplates/Interfaces/TemplatePageInterfaces';
import { getFieldTypeContraintsFromBackend } from '../../FieldTypes/helpers/fieldTypesHelpers';
import { baseApiSlice } from '../../Redux/Slices/BaseApiSlice';
import { get } from '../../Utils/api';
import {
    CustomFieldType,
    IRFQTemplateBackendResponse,
    IRFQTemplateItemBackendResponse,
    IRfqTemplate,
    ITemplatePaymentTermsOptions,
    ITemplatePaymentTermsOptionsInvoiceItemValues,
    ITemplatePaymentTermsOptionsPoItemPercentages,
    TemplateStandardFieldBackendNames,
    TemplateStandardFieldsEnum,
} from '../Interfaces/TemplateInterface';
import {
    blankRfqTemplate,
    dummyPaymentTermsOptionsInvoiceItemValues,
    dummyPaymentTermsOptionsPoItemPercentages,
} from './templateHelpers';
// const paymentTermsToUse: 'inv' | 'po' = 'po';
export const dummyPaymentTermsOptions = false
    ? // paymentTermsToUse === 'inv'
      dummyPaymentTermsOptionsInvoiceItemValues
    : dummyPaymentTermsOptionsPoItemPercentages;

const enum TemplatePaymentTermsStandardInvoiceOptionsEnum {
    DISPATCH_DATE = 'Dispatch date',
    INVOICE_DATE = 'Invoice date',
    RECEIPT_DATE = 'Receipt date',
}

const enum TemplatePaymentTermsTypesEnum {
    INVOICE = 'Pay as per Invoice terms',
    PO = 'Pay as per PO terms',
}

export const getPaymentTermsDetails = (
    paymentTermsSectionItems: IRFQTemplateItemBackendResponse[]
):
    | ITemplatePaymentTermsOptionsInvoiceItemValues
    | ITemplatePaymentTermsOptionsPoItemPercentages => {
    const requiredTerms: {
        [key: string]: IRFQTemplateItemBackendResponse;
    } = {};

    paymentTermsSectionItems.forEach((item) => {
        if (
            [
                'Payment terms',
                // 'Payment type',
                // 'Payment from options',
                // 'Prepayment percentage',
            ].includes(item.name)
        ) {
            requiredTerms[item.name] = item;
        }
    });

    // const newPaymentTermsOptions: {
    //     [key: string]: any[];
    // } = {};

    // requiredTerms['Payment terms']?.options?.forEach((option) => {
    //     if (!newPaymentTermsOptions[option.conditions.value]) {
    //         newPaymentTermsOptions[option.conditions.value] = [];
    //     }
    //     newPaymentTermsOptions[option.conditions.value].push({
    //         section_item_option_id: option.section_item_option_id,
    //         name: option.name,
    //         alternate_name: option.alternate_name,
    //         description: option.description,
    //         isFactwise: option.is_builtin,
    //     });
    // });

    const selectedOptionNames =
        requiredTerms[
            'Payment terms'
        ]?.additional_information.payment_from_options?.map((option) =>
            option.toLowerCase()
        ) ?? [];

    const paymentType =
        requiredTerms['Payment terms']?.additional_information.payment_type;
    // const paymentFromOptions =
    //     requiredTerms['Payment from options']?.constraints?.choices;
    const paymentTermsAllOptions =
        requiredTerms['Payment terms']?.options?.map((option) => {
            return {
                name: option.name,
                alternate_name: option.alternate_name,
                description: option.description,
                isFactwise: option.is_builtin,
            };
        }) ?? [];
    const showPrepaymentPercentage = Boolean(
        requiredTerms['Payment terms']?.additional_information?.allow_prepayment
    );

    if (paymentType === TemplatePaymentTermsTypesEnum.INVOICE) {
        const allPaymentOptions: {
            standardOptions: {
                [key: string]: {
                    label: string;
                    enabled: boolean;
                };
            };
            CustomOptions: string[];
        } = {
            standardOptions: {
                'Dispatch date': {
                    label: TemplatePaymentTermsStandardInvoiceOptionsEnum.DISPATCH_DATE,
                    enabled: false,
                },
                'Invoice date': {
                    label: TemplatePaymentTermsStandardInvoiceOptionsEnum.INVOICE_DATE,
                    enabled: false,
                },
                'Receipt date': {
                    label: TemplatePaymentTermsStandardInvoiceOptionsEnum.RECEIPT_DATE,
                    enabled: false,
                },
            },
            CustomOptions: [],
        };

        paymentTermsAllOptions?.forEach((option) => {
            if (option.isFactwise) {
                if (
                    ['Dispatch date', 'Invoice date', 'Receipt date'].includes(
                        option.name
                    ) &&
                    selectedOptionNames?.includes(option.name.toLowerCase())
                ) {
                    allPaymentOptions.standardOptions[option.name] = {
                        label: option.alternate_name,
                        enabled: true,
                    };
                }
            } else if (selectedOptionNames?.includes(option.name)) {
                allPaymentOptions.CustomOptions.push(option.alternate_name);
            }
        });

        const paymentDetails: ITemplatePaymentTermsOptionsInvoiceItemValues = {
            paymentType: 'invoiceItemValues',
            allowOtherReferenceDate: true,
            prepaymentAllowed: showPrepaymentPercentage,
            standardReferenceDateOptions: {
                DISPATCH_DATE:
                    allPaymentOptions.standardOptions['Dispatch date'],
                INVOICE_DATE: allPaymentOptions.standardOptions['Invoice date'],
                RECEIPT_DATE: allPaymentOptions.standardOptions['Receipt date'],
            },
            customReferenceDateOptions: allPaymentOptions.CustomOptions,
        };
        return paymentDetails;
    } else if (paymentType === TemplatePaymentTermsTypesEnum.PO) {
        const paymentDetails: ITemplatePaymentTermsOptionsPoItemPercentages = {
            paymentType: 'poItemPercentages',
            prepaymentAllowed: showPrepaymentPercentage,
            allowOtherDeliverableNames: true,
            deliverableNameOptions: selectedOptionNames ?? [],
        };
        return paymentDetails;
    }

    return dummyPaymentTermsOptionsInvoiceItemValues;
};

export const templateApiSlice = baseApiSlice.injectEndpoints({
    endpoints: (builder) => ({
        getTemplateById: builder.query<
            IRfqTemplate,
            { templateId: string; entityId: string }
        >({
            query: ({ entityId, templateId }) =>
                `/module_templates/${entityId}/${templateId}`,
            transformResponse: (
                baseQueryReturnValue: IRFQTemplateBackendResponse,
                meta,
                arg
            ): IRfqTemplate => {
                const response: IRfqTemplate = JSON.parse(
                    JSON.stringify(blankRfqTemplate)
                );

                response.templateId = baseQueryReturnValue.template_id;
                response.templateName = baseQueryReturnValue.name;
                response.linked_templates =
                    baseQueryReturnValue.linked_templates;

                response.auto_reminders = baseQueryReturnValue.auto_reminders;

                const templateSections = baseQueryReturnValue.section_list.map(
                    (section) => {
                        return {
                            ...section,
                            section_items: section.section_items.map(
                                (item: any) => {
                                    return {
                                        ...item,
                                        alternate_name:
                                            item.alternate_name.replace(
                                                '_',
                                                ''
                                            ),
                                        name: item.name.replace('_', ''),
                                    };
                                }
                            ),
                        };
                    }
                );
                baseQueryReturnValue = {
                    ...baseQueryReturnValue,
                    section_list: templateSections,
                };

                // const eventDetailsFromBackend =
                //     baseQueryReturnValue.section_list.find(
                //         (section) => section.name === 'PO Group Details'
                //     );

                const eventDetailsFromBackend =
                    baseQueryReturnValue.section_list.find(
                        (section) =>
                            section.name === 'PO Group Details' ||
                            section.name === 'Event Details'
                    );

                response.sections.EVENT_DETAILS = {
                    fieldList: [],
                    label:
                        eventDetailsFromBackend &&
                        eventDetailsFromBackend.alternate_name
                            ? eventDetailsFromBackend.alternate_name
                            : 'Event details',
                };

                eventDetailsFromBackend?.section_items
                    ?.filter(
                        (eventInfo) =>
                            !['Overall discount information'].includes(
                                eventInfo.name
                            )
                    )
                    .forEach((eventInfo) => {
                        let formattedData = {
                            label: eventInfo.alternate_name
                                ? eventInfo.alternate_name
                                : eventInfo.name,
                            description: eventInfo.description,
                            buyerSettings: {
                                required: eventInfo.is_required,
                                showField: !(
                                    eventInfo.additional_information
                                        .is_hidden ?? false
                                ),
                            },
                            canSplit: eventInfo.can_split,
                            sendToPo: eventInfo.send_to_po,
                            sellerSettings: {
                                negotiable:
                                    !(
                                        eventInfo.additional_information
                                            .is_hidden ?? false
                                    ) &&
                                    (eventInfo.additional_information
                                        .is_visible ??
                                        true) &&
                                    (eventInfo.additional_information
                                        .is_negotiable ??
                                        true),
                                showField:
                                    !(
                                        eventInfo.additional_information
                                            .is_hidden ?? false
                                    ) &&
                                    (eventInfo.additional_information
                                        .is_visible ??
                                        true),
                                isMandatory: eventInfo.is_mandatory,
                            },
                            fieldLevel: eventInfo.field_level,
                        };

                        //
                        if (eventInfo?.is_builtin_field) {
                            if (
                                TemplateStandardFieldBackendNames[
                                    eventInfo.name
                                ] === TemplateStandardFieldsEnum.OVERALL_TAX
                            ) {
                                response.fieldSettings.standardFields[
                                    TemplateStandardFieldsEnum.OVERALL_TAX
                                ] = {
                                    ...formattedData,
                                    standardFieldCategory: 'TAX',
                                    taxesOptions:
                                        getTemplateFrontOptionFromListOfTemplateItems(
                                            eventDetailsFromBackend?.section_items.filter(
                                                (sectionItem) =>
                                                    sectionItem.parent_section_item ===
                                                    eventInfo.section_item_id
                                            ) ?? []
                                        ),
                                    buyerSettings: {
                                        ...formattedData.buyerSettings,
                                        showField: formattedData.buyerSettings
                                            .showField
                                            ? getTemplateFrontOptionFromListOfTemplateItems(
                                                  eventDetailsFromBackend?.section_items.filter(
                                                      (sectionItem) =>
                                                          sectionItem.parent_section_item ===
                                                          eventInfo.section_item_id
                                                  ) ?? []
                                              ).length > 0
                                            : false,
                                    },
                                    sellerSettings: {
                                        ...formattedData.sellerSettings,
                                        showField: formattedData.sellerSettings
                                            .showField
                                            ? getTemplateFrontOptionFromListOfTemplateItems(
                                                  eventDetailsFromBackend?.section_items.filter(
                                                      (sectionItem) =>
                                                          sectionItem.parent_section_item ===
                                                          eventInfo.section_item_id
                                                  ) ?? []
                                              ).length > 0
                                            : false,
                                    },
                                    fieldLevel: eventInfo.field_level,
                                };
                            } else if (
                                TemplateStandardFieldBackendNames[
                                    eventInfo.name
                                ] ===
                                TemplateStandardFieldsEnum.OVERALL_ADDITIONAL_COSTS
                            ) {
                                response.fieldSettings.standardFields[
                                    TemplateStandardFieldsEnum.OVERALL_ADDITIONAL_COSTS
                                ] = {
                                    ...formattedData,
                                    standardFieldCategory: 'ADDITIONAL_COSTS',
                                    additionalCostOptions:
                                        getTemplateFrontOptionFromListOfTemplateItems(
                                            eventDetailsFromBackend?.section_items.filter(
                                                (sectionItem) =>
                                                    sectionItem.parent_section_item ===
                                                    eventInfo.section_item_id
                                            ) ?? []
                                        ),
                                    buyerSettings: {
                                        ...formattedData.buyerSettings,
                                        showField: formattedData.buyerSettings
                                            .showField
                                            ? getTemplateFrontOptionFromListOfTemplateItems(
                                                  eventDetailsFromBackend?.section_items.filter(
                                                      (sectionItem) =>
                                                          sectionItem.parent_section_item ===
                                                          eventInfo.section_item_id
                                                  ) ?? []
                                              ).length > 0
                                            : false,
                                    },
                                    sellerSettings: {
                                        ...formattedData.sellerSettings,
                                        showField: formattedData.sellerSettings
                                            .showField
                                            ? getTemplateFrontOptionFromListOfTemplateItems(
                                                  eventDetailsFromBackend?.section_items.filter(
                                                      (sectionItem) =>
                                                          sectionItem.parent_section_item ===
                                                          eventInfo.section_item_id
                                                  ) ?? []
                                              ).length > 0
                                            : false,
                                    },
                                    fieldLevel: eventInfo.field_level,
                                };
                            } else if (
                                TemplateStandardFieldBackendNames[
                                    eventInfo.name
                                ] ===
                                TemplateStandardFieldsEnum.OVERALL_DISCOUNT
                            ) {
                                const listOfSectionItemsFromTemplate =
                                    eventDetailsFromBackend?.section_items.filter(
                                        (sectionItem) =>
                                            sectionItem.section_item_id ===
                                            eventInfo.section_item_id
                                    ) ?? [];

                                listOfSectionItemsFromTemplate.push(eventInfo);

                                response.fieldSettings.standardFields[
                                    TemplateStandardFieldsEnum.OVERALL_DISCOUNT
                                ] = {
                                    ...formattedData,
                                    standardFieldCategory: 'DISCOUNT',
                                    discountOptions:
                                        getTemplateFrontOptionFromListOfTemplateItems(
                                            listOfSectionItemsFromTemplate
                                        ),
                                    buyerSettings: {
                                        ...formattedData.buyerSettings,
                                        showField: formattedData.buyerSettings
                                            .showField
                                            ? getTemplateFrontOptionFromListOfTemplateItems(
                                                  listOfSectionItemsFromTemplate
                                              ).length > 0
                                            : false,
                                    },
                                    sellerSettings: {
                                        ...formattedData.sellerSettings,
                                        showField: formattedData.sellerSettings
                                            .showField
                                            ? getTemplateFrontOptionFromListOfTemplateItems(
                                                  listOfSectionItemsFromTemplate
                                              ).length > 0
                                            : false,
                                    },
                                    fieldLevel: eventInfo.field_level,
                                };
                            } else {
                                response.fieldSettings.standardFields[
                                    TemplateStandardFieldBackendNames[
                                        eventInfo.name
                                    ]
                                ] = formattedData as any;

                                response.sections.EVENT_DETAILS.fieldList.push({
                                    field: TemplateStandardFieldBackendNames[
                                        eventInfo.name
                                    ],
                                    fieldType: 'STANDARD',
                                });
                            }
                        } else {
                            if (eventInfo?.parent_section_item === null) {
                                response.sections.EVENT_DETAILS.fieldList.push({
                                    field: eventInfo.name,
                                    fieldType: 'CUSTOM',
                                });
                                response.fieldSettings.customFields[
                                    eventInfo.name
                                ] = {
                                    label: eventInfo.alternate_name
                                        ? eventInfo.alternate_name
                                        : eventInfo.name,
                                    description: eventInfo.description,
                                    sendToPo: eventInfo.send_to_po,
                                    canSplit: eventInfo.can_split,
                                    buyerSettings: {
                                        required: eventInfo.is_required,
                                        showField: !(
                                            eventInfo.additional_information
                                                .is_hidden ?? false
                                        ),
                                    },
                                    sellerSettings: {
                                        negotiable:
                                            !(
                                                eventInfo.additional_information
                                                    .is_hidden ?? false
                                            ) &&
                                            (eventInfo.additional_information
                                                .is_visible ??
                                                true) &&
                                            (eventInfo.additional_information
                                                .is_negotiable ??
                                                true),
                                        showField:
                                            !(
                                                eventInfo.additional_information
                                                    .is_hidden ?? false
                                            ) &&
                                            (eventInfo.additional_information
                                                .is_visible ??
                                                true),
                                        isMandatory: eventInfo.is_mandatory,
                                    },
                                    fieldLevel: eventInfo.field_level,
                                    type: convertTemplateConstraintsItemToCustomFIeldType(
                                        eventInfo.constraints.field_type
                                    ),
                                    constraints:
                                        getFieldTypeContraintsFromBackend({
                                            field_type:
                                                eventInfo.constraints
                                                    .field_type,
                                            choices:
                                                eventInfo.constraints.choices,
                                            max_limit:
                                                eventInfo.constraints.max_limit,
                                            min_limit:
                                                eventInfo.constraints.min_limit,
                                            choice_type:
                                                eventInfo.constraints
                                                    .choice_type,
                                        }),
                                };
                            }
                        }
                    });

                // traversing of essential terms from backend and updating response
                const essentialTermsfromBackend =
                    baseQueryReturnValue.section_list.find(
                        (section) => section.name === 'Item Terms'
                    );

                response.sections.ESSENTIAL_TERMS = {
                    fieldList: [],
                    label:
                        essentialTermsfromBackend &&
                        essentialTermsfromBackend?.alternate_name
                            ? essentialTermsfromBackend.alternate_name
                            : 'Item Terms',
                };

                const essentialTermsItemsfromBackend =
                    essentialTermsfromBackend!.section_items
                        .filter(
                            (item) =>
                                [
                                    'Quantity',
                                    'Delivery date',
                                    'Cost center',
                                    'Customer name',
                                    'GL',
                                    'Project',
                                    'Additional costs and Taxes',
                                    'Discount',
                                    'BOM',
                                ].includes(item.name) ||
                                item.parent_section_item === null
                        )
                        .filter(
                            (item) =>
                                ![
                                    'Item',
                                    'Specification',
                                    'Measurement unit',
                                    'Quantity Information',
                                    'Discount information',
                                ].includes(item.name)
                        );

                essentialTermsItemsfromBackend.forEach((item) => {
                    if (
                        Object.keys(TemplateStandardFieldBackendNames).includes(
                            item.name
                        )
                    ) {
                        if (
                            !item.additional_information.is_hidden &&
                            item.name !== 'Delivery date'
                        ) {
                            if (item.field_level === 'OTHER') {
                                response.sections.EVENT_DETAILS.fieldList.push({
                                    fieldType: 'STANDARD',
                                    field: TemplateStandardFieldBackendNames[
                                        item.name
                                    ],
                                });
                            } else {
                                response.sections.ESSENTIAL_TERMS.fieldList.push(
                                    {
                                        fieldType: 'STANDARD',
                                        field: TemplateStandardFieldBackendNames[
                                            item.name
                                        ],
                                    }
                                );
                            }
                        }

                        let formattedData = {
                            label: item.alternate_name
                                ? item.alternate_name
                                : item.name,
                            description: item.description,
                            buyerSettings: {
                                required: item.is_required,
                                showField: !(
                                    item.additional_information.is_hidden ??
                                    false
                                ),
                            },
                            sendToPo: item.send_to_po,
                            canSplit: item.can_split,
                            sellerSettings: {
                                negotiable:
                                    !(
                                        item.additional_information.is_hidden ??
                                        false
                                    ) &&
                                    (item.additional_information.is_visible ??
                                        true) &&
                                    (item.additional_information
                                        .is_negotiable ??
                                        true),
                                showField:
                                    !(
                                        item.additional_information.is_hidden ??
                                        false
                                    ) &&
                                    (item.additional_information.is_visible ??
                                        true),
                                isMandatory: item.is_mandatory,
                            },
                            fieldLevel: item.field_level,
                        };
                        if (
                            TemplateStandardFieldBackendNames[item.name] ===
                            TemplateStandardFieldsEnum.PAYMENT_TERMS
                        ) {
                            const formattedDataWithPaymentOptions: ITemplatePaymentTermsOptions =
                                {
                                    ...formattedData,
                                    paymentTermOptions:
                                        // TODO: Remove dummy data
                                        dummyPaymentTermsOptions,
                                    standardFieldCategory: 'PAYMENT_TERMS',
                                };

                            response.fieldSettings.standardFields[
                                TemplateStandardFieldsEnum.PAYMENT_TERMS
                            ] = formattedDataWithPaymentOptions;
                        } else if (
                            TemplateStandardFieldBackendNames[item.name] ===
                            TemplateStandardFieldsEnum.TAX
                        ) {
                            response.fieldSettings.standardFields[
                                TemplateStandardFieldsEnum.TAX
                            ] = {
                                ...formattedData,
                                standardFieldCategory: 'TAX',
                                taxesOptions:
                                    getTemplateFrontOptionFromListOfTemplateItems(
                                        essentialTermsfromBackend?.section_items.filter(
                                            (sectionItem) =>
                                                sectionItem.parent_section_item ===
                                                item.section_item_id
                                        ) ?? []
                                    ),
                                buyerSettings: {
                                    ...formattedData.buyerSettings,
                                    showField: formattedData.buyerSettings
                                        .showField
                                        ? getTemplateFrontOptionFromListOfTemplateItems(
                                              essentialTermsfromBackend?.section_items.filter(
                                                  (sectionItem) =>
                                                      sectionItem.parent_section_item ===
                                                      item.section_item_id
                                              ) ?? []
                                          ).length > 0
                                        : false,
                                },
                                sellerSettings: {
                                    ...formattedData.sellerSettings,
                                    showField: formattedData.sellerSettings
                                        .showField
                                        ? getTemplateFrontOptionFromListOfTemplateItems(
                                              essentialTermsfromBackend?.section_items.filter(
                                                  (sectionItem) =>
                                                      sectionItem.parent_section_item ===
                                                      item.section_item_id
                                              ) ?? []
                                          ).length > 0
                                        : false,
                                },
                                fieldLevel: item.field_level,
                            };
                        } else if (
                            TemplateStandardFieldBackendNames[item.name] ===
                            TemplateStandardFieldsEnum.ADDITIONAL_COSTS
                        ) {
                            response.fieldSettings.standardFields[
                                TemplateStandardFieldsEnum.ADDITIONAL_COSTS
                            ] = {
                                ...formattedData,
                                standardFieldCategory: 'ADDITIONAL_COSTS',
                                additionalCostOptions:
                                    getTemplateFrontOptionFromListOfTemplateItems(
                                        essentialTermsfromBackend?.section_items.filter(
                                            (sectionItem) =>
                                                sectionItem.parent_section_item ===
                                                item.section_item_id
                                        ) ?? []
                                    ),
                                buyerSettings: {
                                    ...formattedData.buyerSettings,
                                    showField: formattedData.buyerSettings
                                        .showField
                                        ? getTemplateFrontOptionFromListOfTemplateItems(
                                              essentialTermsfromBackend?.section_items.filter(
                                                  (sectionItem) =>
                                                      sectionItem.parent_section_item ===
                                                      item.section_item_id
                                              ) ?? []
                                          ).length > 0
                                        : false,
                                },
                                sellerSettings: {
                                    ...formattedData.sellerSettings,
                                    showField: formattedData.sellerSettings
                                        .showField
                                        ? getTemplateFrontOptionFromListOfTemplateItems(
                                              essentialTermsfromBackend?.section_items.filter(
                                                  (sectionItem) =>
                                                      sectionItem.parent_section_item ===
                                                      item.section_item_id
                                              ) ?? []
                                          ).length > 0
                                        : false,
                                },
                                fieldLevel: item.field_level,
                            };
                        } else if (
                            TemplateStandardFieldBackendNames[item.name] ===
                            TemplateStandardFieldsEnum.DISCOUNT
                        ) {
                            const listOfSectionItemsFromTemplate =
                                essentialTermsfromBackend?.section_items.filter(
                                    (sectionItem) =>
                                        sectionItem.parent_section_item ===
                                        item.section_item_id
                                ) ?? [];

                            listOfSectionItemsFromTemplate.push(item);

                            response.fieldSettings.standardFields[
                                TemplateStandardFieldsEnum.DISCOUNT
                            ] = {
                                ...formattedData,
                                standardFieldCategory: 'DISCOUNT',
                                discountOptions:
                                    getTemplateFrontOptionFromListOfTemplateItems(
                                        listOfSectionItemsFromTemplate
                                    ),
                                buyerSettings: {
                                    ...formattedData.buyerSettings,
                                    showField: formattedData.buyerSettings
                                        .showField
                                        ? getTemplateFrontOptionFromListOfTemplateItems(
                                              listOfSectionItemsFromTemplate
                                          ).length > 0
                                        : false,
                                },
                                sellerSettings: {
                                    ...formattedData.sellerSettings,
                                    showField: formattedData.sellerSettings
                                        .showField
                                        ? getTemplateFrontOptionFromListOfTemplateItems(
                                              listOfSectionItemsFromTemplate
                                          ).length > 0
                                        : false,
                                },
                                fieldLevel: item.field_level,
                            };
                        } else {
                            response.fieldSettings.standardFields = {
                                ...response.fieldSettings.standardFields,
                                [TemplateStandardFieldBackendNames[item.name]]:
                                    formattedData,
                            };
                        }
                    } else if (
                        item.name !== 'Discounts' &&
                        item.name !== 'Tax'
                    ) {
                        if (!item.additional_information.is_hidden)
                            response.sections.ESSENTIAL_TERMS.fieldList.push({
                                fieldType: 'CUSTOM',
                                field: item.name,
                            });
                        response.fieldSettings.customFields[item.name] = {
                            label: item.alternate_name
                                ? item.alternate_name
                                : item.name,
                            description: item.description,
                            buyerSettings: {
                                required: item.is_required,
                                showField: !(
                                    item.additional_information.is_hidden ??
                                    false
                                ),
                            },
                            sendToPo: item.send_to_po,
                            canSplit: item.can_split,

                            sellerSettings: {
                                negotiable:
                                    !(
                                        item.additional_information.is_hidden ??
                                        false
                                    ) &&
                                    (item.additional_information.is_visible ??
                                        true) &&
                                    (item.additional_information
                                        .is_negotiable ??
                                        true),
                                showField:
                                    !(
                                        item.additional_information.is_hidden ??
                                        false
                                    ) &&
                                    (item.additional_information.is_visible ??
                                        true),
                                isMandatory: item.is_mandatory,
                            },
                            fieldLevel: item.field_level,
                            type: convertTemplateConstraintsItemToCustomFIeldType(
                                item.constraints.field_type
                            ),
                            constraints: getFieldTypeContraintsFromBackend({
                                field_type: item.constraints.field_type,
                                choices: item.constraints.choices,
                                max_limit: item.constraints.max_limit,
                                min_limit: item.constraints.min_limit,
                                choice_type: item.constraints.choice_type,
                            }),
                        };
                    }
                });

                const paymentDeliveryfromBackend =
                    baseQueryReturnValue.section_list.find(
                        (section) =>
                            section.name === 'Payment and Delivery Terms'
                    );

                response.sections.PAYMENT_AND_DELIVERY_TERMS.label =
                    paymentDeliveryfromBackend &&
                    paymentDeliveryfromBackend.alternate_name
                        ? paymentDeliveryfromBackend.alternate_name
                        : 'Payment and Delivery Terms';
                const paymentDeliveryItemsfromBackend =
                    paymentDeliveryfromBackend!.section_items
                        .filter((item) => item.parent_section_item === null)
                        .filter((item) => ![''].includes(item.name));

                paymentDeliveryItemsfromBackend.forEach((item) => {
                    if (
                        Object.keys(TemplateStandardFieldBackendNames).includes(
                            item.name
                        )
                    ) {
                        if (!item.additional_information.is_hidden)
                            if (item.field_level === 'OTHER') {
                                response.sections.EVENT_DETAILS.fieldList.push({
                                    fieldType: 'STANDARD',
                                    field: TemplateStandardFieldBackendNames[
                                        item.name
                                    ],
                                });
                            } else {
                                response.sections.PAYMENT_AND_DELIVERY_TERMS.fieldList.push(
                                    {
                                        fieldType: 'STANDARD',
                                        field: TemplateStandardFieldBackendNames[
                                            item.name
                                        ],
                                    }
                                );
                            }

                        const formattedData = {
                            label: item.alternate_name
                                ? item.alternate_name
                                : item.name,
                            description: item.description,
                            buyerSettings: {
                                required: item.is_required,
                                showField: !(
                                    item.additional_information.is_hidden ??
                                    false
                                ),
                            },
                            sendToPo: item.send_to_po,
                            canSplit: item.can_split,
                            sellerSettings: {
                                negotiable:
                                    !(
                                        item.additional_information.is_hidden ??
                                        false
                                    ) &&
                                    (item.additional_information.is_visible ??
                                        true) &&
                                    (item.additional_information
                                        .is_negotiable ??
                                        true),
                                showField:
                                    !(
                                        item.additional_information.is_hidden ??
                                        false
                                    ) &&
                                    (item.additional_information.is_visible ??
                                        true),
                                isMandatory: item.is_mandatory,
                            },
                            fieldLevel: item.field_level,
                        };
                        if (
                            TemplateStandardFieldBackendNames[item.name] ===
                            TemplateStandardFieldsEnum.PAYMENT_TERMS
                        ) {
                            const paymentTermsOption = getPaymentTermsDetails(
                                paymentDeliveryfromBackend!.section_items
                            );
                            const formattedDataWithPaymentOptions: ITemplatePaymentTermsOptions =
                                {
                                    ...formattedData,
                                    paymentTermOptions: paymentTermsOption,
                                    standardFieldCategory: 'PAYMENT_TERMS',
                                };
                            response.fieldSettings.standardFields[
                                TemplateStandardFieldsEnum.PAYMENT_TERMS
                            ] = formattedDataWithPaymentOptions;
                        } else {
                            response.fieldSettings.standardFields[
                                TemplateStandardFieldBackendNames[item.name]
                            ] = formattedData as any;
                        }
                    } else if (item.name !== 'Prepayment percentage') {
                        // if (!item.additional_information.is_hidden)
                        response.sections.PAYMENT_AND_DELIVERY_TERMS.fieldList.push(
                            {
                                fieldType: 'CUSTOM',
                                field: item.name,
                            }
                        );
                        response.fieldSettings.customFields[item.name] = {
                            label: item.alternate_name
                                ? item.alternate_name
                                : item.name,
                            description: item.description,
                            buyerSettings: {
                                required: item.is_required,
                                showField: !(
                                    item.additional_information.is_hidden ??
                                    false
                                ),
                            },
                            sendToPo: item.send_to_po,
                            canSplit: item.can_split,
                            fieldLevel: item.field_level,
                            sellerSettings: {
                                negotiable:
                                    !(
                                        item.additional_information.is_hidden ??
                                        false
                                    ) &&
                                    (item.additional_information.is_visible ??
                                        true) &&
                                    (item.additional_information
                                        .is_negotiable ??
                                        true),
                                showField:
                                    !(
                                        item.additional_information.is_hidden ??
                                        false
                                    ) &&
                                    (item.additional_information.is_visible ??
                                        true),
                                isMandatory: item.is_mandatory,
                            },
                            type: convertTemplateConstraintsItemToCustomFIeldType(
                                item.constraints.field_type
                            ),
                            constraints: getFieldTypeContraintsFromBackend({
                                field_type: item.constraints.field_type,
                                choices: item.constraints.choices,
                                max_limit: item.constraints.max_limit,
                                min_limit: item.constraints.min_limit,
                                choice_type: item.constraints.choice_type,
                            }),
                        };
                    }
                });

                // traversing of adition information terms from backend and updating response

                const addtionalInformationfromBackend =
                    baseQueryReturnValue.section_list.find(
                        (section) => section.name === 'Additional Details'
                    );
                response.sections.ADDITIONAL_DETAILS.label =
                    addtionalInformationfromBackend &&
                    addtionalInformationfromBackend.alternate_name
                        ? addtionalInformationfromBackend.alternate_name
                        : 'Additional DetailsTerms';
                const addtionalInformationItemsfromBackend =
                    addtionalInformationfromBackend?.section_items
                        .filter((item) => item.parent_section_item === null)
                        .filter((item) => ![''].includes(item.name));
                addtionalInformationItemsfromBackend?.forEach((item) => {
                    if (
                        Object.keys(TemplateStandardFieldBackendNames).includes(
                            item.name
                        )
                    ) {
                        // if (!item.additional_information.is_hidden)
                        if (item.field_level === 'OTHER') {
                            response.sections.EVENT_DETAILS.fieldList.push({
                                fieldType: 'STANDARD',
                                field: TemplateStandardFieldBackendNames[
                                    item.name
                                ],
                            });
                        } else {
                            response.sections.ADDITIONAL_DETAILS.fieldList.push(
                                {
                                    fieldType: 'STANDARD',
                                    field: TemplateStandardFieldBackendNames[
                                        item.name
                                    ],
                                }
                            );
                        }

                        const formattedData = {
                            label: item.alternate_name
                                ? item.alternate_name
                                : item.name,
                            description: item.description,
                            buyerSettings: {
                                required: item.is_required,
                                showField: !(
                                    item.additional_information.is_hidden ??
                                    false
                                ),
                            },
                            canSplit: item.can_split,
                            sendToPo: item.send_to_po,
                            sellerSettings: {
                                negotiable:
                                    !(
                                        item.additional_information.is_hidden ??
                                        false
                                    ) &&
                                    (item.additional_information.is_visible ??
                                        true) &&
                                    (item.additional_information
                                        .is_negotiable ??
                                        true),
                                showField:
                                    !(
                                        item.additional_information.is_hidden ??
                                        false
                                    ) &&
                                    (item.additional_information.is_visible ??
                                        true),
                            },
                            fieldLevel: item.field_level,
                        };

                        response.fieldSettings.standardFields = {
                            ...response.fieldSettings.standardFields,
                            [TemplateStandardFieldBackendNames[item.name]]:
                                formattedData,
                        };
                    } else {
                        response.sections.ADDITIONAL_DETAILS.fieldList.push({
                            fieldType: 'CUSTOM',
                            field: item.name,
                        });
                        response.fieldSettings.customFields[item.name] = {
                            label: item.alternate_name
                                ? item.alternate_name
                                : item.name,
                            description: item.description,
                            sendToPo: item.send_to_po,
                            canSplit: item.can_split,

                            buyerSettings: {
                                required: item.is_required,
                                showField: !(
                                    item.additional_information.is_hidden ??
                                    false
                                ),
                            },
                            fieldLevel: item.field_level,
                            sellerSettings: {
                                negotiable:
                                    !(
                                        item.additional_information.is_hidden ??
                                        false
                                    ) &&
                                    (item.additional_information.is_visible ??
                                        true) &&
                                    (item.additional_information
                                        .is_negotiable ??
                                        true),
                                showField:
                                    !(
                                        item.additional_information.is_hidden ??
                                        false
                                    ) &&
                                    (item.additional_information.is_visible ??
                                        true),
                                isMandatory: item.is_mandatory,
                            },
                            type: convertTemplateConstraintsItemToCustomFIeldType(
                                item.constraints.field_type
                            ),
                            constraints: getFieldTypeContraintsFromBackend({
                                field_type: item.constraints.field_type,
                                choices: item.constraints.choices,
                                max_limit: item.constraints.max_limit,
                                min_limit: item.constraints.min_limit,
                                choice_type: item.constraints.choice_type,
                            }),
                        };
                    }
                });

                const vendorInformationFromBackend =
                    baseQueryReturnValue.section_list.find(
                        (section) => section.name === 'Vendor Information'
                    );
                response.sections.VENDOR_INFORMATION.label =
                    vendorInformationFromBackend &&
                    vendorInformationFromBackend.alternate_name
                        ? vendorInformationFromBackend.alternate_name
                        : 'Vendor Information';
                const vendorInformationItemsFromBackend =
                    vendorInformationFromBackend?.section_items
                        .filter((item) => item.parent_section_item === null)
                        .filter((item) => ![''].includes(item.name));

                vendorInformationItemsFromBackend?.forEach((item) => {
                    if (
                        Object.keys(TemplateStandardFieldBackendNames).includes(
                            item.name
                        )
                    ) {
                        // if (!item.additional_information.is_hidden)
                        // if (item.field_level === 'OTHER') {
                        //     response.sections.EVENT_DETAILS.fieldList.push({
                        //         fieldType: 'STANDARD',
                        //         field: TemplateStandardFieldBackendNames[
                        //             item.name
                        //         ],
                        //     });
                        // } else {
                        //     response.sections.ADDITIONAL_DETAILS.fieldList.push(
                        //         {
                        //             fieldType: 'STANDARD',
                        //             field: TemplateStandardFieldBackendNames[
                        //                 item.name
                        //             ],
                        //         }
                        //     );
                        // }

                        response.sections.VENDOR_INFORMATION.fieldList.push({
                            fieldType: 'STANDARD',
                            field: TemplateStandardFieldBackendNames[item.name],
                        });
                        const formattedData = {
                            label: item.alternate_name
                                ? item.alternate_name
                                : item.name,
                            description: item.description,
                            buyerSettings: {
                                required: item.is_required,
                                showField: !(
                                    item.additional_information.is_hidden ??
                                    false
                                ),
                            },
                            canSplit: item.can_split,
                            sendToPo: item.send_to_po,
                            sellerSettings: {
                                negotiable:
                                    !(
                                        item.additional_information.is_hidden ??
                                        false
                                    ) &&
                                    (item.additional_information.is_visible ??
                                        true) &&
                                    (item.additional_information
                                        .is_negotiable ??
                                        true),
                                showField:
                                    !(
                                        item.additional_information.is_hidden ??
                                        false
                                    ) &&
                                    (item.additional_information.is_visible ??
                                        true),
                            },
                            fieldLevel: item.field_level,
                        };

                        response.fieldSettings.standardFields = {
                            ...response.fieldSettings.standardFields,
                            [TemplateStandardFieldBackendNames[item.name]]:
                                formattedData,
                        };
                    } else {
                        response.sections.VENDOR_INFORMATION.fieldList.push({
                            fieldType: 'CUSTOM',
                            field: item.name,
                        });
                        response.fieldSettings.customFields[item.name] = {
                            label: item.alternate_name
                                ? item.alternate_name
                                : item.name,
                            description: item.description,
                            sendToPo: item.send_to_po,
                            canSplit: item.can_split,

                            buyerSettings: {
                                required: item.is_required,
                                showField: !(
                                    item.additional_information.is_hidden ??
                                    false
                                ),
                            },
                            fieldLevel: item.field_level,
                            sellerSettings: {
                                negotiable:
                                    !(
                                        item.additional_information.is_hidden ??
                                        false
                                    ) &&
                                    (item.additional_information.is_visible ??
                                        true) &&
                                    (item.additional_information
                                        .is_negotiable ??
                                        true),
                                showField:
                                    !(
                                        item.additional_information.is_hidden ??
                                        false
                                    ) &&
                                    (item.additional_information.is_visible ??
                                        true),
                                isMandatory: item.is_mandatory,
                            },
                            type: convertTemplateConstraintsItemToCustomFIeldType(
                                item.constraints.field_type
                            ),
                            constraints: getFieldTypeContraintsFromBackend({
                                field_type: item.constraints.field_type,
                                choices: item.constraints.choices,
                                max_limit: item.constraints.max_limit,
                                min_limit: item.constraints.min_limit,
                                choice_type: item.constraints.choice_type,
                            }),
                        };
                    }
                });
                return response;
            },
        }),
        getListOfTemplatesForEntity: builder.query<
            TemplateData[],
            {
                entityId?: string;
                templateType:
                    | 'RFQ'
                    | 'PO_GROUP'
                    | 'GL'
                    | 'PROJECT'
                    | 'COST_CENTER'
                    | 'QC'
                    | 'CLM'
                    | 'GR'
                    | 'REQUISITION'
                    | 'QUOTE_CALCULATOR'
                    | 'ITEM'
                    | 'BUYER'
                    | 'ITEM_CART'
                    | 'SRM';
                allStatusTemplates?: boolean;
            }
        >({
            query: ({ entityId, templateType }) =>
                `/module_templates/?${
                    entityId ? `entity_id=${entityId}` : ''
                }&template_type=${templateType}`,
            transformResponse: (
                baseQueryReturnValue: {
                    count: number;
                    templates: ITemplateListDetailsResponse[];
                }[],
                meta,
                { allStatusTemplates = false }
            ) => {
                const templateList: TemplateData[] =
                    baseQueryReturnValue.length > 0
                        ? baseQueryReturnValue[0].templates
                              .filter(
                                  (template: ITemplateListDetailsResponse) =>
                                      template.type === 'ITEM' ||
                                      template.type === ('ITEM_CART' as any) ||
                                      template.type === 'VENDOR'
                                          ? true
                                          : allStatusTemplates
                                          ? true
                                          : template.status === 'ONGOING'
                              )
                              .map((template: ITemplateListDetailsResponse) => {
                                  return {
                                      template_id: template.template_id,
                                      template_name: template.name,
                                      template_type: template.type,
                                      template_status:
                                          template.status as TemplateStatus,
                                      is_default: template.is_default,
                                      is_global: template.is_global,
                                      auto_reminders: {
                                          initial_reminder_hours:
                                              template?.auto_reminders
                                                  ?.initial_reminder_hours ??
                                              12,
                                          max_reminders:
                                              template?.auto_reminders
                                                  ?.max_reminders ?? 4,
                                          reminder_interval:
                                              template?.auto_reminders
                                                  ?.reminder_interval ?? 12,
                                          is_remainder_required:
                                              template?.auto_reminders
                                                  ?.is_remainder_required ??
                                              template.type === 'RFQ',
                                      },
                                      items: template.items,
                                      description: template.description,
                                      tags: template.tags,
                                      template_entity_id: template.entity_id,
                                      template_entity_name:
                                          template.entity_name,
                                      template_description:
                                          template.description,
                                      created_by: template.creator_name,
                                      created_at: template.created_on,
                                      last_modified: template.last_edited_on,
                                      last_modified_by:
                                          template.last_edited_by_name,
                                      deleted_datetime:
                                          template.deleted_datetime,
                                  };
                              })
                        : [];

                return templateList;
            },
        }),
        getUnModifiedTemplateById: builder.query<
            IRFQTemplateBackendResponse,
            { templateId: string; entityId: string }
        >({
            query: ({ entityId, templateId }) =>
                `/module_templates/${entityId}/${templateId}`,
            transformResponse: (
                baseQueryReturnValue: IRFQTemplateBackendResponse,
                meta,
                arg
            ): IRFQTemplateBackendResponse => {
                let response = baseQueryReturnValue;

                let customFieldResponse = response;

                customFieldResponse.section_list[0].section_items =
                    customFieldResponse.section_list[0].section_items.filter(
                        (item) =>
                            ![
                                'Project code',
                                'Project name',
                                'Description',
                                'Internal notes',
                                'Start date',
                                'End date',
                                'Project manager',
                            ].includes(item.name)
                    );

                return customFieldResponse;
            },
        }),
        getProjectFieldsFromTemplateById: builder.query<
            IRFQTemplateBackendResponse,
            { templateId: string; entityId: string }
        >({
            query: ({ entityId, templateId }) =>
                `/module_templates/${entityId}/${templateId}`,
            transformResponse: (
                baseQueryReturnValue: IRFQTemplateBackendResponse,
                meta,
                arg
            ): IRFQTemplateBackendResponse => {
                let response = baseQueryReturnValue;

                response.section_list[0].section_items =
                    response.section_list[0].section_items.filter((item) =>
                        [
                            'Project code',
                            'Project name',
                            'Description',
                            'Internal notes',
                            'Start date',
                            'End date',
                            'Project manager',
                        ].includes(item.name)
                    );

                return response;
            },
        }),
        getAllTemplateItems: builder.query<
            TemplateItemsResponse[],
            {
                fieldType?: TemplateItemTypes;
                itemType: 'ADDITIONAL_COST' | 'TAX' | 'DISCOUNT' | 'OTHER';
                type?: templateType[];
            }
        >({
            query: ({ fieldType, itemType, type }) => ({
                url: `/module_templates/entity_items/?${
                    fieldType ? `&field_type=${fieldType}` : ''
                }&item_type=${itemType}${
                    type
                        ? type
                              .map((t) => `&types[]=${t.replaceAll(' ', '_')}`)
                              .join('')
                        : ''
                }`,
            }),
        }),
        getAllCFNames: builder.query<
            string[],
            {
                fieldType?: TemplateItemTypes;
                type?: templateType[];
            }
        >({
            query: ({ fieldType, type }) => ({
                url: `/module_templates/entity_items/?only_names=True?${
                    fieldType ? `&field_type=${fieldType}` : ''
                }&item_type=OTHER${
                    type
                        ? type
                              .map((t) => `&types[]=${t.replaceAll(' ', '_')}`)
                              .join('')
                        : ''
                }`,
            }),
            transformResponse: (
                baseQueryReturnValue: { name: string }[],
                meta,
                args
            ) => {
                return baseQueryReturnValue.map((item) =>
                    item.name.toLowerCase()
                );
            },
        }),
    }),
});

export interface IUserForPermissionModule {
    user_id: string;
    name: string;
    first_name: any;
    last_name: any;
    entity_id: string;
    entity_name: string;
    action_api_group: string;
    criteria: any;
}

export const getUserListForPermissionModule = async (
    entityId: string,
    permissionModule: string[]
) => {
    try {
        let url = permissionModule
            .map(
                (permission) =>
                    `${
                        permission === 'QUOTE_CALCULATOR' ? 'QUOTE' : permission
                    }_VIEW`
            )
            .join('&action_api_group[]=');

        let res = await get<{
            [sectionIndex: string]: IUserForPermissionModule[];
        }>(
            `/organization/user_permission/?entity_id[]=${entityId}&action_api_group[]=${url}`
        );

        return res.data;
    } catch (e) {}
};

export const {
    useLazyGetTemplateByIdQuery,
    useGetTemplateByIdQuery,
    useGetListOfTemplatesForEntityQuery,
    useGetUnModifiedTemplateByIdQuery,
    useGetProjectFieldsFromTemplateByIdQuery,
    useGetAllTemplateItemsQuery,
    useGetAllCFNamesQuery,
} = templateApiSlice;
export const { useGetCustomConversionQuery } = baseApiSlice.injectEndpoints({
    endpoints: (builder) => ({
        getCustomConversion: builder.query<
            {
                custom_conversions: {
                    from_currency_id: string;
                    from_currency_name: string;
                    to_currency_id: string;
                    to_currency_name: string;
                    conversion_rate: string;
                }[];
                admin: boolean;
            },
            { eventId: string }
        >({
            query: ({ eventId }) => `/backbone/currency_conversion/${eventId}/`,
        }),
    }),
});

export const { useGetCostingCustomConversionQuery } =
    baseApiSlice.injectEndpoints({
        endpoints: (builder) => ({
            getCostingCustomConversion: builder.query<
                {
                    custom_conversions: {
                        from_currency_id: string;
                        from_currency_name: string;
                        to_currency_id: string;
                        to_currency_name: string;
                        conversion_rate: string;
                    }[];
                    admin: boolean;
                },
                { costingID: string }
            >({
                query: ({ costingID }) =>
                    `/cost_calculator/${costingID}/currency_conversions/`,
            }),
        }),
    });

export const convertTemplateConstraintsItemToCustomFIeldType = (
    fieldType: string
): CustomFieldType => {
    switch (fieldType) {
        case 'BOOLEAN':
            return CustomFieldType.BOOLEAN;
        case 'DATE':
            return CustomFieldType.DATE;
        case 'CURRENCY':
            return CustomFieldType.CURRENCY;
        case 'DATETIME':
            return CustomFieldType.DATETIME;
        // case 'COLLECTION':
        //     return CustomFieldType.BOOLEAN
        // case 'LOCATION':
        //     return CustomFieldType.BOOLEAN
        case 'INTEGER':
            return CustomFieldType.INTEGER;
        case 'FLOAT':
            return CustomFieldType.FLOAT;
        case 'SHORTTEXT':
            return CustomFieldType.SHORTTEXT;
        case 'LONGTEXT':
            return CustomFieldType.LONGTEXT;
        case 'CHOICE':
            return CustomFieldType.CHOICE;
        default:
            return CustomFieldType.LONG_TEXT;
    }
};

export const getTemplateForSelectedEntity = async (
    entityId: string,
    templateType:
        | 'RFQ'
        | 'PO_GROUP'
        | 'GL'
        | 'PROJECT'
        | 'COST_CENTER'
        | 'QC'
        | 'CLM'
        | 'GR'
        | 'REQUISITION'
        | 'QUOTE_CALCULATOR'
        | 'ITEM'
        | 'BUYER'
        | 'ITEM_CART'
        | 'SRM',
    allStatusTemplates?: boolean
) => {
    try {
        let baseQueryReturnValue = await get<
            {
                count: number;
                templates: ITemplateListDetailsResponse[];
            }[]
        >(
            `/module_templates/?${
                entityId ? `entity_id=${entityId}` : ''
            }&template_type=${templateType}`
        );
        const templateList: TemplateData[] =
            baseQueryReturnValue.data.length > 0
                ? baseQueryReturnValue.data[0].templates
                      .filter((template: ITemplateListDetailsResponse) =>
                          template.type === 'ITEM' ||
                          template.type === ('ITEM_CART' as any) ||
                          template.type === 'VENDOR'
                              ? true
                              : allStatusTemplates
                              ? true
                              : template.status === 'ONGOING'
                      )
                      .map((template: ITemplateListDetailsResponse) => {
                          return {
                              template_id: template.template_id,
                              template_name: template.name,
                              template_type: template.type,
                              template_status:
                                  template.status as TemplateStatus,
                              is_default: template.is_default,
                              is_global: template.is_global,
                              auto_reminders: {
                                  initial_reminder_hours:
                                      template?.auto_reminders
                                          ?.initial_reminder_hours ?? 12,
                                  max_reminders:
                                      template?.auto_reminders?.max_reminders ??
                                      4,
                                  reminder_interval:
                                      template?.auto_reminders
                                          ?.reminder_interval ?? 12,
                                  is_remainder_required:
                                      template?.auto_reminders
                                          ?.is_remainder_required ??
                                      template.type === 'RFQ',
                              },
                              description: template.description,
                              tags: template.tags,
                              template_entity_id: template.entity_id,
                              template_entity_name: template.entity_name,
                              template_description: template.description,
                              created_by: template.creator_name,
                              created_at: template.created_on,
                              last_modified: template.last_edited_on,
                              last_modified_by: template.last_edited_by_name,
                              deleted_datetime: template.deleted_datetime,
                              items: template.items,
                              section_list: template.section_list,
                          };
                      })
                : [];

        return templateList;
    } catch (err) {
        throw new Error('Failed to get templates');
    }
};
