import {
    Backdrop,
    Box,
    Checkbox,
    Chip,
    CircularProgress,
    FormControlLabel,
    Grid,
    IconButton,
    InputLabel,
    Pagination,
    Tab,
    Tabs,
    ToggleButton,
    ToggleButtonGroup,
} from '@mui/material';
import {
    CellEditingStartedEvent,
    CellKeyDownEvent,
    ColDef,
    Column,
    ColumnApi,
    ColumnMovedEvent,
    ColumnPinnedEvent,
    ColumnResizedEvent,
    ColumnVisibleEvent,
    DragStoppedEvent,
    FilterChangedEvent,
    FirstDataRenderedEvent,
    FullWidthCellKeyDownEvent,
    GetRowIdFunc,
    GridApi,
    GridReadyEvent,
    PostSortRowsParams,
    RowClassParams,
    RowClassRules,
    RowClickedEvent,
    RowDataChangedEvent,
    RowHeightParams,
    RowNode,
    RowSelectedEvent,
    SortChangedEvent,
} from 'ag-grid-community';
import { AgGridReact } from 'ag-grid-react';
import {
    Dispatch,
    SetStateAction,
    useCallback,
    useEffect,
    useMemo,
    // useCallback,
    useState,
} from 'react';

import 'ag-grid-community/dist/styles/ag-grid.scss';
import 'ag-grid-community/dist/styles/ag-theme-alpine/sass/ag-theme-alpine.scss';
import { cloneDeep, isArray, isEqual } from 'lodash';
import React from 'react';
import { IVendorBuyerEntity } from '../Organizations/Admin/Interfaces/VendorsInterface';
import { PROJECT_DASHBOARD_FIELD_DELIMITER } from '../ProjectGlCostCenter/helpers/projectDashboardHelper';
import '../Theme/dataGrid.scss';
import { StatusFilterEnum } from '../UOM&Currency/Hooks/UOM/useMeasurementView';
import { CustomFormControl } from './FWCustomInputs';
import { FWMenu, FWMenuItem } from './FWCustomMenu';
import { FWTooltip } from './FWCustomTooltip';
import FWIcon from './FWIcon';
import { FWInput } from './FWInput';
import FWSelect from './FWSelect';
import { FWTypography } from './FWTypograhy';
import { FWButton } from './FWButton';
import { PODashboardTabs } from '../ItemCatalog/Components/Analytics/AnalyticsPODashboard';
import { updateEventIntegrationStore } from '../ItemAnalytics/Slices/EventIntegrationSlice';
import { useDispatch } from 'react-redux';
import LoadingBackDrop from '../Components/Shared/LoadingBackdrop';

const spinner = `<div style="
border: 6px solid #f3f3f3;
border-radius: 50%;
border-top: 6px solid grey;
width: 40px;
height: 40px;
-webkit-animation: spin 2s linear infinite; 
animation: spin 2s linear infinite;"></div>`;

// this will be shown as the default value for setFilterByCustomStatus and represented as Default option.
export const DEFAULT_OPTION_FOR_CUSTOM_FILTERS = 'All';
export const DEFAULT_OPTION_FOR_ALL_BUYER_ENTITIES = 'All';

interface FWDataGridProps {
    columns: ColDef[]; // columns name and
    rows: Array<any> | null; // data to populate rows
    rowHeight?: number; // Height of the row
    headerHeight?: number; // Height of the header
    pagination?: boolean; // enable/disable pages
    paginationPageSize?: number; // number of rows to show per page
    search?: string; // search filter text
    rowSelection?: string; // multiple select or single select
    suppressRowClickSelection?: boolean;
    context?: any; // context is used to pass parent methods to the cells
    fixedHeight?: number; // if the height needs to be the same always
    selectedRows?: any[]; // index of selected rows [0, 3, 4, ...]
    defaultColDef?: ColDef; // default column definitions for columns
    className?: string; // classes to be added on the grid
    domLayout?: 'autoHeight'; // to handle the auto height for example QC completed dashboard row heights
    rowClassRules?: RowClassRules; // handle dynamic class name changes for rows
    pinnedTopRowData?: any[];
    addNewAdditionalCost?: { showButton: boolean; toggleState: () => void };
    allowFilterByStatus?: boolean;
    allowFilterByCustomStatus?: boolean;
    buyerEntities?: IVendorBuyerEntity[];
    allowFilterByBuyerEntity?: boolean;
    disableDownloadButton?: boolean;
    createNewMeasurementUnit?: { showButton: boolean; toggleState: () => void };
    linkNewItemToTag?: { showButton: boolean; toggleState: () => void };
    customStatusFilterOptions?: string[]; // append All is the default option in list
    maximumColumnsOnDashboard?: number; // used to limit the max number of columns on dashboard
    onBuyerEntityChange?: (newBuyerEntity: IVendorBuyerEntity | null) => void;
    onReady?: (event: GridReadyEvent) => void;
    getRowClass?: (params: RowClassParams) => string | string[] | undefined;
    getRowId?: GetRowIdFunc;
    onRowSelect?: (data: any[]) => void;
    onRowClicked?: (event: RowClickedEvent) => void;
    doesExternalFilterPass?: (node: RowNode) => boolean;
    isExternalFilterPresent?: () => boolean;
    onSortChanged?: (event: SortChangedEvent) => void; // triggers when sort on grid has changed
    onStatusChanged?: (status: StatusFilterEnum) => void; // triggers when status filter for the grid has changed
    onCustomStatusChanged?: (status: any) => void; // triggers when custom status filter for the grid has changed
    postSortRows?: (params: PostSortRowsParams) => void;
    onFilterChanged?: (event: FilterChangedEvent) => void; // triggers when filter on grid has changed
    onColumnMoved?: (event: ColumnMovedEvent) => void; // triggers when column has been moved by user
    onColumnPinned?: (event: ColumnPinnedEvent) => void; // triggers when column/columns were pinned/unpinned
    onColumnVisible?: (event: ColumnVisibleEvent) => void; // triggers when column/columns were hidden or made visible
    onColumnResized?: (event: ColumnResizedEvent) => void; // triggers when column is resized
    onFirstDataRendered?: (event: FirstDataRenderedEvent) => void; // fired first time data is rendered on the grid
    onRowDataChanged?: (event: RowDataChangedEvent) => void; // triggered when new data has been set into the grid using `api.setRowData()` or by changing the `rowData` bound property.
    getRowHeight?: (params: RowHeightParams) => number;
    exportBtnOnClick?: () => Promise<void>;
    onCellKeyDown?: (e: CellKeyDownEvent | FullWidthCellKeyDownEvent) => void; // cell edit event
    onCellEditingStarted?: (e: CellEditingStartedEvent) => void; // cell edit start event
    allowCreateNewProject?: boolean;
    disableCreateButtonClick?: boolean;
    showSearchBar?: boolean;
    searchPlaceholder?: string;
    showExportButton?: boolean;
    disableExportButton?: boolean;
    exportFileName?: string;
    displayLoader?: boolean;
    suppressClickEdit?: boolean; // disable edit cell
    defaultSearchString?: string;
    disableFESearch?: boolean; //disable FE search to use BE enabled search
    onTextChange?: (e: any) => void;
    suppressPaginationPanel?: boolean;
    useCustomPagination?: number; // use custom pagination (pass total number of pages to enable the component)
    customPaginationOnChange?: (
        event: React.ChangeEvent<unknown>,
        page: number
    ) => void;
    customPaginationPageNumber?: number; // reset custom pagination to page 1
    showRefreshIcon?: boolean;
    showDownloadRowDataIcon?: boolean;
    onRefreshIconClick?: () => void;
    showFilterColumnMenu?: boolean;
    handleBulkImportBtnClick?: () => void;
    allowFilterProjectByStatus?: boolean;
    filterByEntity?: string;
    filterByStatus?: string;
    setFilterByStatus?: Dispatch<SetStateAction<string>>;
    onAddBtnClick?: () => void;
    onCreateNewProject?: () => void;
    cookieName?: string;
    showNoOfItemsComponent?: boolean;
    noOfItemsForThisPage?: number;
    handleDownloadSelectedRows?: () => void;
    handleRowSelectionInRowDataInterface?: (selectedRows: any[]) => void;
    addButtonDisableTooltip?: string;
    filterByTemplateSection?: boolean;
    templateSections?: string[];
    templateSectionDelimiter?: string;
    showEntityFilterTitle?: boolean;
    handleSelectedTemplateSectionChange?: (
        selectedTemplateSection: string[]
    ) => void;
    gridRef?: any;
    downloadCSV?: () => void;
    saveDashboardColumnOrder?: boolean;
    resetFilters?: () => void;
    filteredColumns?: string[];
    showTabs?: boolean;
    currentTab?: PODashboardTabs;
    handleTabChange?: (newValue: PODashboardTabs) => void;
    showIntegrationTabs?: boolean;
    integrationType?: 'CART_ORDERS' | 'REQUISITIONS';
    setIntegrationType?: Dispatch<
        SetStateAction<'CART_ORDERS' | 'REQUISITIONS'>
    >;
    showLoaderForItemTagLinkage?: boolean;
    addBtnTooltip?: string;
}

const FWDataGrid = (props: FWDataGridProps) => {
    const [gridApi, setGridApi] = useState<GridApi | undefined>();
    const [gridColumnApi, setGridColumnApi] = useState<ColumnApi | undefined>();
    const [showLoader, setShowLoader] = useState(true);

    const onGridReady = (params: GridReadyEvent) => {
        setShowLoader(false);
        setGridApi(params.api);
        setGridColumnApi(params.columnApi);
        if (onReady) onReady(params);
    };

    // const history = useHistory();

    const [searchTerm, setSearchTerm] = React.useState<string>(
        props.defaultSearchString ?? ''
    );

    // const [openCoulmnFilterMenu, setOpenColumnFilterMenu] =
    //     React.useState<boolean>(false);

    const {
        columns,
        rows,
        rowHeight = 49,
        headerHeight,
        onReady,
        maximumColumnsOnDashboard,
        pagination = true,
        paginationPageSize = 10,
        search,
        pinnedTopRowData,
        addNewAdditionalCost,
        linkNewItemToTag,
        customStatusFilterOptions,
        onRowClicked,
        getRowClass,
        getRowId,
        onRowSelect,
        rowSelection = 'multiple',
        suppressRowClickSelection = true,
        context,
        fixedHeight,
        selectedRows,
        doesExternalFilterPass,
        isExternalFilterPresent,
        defaultColDef,
        className,
        // onSortChanged,
        postSortRows,
        // onFilterChanged,
        onColumnMoved,
        handleDownloadSelectedRows,
        handleRowSelectionInRowDataInterface,
        onColumnPinned,
        onColumnVisible,
        onColumnResized,
        onFirstDataRendered,
        onRowDataChanged,
        getRowHeight,
        domLayout,
        addButtonDisableTooltip,
        displayLoader,
        rowClassRules,
        showSearchBar = false,
        showExportButton = false,
        disableExportButton = false,
        exportBtnOnClick,
        exportFileName = 'export.csv',
        suppressClickEdit = true,
        onCellKeyDown,
        onCellEditingStarted,
        disableFESearch = false,
        onTextChange,
        suppressPaginationPanel,
        useCustomPagination,
        buyerEntities,
        customPaginationPageNumber,
        showRefreshIcon,
        customPaginationOnChange,
        showDownloadRowDataIcon,
        onRefreshIconClick,
        showFilterColumnMenu,
        onAddBtnClick,
        createNewMeasurementUnit,
        disableCreateButtonClick,
        allowCreateNewProject,
        allowFilterByStatus,
        allowFilterByCustomStatus,
        showNoOfItemsComponent,
        noOfItemsForThisPage,
        filterByStatus,
        setFilterByStatus,
        allowFilterByBuyerEntity,
        // showEntityFilterTitle,
        onBuyerEntityChange,
        onCreateNewProject,
        disableDownloadButton,
        gridRef,
        filterByTemplateSection,
        templateSections,
        templateSectionDelimiter,
        handleSelectedTemplateSectionChange,
        downloadCSV,
        resetFilters,
        showTabs,
        currentTab,
        handleTabChange,
        showIntegrationTabs,
        integrationType,
        setIntegrationType,
        showLoaderForItemTagLinkage,
        addBtnTooltip,
    }: FWDataGridProps = props;
    const [filteredColumns, setFilteredColumns] = useState<string[]>([]);
    const [resetToggle, setResetToggle] = useState<boolean>(false);

    //    const handleFilterChanged = (event: FilterChangedEvent) => {
    //         const allColumns = gridColumnApi?.getAllColumns();
    //         const filteredCols = allColumns?.filter((col) => {
    //             const filterModel = gridApi?.getFilterModel();
    //             return filterModel && filterModel[col.getColId()];
    //         }).map(col => col.getColId()) || [];

    //         setFilteredColumns(filteredCols);

    //         if (onFilterChanged) {
    //             onFilterChanged(event);
    //         }
    //    };

    //    const handleSortChanged = (event: any) => {
    //         setFilterColumnMenuList((prev) => {
    //         let newPrev = cloneDeep(prev);
    //         newPrev = newPrev.map((fcm: any) => ({ ...fcm, disabled: true }));
    //         return newPrev;
    //     });

    //     if (props.onSortChanged) {
    //         props.onSortChanged(event);
    //     }
    // };

    const handleResetFiltersClick = () => {
        setResetToggle(!resetToggle);

        if (resetFilters) {
            resetFilters();
        }

        setFilterColumnMenuList((prev) =>
            prev.map((col) => ({ ...col, checked: false }))
        );
    };

    useEffect(() => {
        if (resetToggle) {
            setFilteredColumns([]);
        }
    }, [resetToggle]);

    const handleOnCheck = (event: RowSelectedEvent) => {
        if (onRowSelect && gridApi) {
            // this will return the selected rows in row data format
            if (handleRowSelectionInRowDataInterface) {
                handleRowSelectionInRowDataInterface(gridApi.getSelectedRows());
            }
        }

        if (onRowSelect && gridApi) {
            const data = gridApi.getSelectedRows().map((row) => row.id);
            onRowSelect(data);
        }
    };

    // const loading = () => {
    //
    //     if (rows === null) return loadingCellsRenderer();
    //     else if (rows.length === 0) return noRowsRenderer();
    // };

    const noOfRows = (rows && rows.length) || 0;

    const dispatch = useDispatch();

    // handle selected rows by default
    useEffect(() => {
        if (selectedRows && gridApi && selectedRows.length >= 0) {
            gridApi.forEachNode((node: RowNode, index: number) => {
                if (
                    node.data &&
                    node.data.id &&
                    typeof node.data.id === 'string'
                ) {
                    node.setSelected(selectedRows.includes(node.data.id));
                } else node.setSelected(selectedRows.includes(index));
            });
        }
    }, [selectedRows, noOfRows, gridApi]);

    const [selectedBuyerEntity, setSelectedBuyerEntity] =
        useState<IVendorBuyerEntity | null>(null);

    const [buyerEntityId, setBuyerEntityId] = useState<string>('');

    const [showLoaderOnExportIcon, setShowLoaderOnExportIcon] = useState(false);

    useEffect(() => {
        if (
            buyerEntities &&
            buyerEntities.length > 0 &&
            selectedBuyerEntity === null
        ) {
            setSelectedBuyerEntity(buyerEntities[0]);
            setBuyerEntityId(buyerEntities[0].entityUid);
        }
    }, [buyerEntities, selectedBuyerEntity]);

    useEffect(() => {
        if (gridApi) {
            if (rows === null) {
                gridApi.showLoadingOverlay();
            } else if (rows != null) {
                if (rows.length === 0) {
                    gridApi.showNoRowsOverlay();
                }
            }
        }
    }, [rows, gridApi]);

    const exportAsCSV = React.useCallback(async () => {
        try {
            setShowLoaderOnExportIcon(true);
            if (exportBtnOnClick) {
                await exportBtnOnClick();
                setShowLoaderOnExportIcon(false);
            } else {
                setShowLoaderOnExportIcon(true);
                let excludedHeaderNames = ['OPTIONS'];
                if (gridApi) {
                    gridApi.exportDataAsCsv({
                        fileName: exportFileName,
                        columnKeys: gridApi.getColumnDefs()
                            ? gridApi!
                                  .getColumnDefs()!
                                  .filter(
                                      (cd) =>
                                          cd.headerName &&
                                          !excludedHeaderNames.includes(
                                              cd.headerName.toUpperCase()
                                          )
                                  )
                                  .map((cd: ColDef) =>
                                      cd.colId ? cd.colId : ''
                                  )
                            : [],
                    });
                }
                setShowLoaderOnExportIcon(false);
            }
        } catch (err) {
            setShowLoaderOnExportIcon(false);
        }
    }, [exportBtnOnClick, exportFileName, gridApi]);

    let columnsWithComparator = React.useMemo(() => {
        let colsWithComparator = columns;
        for (let col of colsWithComparator) {
            if (!col.comparator) {
                col.comparator = (valueA, valueB, nodeA, nodeB, isInverted) => {
                    let compValA = valueA ?? '';
                    let compValB = valueB ?? '';
                    try {
                        return compValA
                            .toString()
                            .toUpperCase()
                            .localeCompare(compValB.toString().toUpperCase());
                    } catch (e) {
                        console.error('Factwise', e);
                        return 0;
                    }
                };
            }
        }

        return colsWithComparator;
    }, [columns]);

    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const openCoulmnFilterMenu = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) =>
        setAnchorEl(event.currentTarget);

    // function setCookie(name: string, value: string | number | boolean) {
    //     localStorage.setItem(name, JSON.stringify(value));
    // }

    const handleClose = useCallback(() => {
        setAnchorEl(null);
        if (!props.cookieName) return;
        // let data = gridColumnApi?.getAllColumns();

        const dashboardColumns = gridColumnApi?.getColumnState();

        let columnVisibilityJson: { [key: string]: boolean } = {};
        dashboardColumns?.forEach((column) => {
            columnVisibilityJson[column.colId as string] = !column.hide ?? true;
        });

        if (props.cookieName) {
            localStorage.setItem(
                props.cookieName,
                JSON.stringify(dashboardColumns)
            );
        }
    }, [gridColumnApi, props.cookieName]);

    function getCookie(name: string | undefined) {
        const cookies = localStorage.getItem(name ?? '');

        if (!cookies) return null;

        return cookies;
    }
    // useEffect(() => {
    //     if (gridApi) gridApi.showLoadingOverlay();
    //     const data = getCookie(props.cookieName);
    //     let filterModelJSON: { [key: string]: boolean } | null = null;

    //     try {
    //         filterModelJSON = JSON.parse(data || '');
    //     } catch (e) {
    //         console.error(e);
    //     }

    //     if (!filterModelJSON) {
    //
    //         return;
    //     }

    //

    //     if (gridColumnApi && filterModelJSON) {
    //         gridColumnApi.getAllColumns()?.forEach((column, idx) => {
    //             const colId = column.getColId();

    //             const isVisible = filterModelJSON
    //                 ? JSON.parse(filterModelJSON.toString())[colId]
    //                 : true;

    //

    //             if (isVisible !== undefined) {
    //
    //                 gridColumnApi.setColumnVisible(colId, isVisible);
    //             }
    //         });
    //     }
    //     if (gridApi) gridApi.hideOverlay();
    // }, [gridApi, gridColumnApi, props.cookieName]);

    const [filterColumnMenuList, setFilterColumnMenuList] = React.useState<
        {
            id: string;
            label: string;
            checked: boolean;
        }[]
    >([]);

    const [selectedTemplateSections, setSelectedTemplateSections] = useState<
        string[]
    >(templateSections ?? []);

    // useEffect(() => {
    //     setSelectedTemplateSections((prev) => {
    //         if (prev.length === 0 && templateSections) {
    //             return templateSections;
    //         }

    //         return prev;
    //     });
    // }, [templateSections]);

    const numberOfOptionsSelected = useMemo(() => {
        return filterColumnMenuList.filter((option) => option.checked)?.length;
    }, [filterColumnMenuList]);

    const maximumColumnsSelected = useMemo(() => {
        if (maximumColumnsOnDashboard !== undefined) {
            return numberOfOptionsSelected > maximumColumnsOnDashboard;
        }
        return false;
    }, [maximumColumnsOnDashboard, numberOfOptionsSelected]);

    // const [filterByStatus, setFilterByStatus] = useState<StatusFilterEnum>(
    //     StatusFilterEnum.ALL
    // )

    // append All is the default option in list
    const [filterByCustomStatus, setFilterByCustomStatus] = useState<any>(
        DEFAULT_OPTION_FOR_CUSTOM_FILTERS
    );

    const toggleFilterColumnMenu = (event: DragStoppedEvent) => {
        const columnsMap: {
            [key: string]: boolean | null | undefined;
        } = {};

        event?.columnApi.getColumnState()?.forEach((column) => {
            columnsMap[column.colId] = column.hide;
        });

        const newFilterColumnMenuList = filterColumnMenuList.map((column) => {
            return {
                ...column,
                checked: !columnsMap[column.id] ?? true,
            };
        });
        setFilterColumnMenuList(newFilterColumnMenuList);
    };

    const setFilterColumnMenuListFunc = useCallback(() => {
        if (gridApi) gridApi.showLoadingOverlay();
        if (props.cookieName) {
            // const filterModel = getCookie(props.cookieName);

            const dashboardColumnDataString = getCookie(props.cookieName);

            if (
                dashboardColumnDataString !== null &&
                dashboardColumnDataString !== undefined &&
                gridColumnApi
            ) {
                let dashboardColumnData = [];
                try {
                    dashboardColumnData = JSON.parse(dashboardColumnDataString);
                } catch (e) {
                    console.error(
                        'Failed to parse dashboardColumnDataString:',
                        e
                    );
                }

                if (isArray(dashboardColumnData)) {
                    dashboardColumnData
                        ?.filter(
                            (val) =>
                                val.colId !== 'options' && val.colId !== '0'
                        )
                        .forEach((column, idx) => {
                            const colId = column.colId;

                            const isVisible = !column.hide;
                            //
                            if (isVisible !== undefined) {
                                gridColumnApi.setColumnVisible(
                                    colId,
                                    isVisible
                                );
                            }
                        });
                } else {
                    const filterModel = getCookie(props.cookieName);

                    if (filterModel && gridColumnApi) {
                        // the following code snippet gave an oh-snap - JSON.parse(decodeURIComponent(filterModel));
                        const filterModelJSON = JSON.parse(filterModel);
                        gridColumnApi
                            .getAllColumns()
                            ?.filter(
                                (val) =>
                                    val.getColId() !== 'options' &&
                                    val.getColId() !== '0'
                            )
                            .forEach((column, idx) => {
                                const colId = column.getColId();
                                const isVisible = filterModelJSON
                                    ? JSON.parse(
                                          JSON.stringify(filterModelJSON)
                                      )[colId]
                                    : true;

                                if (isVisible !== undefined) {
                                    gridColumnApi.setColumnVisible(
                                        colId,
                                        isVisible
                                    );
                                }
                            });
                    }
                }
            }
        }

        if (showFilterColumnMenu && gridColumnApi) {
            setFilterColumnMenuList(
                gridColumnApi
                    .getAllColumns()
                    ?.filter(
                        (val) =>
                            val.getColId() !== 'selection' &&
                            val.getColId() !== '0'
                        //  &&
                        // val.getColDef().hide !== true
                    )
                    .map((column: Column) => {
                        return {
                            id: column.getColId(),
                            label: column.getColDef()?.headerName ?? '',
                            checked: column.isVisible() ?? true,
                        };
                    }) ?? []
            );
        }
        if (gridApi) gridApi.hideOverlay();
    }, [gridApi, gridColumnApi, props.cookieName, showFilterColumnMenu]);

    useEffect(() => {
        setFilterColumnMenuListFunc();
    }, [setFilterColumnMenuListFunc]);

    const handleColumnMoved = useCallback(() => {
        const columnState = gridColumnApi?.getColumnState();
        try {
            if (props.cookieName) {
                localStorage.setItem(
                    props.cookieName,
                    JSON.stringify(columnState)
                );
            }
        } catch (err) {
            console.error('Failed to set column order ', err);
        }
    }, [gridColumnApi, props.cookieName]);

    if (displayLoader) {
        return (
            <Box
                style={{
                    width: '100%',
                    height: '82vh',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    // backgroundColor: 'white',
                }}
            >
                <Backdrop
                    style={{
                        zIndex: 1000000,
                        color: '#fff',
                    }}
                    open={true}
                >
                    <CircularProgress style={{ color: 'black' }} />
                </Backdrop>
            </Box>
        );
    }

    return (
        <>
            {(showLoader || gridApi === undefined) && <LoadingBackDrop />}
            {(showSearchBar || showExportButton) && (
                <Grid
                    container
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        mt: '2px',
                        gap: '0.75rem',
                    }}
                    marginBottom={'12px'}
                >
                    {showIntegrationTabs && (
                        <>
                            {/* <Grid item xs={3}></Grid> */}
                            <Grid
                                item
                                xs={12}
                                position={'fixed'}
                                right={'43%'} // change me when width is fixed
                            >
                                {/* <Grid> */}
                                <ToggleButtonGroup
                                    color="primary"
                                    value={integrationType}
                                    exclusive
                                    fullWidth
                                    onChange={(
                                        event: React.MouseEvent<
                                            HTMLElement,
                                            MouseEvent
                                        >,
                                        value: 'CART_ORDERS' | 'REQUISITIONS'
                                    ) => {
                                        setIntegrationType &&
                                            (value === 'CART_ORDERS'
                                                ? setIntegrationType(
                                                      'CART_ORDERS'
                                                  )
                                                : setIntegrationType(
                                                      'REQUISITIONS'
                                                  ));
                                        dispatch(
                                            updateEventIntegrationStore({
                                                type: 'RESET_ALL_ORDERS',
                                                value: null,
                                            })
                                        );
                                    }}
                                    aria-label="Platform"
                                >
                                    <ToggleButton value="CART_ORDERS">
                                        <FWTypography>Orders</FWTypography>
                                    </ToggleButton>
                                    <ToggleButton value="REQUISITIONS">
                                        <FWTypography>
                                            Requisitions
                                        </FWTypography>
                                    </ToggleButton>
                                </ToggleButtonGroup>
                            </Grid>
                            {/* </Grid> */}
                        </>
                    )}
                    <Grid
                        item
                        xs={4}
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            mt: '2px',
                            gap: '0.75rem',
                        }}
                    >
                        {showTabs && (
                            <Box width={'fit-content'}>
                                <Tabs value={currentTab}>
                                    <Tab
                                        label="Approval Pending"
                                        value={PODashboardTabs.APPROVAL_PENDING}
                                        sx={{ padding: '20px 50px' }}
                                        onClick={() => {
                                            if (handleTabChange) {
                                                handleTabChange(
                                                    PODashboardTabs.APPROVAL_PENDING
                                                );
                                            }
                                        }}
                                    />
                                    <Tab
                                        label="Approved"
                                        value={PODashboardTabs.APPROVED}
                                        sx={{ padding: '20px 50px' }}
                                        onClick={() => {
                                            if (handleTabChange) {
                                                handleTabChange(
                                                    PODashboardTabs.APPROVED
                                                );
                                            }
                                        }}
                                    />
                                    <Tab
                                        label="Completed"
                                        value={PODashboardTabs.COMPLETED}
                                        sx={{ padding: '20px 50px' }}
                                        onClick={() => {
                                            if (handleTabChange) {
                                                handleTabChange(
                                                    PODashboardTabs.COMPLETED
                                                );
                                            }
                                        }}
                                    />
                                </Tabs>
                            </Box>
                        )}
                        {showDownloadRowDataIcon &&
                            gridApi &&
                            handleDownloadSelectedRows && (
                                <FWTooltip title="Download selected rows">
                                    <div>
                                        <IconButton
                                            color="primary"
                                            onClick={handleDownloadSelectedRows}
                                            disabled={disableDownloadButton}
                                            sx={{
                                                fontSize: '1.5rem',
                                                marginLeft: '6px',
                                            }}
                                        >
                                            <i className="bi bi-download"></i>
                                        </IconButton>
                                    </div>
                                </FWTooltip>
                            )}
                        {allowFilterByStatus && (
                            <CustomFormControl fullWidth>
                                <InputLabel id="Filter-By">
                                    Filter By
                                </InputLabel>

                                <FWSelect
                                    value={filterByStatus}
                                    onChange={(e: any) => {
                                        if (setFilterByStatus)
                                            setFilterByStatus(e.target.value);
                                        props.onStatusChanged &&
                                            props.onStatusChanged(
                                                e.target.value
                                            );
                                    }}
                                    labelId="Filter-By"
                                    label="Filter By"
                                    fullWidth
                                >
                                    <FWMenuItem value={StatusFilterEnum.ALL}>
                                        All
                                    </FWMenuItem>
                                    <FWMenuItem value={StatusFilterEnum.ACTIVE}>
                                        Active
                                    </FWMenuItem>
                                    <FWMenuItem
                                        value={StatusFilterEnum.DISABLED}
                                    >
                                        Disabled
                                    </FWMenuItem>
                                </FWSelect>
                            </CustomFormControl>
                        )}

                        {customStatusFilterOptions &&
                            allowFilterByCustomStatus && (
                                <CustomFormControl fullWidth>
                                    <InputLabel id="Filter-custom-status">
                                        Filter By
                                    </InputLabel>

                                    <FWSelect
                                        value={filterByCustomStatus}
                                        onChange={(e: any) => {
                                            setFilterByCustomStatus(
                                                e.target.value
                                            );
                                            props.onCustomStatusChanged &&
                                                props.onCustomStatusChanged(
                                                    e.target.value
                                                );
                                        }}
                                        labelId="Filter-custom-status"
                                        label="Filter By"
                                        fullWidth
                                    >
                                        {customStatusFilterOptions?.map(
                                            (filter) => (
                                                <FWMenuItem value={filter}>
                                                    {filter}
                                                </FWMenuItem>
                                            )
                                        )}
                                    </FWSelect>
                                </CustomFormControl>
                            )}

                        {allowFilterByBuyerEntity && buyerEntities && (
                            <CustomFormControl fullWidth>
                                <InputLabel id="Filter-buyer-entity-status">
                                    Buyer Entity
                                </InputLabel>

                                <FWSelect
                                    value={buyerEntityId}
                                    onChange={(e: any) => {
                                        // setFilterByCustomStatus(e.target.value);
                                        // props.onCustomStatusChanged &&
                                        //     props.onCustomStatusChanged(
                                        //         e.target.value
                                        //     );
                                    }}
                                    labelId="Filter-buyer-entity-status"
                                    label="Buyer Entity"
                                    fullWidth
                                >
                                    {buyerEntities?.map((entity) => (
                                        <FWMenuItem
                                            value={entity.entityUid}
                                            onClick={() => {
                                                setSelectedBuyerEntity(entity);
                                                setBuyerEntityId(
                                                    entity.entityUid
                                                );
                                                if (onBuyerEntityChange) {
                                                    onBuyerEntityChange(entity);
                                                }
                                            }}
                                        >
                                            {entity.entityName}
                                        </FWMenuItem>
                                    ))}
                                </FWSelect>
                            </CustomFormControl>
                        )}

                        {filterByTemplateSection &&
                            templateSections &&
                            templateSectionDelimiter &&
                            handleSelectedTemplateSectionChange && (
                                <CustomFormControl fullWidth>
                                    <InputLabel id="Filter-project-section">
                                        Project section
                                    </InputLabel>

                                    {/* <FWAutocomplete
                                        popupIcon={
                                            <Box
                                                fontSize={'16px'}
                                                paddingTop={0.5}
                                            >
                                                <i className="bi bi-chevron-down EventsAddItemStage_DetailsArrowIconSave"></i>
                                            </Box>
                                        }
                                        disableClearable
                                        value={
                                            selectedTemplateSections?.length ===
                                            0
                                                ? ['All templates']
                                                : selectedTemplateSections
                                        }
                                        options={[
                                            'SELECT_ALL',
                                            ...templateSections,
                                        ]}
                                        renderInput={(params) => (
                                            <FWInput
                                                {...params}
                                                style={{
                                                    overflow: 'hidden',
                                                    textOverflow: 'ellipsis',
                                                }}
                                                variant="outlined"
                                            />
                                        )}
                                        multiple
                                        renderOption={(
                                            props,
                                            templateSection: string
                                        ) => {
                                            if (
                                                templateSection === 'SELECT_ALL'
                                            ) {
                                                return (
                                                    <FWMenuItem
                                                        value={'SELECT_ALL'}
                                                        sx={{
                                                            display: 'flex',
                                                            gap: '4px',
                                                        }}
                                                        onClick={(e) => {
                                                            e.stopPropagation();
                                                            let updateList: string[] =
                                                                [];

                                                            const areAllSectionsSelected =
                                                                selectedTemplateSections?.length ===
                                                                templateSections?.length;

                                                            if (
                                                                areAllSectionsSelected
                                                            ) {
                                                                updateList = [];
                                                            } else {
                                                                updateList =
                                                                    templateSections;
                                                            }
                                                            setSelectedTemplateSections(
                                                                updateList
                                                            );
                                                            if (
                                                                handleSelectedTemplateSectionChange
                                                            ) {
                                                                handleSelectedTemplateSectionChange(
                                                                    updateList
                                                                );
                                                            }
                                                        }}
                                                    >
                                                        <Checkbox
                                                            checked={
                                                                selectedTemplateSections?.length ===
                                                                templateSections?.length
                                                            }
                                                        />
                                                        <FWTypography>
                                                            Select all
                                                        </FWTypography>
                                                    </FWMenuItem>
                                                );
                                            } else {
                                                const [
                                                    templateName,
                                                    SectionName,
                                                ] = templateSection.split(
                                                    templateSectionDelimiter
                                                );
                                                const isTemplateAndSectionSelected =
                                                    selectedTemplateSections.findIndex(
                                                        (
                                                            selectedtemplateSection
                                                        ) =>
                                                            selectedtemplateSection ===
                                                            templateSection
                                                    );
                                                return (
                                                    <FWMenuItem
                                                        value={templateSection}
                                                    >
                                                        <Checkbox
                                                            checked={
                                                                isTemplateAndSectionSelected !==
                                                                -1
                                                            }
                                                        />
                                                        <FWTypography
                                                            marginRight={'5px'}
                                                        >
                                                            {SectionName}
                                                        </FWTypography>
                                                        <FWTooltip
                                                            title={templateName}
                                                        >
                                                            <Box color="primary.main">
                                                                <FWIcon
                                                                    name="bi bi-info-circle"
                                                                    size={16}
                                                                />
                                                            </Box>
                                                        </FWTooltip>
                                                    </FWMenuItem>
                                                );
                                            }
                                        }}
                                        PaperComponent={(props) => (
                                            <AutocompleteStyledPaper
                                                {...props}
                                            />
                                        )}
                                        fullWidth
                                        isOptionEqualToValue={(
                                            option: string,
                                            value: any
                                        ) => {
                                            return value === option;
                                        }}
                                    /> */}
                                    <FWSelect
                                        value={
                                            selectedTemplateSections?.length ===
                                            0
                                                ? ['All templates selected']
                                                : selectedTemplateSections
                                        }
                                        onChange={(e: any) => {
                                            // setFilterByCustomStatus(e.target.value);
                                            // props.onCustomStatusChanged &&
                                            //     props.onCustomStatusChanged(
                                            //         e.target.value
                                            //     );
                                        }}
                                        labelId="Filter-project-section"
                                        label="Project section"
                                        renderValue={(value) => {
                                            if (
                                                isEqual(value, [
                                                    'All templates selected',
                                                ]) ||
                                                (value as string[])?.length ===
                                                    templateSections?.length
                                            ) {
                                                return (
                                                    <FWTypography>
                                                        All sections
                                                    </FWTypography>
                                                );
                                            }
                                            const filterBySections = (
                                                value as string[]
                                            ).map(
                                                (selection) =>
                                                    selection.split(
                                                        PROJECT_DASHBOARD_FIELD_DELIMITER
                                                    )[1]
                                            );

                                            return (
                                                <>
                                                    {filterBySections.map(
                                                        (section) => (
                                                            <Chip
                                                                size="small"
                                                                label={section}
                                                                sx={{
                                                                    marginLeft:
                                                                        '5px',
                                                                }}
                                                            />
                                                        )
                                                    )}
                                                </>
                                            );
                                        }}
                                        MenuProps={{
                                            PaperProps: {
                                                sx: {
                                                    bgcolor: 'white',
                                                    maxHeight: '538px',
                                                    boxShadow:
                                                        '0px 5px 16px #00000024',
                                                },
                                            },
                                        }}
                                        fullWidth
                                        multiple
                                    >
                                        {templateSections?.length > 1 && (
                                            <FWMenuItem
                                                value={''}
                                                sx={{
                                                    display: 'flex',
                                                    paddingX: 0,
                                                    gap: '4px',
                                                }}
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                    let updateList: string[] =
                                                        [];

                                                    const areAllSectionsSelected =
                                                        selectedTemplateSections?.length ===
                                                        templateSections?.length;

                                                    if (
                                                        areAllSectionsSelected
                                                    ) {
                                                        updateList = [];
                                                    } else {
                                                        updateList =
                                                            templateSections;
                                                    }
                                                    setSelectedTemplateSections(
                                                        updateList
                                                    );
                                                    if (
                                                        handleSelectedTemplateSectionChange
                                                    ) {
                                                        handleSelectedTemplateSectionChange(
                                                            updateList
                                                        );
                                                    }
                                                }}
                                            >
                                                <Checkbox
                                                    checked={
                                                        selectedTemplateSections?.length ===
                                                        templateSections?.length
                                                    }
                                                    sx={{ paddingY: 0 }}
                                                />
                                                <FWTypography>
                                                    Select all
                                                </FWTypography>
                                            </FWMenuItem>
                                        )}
                                        {templateSections?.map(
                                            (templateSection) => {
                                                const [
                                                    templateName,
                                                    SectionName,
                                                ] = templateSection.split(
                                                    templateSectionDelimiter
                                                );

                                                const isTemplateAndSectionSelected =
                                                    selectedTemplateSections.findIndex(
                                                        (
                                                            selectedtemplateSection
                                                        ) =>
                                                            selectedtemplateSection ===
                                                            templateSection
                                                    );
                                                return (
                                                    <FWMenuItem
                                                        value={templateSection}
                                                        sx={{
                                                            display: 'flex',
                                                            paddingX: 0,
                                                            gap: '4px',
                                                        }}
                                                        onClick={(e) => {
                                                            e.stopPropagation();
                                                            setSelectedTemplateSections(
                                                                (prev) => {
                                                                    let newPrev =
                                                                        cloneDeep(
                                                                            prev
                                                                        );

                                                                    if (
                                                                        isTemplateAndSectionSelected ===
                                                                        -1
                                                                    ) {
                                                                        newPrev =
                                                                            [
                                                                                ...newPrev,
                                                                                templateSection,
                                                                            ];
                                                                    } else {
                                                                        newPrev.splice(
                                                                            isTemplateAndSectionSelected,
                                                                            1
                                                                        );
                                                                    }
                                                                    handleSelectedTemplateSectionChange(
                                                                        newPrev
                                                                    );
                                                                    return newPrev;
                                                                }
                                                            );
                                                        }}
                                                    >
                                                        <Checkbox
                                                            checked={
                                                                isTemplateAndSectionSelected !==
                                                                -1
                                                            }
                                                            sx={{
                                                                paddingY: 0,
                                                            }}
                                                        />
                                                        <Box
                                                            sx={{
                                                                display: 'flex',
                                                                gap: '4px',
                                                            }}
                                                        >
                                                            <FWTypography
                                                                marginRight={
                                                                    '5px'
                                                                }
                                                            >
                                                                {SectionName}
                                                            </FWTypography>
                                                            <FWTooltip
                                                                title={
                                                                    templateName
                                                                }
                                                            >
                                                                <Box color="primary.main">
                                                                    <FWIcon
                                                                        name="bi bi-info-circle"
                                                                        size={
                                                                            16
                                                                        }
                                                                    />
                                                                </Box>
                                                            </FWTooltip>
                                                        </Box>
                                                    </FWMenuItem>
                                                );
                                            }
                                        )}
                                    </FWSelect>
                                </CustomFormControl>
                            )}

                        {/* {allowFilterByBuyerEntity && buyerEntities && (
                            <CustomFormControl fullWidth>
                                <InputLabel id="buyer-entity">
                                    Buyer entity
                                </InputLabel>

                                <FWSelect
                                    value={selectedBuyerEntity?.entityName}
                                    onChange={(e: any) => {
                                        
                                        // if (onBuyerEntityChange) {
                                        //     onBuyerEntityChange(newValue);
                                        // }
                                        // setSelectedBuyerEntity(newValue);
                                    }}
                                    labelId="buyer-entity"
                                    label="Buyer entity"
                                    fullWidth
                                >
                                    {buyerEntities.map((entity) => {
                                        if (
                                            entity.entityUid === 'GLOBAL_ENTITY'
                                        ) {
                                            return (
                                                <FWMenuItem
                                                    value={entity.entityUid}
                                                >
                                                    Global
                                                </FWMenuItem>
                                            );
                                        } else {
                                            return (
                                                <FWMenuItem
                                                    value={entity.entityUid}
                                                >
                                                    {entity.entityName}
                                                </FWMenuItem>
                                            );
                                        }
                                    })}
                                </FWSelect>
                            </CustomFormControl> 
                        )}*/}
                    </Grid>

                    <Grid
                        item
                        xs={'auto'}
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'flex-end',
                            mt: '2px',
                            gap: '0.75rem',
                        }}
                    >
                        {resetFilters && (
                            // <FWTooltip title="Refresh dashboard">
                            <div>
                                {/* <IconButton
                                        size="small"
                                        color="primary"
                                        onClick={resetFilters}
                                        sx={{ fontSize: '1.5rem' }}
                                    >
                                        <i className="bi bi-arrow-clockwise"></i>
                                    </IconButton> */}
                                <FWButton
                                    // variant="text"
                                    variant="contained"
                                    size="small"
                                    color="primary"
                                    onClick={handleResetFiltersClick}
                                >
                                    Reset filters
                                </FWButton>
                            </div>
                            // </FWTooltip>
                        )}
                        {showRefreshIcon && (
                            <FWTooltip title="Refresh dashboard">
                                <div>
                                    <IconButton
                                        size="small"
                                        color="primary"
                                        onClick={onRefreshIconClick}
                                        sx={{ fontSize: '1.5rem' }}
                                    >
                                        <i className="bi bi-arrow-clockwise"></i>
                                    </IconButton>
                                </div>
                            </FWTooltip>
                        )}

                        {showFilterColumnMenu && gridApi && (
                            <FWTooltip title="Filter columns">
                                <div>
                                    <IconButton
                                        color="primary"
                                        onClick={handleClick}
                                        sx={{
                                            fontSize: '1.5rem',
                                        }}
                                    >
                                        <i className="bi bi-sliders2"></i>
                                    </IconButton>
                                </div>
                            </FWTooltip>
                        )}
                        {showExportButton && (
                            <FWTooltip
                                title={
                                    downloadCSV
                                        ? 'Export Item Tags Linkages'
                                        : 'Export as Excel'
                                }
                            >
                                <div>
                                    <IconButton
                                        disabled={
                                            disableExportButton ||
                                            showLoaderOnExportIcon
                                        }
                                        color={
                                            disableExportButton
                                                ? 'secondary'
                                                : 'primary'
                                        }
                                        onClick={
                                            downloadCSV
                                                ? downloadCSV
                                                : exportAsCSV
                                        }
                                        sx={{
                                            fontSize: '1.5rem',
                                        }}
                                    >
                                        {showLoaderOnExportIcon ||
                                        showLoaderForItemTagLinkage ? (
                                            <CircularProgress size={24} />
                                        ) : (
                                            <i className="bi bi-box-arrow-up"></i>
                                        )}
                                    </IconButton>
                                </div>
                            </FWTooltip>
                        )}

                        {showSearchBar && (
                            <>
                                {searchTerm.length > 0 && (
                                    <>
                                        <FWTooltip title="Results are filtered">
                                            <Box color="primary.main">
                                                <i className="bi bi-funnel-fill fs--24" />
                                            </Box>
                                        </FWTooltip>
                                    </>
                                )}

                                <FWInput
                                    value={searchTerm}
                                    onChange={(e: any) => {
                                        setSearchTerm(e.target.value);
                                        onTextChange &&
                                            onTextChange(e.target.value);
                                    }}
                                    placeholder={'Search'}
                                    iconStart={<i className="bi bi-search" />}
                                    style={{ width: 400 }}
                                />
                            </>
                        )}

                        {allowCreateNewProject && (
                            <FWTooltip
                                title={
                                    disableCreateButtonClick
                                        ? addButtonDisableTooltip ?? ''
                                        : 'Create Project'
                                }
                            >
                                <div>
                                    <IconButton
                                        disabled={
                                            disableCreateButtonClick ?? false
                                        }
                                        color="primary"
                                        onClick={onCreateNewProject}
                                        // sx={{
                                        //     marginRight: showRefreshIcon
                                        //         ? '12px'
                                        //         : '',
                                        // }}
                                    >
                                        <i className="bi bi-plus-circle"></i>
                                    </IconButton>
                                </div>
                            </FWTooltip>
                        )}
                        {onAddBtnClick && (
                            <FWTooltip title={addBtnTooltip ?? ''}>
                                <div>
                                    <IconButton
                                        disabled={
                                            disableCreateButtonClick ?? false
                                        }
                                        color="primary"
                                        onClick={onAddBtnClick}
                                        // sx={{
                                        //     marginRight: showRefreshIcon
                                        //         ? '12px'
                                        //         : '',
                                        // }}
                                    >
                                        <i className="bi bi-plus-circle"></i>
                                    </IconButton>
                                </div>
                            </FWTooltip>
                        )}

                        {addNewAdditionalCost && (
                            <>
                                <FWTooltip title="Add new costs">
                                    <div>
                                        <IconButton
                                            sx={{ fontSize: '1.5rem' }}
                                            color="primary"
                                            disabled={
                                                !addNewAdditionalCost?.showButton
                                            }
                                            onClick={() =>
                                                addNewAdditionalCost.toggleState()
                                            }
                                        >
                                            <i className="bi bi-plus-circle"></i>
                                        </IconButton>
                                    </div>
                                </FWTooltip>
                            </>
                        )}

                        {createNewMeasurementUnit && (
                            <>
                                <FWTooltip title="Create new measurement unit">
                                    <div>
                                        <IconButton
                                            sx={{ fontSize: '1.5rem' }}
                                            color="primary"
                                            disabled={
                                                !createNewMeasurementUnit?.showButton
                                            }
                                            onClick={() =>
                                                createNewMeasurementUnit.toggleState()
                                            }
                                        >
                                            <i className="bi bi-plus-circle"></i>
                                        </IconButton>
                                    </div>
                                </FWTooltip>
                            </>
                        )}

                        {linkNewItemToTag && (
                            <>
                                <FWTooltip title="Link item tag to vendor">
                                    <div>
                                        <IconButton
                                            sx={{ fontSize: '1.5rem' }}
                                            color="primary"
                                            disabled={
                                                !linkNewItemToTag?.showButton
                                            }
                                            onClick={() =>
                                                linkNewItemToTag.toggleState()
                                            }
                                        >
                                            <i className="bi bi-plus-circle"></i>
                                        </IconButton>
                                    </div>
                                </FWTooltip>
                            </>
                        )}
                        {/* {resetFilters && (
                            <FWTooltip title="Refresh dashboard">
                                <div>
                                    <IconButton
                                        size="small"
                                        color="primary"
                                        onClick={resetFilters}
                                        sx={{ fontSize: '1.5rem' }}
                                    >
                                        <i className="bi bi-arrow-clockwise"></i>
                                    </IconButton>
                                </div>
                            </FWTooltip>
                        )} */}
                    </Grid>
                </Grid>
            )}

            {/* column filter sidebar */}
            {gridApi && showFilterColumnMenu && (
                <FWMenu
                    anchorEl={anchorEl}
                    open={openCoulmnFilterMenu}
                    onClose={handleClose}
                    PaperProps={{
                        sx: {
                            bgcolor: 'white',
                            maxHeight: '538px',
                        },
                    }}
                >
                    <Box
                        minWidth={100}
                        display={'flex'}
                        flexDirection={'column'}
                        // paddingX={2}
                    >
                        {filterColumnMenuList &&
                            filterColumnMenuList
                                .filter(({ label }) => {
                                    const excludedLabels = ['Options'];
                                    return !excludedLabels.includes(label);
                                })

                                .map(({ checked, id, label }) => (
                                    <FWMenuItem
                                        sx={{ padding: '0px 8px' }}
                                        key={id}
                                        onClick={(e) => {
                                            if (filteredColumns.includes(id)) {
                                                return; // Do nothing if the column is filtered
                                            }
                                            // if maximum number of columns are selected -> allow user to unselect current option else allow user to select & unSelect
                                            if (
                                                (maximumColumnsSelected &&
                                                    checked) ||
                                                !maximumColumnsSelected
                                            ) {
                                                e.preventDefault();
                                                if (gridColumnApi) {
                                                    // find column from list of all grid columns
                                                    let colDef:
                                                        | Column
                                                        | undefined = gridColumnApi
                                                        .getAllColumns()
                                                        ?.find(
                                                            (column: Column) =>
                                                                column.getColId() ===
                                                                id
                                                        );
                                                    if (colDef) {
                                                        gridColumnApi.setColumnVisible(
                                                            id,
                                                            !checked
                                                        );

                                                        if (
                                                            props.saveDashboardColumnOrder
                                                        ) {
                                                            handleColumnMoved();
                                                        }

                                                        setFilterColumnMenuList(
                                                            (prev) => {
                                                                let newPrev =
                                                                    cloneDeep(
                                                                        prev
                                                                    );

                                                                newPrev =
                                                                    newPrev.map(
                                                                        (fcm) =>
                                                                            fcm.id ===
                                                                            id
                                                                                ? {
                                                                                      ...fcm,
                                                                                      checked:
                                                                                          !checked ??
                                                                                          true,
                                                                                  }
                                                                                : fcm
                                                                    );

                                                                return newPrev;
                                                            }
                                                        );
                                                    }
                                                }
                                            }
                                        }}
                                        disabled={
                                            filteredColumns.includes(id) &&
                                            !resetToggle
                                        } // Disable if the column is filtered
                                    >
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    size="small"
                                                    checked={checked}
                                                    disableRipple
                                                    disabled={
                                                        maximumColumnsSelected &&
                                                        !checked
                                                    }
                                                />
                                            }
                                            label={label}
                                        />
                                    </FWMenuItem>
                                ))}
                    </Box>
                </FWMenu>
            )}

            <div
                className={`ag-theme-alpine ${
                    props.onRowClicked ? 'pointer' : ''
                }`}
                style={{
                    height: !fixedHeight
                        ? rows && rows.length < paginationPageSize
                            ? ((rows.length === 0 ? 2 : rows.length) + 2) *
                                  rowHeight +
                              15
                            : ''
                        : fixedHeight + 'px',
                }}
            >
                <AgGridReact
                    api={gridApi}
                    setGridApi={setGridApi}
                    rowData={rows}
                    columnDefs={columnsWithComparator}
                    pagination={pagination}
                    paginationPageSize={paginationPageSize}
                    onGridReady={onGridReady}
                    onRowClicked={onRowClicked}
                    quickFilterText={
                        !disableFESearch
                            ? searchTerm === ''
                                ? search
                                : searchTerm
                            : ''
                    }
                    ref={gridRef}
                    cacheQuickFilter={true}
                    getRowClass={getRowClass}
                    loadingCellRenderer={<CircularProgress size={35} />}
                    rowHeight={rowHeight}
                    animateRows={true}
                    rowSelection={rowSelection}
                    onRowSelected={handleOnCheck}
                    context={context}
                    doesExternalFilterPass={doesExternalFilterPass}
                    isExternalFilterPresent={isExternalFilterPresent}
                    rowClassRules={rowClassRules}
                    // suppress events
                    suppressRowClickSelection={suppressRowClickSelection}
                    suppressCellFocus
                    suppressClickEdit={suppressClickEdit}
                    rowMultiSelectWithClick={false}
                    overlayLoadingTemplate={spinner}
                    tooltipShowDelay={0}
                    overlayNoRowsTemplate={'No data available'}
                    // loading states
                    // loadingOverlayComponent={
                    //     <FWTypography color="text.secondary">
                    //         No data available
                    //     </FWTypography>
                    // }
                    // noRowsOverlayComponent={'No data available'}

                    legacyComponentRendering={true}
                    className={className}
                    getRowHeight={getRowHeight}
                    domLayout={domLayout}
                    getRowId={getRowId}
                    headerHeight={headerHeight}
                    defaultColDef={defaultColDef}
                    pinnedTopRowData={pinnedTopRowData}
                    // onSortChanged={handleSortChanged}
                    postSortRows={postSortRows}
                    // onFilterChanged={handleFilterChanged}
                    // onFilterChangedd={onFilterChangedd}
                    onColumnMoved={onColumnMoved}
                    onDragStopped={(e) => {
                        toggleFilterColumnMenu(e);
                    }}
                    onColumnPinned={onColumnPinned}
                    onColumnVisible={onColumnVisible}
                    onColumnResized={onColumnResized}
                    onFirstDataRendered={onFirstDataRendered}
                    onRowDataChanged={onRowDataChanged}
                    onCellKeyDown={onCellKeyDown}
                    onCellEditingStarted={onCellEditingStarted}
                    suppressPaginationPanel={suppressPaginationPanel}
                    onNewColumnsLoaded={setFilterColumnMenuListFunc}
                ></AgGridReact>
                {useCustomPagination ? (
                    <Box className="mt--10 flex flex--jcfe">
                        {showNoOfItemsComponent &&
                            noOfItemsForThisPage !== undefined &&
                            customPaginationPageNumber !== undefined && (
                                <Box>
                                    <FWTypography>
                                        {' '}
                                        Showing{' '}
                                        {(+customPaginationPageNumber - 1) *
                                            10 +
                                            1 >
                                        noOfItemsForThisPage
                                            ? noOfItemsForThisPage
                                            : (+customPaginationPageNumber -
                                                  1) *
                                                  10 +
                                              1}
                                        -
                                        {+customPaginationPageNumber * 10 >
                                        noOfItemsForThisPage
                                            ? noOfItemsForThisPage
                                            : +customPaginationPageNumber *
                                              10}{' '}
                                        of {noOfItemsForThisPage}
                                    </FWTypography>
                                </Box>
                            )}
                        <Pagination
                            count={useCustomPagination}
                            onChange={customPaginationOnChange}
                            page={customPaginationPageNumber}
                        />
                    </Box>
                ) : (
                    <></>
                )}
            </div>
        </>
    );
};

export default FWDataGrid;
