import {
    DocumentStateEnum,
    IDocumentDetails,
    INewDocument,
} from './Document.model';

export interface ICreateDocumentPageData {
    entities: {
        entity_id: string;
        entity_name: string;
        default_currency_id: string;
    }[];
}

export const InitialCreateDocumentValue = (): INewDocument => ({
    entity_id: '',
    seller_entity_id: null,
    document_entry_id: null,
    document_history_id: null,
    document_name: '',
    document_id: '',
    template_id: '',
    custom_fields: {
        section_list: [],
    },
    custom_fields_negotiate: {
        section_list: [],
    },
    role: '',
    attachment_ids: [],
    document_banner_id: [],
    document_description: '',
    document_logo_id: [],
    document_validity_datetime: '',
    custom_sections: [],
});

export const InitialDocumentResponseValue = (): IDocumentDetails => ({
    document_id: '',
    document_name: '',
    document_banner: [],
    document_description: '',
    document_logo: [],
    document_validity_datetime: '',
    created_by_user_name: '',
    document_entry_id: null,
    document_history_id: null,
    created_by_user_id: '',
    created_datetime: '',
    state: DocumentStateEnum.DRAFT,
    modified_datetime: '',
    buyer_enterprise_id: '',
    buyer_entity_id: '',
    buyer_entity_name: '',
    seller_enterprise_id: '',
    seller_entity_id: '',
    seller_entity_name: '',
    additional_details: {
        template_id: '',
    },
    custom_sections: [],
    custom_fields: {
        section_list: [],
    },
    custom_fields_negotiate: {
        section_list: [],
    },
    attachments: [],
});
