import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import {
    IItemAddProviders,
    ItemReducerAction,
    useItemAdd,
} from '../../Hooks/ItemDirectory/ItemDirectoryHook';
import { IItemDetails } from '../../Interfaces/ItemDirectoryInterface';
import { IItemAddEditResponse } from '../../Services/ItemDirectoryService';
import ItemAddEditContainer, { ItemPageType } from './ItemAddEditContainer';
import {
    IINewItemTemplate,
    useNewItemAdditionalCost,
} from '../../Hooks/ItemDirectory/ItemAdditionalCostHook';

interface IItemAddContainerProps {
    event?: boolean;
    onSuccess?: (
        new_enterprise_item_id: string,
        isFinishedGood: boolean
    ) => void;
    onCancel?: () => void;
    defaultItemName?: string;
    // if below prop is passed the newly created item will be linked to this entity
    // else if not passed it'll be added only to an enterprise and not to any entity (user will have to link it manually)
    entityIDToLink?: string;
    shouldRedirect?: boolean;
}

const ItemAddContainer = ({
    event,
    onSuccess,
    onCancel,
    defaultItemName,
    entityIDToLink,
    shouldRedirect = true,
}: IItemAddContainerProps) => {
    const history = useHistory();

    const handleDetailChange = (data: ItemReducerAction) =>
        updateItemDetails(data);
    const { newItemTemplateSectionList, newGetTemplateFields } =
        useNewItemAdditionalCost(handleDetailChange, ItemPageType.ADD);

    const [templateDetails, setTemplateDetails] = useState<IINewItemTemplate>();

    useEffect(() => {
        setTemplateDetails(newItemTemplateSectionList);
    }, [newItemTemplateSectionList]);

    useEffect(() => {
        newGetTemplateFields();
    }, [newGetTemplateFields]);

    const {
        newItemDetails,
        updateItemDetails,
        measurements,
        currencies,
        isValidDetails,
        handleItemSave,
        globalTags,
        fetchItemMeasurements,
    }: IItemAddProviders = useItemAdd(
        defaultItemName ?? '',
        entityIDToLink ?? null,
        templateDetails
    );

    const handleEmitSaveAction = (
        msg: IItemAddEditResponse,
        isFinishedGood: boolean
    ) => {
        if (msg.success) {
            if (onSuccess) {
                onSuccess(msg.itemId, isFinishedGood);
            } else {
                history.replace(
                    `/admin/items/item-directory/edit/${msg.itemId}`
                );
            }
        } else {
            toast.error(`Item add failed`);
        }
    };

    return (
        <>
            {/* {hookState.state === HookStateValue.LOADING && <LoadingBackDrop />} */}
            <ItemAddEditContainer
                type={ItemPageType.ADD}
                data={newItemDetails}
                fetchItemMeasurements={fetchItemMeasurements}
                handleChange={handleDetailChange}
                measurements={measurements}
                currencies={currencies}
                isValidDetails={isValidDetails}
                handleItemSave={handleItemSave}
                emitSaveAction={handleEmitSaveAction}
                event={event}
                ogData={{} as IItemDetails}
                globalTags={globalTags}
                shouldRedirect={shouldRedirect}
            />
        </>
    );
};

export default ItemAddContainer;
