import { Box, Grid } from '@mui/material';
import { cloneDeep, isEqual } from 'lodash';
import { FWTooltip } from '../../../../Common/FWCustomTooltip';
import { FWTypography } from '../../../../Common/FWTypograhy';
import SingleFieldTypeRenderer from '../../../../FieldTypes/components/SingleFieldTypeRenderer';
import SingleDateFieldRenderer from '../../../../ProjectGlCostCenter/components/SingleDateFieldRenderer';
import { IItemField } from '../../Helpers/ItemHelpers';
import { CustomFieldType } from '../../../../Global/Interfaces/TemplateInterface';
import { IINewItemTemplate } from '../../Hooks/ItemDirectory/ItemAdditionalCostHook';
import {
    ItemReducerAction,
    ItemUpdateActions,
} from '../../Hooks/ItemDirectory/ItemDirectoryHook';
import {
    ICustomField,
    ICustomSection,
} from '../../../../Global/Interfaces/TemplateInterface';
import { useCallback, useEffect } from 'react';

interface IItemCustomFieldsProps {
    custom_sections: ICustomSection[];
    handleChange: (action: ItemReducerAction) => void;
    newItemTemplateSectionList: IINewItemTemplate | undefined;
    customFieldTemplateSetting: {
        [field: string]: IItemField;
    };
}

const ItemCustomFields = ({
    custom_sections,
    handleChange,
    customFieldTemplateSetting,
    newItemTemplateSectionList,
}: IItemCustomFieldsProps) => {
    const handleCustomFieldUpdate = (
        customField: ICustomField,
        sectionName: string,
        newValue: string | string[] | boolean | number
    ) => {
        const currSection = custom_sections.find(
            (sectionItem) => sectionItem.name === sectionName
        );

        if (currSection) {
            let currField = currSection.custom_fields.find(
                (field) => field.name === customField.name
            );

            if (currField) {
                currField.value = newValue;
            }
        }
        console.log('custom_sections', custom_sections);

        handleChange({
            type: ItemUpdateActions.CUSTOM_SECTION,
            value: custom_sections,
        });
    };

    const getValueForCustomFields = useCallback(
        (fieldName: string, fieldType: CustomFieldType) => {
            const fieldNameExists = custom_sections[0]?.custom_fields?.find(
                (field) => field.name === fieldName
            );
            console.log('fieldNameExists', fieldNameExists);
            if (fieldNameExists) {
                return fieldNameExists.value;
            } else {
                return fieldType === 'MULTI_CHOICE' ? [] : null;
            }
        },
        [custom_sections]
    );

    // getValueForCustomFields

    // Working
    useEffect(() => {
        if (newItemTemplateSectionList) {
            let customSection: ICustomSection = {
                name: newItemTemplateSectionList?.custom_sections[0].name,
                section_type: 'ITEM',
                custom_fields:
                    newItemTemplateSectionList.custom_sections[0].custom_fields.map(
                        (field) => {
                            return {
                                name: field.name,
                                type: field.type as CustomFieldType,
                                value: getValueForCustomFields(
                                    field.name,
                                    field.type
                                ),

                                is_locked: true,
                                is_visible: true,
                                description: field.description,
                                is_required: field.is_required,
                                is_negotiable: true,
                            };
                        }
                    ),
            };

            let custom_sections = [customSection];

            console.log('custom_sections 2', custom_sections);
            handleChange({
                type: ItemUpdateActions.CUSTOM_SECTION,
                value: custom_sections,
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [newItemTemplateSectionList]);

    console.log('heyyyCustom', cloneDeep(custom_sections));

    console.log(
        'newItemTemplateSectionList',
        cloneDeep(newItemTemplateSectionList?.custom_sections)
    );

    return (
        <>
            {custom_sections?.map((section) => {
                return (
                    <Grid
                        container
                        sx={{
                            display: 'grid',
                            gridTemplateColumns: 'repeat(4, 1fr)',
                            gap: '10px',
                        }}
                    >
                        {section.custom_fields
                            .sort((a, b) => a.name.localeCompare(b.name))
                            .map((customField) => {
                                let fieldSettingFromTemplate =
                                    customFieldTemplateSetting[
                                        customField.name
                                    ];

                                switch (
                                    fieldSettingFromTemplate?.constraints
                                        ?.field_type
                                ) {
                                    case 'SHORTTEXT':
                                        return (
                                            <Grid>
                                                <Grid
                                                    sx={{
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        gap: '0.5rem',
                                                    }}
                                                >
                                                    <FWTypography
                                                        color={'text.secondary'}
                                                    >
                                                        {customField.name}{' '}
                                                        {!fieldSettingFromTemplate.is_required &&
                                                            '(optional)'}
                                                    </FWTypography>
                                                    {customField?.description && (
                                                        <FWTooltip
                                                            title={
                                                                customField?.description
                                                            }
                                                            placement="right"
                                                        >
                                                            <Box color="primary.main">
                                                                <i className="bi bi-question-circle" />
                                                            </Box>
                                                        </FWTooltip>
                                                    )}
                                                </Grid>
                                                <SingleFieldTypeRenderer
                                                    fieldType={
                                                        fieldSettingFromTemplate
                                                            .constraints
                                                            .field_type
                                                    }
                                                    constraints={{
                                                        fieldType:
                                                            CustomFieldType.SHORTTEXT,
                                                        maxLimit:
                                                            fieldSettingFromTemplate
                                                                .constraints
                                                                .max_limit,
                                                        minLimit:
                                                            fieldSettingFromTemplate
                                                                .constraints
                                                                .min_limit,
                                                    }}
                                                    value={
                                                        customField.value as string
                                                    }
                                                    errors={
                                                        fieldSettingFromTemplate.is_required &&
                                                        ((typeof customField.value ===
                                                            'string' &&
                                                            customField.value?.trim()) ===
                                                            '' ||
                                                            customField.value ===
                                                                null)
                                                    }
                                                    helper={{
                                                        text:
                                                            fieldSettingFromTemplate.is_required &&
                                                            ((typeof customField.value ===
                                                                'string' &&
                                                                customField.value?.trim()) ===
                                                                '' ||
                                                                customField.value ===
                                                                    null)
                                                                ? 'Field is requied'
                                                                : '',
                                                    }}
                                                    updateHandler={(
                                                        newValue: string
                                                    ) => {
                                                        handleCustomFieldUpdate(
                                                            customField,
                                                            section.name,
                                                            newValue
                                                        );
                                                    }}
                                                />
                                            </Grid>
                                        );
                                    case 'LONGTEXT':
                                        return (
                                            <Grid>
                                                <Grid
                                                    sx={{
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        gap: '0.5rem',
                                                    }}
                                                >
                                                    <FWTypography
                                                        color={'text.secondary'}
                                                    >
                                                        {customField.name}{' '}
                                                        {!fieldSettingFromTemplate.is_required &&
                                                            '(optional)'}
                                                    </FWTypography>
                                                    {customField?.description && (
                                                        <FWTooltip
                                                            title={
                                                                customField?.description
                                                            }
                                                            placement="right"
                                                        >
                                                            <Box color="primary.main">
                                                                <i className="bi bi-question-circle" />
                                                            </Box>
                                                        </FWTooltip>
                                                    )}
                                                </Grid>
                                                <SingleFieldTypeRenderer
                                                    fieldType={
                                                        fieldSettingFromTemplate
                                                            .constraints
                                                            .field_type
                                                    }
                                                    constraints={{
                                                        fieldType:
                                                            CustomFieldType.LONGTEXT,
                                                        maxLimit:
                                                            fieldSettingFromTemplate
                                                                .constraints
                                                                .max_limit,
                                                        minLimit:
                                                            fieldSettingFromTemplate
                                                                .constraints
                                                                .min_limit,
                                                    }}
                                                    value={
                                                        customField.value as string
                                                    }
                                                    errors={
                                                        fieldSettingFromTemplate.is_required &&
                                                        ((typeof customField.value ===
                                                            'string' &&
                                                            customField.value?.trim()) ===
                                                            '' ||
                                                            customField.value ===
                                                                null)
                                                    }
                                                    helper={{
                                                        text:
                                                            fieldSettingFromTemplate.is_required &&
                                                            ((typeof customField.value ===
                                                                'string' &&
                                                                customField.value?.trim()) ===
                                                                '' ||
                                                                customField.value ===
                                                                    null)
                                                                ? 'Field is requied'
                                                                : '',
                                                    }}
                                                    updateHandler={(
                                                        newValue: string
                                                    ) => {
                                                        handleCustomFieldUpdate(
                                                            customField,
                                                            section.name,
                                                            newValue
                                                        );
                                                    }}
                                                />
                                            </Grid>
                                        );
                                    case 'DATE':
                                    case 'DATETIME':
                                        return (
                                            <Grid>
                                                <Grid
                                                    sx={{
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        gap: '0.5rem',
                                                    }}
                                                >
                                                    <FWTypography
                                                        color={'text.secondary'}
                                                    >
                                                        {customField.name}{' '}
                                                        {!fieldSettingFromTemplate.is_required &&
                                                            '(optional)'}{' '}
                                                    </FWTypography>
                                                    {customField?.description && (
                                                        <FWTooltip
                                                            title={
                                                                customField?.description
                                                            }
                                                            placement="right"
                                                        >
                                                            <Box color="primary.main">
                                                                <i className="bi bi-question-circle" />
                                                            </Box>
                                                        </FWTooltip>
                                                    )}
                                                </Grid>
                                                <SingleDateFieldRenderer
                                                    value={
                                                        customField.value as string
                                                    }
                                                    rendererProperty={{
                                                        gridSize: 12,
                                                        label: '',
                                                        required: true,
                                                        type: 'DATE',
                                                        is_hidden: false,
                                                    }}
                                                    error={
                                                        fieldSettingFromTemplate.is_required &&
                                                        !Boolean(
                                                            customField.value
                                                        )
                                                    }
                                                    helper={{
                                                        text:
                                                            fieldSettingFromTemplate.is_required &&
                                                            !Boolean(
                                                                customField.value
                                                            )
                                                                ? 'Field is requied'
                                                                : '',
                                                    }}
                                                    updateHandler={(
                                                        newValue: string | null
                                                    ) => {
                                                        if (newValue)
                                                            handleCustomFieldUpdate(
                                                                customField,
                                                                section.name,
                                                                newValue
                                                            );
                                                    }}
                                                />
                                            </Grid>
                                        );
                                    case 'FLOAT':
                                        return (
                                            <Grid>
                                                <Grid
                                                    sx={{
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        gap: '0.5rem',
                                                    }}
                                                >
                                                    <FWTypography
                                                        color={'text.secondary'}
                                                    >
                                                        {customField.name}{' '}
                                                        {!fieldSettingFromTemplate.is_required &&
                                                            '(optional)'}
                                                    </FWTypography>
                                                    {customField?.description && (
                                                        <FWTooltip
                                                            title={
                                                                customField?.description
                                                            }
                                                            placement="right"
                                                        >
                                                            <Box color="primary.main">
                                                                <i className="bi bi-question-circle" />
                                                            </Box>
                                                        </FWTooltip>
                                                    )}
                                                </Grid>

                                                <SingleFieldTypeRenderer
                                                    fieldType={
                                                        fieldSettingFromTemplate
                                                            .constraints
                                                            .field_type
                                                    }
                                                    constraints={{
                                                        fieldType:
                                                            CustomFieldType.FLOAT,
                                                        maxLimit:
                                                            fieldSettingFromTemplate
                                                                .constraints
                                                                .max_limit,
                                                        minLimit:
                                                            fieldSettingFromTemplate
                                                                .constraints
                                                                .min_limit,
                                                    }}
                                                    value={
                                                        customField.value as string
                                                    }
                                                    errors={
                                                        fieldSettingFromTemplate.is_required &&
                                                        ((typeof customField.value ===
                                                            'string' &&
                                                            customField.value?.trim()) ===
                                                            '' ||
                                                            customField.value ===
                                                                null)
                                                    }
                                                    helper={{
                                                        text:
                                                            fieldSettingFromTemplate.is_required &&
                                                            ((typeof customField.value ===
                                                                'string' &&
                                                                customField.value?.trim()) ===
                                                                '' ||
                                                                customField.value ===
                                                                    null)
                                                                ? 'Field is requied'
                                                                : '',
                                                    }}
                                                    updateHandler={(
                                                        newValue: string
                                                    ) => {
                                                        handleCustomFieldUpdate(
                                                            customField,
                                                            section.name,
                                                            newValue
                                                        );
                                                    }}
                                                />
                                            </Grid>
                                        );
                                    case 'INTEGER':
                                        return (
                                            <Grid>
                                                <Grid
                                                    sx={{
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        gap: '0.5rem',
                                                    }}
                                                >
                                                    <FWTypography
                                                        color={'text.secondary'}
                                                    >
                                                        {customField.name}{' '}
                                                        {!fieldSettingFromTemplate.is_required &&
                                                            '(optional)'}
                                                    </FWTypography>
                                                    {customField?.description && (
                                                        <FWTooltip
                                                            title={
                                                                customField?.description
                                                            }
                                                            placement="right"
                                                        >
                                                            <Box color="primary.main">
                                                                <i className="bi bi-question-circle" />
                                                            </Box>
                                                        </FWTooltip>
                                                    )}
                                                </Grid>
                                                <SingleFieldTypeRenderer
                                                    fieldType={
                                                        fieldSettingFromTemplate
                                                            .constraints
                                                            .field_type
                                                    }
                                                    constraints={{
                                                        fieldType:
                                                            CustomFieldType.INTEGER,
                                                        maxLimit:
                                                            fieldSettingFromTemplate
                                                                .constraints
                                                                .max_limit,
                                                        minLimit:
                                                            fieldSettingFromTemplate
                                                                .constraints
                                                                .min_limit,
                                                    }}
                                                    value={
                                                        customField.value as string
                                                    }
                                                    errors={
                                                        fieldSettingFromTemplate.is_required &&
                                                        ((typeof customField.value ===
                                                            'string' &&
                                                            customField.value?.trim()) ===
                                                            '' ||
                                                            customField.value ===
                                                                null)
                                                    }
                                                    helper={{
                                                        text:
                                                            fieldSettingFromTemplate.is_required &&
                                                            ((typeof customField.value ===
                                                                'string' &&
                                                                customField.value?.trim()) ===
                                                                '' ||
                                                                customField.value ===
                                                                    null)
                                                                ? 'Field is requied'
                                                                : '',
                                                    }}
                                                    updateHandler={(
                                                        newValue: string
                                                    ) => {
                                                        handleCustomFieldUpdate(
                                                            customField,
                                                            section.name,
                                                            newValue
                                                        );
                                                    }}
                                                />
                                            </Grid>
                                        );
                                    case 'BOOLEAN':
                                        return (
                                            <Grid sx={{ width: 'fit-content' }}>
                                                <Grid
                                                    sx={{
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        gap: '0.5rem',
                                                    }}
                                                >
                                                    <FWTypography
                                                        color={'text.secondary'}
                                                    >
                                                        {customField.name}

                                                        {!fieldSettingFromTemplate.is_required &&
                                                            '(optional)'}
                                                    </FWTypography>
                                                    {customField?.description && (
                                                        <FWTooltip
                                                            title={
                                                                customField?.description
                                                            }
                                                            placement="right"
                                                        >
                                                            <Box color="primary.main">
                                                                <i className="bi bi-question-circle" />
                                                            </Box>
                                                        </FWTooltip>
                                                    )}
                                                </Grid>

                                                <SingleFieldTypeRenderer
                                                    fieldType={'BOOLEAN'}
                                                    constraints={{
                                                        fieldType:
                                                            CustomFieldType.BOOLEAN,
                                                    }}
                                                    value={
                                                        customField.value as boolean
                                                    }
                                                    errors={
                                                        fieldSettingFromTemplate.is_required &&
                                                        customField.value ===
                                                            null
                                                    }
                                                    helper={{
                                                        text:
                                                            fieldSettingFromTemplate.is_required &&
                                                            customField.value ===
                                                                null
                                                                ? 'Field is requied'
                                                                : '',
                                                    }}
                                                    updateHandler={(
                                                        newValue: boolean
                                                    ) => {
                                                        handleCustomFieldUpdate(
                                                            customField,
                                                            section.name,
                                                            newValue
                                                        );
                                                    }}
                                                />
                                            </Grid>
                                        );
                                    case 'CHOICE':
                                        if (
                                            fieldSettingFromTemplate
                                                ?.constraints.choice_type ===
                                            'DROPDOWN'
                                        ) {
                                            return (
                                                <Grid>
                                                    <Grid
                                                        sx={{
                                                            display: 'flex',
                                                            alignItems:
                                                                'center',
                                                            gap: '0.5rem',
                                                        }}
                                                    >
                                                        <FWTypography
                                                            color={
                                                                'text.secondary'
                                                            }
                                                        >
                                                            {customField.name}{' '}
                                                            {!fieldSettingFromTemplate.is_required &&
                                                                '(optional)'}
                                                        </FWTypography>
                                                        {customField?.description && (
                                                            <FWTooltip
                                                                title={
                                                                    customField?.description
                                                                }
                                                                placement="right"
                                                            >
                                                                <Box color="primary.main">
                                                                    <i className="bi bi-question-circle" />
                                                                </Box>
                                                            </FWTooltip>
                                                        )}
                                                    </Grid>
                                                    <SingleFieldTypeRenderer
                                                        fieldType={
                                                            fieldSettingFromTemplate
                                                                ?.constraints
                                                                .field_type
                                                        }
                                                        constraints={{
                                                            fieldType:
                                                                CustomFieldType.CHOICE,
                                                            choices:
                                                                fieldSettingFromTemplate
                                                                    ?.constraints
                                                                    .choices ??
                                                                [],
                                                            choiceType:
                                                                fieldSettingFromTemplate
                                                                    ?.constraints
                                                                    .choice_type,
                                                        }}
                                                        value={
                                                            customField?.value as string
                                                        }
                                                        errors={
                                                            fieldSettingFromTemplate.is_required &&
                                                            !Boolean(
                                                                customField.value
                                                            )
                                                        }
                                                        helper={{
                                                            text:
                                                                fieldSettingFromTemplate.is_required &&
                                                                !Boolean(
                                                                    customField.value
                                                                )
                                                                    ? 'Field is requied'
                                                                    : '',
                                                        }}
                                                        updateHandler={(
                                                            newValue: string
                                                        ) => {
                                                            handleCustomFieldUpdate(
                                                                customField,
                                                                section.name,
                                                                newValue
                                                            );
                                                        }}
                                                    />
                                                </Grid>
                                            );
                                        } else {
                                            return (
                                                <Grid>
                                                    <Grid
                                                        sx={{
                                                            display: 'flex',
                                                            alignItems:
                                                                'center',
                                                            gap: '0.5rem',
                                                        }}
                                                    >
                                                        <FWTypography
                                                            color={
                                                                'text.secondary'
                                                            }
                                                        >
                                                            {customField.name}{' '}
                                                            {!fieldSettingFromTemplate.is_required &&
                                                                '(optional)'}
                                                        </FWTypography>
                                                        {customField?.description && (
                                                            <FWTooltip
                                                                title={
                                                                    customField?.description
                                                                }
                                                                placement="right"
                                                            >
                                                                <Box color="primary.main">
                                                                    <i className="bi bi-question-circle" />
                                                                </Box>
                                                            </FWTooltip>
                                                        )}
                                                    </Grid>

                                                    <SingleFieldTypeRenderer
                                                        fieldType={
                                                            fieldSettingFromTemplate
                                                                ?.constraints
                                                                .field_type
                                                        }
                                                        constraints={{
                                                            fieldType:
                                                                CustomFieldType.CHOICE,
                                                            choices:
                                                                fieldSettingFromTemplate
                                                                    ?.constraints
                                                                    .choices ??
                                                                [],
                                                            choiceType:
                                                                'MULTI_SELECT',
                                                        }}
                                                        value={
                                                            customField.value as string[]
                                                        }
                                                        errors={
                                                            customField.is_required &&
                                                            (isEqual(
                                                                customField.value,
                                                                []
                                                            ) ||
                                                                !Boolean(
                                                                    customField.value
                                                                ))
                                                        }
                                                        helper={{
                                                            text:
                                                                customField.is_required &&
                                                                (isEqual(
                                                                    customField.value,
                                                                    []
                                                                ) ||
                                                                    !Boolean(
                                                                        customField.value
                                                                    ))
                                                                    ? 'Field is required'
                                                                    : '',
                                                        }}
                                                        updateHandler={(
                                                            newValue: string[]
                                                        ) => {
                                                            handleCustomFieldUpdate(
                                                                customField,
                                                                section.name,
                                                                newValue
                                                            );
                                                        }}
                                                    />
                                                </Grid>
                                            );
                                        }
                                    default:
                                        return <></>;
                                }
                            })}
                    </Grid>
                );
            })}
        </>
    );
};

export default ItemCustomFields;
