import {
    IAdditionalCost,
    IAdditionalCostsBackend,
    IDiscount,
} from '../../../AdditionalCost/models/AdditionalCost.model';
import { TemplateItemOption } from '../../../BuyerTemplates/Interfaces/TemplatePageInterfaces';
import { StandardPaymentReferenceDateEnum } from '../../../Global/Interfaces/PaymentTermsInterface';
import { ITemplateCustomFieldOptions } from './anotherInterface';

export enum DocumentTemplateStandardFieldsEnum {
    // ATTACHMENT = 'ATTACHMENT',
    DOCUMENT_NAME = 'DOCUMENT_NAME',
    DOCUMENT_DESCRIPTION = 'DOCUMENT_DESCRIPTION',
    DOCUMENT_VALIDITY_DATE = 'DOCUMENT_VALIDITY_DATE',
    DOCUMENT_LOGO = 'DOCUMENT_LOGO',
    DOCUMENT_BANNER = 'DOCUMENT_BANNER',
    TEMPLATE = 'TEMPLATE',
}

export enum DocumentStandardSections {
    DOCUMENT_DETAIL = 'DOCUMENT_DETAIL',
}

export type standardSectionsBackendName = 'Document Details';

export interface IDocumentTemplateFieldSetting {
    standardFields: DocumentTemplateStandardFieldSettingsObj;
    customFields: {
        [field: string]: ITemplateCustomFieldOptions;
    };
}

export const TemplateStandardFieldBackendNames: {
    [BackendTemplateItemName: string]: DocumentTemplateStandardFieldsEnum;
} = {};

export type DocumentTemplateStandardFieldSettingsObj = {
    // [DocumentTemplateStandardFieldsEnum.ATTACHMENT]: ITemplateStandardFieldOptions;
    [DocumentTemplateStandardFieldsEnum.TEMPLATE]: ITemplateStandardFieldOptions;
    [DocumentTemplateStandardFieldsEnum.DOCUMENT_BANNER]: ITemplateStandardFieldOptions;
    [DocumentTemplateStandardFieldsEnum.DOCUMENT_DESCRIPTION]: ITemplateStandardFieldOptions;
    [DocumentTemplateStandardFieldsEnum.DOCUMENT_LOGO]: ITemplateStandardFieldOptions;
    [DocumentTemplateStandardFieldsEnum.DOCUMENT_NAME]: ITemplateStandardFieldOptions;
    [DocumentTemplateStandardFieldsEnum.DOCUMENT_VALIDITY_DATE]: ITemplateStandardFieldOptions;
};

export interface IDocumentTemplate {
    templateId: string;
    templateName: string;
    fieldSettings: IDocumentTemplateFieldSetting;
    sections: IRfqTemplateSections & {
        [customSection: string]: IDocumentTemplateSectionDetails;
    };
}

export const DocumentTemplateStandardFieldBackendNames: {
    [BackendTemplateItemName: string]: DocumentTemplateStandardFieldsEnum;
} = {
    'Document name': DocumentTemplateStandardFieldsEnum.DOCUMENT_NAME,
    'Document banner': DocumentTemplateStandardFieldsEnum.DOCUMENT_BANNER,
    'Document description':
        DocumentTemplateStandardFieldsEnum.DOCUMENT_DESCRIPTION,
    'Document logo': DocumentTemplateStandardFieldsEnum.DOCUMENT_LOGO,
    'Document validity date':
        DocumentTemplateStandardFieldsEnum.DOCUMENT_VALIDITY_DATE,
    // Attachments: DocumentTemplateStandardFieldsEnum.ATTACHMENT,
    // Templates: DocumentTemplateStandardFieldsEnum.TEMPLATE,
};

export type IRfqTemplateSections = {
    [section in DocumentStandardSections]: IDocumentTemplateSectionDetails;
};

export interface IDocumentTemplateSectionDetails {
    label: string;
    fieldList: ISectionField[];
}

export type ISectionField =
    | {
          fieldType: 'STANDARD';
          field: DocumentTemplateStandardFieldsEnum;
      }
    | {
          fieldType: 'CUSTOM';
          field: string;
      };

export type ITemplateStandardFieldOptionsBase = {
    label: string;
    description: string;
    // isItemLevel: boolean;
    buyerSettings: {
        showField: boolean;
        required: boolean;
    };
    sellerSettings: {
        showField: boolean;
        negotiable: boolean;
        isMandatory: boolean;
    };
    // contraints: any;
};

export type ITemplateStandardFieldOptions =
    ITemplateStandardFieldOptionsBase & {
        standardFieldCategory: 'STANDARD';
    };

export type ITemplatePaymentTermsOptions = ITemplateStandardFieldOptionsBase & {
    standardFieldCategory: 'PAYMENT_TERMS';
    paymentTermOptions:
        | ITemplatePaymentTermsOptionsInvoiceItemValues
        | ITemplatePaymentTermsOptionsPoItemPercentages;
};

export type ITemplatePaymentTermsOptionsInvoiceItemValues = {
    paymentType: 'invoiceItemValues';
    prepaymentAllowed: boolean;
    standardReferenceDateOptions: {
        [key in StandardPaymentReferenceDateEnum]: {
            label: string;
            enabled: boolean;
        };
    };
    customReferenceDateOptions: string[];
    allowOtherReferenceDate: boolean;
};

export type ITemplatePaymentTermsOptionsPoItemPercentages = {
    paymentType: 'poItemPercentages';
    prepaymentAllowed: boolean;
    deliverableNameOptions: string[];
    allowOtherDeliverableNames: boolean;
};

// type for Additional Cost and Discount
export type ITemplateAdditionalCostOptions =
    ITemplateStandardFieldOptionsBase & {
        standardFieldCategory: 'ADDITIONAL_COSTS';
        additionalCostOptions: ({
            is_required: boolean;
        } & IAdditionalCost)[];
    };

export type ITemplateTaxesOptions = ITemplateStandardFieldOptionsBase & {
    standardFieldCategory: 'TAX';
    taxesOptions: ({
        is_required: boolean;
    } & IAdditionalCost)[];
};

export type ITemplateDiscountOptions = ITemplateStandardFieldOptionsBase & {
    standardFieldCategory: 'DISCOUNT';
    discountOptions: ({
        is_required: boolean;
    } & IDiscount)[];
};

// USE TEMPLATE_INTERFACE CUSTOM-FIELD-TYPE -> Global\Interfaces\TemplateInterface.ts
export enum CustomFieldType {
    STRING = 'STRING',
    SHORT_TEXT = 'SHORT_TEXT',
    LONG_TEXT = 'LONG_TEXT',
    SHORTTEXT = 'SHORTTEXT',
    LONGTEXT = 'LONGTEXT',
    CURRENCY = 'CURRENCY',
    DECIMAL = 'DECIMAL',
    INTEGER = 'INTEGER',
    CHOICE = 'CHOICE',
    LOCATION = 'LOCATION',
    BOOLEAN = 'BOOLEAN',
    DATE = 'DATE',
    DATETIME = 'DATETIME',
    FLOAT = 'FLOAT',
    MULTI_CHOICE = 'MULTI_CHOICE',
    ATTACHMENT = 'ATTACHMENT',
    TEMPLATE = 'TEMPLATE',
    // NUMBER = 'NUMBER',
    // MULTI_SELECT = 'MULTI_SELECT',
}

export interface IDocumentTemplateBackendResponse {
    template_id: string;
    auto_reminders: {
        max_reminders: number;
        reminder_interval: number;
        is_remainder_required: boolean;
        initial_reminder_hours: number;
        // default_max_reminders: number;
        // default_reminder_interval: number;
        // default_reminder_required: boolean;
        // default_intital_reminder_hours: number;
    };
    section_list: Array<{
        section_id: string;
        section_items: Array<IDocumentTemplateSectionItemBackendResponse>;
        created_datetime: string;
        modified_datetime: string;
        deleted_datetime: any;
        name: string;
        alternate_name: string;
        description: string;
        sequence: number;
        is_visible: boolean;
        is_optional: boolean;
        created_by_user: string;
        modified_by_user: string;
        deleted_by_user: any;
        template: string;
    }>;
    entity: {
        entity_name: string;
        entity_id: string;
    };
    created_datetime: string;
    modified_datetime: string;
    deleted_datetime: any;
    name: string;
    type: string;
    description: string;
    tags: Array<string>;
    is_global: boolean;
    status: string;
    published_on: any;
    created_on: string;
    last_edited_on: string;
    created_by_user: string;
    modified_by_user: string;
    deleted_by_user: any;
    enterprise: string;
    creator: string;
    last_edited_by: string;
}

export interface IDocumentTemplateSectionItemBackendResponse {
    section_item_id: string;
    created_datetime: string;
    field_level: 'ITEM' | 'OTHER' | 'DELIVERY_SCHEDULE';

    modified_datetime: string;
    deleted_datetime: any;
    sequence: number;
    name: string;
    alternate_name: string;
    description: string;
    constraints: {
        max_limit?: number;
        min_limit?: number;
        field_type: string;
        decimal_places?: number;
        choices?: string[];
        choice_type?: 'DROPDOWN' | 'MULTI_SELECT' | undefined;
    };
    can_remove: boolean;
    is_builtin_field: boolean;
    is_required: boolean;
    is_mandatory: boolean;
    is_variable: boolean;
    additional_information: {
        is_locked: boolean;
        // is_hidden: boolean;
        is_visible: boolean;
        is_negotiable: boolean;
        is_hidden: boolean;
        additional_cost_information?: Omit<
            IAdditionalCostsBackend,
            'cost_value' | 'cost_name'
        >;
        shipping_rate_information?: Omit<
            IAdditionalCostsBackend,
            'cost_value' | 'cost_name'
        >;
        taxes_information?: Omit<
            IAdditionalCostsBackend,
            'cost_value' | 'cost_name'
        >;
        discount_information?: Omit<
            IAdditionalCostsBackend,
            'cost_value' | 'cost_name'
        >;
    };
    created_by_user: string;
    modified_by_user: string;
    deleted_by_user: any;
    section: string;
    parent_section_item: string | null;
}

export interface IDocumentTemplateItemBackendResponse {
    section_item_id: string;
    created_datetime: string;
    modified_datetime: string;
    deleted_datetime: any;
    sequence: number;
    name: string;
    alternate_name: string;
    description: string;
    constraints: {
        max_limit?: number;
        min_limit?: number;
        field_type: string;
        choices?: string[];
        decimal_places?: number;
        choice_type?: 'DROPDOWN' | 'MULTI_SELECT';
    };
    can_remove: boolean;
    is_builtin_field: boolean;
    is_required: boolean;
    is_variable: boolean;
    additional_information: {
        is_visible: boolean;
        is_negotiable: boolean;
        is_hidden: boolean;
    };
    created_by_user: string;
    modified_by_user: string;
    deleted_by_user: any;
    section: string;
    parent_section_item: string | null;
    options?: TemplateItemOption[] | null;
}
