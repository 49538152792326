/* eslint-disable react-hooks/exhaustive-deps */
import {
    Box,
    CircularProgress,
    FormHelperText,
    Grid,
    IconButton,
    TextField,
    Typography,
} from '@mui/material';
import { cloneDeep } from 'lodash';
import moment from 'moment';
import { useCallback, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { FWTooltip } from '../../Common/FWCustomTooltip';
import FWIcon from '../../Common/FWIcon';
import { FWInput } from '../../Common/FWInput';
import { FWTypography } from '../../Common/FWTypograhy';
import { getFWDate, getFWDateTime } from '../../Common/Utils/DateUtils';
import { IFileResourceType } from '../../Components/Shared/UploadFile';
import { IFieldTypeConstraints } from '../../FieldTypes/FieldTypes.model';
import SingleFieldTypeRenderer, {
    checkIfValueIsLink,
} from '../../FieldTypes/components/SingleFieldTypeRenderer';
import EditAttachmentsButtonAndPopover from '../../Global/Containers/EditAttachmentsButtonAndPopover';
import { IBulkImport } from '../../Global/Interfaces/BulkImportInterface';
import { downloadFile } from '../../Global/Services/FileStorageService';
import { IAttachment } from '../../Models/RFQ.model';
import {
    DocumentStateEnum,
    IDocumentCustomField,
    IDocumentDetails,
    IDocumentDetailsErrors,
    INewDocument,
} from '../Constants/Document.model';
import { IDocumentPageMode } from '../Constants/Interfaces/documentAccess';
import {
    IDocumentTemplate,
    ISectionField,
} from '../Constants/Interfaces/documentTemplate';
import {
    STANDARD_GRID_COL_GAP,
    STANDARD_GRID_SIZE,
    STANDARD_LONGFIELD_GRID_SIZE,
} from '../Pages/DraftDocumentPage';
import {
    // IAttachmentCustomField,
    ICustomSection,
} from '../../Global/Interfaces/TemplateInterface';

export interface IDraftDocumentCustomFieldsProps {
    templateDetails: IDocumentTemplate;
    updateDocumentDetails: (field: keyof INewDocument, value: any) => void;
    documentsPayload: INewDocument;
    errors: IDocumentDetailsErrors;
    sectionName: string;
    standardGridSize: number;
    customGridSize: {
        LONGTEXT: number;
    };
    documentDetails: IDocumentDetails;
    pageMode: IDocumentPageMode;
}

export default function DraftDocumentCustomFields({
    documentsPayload,
    errors,
    templateDetails,
    updateDocumentDetails,
    sectionName,
    standardGridSize,
    customGridSize,
    documentDetails,
    pageMode,
}: IDraftDocumentCustomFieldsProps) {
    const [docAttachments, setDocAttachments] = useState<{
        [customFieldName: string]: IAttachment[];
    }>({});

    const [localCopyOfCustomFieldNeg, setLocalCopyOfCustomFieldNeg] = useState<
        ICustomSection[]
    >(documentDetails.custom_sections);
    const [deletingAttachment, setDeletingAttchment] = useState(false);
    console.log('docAttachments', cloneDeep(docAttachments));

    useEffect(() => {
        setLocalCopyOfCustomFieldNeg(documentsPayload.custom_sections);
        // used .length beause if it changes from empty array to filled value then we need to run this (ignore this now as its fixed)
    }, [documentsPayload.custom_sections]);

    // useEffect(() => {
    //     setLocalCopyOfCustomFieldNeg(documentDetails.custom_sections);
    //     // setLocalCopyOfCustomFieldNonNeg({
    //     //     section_list: documentsPayload.custom_fields.section_list.filter(
    //     //         (section) => section.name === sectionName
    //     //     ) ?? [
    //     //         {
    //     //             name: sectionName,
    //     //             fields: [],
    //     //         },
    //     //     ],
    //     // });
    // }, [documentsPayload.custom_sections]);

    // const getAttachmentsFromField = useCallback(
    //     (
    //         fieldName: string,
    //         sectionName: string,
    //         isFieldNegotiable: boolean
    //     ) => {
    //         let attachmentList: IAttachment[] = [];

    //         if (isFieldNegotiable) {
    //             const attachmentIds =
    //                 (documentsPayload.custom_sections
    //                     .find((section) => section.name === sectionName)
    //                     ?.custom_fields?.find(
    //                         (field) => field.name === fieldName
    //                     )?.value as string[]) ?? [];

    //             attachmentList = documentDetails.attachments.filter(
    //                 (attachment) =>
    //                     attachmentIds.some(
    //                         (id) => id === attachment.attachment_id
    //                     )
    //             );
    //         } else {
    //             const attachmentIds =
    //                 (documentsPayload.custom_sections
    //                     .find((section) => section.name === sectionName)
    //                     ?.custom_fields?.find(
    //                         (field) => field.name === fieldName
    //                     )?.value as string[]) ?? [];

    //             attachmentList = documentDetails.attachments.filter(
    //                 (attachment) =>
    //                     attachmentIds.some(
    //                         (id) => id === attachment.attachment_id
    //                     )
    //             );
    //         }

    //         return attachmentList;
    //     },
    //     [documentDetails.attachments, documentsPayload.custom_sections]
    // );

    const getAttachmentsFromField = useCallback(
        (
            fieldName: string,
            sectionName: string,
            isFieldNegotiable: boolean
        ) => {
            // Find the section matching the sectionName
            const section = documentDetails.custom_sections.find(
                (section) => section.name === sectionName
            );

            if (!section) return [];

            // Find the field matching the fieldName within the section
            const field = section.custom_fields.find(
                (field) => field.name === fieldName
            );

            if (!field || !field.attachments) return [];

            // Return the attachments directly from the field
            const attachmentList: IAttachment[] = field.attachments;

            return attachmentList;
        },
        [documentDetails.custom_sections]
    );

    const updateDocAttachments = useCallback(
        (considerPrev = true) => {
            // Added this logic for deleting bug
            if (!deletingAttachment) {
                setDocAttachments((prev) => {
                    let newPrev: {
                        [customFieldName: string]: IAttachment[];
                    } = considerPrev ? prev : {};

                    documentsPayload.custom_sections.forEach((section) => {
                        section.custom_fields.forEach((field) => {
                            console.log('lover', field);
                            if (
                                ['ATTACHMENT', 'TEMPLATE'].includes(field.type)
                            ) {
                                if (newPrev[field.name]) {
                                    newPrev[field.name].push(
                                        ...getAttachmentsFromField(
                                            field.name,
                                            section.name,
                                            false
                                        )
                                    );
                                    let finalValue: IAttachment[] = [];

                                    newPrev[field.name].forEach(
                                        (attachment) => {
                                            if (
                                                !finalValue.some(
                                                    (at) =>
                                                        at.attachment_id ===
                                                        attachment.attachment_id
                                                )
                                            ) {
                                                finalValue.push(attachment);
                                            }
                                        }
                                    );

                                    newPrev[field.name] = finalValue;
                                } else {
                                    newPrev[field.name] = [
                                        ...getAttachmentsFromField(
                                            field.name,
                                            section.name,
                                            false
                                        ),
                                    ];
                                    let finalValue: IAttachment[] = [];

                                    newPrev[field.name].forEach(
                                        (attachment) => {
                                            if (
                                                !finalValue.some(
                                                    (at) =>
                                                        at.attachment_id ===
                                                        attachment.attachment_id
                                                )
                                            ) {
                                                finalValue.push(attachment);
                                            }
                                        }
                                    );

                                    newPrev[field.name] = finalValue;
                                }
                            }
                        });
                    });
                    // documentsPayload.custom_sections.forEach((section) => {
                    //     section.custom_fields.forEach((field) => {
                    //         if (['ATTACHMENT', 'TEMPLATE'].includes(field.type)) {
                    //             if (newPrev[field.name]) {
                    //                 newPrev[field.name].push(
                    //                     ...getAttachmentsFromField(
                    //                         field.name,
                    //                         section.name,
                    //                         true
                    //                     )
                    //                 );
                    //                 let finalValue: IAttachment[] = [];

                    //                 newPrev[field.name].forEach((attachment) => {
                    //                     if (
                    //                         !finalValue.some(
                    //                             (at) =>
                    //                                 at.attachment_id ===
                    //                                 attachment.attachment_id
                    //                         )
                    //                     ) {
                    //                         finalValue.push(attachment);
                    //                     }
                    //                 });

                    //                 newPrev[field.name] = finalValue;
                    //             } else {
                    //                 newPrev[field.name] = [
                    //                     ...getAttachmentsFromField(
                    //                         field.name,
                    //                         section.name,
                    //                         true
                    //                     ),
                    //                 ];
                    //                 let finalValue: IAttachment[] = [];

                    //                 newPrev[field.name].forEach((attachment) => {
                    //                     if (
                    //                         !finalValue.some(
                    //                             (at) =>
                    //                                 at.attachment_id ===
                    //                                 attachment.attachment_id
                    //                         )
                    //                     ) {
                    //                         finalValue.push(attachment);
                    //                     }
                    //                 });

                    //                 newPrev[field.name] = finalValue;
                    //             }
                    //         }
                    //     });
                    // });
                    console.log('setter 1', cloneDeep(newPrev));
                    return newPrev;
                });
            }
            setDeletingAttchment(false);
        },
        [documentDetails, documentsPayload, getAttachmentsFromField]
    );

    useEffect(() => {
        updateDocAttachments(pageMode === 'VIEW' ? false : true);
    }, [updateDocAttachments, pageMode]);

    const customSort = (a: ISectionField, b: ISectionField) => {
        const order: {
            [fieldType: string]: number;
        } = {
            LONGTEXT: 0,
            ATTACHMENT: 1,
            TEMPLATE: 2,
        };

        const typeA = templateDetails.fieldSettings.customFields[a.field]
            ?.constraints.fieldType as string;
        const typeB = templateDetails.fieldSettings.customFields[b.field]
            ?.constraints.fieldType as string;

        if (order[typeA] < order[typeB]) {
            return -1;
        } else if (order[typeA] > order[typeB]) {
            return 1;
        } else {
            return 0;
        }
    };

    const templateCF =
        templateDetails?.sections.DOCUMENT_DETAIL?.fieldList?.filter(
            (field) => field.fieldType === 'CUSTOM'
        );

    const updateCustomField = useCallback(
        (newFieldDetails: IDocumentCustomField, isFieldNegotiable: boolean) => {
            let fieldList = localCopyOfCustomFieldNeg;
            // const setFieldList = setLocalCopyOfCustomFieldNeg;

            const fieldIndex = fieldList[0]?.custom_fields?.findIndex(
                (payloadField) => payloadField.name === newFieldDetails.name
            );

            if (fieldIndex !== -1) {
                setLocalCopyOfCustomFieldNeg((prev) => {
                    let newPrev = cloneDeep(prev);

                    newPrev[0].custom_fields[fieldIndex].value =
                        newFieldDetails.value;

                    updateDocumentDetails('custom_sections', newPrev);
                    return newPrev;

                    // if (isFieldNegotiable) {
                    //     updateDocumentDetails(
                    //         'custom_fields_negotiate',
                    //         newPrev
                    //     );
                    // } else {
                    //     updateDocumentDetails('custom_fields', newPrev);
                    // }
                });
            }
        },
        [localCopyOfCustomFieldNeg, updateDocumentDetails]
    );

    return (
        <>
            {templateCF?.map((field) => {
                const fieldInfoFromTemplate =
                    templateDetails.fieldSettings.customFields[field.field];

                const isFieldNegotiable =
                    fieldInfoFromTemplate.sellerSettings.negotiable;

                const fieldList = localCopyOfCustomFieldNeg;

                const fieldInfoInDocumentDetails =
                    fieldList[0]?.custom_fields?.find(
                        (payloadField) =>
                            payloadField.name === fieldInfoFromTemplate.label
                    );

                if (!Boolean(fieldInfoInDocumentDetails)) {
                    return <></>;
                }

                const fieldError =
                    errors.custom_sections &&
                    errors.custom_sections[fieldInfoFromTemplate.label];

                // const fieldError = isFieldNegotiable
                //     ? errors.custom_fields_negotiate &&
                //       errors.custom_fields_negotiate[
                //           fieldInfoFromTemplate.label
                //       ]
                //     : errors.custom_fields &&
                //       errors.custom_fields[fieldInfoFromTemplate.label];

                switch (fieldInfoFromTemplate.constraints.fieldType) {
                    case 'SHORTTEXT':
                    case 'DATE':
                    case 'DATETIME':
                    case 'INTEGER':
                    case 'FLOAT':
                        return (
                            <StandardCustomFieldComponent
                                fieldInfo={{
                                    fieldName: fieldInfoFromTemplate.label,
                                    value:
                                        (fieldInfoInDocumentDetails?.value as string) ??
                                        '',
                                    helperText: fieldError?.message ?? '',
                                    constraints:
                                        fieldInfoFromTemplate.constraints,
                                    description:
                                        fieldInfoFromTemplate.description,
                                    error: Boolean(fieldError),
                                    fieldType:
                                        fieldInfoFromTemplate.type as any,
                                    isFieldRequired:
                                        fieldInfoFromTemplate.buyerSettings
                                            .required,
                                    maxRows: 1,
                                }}
                                pageMode={pageMode}
                                xs={STANDARD_GRID_SIZE}
                                updateHandler={(newValue: any) => {
                                    if (fieldInfoInDocumentDetails) {
                                        let newFieldDetails = cloneDeep(
                                            fieldInfoInDocumentDetails
                                        );
                                        newFieldDetails.value = newValue;
                                        updateCustomField(
                                            newFieldDetails,
                                            isFieldNegotiable
                                        );
                                    }
                                }}
                            />
                        );
                    case 'BOOLEAN':
                        return (
                            <Grid item xs={STANDARD_GRID_SIZE}>
                                <Grid
                                    sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        gap: '0.5rem',
                                    }}
                                >
                                    <FWTypography color={'text.secondary'}>
                                        {fieldInfoFromTemplate?.label}{' '}
                                        {!fieldInfoFromTemplate?.buyerSettings
                                            .required && '(optional)'}
                                    </FWTypography>
                                    {fieldInfoFromTemplate?.description && (
                                        <FWTooltip
                                            title={
                                                fieldInfoFromTemplate?.description
                                            }
                                            placement="right"
                                        >
                                            <Box color="primary.main">
                                                <i className="bi bi-question-circle" />
                                            </Box>
                                        </FWTooltip>
                                    )}
                                </Grid>
                                {pageMode === 'VIEW' ? (
                                    <FWTypography>
                                        {fieldInfoInDocumentDetails?.value?.toString()
                                            ? fieldInfoInDocumentDetails?.value?.toString()
                                            : '-'}
                                    </FWTypography>
                                ) : (
                                    <Grid sx={{ width: 'fit-content' }}>
                                        <SingleFieldTypeRenderer
                                            fieldType={
                                                fieldInfoFromTemplate
                                                    ?.constraints.fieldType
                                            }
                                            constraints={
                                                fieldInfoFromTemplate?.constraints
                                            }
                                            value={
                                                fieldInfoInDocumentDetails?.value as boolean
                                            }
                                            errors={Boolean(fieldError)}
                                            helper={{
                                                text: Boolean(fieldError)
                                                    ? fieldError?.message ?? ''
                                                    : '',
                                            }}
                                            updateHandler={(
                                                newValue: boolean
                                            ) => {
                                                if (
                                                    fieldInfoInDocumentDetails
                                                ) {
                                                    let newFieldDetails =
                                                        cloneDeep(
                                                            fieldInfoInDocumentDetails
                                                        );
                                                    newFieldDetails.value =
                                                        newValue;
                                                    updateCustomField(
                                                        newFieldDetails,
                                                        isFieldNegotiable
                                                    );
                                                }
                                            }}
                                        />
                                    </Grid>
                                )}
                            </Grid>
                        );
                    case 'CHOICE':
                        if (
                            fieldInfoFromTemplate?.constraints.choiceType ===
                            'DROPDOWN'
                        ) {
                            return (
                                <Grid item xs={STANDARD_GRID_SIZE}>
                                    <Grid
                                        sx={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            gap: '0.5rem',
                                        }}
                                    >
                                        <FWTypography color={'text.secondary'}>
                                            {fieldInfoFromTemplate?.label}{' '}
                                            {!fieldInfoFromTemplate
                                                ?.buyerSettings.required &&
                                                '(optional)'}
                                        </FWTypography>
                                        {fieldInfoFromTemplate?.description && (
                                            <FWTooltip
                                                title={
                                                    fieldInfoFromTemplate?.description
                                                }
                                                placement="right"
                                            >
                                                <Box color="primary.main">
                                                    <i className="bi bi-question-circle" />
                                                </Box>
                                            </FWTooltip>
                                        )}
                                    </Grid>
                                    {pageMode === 'EDIT' ? (
                                        <SingleFieldTypeRenderer
                                            fieldType={
                                                fieldInfoFromTemplate
                                                    ?.constraints.fieldType
                                            }
                                            constraints={{
                                                fieldType:
                                                    fieldInfoFromTemplate
                                                        ?.constraints.fieldType,
                                                choices:
                                                    fieldInfoFromTemplate
                                                        ?.constraints.choices,
                                                choiceType:
                                                    fieldInfoFromTemplate
                                                        ?.constraints
                                                        .choiceType,
                                            }}
                                            value={
                                                fieldInfoInDocumentDetails?.value as string
                                            }
                                            errors={Boolean(fieldError)}
                                            helper={{
                                                text: Boolean(fieldError)
                                                    ? fieldError?.message ?? ''
                                                    : '',
                                            }}
                                            updateHandler={(
                                                newValue: string
                                            ) => {
                                                if (
                                                    fieldInfoInDocumentDetails
                                                ) {
                                                    let newFieldDetails =
                                                        cloneDeep(
                                                            fieldInfoInDocumentDetails
                                                        );
                                                    newFieldDetails.value =
                                                        newValue;
                                                    updateCustomField(
                                                        newFieldDetails,
                                                        isFieldNegotiable
                                                    );
                                                }
                                            }}
                                        />
                                    ) : (
                                        <FWTypography>
                                            {Boolean(
                                                fieldInfoInDocumentDetails?.value
                                            )
                                                ? fieldInfoInDocumentDetails?.value
                                                : '-'}
                                        </FWTypography>
                                    )}
                                </Grid>
                            );
                        } else {
                            return (
                                <Grid item xs={STANDARD_GRID_SIZE}>
                                    <Grid
                                        sx={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            gap: '0.5rem',
                                        }}
                                    >
                                        <FWTypography color={'text.secondary'}>
                                            {fieldInfoFromTemplate?.label}{' '}
                                            {!fieldInfoFromTemplate
                                                ?.buyerSettings.required &&
                                                '(optional)'}
                                        </FWTypography>
                                        {fieldInfoFromTemplate?.description && (
                                            <FWTooltip
                                                title={
                                                    fieldInfoFromTemplate?.description
                                                }
                                                placement="right"
                                            >
                                                <Box color="primary.main">
                                                    <i className="bi bi-question-circle" />
                                                </Box>
                                            </FWTooltip>
                                        )}
                                    </Grid>
                                    {pageMode === 'EDIT' ? (
                                        <SingleFieldTypeRenderer
                                            fieldType={
                                                fieldInfoFromTemplate
                                                    ?.constraints.fieldType
                                            }
                                            constraints={{
                                                fieldType:
                                                    fieldInfoFromTemplate
                                                        ?.constraints.fieldType,
                                                choices:
                                                    fieldInfoFromTemplate
                                                        ?.constraints.choices,
                                                choiceType:
                                                    fieldInfoFromTemplate
                                                        ?.constraints
                                                        .choiceType ??
                                                    'MULTI_SELECT',
                                            }}
                                            value={
                                                (fieldInfoInDocumentDetails?.value ??
                                                    []) as string[]
                                            }
                                            errors={Boolean(fieldError)}
                                            helper={{
                                                text: Boolean(fieldError)
                                                    ? fieldError?.message ?? ''
                                                    : '',
                                            }}
                                            updateHandler={(
                                                newValue: string[]
                                            ) => {
                                                if (
                                                    fieldInfoInDocumentDetails
                                                ) {
                                                    let newFieldDetails =
                                                        cloneDeep(
                                                            fieldInfoInDocumentDetails
                                                        );
                                                    newFieldDetails.value =
                                                        newValue;
                                                    updateCustomField(
                                                        newFieldDetails,
                                                        isFieldNegotiable
                                                    );
                                                }
                                            }}
                                        />
                                    ) : (
                                        <FWTypography>
                                            {(
                                                fieldInfoInDocumentDetails?.value as string[]
                                            )?.length > 0
                                                ? (
                                                      fieldInfoInDocumentDetails?.value as string[]
                                                  )?.map((val) => (
                                                      <span
                                                          style={{
                                                              marginRight:
                                                                  '5px',
                                                          }}
                                                      >
                                                          {val};
                                                      </span>
                                                  ))
                                                : '-'}
                                        </FWTypography>
                                    )}
                                </Grid>
                            );
                        }

                    default:
                        return <></>;
                }
            })}

            {templateCF?.sort(customSort)?.map((field) => {
                const fieldInfoFromTemplate =
                    templateDetails.fieldSettings.customFields[field.field];

                const isFieldNegotiable =
                    fieldInfoFromTemplate.sellerSettings.negotiable;

                const fieldList = localCopyOfCustomFieldNeg;

                const fieldInfoInDocumentDetails =
                    fieldList[0]?.custom_fields?.find(
                        (payloadField) =>
                            payloadField.name === fieldInfoFromTemplate.label
                    );

                if (!Boolean(fieldInfoInDocumentDetails)) {
                    return <></>;
                }

                const fieldError =
                    errors.custom_sections &&
                    errors.custom_sections[fieldInfoFromTemplate.label];
                // : errors.custom_fields &&
                //   errors.custom_fields[fieldInfoFromTemplate.label];

                switch (fieldInfoFromTemplate.constraints.fieldType) {
                    case 'LONGTEXT':
                        return (
                            <StandardCustomFieldComponent
                                fieldInfo={{
                                    fieldName: fieldInfoFromTemplate.label,
                                    value:
                                        (fieldInfoInDocumentDetails?.value as string) ??
                                        '',
                                    helperText: fieldError?.message ?? '',
                                    constraints:
                                        fieldInfoFromTemplate.constraints,
                                    description:
                                        fieldInfoFromTemplate.description,
                                    error: Boolean(fieldError),
                                    fieldType:
                                        fieldInfoFromTemplate.type as any,
                                    isFieldRequired:
                                        fieldInfoFromTemplate.buyerSettings
                                            .required,
                                    maxRows: 5,
                                }}
                                pageMode={pageMode}
                                xs={STANDARD_LONGFIELD_GRID_SIZE}
                                updateHandler={(newValue: any) => {
                                    if (fieldInfoInDocumentDetails) {
                                        let newFieldDetails = cloneDeep(
                                            fieldInfoInDocumentDetails
                                        );
                                        newFieldDetails.value = newValue;
                                        updateCustomField(
                                            newFieldDetails,
                                            isFieldNegotiable
                                        );
                                    }
                                }}
                            />
                        );
                    case 'TEMPLATE':
                        return (
                            <Grid container>
                                <Box sx={{ display: 'flex' }}>
                                    <FieldNameDescription
                                        name={fieldInfoFromTemplate.label}
                                        description={
                                            fieldInfoFromTemplate.description
                                        }
                                        required={
                                            fieldInfoFromTemplate.buyerSettings
                                                .required
                                        }
                                    />
                                    {pageMode === 'EDIT' && (
                                        <DummyUploadComponent
                                            onFileUpload={(
                                                files: IAttachment[]
                                            ) => {
                                                setDocAttachments((prev) => {
                                                    let newPrev =
                                                        cloneDeep(prev);

                                                    if (
                                                        newPrev[
                                                            fieldInfoFromTemplate
                                                                .label
                                                        ]
                                                    ) {
                                                        newPrev[
                                                            fieldInfoFromTemplate
                                                                .label
                                                        ].push(...files);
                                                    } else {
                                                        newPrev[
                                                            fieldInfoFromTemplate.label
                                                        ] = [...files];
                                                    }

                                                    if (
                                                        fieldInfoInDocumentDetails
                                                    ) {
                                                        let newFieldDetails =
                                                            cloneDeep(
                                                                fieldInfoInDocumentDetails
                                                            );

                                                        newFieldDetails.value =
                                                            newPrev[
                                                                newFieldDetails
                                                                    .name
                                                            ].map(
                                                                (attachment) =>
                                                                    attachment.attachment_id
                                                            );

                                                        updateCustomField(
                                                            newFieldDetails,
                                                            isFieldNegotiable
                                                        );
                                                    }

                                                    return newPrev;
                                                });
                                            }}
                                            attachments={[]}
                                            removeAttachment={async (
                                                index: number
                                            ) => {
                                                setDocAttachments((prev) => {
                                                    let newPrev =
                                                        cloneDeep(prev);

                                                    if (
                                                        newPrev[
                                                            fieldInfoFromTemplate
                                                                .label
                                                        ]
                                                    ) {
                                                        newPrev[
                                                            fieldInfoFromTemplate
                                                                .label
                                                        ].splice(index, 1);
                                                    }

                                                    if (
                                                        fieldInfoInDocumentDetails
                                                    ) {
                                                        let newFieldDetails =
                                                            cloneDeep(
                                                                fieldInfoInDocumentDetails
                                                            );

                                                        newFieldDetails.value =
                                                            (
                                                                newPrev[
                                                                    fieldInfoFromTemplate
                                                                        .label
                                                                ] ?? []
                                                            )?.map(
                                                                (attachment) =>
                                                                    attachment.attachment_id
                                                            );

                                                        updateCustomField(
                                                            newFieldDetails,
                                                            isFieldNegotiable
                                                        );

                                                        return newPrev;
                                                    }
                                                    return newPrev;
                                                });
                                            }}
                                            resourceType={
                                                IFileResourceType.DOCUMENT_TEMPLATE
                                            }
                                            tooltipText="Upload template"
                                            xs={STANDARD_GRID_SIZE / 2}
                                        />
                                    )}
                                </Box>

                                <Grid container gap={STANDARD_GRID_COL_GAP}>
                                    {templateDetails.fieldSettings.customFields[
                                        fieldInfoFromTemplate.label
                                    ].buyerSettings.required &&
                                        docAttachments[
                                            fieldInfoFromTemplate.label
                                        ]?.length === 0 && (
                                            <FormHelperText
                                                sx={{
                                                    color: 'error.main',
                                                    fontSize: '0.8rem',
                                                }}
                                            >
                                                Input required
                                            </FormHelperText>
                                        )}
                                    {docAttachments[
                                        fieldInfoFromTemplate.label
                                    ]?.map((attachment, index) => (
                                        <UploadedDocument
                                            fileName={attachment.file_name}
                                            resourceType={
                                                IFileResourceType.DOCUMENT_TEMPLATE
                                            }
                                            deleteTooltipText="Delete template"
                                            downloadTooltipText="Download template"
                                            allowDelete={
                                                pageMode === 'EDIT' &&
                                                documentDetails.state ===
                                                    DocumentStateEnum.DRAFT
                                            }
                                            onDeleteAttachment={() =>
                                                setDocAttachments((prev) => {
                                                    console.log('here');
                                                    setDeletingAttchment(true);
                                                    let newPrev =
                                                        cloneDeep(prev);

                                                    if (
                                                        newPrev[
                                                            fieldInfoFromTemplate
                                                                .label
                                                        ]
                                                    ) {
                                                        newPrev[
                                                            fieldInfoFromTemplate
                                                                .label
                                                        ].splice(index, 1);
                                                    }

                                                    if (
                                                        fieldInfoInDocumentDetails
                                                    ) {
                                                        let newFieldDetails =
                                                            cloneDeep(
                                                                fieldInfoInDocumentDetails
                                                            );

                                                        newFieldDetails.value =
                                                            (
                                                                newPrev[
                                                                    fieldInfoFromTemplate
                                                                        .label
                                                                ] ?? []
                                                            )?.map(
                                                                (attachment) =>
                                                                    attachment.attachment_id
                                                            );

                                                        console.log(
                                                            'wowww',
                                                            cloneDeep(
                                                                newFieldDetails
                                                            )
                                                        );

                                                        updateCustomField(
                                                            newFieldDetails,
                                                            isFieldNegotiable
                                                        );

                                                        return newPrev;
                                                    }
                                                    console.log(
                                                        'setter ',
                                                        cloneDeep(newPrev)
                                                    );
                                                    return newPrev;
                                                })
                                            }
                                            attachment={attachment}
                                            xs={STANDARD_GRID_SIZE}
                                        />
                                    ))}
                                </Grid>
                            </Grid>
                        );
                    case 'ATTACHMENT':
                        return (
                            <Grid container>
                                <Box sx={{ display: 'flex' }}>
                                    <FieldNameDescription
                                        name={fieldInfoFromTemplate.label}
                                        description={
                                            fieldInfoFromTemplate.description
                                        }
                                        required={
                                            fieldInfoFromTemplate.buyerSettings
                                                .required
                                        }
                                    />
                                    {pageMode === 'EDIT' && (
                                        <DummyUploadComponent
                                            onFileUpload={(
                                                files: IAttachment[]
                                            ) => {
                                                setDocAttachments((prev) => {
                                                    let newPrev =
                                                        cloneDeep(prev);

                                                    if (
                                                        newPrev[
                                                            fieldInfoFromTemplate
                                                                .label
                                                        ]
                                                    ) {
                                                        newPrev[
                                                            fieldInfoFromTemplate
                                                                .label
                                                        ].push(...files);
                                                    } else {
                                                        newPrev[
                                                            fieldInfoFromTemplate.label
                                                        ] = [...files];
                                                    }

                                                    if (
                                                        fieldInfoInDocumentDetails
                                                    ) {
                                                        let newFieldDetails =
                                                            cloneDeep(
                                                                fieldInfoInDocumentDetails
                                                            );

                                                        newFieldDetails.value =
                                                            newPrev[
                                                                newFieldDetails
                                                                    .name
                                                            ].map(
                                                                (attachment) =>
                                                                    attachment.attachment_id
                                                            );

                                                        updateCustomField(
                                                            newFieldDetails,
                                                            isFieldNegotiable
                                                        );
                                                    }

                                                    return newPrev;
                                                });
                                            }}
                                            attachments={[]}
                                            removeAttachment={async (
                                                index: number
                                            ) => {
                                                setDocAttachments((prev) => {
                                                    let newPrev =
                                                        cloneDeep(prev);

                                                    if (
                                                        newPrev[
                                                            fieldInfoFromTemplate
                                                                .label
                                                        ]
                                                    ) {
                                                        newPrev[
                                                            fieldInfoFromTemplate
                                                                .label
                                                        ].splice(index, 1);
                                                    }

                                                    if (
                                                        fieldInfoInDocumentDetails
                                                    ) {
                                                        let newFieldDetails =
                                                            cloneDeep(
                                                                fieldInfoInDocumentDetails
                                                            );

                                                        newFieldDetails.value =
                                                            (
                                                                newPrev[
                                                                    fieldInfoFromTemplate
                                                                        .label
                                                                ] ?? []
                                                            )?.map(
                                                                (attachment) =>
                                                                    attachment.attachment_id
                                                            );

                                                        updateCustomField(
                                                            newFieldDetails,
                                                            isFieldNegotiable
                                                        );

                                                        console.log(
                                                            'setter',
                                                            cloneDeep(newPrev)
                                                        );

                                                        return newPrev;
                                                    }

                                                    return newPrev;
                                                });
                                            }}
                                            resourceType={
                                                IFileResourceType.DOCUMENT_ATTACHMENT
                                            }
                                            tooltipText="Upload attachment"
                                            xs={STANDARD_GRID_SIZE / 2}
                                        />
                                    )}
                                </Box>

                                <Grid container gap={STANDARD_GRID_COL_GAP}>
                                    {templateDetails.fieldSettings.customFields[
                                        fieldInfoFromTemplate.label
                                    ].buyerSettings.required &&
                                        docAttachments[
                                            fieldInfoFromTemplate.label
                                        ]?.length === 0 && (
                                            <FormHelperText
                                                sx={{
                                                    color: 'error.main',
                                                    fontSize: '0.8rem',
                                                }}
                                            >
                                                Input required
                                            </FormHelperText>
                                        )}
                                    {docAttachments[
                                        fieldInfoFromTemplate.label
                                    ]?.map((attachment, index) => (
                                        <UploadedDocument
                                            fileName={attachment.file_name}
                                            resourceType={
                                                IFileResourceType.DOCUMENT_ATTACHMENT
                                            }
                                            deleteTooltipText="Delete attachment"
                                            downloadTooltipText="Download attachment"
                                            allowDelete={
                                                pageMode === 'EDIT' &&
                                                documentDetails.state ===
                                                    DocumentStateEnum.DRAFT
                                            }
                                            onDeleteAttachment={() =>
                                                setDocAttachments((prev) => {
                                                    setDeletingAttchment(true);
                                                    let newPrev =
                                                        cloneDeep(prev);

                                                    if (
                                                        newPrev[
                                                            fieldInfoFromTemplate
                                                                .label
                                                        ]
                                                    ) {
                                                        newPrev[
                                                            fieldInfoFromTemplate
                                                                .label
                                                        ].splice(index, 1);
                                                    }

                                                    if (
                                                        fieldInfoInDocumentDetails
                                                    ) {
                                                        let newFieldDetails =
                                                            cloneDeep(
                                                                fieldInfoInDocumentDetails
                                                            );

                                                        newFieldDetails.value =
                                                            (
                                                                newPrev[
                                                                    fieldInfoFromTemplate
                                                                        .label
                                                                ] ?? []
                                                            )?.map(
                                                                (attachment) =>
                                                                    attachment.attachment_id
                                                            );
                                                        newFieldDetails.attachments =
                                                            newFieldDetails.attachments?.filter(
                                                                (attachment) =>
                                                                    Array.isArray(
                                                                        newFieldDetails.value
                                                                    ) &&
                                                                    newFieldDetails.value.includes(
                                                                        attachment.attachment_id
                                                                    )
                                                            );
                                                        console.log(
                                                            'root',
                                                            cloneDeep(
                                                                newFieldDetails
                                                            )
                                                        );
                                                        updateCustomField(
                                                            newFieldDetails,
                                                            isFieldNegotiable
                                                        );

                                                        // setDocAttachments(
                                                        //     (prev) => {
                                                        //         const attachments =
                                                        //             cloneDeep(
                                                        //                 prev
                                                        //             );
                                                        //         attachments[
                                                        //             fieldInfoFromTemplate.label
                                                        //         ] = attachments[
                                                        //             fieldInfoFromTemplate
                                                        //                 .label
                                                        //         ].filter(
                                                        //             (
                                                        //                 attachment
                                                        //             ) =>
                                                        //                 newFieldDetails.attachments?.some(
                                                        //                     (
                                                        //                         huhhh
                                                        //                     ) =>
                                                        //                         huhhh.attachment_id ===
                                                        //                         attachment.attachment_id
                                                        //                 )
                                                        //         );

                                                        //         console.log(
                                                        //             'meow meow',
                                                        //             attachments
                                                        //         );

                                                        //         return attachments;
                                                        //     }
                                                        // );
                                                        console.log(
                                                            'talkkkAnymore',
                                                            cloneDeep(
                                                                docAttachments[
                                                                    fieldInfoFromTemplate
                                                                        .label
                                                                ]
                                                            )
                                                        );

                                                        console.log(
                                                            'setter',
                                                            cloneDeep(newPrev)
                                                        );
                                                        return newPrev;
                                                    }
                                                    console.log(
                                                        'setter',
                                                        cloneDeep(newPrev)
                                                    );
                                                    return newPrev;
                                                })
                                            }
                                            attachment={attachment}
                                            xs={STANDARD_GRID_SIZE}
                                        />
                                    ))}
                                </Grid>
                            </Grid>
                        );
                    default:
                        return <></>;
                }
            })}
        </>
    );
}

export interface IStandardCustomFieldComponentProps {
    xs: number;
    fieldInfo: {
        fieldName: string;
        isFieldRequired: boolean;
        description: string;
        fieldType:
            | 'SHORTTEXT'
            | 'DATE'
            | 'DATETIME'
            | 'FLOAT'
            | 'INTEGER'
            | 'LONGTEXT';
        constraints: IFieldTypeConstraints;
        value: string;
        error: boolean;
        helperText: string;
        maxRows: number;
    };
    updateHandler: (newValue: string) => void;
    pageMode: IDocumentPageMode;
}

export const FieldNameDescription = ({
    name,
    required,
    description,
    infoIcon = 'bi bi-info-circle',
    variant = 'default',
}: {
    name: string;
    required: boolean;
    description: string;
    infoIcon?: string;
    variant?: 'default' | 'error';
}) => {
    return (
        <Grid
            sx={{
                display: 'flex',
                alignItems: 'center',
                gap: '0.5rem',
            }}
        >
            <FWTypography
                color={variant === 'default' ? 'text.secondary' : 'error.main'}
            >
                {name} {!required && '(optional)'}
            </FWTypography>
            {description && (
                <FWTooltip title={description}>
                    <Box color="primary.main">
                        <i className={infoIcon} />
                    </Box>
                </FWTooltip>
            )}
        </Grid>
    );
};

export const StandardCustomFieldComponent = ({
    xs,
    fieldInfo,
    updateHandler,
    pageMode,
}: IStandardCustomFieldComponentProps) => {
    if (fieldInfo.fieldType === 'LONGTEXT') {
    }
    return (
        <Grid item xs={xs}>
            <FieldNameDescription
                name={fieldInfo.fieldName}
                description={fieldInfo.description}
                required={fieldInfo.isFieldRequired}
            />

            {pageMode === 'EDIT' ? (
                fieldInfo.fieldType === 'LONGTEXT' ? (
                    <TextField
                        multiline
                        maxRows={fieldInfo.maxRows}
                        value={fieldInfo.value}
                        error={fieldInfo.error}
                        helperText={fieldInfo.helperText}
                        onChange={(e) => {
                            if (e.target.value?.length <= 1000) {
                                updateHandler(e.target.value);
                            } else if (e.target.value?.length > 1000) {
                                updateHandler(e.target.value?.slice(0, 1000));
                            }
                        }}
                        fullWidth
                        size="small"
                    />
                ) : ['DATETIME'].includes(fieldInfo.fieldType) ? (
                    <FWInput
                        type="datetime-local"
                        required
                        value={fieldInfo.value}
                        onChange={(e) => updateHandler(e.target.value)}
                        error={fieldInfo.error}
                        helper={{ text: fieldInfo.helperText }}
                        InputProps={{
                            inputProps: {
                                min: moment().format('YYYY-MM-DDTHH:mm'),
                            },
                        }}
                    />
                ) : (
                    <SingleFieldTypeRenderer
                        fieldType={fieldInfo.fieldType}
                        constraints={fieldInfo.constraints as any}
                        value={fieldInfo.value}
                        errors={fieldInfo.error}
                        helper={{ text: fieldInfo.helperText }}
                        updateHandler={(newValue: any) => {
                            updateHandler(newValue);
                        }}
                    />
                )
            ) : fieldInfo.fieldType === 'LONGTEXT' ? (
                checkIfValueIsLink(fieldInfo.value) ? (
                    <a
                        href={(fieldInfo?.value as string) ?? ''}
                        target="_blank"
                        rel="noopener noreferrer"
                        style={{ textDecoration: 'none' }}
                    >
                        <Typography
                            color={'primary'}
                            sx={{
                                textDecoration: 'none',
                                textOverflow: 'ellipsis',
                                overflow: 'hidden',
                            }}
                        >
                            {fieldInfo?.value as string}
                        </Typography>
                    </a>
                ) : (
                    <FWTypography
                        sx={{
                            whiteSpace: 'normal',
                        }}
                    >
                        {fieldInfo.value}
                    </FWTypography>
                )
            ) : (
                <FWTypography>
                    {['DATE', 'DATETIME'].includes(fieldInfo.fieldType)
                        ? fieldInfo.value
                            ? fieldInfo.fieldType === 'DATE'
                                ? getFWDate(fieldInfo.value)
                                : getFWDateTime(fieldInfo.value)
                            : '-'
                        : fieldInfo.value
                        ? fieldInfo.value
                        : '-'}
                </FWTypography>
            )}
        </Grid>
    );
};

export interface IDummyUploadComponent {
    tooltipText: string;
    xs: number;
    resourceType: IFileResourceType;
    onFileUpload: (files: IAttachment[]) => void;
    attachments: IAttachment[];
    removeAttachment: (idx: number) => Promise<void>;
    disabled?: boolean;
    multiple?: boolean;
    allowedFileTypes?: string[];
    allowHorizontalPadding?: boolean;
}

export const DummyUploadComponent = ({
    tooltipText,
    xs,
    resourceType,
    attachments,
    onFileUpload,
    removeAttachment,
    disabled,
    allowedFileTypes,
    multiple = true,
    allowHorizontalPadding = true,
}: IDummyUploadComponent) => {
    return (
        <Grid item xs={xs}>
            <FWTooltip title={tooltipText}>
                <Box>
                    <EditAttachmentsButtonAndPopover
                        attachments={attachments}
                        resourceType={resourceType}
                        multiple={multiple}
                        allowedFileTypes={allowedFileTypes}
                        onFileUpload={(files: IAttachment[]) => {
                            onFileUpload(files);
                        }}
                        CustomLoaderForFileUpload={() => (
                            <Grid sx={{ paddingX: '1rem', paddingY: '0.5rem' }}>
                                <CircularProgress
                                    sx={{
                                        fontSize: '16px',
                                        color: 'rgb(0, 122, 255)',
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        gap: '5px',
                                    }}
                                    size={16}
                                />
                            </Grid>
                        )}
                        showAddFileToolip={false}
                        removeAttachment={removeAttachment}
                        CustomComponentForFileUploadIcon={() =>
                            disabled ? (
                                <></>
                            ) : (
                                <>
                                    <Grid
                                        sx={{
                                            paddingX: allowHorizontalPadding
                                                ? '1rem'
                                                : 0,
                                            paddingY: '0.5rem',
                                            display: 'flex',
                                            justifyContent: 'center',
                                            gap: '5px',
                                            width: allowHorizontalPadding
                                                ? '100%'
                                                : 'fit-content',
                                            alignItems: 'center',
                                            borderRadius: '8px',
                                        }}
                                    >
                                        <Box color={'primary.main'}>
                                            <FWIcon
                                                name="bi bi-plus-circle"
                                                size={16}
                                            />
                                        </Box>
                                    </Grid>
                                </>
                            )
                        }
                    />
                </Box>
            </FWTooltip>
        </Grid>
    );
};

export interface IUploadedDocumentCard {
    fileName: string;
    xs: number;
    resourceType: IFileResourceType;
    attachment: IAttachment;
    onDeleteAttachment: () => void;
    allowDelete: boolean;
    downloadTooltipText: string;
    deleteTooltipText: string;
}

export const UploadedDocument = ({
    fileName,
    xs,
    resourceType,
    attachment,
    onDeleteAttachment,
    allowDelete,
    downloadTooltipText,
    deleteTooltipText,
}: IUploadedDocumentCard) => {
    const getFileNameAndFileType = (fileNameAndType: string) => {
        const nameArr = fileNameAndType.split('.');

        const fileType = nameArr[nameArr?.length - 1]?.toUpperCase();
        const fileName = nameArr.slice(0, nameArr?.length - 1).join('.');

        return { fileName, fileType };
    };
    const handleDownloadFile = async (attachmentId: string) => {
        try {
            const downloadUrl = await downloadFile(attachmentId);
            window.open(downloadUrl, '_self');
        } catch (error) {
            toast.error('Failed to download file');
        }
    };

    const returnFileId = (file: IAttachment | IBulkImport) => {
        return (file as IAttachment).attachment_id;
    };
    return (
        <Grid
            item
            xs={xs}
            sx={{
                background:
                    resourceType === IFileResourceType.DOCUMENT_TEMPLATE
                        ? '#ebf4ff'
                        : '#F0F0F2',
                borderRadius: '10px',
                padding: '1rem',
                width: 'fit-content',
                display: 'flex',
                alignItems: 'center',
            }}
        >
            <Box sx={{ flexBasis: '75%', maxWidth: '75%' }}>
                <FWTypography fontWeight={600} fontSize={20}>
                    {getFileNameAndFileType(fileName).fileName}
                </FWTypography>
                <FWTypography color={'text.secondary'} fontWeight={500}>
                    {getFileNameAndFileType(fileName).fileType}
                </FWTypography>
            </Box>
            <Box sx={{ flexBasis: '25%', display: 'flex' }}>
                <Box sx={{ flexBasis: '50%' }}>
                    <FWTooltip title={downloadTooltipText}>
                        <Box sx={{ display: 'flex' }}>
                            <IconButton
                                sx={{ color: 'black', marginLeft: 'auto' }}
                                onClick={() =>
                                    handleDownloadFile(returnFileId(attachment))
                                }
                            >
                                <FWIcon size={20} name="bi bi-download" />
                            </IconButton>
                        </Box>
                    </FWTooltip>
                </Box>
                {allowDelete && (
                    <Box sx={{ flexBasis: '50%' }}>
                        <FWTooltip title={deleteTooltipText}>
                            <Box sx={{ display: 'flex' }}>
                                <IconButton
                                    sx={{
                                        color: 'error.main',
                                        marginLeft: 'auto',
                                    }}
                                    onClick={onDeleteAttachment}
                                >
                                    <FWIcon size={20} name="bi bi-trash3" />
                                </IconButton>
                            </Box>
                        </FWTooltip>
                    </Box>
                )}
            </Box>
        </Grid>
    );
};
