// requisition_name: string,
//     template_id: string,
//     default_currency_id: string,
//     status: string,
//     notes: string,
//     total: number,
//     cart_id: string,
//     cart_item_ids: string[]
import { IPostRFQItemDetails } from '../../Models/RFQ.model';
import { baseApiSlice } from '../../Redux/Slices/BaseApiSlice';
import { get, post } from '../../Utils/api';
import { IRequistionDetailsResponse } from '../Interfaces/OrderInterfaces';

export const createOrderFromCart = async (
    payload: {
        requisition_name: string;
        template_id: string;
        default_currency_id: string;
        status: string;
        notes: string;
        total: number;
        cart_id: string;
        cart_item_ids: string[];
    },
    cartId: string
) => {
    try {
        const response = await post<any, any>(
            `/organization/item_analytics/cart/${cartId}/requisition_and_items/create/`,
            payload
        );
        return response;
    } catch (e) {}
};

export const getApproversForOrder = async (
    payload: {
        default_currency_id: string;
        total: number;
        cart_id: string;
        cart_item_ids: string[];
    },
    cartId: string
) => {
    try {
        const response = await post<any, any>(
            `/organization/item_analytics/cart/${cartId}/requisition/next_approvers/`,
            payload
        );
        return response;
    } catch (e) {}
};
export const getRequisitionTemplateDetails = async (
    templates: string[],
    type: string
) => {
    let templateIdsQuery = templates
        .map((template) => `template_ids[]=${template}`)
        .join('&');
    let res;
    if (type === 'CART_ORDERS') {
        res = await get<any>(
            `/module_templates/?template_type=ITEM_CART&${templateIdsQuery}`
        );
    } else {
        res = await get<any>(
            `/module_templates/?template_type=REQUISITION&${templateIdsQuery}`
        );
    }

    return res.data;
};
export const getItemCartTemplateDetails = async () => {
    let res = await get<any>('/module_templates/?template_type=CART');

    return res.data;
};
export const addCartItemsInEvent = async (
    eventId: string,
    eventItems: IPostRFQItemDetails[]
) => {
    return new Promise(async (resolve, reject) => {
        try {
            const payload = {
                rfq_event_items: eventItems,
            };
            const response = await post(
                `/events/rfq/${eventId}/items/bulk_create/`,
                payload
            );
            resolve(response.data);
        } catch (e) {
            reject(e);
        }
    });
};

export const checkForDuplicates = async (payload: any) => {
    return new Promise(async (resolve, reject) => {
        try {
            const response = await post(
                `/requisitions/items/group/enterprise_item/`,
                payload
            );
            resolve(response.data);
        } catch (e) {
            reject(e);
        }
    });
};

export const createEventWithCombinations = async (
    payload: any,
    eventId: any
) => {
    return new Promise(async (resolve, reject) => {
        try {
            const response = await post(
                `/events/rfq/${eventId}/requisition/items/combine/`,
                payload
            );
            resolve(response.data);
        } catch (e) {
            reject(e);
        }
    });
};

export const { useGetOrderDashboardDataMutation } =
    baseApiSlice.injectEndpoints({
        endpoints: (builder) => ({
            getOrderDashboardData: builder.mutation<
                IRequistionDetailsResponse,
                {
                    page_number: number;
                    search_text: string;
                    tab: string;
                }
            >({
                query: ({ page_number, search_text, tab }) => ({
                    url: `/dashboard/`,
                    method: 'POST',
                    body: {
                        dashboard_view: 'order',
                        items_per_page: 10,
                        page_number: page_number,
                        tab: tab,
                        search_text:
                            search_text?.length && search_text?.length > 0
                                ? search_text
                                : '',
                        sort_fields: [],
                    },
                }),
                transformResponse: (
                    baseQueryReturnValue: IRequistionDetailsResponse,
                    meta,
                    arg
                ) => {
                    return baseQueryReturnValue;
                },
            }),
        }),
    });
