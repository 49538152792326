import {
    IFileResourceType,
    IResourceFields,
} from '../../Components/Shared/UploadFile';
import { get, post, postToS3 } from '../../Utils/api';
import {
    IBulkImport,
    IBulkImportCSVProcessResponse,
    IBulkImportTemplateApiResponse,
} from '../Interfaces/BulkImportInterface';

export interface IBulkImportTemplateFields {
    explanation: string;
    format: string;
    header: string;
}

export const fetchBulkImportCSVHeaders = async (
    resource_type: IFileResourceType,
    resource_fields: IResourceFields[],
    entity_id?: string,
    templateId?: string,
    import_type?:
        | 'BOM_UPDATE'
        | 'BOM_CREATE'
        | 'BOM_DASHBOARD'
        | 'BOM_FINISHED_GOOD'
): Promise<IBulkImportTemplateFields[]> => {
    return new Promise(async (resolve, reject) => {
        try {
            const response: any = await post<any, any>(
                templateId
                    ? `/organization/bulk_import/template/${templateId}/generate/`
                    : `/organization/bulk_import/template/generate/`,
                {
                    resource_type,
                    resource_fields,
                    ...(entity_id ? { entity_id } : {}),
                    ...(import_type ? { import_type } : {}),
                }
            );
            const data: any = response.data.bulk_import_template.columns;
            resolve(data);
        } catch (err) {
            reject();
        }
    });
};

export const processCSV = async (
    bulk_import_id: string,
    additional_information?: {
        [key: string]: any;
    }
): Promise<IBulkImportCSVProcessResponse> => {
    return new Promise(async (resolve, reject) => {
        try {
            const response: any = await post<any, any>(
                `/organization/bulk_import/process/`,
                { bulk_import_id, additional_information },
                {
                    timeout: 29000,
                    headers: {
                        accessControlAllowOrigin: '*',
                    },
                }
            );
            const data: IBulkImportCSVProcessResponse = response.data.response;
            resolve(data);
            // return data;
        } catch (err: any) {
            reject(err);
        }
    });
};

export interface IUploadUrlResponse {
    bulk_import_id: string;
    url: string;
    fields: {
        AWSAccessKeyId: string;
        key: string;
        policy: string;
        signature: string;
        'x-amz-security-authData': string;
    };
}

export const uploadBulkImportFile = async (
    file: File,
    resourceType: IFileResourceType
): Promise<any> => {
    return new Promise(async (resolve, reject) => {
        try {
            const { bulk_import_id, url, fields } =
                await fetchBulkImportUploadUrl(resourceType, file.name);
            // Papa.parse(file, {
            //     download: true,
            //     // header: true,
            //     skipEmptyLines: true,
            //     complete: (result: ParseResult<ICSVData>) => {
            //
            //     },
            // });
            await uploadFileToGeneratedUrl(url, fields, file);

            // await confirmAttachment(attachment_id);
            const bulkImport: IBulkImport = {
                bulk_import_id,
                storage_id: '',
                file_name: file.name,
                to_be_deleted: false,
                isNew: true,
            };

            resolve(bulkImport);
        } catch (e) {
            reject();
        }
    });
};

export const fetchBulkImportUploadUrl = async (
    resourceType: string,
    fileName: string
): Promise<IUploadUrlResponse> => {
    const generateUploadUrlResponse: any = await post<any, any>(
        `/organization/bulk_import/url/generate/`,
        {
            file_name: fileName,
            resource_type: resourceType,
        }
    );
    //
    return {
        bulk_import_id: generateUploadUrlResponse.data['bulk_import_id'],
        url: generateUploadUrlResponse.data['url'],
        fields: generateUploadUrlResponse.data['fields'],
    };
};

export const uploadFileToGeneratedUrl = async (
    url: string,
    fields: any,
    file: File
): Promise<any> => {
    const formData = new FormData();
    for (const key in fields) {
        formData.append(key, fields[key]);
    }
    process.env.REACT_APP_ENV === 'newdbtest1' ||
    process.env.REACT_APP_ENV === 'production'
        ? formData.append('File', file)
        : formData.append('file', file);
    try {
        let res = await postToS3(url, formData);
        return res.data;
    } catch (error) {
        return false;
    }
};

export const downloadBulkImportFile = (bulkImportId: string): Promise<any> => {
    return new Promise(async (resolve, reject) => {
        try {
            const downloadURL = await post<any, string>(
                `/organization/bulk_import/url/generate/download/`,
                {
                    bulk_import_id: bulkImportId,
                }
            );

            resolve(downloadURL.data);
        } catch (err) {
            reject(err);
        }
    });
};

export const getBulkImportTemplateColumns = async (
    template_id: string
): Promise<IBulkImportTemplateApiResponse[]> => {
    return new Promise(async (resolve, reject) => {
        try {
            const resp: any = await get(
                `/organization/bulk_import/template/${template_id}/variable_columns/`
            );
            const templateColumns: any = resp.data;
            resolve(templateColumns);
        } catch (err) {
            reject(err);
        }
    });
};
