import {
    Box,
    DialogActions,
    DialogContent,
    FormControl,
    FormHelperText,
    Grid,
} from '@mui/material';
import { cloneDeep } from 'lodash';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { TemplateData } from '../../../../BuyerTemplates/Interfaces/TemplatePageInterfaces';
import FWAutocomplete from '../../../../Common/FWAutocomplete';
import { FWButton } from '../../../../Common/FWButton';
import { FWMenuItem } from '../../../../Common/FWCustomMenu';
import { FWInput } from '../../../../Common/FWInput';
import { FWPopupWithChildren } from '../../../../Common/FWPopupWithChildren';
import FWSelect from '../../../../Common/FWSelect';
import { FWTypography } from '../../../../Common/FWTypograhy';
import {
    getTemplateForSelectedEntity,
    useGetTemplateByIdQuery,
} from '../../../../Global/Helpers/templateApiSlice';
import { TemplateStandardFieldsEnum } from '../../../../Global/Interfaces/TemplateInterface';
import { IEntity } from '../../../../Organizations/Admin/Interfaces/EnterpriseInterface';
import { fetchEntities } from '../../../../Organizations/Admin/Services/EnterpriseService';
import FWAutoCompleteForProject from '../../../../ProjectGlCostCenter/components/FWAutocompleteForProject';
import { FieldNameDescription } from '../../../../SRM/Components/DraftDocumentCustomFields';
import { useDispatch } from 'react-redux';
import { batch } from 'react-redux';
import { useSelector } from 'react-redux';
import { IGlobalAppStore } from '../../../../Redux/Store';
import IntegrationWarnings from './IntegrationWarnings';
import { updateEventIntegrationStore } from '../../../../ItemAnalytics/Slices/EventIntegrationSlice';
// import useTemplateIntegrationValidation from '../../../../Events/Hooks/Buyer/useTemplateIntegrationValidation';

interface ICreateEventPopupProps {
    open: boolean;
    setOpen: React.Dispatch<React.SetStateAction<boolean>>;
    url: string;
    templateType: 'RFQ' | 'PO_GROUP';
    showCancel: boolean;
    cartId?: string;
    // cartTemplateDetails?: IItemCartTemplate;
}

type ICreateEventPopupErrorType = {
    entity: string;
    template: string;
    project: string;
};

const CreateEventPopup = ({
    open,
    setOpen,
    showCancel,
    templateType,
    url,
    cartId,
}: // cartTemplateDetails,
ICreateEventPopupProps) => {
    // states
    const dispatch = useDispatch();
    const source = useSelector(
        (store: IGlobalAppStore) => store.EventIntegrationStore.source
    );
    const [essentials, setEssentials] = useState<{
        entity_id: string;
        template_id: string;
        project_id: string;
        project_name: string;
        project_code: string;
    }>({
        entity_id: '',
        template_id: '',
        project_id: '',
        project_name: '',
        project_code: '',
    });
    const [errors, setErrors] = useState<ICreateEventPopupErrorType>({
        entity: '',
        project: '',
        template: '',
    });

    const [listOfEntities, setListOfEntities] = useState<IEntity[]>([]);
    const [
        listOfTemplatesOfSelectedEntity,
        seListtOfTemplatesOfSelectedEntity,
    ] = useState<TemplateData[]>([]);
    const history = useHistory();

    // const { validateTemplatesOnPopup } = useTemplateIntegrationValidation(
    //     essentials.entity_id
    // );
    useEffect(() => {
        fetchEntities().then((res) => {
            setListOfEntities(res);
        });
    }, []);

    useEffect(() => {
        getTemplateForSelectedEntity(essentials.entity_id, templateType).then(
            (res) => {
                seListtOfTemplatesOfSelectedEntity(res);
            }
        );
    }, [essentials.entity_id, templateType]);

    // useEffect(() => {
    //     if (source === 'REQUISITIONS') {
    //         seListtOfTemplatesOfSelectedEntity(
    //             validateTemplatesOnPopup(listOfTemplatesOfSelectedEntity)
    //         );
    //     }
    // }, [source, validateTemplatesOnPopup, listOfTemplatesOfSelectedEntity]);
    // RTKs
    // const {
    //     data: listOfEntities,
    //     refetch: getEntityList,
    //     isFetching,
    //     isLoading,
    //     error,
    // } = useGetEntityListQuery({}, { skip: false });

    // useEffect(() => {
    //     console.log(
    //         'listOfEntities',
    //         listOfEntities,
    //         isFetching,
    //         isLoading,
    //         error
    //     );
    // }, [error, isFetching, isLoading, listOfEntities]);

    // const {
    //     data: listOfTemplatesOfSelectedEntity,
    //     refetch: refetchTemplateDetails,
    // } = useGetListOfTemplatesForEntityQuery(
    //     {
    //         entityId: essentials?.entity_id,
    //         templateType: templateType as any,
    //     },
    //     {
    //         skip: !Boolean(essentials?.entity_id),
    //     }
    // );

    const { data: selectedtemplateData } = useGetTemplateByIdQuery(
        {
            entityId: essentials?.entity_id,
            templateId: essentials.template_id,
        },
        {
            skip:
                !Boolean(essentials.entity_id) ||
                !Boolean(essentials.template_id),
        }
    );

    const [standardFields, setStandardFields] = useState<any>();
    const [customFields, setCustomFields] = useState<any>();
    const [reqsStatus, setReqsStatus] = useState<any[]>([]);
    // const {
    //     commonFields,
    //     inEventFields,
    //     isBuyerExpanded,
    //     setIsBuyerExpanded,
    //     isCommonExpanded,
    //     setIsCommonExpanded,
    // } = useItemCartEventHook(cartTemplateDetails, selectedtemplateData);
    //memos
    const isProjectAtEventLevel = useMemo(() => {
        // currently only showing for rfq
        if (templateType === 'PO_GROUP') {
            return false;
        }
        if (selectedtemplateData) {
            return selectedtemplateData.sections.EVENT_DETAILS.fieldList.some(
                (field) => field.field === TemplateStandardFieldsEnum.PROJECT
            );
        }
        return false;
    }, [selectedtemplateData, templateType]);

    const projectFieldDetailsInSelectedTemplate = useMemo(() => {
        if (selectedtemplateData) {
            return selectedtemplateData.fieldSettings.standardFields[
                TemplateStandardFieldsEnum.PROJECT
            ];
        }
        return null;
    }, [selectedtemplateData]);

    const isProjectFieldMandatory = useMemo(() => {
        if (isProjectAtEventLevel && projectFieldDetailsInSelectedTemplate) {
            return projectFieldDetailsInSelectedTemplate.buyerSettings
                ?.required;
        }
        return false;
    }, [isProjectAtEventLevel, projectFieldDetailsInSelectedTemplate]);

    //callbacks
    const handleEntityChange = useCallback((newEntity: IEntity | null) => {
        if (newEntity === null) {
            setEssentials((prev) => ({
                entity_id: '',
                project_id: '',
                project_name: '',
                template_id: '',
                project_code: '',
            }));
        } else {
            setEssentials((prev) => ({
                entity_id: newEntity?.entityId,
                template_id: '',
                project_id: '',
                project_name: '',
                project_code: '',
            }));
        }
    }, []);

    const handleTemplateChange = useCallback(
        (newTemplate: TemplateData | null) => {
            if (newTemplate === null) {
                setEssentials((prev) => ({
                    ...prev,
                    template_id: '',
                    project_id: '',
                    project_name: '',
                }));
            } else {
                setEssentials((prev) => ({
                    ...prev,
                    template_id: newTemplate.template_id,
                    project_id: '',
                    project_name: '',
                }));
            }
        },
        []
    );
    // useEffect(() => {
    //     try {
    //         if (cloneDeep(listOfEntities) === undefined) {
    //             getEntityList();
    //         }
    //     } catch (err) {
    //
    //     }
    // }, [getEntityList, listOfEntities]);

    useEffect(() => {
        setErrors((prev) => {
            let newError = cloneDeep(prev);

            newError.entity = Boolean(essentials.entity_id)
                ? ''
                : 'Please select a valid entity';

            newError.template = Boolean(essentials.template_id)
                ? ''
                : 'Please select a template';

            if (isProjectFieldMandatory && !essentials.project_id) {
                newError.project = 'Please select a project';
            } else {
                newError.project = '';
            }

            return newError;
        });
    }, [
        essentials.entity_id,
        essentials.template_id,
        essentials.project_id,
        isProjectFieldMandatory,
    ]);

    useEffect(() => {
        if (essentials.entity_id === '' && listOfEntities?.length > 0) {
            handleEntityChange(listOfEntities[0]);
        }
    }, [essentials.entity_id, handleEntityChange, listOfEntities]);

    useEffect(() => {
        if (listOfTemplatesOfSelectedEntity?.length > 0) {
            if (
                essentials.template_id === '' ||
                listOfTemplatesOfSelectedEntity.find(
                    (temp) => temp.template_id === essentials.template_id
                ) === undefined
            )
                handleTemplateChange(listOfTemplatesOfSelectedEntity[0]);
        }
    }, [
        essentials.template_id,
        handleTemplateChange,
        listOfTemplatesOfSelectedEntity,
    ]);
    return (
        <FWPopupWithChildren
            open={open}
            handleClose={() => {
                setOpen(false);
            }}
            showClose={showCancel}
            applyTitleStyling={false}
            title={'Select entity and template for the event'}
        >
            <DialogContent>
                <Grid container rowGap={2}>
                    {/* Entity selection */}
                    <FormControl fullWidth required>
                        <FWTypography color={'text.secondary'}>
                            Entity
                        </FWTypography>
                        <FWSelect
                            value={essentials.entity_id}
                            error={Boolean(errors.entity)}
                            disabled={(listOfEntities ?? [])?.length === 1}
                            required
                            MenuProps={{
                                PaperProps: {
                                    sx: {
                                        bgcolor: 'white',
                                        boxShadow:
                                            '0px 5px 16px #00000024 !important',
                                        maxHeight: '300px',
                                        paddingY: '0px',
                                    },
                                },
                            }}
                        >
                            {(listOfEntities ?? [])?.map((entity, idx) => (
                                <FWMenuItem
                                    value={entity.entityId}
                                    key={entity.entityId}
                                    onClick={(e) => {
                                        // DEBUG HERE
                                        handleEntityChange(entity);
                                    }}
                                >
                                    {entity.entityName}
                                </FWMenuItem>
                            ))}
                        </FWSelect>
                        {Boolean(errors.entity) && (
                            <FormHelperText color="error.main">
                                {errors.entity}
                            </FormHelperText>
                        )}
                    </FormControl>
                    {/* Template selection */}
                    <FormControl fullWidth required>
                        <FWTypography color={'text.secondary'}>
                            Template
                        </FWTypography>
                        <FWAutocomplete
                            disabled={
                                listOfTemplatesOfSelectedEntity?.length === 1
                            }
                            options={
                                listOfTemplatesOfSelectedEntity
                                    ? listOfTemplatesOfSelectedEntity.filter(
                                          (temp) =>
                                              temp.template_entity_id ===
                                              essentials.entity_id
                                      )
                                    : []
                            }
                            popupIcon={
                                <Box fontSize={'16px'}>
                                    <i className="bi bi-chevron-down EventsAddItemStage_DetailsArrowIconSave"></i>
                                </Box>
                            }
                            fullWidth
                            disableClearable
                            value={
                                listOfTemplatesOfSelectedEntity?.find(
                                    (temp) =>
                                        temp.template_id ===
                                        essentials?.template_id
                                ) ?? null
                            }
                            onChange={(e, newValue: TemplateData) => {
                                handleTemplateChange(newValue);
                            }}
                            getOptionLabel={(option: TemplateData) =>
                                `${option.template_name}`
                            }
                            renderInput={(params) => {
                                return (
                                    <FWInput
                                        {...params}
                                        required
                                        error={Boolean(errors.template)}
                                    />
                                );
                            }}
                        />
                    </FormControl>
                    {/* Project selection */}
                    {isProjectAtEventLevel && (
                        <FormControl fullWidth required>
                            <FieldNameDescription
                                name={
                                    projectFieldDetailsInSelectedTemplate?.label ??
                                    'Project'
                                }
                                description={
                                    projectFieldDetailsInSelectedTemplate?.description ??
                                    ''
                                }
                                required={
                                    projectFieldDetailsInSelectedTemplate
                                        ?.buyerSettings.required ?? false
                                }
                            />
                            <FWAutoCompleteForProject
                                entityId={essentials.entity_id}
                                onChange={(
                                    newProject: {
                                        project_id: string;
                                        project_code: string;
                                        project_name: string;
                                    } | null
                                ) => {
                                    if (newProject) {
                                        setEssentials((prev) => ({
                                            ...prev,
                                            project_id: newProject?.project_id,
                                            project_name:
                                                newProject.project_name,
                                        }));
                                    }
                                }}
                                projectTab="all"
                                selectedProjectCode={null}
                                selectedProjectId={essentials.project_id}
                                selectedProjectName={null}
                                error={Boolean(errors.project)}
                                // error={
                                //     'default_event_project_id' in errors
                                //     // 'default_event_project_id' in errors
                                // }
                                helperText={errors.project}
                            />
                        </FormControl>
                    )}
                    {source !== '' && (
                        <>
                            <IntegrationWarnings
                                rfqTemplate={selectedtemplateData}
                                fieldsForExport={standardFields}
                                setFieldsForExport={setStandardFields}
                                customFieldsForExport={customFields}
                                setCustomFieldsForExport={setCustomFields}
                                reqsStatus={reqsStatus}
                                setReqsStatus={setReqsStatus}
                            />
                        </>
                    )}
                </Grid>
            </DialogContent>
            <DialogActions sx={{ padding: '12px 24px 24px 24px' }}>
                {showCancel && (
                    <FWButton
                        variant="outlined"
                        onClick={() => {
                            setOpen(false);
                        }}
                        color="primary"
                    >
                        Cancel
                    </FWButton>
                )}
                &nbsp;
                <FWButton
                    variant="contained"
                    color="primary"
                    disabled={
                        (reqsStatus.length !== 0 &&
                            !reqsStatus.some((value) => value.checked)) ||
                        !Boolean(essentials.entity_id) ||
                        !Boolean(essentials.template_id) ||
                        (isProjectAtEventLevel &&
                            projectFieldDetailsInSelectedTemplate?.buyerSettings
                                ?.showField &&
                            projectFieldDetailsInSelectedTemplate?.buyerSettings
                                ?.required &&
                            !Boolean(essentials.project_id))
                    }
                    onClick={() => {
                        if (source !== '' && standardFields) {
                            let payload = reqsStatus
                                .filter((data) => !data.checked)
                                .map((req) => req.id);
                            batch(() => {
                                dispatch(
                                    updateEventIntegrationStore({
                                        type: 'UPDATE_CUSTOM_FIELDS',
                                        value: customFields,
                                    })
                                );
                                dispatch(
                                    updateEventIntegrationStore({
                                        type: 'REMOVE_REQUISITIONS',
                                        value: payload,
                                    })
                                );
                                dispatch(
                                    updateEventIntegrationStore({
                                        type: 'UPDATE_STANDARD_FIELDS',
                                        value: standardFields,
                                    })
                                );
                            });
                        }
                        history.push({
                            pathname: url,
                            state: {
                                templateId: essentials.template_id,
                                entityId: essentials.entity_id,
                                projectId: essentials.project_id,
                                projectName: essentials.project_name,
                                projectCode: essentials.project_code,
                                cartId:
                                    cartId !== null || cartId !== undefined
                                        ? cartId
                                        : '',
                            },
                        });
                        setOpen(false);
                    }}
                    id={'buyerEventsDashboard_confirmDeleteEvent'}
                >
                    Confirm
                </FWButton>
            </DialogActions>
        </FWPopupWithChildren>
    );
};

export default React.memo(CreateEventPopup);
