import { useEffect, useState } from 'react';
import {
    IRfqTemplate,
    RfqStandardSections,
    TemplateStandardFieldsEnum,
} from '../../Global/Interfaces/TemplateInterface';
import { extractFields } from '../Helpers/ItemCartIntegration';
import { useDispatch } from 'react-redux';
import { updateEventIntegrationStore } from '../Slices/EventIntegrationSlice';

export interface FieldsForExport {
    label: string;
    isChecked: boolean;
    fieldExists: boolean;
    heirarchy: boolean;
    fieldType: 'STANDARD' | 'CUSTOM';
    rfqSection?: string;
    reqSection?: string;
}

const useIntegrationWarningHook = (
    reqTemplate: IRfqTemplate,
    rfqTemplate: IRfqTemplate | undefined,
    requisition: any,
    handleFieldsForExportChange: (
        fields: any[],
        customFields: any[],
        requistionId: string
    ) => void,
    source: '' | 'CART_ORDERS' | 'REQUISITIONS'
) => {
    const dispatch = useDispatch();
    const [fields, setFields] = useState<FieldsForExport[]>([]);
    const keyMap = ['PROJECT', 'GL', 'COST_CENTER'];
    const processFields = () => {
        try {
            let newFields: any[] = [];
            if (rfqTemplate) {
                const reqFields = extractFields(reqTemplate.sections);
                const rfqFields = extractFields(rfqTemplate.sections);
                let rfqFieldNames = rfqFields.map((field) => field.name);
                const eventDetails =
                    rfqTemplate.sections.EVENT_DETAILS.fieldList.map(
                        (data) => data.field
                    );
                reqFields.forEach((field) => {
                    if (
                        eventDetails.includes(field.name) &&
                        !keyMap.includes(field.name)
                    ) {
                    } else {
                        if (
                            rfqTemplate.fieldSettings.standardFields[
                                field.name as TemplateStandardFieldsEnum
                            ]
                        ) {
                            newFields = [
                                ...newFields,
                                {
                                    label: reqTemplate.fieldSettings
                                        .standardFields[
                                        field.name as TemplateStandardFieldsEnum
                                    ].label,
                                    isChecked: true,
                                    fieldExists:
                                        (rfqFieldNames.includes(field.name) ||
                                            field.name === 'DELIVERY_DATE') &&
                                        rfqTemplate.fieldSettings
                                            .standardFields[
                                            field.name as TemplateStandardFieldsEnum
                                        ] &&
                                        rfqTemplate.fieldSettings
                                            .standardFields[
                                            field.name as TemplateStandardFieldsEnum
                                        ].buyerSettings.showField,
                                    heirarchy: keyMap.includes(field.name)
                                        ? validatePGC(field.name)
                                        : true,
                                    fieldType: 'STANDARD',
                                },
                            ];
                        } else if (
                            reqTemplate.fieldSettings.customFields[field.name]
                        ) {
                            newFields = [
                                ...newFields,
                                {
                                    label: reqTemplate.fieldSettings
                                        .customFields[field.name].label,
                                    isChecked: true,
                                    fieldExists: Boolean(
                                        rfqTemplate.fieldSettings.customFields[
                                            field.name
                                        ]
                                    ),
                                    heirarchy: keyMap.includes(field.name)
                                        ? validatePGC(field.name)
                                        : true,
                                    fieldType: 'CUSTOM',
                                    rfqSection: rfqFields.find(
                                        (f) => f.name === field.name
                                    )?.section,
                                    reqSection: field.section,
                                },
                            ];
                        }
                    }
                });
            }
            setFields(newFields);
        } catch (err) {}
    };

    useEffect(() => {
        processFields();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [rfqTemplate]);
    const validatePGC = (field: string) => {
        if (rfqTemplate) {
            const reqSettings =
                reqTemplate.fieldSettings.standardFields[
                    field as TemplateStandardFieldsEnum
                ];
            const rfqSettings =
                rfqTemplate.fieldSettings.standardFields[
                    field as TemplateStandardFieldsEnum
                ];
            if (reqSettings.fieldLevel === rfqSettings.fieldLevel) return true;
            else if (reqSettings.fieldLevel === 'OTHER') return true;
            else if (
                reqSettings.fieldLevel === 'ITEM' &&
                rfqSettings.fieldLevel === 'DELIVERY_SCHEDULE'
            )
                return true;
            else return false;
        }
    };
    useEffect(() => {
        try {
            if (fields.length !== 0) {
                let temp = fields.filter(
                    (field) =>
                        field.fieldType === 'STANDARD' &&
                        field.isChecked &&
                        field.fieldExists &&
                        field.heirarchy
                );
                let customFields = fields.filter(
                    (field) =>
                        field.fieldType === 'CUSTOM' &&
                        field.isChecked &&
                        field.fieldExists
                );
                handleFieldsForExportChange(
                    temp,
                    customFields,
                    requisition.requisition_id
                );
            }
        } catch (err) {}
        //eslint-disable-next-line
    }, [fields]);

    useEffect(() => {
        if (rfqTemplate) {
            let map = {};
            if (source === 'REQUISITIONS') {
                map = {
                    [reqTemplate.sections['ITEM_TERMS' as RfqStandardSections]
                        .label]: rfqTemplate.sections['ESSENTIAL_TERMS'].label,
                    [reqTemplate.sections[
                        'PAYMENT_AND_DELIVERY_TERMS' as RfqStandardSections
                    ].label]:
                        rfqTemplate.sections['PAYMENT_AND_DELIVERY_TERMS']
                            .label,
                    [reqTemplate.sections[
                        'ADDITIONAL_DETAILS' as RfqStandardSections
                    ].label]: rfqTemplate.sections['ADDITIONAL_DETAILS'].label,
                };
            } else if (source === 'CART_ORDERS') {
                map = {
                    [reqTemplate.sections[
                        'ESSENTIAL_TERMS' as RfqStandardSections
                    ].label]: rfqTemplate.sections['ESSENTIAL_TERMS'].label,
                    [reqTemplate.sections[
                        'PAYMENT_AND_DELIVERY_TERMS' as RfqStandardSections
                    ].label]:
                        rfqTemplate.sections['PAYMENT_AND_DELIVERY_TERMS']
                            .label,
                    [reqTemplate.sections[
                        'ADDITIONAL_DETAILS' as RfqStandardSections
                    ].label]: rfqTemplate.sections['ADDITIONAL_DETAILS'].label,
                };
            }
            dispatch(
                updateEventIntegrationStore({
                    type: 'UPDATE_KEY_MAP',
                    value: map,
                })
            );
        }
    }, [dispatch, reqTemplate.sections, rfqTemplate, source]);
    return {
        fields,
        setFields,
        validatePGC,
    };
};

export default useIntegrationWarningHook;
