import {
    IconButton,
    FormGroup,
    FormControlLabel,
    Checkbox,
    Box,
    DialogContent,
    DialogActions,
    Button,
    Grid,
    Typography,
} from '@mui/material';
import { cloneDeep } from 'lodash';
import {
    Dispatch,
    Fragment,
    SetStateAction,
    useContext,
    useEffect,
    useState,
} from 'react';
import { toast } from 'react-toastify';
import { FWTooltip } from '../../../../../Common/FWCustomTooltip';
import FWIcon from '../../../../../Common/FWIcon';
import { FWInput } from '../../../../../Common/FWInput';
import { FWPopupWithChildren } from '../../../../../Common/FWPopupWithChildren';
import { FWTypography } from '../../../../../Common/FWTypograhy';
import { useFeatureAccess } from '../../../../../FeatureAccess/Hooks/FeatureAccessHook';
import { TFeatureNames } from '../../../../../FeatureAccess/Interfaces/FeaturesInterface';
import { planUpgradeTooltipText } from '../../../../../FeatureAccess/Utils/planUpgradeTooltipText';
import {
    // CustomTemplateItemTypes,
    CustomTemplateTypes,
    ICustomTemplateList,
} from '../../../../../Global/Interfaces/CustomTemplate.interface';
import {
    ASModule,
    IAdminCustomFieldsAndChecklist,
} from '../../../Interfaces/AdminSettingsInterface';
import {
    createCustomTemplate,
    deleteCustomTemplate,
    updateCustomTemplate,
} from '../../../Services/AdminSettingsService';
import { AuthContext } from '../../../../../Contexts/AuthContext';
import { CustomFieldType } from '../../../../../Global/Interfaces/TemplateInterface';

interface ICustomFieldsDrawer {
    customFields: ICustomTemplateList;
    setAdminTemplateSettings: Dispatch<
        SetStateAction<IAdminCustomFieldsAndChecklist>
    >;
    sectionTitle?: string;
    moduleName: ASModule;
    customFieldIndexToUpdate: number;
    defaultTemplateName?: string;
    maximumAllowedCustomFields?: number | 'unlimited';
}

const getFeatureName = (moduleName: ASModule): TFeatureNames | null => {
    switch (moduleName) {
        case ASModule.EVENT:
            return 'feature-event-customfields-enabled';
        case ASModule.GOODS_RECEIPT:
            return 'feature-gr-customfields-enabled';
        case ASModule.QUALITY_CHECK:
            return 'feature-qc-customfields-enabled';
        default:
            return null;
    }
};

const getCustomTemplateType = (
    moduleName: ASModule
): CustomTemplateTypes | null => {
    switch (moduleName) {
        case ASModule.GOODS_RECEIPT:
            return CustomTemplateTypes.GRN_CUSTOM_FIELDS;
        case ASModule.QUALITY_CHECK:
            return CustomTemplateTypes.QC_CUSTOM_FIELDS;
        case ASModule.INVOICE:
            return CustomTemplateTypes.INVOICE_BATCH_ITEM_CUSTOM_FIELDS;
        case ASModule.EVENT:
            return CustomTemplateTypes.RFQ_ITEM_CUSTOM_FIELDS;
        default:
            return null;
    }
};

const CustomFieldsDrawer = ({
    sectionTitle = 'Custom fields',
    customFields,
    moduleName,
    setAdminTemplateSettings,
    customFieldIndexToUpdate,
    defaultTemplateName = 'CUSTOM_FIELDS',
    maximumAllowedCustomFields = 4,
}: ICustomFieldsDrawer) => {
    const [localCustomFields, setLocalCustomFields] = useState(
        cloneDeep(customFields)
    );
    const [open, setOpen] = useState(false);
    const { isFeatureAvailable } = useFeatureAccess();
    const [errors, setErrors] = useState<{ [key: string]: string }>({});
    const ModuleType = getCustomTemplateType(moduleName);
    const FeatureName = getFeatureName(moduleName);
    const { authData } = useContext(AuthContext);

    useEffect(() => {
        if (open) {
            setLocalCustomFields(() => {
                let newObj = cloneDeep(customFields);
                if (newObj.items.length === 0) {
                    newObj.items.push({
                        template_item_id: '',
                        template_item_name: '',
                        template_item_type: CustomFieldType.SHORTTEXT,
                        is_required: false,
                        template: '',
                        additional_information: {
                            is_negotiable: true,
                            is_visible: true,
                        },
                    });
                    return newObj;
                }
                return newObj;
            });
        }
    }, [customFields, open]);

    const handleFieldChange = (
        type: 'MANDATORY' | 'NAME' | 'TYPE' | 'NEGOTIABLE' | 'VISIBLE',
        e: any,
        index: number
    ) => {
        let newObj = cloneDeep(localCustomFields);
        switch (type) {
            case 'MANDATORY':
                newObj.items[index].is_required = e.target.checked;
                break;
            case 'NAME':
                newObj.items[index].template_item_name = e.target.value;
                break;
            case 'TYPE':
                newObj.items[index].template_item_type = e.target.value;
                break;
            case 'NEGOTIABLE':
                newObj.items[index].additional_information.is_negotiable =
                    e.target.checked;
                break;
            case 'VISIBLE':
                newObj.items[index].additional_information.is_visible =
                    e.target.checked;
                if (!e.target.checked) {
                    newObj.items[index].additional_information.is_negotiable =
                        e.target.checked;
                }
        }
        let newErrors = calculateErrors(newObj);
        setErrors(newErrors);
        setLocalCustomFields(newObj);
    };

    const handleRowUpdate = (type: 'ADD' | 'REMOVE', index: number) => {
        let newObj = cloneDeep(localCustomFields);
        switch (type) {
            case 'ADD':
                newObj.items.push({
                    template_item_id: '',
                    template_item_name: '',
                    template_item_type: CustomFieldType.SHORTTEXT,
                    is_required: false,
                    template: '',
                    additional_information: {
                        is_negotiable: true,
                        is_visible: true,
                    },
                });
                break;
            case 'REMOVE':
                newObj.items.splice(index, 1);
                break;
        }
        setLocalCustomFields(newObj);
    };

    const saveCustomFields = async () => {
        try {
            if (ModuleType !== null) {
                localCustomFields.template_name = defaultTemplateName;
                if (localCustomFields.template_id === '') {
                    let resp = await createCustomTemplate(localCustomFields);
                    setAdminTemplateSettings((prev) => {
                        let newObj = cloneDeep(prev);
                        newObj[moduleName].customFields[
                            customFieldIndexToUpdate
                        ] = resp;
                        return newObj;
                    });
                } else {
                    if (localCustomFields.items.length === 0) {
                        let entity_id = localCustomFields.entity;
                        await deleteCustomTemplate(
                            entity_id,
                            localCustomFields.template_id
                        );
                        setAdminTemplateSettings((prev) => {
                            let newObj = cloneDeep(prev);
                            newObj[moduleName].customFields[
                                customFieldIndexToUpdate
                            ] = {
                                type: ModuleType,
                                entity: entity_id,
                                template_id: '',
                                template_name: defaultTemplateName,
                                enterprise: '',
                                items: [],
                            };
                            return newObj;
                        });
                    } else {
                        let resp = await updateCustomTemplate(
                            localCustomFields
                        );
                        setAdminTemplateSettings((prev) => {
                            let newObj = cloneDeep(prev);
                            newObj[moduleName].customFields[
                                customFieldIndexToUpdate
                            ] = resp;
                            return newObj;
                        });
                    }
                }
            }
        } catch (err) {
            toast.error("Couldn't update custom fields");
        }
        setOpen(false);
    };

    const calculateErrors = (localCustomFields: any) => {
        let newErrors: { [key: string]: string } = {};
        const namesList: string[] = [];
        if (localCustomFields.template_name.trim() === '') {
            newErrors['template_name'] = 'Name is required';
        }
        let idx = 0;
        if (
            localCustomFields.template_id === '' &&
            localCustomFields.items.length === 0
        ) {
            newErrors['template_item'] = 'Items are required';
        }
        for (const row of localCustomFields.items) {
            if (row.template_item_name.trim() === '') {
                newErrors[`template_item_name_${idx}`] = 'Name is required';
            } else if (row.template_item_type.trim() === '') {
                newErrors[`template_item_type_${idx}`] = 'Type is required';
            } else {
                namesList.push(row.template_item_name.trim().toLowerCase());
            }
            idx++;
        }
        namesList.forEach((item, index) => {
            if (namesList.indexOf(item) !== index) {
                newErrors[`template_item_name_${index}`] = 'Duplicate name';
            }
        });
        return newErrors;
    };

    return (
        <>
            <Box>
                {/* In this section we need to use the root state instead of the copied local object */}

                <div className="flex flex--aic">
                    <FWTypography color="text.secondary">
                        {sectionTitle}
                    </FWTypography>
                    {authData.details?.role === 'ADMIN' && (
                        <FWTooltip
                            title={
                                FeatureName !== null &&
                                !isFeatureAvailable(FeatureName)
                                    ? planUpgradeTooltipText.featureUnavailableText
                                    : ''
                            }
                        >
                            <span>
                                {
                                    <FWTooltip title="Add custom fields">
                                        <div>
                                            <IconButton
                                                disabled={
                                                    FeatureName !== null &&
                                                    !isFeatureAvailable(
                                                        FeatureName
                                                    )
                                                }
                                                color="primary"
                                                onClick={() => {
                                                    setOpen(true);
                                                }}
                                            >
                                                <FWIcon
                                                    name="bi bi-plus-circle"
                                                    size={20}
                                                />
                                            </IconButton>
                                        </div>
                                    </FWTooltip>
                                }
                            </span>
                        </FWTooltip>
                    )}
                </div>

                <div>
                    {customFields.items.map((field, index) => (
                        <div className="flex flex--aic" key={index}>
                            <FWTypography>{index + 1}.&nbsp;</FWTypography>
                            <FWTypography>
                                {field.template_item_name}
                            </FWTypography>
                        </div>
                    ))}
                </div>
                {/* ---------------------------------------------------------- */}
            </Box>
            <FWPopupWithChildren
                open={open}
                handleClose={() => setOpen(false)}
                title={sectionTitle}
                size={'large'}
                titleAddOnElement={
                    <IconButton
                        color="primary"
                        onClick={() => {
                            handleRowUpdate('ADD', -1);
                        }}
                        disabled={
                            maximumAllowedCustomFields !== 'unlimited' &&
                            localCustomFields.items.length >=
                                maximumAllowedCustomFields
                        }
                    >
                        <FWIcon name="bi bi-plus-circle" size={20} />
                    </IconButton>
                }
            >
                <DialogContent sx={{ m: '12px' }}>
                    {localCustomFields.items.length === 0 && (
                        <div className="flex flex--aic">
                            <FWTypography color="text.secondary">
                                Add custom field
                            </FWTypography>
                            &nbsp;
                            <IconButton
                                color="primary"
                                onClick={() => {
                                    handleRowUpdate('ADD', -1);
                                }}
                            >
                                <FWIcon name="bi bi-plus-circle" size={20} />
                            </IconButton>
                        </div>
                    )}
                    <Box
                        display="grid"
                        // gridTemplateColumns={
                        //     localCustomFields.type === 'RFQ_ITEM_CUSTOM_FIELDS'
                        //         ? '24% 24% 18% 18% 10%'
                        //         : '1.25fr 1.25fr 1fr 0.5fr'
                        // }
                        columnGap="10px"
                        rowGap="10px"
                        alignItems={'center'}
                    >
                        {localCustomFields.items.map((field, index: number) => {
                            return (
                                <Fragment key={index}>
                                    <Box
                                        sx={{
                                            border: '1px solid #C4C4C8',
                                            borderRadius: '25px',
                                            padding: '15px',
                                            marginBottom: '5px',
                                        }}
                                    >
                                        <Box
                                            display="flex"
                                            justifyContent="space-between"
                                            alignItems="flex-end"
                                        >
                                            <Box
                                                display="flex"
                                                justifyContent="space-between"
                                                alignItems="flex-end"
                                                width="85%"
                                            >
                                                <Box>
                                                    <FWInput
                                                        // disabled={
                                                        //     moduleName === ASModule.EVENT
                                                        //         ? customFields.items.some(
                                                        //               (data) =>
                                                        //                   data
                                                        //                       .template_item_name
                                                        //                       .length > 0 &&
                                                        //                   data.template_item_name ===
                                                        //                       field.template_item_name
                                                        //           )
                                                        //         : false
                                                        // }
                                                        label="Field name"
                                                        value={
                                                            field.template_item_name
                                                        }
                                                        name="NAME"
                                                        onChange={(e) => {
                                                            handleFieldChange(
                                                                'NAME',
                                                                e,
                                                                index
                                                            );
                                                        }}
                                                        error={
                                                            `template_item_name_${index}` in
                                                            errors
                                                        }
                                                        helper={{
                                                            text:
                                                                `template_item_name_${index}` in
                                                                errors
                                                                    ? errors[
                                                                          `template_item_name_${index}`
                                                                      ]
                                                                    : '',
                                                        }}
                                                    />
                                                </Box>
                                                <Box>
                                                    <FormGroup>
                                                        <FormControlLabel
                                                            control={
                                                                <Checkbox size="small" />
                                                            }
                                                            label={
                                                                <>
                                                                    <span>
                                                                        Mandatory
                                                                    </span>
                                                                    &nbsp;
                                                                    <FWTooltip
                                                                        title={
                                                                            localCustomFields.type ===
                                                                            CustomTemplateTypes.INVOICE_BATCH_ITEM_CUSTOM_FIELDS
                                                                                ? 'The Seller will have to add data on this field'
                                                                                : 'The Buyer will have to add data on this field'
                                                                        }
                                                                    >
                                                                        <span color="text.secondatry">
                                                                            <IconButton color="primary">
                                                                                <FWIcon
                                                                                    name="bi bi-info-circle"
                                                                                    size={
                                                                                        20
                                                                                    }
                                                                                />
                                                                            </IconButton>
                                                                        </span>
                                                                    </FWTooltip>
                                                                </>
                                                            }
                                                            checked={
                                                                field.is_required
                                                            }
                                                            onChange={(e) => {
                                                                handleFieldChange(
                                                                    'MANDATORY',
                                                                    e,
                                                                    index
                                                                );
                                                            }}
                                                            name="MANDATORY"
                                                        />
                                                    </FormGroup>
                                                </Box>
                                            </Box>
                                            <Box>
                                                <IconButton
                                                    color="error"
                                                    onClick={() => {
                                                        handleRowUpdate(
                                                            'REMOVE',
                                                            index
                                                        );
                                                    }}
                                                    // disabled={
                                                    //     localCustomFields.items
                                                    //         .length === 1
                                                    // }
                                                >
                                                    <FWIcon
                                                        name="bi bi-dash-circle"
                                                        size={20}
                                                    />
                                                </IconButton>
                                            </Box>
                                        </Box>
                                        {localCustomFields.type ===
                                            'RFQ_ITEM_CUSTOM_FIELDS' && (
                                            <>
                                                <Box marginTop="10px">
                                                    <Typography variant="subtitle2">
                                                        Accessibility to Vendor
                                                    </Typography>
                                                </Box>
                                                <Grid container xs={12}>
                                                    <Grid item xs={6}>
                                                        <Box>
                                                            <FormGroup>
                                                                <FormControlLabel
                                                                    control={
                                                                        <Checkbox size="small" />
                                                                    }
                                                                    label={
                                                                        <>
                                                                            <span>
                                                                                Vendor
                                                                                Can
                                                                                View
                                                                            </span>
                                                                            {/* &nbsp;
                                                                    <FWTooltip title="This field is negotiable with the vendor">
                                                                        <span color="text.secondatry">
                                                                            <i className="bi bi-info-circle"></i>
                                                                        </span>
                                                                    </FWTooltip> */}
                                                                        </>
                                                                    }
                                                                    checked={
                                                                        field
                                                                            ?.additional_information
                                                                            ?.is_visible
                                                                    }
                                                                    onChange={(
                                                                        e
                                                                    ) => {
                                                                        handleFieldChange(
                                                                            'VISIBLE',
                                                                            e,
                                                                            index
                                                                        );
                                                                    }}
                                                                    name="VISIBLE"
                                                                />
                                                            </FormGroup>
                                                        </Box>
                                                    </Grid>
                                                    {field
                                                        ?.additional_information
                                                        ?.is_visible && (
                                                        <Grid item xs={6}>
                                                            <Box>
                                                                <FormGroup>
                                                                    <FormControlLabel
                                                                        control={
                                                                            <Checkbox size="small" />
                                                                        }
                                                                        label={
                                                                            <>
                                                                                <span>
                                                                                    Vendor
                                                                                    Can
                                                                                    Negotiate
                                                                                </span>
                                                                                {/* &nbsp;
                                                                    <FWTooltip title="This field is negotiable with the vendor">
                                                                        <span color="text.secondatry">
                                                                            <i className="bi bi-info-circle"></i>
                                                                        </span>
                                                                    </FWTooltip> */}
                                                                            </>
                                                                        }
                                                                        checked={
                                                                            field
                                                                                ?.additional_information
                                                                                ?.is_negotiable
                                                                        }
                                                                        onChange={(
                                                                            e
                                                                        ) => {
                                                                            handleFieldChange(
                                                                                'NEGOTIABLE',
                                                                                e,
                                                                                index
                                                                            );
                                                                        }}
                                                                        name="NEGOTIABLE"
                                                                    />
                                                                </FormGroup>
                                                            </Box>
                                                        </Grid>
                                                    )}
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <Typography variant="caption">
                                                        {!field
                                                            ?.additional_information
                                                            ?.is_visible
                                                            ? 'This field is internal to your company.'
                                                            : !field
                                                                  ?.additional_information
                                                                  .is_negotiable
                                                            ? 'Vendors can view but not respond to this field'
                                                            : 'Vendors can view and respond to this field'}
                                                    </Typography>
                                                </Grid>
                                            </>
                                        )}
                                    </Box>
                                </Fragment>
                            );
                        })}
                    </Box>
                </DialogContent>
                <DialogActions sx={{ m: '12px' }}>
                    <Button variant="outlined" onClick={() => setOpen(false)}>
                        Cancel
                    </Button>
                    &nbsp;
                    <Button
                        variant="contained"
                        onClick={() => saveCustomFields()}
                        disabled={
                            !(
                                Object.keys(calculateErrors(localCustomFields))
                                    .length === 0
                            )
                        }
                    >
                        Submit
                    </Button>
                </DialogActions>
            </FWPopupWithChildren>
        </>
    );
};

export default CustomFieldsDrawer;
