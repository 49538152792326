import { Box, Grid, IconButton } from '@mui/material';
import { useMemo, useRef, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import '../../Assets/PDFStyles/index.css';
import { FWButton } from '../../Common/FWButton';
import { HookStateValue } from '../../Common/Hooks/StateHook';
import { getFWDate } from '../../Common/Utils/DateUtils';
import LoadingBackDrop from '../../Components/Shared/LoadingBackdrop';
import TitleBar from '../../Components/Shared/TitleBar';
import CreatedByFactWise from '../../Global/Components/CreatedByFactWise';
import { getTemplateWithOnTheFlyFields } from '../../Global/Helpers/templateHelpers';
import useGeneratePdf from '../../Global/Hooks/useGeneratePdf';
import { useRfqTemplate } from '../../Global/Hooks/useRfqTemplate';
import PoReviewBuyerAndVendorInfo from '../Components/PoReviewBuyerAndSellerInfo';
import POReviewFooter from '../Components/PoReviewFooter';
import PoReviewItemAccordions from '../Components/PoReviewItemAccordions';
import PurchaseOrderReviewButtonsContainer from '../Containers/PurchaseOrderReviewButtonsContainer';
import { usePurchaseOrder } from '../Hooks/PurchaseOrderDetails';
import PoReviewTopLevelLogoInfo from './POReviewTopLeveLogoInfo';
import AdditionalDetailsAtEventLevelViewMode from '../../Events/Components/Buyer/AdditionalDetailsAtEventLevelViewMode';
import PaymentTermsIncotermGRTolerenceForEventViewMode from '../../Events/Components/Buyer/PaymentTermsIncotermGRTolerenceForEventViewMode';
import { FWTypography } from '../../Common/FWTypograhy';
import { RfqCustomFieldsViewMode } from '../../Events/Components/Buyer/RFQCustomFieldViewMode';
import { transformPaymentTermsFromBackendToFrontend } from '../../Global/Helpers/paymentTermsHelpers';
import { TemplateStandardFieldsEnum } from '../../Global/Interfaces/TemplateInterface';
import { FWTooltip } from '../../Common/FWCustomTooltip';
import { FWPopup } from '../../Common/FWPopup';
import EditAttachmentsButtonAndPopover from '../../Global/Containers/EditAttachmentsButtonAndPopover';
import { IFileResourceType } from '../../Components/Shared/UploadFile';

export default function PurchaseOrderReviewPage({
    enterpriseType,
}: {
    enterpriseType: 'BUYER' | 'SELLER';
}) {
    const paramData: {
        id: string;
        poPreviewId: string;
    } = useParams();

    const { hookState, purchaseOrderData, templateId, entityId } =
        usePurchaseOrder(
            paramData.id ?? paramData.poPreviewId,
            enterpriseType,
            !!paramData.poPreviewId
        );

    const { hookState: templateHookState, templateDetails: rfqTemplate } =
        useRfqTemplate(
            templateId?.trim() ? templateId?.trim() : 'DEFAULT',
            entityId ?? ''
        );

    //

    const templateWithOnTheFlyFields = useMemo(() => {
        const newTemplate = getTemplateWithOnTheFlyFields({
            items: purchaseOrderData.purchase_order_items ?? [],
            rfqTemplate,
        });
        return newTemplate;
    }, [purchaseOrderData, rfqTemplate]);

    const [openPoRevisePopup, setOpenPoRevisePopup] = useState(false);

    const fileName = `PO_${purchaseOrderData.custom_purchase_order_id.trim()}_${
        purchaseOrderData.purchase_order_id === ''
            ? 'PO_Draft'
            : purchaseOrderData.submission_datetime !== null &&
              purchaseOrderData.submission_datetime.trim().length > 0
            ? getFWDate(purchaseOrderData.submission_datetime)
            : '-'
    }.pdf`;

    const pdfRef = useRef<HTMLDivElement>(null);
    const { pdfWindowOpened, setPdfWindowOpened } = useGeneratePdf(
        pdfRef,
        fileName
    );

    const history = useHistory();

    //
    const poLevelFieldsCount = () => {
        return rfqTemplate.sections.EVENT_DETAILS.fieldList.filter(
            (field) =>
                ![
                    'EVENT_NAME',
                    'EVENT_TYPE',
                    'EVENT_DEADLINE',
                    'DISCOUNT',
                    'CURRENCY',
                    'BUYER_ENTITY',
                    'TEMPLATE',
                    'DEFAULT_ITEM_TERMS',
                ].includes(field.field) &&
                field.field !== undefined &&
                rfqTemplate?.fieldSettings?.customFields[field.field]
                    ?.sellerSettings?.showField === true &&
                rfqTemplate?.fieldSettings?.standardFields[
                    TemplateStandardFieldsEnum[
                        field.field as keyof typeof TemplateStandardFieldsEnum
                    ]
                ]?.sellerSettings?.showField === true
        ).length;
    };

    return hookState.state === HookStateValue.LOADING ||
        templateHookState.state === HookStateValue.LOADING ? (
        <LoadingBackDrop />
    ) : hookState.state === HookStateValue.ERROR ||
      templateHookState.state === HookStateValue.ERROR ? (
        <>Error</>
    ) : (
        <>
            {/* uncomment the code below to view pdf in an iframe */}
            {/* <PDFViewer width="100%" height="100%">
                <PdfTemplate purchaseOrderData={purchaseOrderData} />
            </PDFViewer> */}
            {!pdfWindowOpened && (
                <TitleBar
                    top={'0px'}
                    title={'PO Review'}
                    hideBackButton={!!paramData.poPreviewId}
                >
                    {/* {enterpriseType === 'BUYER' && (
                        <FWTooltip title="Revise PO">
                            <div>
                                <IconButton
                                    color="primary"
                                    onClick={() => setOpenPoRevisePopup(true)}
                                >
                                    <i className="bi bi-pencil"></i>
                                </IconButton>
                            </div>
                        </FWTooltip>
                    )} */}
                    <Box
                        className="flex flex--jcsb flex--aic"
                        // margin={'0 1rem'}
                        height={'3rem'}
                    >
                        <Box className="flex flex--aic" width={'max-content'}>
                            {' '}
                            {/* <FWTypography color="text.secondary">
                            Attachments
                        </FWTypography> */}
                            <EditAttachmentsButtonAndPopover
                                attachments={
                                    purchaseOrderData.buyer_attachments ?? []
                                }
                                resourceType={IFileResourceType.RFQ}
                                onFileUpload={() => {}}
                                removeAttachment={async (idx: number) => {}}
                                allowFileUpload={false}
                                customIconTooltip={'Buyer attachments'}
                                showRemoveButton={false}
                                clipIconSize={20}
                            />
                            <EditAttachmentsButtonAndPopover
                                attachments={
                                    purchaseOrderData.seller_attachments ?? []
                                }
                                resourceType={IFileResourceType.RFQ}
                                onFileUpload={() => {}}
                                removeAttachment={async (idx: number) => {}}
                                allowFileUpload={false}
                                customIconTooltip={'Seller attachments'}
                                showRemoveButton={false}
                                clipIconSize={20}
                            />
                        </Box>
                    </Box>
                    &nbsp;
                    {!paramData.poPreviewId && (
                        <FWTooltip title="Download PDF">
                            <div>
                                <IconButton
                                    color="primary"
                                    onClick={() => setPdfWindowOpened(true)}
                                >
                                    <i className="bi bi-file-earmark-arrow-down"></i>
                                </IconButton>
                            </div>
                        </FWTooltip>
                    )}
                </TitleBar>
            )}
            {pdfWindowOpened && <div className={'hideBg'}></div>}
            <Box
                ref={pdfRef}
                sx={{
                    ...(pdfWindowOpened && {
                        scale: '0.85',
                        width: 1700,
                        position: 'relative',
                        top: '-10px',
                        bottom: '100px',
                        left: -255,
                        paddingX: 20,
                        paddingY: 2.25,
                        marginBottom: '150px',
                        size: 'A4 portrait',
                    }),
                }}
            >
                <PoReviewTopLevelLogoInfo
                    currentPurchaseOrder={purchaseOrderData}
                    pdfWindowOpened={pdfWindowOpened}
                />
                <PoReviewBuyerAndVendorInfo
                    currentPurchaseOrder={purchaseOrderData}
                    enterpriseType={enterpriseType}
                />

                {purchaseOrderData.purchase_order_type &&
                    Boolean(poLevelFieldsCount()) && (
                        <Box
                            style={{
                                margin: '20px',
                                border: '2px solid #BBBBBF',
                                borderRadius: '12px',
                                padding: '8px',
                                width: '97%',
                                paddingLeft: '30px',
                                paddingBottom: '10px',
                            }}
                        >
                            <FWTypography
                                variant="h4"
                                style={{ marginBottom: '20px' }}
                            >
                                {'PO overall details'}
                            </FWTypography>
                            <Grid container columnGap={'15px'} rowGap={'10px'}>
                                <RfqCustomFieldsViewMode
                                    gridSize={2}
                                    entityId={purchaseOrderData.seller_entity}
                                    templateId={rfqTemplate.templateId}
                                    accessedBy="VENDOR"
                                    custom_sections={
                                        purchaseOrderData.custom_sections
                                    }
                                />
                                <PaymentTermsIncotermGRTolerenceForEventViewMode
                                    gridSize={2}
                                    default_event_quantity_tolerance_percentage={
                                        purchaseOrderData.gr_tolerance ?? ''
                                    }
                                    incoterm_id={
                                        purchaseOrderData.incoterm_id ?? ''
                                    }
                                    lead_time={
                                        purchaseOrderData.lead_time ?? ''
                                    }
                                    lead_time_period={
                                        purchaseOrderData.lead_time_period ?? ''
                                    }
                                    paymentTerms={transformPaymentTermsFromBackendToFrontend(
                                        {
                                            prepayment_percentage:
                                                purchaseOrderData.prepayment_percentage,
                                            payment_terms:
                                                purchaseOrderData.payment_terms,
                                            deliverables_payment_terms:
                                                purchaseOrderData.deliverables_payment_terms as any,
                                        }
                                    )}
                                    accessedBy="VENDOR"
                                    templateDetails={rfqTemplate}
                                />
                                <AdditionalDetailsAtEventLevelViewMode
                                    accessedBy="VENDOR"
                                    gridSize={2}
                                    default_event_item_additional_information={
                                        purchaseOrderData.custom_additional_information ??
                                        ''
                                    }
                                    requisition_approved={
                                        purchaseOrderData.requisition_information
                                            ? purchaseOrderData
                                                  .requisition_information[0]
                                                  ?.requisition_approved ??
                                              false
                                            : false
                                    }
                                    requisition_no={
                                        purchaseOrderData.requisition_information
                                            ? purchaseOrderData
                                                  .requisition_information[0]
                                                  ?.requisition_number ?? ''
                                            : ''
                                    }
                                    templateDetails={rfqTemplate}
                                />
                            </Grid>
                        </Box>
                    )}
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: '32px',
                        padding: '20px 20px 0 20px',
                    }}
                >
                    {purchaseOrderData.purchase_order_items.map((item, idx) => (
                        <PoReviewItemAccordions
                            item={item}
                            purchase_order_status={purchaseOrderData.status}
                            key={idx}
                            idx={idx}
                            pdfWindowOpened={pdfWindowOpened}
                            rfqTemplate={templateWithOnTheFlyFields}
                        />
                    ))}
                </div>
                {/* <Grid
                    container
                    direction={'column'}
                    paddingTop={2.5}
                    gap={4}
                    paddingX={2.5}
                >
                </Grid> */}
                <div style={{ margin: '16px 0px' }}>
                    <POReviewFooter
                        items={purchaseOrderData.purchase_order_items}
                        discount_perc={
                            purchaseOrderData.pricing_information
                                .discount_percentage
                        }
                        notes={purchaseOrderData.notes}
                        tncText={purchaseOrderData.terms_and_conditions.data}
                        rfqTemplate={templateWithOnTheFlyFields}
                        event_type={
                            purchaseOrderData.purchase_order_type === 'PO_GROUP'
                                ? 'DIRECT'
                                : 'FROM_EVENT'
                        }
                    />
                </div>
                {/* <Grid item xs={12} marginY={2}>
                </Grid> */}
                {pdfWindowOpened && (
                    <CreatedByFactWise style={{ right: 200 }} />
                )}
                {paramData.poPreviewId
                    ? !pdfWindowOpened && (
                          <Box
                              className="flex flex--aic flex--jcc"
                              position={'relative'}
                              left={'50%'}
                              sx={{ transform: 'translateX(-50%)' }}
                              marginY={5}
                              gap={2}
                          >
                              <FWButton
                                  variant={'contained'}
                                  onClick={() =>
                                      history.push(
                                          `/seller/purchase_orders/${paramData.poPreviewId}/review/`
                                      )
                                  }
                              >
                                  Accept/Reject PO
                              </FWButton>
                              <FWButton
                                  variant={'contained'}
                                  onClick={() => setPdfWindowOpened(true)}
                              >
                                  Print PO
                              </FWButton>
                          </Box>
                      )
                    : !pdfWindowOpened && (
                          <PurchaseOrderReviewButtonsContainer
                              purchaseOrderData={purchaseOrderData}
                              enterpriseType={enterpriseType}
                          />
                      )}
            </Box>
            <FWPopup
                open={openPoRevisePopup}
                setOpen={setOpenPoRevisePopup}
                onClickConfirm={() =>
                    history.push(
                        `/buyer/purchase_orders/${paramData.id}/revise/`
                    )
                }
                title={'Revise PO'}
                msg={'The PO will be approved and issued to vendor'}
            />
        </>
    );
}
