import { del, get, post, put } from '../../Utils/api';
import { defaultAutoReminderData } from '../Component/AddTemplatePopUp';
import { INewTemplateField } from '../Containers/TemplateSections';
import {
    ICreateTemplatePayload,
    ICreateTemplateResponse,
    IEntityItemsResponse,
    IFactwiseItemsResponse,
    ISingleTemplateDetailsResponse,
    ITemplateListDetailsResponse,
    ITemplateListResponse,
} from '../Interfaces/TemplateAPIData';
import {
    TemplateData,
    TemplateDetails,
    TemplateFormDetails,
    TemplateItem,
    TemplateItemFormDetails,
    TemplateItemTypes,
    TemplateListObject,
    TemplateSectionFormDetails,
    TemplateStatus,
    TemplateSuggestedFieldObject,
    templateType,
} from '../Interfaces/TemplatePageInterfaces';
/*
    Current APIS:

    1.Local Storage:
    Setting up dummy Database on chrome to work with
        a.Setting Data:
            setDatainLocalStorage - set initial load data
        
        b.Retriving Data:
            getDataFromLocalStorageList -  get all template list
            getDataFromLocalStorageDetails - get all template details
            getDataSuggestedFromLocalStorage - get all suggested fields

    2.Templates:
        1. getAllTemplateData - get all templates to show on navbar, it supports searching too
        2. getTemplateDataById - get template details by id
        3. changeTemplateStatus - change template status to draft, published or disabled
        4. deleteTemplate - delete template (only for draft)
        5. cloneTemplate - clone template (only need to change template name)
        6. addTemplate - add new to create a new Template 
        7. updateTemplate - update template details only

    3.Sections:
        1. addTemplateSection - add new section to a template
        2. updateTemplateSection - update section details
        3. deleteTemplateSection - delete section
        4. changeTemplateSectionPosition - change section position on view

    4.Items:
        1. addTemplateItem- add new item to a section
        2. updateTemplateItem - update item details
        3. deleteTemplateItem - delete item
        4. changeTemplateItemPosition - change item position on view
        5. getAllSuggestedFields - get all suggested fields to show on add item dialog, it supports searching too


*/

// Templates APIS  Start -------------------------------------------------------------------------------------------------------
export const getAllTemplateData = async (
    searchText: string,
    enterprise_id?: string
): Promise<TemplateListObject[]> => {
    const newData = await get<any[]>(
        `/module_templates/?search_term=${searchText}`
    );

    const templateList: TemplateListObject[] = newData.data
        .filter((template) =>
            [
                'RFQ',
                'PO GROUP',
                'CLM',
                'ITEM',
                'BUYER',
                'ITEM CART',
                'QUOTE CALCULATOR',
                'PROJECT',
                'VENDOR',
                'SRM',
                'BOM',
                'VISIT',
                ...(enterprise_id !== '9c8e0e18-0c17-4324-b7a2-1b0c8cbd2241'
                    ? ['REQUISITION']
                    : []),
            ].includes(template.type.replace('_', ' '))
        )
        .map((module: ITemplateListResponse) => {
            return {
                template_type: module.type.replaceAll('_', ' ') as templateType,
                template_count: module.count,
                template_rows: module.templates.map(
                    (template: ITemplateListDetailsResponse) => {
                        return {
                            template_id: template.template_id,
                            template_name: template.name,
                            auto_reminders: {
                                initial_reminder_hours:
                                    template?.auto_reminders
                                        ?.initial_reminder_hours ??
                                    defaultAutoReminderData?.default_intital_reminder_hours,
                                max_reminders:
                                    template?.auto_reminders?.max_reminders ??
                                    defaultAutoReminderData?.default_max_reminders,
                                reminder_interval:
                                    template?.auto_reminders
                                        ?.reminder_interval ??
                                    defaultAutoReminderData?.default_reminder_interval,
                                is_remainder_required:
                                    template?.auto_reminders
                                        ?.is_remainder_required ??
                                    defaultAutoReminderData?.default_reminder_required,
                            },
                            template_type: template.type.replaceAll(
                                '_',
                                ' '
                            ) as templateType,
                            template_status: template.status as TemplateStatus,
                            is_default: template.is_default,
                            is_global: template.is_global,
                            description: template.description,
                            items: template.items,
                            tags: template.tags,
                            template_entity_id: template.entity_id,
                            template_entity_name: template.entity_name,
                            template_description: template.description,
                            created_by: template.creator_name,
                            created_at: template.created_on,
                            last_modified: template.last_edited_on,
                            last_modified_by: template.last_edited_by_name,
                            deleted_datetime: template.deleted_datetime,
                        };
                    }
                ),
            };
        });

    return templateList;
};

export const getModuleTemplateData = async (
    entityId: string,
    template_type: 'RFQ' | 'PO GROUP' | 'CLM'
): Promise<TemplateData[]> => {
    const newData = await get<
        {
            count: number;
            templates: ITemplateListDetailsResponse[];
        }[]
    >(
        `/module_templates/?entity_id=${entityId}&template_type=${template_type}?search_term='Demo'`
    );
    const templateList: TemplateData[] = newData.data[0].templates
        .filter(
            (template: ITemplateListDetailsResponse) =>
                template.status === 'ONGOING'
        )
        .map((template: ITemplateListDetailsResponse) => {
            return {
                template_id: template.template_id,
                template_name: template.name,
                template_type: template.type.replaceAll(
                    '_',
                    ' '
                ) as templateType,
                template_status: template.status as TemplateStatus,
                is_default: template.is_default,
                is_global: template.is_global,
                description: template.description,
                tags: template.tags,
                auto_reminders: {
                    initial_reminder_hours:
                        template?.auto_reminders?.initial_reminder_hours ??
                        defaultAutoReminderData?.default_intital_reminder_hours,
                    max_reminders:
                        template?.auto_reminders?.max_reminders ??
                        defaultAutoReminderData?.default_max_reminders,
                    reminder_interval:
                        template?.auto_reminders?.reminder_interval ??
                        defaultAutoReminderData?.default_reminder_interval,
                    is_remainder_required:
                        template?.auto_reminders?.is_remainder_required ??
                        defaultAutoReminderData?.default_reminder_required,
                },
                items: template.items,
                template_entity_id: template.entity_id,
                template_entity_name: template.entity_name,
                template_description: template.description,
                created_by: template.creator_name,
                created_at: template.created_on,
                last_modified: template.last_edited_on,
                last_modified_by: template.last_edited_by_name,
                deleted_datetime: template.deleted_datetime,
            };
        });
    return templateList;
};

export const checkDuplicateTemplateName = async (
    templateName: string,
    templateType: templateType,
    templateEntity: string
): Promise<boolean> => {
    const response = await get<{
        template_exists: boolean;
    }>(
        `/module_templates/${templateEntity}/template/exists/${
            templateName ? `?name=${templateName}` : ''
        }${templateType ? `&type=${templateType.replace(/ /g, '_')}` : ''}`
    );

    return response.data.template_exists;
};

export const getTemplateDataById = async (
    template_id: string,
    entity_id: string
) => {
    const response = await get<ISingleTemplateDetailsResponse>(
        `/module_templates/${entity_id}/${template_id}/`
    );

    // const isBomTemplate = response.data.type === 'BOM';

    const templateSections = response.data.section_list.map((section) => {
        return {
            ...section,
            section_items: section.section_items
                // .filter((item: TemplateItem) => {
                //     if (
                //         isBomTemplate &&
                //         (item.name
                //             .toLowerCase()
                //             .includes('Additional costs'.toLowerCase()) ||
                //             item.name.toLowerCase().includes('taxes') ||
                //             item.name.toLowerCase().includes('discount'))
                //     ) {
                //         return true;
                //     }
                //     return true;
                // })
                .map((item: TemplateItem) => {
                    return {
                        ...item,
                        alternate_name: item.alternate_name.replace('_', ''),
                        name: item.name.replace('_', ''),
                    };
                }),
        };
    });

    const template: TemplateDetails = {
        template_id: response.data.template_id,
        assigned_users: response.data.assigned_users as any, // ask udit and check the this
        template_name: response.data.name,
        linked_templates: response.data.linked_templates,
        template_type: response.data.type.replaceAll('_', ' ') as templateType,
        template_status: response.data.status as TemplateStatus,
        is_default: response.data.is_default,
        is_global: response.data.is_global,
        description: response.data.description,
        tags: response.data.tags,
        template_entity_id: response.data.entity.entity_id,
        template_entity_name: response.data.entity.entity_name,
        template_description: response.data.description,
        created_by: response.data.creator,
        created_at: response.data.created_on,
        last_modified: response.data.last_edited_on,
        last_modified_by: response.data.last_edited_by_name,
        deleted_datetime: response.data.deleted_datetime,
        auto_reminders: {
            initial_reminder_hours:
                response.data.auto_reminders?.initial_reminder_hours ??
                defaultAutoReminderData?.default_intital_reminder_hours,
            max_reminders:
                response.data.auto_reminders?.max_reminders ??
                defaultAutoReminderData?.default_max_reminders,
            reminder_interval:
                response.data.auto_reminders?.reminder_interval ??
                defaultAutoReminderData?.default_reminder_interval,
            is_remainder_required:
                response.data.auto_reminders?.is_remainder_required ??
                defaultAutoReminderData?.default_reminder_required,
        },
        items: response.data.items,
        template_sections: templateSections,
    };

    return template;
};

export const changeTemplateStatus = async (
    entity_id: string,
    template_id: string,
    status: TemplateStatus
): Promise<void> => {
    await put<any, any>(
        `/module_templates/${entity_id}/${template_id}/status/update/`,
        {
            status: status,
        }
    );
};

export const createNewTemplateField = async (
    entity_id: string,
    template_id: string,
    fieldDetails: INewTemplateField
): Promise<void> => {
    await post<INewTemplateField, any>(
        `/module_templates/${entity_id}/${template_id}/sections/create/`,
        fieldDetails
    );
};

export const deleteTemplate = async (
    template_id: string,
    entity_id: string
): Promise<void> => {
    await del<ITemplateListDetailsResponse>(
        `/module_templates/${entity_id}/${template_id}/delete/`
    );
};

export const cloneTemplate = async (
    entity_id: string,
    template_id: string,
    templateName: string
): Promise<string | null> => {
    const response = await post<any, any>(
        `/module_templates/${entity_id}/${template_id}/clone/`,
        {
            template_name: templateName,
        }
    );
    return response.data?.template_id;
};

export const createTemplate = async (
    template: TemplateFormDetails,
    entity_id: string
): Promise<TemplateDetails> => {
    const response = await post<
        ICreateTemplatePayload,
        ICreateTemplateResponse
    >(`/module_templates/create/`, {
        ...template,
        template_type: template.template_type.replaceAll(' ', '_'),
    });

    const newTemplate: TemplateDetails = {
        template_id: response.data.template_id,
        template_name: response.data.name,
        template_type: response.data.type.replace(' ', '_') as templateType,
        template_status: response.data.status as TemplateStatus,
        is_default: false,
        is_global: response.data.is_global,
        description: response.data.description,
        tags: response.data.tags,
        template_entity_id: response.data.entity.entity_id,
        template_entity_name: response.data.entity.entity_name,
        template_description: response.data.description,
        created_by: response.data.created_by_user,
        created_at: response.data.created_on,
        last_modified: response.data.last_edited_on,
        last_modified_by: response.data.last_edited_on,
        deleted_datetime: response.data.deleted_datetime,
        template_sections: response.data.section_list,
        items: response.data.items,
        auto_reminders: {
            initial_reminder_hours:
                response.data.auto_reminders.initial_reminder_hours ??
                defaultAutoReminderData?.default_intital_reminder_hours,
            max_reminders:
                response.data.auto_reminders.max_reminders ??
                defaultAutoReminderData?.default_max_reminders,
            reminder_interval:
                response.data.auto_reminders.reminder_interval ??
                defaultAutoReminderData?.default_reminder_interval,
            is_remainder_required:
                response.data.auto_reminders.is_remainder_required ??
                defaultAutoReminderData?.default_reminder_required,
        },
    };
    return newTemplate;
};

export const updateTemplate = async (
    updatedTemplateData: TemplateFormDetails & { items: string[] }
) => {
    const result = await put<TemplateFormDetails, any>(
        `/module_templates/${updatedTemplateData.template_id}/update/`,
        updatedTemplateData
    );
    return result.data.template_id;
};

export const getTemplateItems = async (
    template_id: string,
    type: string,
    item_type: string
) => {
    try {
        const result = await get<any>(
            `/module_templates/entity_items/?template_id=${template_id}&type=${type}&item_type=${item_type}`
        );

        return result.data;
    } catch (e) {
        throw e;
    }
};

//Set Template as Default
export const setTemplateAsDefault = async (
    entity_id: string,
    template_id: string,
    module_type: templateType
) => {
    try {
        const res = await put(
            `/module_templates/${entity_id}/${template_id}/default/update/`,
            { module_type: module_type.replaceAll(' ', '_') }
        );
        return res.data;
    } catch (err) {
        throw err;
    }
};

// TODO:Change Global to proper API
// export const setTemplateGlobal = async (template_id: string) => {
//     const data = await getDataFromLocalStorageDetails();
//     const templateIndex = data.findIndex(
//         (template: any) => template.template_id === template_id
//     );
//     if (templateIndex !== -1) {
//         data[templateIndex].is_global = true;
//         localStorage.setItem('templateDetailsList', JSON.stringify(data));
//     }
// };

// Template APIS  END -------------------------------------------------------------------------------------------------------

// Section APIS  Start -------------------------------------------------------------------------------------------------------
export const addTemplateSection = async (
    template_entity: string,
    template_id: string,
    section_data: TemplateSectionFormDetails
) => {
    const response = await post<TemplateSectionFormDetails, any>(
        `/module_templates/${template_entity}/${template_id}/sections/create/`,
        { ...section_data, is_optional: true }
    );

    return response.data?.section_id;
};

export const updateTemplateSection = async (
    template_entity: string,
    template_id: string,
    section_data: TemplateSectionFormDetails
) => {
    await put<TemplateSectionFormDetails, any>(
        `/module_templates/${template_entity}/${template_id}/sections/${section_data.section_id}/update/`,
        section_data
    );
};

export const updateTemplateSectionPermision = async (
    template_entity: string,
    template_id: string,
    section_id: string,
    payload: {
        user_id: string;
        permissions: string[];
    }[]
) => {
    await put<TemplateSectionFormDetails, any>(
        `/module_templates/${template_entity}/${template_id}/sections/${section_id}/permissions/update/`,
        payload
    );
};

export const updateTemplatePermissionAPI = async (
    template_entity: string,
    template_id: string,
    payload: {
        user_id: string;
        permissions: string[];
    }[]
) => {
    await put<TemplateSectionFormDetails, any>(
        `/module_templates/${template_entity}/${template_id}/permissions/update/`,
        payload
    );
};

export const deleteTemplateSection = async (
    template_entity: string,
    template_id: string,
    section_id: string
) => {
    await del(
        `/module_templates/${template_entity}/${template_id}/sections/${section_id}/delete/`
    );
};

export const changeTemplateSectionPosition = async (
    template_entity: string,
    template_id: string,
    section_id: string,
    position: number
) => {
    await put(
        `/module_templates/${template_entity}/${template_id}/sections/${section_id}/sequence/update/`,
        {
            sequence: position,
        }
    );
};

// Section APIS  End -------------------------------------------------------------------------------------------------------

// Items APIS  Start -------------------------------------------------------------------------------------------------------
export const addTemplateItem = async (
    template_entity: string,
    template_id: string,
    section_id: string,
    itemData: TemplateItemFormDetails
): Promise<TemplateItem> => {
    if (itemData.name === '') {
        itemData.name = itemData.alternate_name;
    }

    const response = await post<TemplateItemFormDetails, any>(
        `/module_templates/${template_entity}/${template_id}/sections/${section_id}/items/create/`,
        { ...itemData }
    );
    return response.data;
};

export const updateTemplateItem = async (
    template_entity: string,
    template_id: string,
    section_id: string,
    item_id: string,
    itemData: TemplateItemFormDetails
) => {
    if (itemData.is_builtin_field === false) {
        itemData.name = itemData.alternate_name;
    }
    await put<TemplateItemFormDetails, any>(
        `/module_templates/${template_entity}/${template_id}/sections/${section_id}/items/${item_id}/update/`,
        itemData
    );
    // TODO: add field_level
};

export const updateMultipleTemplateItems = async (
    template_entity: string,
    template_id: string,
    section_id: string,
    template_items: TemplateItem[]
) => {
    try {
        await put<any, any>(
            `/module_templates/${template_entity}/${template_id}/sections/${section_id}/items/update/`,
            template_items
        );
        // TODO: add field_level
    } catch (err) {
        throw err;
    }
};

export const updateAdditionalCostForTemplate = async (
    template_entity: string,
    template_id: string,
    section_id: string,
    parent_section_item_id: string,
    payload: any
) => {
    try {
        let res = await put<any, any>(
            `/module_templates/${template_entity}/${template_id}/sections/${section_id}/items/${parent_section_item_id}/items/update/`,
            payload
            // TODO: add field_level
        );
        return res.data;
    } catch (err) {
        throw err;
    }
};

export const deleteTemplateItem = async (
    template_entity: string,
    template_id: string,
    section_id: string,
    item_id: string
) => {
    await del(
        `/module_templates/${template_entity}/${template_id}/sections/${section_id}/items/${item_id}/delete/`
    );
};

export const changeTemplateItemPosition = async (
    template_entity: string,
    template_id: string,
    section_id: string,
    item_id: string,
    position: number
) => {
    await put(
        `/module_templates/${template_entity}/${template_id}/sections/${section_id}/items/${item_id}/sequence/update/`,
        {
            sequence: position,
        }
    );
};

export const CheckDuplicateItemName = async (
    entity_id: string,
    template_id: string,
    item_name: string,
    constraints:
        | {
              max_limit?: number;
              min_limit?: number;
              min?: number;
              max?: number;
              choices?: string[];
              decimal_places?: number;
              field_type: TemplateItemTypes;
          }
        | {}
): Promise<{
    item_name: boolean;
    item_type: boolean;
}> => {
    const response = await post<any, any>(
        `/module_templates/${entity_id}/template/${template_id}/item/exists/`,
        {
            name: item_name,
            constraints: constraints,
        }
    );
    const responseData = response.data.item_exists;
    const duplicates = {
        item_name: responseData.item_name_exists,
        item_type: responseData.item_constraint_exists,
    };

    return duplicates;
};

export const getAllEntityFields = async (
    searchText: string,
    template_id: string,
    item_type?: 'ADDITIIONAL_COST' | 'TAX' | 'DISCOUNT' | 'OTHER',
    type?: templateType
): Promise<TemplateSuggestedFieldObject> => {
    let fortype: string | undefined = type?.replaceAll(' ', '_');
    const response = await get<IEntityItemsResponse[]>(
        `/module_templates/entity_items/?template_id=${template_id}${
            type ? `&type=${fortype}` : ''
        }${item_type ? `&item_type=${item_type}` : ''}${
            searchText ? `&search_term=${searchText}` : ''
        }`
    );
    const entityListData = response.data;
    const entityList: TemplateSuggestedFieldObject = {
        fields_module: 'ENTITY',
        fields_count: entityListData.length,
        fields_rows: entityListData.map((entity_item) => ({
            section_item_id: '',
            name: entity_item.name,
            alternate_name: entity_item.name,
            field_level:
                type === 'ITEM' || type === 'VENDOR' ? 'OTHER' : 'ITEM',
            sequence: 0,
            description: entity_item.description,
            constraints: entity_item.constraints,
            can_remove: true,
            is_required: entity_item.is_required,
            is_mandatory: entity_item.is_mandatory,
            is_builtin_field: false,
            can_split: false,
            send_to_po: true,
            additional_information: {},
            parent_section_item: entity_item.parent_section_item,
        })),
    };
    return entityList;
};

export const getAllFactwiseFields = async (
    searchText: string,
    constraint:
        | {
              max_limit?: number;
              min_limit?: number;
              min?: number;
              max?: number;
              choices?: string[];
              decimal_places?: number;
              field_type: TemplateItemTypes;
          }
        | {}
): Promise<TemplateSuggestedFieldObject> => {
    const response = await post<any, IFactwiseItemsResponse[]>(
        `/module_templates/builtin-fields/?template_type=${'RFQ'}${
            searchText ? `&search_term=${searchText}` : ''
        }`,
        constraint
    );

    const factWiseListData = response.data;
    const factWiseList: TemplateSuggestedFieldObject = {
        fields_module: 'FACTWISE',
        fields_count: factWiseListData.length,
        fields_rows: factWiseListData.map((factwise_entity) => ({
            section_item_id: '',
            name: factwise_entity.name,
            alternate_name: factwise_entity.name,
            sequence: 0,
            description: factwise_entity.description,
            field_level: 'OTHER',
            constraints: factwise_entity.constraints,
            can_remove: true,
            is_required: factwise_entity.is_required,
            is_mandatory: factwise_entity.is_mandatory,
            is_builtin_field: false,
            can_split: false,
            send_to_po: true,
            additional_information: {},
            parent_section_item: factwise_entity.parent_section_item,
        })),
    };
    return factWiseList;
};

export const generateDefaultTemplates = async (entity_id: string) => {
    await post<any, any>(
        `/module_templates/${entity_id}/create_default_template/`,
        {}
    );
};
// Items APIS  End -------------------------------------------------------------------------------------------------------
export interface AddPaymentOptionPayload {
    name: string;
    alternate_name: string;
    description: string;
    item_name: string;
    conditions: {
        name: string;
        value: string;
    };
}

export const addNewPaymentOption = async (
    template_entity: string,
    template_id: string,
    section_id: string,
    section_item_id: string,
    section_option: AddPaymentOptionPayload
) => {
    try {
        await post<any, any>(
            `/module_templates/${template_entity}/${template_id}/sections/${section_id}/items/${section_item_id}/options/create/`,
            section_option
        );
    } catch (err) {
        throw err;
    }
};

export const updatePaymentOption = async (
    template_entity: string,
    template_id: string,
    section_id: string,
    section_item_id: string,
    section_option_id: string,
    section_option: AddPaymentOptionPayload
) => {
    try {
        await put(
            `/module_templates/${template_entity}/${template_id}/sections/${section_id}/items/${section_item_id}/options/${section_option_id}/update/`,
            section_option
        );
    } catch (err) {
        throw err;
    }
};

export const deletePaymentOption = async (
    template_entity: string,
    template_id: string,
    section_id: string,
    section_item_id: string,
    section_option_id: string
) => {
    try {
        await del(
            `/module_templates/${template_entity}/${template_id}/sections/${section_id}/items/${section_item_id}/options/${section_option_id}/delete/`
        );
    } catch (err) {
        throw err;
    }
};

export const getAllSuggestedPaymentOptions = async (
    section_item_id: string,
    searchText: string
) => {
    const response = await get<any>(
        `/module_templates/entity_options/?section_item_id=${section_item_id}&search_term=${searchText}`
    );
    return response.data;
};

export const checkPaymentOptionExists = async (
    template_entity: string,
    template_id: string,
    section_item_id: string,
    option: string
) => {
    const response = await post<any, any>(
        `/module_templates/${template_entity}/template/${template_id}/items/${section_item_id}/option/exists/`,
        {
            name: option,
        }
    );
    return response.data.option_exists;
};
