import { cloneDeep } from 'lodash';
import {
    calculateAddtionalCost,
    convertAdditionalCostBackendToFrontend,
} from '../../../../../AdditionalCost/helpers';
import { IEventItemDetails } from '../../../../../Events/Interfaces/Buyer/RFQ.model';
import {
    IPaymentTerms,
    IPaymentTermsInvoiceItemValues,
    IPaymentTermsPoItemPercentages,
} from '../../../../../Global/Interfaces/PaymentTermsInterface';
import { PeriodEnum } from '../../../../../Global/Interfaces/PeriodInterface';
import { ICustomSection } from '../../../../../Global/Interfaces/TemplateInterface';
import { IGetBuyerRFQBidItemDetails } from '../../../../../Models/RFQ.model';
import {
    calculateItemTotal,
    calculateNewItemTotalWithDecimalPlaces,
} from '../../../../Shared/totalCalculator';
import { deliverySchedulesItemsAreSame } from '../DraftBidColumn/bidComparisonFunctions';

// const CompareNoOfDays = (
//     day_count_1: number,
//     day_1_period: string,
//     day_count_2: number,
//     day_2_period: string,
//     operator: '<=' | '<'
// ) => {
//     if (!isNaN(day_count_1) && !isNaN(day_count_2)) {
//         let day_1_base = moment().add(
//             day_count_1,
//             day_1_period === 'WEEKS'
//                 ? 'w'
//                 : day_1_period === 'MONTHS'
//                 ? 'M'
//                 : 'd'
//         );
//         let day_2_base = moment().add(
//             day_count_2,
//             day_2_period === 'WEEKS'
//                 ? 'w'
//                 : day_2_period === 'MONTHS'
//                 ? 'M'
//                 : 'd'
//         );
//         if (operator === '<=') return day_1_base <= day_2_base;
//         else if (operator === '<') return day_1_base < day_2_base;
//         else return true;
//     } else return true;
// };

export const compareValues = (
    rfqItem: IEventItemDetails,
    latest_bid: IGetBuyerRFQBidItemDetails | null
) => {
    let comparison: { [key: string]: 'SAFE' | 'WARNING' } = {};
    if (latest_bid !== null) {
        if (
            typeof rfqItem !== 'undefined' &&
            (latest_bid.bid_item_response_type === 'ACCEPTED' ||
                latest_bid.bid_item_response_type === 'RENEGOTIATING' ||
                latest_bid.bid_item_response_type === 'ADDED')
        ) {
            if (
                parseFloat(rfqItem.quantity_information.quantity) !==
                parseFloat(latest_bid.quantity_information.quantity)
            )
                comparison['quantity'] = 'WARNING';
            else comparison['quantity'] = 'SAFE';

            if (
                rfqItem.pricing_information.desired_price !== null &&
                latest_bid.pricing_information.currency_code_abbreviation ===
                    rfqItem.pricing_information.currency_code_abbreviation &&
                parseFloat(latest_bid.pricing_information.price) >
                    parseFloat(rfqItem.pricing_information.desired_price)
            )
                comparison['price'] = 'WARNING';
            else comparison['price'] = 'SAFE';

            const rfqItemTotal = calculateItemTotal(
                rfqItem.quantity_information.quantity,
                rfqItem.pricing_information.desired_price,
                rfqItem.pricing_information.shipping_per_unit ?? 0,
                rfqItem.pricing_information.additional_charges ?? []
            );
            const bidItemTotal = calculateItemTotal(
                latest_bid.quantity_information.quantity,
                latest_bid.pricing_information.price,
                latest_bid.pricing_information.shipping_per_unit,
                latest_bid.pricing_information.additional_charges
            );

            comparison['total'] =
                rfqItemTotal < bidItemTotal ? 'WARNING' : 'SAFE';

            const rfqTaxesTotal = calculateAddtionalCost({
                additionalCost: convertAdditionalCostBackendToFrontend(
                    rfqItem.pricing_information.taxes ?? []
                ),
                baseQuantity: rfqItem.quantity_information.quantity,
                baseRate: rfqItem.pricing_information.desired_price,
                type: 'TAX',
                discountAdditionalCost: convertAdditionalCostBackendToFrontend(
                    rfqItem.pricing_information.discounts ?? []
                ),
            }).effectiveRateDeviation;
            const bidTaxesTotal = calculateAddtionalCost({
                additionalCost: convertAdditionalCostBackendToFrontend(
                    latest_bid.pricing_information.taxes ?? []
                ),
                baseQuantity: latest_bid.quantity_information.quantity,
                baseRate: latest_bid.pricing_information.price,
                type: 'TAX',
                discountAdditionalCost: convertAdditionalCostBackendToFrontend(
                    latest_bid.pricing_information.discounts ?? []
                ),
            }).effectiveRateDeviation;
            comparison['taxes'] =
                rfqTaxesTotal < bidTaxesTotal ? 'WARNING' : 'SAFE';

            const rfqDiscountsTotal = calculateAddtionalCost({
                additionalCost: convertAdditionalCostBackendToFrontend(
                    rfqItem.pricing_information.discounts ?? []
                ),
                baseQuantity: rfqItem.quantity_information.quantity,
                baseRate: rfqItem.pricing_information.desired_price,
                type: 'DISCOUNT',
            }).effectiveRateDeviation;
            const bidDiscountsTotal = calculateAddtionalCost({
                additionalCost: convertAdditionalCostBackendToFrontend(
                    latest_bid.pricing_information.discounts ?? []
                ),
                baseQuantity: latest_bid.quantity_information.quantity,
                baseRate: latest_bid.pricing_information.price,
                type: 'DISCOUNT',
            }).effectiveRateDeviation;
            comparison['discount'] =
                rfqDiscountsTotal > bidDiscountsTotal ? 'WARNING' : 'SAFE';

            const rfqAdditionalCostsTotal = calculateAddtionalCost({
                additionalCost: convertAdditionalCostBackendToFrontend(
                    rfqItem.pricing_information.additional_costs ?? []
                ),
                baseQuantity: rfqItem.quantity_information.quantity,
                baseRate: rfqItem.pricing_information.desired_price,
                type: 'ADDITIONAL_COST',
            }).effectiveRateDeviation;
            const bidAdditionalCostsTotal = calculateAddtionalCost({
                additionalCost: convertAdditionalCostBackendToFrontend(
                    latest_bid.pricing_information.additional_costs ?? []
                ),
                baseQuantity: latest_bid.quantity_information.quantity,
                baseRate: latest_bid.pricing_information.price,
                type: 'ADDITIONAL_COST',
            }).effectiveRateDeviation;

            comparison['additionalCost'] =
                rfqAdditionalCostsTotal < bidAdditionalCostsTotal
                    ? 'WARNING'
                    : 'SAFE';

            comparison['payment_terms'] = paymentTermsComparison(
                rfqItem.paymentTerms,
                latest_bid.paymentTerms
            );
            if (
                latest_bid.paymentTerms &&
                parseFloat(latest_bid.paymentTerms?.prepaymentPercentage) >
                    parseFloat(
                        rfqItem.paymentTerms?.prepaymentPercentage ?? '0'
                    )
            )
                comparison['prepayment'] = 'WARNING';
            else comparison['prepayment'] = 'SAFE';

            if (rfqItem.incoterm !== latest_bid.incoterm)
                comparison['incoterm'] = 'WARNING';
            else comparison['incoterm'] = 'SAFE';
            // lead time calculation
            // if (
            //     CompareNoOfDays(
            //         parseFloat(rfqItem.procurement_information.lead_time),
            //         rfqItem.procurement_information.lead_time_period,
            //         parseFloat(latest_bid.procurement_information.lead_time),
            //         latest_bid.procurement_information.lead_time_period,
            //         '<'
            //     )
            // )
            comparison['leadTime'] = leadTimeComparison(
                latest_bid.procurement_information.lead_time,
                latest_bid.procurement_information.lead_time_period,
                rfqItem.procurement_information.lead_time,
                rfqItem.procurement_information.lead_time_period
            );
            // delivery schedule calculation
            // latest_bid.delivery_information.sort((delivery1, delivery2) => {
            //     if (delivery1.delivery_date === null) return 1;
            //     if (delivery2.delivery_date === null) return -1;
            //     else {
            //         let date1 = new Date(delivery1.delivery_date).getTime();
            //         let date2 = new Date(delivery2.delivery_date).getTime();
            //         return date1 - date2;
            //     }
            // });
            let latest_bid_delivery_information_copy = [
                ...latest_bid.delivery_information,
            ];
            latest_bid_delivery_information_copy.sort(
                (delivery1, delivery2) => {
                    if (delivery1.delivery_date === null) return 1;
                    if (delivery2.delivery_date === null) return -1;
                    else {
                        let date1 = new Date(delivery1.delivery_date).getTime();
                        let date2 = new Date(delivery2.delivery_date).getTime();
                        return date1 - date2;
                    }
                }
            );
            if (
                deliverySchedulesItemsAreSame(
                    rfqItem.delivery_information,
                    latest_bid_delivery_information_copy
                )
            )
                comparison['delivery'] = 'SAFE';
            else comparison['delivery'] = 'WARNING';

            comparison['custom_fields'] = 'SAFE';
            // if (
            //     !(
            //         rfqItem.custom_fields_negotiate &&
            //         rfqItem.custom_fields_negotiate.section_list &&
            //         latest_bid.custom_fields_negotiate &&
            //         latest_bid.custom_fields_negotiate.section_list &&
            //         latest_bid.custom_fields_negotiate.section_list.length ===
            //             rfqItem.custom_fields_negotiate.section_list.length
            //     )
            // ) {
            //     comparison['custom_fields'] = 'WARNING';
            // } else {
            //     for (
            //         let sectionIdx = 0;
            //         sectionIdx <
            //         rfqItem.custom_fields_negotiate.section_list.length;
            //         sectionIdx += 1
            //     ) {
            //         if (
            //             !(
            //                 latest_bid.custom_fields_negotiate.section_list[
            //                     sectionIdx
            //                 ].name ===
            //                     rfqItem.custom_fields_negotiate.section_list[
            //                         sectionIdx
            //                     ].name &&
            //                 latest_bid.custom_fields_negotiate.section_list[
            //                     sectionIdx
            //                 ].fields.length ===
            //                     rfqItem.custom_fields_negotiate.section_list[
            //                         sectionIdx
            //                     ].fields.length
            //             )
            //         ) {
            //             comparison['custom_fields'] = 'WARNING';
            //         } else {
            //             for (
            //                 let fieldIdx = 0;
            //                 fieldIdx <
            //                 latest_bid.custom_fields_negotiate.section_list[
            //                     sectionIdx
            //                 ].fields.length;
            //                 fieldIdx += 1
            //             ) {
            //                 comparison[
            //                     latest_bid.custom_fields_negotiate.section_list[
            //                         sectionIdx
            //                     ].fields[fieldIdx].name
            //                 ] = 'SAFE';
            //                 if (
            //                     !(
            //                         latest_bid.custom_fields_negotiate
            //                             .section_list[sectionIdx].fields[
            //                             fieldIdx
            //                         ].name ===
            //                             rfqItem.custom_fields_negotiate
            //                                 .section_list[sectionIdx].fields[
            //                                 fieldIdx
            //                             ].name &&
            //                         (!rfqItem.custom_fields_negotiate
            //                             .section_list[sectionIdx].fields[
            //                             fieldIdx
            //                         ].value ||
            //                             latest_bid.custom_fields_negotiate
            //                                 .section_list[sectionIdx].fields[
            //                                 fieldIdx
            //                             ].value ===
            //                                 rfqItem.custom_fields_negotiate
            //                                     .section_list[sectionIdx]
            //                                     .fields[fieldIdx].value)
            //                     )
            //                 ) {
            //                     comparison[
            //                         latest_bid.custom_fields_negotiate.section_list[
            //                             sectionIdx
            //                         ].fields[fieldIdx].name
            //                     ] = 'WARNING';
            //                 }
            //             }
            //         }
            //     }
            // }
        }
    }
    return comparison;
};

export const compareValueChanges = (
    rfqItem: IEventItemDetails,
    latest_bid: IGetBuyerRFQBidItemDetails | null
) => {
    let comparison: { [key: string]: 'SAFE' | 'WARNING' } = {};
    if (latest_bid !== null) {
        if (
            typeof rfqItem !== 'undefined' &&
            (latest_bid.bid_item_response_type === 'ACCEPTED' ||
                latest_bid.bid_item_response_type === 'RENEGOTIATING' ||
                latest_bid.bid_item_response_type === 'ADDED')
        ) {
            if (
                parseFloat(rfqItem.quantity_information.quantity) !==
                parseFloat(latest_bid.quantity_information.quantity)
            )
                comparison['quantity'] = 'WARNING';
            else comparison['quantity'] = 'SAFE';

            if (
                // rfqItem.pricing_information.desired_price !== null &&
                // latest_bid.pricing_information.currency_code_abbreviation ===
                //     rfqItem.pricing_information.currency_code_abbreviation &&
                parseFloat(latest_bid.pricing_information.price) !==
                parseFloat(rfqItem.pricing_information.desired_price)
            )
                comparison['price'] = 'WARNING';
            else comparison['price'] = 'SAFE';

            const rfqItemTotal = calculateNewItemTotalWithDecimalPlaces({
                additional_cost: convertAdditionalCostBackendToFrontend(
                    rfqItem.pricing_information.additional_costs
                ),
                decimalPlaces: 2,
                discount: convertAdditionalCostBackendToFrontend(
                    rfqItem.pricing_information.discounts
                ),
                item_price: rfqItem.pricing_information.desired_price,
                itemDS: rfqItem.delivery_information,
                tax: convertAdditionalCostBackendToFrontend(
                    rfqItem.pricing_information.taxes
                ),
            });

            const bidItemTotal = calculateNewItemTotalWithDecimalPlaces({
                additional_cost: convertAdditionalCostBackendToFrontend(
                    latest_bid.pricing_information.additional_costs
                ),
                decimalPlaces: 2,
                discount: convertAdditionalCostBackendToFrontend(
                    latest_bid.pricing_information.discounts
                ),
                item_price: latest_bid.pricing_information.price,
                itemDS: latest_bid.delivery_information,
                tax: convertAdditionalCostBackendToFrontend(
                    latest_bid.pricing_information.taxes
                ),
            });

            comparison['total'] =
                rfqItemTotal !== bidItemTotal ? 'WARNING' : 'SAFE';

            const rfqTaxesTotal = calculateAddtionalCost({
                additionalCost: convertAdditionalCostBackendToFrontend(
                    rfqItem.pricing_information.taxes ?? []
                ),
                baseQuantity: rfqItem.quantity_information.quantity,
                baseRate: rfqItem.pricing_information.desired_price,
                type: 'TAX',
                discountAdditionalCost: convertAdditionalCostBackendToFrontend(
                    rfqItem.pricing_information.discounts ?? []
                ),
            }).effectiveRateDeviation;
            const bidTaxesTotal = calculateAddtionalCost({
                additionalCost: convertAdditionalCostBackendToFrontend(
                    latest_bid.pricing_information.taxes ?? []
                ),
                baseQuantity: latest_bid.quantity_information.quantity,
                baseRate: latest_bid.pricing_information.price,
                type: 'TAX',
                discountAdditionalCost: convertAdditionalCostBackendToFrontend(
                    latest_bid.pricing_information.discounts ?? []
                ),
            }).effectiveRateDeviation;
            comparison['taxes'] =
                rfqTaxesTotal !== bidTaxesTotal ? 'WARNING' : 'SAFE';

            const rfqDiscountsTotal = calculateAddtionalCost({
                additionalCost: convertAdditionalCostBackendToFrontend(
                    rfqItem.pricing_information.discounts ?? []
                ),
                baseQuantity: rfqItem.quantity_information.quantity,
                baseRate: rfqItem.pricing_information.desired_price,
                type: 'DISCOUNT',
            }).effectiveRateDeviation;
            const bidDiscountsTotal = calculateAddtionalCost({
                additionalCost: convertAdditionalCostBackendToFrontend(
                    latest_bid.pricing_information.discounts ?? []
                ),
                baseQuantity: latest_bid.quantity_information.quantity,
                baseRate: latest_bid.pricing_information.price,
                type: 'DISCOUNT',
            }).effectiveRateDeviation;
            comparison['discount'] =
                rfqDiscountsTotal !== bidDiscountsTotal ? 'WARNING' : 'SAFE';

            const rfqAdditionalCostsTotal = calculateAddtionalCost({
                additionalCost: convertAdditionalCostBackendToFrontend(
                    rfqItem.pricing_information.additional_costs ?? []
                ),
                baseQuantity: rfqItem.quantity_information.quantity,
                baseRate: rfqItem.pricing_information.desired_price,
                type: 'ADDITIONAL_COST',
            }).effectiveRateDeviation;
            const bidAdditionalCostsTotal = calculateAddtionalCost({
                additionalCost: convertAdditionalCostBackendToFrontend(
                    latest_bid.pricing_information.additional_costs ?? []
                ),
                baseQuantity: latest_bid.quantity_information.quantity,
                baseRate: latest_bid.pricing_information.price,
                type: 'ADDITIONAL_COST',
            }).effectiveRateDeviation;

            comparison['additionalCost'] =
                rfqAdditionalCostsTotal !== bidAdditionalCostsTotal
                    ? 'WARNING'
                    : 'SAFE';

            comparison['payment_terms'] = paymentTermsComparison(
                rfqItem.paymentTerms,
                latest_bid.paymentTerms
            );
            if (
                latest_bid.paymentTerms &&
                parseFloat(latest_bid.paymentTerms?.prepaymentPercentage) >
                    parseFloat(
                        rfqItem.paymentTerms?.prepaymentPercentage ?? '0'
                    )
            )
                comparison['prepayment'] = 'WARNING';
            else comparison['prepayment'] = 'SAFE';

            if (rfqItem.incoterm !== latest_bid.incoterm)
                comparison['incoterm'] = 'WARNING';
            else comparison['incoterm'] = 'SAFE';
            // lead time calculation
            // if (
            //     CompareNoOfDays(
            //         parseFloat(rfqItem.procurement_information.lead_time),
            //         rfqItem.procurement_information.lead_time_period,
            //         parseFloat(latest_bid.procurement_information.lead_time),
            //         latest_bid.procurement_information.lead_time_period,
            //         '<'
            //     )
            // )
            comparison['leadTime'] = leadTimeComparison(
                latest_bid.procurement_information.lead_time,
                latest_bid.procurement_information.lead_time_period,
                rfqItem.procurement_information.lead_time,
                rfqItem.procurement_information.lead_time_period
            );
            // delivery schedule calculation
            // latest_bid.delivery_information.sort((delivery1, delivery2) => {
            //     if (delivery1.delivery_date === null) return 1;
            //     if (delivery2.delivery_date === null) return -1;
            //     else {
            //         let date1 = new Date(delivery1.delivery_date).getTime();
            //         let date2 = new Date(delivery2.delivery_date).getTime();
            //         return date1 - date2;
            //     }
            // });
            let latest_bid_delivery_information_copy = [
                ...latest_bid.delivery_information,
            ];
            latest_bid_delivery_information_copy.sort(
                (delivery1, delivery2) => {
                    if (delivery1.delivery_date === null) return 1;
                    if (delivery2.delivery_date === null) return -1;
                    else {
                        let date1 = new Date(delivery1.delivery_date).getTime();
                        let date2 = new Date(delivery2.delivery_date).getTime();
                        return date1 - date2;
                    }
                }
            );
            if (
                deliverySchedulesItemsAreSame(
                    rfqItem.delivery_information,
                    latest_bid_delivery_information_copy
                )
            )
                comparison['delivery'] = 'SAFE';
            else comparison['delivery'] = 'WARNING';

            comparison['custom_fields'] = 'SAFE';
            // if (
            //     !(
            //         rfqItem.custom_fields_negotiate &&
            //         rfqItem.custom_fields_negotiate.section_list &&
            //         latest_bid.custom_fields_negotiate &&
            //         latest_bid.custom_fields_negotiate.section_list &&
            //         latest_bid.custom_fields_negotiate.section_list.length ===
            //             rfqItem.custom_fields_negotiate.section_list.length
            //     )
            // ) {
            //     comparison['custom_fields'] = 'WARNING';
            // } else {
            //     for (
            //         let sectionIdx = 0;
            //         sectionIdx <
            //         rfqItem.custom_fields_negotiate.section_list.length;
            //         sectionIdx += 1
            //     ) {
            //         if (
            //             !(
            //                 latest_bid.custom_fields_negotiate.section_list[
            //                     sectionIdx
            //                 ].name ===
            //                     rfqItem.custom_fields_negotiate.section_list[
            //                         sectionIdx
            //                     ].name &&
            //                 latest_bid.custom_fields_negotiate.section_list[
            //                     sectionIdx
            //                 ].fields.length ===
            //                     rfqItem.custom_fields_negotiate.section_list[
            //                         sectionIdx
            //                     ].fields.length
            //             )
            //         ) {
            //             comparison['custom_fields'] = 'WARNING';
            //         } else {
            //             for (
            //                 let fieldIdx = 0;
            //                 fieldIdx <
            //                 latest_bid.custom_fields_negotiate.section_list[
            //                     sectionIdx
            //                 ].fields.length;
            //                 fieldIdx += 1
            //             ) {
            //                 comparison[
            //                     latest_bid.custom_fields_negotiate.section_list[
            //                         sectionIdx
            //                     ].fields[fieldIdx].name
            //                 ] = 'SAFE';
            //                 if (
            //                     !(
            //                         latest_bid.custom_fields_negotiate
            //                             .section_list[sectionIdx].fields[
            //                             fieldIdx
            //                         ].name ===
            //                             rfqItem.custom_fields_negotiate
            //                                 .section_list[sectionIdx].fields[
            //                                 fieldIdx
            //                             ].name &&
            //                         (!rfqItem.custom_fields_negotiate
            //                             .section_list[sectionIdx].fields[
            //                             fieldIdx
            //                         ].value ||
            //                             latest_bid.custom_fields_negotiate
            //                                 .section_list[sectionIdx].fields[
            //                                 fieldIdx
            //                             ].value ===
            //                                 rfqItem.custom_fields_negotiate
            //                                     .section_list[sectionIdx]
            //                                     .fields[fieldIdx].value)
            //                     )
            //                 ) {
            //                     comparison[
            //                         latest_bid.custom_fields_negotiate.section_list[
            //                             sectionIdx
            //                         ].fields[fieldIdx].name
            //                     ] = 'WARNING';
            //                 }
            //             }
            //         }
            //     }
            // }
        }
    }
    return comparison;
};

export const compareValueChangesBid = (
    previous_bid: IGetBuyerRFQBidItemDetails | null,
    latest_bid: IGetBuyerRFQBidItemDetails | null
) => {
    let comparison: { [key: string]: 'SAFE' | 'WARNING' } = {
        quantity: 'SAFE',
        price: 'SAFE',
        total: 'SAFE',
        payment_terms: 'SAFE',
        prepayment: 'SAFE',
        incoterm: 'SAFE',
        leadTime: 'SAFE',
        delivery: 'SAFE',
        custom_fields: 'SAFE',
        taxes: 'SAFE',
        discounts: 'SAFE',
        additionalCost: 'SAFE',
    };
    if (
        previous_bid?.bid_item_response_type === 'EXCLUDED' ||
        previous_bid?.bid_item_response_type === 'REMOVED' ||
        latest_bid?.bid_item_response_type === 'EXCLUDED' ||
        latest_bid?.bid_item_response_type === 'REMOVED'
    )
        return comparison;

    if (latest_bid === null && previous_bid === null) {
        return comparison;
    }
    if (
        (latest_bid === null && previous_bid !== null) ||
        (latest_bid !== null && previous_bid === null) ||
        (latest_bid?.pricing_information === null &&
            previous_bid?.pricing_information !== null) ||
        (latest_bid?.pricing_information !== null &&
            previous_bid?.pricing_information === null) ||
        (latest_bid?.quantity_information === null &&
            previous_bid?.quantity_information !== null) ||
        (latest_bid?.quantity_information !== null &&
            previous_bid?.quantity_information === null) ||
        (latest_bid?.delivery_information === null &&
            previous_bid?.delivery_information !== null) ||
        (latest_bid?.delivery_information !== null &&
            previous_bid?.delivery_information === null) ||
        (latest_bid?.paymentTerms === null &&
            previous_bid?.paymentTerms !== null) ||
        (latest_bid?.paymentTerms !== null &&
            previous_bid?.paymentTerms === null)
    ) {
        comparison['quantity'] = 'WARNING';
        comparison['price'] = 'WARNING';
        comparison['total'] = 'WARNING';
        comparison['payment_terms'] = 'WARNING';
        comparison['prepayment'] = 'WARNING';
        comparison['incoterm'] = 'WARNING';
        comparison['leadTime'] = 'WARNING';
        comparison['delivery'] = 'WARNING';
        comparison['custom_fields'] = 'WARNING';
        comparison['taxes'] = 'WARNING';
        comparison['discounts'] = 'WARNING';
        comparison['additionalCost'] = 'WARNING';
        return comparison;
    }

    if (latest_bid !== null && previous_bid !== null) {
        if (
            typeof previous_bid !== 'undefined' &&
            (latest_bid.bid_item_response_type === 'ACCEPTED' ||
                latest_bid.bid_item_response_type === 'RENEGOTIATING' ||
                latest_bid.bid_item_response_type === 'ADDED')
        ) {
            if (
                parseFloat(previous_bid.quantity_information.quantity) !==
                parseFloat(latest_bid.quantity_information.quantity)
            )
                comparison['quantity'] = 'WARNING';
            else comparison['quantity'] = 'SAFE';

            if (
                // previous_bid.pricing_information.price !== null
                // &&
                // latest_bid.pricing_information.currency_code_abbreviation ===
                //     previous_bid.pricing_information
                //         .currency_code_abbreviation &&
                parseFloat(latest_bid.pricing_information.price) !==
                parseFloat(previous_bid.pricing_information.price)
            )
                comparison['price'] = 'WARNING';
            else comparison['price'] = 'SAFE';

            const prevBidItemTotal = calculateNewItemTotalWithDecimalPlaces({
                additional_cost: convertAdditionalCostBackendToFrontend(
                    previous_bid.pricing_information.additional_costs
                ),
                decimalPlaces: 2,
                discount: convertAdditionalCostBackendToFrontend(
                    previous_bid.pricing_information.discounts
                ),
                item_price: previous_bid.pricing_information.price,
                itemDS: previous_bid.delivery_information,
                tax: convertAdditionalCostBackendToFrontend(
                    previous_bid.pricing_information.taxes
                ),
            });
            const bidItemTotal = calculateNewItemTotalWithDecimalPlaces({
                additional_cost: convertAdditionalCostBackendToFrontend(
                    latest_bid.pricing_information.additional_costs
                ),
                decimalPlaces: 2,
                discount: convertAdditionalCostBackendToFrontend(
                    latest_bid.pricing_information.discounts
                ),
                item_price: latest_bid.pricing_information.price,
                itemDS: latest_bid.delivery_information,
                tax: convertAdditionalCostBackendToFrontend(
                    latest_bid.pricing_information.taxes
                ),
            });

            comparison['total'] =
                prevBidItemTotal !== bidItemTotal ? 'WARNING' : 'SAFE';

            const prevBidTaxesTotal = calculateAddtionalCost({
                additionalCost: convertAdditionalCostBackendToFrontend(
                    previous_bid.pricing_information.taxes ?? []
                ),
                baseQuantity: previous_bid.quantity_information.quantity,
                baseRate: previous_bid.pricing_information.price,
                type: 'TAX',
                discountAdditionalCost: convertAdditionalCostBackendToFrontend(
                    previous_bid.pricing_information.discounts ?? []
                ),
            }).effectiveRateDeviation;
            const bidTaxesTotal = calculateAddtionalCost({
                additionalCost: convertAdditionalCostBackendToFrontend(
                    latest_bid.pricing_information.taxes ?? []
                ),
                baseQuantity: latest_bid.quantity_information.quantity,
                baseRate: latest_bid.pricing_information.price,
                type: 'TAX',
                discountAdditionalCost: convertAdditionalCostBackendToFrontend(
                    latest_bid.pricing_information.discounts ?? []
                ),
            }).effectiveRateDeviation;
            comparison['taxes'] =
                prevBidTaxesTotal !== bidTaxesTotal ? 'WARNING' : 'SAFE';

            const prevBidDiscountsTotal = calculateAddtionalCost({
                additionalCost: convertAdditionalCostBackendToFrontend(
                    previous_bid.pricing_information.discounts ?? []
                ),
                baseQuantity: previous_bid.quantity_information.quantity,
                baseRate: previous_bid.pricing_information.price,
                type: 'DISCOUNT',
            }).effectiveRateDeviation;
            const bidDiscountsTotal = calculateAddtionalCost({
                additionalCost: convertAdditionalCostBackendToFrontend(
                    latest_bid.pricing_information.discounts ?? []
                ),
                baseQuantity: latest_bid.quantity_information.quantity,
                baseRate: latest_bid.pricing_information.price,
                type: 'DISCOUNT',
            }).effectiveRateDeviation;
            comparison['discount'] =
                prevBidDiscountsTotal !== bidDiscountsTotal
                    ? 'WARNING'
                    : 'SAFE';

            const prevBidAdditionalCostsTotal = calculateAddtionalCost({
                additionalCost: convertAdditionalCostBackendToFrontend(
                    previous_bid.pricing_information.additional_costs ?? []
                ),
                baseQuantity: previous_bid.quantity_information.quantity,
                baseRate: previous_bid.pricing_information.price,
                type: 'ADDITIONAL_COST',
            }).effectiveRateDeviation;
            const bidAdditionalCostsTotal = calculateAddtionalCost({
                additionalCost: convertAdditionalCostBackendToFrontend(
                    latest_bid.pricing_information.additional_costs ?? []
                ),
                baseQuantity: latest_bid.quantity_information.quantity,
                baseRate: latest_bid.pricing_information.price,
                type: 'ADDITIONAL_COST',
            }).effectiveRateDeviation;
            comparison['additionalCost'] =
                prevBidAdditionalCostsTotal !== bidAdditionalCostsTotal
                    ? 'WARNING'
                    : 'SAFE';

            comparison['payment_terms'] = paymentTermsComparison(
                previous_bid.paymentTerms,
                latest_bid.paymentTerms
            );
            if (
                latest_bid.paymentTerms &&
                parseFloat(latest_bid.paymentTerms?.prepaymentPercentage) !==
                    parseFloat(
                        previous_bid.paymentTerms?.prepaymentPercentage ?? '0'
                    )
            )
                comparison['prepayment'] = 'WARNING';
            else comparison['prepayment'] = 'SAFE';
            if (previous_bid.incoterm !== latest_bid.incoterm)
                comparison['incoterm'] = 'WARNING';
            else comparison['incoterm'] = 'SAFE';
            // lead time calculation
            // if (
            //     CompareNoOfDays(
            //         parseFloat(previous_bid.procurement_information.lead_time),
            //         previous_bid.procurement_information.lead_time_period,
            //         parseFloat(latest_bid.procurement_information.lead_time),
            //         latest_bid.procurement_information.lead_time_period,
            //         '<'
            //     )
            // )
            comparison['leadTime'] = leadTimeComparison(
                latest_bid.procurement_information.lead_time,
                latest_bid.procurement_information.lead_time_period,
                previous_bid.procurement_information.lead_time,
                previous_bid.procurement_information.lead_time_period
            );
            // delivery schedule calculation
            // latest_bid.delivery_information.sort((delivery1, delivery2) => {
            //     if (delivery1.delivery_date === null) return 1;
            //     if (delivery2.delivery_date === null) return -1;
            //     else {
            //         let date1 = new Date(delivery1.delivery_date).getTime();
            //         let date2 = new Date(delivery2.delivery_date).getTime();
            //         return date1 - date2;
            //     }
            // });

            let latest_bid_delivery_information_copy = [
                ...latest_bid.delivery_information,
            ];
            latest_bid_delivery_information_copy.sort(
                (delivery1, delivery2) => {
                    if (delivery1.delivery_date === null) return 1;
                    if (delivery2.delivery_date === null) return -1;
                    else {
                        let date1 = new Date(delivery1.delivery_date).getTime();
                        let date2 = new Date(delivery2.delivery_date).getTime();
                        return date1 - date2;
                    }
                }
            );
            if (
                deliverySchedulesItemsAreSame(
                    previous_bid.delivery_information,
                    latest_bid_delivery_information_copy
                )
            )
                comparison['delivery'] = 'SAFE';
            else comparison['delivery'] = 'WARNING';

            comparison['custom_fields'] = 'SAFE';
            // if (
            //     !(
            //         previous_bid.custom_fields_negotiate &&
            //         previous_bid.custom_fields_negotiate.section_list &&
            //         latest_bid.custom_fields_negotiate &&
            //         latest_bid.custom_fields_negotiate.section_list &&
            //         latest_bid.custom_fields_negotiate.section_list.length ===
            //             previous_bid.custom_fields_negotiate.section_list.length
            //     )
            // ) {
            //     comparison['custom_fields'] = 'WARNING';
            // } else {
            //     for (
            //         let sectionIdx = 0;
            //         sectionIdx <
            //         previous_bid.custom_fields_negotiate.section_list.length;
            //         sectionIdx += 1
            //     ) {
            //         if (
            //             !(
            //                 latest_bid.custom_fields_negotiate.section_list[
            //                     sectionIdx
            //                 ].name ===
            //                     previous_bid.custom_fields_negotiate
            //                         .section_list[sectionIdx].name &&
            //                 latest_bid.custom_fields_negotiate.section_list[
            //                     sectionIdx
            //                 ].fields.length ===
            //                     previous_bid.custom_fields_negotiate
            //                         .section_list[sectionIdx].fields.length
            //             )
            //         ) {
            //             comparison['custom_fields'] = 'WARNING';
            //         } else {
            //             for (
            //                 let fieldIdx = 0;
            //                 fieldIdx <
            //                 latest_bid.custom_fields_negotiate.section_list[
            //                     sectionIdx
            //                 ].fields.length;
            //                 fieldIdx += 1
            //             ) {
            //                 comparison[
            //                     latest_bid.custom_fields_negotiate.section_list[
            //                         sectionIdx
            //                     ].fields[fieldIdx].name
            //                 ] = 'SAFE';
            //                 if (
            //                     !(
            //                         latest_bid.custom_fields_negotiate
            //                             .section_list[sectionIdx].fields[
            //                             fieldIdx
            //                         ].name ===
            //                             previous_bid.custom_fields_negotiate
            //                                 .section_list[sectionIdx].fields[
            //                                 fieldIdx
            //                             ].name &&
            //                         (!previous_bid.custom_fields_negotiate
            //                             .section_list[sectionIdx].fields[
            //                             fieldIdx
            //                         ].value ||
            //                             latest_bid.custom_fields_negotiate
            //                                 .section_list[sectionIdx].fields[
            //                                 fieldIdx
            //                             ].value ===
            //                                 previous_bid.custom_fields_negotiate
            //                                     .section_list[sectionIdx]
            //                                     .fields[fieldIdx].value)
            //                     )
            //                 ) {
            //                     comparison[
            //                         latest_bid.custom_fields_negotiate.section_list[
            //                             sectionIdx
            //                         ].fields[fieldIdx].name
            //                     ] = 'WARNING';
            //                 }
            //             }
            //         }
            //     }
            // }
        }
    }
    return comparison;
};

const getComparablePaymentTermsForInvoice = (
    paymentTerms: IPaymentTermsInvoiceItemValues
): IPaymentTermsInvoiceItemValues => {
    const newPaymentTerms = { ...paymentTerms };
    newPaymentTerms.prepaymentPercentage = '0';
    newPaymentTerms.terms =
        newPaymentTerms.period === PeriodEnum.DAYS
            ? newPaymentTerms.terms
            : newPaymentTerms.period === PeriodEnum.WEEKS
            ? (+paymentTerms.terms * 7).toFixed(0)
            : newPaymentTerms.period === PeriodEnum.MONTHS
            ? (+paymentTerms.terms * 30).toFixed(0)
            : '0';
    newPaymentTerms.period = PeriodEnum.DAYS;

    return newPaymentTerms;
};

const getComparablePaymentTermsForPoItem = (
    paymentTerms: IPaymentTermsPoItemPercentages
): IPaymentTermsPoItemPercentages => {
    const newPaymentTerms = { ...paymentTerms };
    newPaymentTerms.list = newPaymentTerms.list.map((row) => {
        const newRow = { ...row };
        if (newRow.milestoneType === 'FIXED_DATE') {
            return newRow;
        } else {
            return {
                ...newRow,
                terms:
                    newRow.period === PeriodEnum.DAYS
                        ? newRow.terms
                        : newRow.period === PeriodEnum.WEEKS
                        ? (+newRow.terms * 7).toFixed(0)
                        : newRow.period === PeriodEnum.MONTHS
                        ? (+newRow.terms * 30).toFixed(0)
                        : '0',
                period: PeriodEnum.DAYS,
            };
        }
    });

    return newPaymentTerms;
};

export const paymentTermsComparison = (
    rfqPaymentTerms: IPaymentTerms | null,
    bidPaymentTerms: IPaymentTerms | null
): 'SAFE' | 'WARNING' => {
    if (!rfqPaymentTerms || !bidPaymentTerms) {
        return 'SAFE';
    }
    if (rfqPaymentTerms.paymentType !== bidPaymentTerms.paymentType) {
        return 'WARNING';
    }
    if (
        rfqPaymentTerms.paymentType === 'invoiceItemValues' &&
        bidPaymentTerms.paymentType === 'invoiceItemValues'
    ) {
        // invoice item values payment terms comparison
        if (
            +rfqPaymentTerms.prepaymentPercentage >= 100 &&
            +bidPaymentTerms.prepaymentPercentage >= 100
        ) {
            return 'SAFE';
        }
        if (
            JSON.stringify(
                getComparablePaymentTermsForInvoice(rfqPaymentTerms)
            ) ===
            JSON.stringify(getComparablePaymentTermsForInvoice(bidPaymentTerms))
        ) {
            return 'SAFE';
        }
        return 'WARNING';
    } else if (
        rfqPaymentTerms.paymentType === 'poItemPercentages' &&
        bidPaymentTerms.paymentType === 'poItemPercentages'
    ) {
        //allocation percentage payment terms comparison
        if (
            JSON.stringify(
                getComparablePaymentTermsForPoItem(rfqPaymentTerms)
            ) ===
            JSON.stringify(getComparablePaymentTermsForPoItem(bidPaymentTerms))
        ) {
            return 'SAFE';
        }
        return 'WARNING';
    }
    // Should never get here.
    return 'SAFE';
};

export const leadTimeComparison = (
    lead_time: string,
    lead_time_period: string,
    compare_to_lead_time: string,
    compare_to_lead_time_period: string
) => {
    if (
        ['MONTHS', 'WEEKS', 'DAYS'].includes(lead_time_period) &&
        ['MONTHS', 'WEEKS', 'DAYS'].includes(compare_to_lead_time_period)
    ) {
        const lead_time_days =
            lead_time_period === 'MONTHS'
                ? parseFloat(lead_time) * 30
                : lead_time_period === 'WEEKS'
                ? parseFloat(lead_time) * 7
                : parseFloat(lead_time);
        const compare_to_lead_time_days =
            compare_to_lead_time_period === 'MONTHS'
                ? parseFloat(compare_to_lead_time) * 30
                : compare_to_lead_time_period === 'WEEKS'
                ? parseFloat(compare_to_lead_time) * 7
                : parseFloat(compare_to_lead_time);
        return lead_time_days > compare_to_lead_time_days ? 'WARNING' : 'SAFE';
    } else {
        if (
            parseFloat(lead_time) !== parseFloat(compare_to_lead_time) ||
            lead_time_period !== compare_to_lead_time_period
        ) {
            return 'WARNING';
        }
    }
    return 'SAFE';
};

//FUNCTION TO REMOVE CUSTOM_SECTION_IDS AND CUSTOM_FIELD_IDS FROM THE BID ITEMS AND FILTERING BASED ON IS_VISIBLE
export const modifyBidForItemForm = (
    customSections: ICustomSection[]
): ICustomSection[] => {
    let temp = cloneDeep(customSections);
    temp = temp.map((section) => {
        let temp = cloneDeep(section);
        delete temp['custom_section_id'];
        temp.custom_fields = temp.custom_fields
            .filter((field) => field.is_visible)
            .map((field) => {
                let temp = cloneDeep(field);
                delete temp['custom_field_id'];
                return temp;
            });
        return temp;
    });

    return temp;
};
