import {
    Avatar,
    Box,
    Checkbox,
    CircularProgress,
    DialogActions,
    DialogContent,
    FormControlLabel,
    FormGroup,
    Grid,
    IconButton,
    Radio,
    TextField,
    Typography,
    styled,
} from '@mui/material';
import { cloneDeep, debounce, isEmpty, isNull, uniqueId } from 'lodash';
import {
    Fragment,
    useCallback,
    useContext,
    useEffect,
    useMemo,
    useRef,
    useState,
} from 'react';
import { useSelector } from 'react-redux';
// import { useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import {
    FWAccordion,
    FWAccordionDetails,
    FWAccordionSummary,
} from '../../../../Common/FWAccordian';
import FWAutocomplete from '../../../../Common/FWAutocomplete';
import { FWButton } from '../../../../Common/FWButton';
import { FWMenuItem } from '../../../../Common/FWCustomMenu';
import { FWTooltip } from '../../../../Common/FWCustomTooltip';
import FWGridKeyValue from '../../../../Common/FWGridKeyValue';
import FWIcon from '../../../../Common/FWIcon';
import { FWInput } from '../../../../Common/FWInput';
import { FWPopupWithChildren } from '../../../../Common/FWPopupWithChildren';
import { FWTypography } from '../../../../Common/FWTypograhy';
import {
    IFileResourceType,
    IResourceFields,
} from '../../../../Components/Shared/UploadFile';
import { AuthContext } from '../../../../Contexts/AuthContext';
import SignupPendingIcon from '../../../../Global/Components/SignupPendingIcon';
import TagsInput from '../../../../Global/Components/TagsInput';
import VendorVerificationStatusIcon from '../../../../Global/Components/VendorVerificationStatusIcon';
import BulkImportPopup from '../../../../Global/Containers/BulkImportPopup';
import { IAddresses } from '../../../../Models/Address.model';
import { VerificationStatus } from '../../../../Redux/Slices/VerificationSlice';
import { IGlobalAppStore } from '../../../../Redux/Store';
import { getVendorEntityAddresses } from '../../../../Services/events/eventOperations';
import CommaSeparatedLinks from '../../Components/CommaSeparatedLinks';
import VendorIdentifications from '../../Components/VendorIdentifications';
import {
    IVendorAddProviders,
    VendorDetailActions,
    initialVendorDetails,
    useAddVendor,
} from '../../Hooks/Vendors/VendorAddHook';
import { IEntity } from '../../Interfaces/EnterpriseInterface';
import {
    IVendor,
    IVendorAddDetails,
    IVendorListSummary,
    VendorStatus,
    VendorVerificationStatus,
} from '../../Interfaces/VendorsInterface';
import {
    IVendorIdentification,
    IVendorSearchedData,
    getEntityIdentifications,
} from '../../Services/VendorsService';
// import { useVendorTemplateHook } from '../../Hooks/Vendors/useVendorTemplateHook';

interface IVendorAddPopupProps {
    popupToggle: boolean;
    allEnterpriseVendors: IVendorListSummary[];
    entities: IEntity[];
    handlePopupToggle: () => void;
    onFailure?: () => void;
    onSuccess?: (
        enterprise_vendor_master_id: string,
        vendor_code: string
    ) => void;
    fixedVendorDetails?: IVendorAddDetails | null;
}

const StyledFWAccordionSummary = styled(FWAccordionSummary)({
    backgroundColor: 'white',

    '&': {
        padding: '0',
        margin: '5px 0',
        backgroundColor: 'white',
    },
    '& .MuiAccordionSummary-content': {
        margin: '0',
        width: '100%',
        backgroundColor: 'white',
    },
});

const VendorAddPopup = ({
    popupToggle,
    allEnterpriseVendors,
    entities,
    handlePopupToggle,
    onSuccess = (
        enterprise_vendor_master_id: string,
        vendor_code: string
    ) => {},
    onFailure = () => {},
    fixedVendorDetails = null,
}: IVendorAddPopupProps) => {
    const { authData } = useContext(AuthContext);
    const contentRef = useRef<HTMLElement>();

    const {
        globalVendorTags,
        allVendors,
        vendorDetails,
        setVendorDetails,
        isValidDetails,
        updateVendorDetails,
        searchForVendors,
        validateInfo,
        vendorInfoErrors,
        addVendorToDirectory,
        resetAllVendors,
    }: IVendorAddProviders = useAddVendor(fixedVendorDetails, popupToggle);

    const SINGLE_ENTITY = useSelector(
        (store: IGlobalAppStore) => store.VerificationStore.hasSignleEntity
    );

    // const location = useLocation();
    // const isEventPage = location.pathname.includes('event');
    const [loading, setLoading] = useState<boolean>(false);

    const [stoppedSearching, setStoppedSearching] = useState<boolean>(false);
    const [typing, setTyping] = useState<boolean>(false);

    const [searchQuery, setSearchQuery] = useState('');

    const debounceSearch = useMemo(
        () =>
            debounce(async (searchQueryText) => {
                await searchForVendors(searchQueryText);
                setStoppedSearching(true);
                setTyping(false);
            }, 500),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        []
    );

    const [vendorIdentifications, setVendorIdentifications] = useState<
        IVendorIdentification[]
    >([]);

    useEffect(() => {
        debounceSearch(searchQuery as any);
    }, [debounceSearch, searchQuery]);

    const [vendorAddress, setVendorAddress] = useState<IAddresses[]>([]);

    const getLinkedIdentifications = useCallback(async () => {
        if (vendorDetails?.vendorUid) {
            try {
                let res = await getEntityIdentifications(
                    vendorDetails.vendorUid
                );
                if (res)
                    setVendorIdentifications(res.filter((id) => id.is_public));

                let addresses = await getVendorEntityAddresses(
                    vendorDetails.vendorUid
                );
                if (addresses) setVendorAddress(addresses);
            } catch (err) {}
        }
    }, [vendorDetails?.vendorUid]);

    useEffect(() => {
        getLinkedIdentifications();
    }, [getLinkedIdentifications, vendorDetails?.vendorUid]);

    const handleFieldBlur = (event: any) => {
        validateInfo(event);
    };

    const usedVendorCodes = useMemo(() => {
        return allEnterpriseVendors.map((vendor) => vendor.vendorCode);
    }, [allEnterpriseVendors]);

    const usedVendorUids = useMemo(() => {
        return allEnterpriseVendors.map((vendor) => vendor.vendorUid);
    }, [allEnterpriseVendors]);

    const handleAddVendorToDirectory = async () => {
        setLoading(true);
        try {
            const vendorAdded = await addVendorToDirectory();
            if (vendorAdded) {
                toast.success('Vendor added to vendor directory successfully');
                onSuccess(
                    vendorAdded.enterprise_vendor_master_id,
                    vendorAdded.vendor_code
                );
                handleClosePopup();
            }
        } catch {
            onFailure();
            toast.error('Failed to add vendor');
        } finally {
            setLoading(false);
        }
    };

    // useEffect to select one entity by default
    // when a new vendor is being added
    const eventEntityId = useSelector(
        (store: IGlobalAppStore) =>
            store.EventCreationStore.eventDetails?.entity_id
    );
    useEffect(() => {
        if (eventEntityId) {
            if (
                vendorDetails !== null &&
                vendorDetails.entities.length === 0 &&
                entities.length > 0
            ) {
                updateVendorDetails({
                    type: VendorDetailActions.ENTITY_ADD,
                    value: eventEntityId ?? '',
                });
            }
        } else {
            if (
                vendorDetails &&
                SINGLE_ENTITY &&
                !vendorDetails.entities[0] &&
                entities[0]
            ) {
                //
                updateVendorDetails({
                    type: VendorDetailActions.ENTITY_ADD,
                    value: entities[0].entityId,
                });
            }
        }
    }, [
        entities,
        updateVendorDetails,
        vendorDetails,
        eventEntityId,
        SINGLE_ENTITY,
    ]);

    const enterpriseEntities = useMemo(() => {
        return entities.map((entity) => entity.entityId);
    }, [entities]);

    const filteredVendors = useMemo(
        () =>
            allVendors.filter(
                (vendor) => !enterpriseEntities.includes(vendor.vendorUid)
            ),
        [allVendors, enterpriseEntities]
    );

    const handleClosePopup = () => {
        setVendorDetails(null);
        setStoppedSearching(false);
        setTyping(false);
        handlePopupToggle();
        setImportPopupToggle(false);
    };

    // bulk import
    const [importPopupToggle, setImportPopupToggle] = useState<boolean>(false);

    enum resourceFields {
        PREFERRED_ITEM_CODE = 'Preferred item code',
        TAGS = 'Tags',
        SECONDARY_CONTACT_EMAIL = 'Secondary contact email',
        PRIMARY_CONTACT_SECONDARY_EMAIL = 'Primary contact secondary email',
        PRIMARY_CONTACT_CC_EMAIL = 'Primary contact cc email',
        VENDOR_ADDRESS = 'Vendor address',
        VENDOR_IDENTIFICATION_NAME = 'Vendor identification name',
    }

    const BulkImportInitialFields = [
        { key: resourceFields.PREFERRED_ITEM_CODE, value: 2 },
        { key: resourceFields.TAGS, value: 1 },
        { key: resourceFields.PRIMARY_CONTACT_SECONDARY_EMAIL, value: 1 },
        { key: resourceFields.PRIMARY_CONTACT_CC_EMAIL, value: 1 },
        { key: resourceFields.SECONDARY_CONTACT_EMAIL, value: 1 },
        { key: resourceFields.VENDOR_ADDRESS, value: 1 },
        { key: resourceFields.VENDOR_IDENTIFICATION_NAME, value: 1 },
    ];

    const [templateDownloadValues, setTemplateDownloadValues] = useState<
        IResourceFields[]
    >(cloneDeep(BulkImportInitialFields));

    const handleImportPopupToggle = () => {
        setImportPopupToggle(!importPopupToggle);
    };

    const handleTemplateDownloadValues = (e: any, index: number) => {
        setTemplateDownloadValues((prevValues) => {
            prevValues[index].value = +e.target.value;
            return [...prevValues];
        });
    };

    const resetTemplateDownloadValues = () => {
        setTemplateDownloadValues(cloneDeep(BulkImportInitialFields));
    };

    useEffect(() => {
        if (vendorDetails === null) {
            setVendorIdentifications([]);
            setVendorAddress([]);
        }
    }, [vendorDetails]);

    // const handleSearchVendors=()=>{
    //     // setVendorDetails
    // }

    return importPopupToggle ? (
        <BulkImportPopup
            popupToggle={importPopupToggle}
            title="Bulk upload: Vendor Directory"
            helpText="Generate a template per your requirements, fill in data accurately, and then upload it to your Vendor Directory"
            handlePopupToggle={handleClosePopup}
            resourceType={IFileResourceType.VENDOR}
            resource_fields={templateDownloadValues}
            onSuccessDownload={resetTemplateDownloadValues}
            label="Attach file for upload"
        >
            <>
                <div className="mb--15 mt--5">
                    <FWInput
                        label="Maximum number of preferred item codes per vendor"
                        type="text"
                        allowOnly="NUMBER"
                        style={{ width: '100%' }}
                        value={templateDownloadValues[0]?.value || ''}
                        onChange={(e) => handleTemplateDownloadValues(e, 0)}
                    />
                </div>
                <div className="mb--25 mt--5">
                    <FWInput
                        label="Maximum number of tags per vendor"
                        type="text"
                        name="preferred_vendors"
                        allowOnly="NUMBER"
                        style={{ width: '100%' }}
                        value={templateDownloadValues[1]?.value || ''}
                        onChange={(e) => handleTemplateDownloadValues(e, 1)}
                    />
                </div>
                <div className="mb--25 mt--5">
                    <FWInput
                        label="Maximum number of secondary contacts"
                        type="text"
                        allowOnly="NUMBER"
                        style={{ width: '100%' }}
                        value={templateDownloadValues[2]?.value || ''}
                        onChange={(e) => handleTemplateDownloadValues(e, 2)}
                    />
                </div>
                <div className="mb--25 mt--5">
                    <FWInput
                        label="Maximum number of vendor identifications"
                        type="text"
                        allowOnly="NUMBER"
                        style={{ width: '100%' }}
                        value={templateDownloadValues[5]?.value || ''}
                        onChange={(e) => handleTemplateDownloadValues(e, 5)}
                    />
                </div>
            </>
        </BulkImportPopup>
    ) : (
        <FWPopupWithChildren
            handleClose={() => {
                handleClosePopup();
                onFailure();
            }}
            open={popupToggle}
            title="Add vendor organization"
            PaperProps={{
                sx: {
                    minHeight: '80vh',
                    minWidth: '991px !important',
                },
            }}
            titleAddOnElement={
                <FWTooltip
                    title="Bulk import"
                    onClick={handleImportPopupToggle}
                >
                    <div>
                        <IconButton color="primary">
                            <FWIcon name="bi bi-box-arrow-down" size={24} />
                        </IconButton>
                    </div>
                </FWTooltip>
            }
        >
            <DialogContent
                style={{ paddingTop: 0, paddingBottom: 0 }}
                ref={contentRef}
            >
                <div className="mt--5">
                    <Grid container>
                        <Grid
                            item
                            xs={12}
                            display="flex"
                            alignItems="center"
                            justifyContent="space-between"
                        >
                            {fixedVendorDetails === null && (
                                <>
                                    <FWAutocomplete
                                        disabled={!isNull(vendorDetails)}
                                        open={
                                            stoppedSearching &&
                                            isNull(vendorDetails)
                                        }
                                        options={filteredVendors}
                                        sx={{ width: 'calc(100% - 31px)' }}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                autoFocus
                                                autoComplete="off"
                                                placeholder="Search vendor by name"
                                                onKeyDown={(e) => {
                                                    if (e.key === 'Enter') {
                                                        e.stopPropagation();
                                                        // e.preventDefault();
                                                    }
                                                }}
                                            />
                                        )}
                                        // value={searchQuery}
                                        onInputChange={(e: any) => {
                                            if (e.target.value) {
                                                setStoppedSearching(false);

                                                if (
                                                    e.target.value.length >= 1
                                                ) {
                                                    setTyping(true);
                                                }
                                                setSearchQuery(e.target.value);
                                            }
                                        }}
                                        getOptionLabel={(option: IVendor) =>
                                            option.vendorName
                                        }
                                        filterOptions={(
                                            options: IVendor[],
                                            params
                                        ) => {
                                            const inputValue: string =
                                                params.inputValue.trim();
                                            if (inputValue.trim() === '')
                                                return options;
                                            const customVendor: IVendorSearchedData =
                                                {
                                                    vendorUid: new Date()
                                                        .getMilliseconds()
                                                        .toString(),
                                                    vendorStatus:
                                                        VendorStatus.ACTIVE,
                                                    vendorName: inputValue,
                                                    vendorVerificationStatus:
                                                        VendorVerificationStatus.INVITATION_HOLD,
                                                    vendorEnterpriseUid: '',
                                                    vendorEnterpriseName: '',
                                                    enterpriseVendorMasterId:
                                                        '',
                                                    isCustom: true,
                                                    vendorCode: '',
                                                    notes: '',
                                                    tags: [],
                                                    description: '',
                                                    website: '',
                                                    email: '',
                                                    telephone: '',
                                                    primaryContacts: [
                                                        {
                                                            parent: null,
                                                            uid: uniqueId(),
                                                            default: true,
                                                            email: '',
                                                            fullName: '',
                                                            contactNumber: '',
                                                            notes: '',
                                                            isPresentInFW:
                                                                false,
                                                            selected: false,
                                                            internalName: '',
                                                            isCCEmail: false,
                                                            isSecondaryEmail:
                                                                false,
                                                        },
                                                    ],
                                                    entities: [],
                                                    is_entity_linked: true,
                                                    is_virtual: true,
                                                    verification_status:
                                                        VerificationStatus.UNVERIFIED,
                                                    is_new_invitation_row: true,
                                                    vendor_internal_name:
                                                        inputValue,
                                                    entity_doing_business_as:
                                                        '',
                                                    is_Dir_Vendor: false,
                                                    address_info: null,
                                                    globalName: '',
                                                    globalEmail: '',
                                                    vendor_contact_name: null,
                                                    vendor_contact_email: null,
                                                    seller_address_information:
                                                        [],
                                                    seller_identifications: [],
                                                    seller_contacts: [],
                                                    custom_sections: [],
                                                };

                                            const filtered = options;
                                            filtered.push(customVendor);
                                            return filtered;
                                        }}
                                        renderOption={(
                                            props: any,
                                            option: IVendorSearchedData
                                        ) => {
                                            const alreadyAdded =
                                                usedVendorUids.includes(
                                                    option.vendorUid
                                                );
                                            return (
                                                <Fragment
                                                    key={option.vendorUid}
                                                >
                                                    <FWTooltip
                                                        title={
                                                            alreadyAdded
                                                                ? 'Vendor already added'
                                                                : ''
                                                        }
                                                    >
                                                        <div>
                                                            <FWMenuItem
                                                                {...props}
                                                                disabled={
                                                                    alreadyAdded
                                                                }
                                                                sx={{
                                                                    color: alreadyAdded
                                                                        ? 'text.secondary'
                                                                        : undefined,
                                                                }}
                                                            >
                                                                {option.isCustom ? (
                                                                    <Box
                                                                        display="flex"
                                                                        flexDirection="column"
                                                                    >
                                                                        <Box
                                                                            display="flex"
                                                                            justifyContent={
                                                                                'center'
                                                                            }
                                                                            alignItems="center"
                                                                            color="primary.main"
                                                                        >
                                                                            <Box
                                                                                display="flex"
                                                                                alignItems="center"
                                                                                justifyContent="center"
                                                                                flex="1"
                                                                                fontSize={
                                                                                    '1.5rem'
                                                                                }
                                                                                bgcolor="#007aff22"
                                                                                borderRadius="50% !important"
                                                                                width="40px"
                                                                                height="40px"
                                                                                padding="5px"
                                                                                marginLeft="5px"
                                                                            >
                                                                                <i className="bi bi-person-plus"></i>
                                                                            </Box>
                                                                            &nbsp;
                                                                            <Typography
                                                                                padding="0px 10px 0px 5px"
                                                                                fontSize={
                                                                                    '1rem'
                                                                                }
                                                                                fontWeight="500"
                                                                            >
                                                                                {`Invite vendor entity '${option.vendorName}' to join FactWise`}
                                                                            </Typography>
                                                                        </Box>
                                                                    </Box>
                                                                ) : (
                                                                    <>
                                                                        <Box
                                                                            display="flex"
                                                                            justifyContent={
                                                                                'center'
                                                                            }
                                                                            alignItems="center"
                                                                        >
                                                                            <Box
                                                                                flex="1"
                                                                                padding="5px"
                                                                            >
                                                                                {option.is_Dir_Vendor ? (
                                                                                    <FWTooltip
                                                                                        title={
                                                                                            'Vendor present in the directory'
                                                                                        }
                                                                                    >
                                                                                        <Box
                                                                                            color="text.secondary"
                                                                                            fontSize={
                                                                                                '1.6rem'
                                                                                            }
                                                                                            bgcolor="#f2f2f2"
                                                                                            borderRadius="50% !important"
                                                                                            width="40px"
                                                                                            height="40px"
                                                                                            display="flex"
                                                                                            alignItems="center"
                                                                                            justifyContent="center"
                                                                                        >
                                                                                            <i className="bi bi-folder"></i>
                                                                                        </Box>
                                                                                    </FWTooltip>
                                                                                ) : (
                                                                                    <FWTooltip
                                                                                        title={
                                                                                            'Vendor not present in the directory'
                                                                                        }
                                                                                    >
                                                                                        <Box
                                                                                            color="text.secondary"
                                                                                            fontSize={
                                                                                                '1.6rem'
                                                                                            }
                                                                                            bgcolor="#f2f2f2"
                                                                                            borderRadius="50% !important"
                                                                                            width="40px"
                                                                                            height="40px"
                                                                                            display="flex"
                                                                                            alignItems="center"
                                                                                            justifyContent="center"
                                                                                        >
                                                                                            <i className="bi bi-globe"></i>
                                                                                        </Box>
                                                                                    </FWTooltip>
                                                                                )}
                                                                            </Box>
                                                                            <Box padding="0px 10px 0px 5px">
                                                                                <Box display="flex">
                                                                                    <Typography
                                                                                        fontSize={
                                                                                            '1rem'
                                                                                        }
                                                                                        fontWeight="500"
                                                                                    >
                                                                                        {
                                                                                            option.vendorName
                                                                                        }
                                                                                        &nbsp;
                                                                                    </Typography>
                                                                                    <Typography color="text.secondary">
                                                                                        (
                                                                                        {
                                                                                            option.vendorEnterpriseName
                                                                                        }

                                                                                        )
                                                                                        &nbsp;
                                                                                    </Typography>
                                                                                    <VendorVerificationStatusIcon
                                                                                        verificationStatus={
                                                                                            option.verification_status
                                                                                        }
                                                                                    />
                                                                                    {option.is_virtual && (
                                                                                        <>
                                                                                            &nbsp;
                                                                                            <SignupPendingIcon />
                                                                                        </>
                                                                                    )}
                                                                                </Box>
                                                                                <Typography
                                                                                    fontSize={
                                                                                        '0.9rem'
                                                                                    }
                                                                                    color="text.secondary"
                                                                                >
                                                                                    {[
                                                                                        option.entity_doing_business_as,
                                                                                        option.website,
                                                                                        [
                                                                                            option
                                                                                                .address_info
                                                                                                ?.city,
                                                                                            option
                                                                                                .address_info
                                                                                                ?.country,
                                                                                        ]
                                                                                            .filter(
                                                                                                Boolean
                                                                                            )
                                                                                            .join(
                                                                                                ', '
                                                                                            ),
                                                                                    ]
                                                                                        .filter(
                                                                                            Boolean
                                                                                        )
                                                                                        .join(
                                                                                            ' \u00B7 '
                                                                                        )}
                                                                                </Typography>
                                                                                <Typography
                                                                                    fontSize={
                                                                                        '0.9rem'
                                                                                    }
                                                                                    color="text.secondary"
                                                                                >
                                                                                    {option.is_Dir_Vendor
                                                                                        ? [
                                                                                              option.vendor_contact_name,
                                                                                              option.vendor_contact_email,
                                                                                          ]
                                                                                              .filter(
                                                                                                  Boolean
                                                                                              )
                                                                                              .join(
                                                                                                  ' \u00B7 '
                                                                                              )
                                                                                        : [
                                                                                              option.globalName,
                                                                                              option.globalEmail,
                                                                                          ]
                                                                                              .filter(
                                                                                                  Boolean
                                                                                              )
                                                                                              .join(
                                                                                                  ' \u00B7 '
                                                                                              )}
                                                                                </Typography>
                                                                            </Box>
                                                                        </Box>
                                                                    </>
                                                                )}
                                                            </FWMenuItem>
                                                        </div>
                                                    </FWTooltip>
                                                </Fragment>
                                            );
                                        }}
                                        onChange={(
                                            event,
                                            vendor: IVendor | null
                                        ) => {
                                            if (vendor) {
                                                delete (vendor as any)
                                                    .seller_identifications;
                                                delete (vendor as any)
                                                    .seller_address_information;
                                            }
                                            setVendorDetails(
                                                vendor
                                                    ? {
                                                          ...cloneDeep(
                                                              initialVendorDetails
                                                          ),
                                                          ...cloneDeep(vendor),
                                                          entities:
                                                              entities.map(
                                                                  (entity) =>
                                                                      entity.entityId
                                                              ),
                                                      }
                                                    : null
                                            );
                                            resetAllVendors();
                                        }}
                                        freeSolo={true}
                                        clearOnEscape
                                    />
                                    {!isNull(vendorDetails) && (
                                        <IconButton
                                            color="error"
                                            onClick={() => {
                                                setVendorDetails(null);
                                                // handleSearchVendors()
                                            }}
                                            sx={{
                                                ml: '5px',
                                                padding: 0,
                                            }}
                                            disableRipple
                                        >
                                            <FWIcon
                                                name="bi bi-arrow-counterclockwise"
                                                size={20}
                                            />
                                        </IconButton>
                                    )}
                                    {typing && (
                                        <CircularProgress
                                            size={20}
                                            sx={{ ml: '5px' }}
                                        />
                                    )}
                                </>
                            )}
                        </Grid>
                    </Grid>
                    {!isNull(vendorDetails) && (
                        <Grid
                            container
                            marginTop="15px"
                            columnSpacing={2}
                            alignItems="center"
                        >
                            <Grid
                                item
                                xs="auto"
                                className="flex"
                                marginBottom="10px"
                            >
                                <Avatar
                                    alt={vendorDetails.vendorName}
                                    src={vendorDetails.vendorName}
                                    style={{
                                        width: 70,
                                        height: 70,
                                    }}
                                />
                            </Grid>
                            <Grid item xs={10}>
                                <FWTypography sx={{ maxWidth: '100%' }}>
                                    {vendorDetails.vendorName}
                                </FWTypography>

                                <FWTypography
                                    sx={{
                                        fontStyle: vendorDetails.isCustom
                                            ? 'italic'
                                            : undefined,
                                        color: vendorDetails.isCustom
                                            ? 'text.secondary'
                                            : undefined,
                                    }}
                                >
                                    {isEmpty(vendorDetails.description)
                                        ? vendorDetails.isCustom
                                            ? 'Invitation pending'
                                            : ''
                                        : vendorDetails.description}
                                </FWTypography>
                            </Grid>
                            {!vendorDetails.isCustom && (
                                <>
                                    {!isEmpty(vendorDetails.website) && (
                                        <Grid item xs={4}>
                                            <FWTypography
                                                sx={{
                                                    color: 'text.secondary',
                                                }}
                                            >
                                                Company webpage
                                            </FWTypography>
                                            <FWTypography>
                                                <CommaSeparatedLinks
                                                    value={
                                                        vendorDetails.website
                                                            ? vendorDetails.website
                                                            : '-'
                                                    }
                                                />
                                            </FWTypography>
                                        </Grid>
                                    )}
                                    {!isEmpty(vendorDetails.email) && (
                                        <Grid item xs={4}>
                                            <FWTypography
                                                sx={{
                                                    color: 'text.secondary',
                                                }}
                                            >
                                                Email
                                            </FWTypography>
                                            <FWTypography>
                                                <CommaSeparatedLinks
                                                    value={vendorDetails.email}
                                                    type="mailto"
                                                    noLink
                                                />
                                            </FWTypography>
                                        </Grid>
                                    )}
                                    {!isEmpty(vendorDetails.telephone) && (
                                        <Grid
                                            item
                                            xs={4}
                                            className="pb--15 pr--15"
                                        >
                                            <FWTypography
                                                sx={{
                                                    color: 'text.secondary',
                                                }}
                                            >
                                                Telephone
                                            </FWTypography>
                                            <FWTypography>
                                                <CommaSeparatedLinks
                                                    value={
                                                        vendorDetails.telephone
                                                    }
                                                    type="tel"
                                                    noLink
                                                />
                                            </FWTypography>
                                        </Grid>
                                    )}
                                </>
                            )}

                            <Grid item xs={12} marginTop="15px">
                                <FWTypography
                                    sx={{
                                        fontSize: '23px',
                                        marginBottom: '10px',
                                    }}
                                >
                                    Directory details
                                </FWTypography>

                                <Grid container columnSpacing={2}>
                                    <Grid item xs={6} marginBottom="15px">
                                        <FWInput
                                            label="Vendor code"
                                            value={vendorDetails.vendorCode}
                                            onChange={(e) => {
                                                validateInfo(e);
                                                updateVendorDetails({
                                                    type: VendorDetailActions.VENDOR_CODE,
                                                    value: e.target.value,
                                                });
                                            }}
                                            name={
                                                VendorDetailActions.VENDOR_CODE
                                            }
                                            onBlur={handleFieldBlur}
                                            error={
                                                vendorInfoErrors[
                                                    VendorDetailActions
                                                        .VENDOR_CODE
                                                ]?.error ||
                                                usedVendorCodes.includes(
                                                    vendorDetails.vendorCode
                                                )
                                            }
                                            helper={{
                                                text: vendorInfoErrors[
                                                    VendorDetailActions
                                                        .VENDOR_CODE
                                                ]?.error
                                                    ? 'Please enter a vendor code'
                                                    : usedVendorCodes.includes(
                                                          vendorDetails.vendorCode
                                                      )
                                                    ? 'Duplicate vendor code'
                                                    : '',
                                                textColor: 'error',
                                            }}
                                            required
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <TagsInput
                                            label="Vendor Tags"
                                            options={globalVendorTags}
                                            value={vendorDetails.tags}
                                            updateData={(eventValue) => {
                                                updateVendorDetails({
                                                    type: VendorDetailActions.VENDOR_TAGS,
                                                    value: eventValue,
                                                });
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={12} marginTop={2}>
                                        <FWTypography
                                            fontWeight={500}
                                            // color={'text.secondary'}
                                        >
                                            Addresses (optional)
                                        </FWTypography>
                                        {vendorDetails.seller_address_information?.map(
                                            (sellerAddressInformation, idx) => (
                                                <Grid
                                                    container
                                                    marginBottom={'15px'}
                                                >
                                                    <Grid item xs={10}>
                                                        <FWInput
                                                            key={idx}
                                                            sx={{
                                                                width: '100%',
                                                            }}
                                                            name={
                                                                VendorDetailActions.SELLER_ADDRESS_INFORMATION
                                                            }
                                                            value={
                                                                sellerAddressInformation
                                                            }
                                                            onChange={(e) => {
                                                                validateInfo(e);
                                                                updateVendorDetails(
                                                                    {
                                                                        type: VendorDetailActions.SELLER_ADDRESS_INFORMATION,
                                                                        value: e
                                                                            .target
                                                                            .value,
                                                                        index: idx,
                                                                    }
                                                                );
                                                            }}
                                                        />
                                                    </Grid>
                                                    <Grid
                                                        item
                                                        xs={2}
                                                        paddingLeft={2}
                                                        alignSelf={'center'}
                                                    >
                                                        <IconButton
                                                            color="error"
                                                            disabled={idx === 0}
                                                            onClick={() => {
                                                                updateVendorDetails(
                                                                    {
                                                                        type: VendorDetailActions.SELLER_ADDRESS_INFORMATION_REMOVE,
                                                                        index: idx,
                                                                    }
                                                                );
                                                            }}
                                                            size="small"
                                                        >
                                                            <i className="bi bi-dash-circle"></i>
                                                        </IconButton>
                                                        &nbsp;
                                                        {idx ===
                                                            vendorDetails
                                                                ?.seller_address_information
                                                                ?.length ||
                                                            (0 - 1 && (
                                                                <IconButton
                                                                    color="primary"
                                                                    onClick={() => {
                                                                        updateVendorDetails(
                                                                            {
                                                                                type: VendorDetailActions.SELLER_ADDRESS_INFORMATION_ADD,
                                                                            }
                                                                        );
                                                                    }}
                                                                    size="small"
                                                                >
                                                                    <i className="bi bi-plus-circle"></i>
                                                                </IconButton>
                                                            ))}
                                                    </Grid>
                                                </Grid>
                                            )
                                        )}
                                        {vendorAddress
                                            ?.filter(
                                                (vendorAdd) =>
                                                    vendorAdd.is_public &&
                                                    vendorAdd.status ===
                                                        'ACTIVE'
                                            )
                                            ?.map((address) => (
                                                <Grid
                                                    display={'flex'}
                                                    gap={0.5}
                                                >
                                                    <Typography>
                                                        <Box
                                                            color="secondary.main"
                                                            display="inline"
                                                        >
                                                            {
                                                                address.address
                                                                    .address_nickname
                                                            }
                                                            :{' '}
                                                        </Box>
                                                        {`${address.address.full_address}`}
                                                        {/* {`${
                                                            address.address
                                                                .address1
                                                                ? address
                                                                      .address
                                                                      .address1 +
                                                                  ', '
                                                                : ''
                                                        }${
                                                            address.address
                                                                .address2
                                                                ? address
                                                                      .address
                                                                      .address2 +
                                                                  ', '
                                                                : ''
                                                        }${
                                                            address.address
                                                                .address3
                                                                ? address
                                                                      .address
                                                                      .address3 +
                                                                  ', '
                                                                : ''
                                                        }${
                                                            address.address.city
                                                        }, ${
                                                            address.address
                                                                .state_or_territory
                                                        } ${
                                                            address.address
                                                                .postal_code
                                                        }`} */}
                                                    </Typography>
                                                    <FWTooltip
                                                        title="Vendor defined"
                                                        color="text.primary"
                                                    >
                                                        <Box color="primary.main">
                                                            <i className="bi bi-person-check"></i>
                                                        </Box>
                                                    </FWTooltip>
                                                </Grid>
                                            ))}
                                    </Grid>
                                    <Grid
                                        item
                                        xs={12}
                                        marginTop={2}
                                        marginBottom={1}
                                    >
                                        <FWTypography fontWeight={500}>
                                            Vendor identification
                                        </FWTypography>
                                    </Grid>

                                    {vendorDetails?.seller_identifications &&
                                        vendorDetails?.seller_identifications.map(
                                            (
                                                {
                                                    identification_name,
                                                    identification_value,
                                                },
                                                idx
                                            ) => (
                                                <VendorIdentifications
                                                    data={
                                                        vendorDetails?.seller_identifications
                                                    }
                                                    isLastItem={
                                                        idx ===
                                                        (vendorDetails
                                                            ?.seller_identifications
                                                            ?.length || 0) -
                                                            1
                                                    }
                                                    identification_name={
                                                        identification_name
                                                    }
                                                    identification_value={
                                                        identification_value
                                                    }
                                                    key={idx}
                                                    updateVendorDetails={
                                                        updateVendorDetails
                                                    }
                                                    index={idx}
                                                    identificationsLength={
                                                        vendorDetails
                                                            ?.seller_identifications
                                                            ?.length || 0
                                                    }
                                                    validateInfo={validateInfo}
                                                    vendorDetails={
                                                        vendorDetails
                                                    }
                                                    vendorInfoErrors={
                                                        vendorInfoErrors
                                                    }
                                                />
                                            )
                                        )}
                                    <Grid
                                        container
                                        paddingTop={2}
                                        style={{
                                            paddingLeft: '15px',
                                            display: 'flex',
                                            flexDirection: 'column',
                                        }}
                                    >
                                        {vendorIdentifications &&
                                            vendorIdentifications.map(
                                                (
                                                    {
                                                        identification_name,
                                                        identification_value,
                                                    },
                                                    idx
                                                ) => (
                                                    <Grid
                                                        display={'flex'}
                                                        gap={0.5}
                                                    >
                                                        <FWTypography
                                                            color={
                                                                'text.secondary'
                                                            }
                                                        >
                                                            {
                                                                identification_name
                                                            }
                                                            :
                                                        </FWTypography>
                                                        <FWTypography>
                                                            {
                                                                identification_value
                                                            }
                                                        </FWTypography>
                                                        <FWTooltip
                                                            title="Vendor defined"
                                                            color="text.primary"
                                                        >
                                                            <Box color="primary.main">
                                                                <i className="bi bi-person-check"></i>
                                                            </Box>
                                                        </FWTooltip>
                                                    </Grid>
                                                )
                                            )}
                                    </Grid>
                                    {/* <Grid item xs={6}>
                                        <FWInput
                                            label={'Identification name'}
                                            name={
                                                VendorDetailActions.IDENTIFICATION_NAME
                                            }
                                            value={
                                                vendorDetails
                                                    ?.seller_identifications?.[0]
                                                    ?.identification_name
                                            }
                                            onChange={(e) => {
                                                validateInfo(e);
                                                updateVendorDetails({
                                                    type: VendorDetailActions.IDENTIFICATION_NAME,
                                                    value: e.target.value,
                                                });
                                            }}
                                            // onBlur={handleFieldBlur}
                                            error={
                                                vendorInfoErrors[
                                                    VendorDetailActions
                                                        .IDENTIFICATION_NAME
                                                ]?.error
                                            }
                                            helper={{
                                                text: vendorInfoErrors[
                                                    VendorDetailActions
                                                        .IDENTIFICATION_NAME
                                                ]?.error
                                                    ? 'Please enter a identification name'
                                                    : '',
                                                textColor: 'error',
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <FWInput
                                            label={'Identification value'}
                                            name={
                                                VendorDetailActions.IDENTIFICATION_VALUE
                                            }
                                            value={
                                                vendorDetails
                                                    ?.seller_identifications?.[0]
                                                    ?.identification_value
                                            }
                                            onChange={(e) => {
                                                validateInfo(e);
                                                updateVendorDetails({
                                                    type: VendorDetailActions.IDENTIFICATION_VALUE,
                                                    value: e.target.value,
                                                });
                                            }}
                                            // onBlur={handleFieldBlur}
                                            error={
                                                vendorInfoErrors[
                                                    VendorDetailActions
                                                        .IDENTIFICATION_VALUE
                                                ]?.error
                                            }
                                            helper={{
                                                text: vendorInfoErrors[
                                                    VendorDetailActions
                                                        .IDENTIFICATION_VALUE
                                                ]?.error
                                                    ? 'Please enter a identification value'
                                                    : '',
                                                textColor: 'error',
                                            }}
                                        />
                                    </Grid> */}
                                    <Grid item xs={12} marginTop={1}></Grid>
                                    <Grid item xs={12} marginTop={1}>
                                        <FWInput
                                            label="Notes (optional)"
                                            value={vendorDetails.notes}
                                            multiline
                                            onChange={(e) => {
                                                updateVendorDetails({
                                                    type: VendorDetailActions.VENDOR_NOTES,
                                                    value: e.target.value,
                                                });
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={12} marginTop={1}></Grid>

                                    {!SINGLE_ENTITY && (
                                        <Grid item xs={12} marginY={1}>
                                            <FWTypography color="text.secondary">
                                                Assign to Entities:
                                            </FWTypography>
                                            <FormGroup
                                                sx={{
                                                    '.MuiFormControlLabel-root':
                                                        {
                                                            width: 'max-content',
                                                        },
                                                }}
                                            >
                                                {
                                                    <FormControlLabel
                                                        label="Select all"
                                                        control={
                                                            <Checkbox
                                                                disableRipple
                                                                disableFocusRipple
                                                                disableTouchRipple
                                                            ></Checkbox>
                                                        }
                                                        onChange={(e: any) => {
                                                            if (
                                                                e.target.checked
                                                            ) {
                                                                entities
                                                                    .filter(
                                                                        (
                                                                            entity
                                                                        ) =>
                                                                            !vendorDetails.entities.includes(
                                                                                entity.entityId
                                                                            )
                                                                    )
                                                                    .map(
                                                                        (
                                                                            entity
                                                                        ) =>
                                                                            updateVendorDetails(
                                                                                {
                                                                                    type: VendorDetailActions.ENTITY_ADD,
                                                                                    value: entity.entityId,
                                                                                }
                                                                            )
                                                                    );
                                                            } else {
                                                                entities
                                                                    .filter(
                                                                        (
                                                                            entity
                                                                        ) =>
                                                                            entity.entityId !==
                                                                            authData
                                                                                .details
                                                                                ?.defaults
                                                                                ?.primary_entity_id
                                                                    )
                                                                    .map(
                                                                        (
                                                                            entity
                                                                        ) =>
                                                                            updateVendorDetails(
                                                                                {
                                                                                    type: VendorDetailActions.ENTITY_REMOVE,
                                                                                    value: entity.entityId,
                                                                                }
                                                                            )
                                                                    );
                                                            }
                                                        }}
                                                        checked={
                                                            vendorDetails
                                                                .entities
                                                                .length ===
                                                            entities.length
                                                        }
                                                    ></FormControlLabel>
                                                }

                                                {entities.map((entity) => {
                                                    const disableCheckbox =
                                                        fixedVendorDetails &&
                                                        fixedVendorDetails.entities.includes(
                                                            entity.entityId
                                                        )
                                                            ? true
                                                            : false;
                                                    return (
                                                        <FormControlLabel
                                                            disabled={
                                                                SINGLE_ENTITY
                                                                // ||
                                                                // isEventPage
                                                            }
                                                            key={
                                                                entity.entityId
                                                            }
                                                            control={
                                                                <Checkbox
                                                                    disableRipple
                                                                    disableFocusRipple
                                                                    disableTouchRipple
                                                                    disabled={
                                                                        disableCheckbox
                                                                    }
                                                                    color={
                                                                        disableCheckbox
                                                                            ? 'default'
                                                                            : 'primary'
                                                                    }
                                                                />
                                                            }
                                                            label={
                                                                entity.entityName
                                                            }
                                                            onChange={(
                                                                e: any
                                                            ) => {
                                                                if (
                                                                    e.target
                                                                        .checked
                                                                ) {
                                                                    updateVendorDetails(
                                                                        {
                                                                            type: VendorDetailActions.ENTITY_ADD,
                                                                            value: entity.entityId,
                                                                        }
                                                                    );
                                                                } else if (
                                                                    vendorDetails
                                                                        .entities
                                                                        .length >
                                                                    1
                                                                ) {
                                                                    updateVendorDetails(
                                                                        {
                                                                            type: VendorDetailActions.ENTITY_REMOVE,
                                                                            value: entity.entityId,
                                                                        }
                                                                    );
                                                                }
                                                            }}
                                                            checked={vendorDetails.entities.includes(
                                                                entity.entityId
                                                            )}
                                                        />
                                                    );
                                                })}
                                            </FormGroup>
                                        </Grid>
                                    )}
                                </Grid>
                            </Grid>

                            <Grid item xs={12} marginTop="15px">
                                <div className="flex flex--aic mb--10">
                                    <FWTypography
                                        sx={{
                                            fontSize: '23px',
                                            mr: '10px',
                                        }}
                                    >
                                        {/* // TODO: when actual POC is integrated enable this condition */}
                                        {/* {vendorDetails.isCustom
                                            ? 'Contact(s)'
                                            : `${vendorDetails.vendorName} sales POC`} */}
                                        Contact{/* (s) */}
                                    </FWTypography>
                                    {/* // TODO: when actual POC is integrated enable this condition */}
                                    {/* {vendorDetails.isCustom && ( */}
                                    {/* <IconButton
                                        color="primary"
                                        onClick={() => {
                                            updateVendorDetails({
                                                type: VendorDetailActions.USER_CONTACT_ADD,
                                            });
                                        }}
                                        disableRipple
                                    >
                                        <FWIcon
                                            name="bi bi-plus-circle"
                                            size={20}
                                        />
                                    </IconButton> */}
                                    {/* <Grid item xs={1} marginTop={'auto'}> */}
                                    <FWTooltip
                                        title="Add  contact"
                                        className="addIcon"
                                    >
                                        <div>
                                            <IconButton
                                                onClick={() => {
                                                    updateVendorDetails({
                                                        type: VendorDetailActions.USER_CONTACT_ADD,
                                                    });
                                                }}
                                            >
                                                <FWIcon
                                                    color="primary.main"
                                                    name="bi bi-plus-circle"
                                                />
                                            </IconButton>
                                        </div>
                                    </FWTooltip>
                                    {/* </Grid> */}
                                    {/* )} */}
                                </div>

                                {vendorDetails.primaryContacts
                                    .filter((contact) => !contact.parent)
                                    .map((contact, index) => {
                                        return (
                                            <Fragment key={index}>
                                                <Grid display={'flex'}>
                                                    <Grid
                                                        container
                                                        columnSpacing={2}
                                                        rowGap={2}
                                                        flexWrap="nowrap"
                                                        marginBottom="15px"
                                                        alignItems="flex-start"
                                                    >
                                                        {contact.isPresentInFW && (
                                                            <Grid
                                                                item
                                                                xs="auto"
                                                            >
                                                                <FWTypography color="text.secondary">
                                                                    Invite
                                                                </FWTypography>
                                                                <Checkbox
                                                                    checked={
                                                                        contact.selected
                                                                    }
                                                                    onChange={() => {
                                                                        updateVendorDetails(
                                                                            {
                                                                                type: VendorDetailActions.USER_CONTACT_UPDATE,
                                                                                field: VendorDetailActions.USER_INVITE_DEFAULT,
                                                                                uidIndex:
                                                                                    contact.uid,
                                                                            }
                                                                        );
                                                                    }}
                                                                    sx={{
                                                                        padding:
                                                                            '4px',
                                                                    }}
                                                                    disableRipple
                                                                    disableFocusRipple
                                                                    disableTouchRipple
                                                                />
                                                            </Grid>
                                                        )}
                                                        {/* // TODO: when actual POC is integrated enable this box */}
                                                        {/* {!contact.isPresentInFW &&
                                                        !vendorDetails.isCustom && (
                                                            <Grid
                                                                item
                                                                xs="auto"
                                                                minWidth="57px"
                                                            ></Grid>
                                                        )} */}
                                                        <Grid item xs="auto">
                                                            <FWTypography color="text.secondary">
                                                                Default
                                                            </FWTypography>
                                                            <Radio
                                                                checked={
                                                                    contact.default
                                                                }
                                                                onChange={() => {
                                                                    updateVendorDetails(
                                                                        {
                                                                            type: VendorDetailActions.USER_CONTACT_UPDATE,
                                                                            field: VendorDetailActions.USER_DEFAULT,
                                                                            uidIndex:
                                                                                contact.uid,
                                                                        }
                                                                    );
                                                                }}
                                                                sx={{
                                                                    padding:
                                                                        '4px',
                                                                }}
                                                                disabled={
                                                                    !contact.selected &&
                                                                    contact.isPresentInFW
                                                                }
                                                                disableRipple
                                                                disableFocusRipple
                                                                disableTouchRipple
                                                            />
                                                        </Grid>
                                                        <Grid item xs={12}>
                                                            <FWAccordion

                                                            // onChange={(
                                                            //     e,
                                                            //     expanded
                                                            // ) =>
                                                            //     setAccordionOpen(
                                                            //         (prev) => ({
                                                            //             ...prev,
                                                            //             [contact.uid]:
                                                            //                 expanded,
                                                            //         })
                                                            //     )
                                                            // }
                                                            >
                                                                <StyledFWAccordionSummary
                                                                    style={{
                                                                        backgroundColor:
                                                                            'white',
                                                                    }}
                                                                >
                                                                    <Grid
                                                                        container
                                                                        xs={12}
                                                                        columnGap={
                                                                            1
                                                                        }
                                                                        flexWrap={
                                                                            'nowrap'
                                                                        }
                                                                    >
                                                                        <Grid
                                                                            item
                                                                            xs={
                                                                                3
                                                                            }
                                                                        >
                                                                            {contact.isPresentInFW ? (
                                                                                <FWGridKeyValue
                                                                                    title="Full name"
                                                                                    value={
                                                                                        contact.fullName ||
                                                                                        '-'
                                                                                    }
                                                                                    xs={
                                                                                        12
                                                                                    }
                                                                                    lg={
                                                                                        12
                                                                                    }
                                                                                />
                                                                            ) : (
                                                                                <FWInput
                                                                                    label="Full name"
                                                                                    value={
                                                                                        contact.fullName
                                                                                    }
                                                                                    onClick={(
                                                                                        e: any
                                                                                    ) => {
                                                                                        e.stopPropagation();
                                                                                    }}
                                                                                    onChange={(
                                                                                        e
                                                                                    ) => {
                                                                                        validateInfo(
                                                                                            e
                                                                                        );
                                                                                        updateVendorDetails(
                                                                                            {
                                                                                                type: VendorDetailActions.USER_CONTACT_UPDATE,
                                                                                                field: VendorDetailActions.USER_NAME,
                                                                                                uidIndex:
                                                                                                    contact.uid,
                                                                                                value: e
                                                                                                    .target
                                                                                                    .value,
                                                                                            }
                                                                                        );
                                                                                    }}
                                                                                    // allowOnly="TEXT"
                                                                                    disabled={
                                                                                        contact.isPresentInFW
                                                                                    }
                                                                                    required
                                                                                    name={
                                                                                        VendorDetailActions.USER_NAME +
                                                                                        contact.uid
                                                                                    }
                                                                                    error={
                                                                                        vendorInfoErrors[
                                                                                            VendorDetailActions.USER_NAME +
                                                                                                contact.uid
                                                                                        ]
                                                                                            ?.error
                                                                                    }
                                                                                    helper={{
                                                                                        text: vendorInfoErrors[
                                                                                            VendorDetailActions.USER_NAME +
                                                                                                contact.uid
                                                                                        ]
                                                                                            ?.message,
                                                                                    }}
                                                                                />
                                                                            )}
                                                                        </Grid>
                                                                        <Grid
                                                                            item
                                                                            xs={
                                                                                3
                                                                            }
                                                                        >
                                                                            {contact.isPresentInFW ? (
                                                                                <FWGridKeyValue
                                                                                    title="Email"
                                                                                    value={
                                                                                        contact.email ||
                                                                                        '-'
                                                                                    }
                                                                                    xs={
                                                                                        12
                                                                                    }
                                                                                    lg={
                                                                                        12
                                                                                    }
                                                                                />
                                                                            ) : (
                                                                                <FWInput
                                                                                    label="Email"
                                                                                    name={
                                                                                        VendorDetailActions.USER_EMAIL +
                                                                                        contact.uid
                                                                                    }
                                                                                    value={
                                                                                        contact.email
                                                                                    }
                                                                                    onClick={(
                                                                                        e: any
                                                                                    ) => {
                                                                                        e.stopPropagation();
                                                                                    }}
                                                                                    onChange={(
                                                                                        e
                                                                                    ) => {
                                                                                        validateInfo(
                                                                                            e
                                                                                        );
                                                                                        updateVendorDetails(
                                                                                            {
                                                                                                type: VendorDetailActions.USER_CONTACT_UPDATE,
                                                                                                field: VendorDetailActions.USER_EMAIL,
                                                                                                uidIndex:
                                                                                                    contact.uid,
                                                                                                value: e
                                                                                                    .target
                                                                                                    .value,
                                                                                            }
                                                                                        );
                                                                                    }}
                                                                                    disabled={
                                                                                        contact.isPresentInFW
                                                                                    }
                                                                                    required
                                                                                    error={
                                                                                        vendorInfoErrors[
                                                                                            VendorDetailActions.USER_EMAIL +
                                                                                                contact.uid
                                                                                        ]
                                                                                            ?.error
                                                                                    }
                                                                                    helper={{
                                                                                        text: vendorInfoErrors[
                                                                                            VendorDetailActions.USER_EMAIL +
                                                                                                contact.uid
                                                                                        ]
                                                                                            ?.message,
                                                                                    }}
                                                                                />
                                                                            )}
                                                                        </Grid>
                                                                        <Grid
                                                                            item
                                                                            xs={
                                                                                3
                                                                            }
                                                                        >
                                                                            {contact.isPresentInFW ? (
                                                                                <FWGridKeyValue
                                                                                    title="Phone"
                                                                                    value={
                                                                                        contact.contactNumber ||
                                                                                        '-'
                                                                                    }
                                                                                    xs={
                                                                                        12
                                                                                    }
                                                                                    lg={
                                                                                        12
                                                                                    }
                                                                                />
                                                                            ) : (
                                                                                <FWInput
                                                                                    label="Phone (optional)"
                                                                                    value={
                                                                                        contact.contactNumber
                                                                                    }
                                                                                    onClick={(
                                                                                        e: any
                                                                                    ) => {
                                                                                        e.stopPropagation();
                                                                                    }}
                                                                                    allowOnly="TEL"
                                                                                    onChange={(
                                                                                        e
                                                                                    ) => {
                                                                                        validateInfo(
                                                                                            e
                                                                                        );
                                                                                        updateVendorDetails(
                                                                                            {
                                                                                                type: VendorDetailActions.USER_CONTACT_UPDATE,
                                                                                                field: VendorDetailActions.USER_NUMBER,
                                                                                                uidIndex:
                                                                                                    contact.uid,
                                                                                                value: e
                                                                                                    .target
                                                                                                    .value,
                                                                                            }
                                                                                        );
                                                                                    }}
                                                                                    disabled={
                                                                                        contact.isPresentInFW
                                                                                    }
                                                                                    name={
                                                                                        VendorDetailActions.USER_NUMBER +
                                                                                        contact.uid
                                                                                    }
                                                                                    error={
                                                                                        vendorInfoErrors[
                                                                                            VendorDetailActions.USER_NUMBER +
                                                                                                contact.uid
                                                                                        ]
                                                                                            ?.error
                                                                                    }
                                                                                    helper={{
                                                                                        text: vendorInfoErrors[
                                                                                            VendorDetailActions.USER_NUMBER +
                                                                                                contact.uid
                                                                                        ]
                                                                                            ?.message,
                                                                                    }}
                                                                                />
                                                                            )}
                                                                        </Grid>
                                                                        <Grid
                                                                            item
                                                                            xs={
                                                                                3
                                                                            }
                                                                        >
                                                                            <FWInput
                                                                                onClick={(
                                                                                    e: any
                                                                                ) => {
                                                                                    e.stopPropagation();
                                                                                }}
                                                                                label="Notes (optional)"
                                                                                value={
                                                                                    contact.notes
                                                                                }
                                                                                onChange={(
                                                                                    e
                                                                                ) => {
                                                                                    updateVendorDetails(
                                                                                        {
                                                                                            type: VendorDetailActions.USER_CONTACT_UPDATE,
                                                                                            field: VendorDetailActions.USER_NOTES,
                                                                                            uidIndex:
                                                                                                contact.uid,
                                                                                            value: e
                                                                                                .target
                                                                                                .value,
                                                                                        }
                                                                                    );
                                                                                }}
                                                                            />
                                                                            {/* {!contact.isPresentInFW ? (
                                                        ) : (
                                                            <FWInput
                                                                label="Internal name (optional)"
                                                                value={
                                                                    contact.internalName
                                                                }
                                                                onChange={(
                                                                    e
                                                                ) => {
                                                                    updateVendorDetails(
                                                                        {
                                                                            type: VendorDetailActions.USER_CONTACT_UPDATE,
                                                                            field: VendorDetailActions.USER_INTERNAL_NAME,
                                                                            index,
                                                                            value: e
                                                                                .target
                                                                                .value,
                                                                        }
                                                                    );
                                                                }}
                                                            />
                                                        )} */}
                                                                        </Grid>
                                                                    </Grid>
                                                                </StyledFWAccordionSummary>
                                                                <FWAccordionDetails>
                                                                    <Grid
                                                                        container
                                                                    >
                                                                        <Grid
                                                                            item
                                                                        >
                                                                            <Grid
                                                                                container
                                                                                xs={
                                                                                    12
                                                                                }
                                                                            >
                                                                                <FWTypography
                                                                                    color={
                                                                                        'secondary.main'
                                                                                    }
                                                                                    alignSelf={
                                                                                        'center'
                                                                                    }
                                                                                >
                                                                                    Other
                                                                                    emails
                                                                                </FWTypography>
                                                                                {vendorDetails.primaryContacts.filter(
                                                                                    (
                                                                                        c
                                                                                    ) =>
                                                                                        c.parent ===
                                                                                        contact.uid
                                                                                )
                                                                                    .length ===
                                                                                    0 && (
                                                                                    <FWTooltip
                                                                                        title="Add secondary Email"
                                                                                        className="addIcon"
                                                                                    >
                                                                                        <div>
                                                                                            <IconButton
                                                                                                onClick={() => {
                                                                                                    updateVendorDetails(
                                                                                                        {
                                                                                                            type: VendorDetailActions.USER_SECONDARY_CONTACT_ADD,
                                                                                                            value: contact.uid,
                                                                                                        }
                                                                                                    );
                                                                                                }}
                                                                                            >
                                                                                                <FWIcon
                                                                                                    color="primary.main"
                                                                                                    name="bi bi-plus-circle"
                                                                                                />
                                                                                            </IconButton>
                                                                                        </div>
                                                                                    </FWTooltip>
                                                                                )}
                                                                            </Grid>
                                                                            <Grid
                                                                                item
                                                                            >
                                                                                <Grid
                                                                                    container
                                                                                    rowGap={
                                                                                        1
                                                                                    }
                                                                                    columnGap={
                                                                                        1
                                                                                    }
                                                                                    alignItems={
                                                                                        'center'
                                                                                    }
                                                                                    justifyItems={
                                                                                        'center'
                                                                                    }
                                                                                >
                                                                                    {vendorDetails.primaryContacts
                                                                                        .filter(
                                                                                            (
                                                                                                c
                                                                                            ) =>
                                                                                                c.parent ===
                                                                                                contact.uid
                                                                                        )
                                                                                        .map(
                                                                                            (
                                                                                                secondaryContact,
                                                                                                idx
                                                                                            ) => (
                                                                                                <Grid
                                                                                                    item
                                                                                                    padding={
                                                                                                        0.25
                                                                                                    }
                                                                                                >
                                                                                                    <Grid
                                                                                                        container
                                                                                                    >
                                                                                                        {/* email input field */}
                                                                                                        <Grid
                                                                                                            item
                                                                                                        >
                                                                                                            <FWInput
                                                                                                                sx={{
                                                                                                                    maxWidth:
                                                                                                                        '300px',
                                                                                                                }}
                                                                                                                onClick={(
                                                                                                                    e: any
                                                                                                                ) => {
                                                                                                                    e.stopPropagation();
                                                                                                                }}
                                                                                                                value={
                                                                                                                    secondaryContact.email
                                                                                                                }
                                                                                                                name={
                                                                                                                    VendorDetailActions.USER_EMAIL +
                                                                                                                    secondaryContact.uid
                                                                                                                }
                                                                                                                onChange={(
                                                                                                                    e
                                                                                                                ) => {
                                                                                                                    validateInfo(
                                                                                                                        e
                                                                                                                    );
                                                                                                                    updateVendorDetails(
                                                                                                                        {
                                                                                                                            type: VendorDetailActions.USER_CONTACT_UPDATE,
                                                                                                                            field: VendorDetailActions.USER_EMAIL,
                                                                                                                            uidIndex:
                                                                                                                                secondaryContact.uid,
                                                                                                                            value: e
                                                                                                                                .target
                                                                                                                                .value,
                                                                                                                        }
                                                                                                                    );
                                                                                                                }}
                                                                                                                placeholder="email"
                                                                                                                error={
                                                                                                                    vendorInfoErrors[
                                                                                                                        VendorDetailActions.USER_EMAIL +
                                                                                                                            secondaryContact.uid
                                                                                                                    ]
                                                                                                                        ?.error
                                                                                                                }
                                                                                                                helper={{
                                                                                                                    text:
                                                                                                                        (vendorInfoErrors[
                                                                                                                            VendorDetailActions.USER_EMAIL +
                                                                                                                                secondaryContact.uid
                                                                                                                        ]
                                                                                                                            ?.message ??
                                                                                                                            `
                                                                                                                        `) +
                                                                                                                        '\n' +
                                                                                                                        (secondaryContact.isCCEmail ===
                                                                                                                            false &&
                                                                                                                        secondaryContact.isSecondaryEmail ===
                                                                                                                            false
                                                                                                                            ? '\nPlease select at least one option before proceeding.'
                                                                                                                            : ''),
                                                                                                                }}
                                                                                                            />
                                                                                                        </Grid>

                                                                                                        {/* secondary and cc email container */}
                                                                                                        <Grid
                                                                                                            item
                                                                                                        >
                                                                                                            <Grid
                                                                                                                container
                                                                                                                flexDirection={
                                                                                                                    'column'
                                                                                                                }
                                                                                                                alignItems={
                                                                                                                    'center'
                                                                                                                }
                                                                                                                marginLeft={
                                                                                                                    0.5
                                                                                                                }
                                                                                                            >
                                                                                                                <Grid
                                                                                                                    item
                                                                                                                >
                                                                                                                    <Grid
                                                                                                                        container
                                                                                                                    >
                                                                                                                        <Grid
                                                                                                                            item
                                                                                                                        >
                                                                                                                            <Grid
                                                                                                                                container
                                                                                                                                alignItems={
                                                                                                                                    'center'
                                                                                                                                }
                                                                                                                                justifyItems={
                                                                                                                                    'center'
                                                                                                                                }
                                                                                                                            >
                                                                                                                                <Grid
                                                                                                                                    item
                                                                                                                                >
                                                                                                                                    <Checkbox
                                                                                                                                        defaultChecked={
                                                                                                                                            secondaryContact.isCCEmail
                                                                                                                                        }
                                                                                                                                        checked={
                                                                                                                                            secondaryContact.isCCEmail
                                                                                                                                        }
                                                                                                                                        onChange={(
                                                                                                                                            e
                                                                                                                                        ) => {
                                                                                                                                            updateVendorDetails(
                                                                                                                                                {
                                                                                                                                                    type: VendorDetailActions.USER_CONTACT_UPDATE,
                                                                                                                                                    field: VendorDetailActions.USER_TOGGLE_CC_EMAIL,
                                                                                                                                                    uidIndex:
                                                                                                                                                        secondaryContact.uid,
                                                                                                                                                    value: e
                                                                                                                                                        .target
                                                                                                                                                        .checked,
                                                                                                                                                }
                                                                                                                                            );
                                                                                                                                        }}
                                                                                                                                    />
                                                                                                                                </Grid>
                                                                                                                                <Grid
                                                                                                                                    item
                                                                                                                                >
                                                                                                                                    <FWTypography>
                                                                                                                                        CC
                                                                                                                                        email
                                                                                                                                    </FWTypography>
                                                                                                                                </Grid>
                                                                                                                            </Grid>
                                                                                                                        </Grid>

                                                                                                                        <Grid
                                                                                                                            item
                                                                                                                        >
                                                                                                                            <Grid
                                                                                                                                container
                                                                                                                                alignItems={
                                                                                                                                    'center'
                                                                                                                                }
                                                                                                                                justifyItems={
                                                                                                                                    'center'
                                                                                                                                }
                                                                                                                            >
                                                                                                                                <Grid
                                                                                                                                    item
                                                                                                                                >
                                                                                                                                    <Checkbox
                                                                                                                                        defaultChecked={
                                                                                                                                            secondaryContact.isSecondaryEmail
                                                                                                                                        }
                                                                                                                                        checked={
                                                                                                                                            secondaryContact.isSecondaryEmail
                                                                                                                                        }
                                                                                                                                        onChange={(
                                                                                                                                            e
                                                                                                                                        ) => {
                                                                                                                                            updateVendorDetails(
                                                                                                                                                {
                                                                                                                                                    type: VendorDetailActions.USER_CONTACT_UPDATE,
                                                                                                                                                    field: VendorDetailActions.USER_TOGGLE_SECONDARY_EMAIL,
                                                                                                                                                    uidIndex:
                                                                                                                                                        secondaryContact.uid,
                                                                                                                                                    value: e
                                                                                                                                                        .target
                                                                                                                                                        .checked,
                                                                                                                                                }
                                                                                                                                            );
                                                                                                                                        }}
                                                                                                                                    />
                                                                                                                                </Grid>
                                                                                                                                <Grid
                                                                                                                                    item
                                                                                                                                >
                                                                                                                                    <FWTypography
                                                                                                                                        marginRight={
                                                                                                                                            0.25
                                                                                                                                        }
                                                                                                                                    >
                                                                                                                                        Secondary
                                                                                                                                        email
                                                                                                                                    </FWTypography>
                                                                                                                                </Grid>
                                                                                                                            </Grid>
                                                                                                                        </Grid>
                                                                                                                    </Grid>
                                                                                                                </Grid>
                                                                                                            </Grid>
                                                                                                        </Grid>
                                                                                                        <Grid
                                                                                                            item
                                                                                                        >
                                                                                                            <Grid
                                                                                                                container
                                                                                                                alignItems={
                                                                                                                    'center'
                                                                                                                }
                                                                                                                justifyItems={
                                                                                                                    'center'
                                                                                                                }
                                                                                                            >
                                                                                                                <Grid
                                                                                                                    item
                                                                                                                >
                                                                                                                    <FWTooltip
                                                                                                                        title="Remove secondary contact"
                                                                                                                        className="removeIcon"
                                                                                                                    >
                                                                                                                        <div>
                                                                                                                            <IconButton
                                                                                                                                onClick={() => {
                                                                                                                                    updateVendorDetails(
                                                                                                                                        {
                                                                                                                                            type: VendorDetailActions.USER_CONTACT_REMOVE,
                                                                                                                                            uidIndex:
                                                                                                                                                secondaryContact.uid,
                                                                                                                                        }
                                                                                                                                    );
                                                                                                                                }}
                                                                                                                            >
                                                                                                                                <FWIcon
                                                                                                                                    color="error.main"
                                                                                                                                    name="bi bi-dash-circle"
                                                                                                                                />
                                                                                                                            </IconButton>
                                                                                                                        </div>
                                                                                                                    </FWTooltip>
                                                                                                                </Grid>
                                                                                                                {vendorDetails.primaryContacts.filter(
                                                                                                                    (
                                                                                                                        c
                                                                                                                    ) =>
                                                                                                                        c.parent ===
                                                                                                                        contact.uid
                                                                                                                )
                                                                                                                    .length -
                                                                                                                    1 ===
                                                                                                                    idx && (
                                                                                                                    <Grid
                                                                                                                        item
                                                                                                                    >
                                                                                                                        <FWTooltip
                                                                                                                            title="Add secondary contact"
                                                                                                                            className="addIcon"
                                                                                                                        >
                                                                                                                            <div>
                                                                                                                                <IconButton
                                                                                                                                    onClick={() => {
                                                                                                                                        updateVendorDetails(
                                                                                                                                            {
                                                                                                                                                type: VendorDetailActions.USER_SECONDARY_CONTACT_ADD,
                                                                                                                                                value: contact.uid,
                                                                                                                                            }
                                                                                                                                        );
                                                                                                                                    }}
                                                                                                                                >
                                                                                                                                    <FWIcon
                                                                                                                                        color="primary.main"
                                                                                                                                        name="bi bi-plus-circle"
                                                                                                                                    />
                                                                                                                                </IconButton>
                                                                                                                            </div>
                                                                                                                        </FWTooltip>
                                                                                                                    </Grid>
                                                                                                                )}
                                                                                                            </Grid>
                                                                                                        </Grid>
                                                                                                    </Grid>
                                                                                                </Grid>
                                                                                            )
                                                                                        )}
                                                                                </Grid>
                                                                            </Grid>
                                                                        </Grid>
                                                                    </Grid>
                                                                </FWAccordionDetails>
                                                            </FWAccordion>
                                                        </Grid>
                                                        {/* <Grid item xs="auto">
                                                        <IconButton
                                                            color="error"
                                                            onClick={() => {
                                                                updateVendorDetails(
                                                                    {
                                                                        type: VendorDetailActions.USER_CONTACT_REMOVE,
                                                                        index,
                                                                    }
                                                                );
                                                            }}
                                                            sx={{
                                                                mt: '24px',
                                                                visibility:
                                                                    contact.isPresentInFW
                                                                        ? 'hidden'
                                                                        : undefined,
                                                            }}
                                                            disableRipple
                                                            disabled={
                                                                contact.isPresentInFW
                                                            }
                                                        >
                                                            <FWIcon
                                                                name="bi bi-dash-circle"
                                                                size={20}
                                                            />
                                                        </IconButton>
                                                    </Grid> */}
                                                        <IconButton
                                                            sx={{
                                                                alignSelf:
                                                                    'center',
                                                                paddingLeft:
                                                                    '16px',
                                                            }}
                                                            color={'error'}
                                                            size={'small'}
                                                            onClick={() => {
                                                                updateVendorDetails(
                                                                    {
                                                                        type: VendorDetailActions.USER_CONTACT_REMOVE,
                                                                        uidIndex:
                                                                            contact.uid,
                                                                    }
                                                                );
                                                            }}
                                                            disabled={
                                                                contact.default
                                                                    ? true
                                                                    : false
                                                            }
                                                        >
                                                            <i className="bi bi-dash-circle" />
                                                        </IconButton>
                                                    </Grid>
                                                    {contact.isPresentInFW && (
                                                        <Grid
                                                            container
                                                            columnSpacing={2}
                                                            rowGap={2}
                                                            flexWrap="nowrap"
                                                            marginBottom="15px"
                                                            alignItems="flex-start"
                                                        >
                                                            <Grid
                                                                item
                                                                xs={12}
                                                                marginTop="15px"
                                                                display="flex"
                                                            >
                                                                <FWTypography
                                                                    sx={{
                                                                        fontSize:
                                                                            '23px',
                                                                        mr: '10px',
                                                                    }}
                                                                >
                                                                    Additional
                                                                    contact(s)
                                                                </FWTypography>
                                                                <IconButton
                                                                    color="primary"
                                                                    onClick={() => {
                                                                        updateVendorDetails(
                                                                            {
                                                                                type: VendorDetailActions.USER_CONTACT_ADD,
                                                                            }
                                                                        );
                                                                    }}
                                                                    disableRipple
                                                                >
                                                                    <FWIcon
                                                                        name="bi bi-plus-circle"
                                                                        size={
                                                                            20
                                                                        }
                                                                    />
                                                                </IconButton>
                                                            </Grid>
                                                        </Grid>
                                                    )}
                                                </Grid>
                                            </Fragment>
                                        );
                                    })}
                            </Grid>
                        </Grid>
                    )}
                </div>
            </DialogContent>
            <DialogActions style={{ padding: '15px 24px 24px' }}>
                {!isNull(vendorDetails) && (
                    <FWButton
                        variant="contained"
                        disabled={
                            !isValidDetails ||
                            usedVendorCodes.includes(
                                vendorDetails.vendorCode
                            ) ||
                            loading
                        }
                        onClick={handleAddVendorToDirectory}
                    >
                        {loading ? (
                            <CircularProgress
                                size={24}
                                style={{ color: '#c4c4c8' }}
                            />
                        ) : (
                            'Add vendor'
                        )}
                    </FWButton>
                )}
            </DialogActions>
        </FWPopupWithChildren>
    );
};

export default VendorAddPopup;
