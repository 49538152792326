import { Box, CircularProgress, Grid } from '@mui/material';
import React, {
    Dispatch,
    SetStateAction,
    useCallback,
    useEffect,
    useRef,
    useState,
} from 'react';
import { FWTypography } from '../../Common/FWTypograhy';
// import ExpandableMenuItem from '../../Global/Components/ExpandableMenuItem';
import { getFieldLevel } from '../Constants/FixedProperties';
import {
    TemplateItem,
    TemplateItemTypes,
    TemplateItemtypesKeys,
    // TemplateSuggestedFieldObject,
    templateType,
} from '../Interfaces/TemplatePageInterfaces';
// import { IEntityItemsResponse } from '../Interfaces/TemplateAPIData';

interface ITemplateSuggestedFieldsSectionProps {
    suggestedFields: TemplateItem[];
    addNewItem: (itemTemplateData: TemplateItem) => Promise<void>;
    templateType: templateType;
    sectionName: string;
    setCurrentPage: Dispatch<SetStateAction<number>>;
    hasMoreItems: boolean;
    isLoading: boolean;
    refetchFields: any;
}
export const capitaliseFirstLetter = (str: string) => {
    return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
};

export const removeUnderscore = (str: string) => {
    return str.split('_').join(' ');
};

const mapFieldType = (
    fieldType: TemplateItemTypes,
    choiceType: 'DEFAULT' | 'DROPDOWN' | 'MULTI_SELECT' = 'DEFAULT'
) => {
    switch (fieldType) {
        case TemplateItemtypesKeys.LONGTEXT:
            return 'Long Text';
        case TemplateItemtypesKeys.SHORTTEXT:
            return 'Text';
        case TemplateItemtypesKeys.CURRENCY:
            return 'Currency';
        case TemplateItemtypesKeys.NUMBER:
            return 'Number';
        case TemplateItemtypesKeys.CHOICE:
            return choiceType === 'DROPDOWN' ? 'Select' : 'Multi Select';
        case TemplateItemtypesKeys.MULTI_SELECT:
            return 'Multi Select';
        case TemplateItemtypesKeys.BOOLEAN:
            return 'Checkbox';
        case TemplateItemtypesKeys.LOCATION:
            return 'Location';
        case TemplateItemtypesKeys.DATE:
            return 'Date';
        case TemplateItemtypesKeys.DATETIME:
            return 'Date & Time';
        default:
            return 'Text';
    }
};

const fieldAdditionalInfo = (additionalDetails: {
    is_visible?: boolean;
    is_negotiable?: boolean;
}) => {
    if (additionalDetails.is_negotiable) {
        return <> &middot; Vendor can negotiable </>;
    } else if (additionalDetails.is_visible) {
        return <> &middot; Visible to vendor</>;
    } else if (!additionalDetails.is_visible) {
        return <> &middot; Hidden from vendor</>;
    } else {
        return '';
    }
};

const fieldMandatoryOrNonMandatoryInfo = (
    additionalDetails: {
        is_required?: boolean;
        is_factwise?: boolean;
        is_hidden?: boolean;
        is_visible?: boolean;
        is_negotiable?: boolean;
        is_mandatory?: boolean;
    },
    ifCollection: boolean,
    templateModule: string
) => {
    return additionalDetails.is_required ? (
        <>
            {' '}
            {<>&middot;</>}{' '}
            {['QUOTE CALCULATOR', 'PROJECT'].includes(templateModule)
                ? 'Mandatory'
                : 'Mandatory for Buyer'}
        </>
    ) : (
        <>
            {' '}
            {!ifCollection && <>&middot;</>}{' '}
            {['QUOTE CALCULATOR', 'PROJECT'].includes(templateModule)
                ? 'Not Mandatory'
                : 'Not Mandatory for Buyer'}{' '}
        </>
    );
};

const TemplateSuggestedFieldsSection = ({
    suggestedFields,
    addNewItem,
    sectionName,
    templateType,
    setCurrentPage,
    hasMoreItems,
    isLoading,
    refetchFields,
}: ITemplateSuggestedFieldsSectionProps) => {
    //STATES
    const [disableField, setDisableField] = useState<number>(-1);

    //OBSERVER REF AND CALLBACK
    const observer = useRef<IntersectionObserver | null>(null);
    const observerCallback = useCallback(
        (node) => {
            if (isLoading || !hasMoreItems) return;
            if (observer.current) observer.current.disconnect();
            observer.current = new IntersectionObserver((entries) => {
                if (entries[0].isIntersecting) {
                    setCurrentPage((prevPageNo) => prevPageNo + 1);
                }
            });
            if (node) observer.current.observe(node);
        },
        [hasMoreItems, isLoading, setCurrentPage]
    );

    //USEEFFECTS
    useEffect(() => {
        setDisableField(-1);
    }, []);
    console.log('suggestedFields', suggestedFields);
    return (
        <Box>
            <Grid
                container
                rowGap={2}
                maxHeight={'55vh'}
                overflow={'scroll'}
                paddingBottom={suggestedFields.length > 0 ? '10px' : '0px'}
                sx={{
                    '&::-webkit-scrollbar': {
                        width: '0.4em',
                    },
                    '&::-webkit-scrollbar-track': {
                        boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
                        webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
                    },
                    '&::-webkit-scrollbar-thumb': {
                        borderRadius: '10px',
                    },
                }}
            >
                {suggestedFields.length > 0 ? (
                    <>
                        {suggestedFields.map((itemDetails, index) => {
                            if (itemDetails.used_in_template) {
                                console.log('coming here', itemDetails.name);
                                return <></>;
                            }
                            return (
                                <Grid
                                    key={index}
                                    item
                                    xs={10}
                                    display="flex"
                                    alignItems={'center'}
                                    bgcolor={'#fff'}
                                    padding={'5px'}
                                    marginLeft={'10px'}
                                    boxShadow={
                                        '0px 2px 5px 0px rgba(0,0,0,0.2)'
                                    }
                                    paddingY={'5px'}
                                    borderRadius={'5px'}
                                    sx={{
                                        cursor: 'pointer',
                                        // padding: 0.5,
                                    }}
                                    onClick={async () => {
                                        try {
                                            if (disableField !== index) {
                                                addNewItem({
                                                    ...itemDetails,
                                                    is_variable:
                                                        itemDetails?.constraints
                                                            ?.choice_type ===
                                                        'MULTI_SELECT',
                                                    field_level: getFieldLevel(
                                                        templateType,
                                                        sectionName
                                                    ),
                                                    additional_information: {
                                                        is_visible:
                                                            itemDetails
                                                                ?.additional_information
                                                                ?.is_visible ??
                                                            true,
                                                        is_negotiable:
                                                            itemDetails
                                                                ?.additional_information
                                                                ?.is_negotiable ??
                                                            true,
                                                        is_hidden:
                                                            itemDetails
                                                                ?.additional_information
                                                                ?.is_hidden ??
                                                            false,
                                                    },
                                                }).then(() => {
                                                    setCurrentPage(1);
                                                    refetchFields();
                                                });
                                            }
                                            // setDisableField(index);
                                        } catch (e) {
                                            setDisableField(-1);
                                        }
                                    }}
                                >
                                    <Box
                                        padding="20px"
                                        bgcolor={
                                            //ASK WHAT TO DO HERE
                                            false
                                                ? 'rgb(237,200,255,0.4)'
                                                : 'rgb(255,223,199,0.3)'
                                        }
                                        marginRight={'10px'}
                                        borderRadius={'5px'}
                                    ></Box>
                                    <Box minWidth={0} paddingRight={0.5}>
                                        <Box
                                            display="flex"
                                            alignItems={'center'}
                                        >
                                            <FWTypography fontSize="16px">
                                                {itemDetails.name}
                                            </FWTypography>
                                        </Box>
                                        <Box>
                                            <FWTypography
                                                color={'text.secondary'}
                                                fontSize={'12px'}
                                                margin={'3px 0px'}
                                            >
                                                {mapFieldType(
                                                    itemDetails.constraints
                                                        .field_type,
                                                    itemDetails.constraints
                                                        .choice_type
                                                )}
                                                {!['SRM', 'PROJECT'].includes(
                                                    templateType
                                                ) &&
                                                    itemDetails.additional_information !==
                                                        undefined &&
                                                    fieldAdditionalInfo(
                                                        itemDetails.additional_information
                                                    )}

                                                {['PROJECT'].includes(
                                                    templateType
                                                ) &&
                                                    itemDetails.additional_information !==
                                                        undefined &&
                                                    fieldMandatoryOrNonMandatoryInfo(
                                                        {
                                                            ...itemDetails.additional_information,
                                                            is_factwise:
                                                                itemDetails.is_builtin_field,
                                                            is_required:
                                                                itemDetails.is_required,
                                                            is_mandatory:
                                                                itemDetails.is_mandatory,
                                                        },
                                                        (itemDetails.constraints
                                                            .field_type as string) ===
                                                            'COLLECTION',
                                                        templateType
                                                    )}
                                            </FWTypography>
                                            {/* <FWTypography
                                        color={'text.secondary'}
                                        fontSize={'12px'}
                                    >
                                        <i className="bi bi-info-circle"></i>{' '}
                                        {itemDetails.item_description}
                                    </FWTypography> */}
                                        </Box>
                                    </Box>
                                </Grid>
                            );
                        })}
                        {hasMoreItems && (
                            <div
                                style={{
                                    paddingTop: '10px',
                                    width: '100%',
                                    display: 'flex',
                                    justifyContent: 'center',
                                }}
                                ref={observerCallback}
                            >
                                <CircularProgress size={28} />
                            </div>
                        )}
                    </>
                ) : (
                    <FWTypography
                        paddingY={0.5}
                        paddingX={1}
                        sx={{ color: 'rgba(26,26,31,0.6)' }}
                    >
                        No custom field has been added
                    </FWTypography>
                )}
            </Grid>
        </Box>
    );
};

export default TemplateSuggestedFieldsSection;
