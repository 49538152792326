import { cloneDeep, isEmpty, isEqual } from 'lodash';
import { useCallback, useEffect, useReducer, useState } from 'react';
import { toast } from 'react-toastify';
import { useGetAdditionalCostFromAdminQuery } from '../../../../AdditionalCost/Services/additionalCosts.services';
import { IAdditionalCostsBackend } from '../../../../AdditionalCost/models/AdditionalCost.model';
import {
    HookStateValue,
    IHookState,
    useHookState,
} from '../../../../Common/Hooks/StateHook';
import { getAllCurrencies } from '../../../../Events/Services/Buyer/shared.service';
import { modifyCustomSectionForUpdate } from '../../../../Global/Helpers/templateHelpers';
import {
    CustomFieldType,
    ICustomSection,
} from '../../../../Global/Interfaces/TemplateInterface';
import { ICustomAttributeNew } from '../../../../Models/Attributes.model';
import { ICurrencyDetails } from '../../../../Models/Currency.model';
import { getDuplicateNames } from '../../Components/Settings/GRNQC/GRNQCSettings';
import { IEntity } from '../../Interfaces/EnterpriseInterface';
import {
    IItemCustomId,
    IItemDetails,
    IItemEntityDetail,
    IItemMeasurementUnits,
    IItemStandardTermsOptions,
    IItemSummary,
    IMeasurement,
    ItemEntityStatus,
    ItemStatus,
} from '../../Interfaces/ItemDirectoryInterface';
import { IItemUpdateValues } from '../../Pages/ItemDirectory/ItemsListOptionsMenu';
import { fetchEntities } from '../../Services/EnterpriseService';
import {
    IItemAddEditResponse,
    IItemValuePayload,
    addNewItemToEnterprise,
    addNewItemToEntity,
    fetchEntityItemsList,
    fetchGlobalItemTags,
    fetchItemDetails,
    fetchItemEntitiesList,
    fetchItemsOrBuyersOrVendorsListCSVLink,
    oldfetchItemsList,
    updateEntitiesList,
    updateItemValue,
    useGetItemMeasurementsQuery,
} from '../../Services/ItemDirectoryService';
import { IINewItemTemplate } from './ItemAdditionalCostHook';

/*****
 Item directory hooks - reducer, actions, enums, etc.
 *****/

const initialItemDetails: IItemDetails = {
    id: '',
    itemCode: '',
    itemName: '',
    rss_feed_links: [],
    description: '',
    linked_currencies: [],
    primary_index: null,
    secondary_indices: [],
    image: [],
    notes: '',
    internal_notes: '',
    measurementUnits: [],
    bom_template: null,
    // defaultCurrency: {
    //     entry_id: '',
    //     currency_code_abbreviation: '',
    //     currency_name: '',
    //     currency_symbol: '',
    // },
    isBuyer: true,
    isSeller: false,
    buyerPricingInformation: {
        price: null,
        currencyCodeId: null,
        additionalCost: [],
    },
    sellerPricingInformation: {
        price: null,
        currencyCodeId: null,
        additionalCost: [],
    },
    standardTerms: {
        lead: null,
        payment: null,
        prePayment: null,
    },
    specifications: [],
    customIds: [],
    status: ItemStatus.ACTIVE,
    custom_fields: { section_list: [] },
    custom_sections: [],
    // defaultPrice: '',
    tags: [],
    additionalCost: [],
    item_type: 'RAW_MATERIAL',
    attributes: [],
};

export enum ItemUpdateActions {
    ITEM_CODE = 'ITEM_CODE',
    ITEM_TYPE = 'ITEM_TYPE',
    NAME = 'NAME',
    DESCRIPTION = 'DESCRIPTION',
    INTERNAL_NOTES = 'INTERNAL_NOTES',
    NOTES = 'NOTES',
    TAGS = 'TAGS',
    MEASUREMENTS = 'MEASUREMENTS',
    TEMPLATE_ID = 'TEMPLATE_ID',
    // DEFAULT_CURRENCY = 'DEFAULT_CURRENCY',
    // DEFAULT_PRICE = 'DEFAULT_PRICE',
    CUSTOM_FIELDS = 'CUSTOM_FIELDS',
    CUSTOM_SECTION = 'CUSTOM_SECTION',
    IS_BUYER = 'IS_BUYER',
    IS_SELLER = 'IS_SELLER',
    BUYER_PRICING_INFORMATION = 'BUYER_PRICING_INFORMATION',
    SELLER_PRICING_INFORMATION = 'SELLER_PRICING_INFORMATION',
    LEAD = 'LEAD',
    PAYMENT = 'PAYMENT',
    PREPAYMENT = 'PREPAYMENT',
    SPECIFICATION = 'SPECIFICATION',
    CUSTOM_IDS = 'CUSTOM_IDS',
    RESET = 'RESET',
    ADDITIONAL_COST = 'ADDITIONAL_COST',
    SELLER_ADDITIONAL_COST = 'SELLER_ADDITIONAL_COST',
    BUYER_ADDITIONAL_COST = 'BUYER_ADDITIONAL_COST',
}

interface IStringAction {
    type:
        | ItemUpdateActions.ITEM_CODE
        | ItemUpdateActions.NAME
        | ItemUpdateActions.DESCRIPTION
        | ItemUpdateActions.INTERNAL_NOTES
        | ItemUpdateActions.NOTES;

    // | ItemUpdateActions.DEFAULT_PRICE;
    value: string;
}

interface IStringArrayAction {
    type: ItemUpdateActions.TAGS;
    value: string[];
}

interface IArrayAction {
    type: ItemUpdateActions.MEASUREMENTS;
    value: IMeasurement[];
}

// interface IDefaultCurrencyAction {
//     type: ItemUpdateActions.DEFAULT_CURRENCY;
//     value: ICurrencyDetails;
// }

interface IStandardTermsAction {
    type:
        | ItemUpdateActions.LEAD
        | ItemUpdateActions.PAYMENT
        | ItemUpdateActions.PREPAYMENT;
    value: IItemStandardTermsOptions | null;
}

interface ISpecificationAction {
    type: ItemUpdateActions.SPECIFICATION;
    value: ICustomAttributeNew[];
}

interface ICustomIdsAction {
    type: ItemUpdateActions.CUSTOM_IDS;
    value: IItemCustomId[];
}

interface IResetAction {
    type: ItemUpdateActions.RESET;
    value: IItemDetails;
}

interface ITemplateIdAction {
    type: ItemUpdateActions.TEMPLATE_ID;
    value: string;
}

interface IBuyerPricingInformation {
    type: ItemUpdateActions.BUYER_PRICING_INFORMATION;
    value: {
        price: number | null;
        currencyCodeId: string | null;
    };
}

interface ISellerPricingInformation {
    type: ItemUpdateActions.SELLER_PRICING_INFORMATION;
    value: {
        price: number | null;
        currencyCodeId: string | null;
    };
}

interface IAdditionalCostInformation {
    type: ItemUpdateActions.ADDITIONAL_COST;
    value: IAdditionalCostsBackend[];
}

// cost_name: string;
//     cost_type: CostTypeEnum;
//     allocation_type: AllocationTypeEnum | null;
//     cost_value: number | string;

interface IIsBuyer {
    type: ItemUpdateActions.IS_BUYER;
    value: boolean;
}

interface IIsSeller {
    type: ItemUpdateActions.IS_SELLER;
    value: boolean;
}

interface ISellerSideAdditionalCost {
    type: ItemUpdateActions.SELLER_ADDITIONAL_COST;
    value: IAdditionalCostsBackend[];
}

interface IBuyerSideAdditionalCost {
    type: ItemUpdateActions.BUYER_ADDITIONAL_COST;
    value: IAdditionalCostsBackend[];
}

interface IItemCustomFields {
    type: ItemUpdateActions.CUSTOM_SECTION;
    value: ICustomSection[];
}
interface IItemType {
    type: ItemUpdateActions.ITEM_TYPE;
    value: 'FINISHED_GOOD' | 'RAW_MATERIAL';
}

export type ItemReducerAction =
    | IStringAction
    | IStringArrayAction
    | IResetAction
    | ITemplateIdAction
    | IArrayAction
    // | IDefaultCurrencyAction
    | IBuyerPricingInformation
    | ISellerPricingInformation
    | IIsBuyer
    | IIsSeller
    | IStandardTermsAction
    | ISpecificationAction
    | IAdditionalCostInformation
    | IItemCustomFields
    | ISellerSideAdditionalCost
    | IBuyerSideAdditionalCost
    | ICustomIdsAction
    | IItemType;

const itemDetailReducer = (
    state: IItemDetails,
    action: ItemReducerAction
): IItemDetails => {
    let newItemDetail: IItemDetails = { ...state };

    switch (action.type) {
        case ItemUpdateActions.ITEM_CODE:
            newItemDetail.itemCode = action.value;
            break;
        case ItemUpdateActions.TEMPLATE_ID:
            newItemDetail.template_id = action.value;
            break;
        case ItemUpdateActions.NAME:
            newItemDetail.itemName = action.value;
            break;
        case ItemUpdateActions.DESCRIPTION:
            newItemDetail.description = action.value;
            break;
        case ItemUpdateActions.TAGS:
            newItemDetail.tags = action.value;
            break;
        case ItemUpdateActions.NOTES:
            newItemDetail.notes = action.value;
            break;
        case ItemUpdateActions.INTERNAL_NOTES:
            newItemDetail.internal_notes = action.value;
            break;
        case ItemUpdateActions.MEASUREMENTS:
            newItemDetail.measurementUnits = action.value;
            break;

        // case ItemUpdateActions.DEFAULT_CURRENCY:
        //     newItemDetail.defaultCurrency = action.value;
        //     break;
        // case ItemUpdateActions.DEFAULT_PRICE:
        //     newItemDetail.defaultPrice = action.value;
        //     break;
        case ItemUpdateActions.BUYER_PRICING_INFORMATION:
            newItemDetail.buyerPricingInformation = action.value;
            break;
        case ItemUpdateActions.SELLER_PRICING_INFORMATION:
            newItemDetail.sellerPricingInformation = action.value;
            break;
        case ItemUpdateActions.IS_BUYER:
            newItemDetail.isBuyer = action.value;

            if (action.value) {
                newItemDetail.buyerPricingInformation.currency_symbol = null;
                newItemDetail.buyerPricingInformation.price = null;
            } else {
                newItemDetail.buyerPricingInformation = {
                    currencyCodeId: null,
                    price: null,
                };
            }

            break;
        case ItemUpdateActions.IS_SELLER:
            newItemDetail.isSeller = action.value;

            if (action.value) {
                newItemDetail.sellerPricingInformation.currency_symbol = null;
                newItemDetail.sellerPricingInformation.price = null;
            } else {
                newItemDetail.sellerPricingInformation = {
                    currencyCodeId: null,
                    price: null,
                };
            }
            break;
        case ItemUpdateActions.LEAD:
            newItemDetail.standardTerms.lead = action.value;
            break;
        case ItemUpdateActions.PAYMENT:
            newItemDetail.standardTerms.payment = action.value;
            break;
        case ItemUpdateActions.PREPAYMENT:
            newItemDetail.standardTerms.prePayment = action.value;
            break;
        case ItemUpdateActions.SPECIFICATION:
            newItemDetail.attributes = [...action.value];
            break;

        case ItemUpdateActions.ADDITIONAL_COST:
            newItemDetail.additionalCost = action.value;
            newItemDetail.buyerPricingInformation.additionalCost =
                newItemDetail.buyerPricingInformation.additionalCost?.map(
                    (cost) => {
                        const temp = action.value.find(
                            (c) =>
                                cost?.additional_cost_id ===
                                c?.additional_cost_id
                        );
                        if (temp) cost.cost_value = temp.cost_value;

                        return cost;
                    }
                ) ?? [];

            newItemDetail.sellerPricingInformation.additionalCost =
                newItemDetail.sellerPricingInformation.additionalCost?.map(
                    (cost) => {
                        const temp = action.value.find(
                            (c) =>
                                cost?.additional_cost_id ===
                                c?.additional_cost_id
                        );
                        if (temp) cost.cost_value = temp.cost_value;

                        return cost;
                    }
                ) ?? [];

            break;
        case ItemUpdateActions.CUSTOM_IDS:
            newItemDetail.customIds = [...action.value];
            break;
        case ItemUpdateActions.CUSTOM_SECTION:
            newItemDetail.custom_sections = action.value;
            break;
        case ItemUpdateActions.SELLER_ADDITIONAL_COST:
            newItemDetail.sellerPricingInformation.additionalCost = [
                ...action.value,
            ];

            break;
        case ItemUpdateActions.BUYER_ADDITIONAL_COST:
            newItemDetail.buyerPricingInformation.additionalCost = [
                ...action.value,
            ];
            break;
        case ItemUpdateActions.ITEM_TYPE:
            newItemDetail.item_type = action.value;
            break;
        case ItemUpdateActions.RESET:
            newItemDetail = { ...action.value };
            break;
    }

    return newItemDetail;
};

/*****
 Item directory list hook
 *****/

export interface IItemDirectoryProviders {
    itemDirectoryList: IItemSummary[];
    hookState: IHookState;
    updateItemDirectoryList: (data: IItemUpdateValues) => void;
}

export const useItemsList = () => {
    const [itemDirectoryList, setItemDirectoryList] = useState<IItemSummary[]>(
        []
    );
    const { hookState, updateHookState } = useHookState(HookStateValue.LOADING);

    const getItemDirectoryList = useCallback(async () => {
        updateHookState(HookStateValue.LOADING);
        try {
            const items = await oldfetchItemsList();
            setItemDirectoryList(items);
            updateHookState(HookStateValue.READY);
        } catch (error) {
            updateHookState(HookStateValue.ERROR);
        }
    }, [updateHookState]);

    const updateItemDirectoryList = (data: IItemUpdateValues) => {
        setItemDirectoryList((prevList) => {
            // FIXME: item type any
            const item: any = prevList[data.index];
            item[data.key] = data.value;
            return [...prevList];
        });
    };

    useEffect(() => {
        getItemDirectoryList();
    }, [getItemDirectoryList]);

    const itemDirectoryProviders: IItemDirectoryProviders = {
        itemDirectoryList,
        hookState,
        updateItemDirectoryList,
    };

    return itemDirectoryProviders;
};

/*****
 Entity Item directory list hook
 *****/

export interface IEntityItemDirectoryProviders {
    entityItemsList: IItemSummary[];
    hookState: IHookState;
    updateItemDirectoryList: () => void;
    linkItem: (itemUid: string) => Promise<boolean>;
    disableItem: (itemUid: string) => Promise<boolean>;
}

export const useEntityItemsList = (entityUid: string) => {
    const [entityItemsList, setEntityItemsList] = useState<IItemSummary[]>([]);
    const { hookState, updateHookState } = useHookState(HookStateValue.LOADING);

    const getEntityItemsList = useCallback(async () => {
        updateHookState(HookStateValue.LOADING);
        try {
            const items = await fetchEntityItemsList(entityUid);
            setEntityItemsList(items);
            updateHookState(HookStateValue.READY);
        } catch (error) {
            updateHookState(HookStateValue.ERROR);
        }
    }, [updateHookState, entityUid]);

    const updateItemDirectoryList = () => {
        getEntityItemsList();
    };

    const linkItem = async (itemUid: string) => {
        updateHookState(HookStateValue.LOADING);
        try {
            const itemLinked = await updateEntitiesList(itemUid, {
                entityId: entityUid,
                status: ItemEntityStatus.ACTIVE,
            });
            getEntityItemsList();
            return itemLinked;
        } catch (error) {
            updateHookState(HookStateValue.ERROR);
            return false;
        }
    };

    const disableItem = async (itemUid: string) => {
        updateHookState(HookStateValue.LOADING);
        try {
            const itemLinked = await updateEntitiesList(itemUid, {
                entityId: entityUid,
                status: ItemEntityStatus.INACTIVE,
            });
            getEntityItemsList();
            return itemLinked;
        } catch (error) {
            updateHookState(HookStateValue.ERROR);
            return false;
        }
    };

    useEffect(() => {
        getEntityItemsList();
    }, [getEntityItemsList]);

    const itemDirectoryProviders: IEntityItemDirectoryProviders = {
        entityItemsList,
        hookState,
        updateItemDirectoryList,
        linkItem,
        disableItem,
    };

    return itemDirectoryProviders;
};

/*****
 Item details hook
 *****/

export interface IItemDetailSummaryProviders {
    hookState: IHookState;
    itemDetails: IItemDetails;
    itemEditDetails: IItemDetails;
    measurements: IItemMeasurementUnits;
    currencies: ICurrencyDetails[];
    isValidDetails: boolean;
    updateEditDetails: (data: ItemReducerAction) => void;
    handleItemSave: () => Promise<IItemAddEditResponse>;
    globalTags: string[];
    fetchItemMeasurements: () => void;
}

export const useItemDetailSummary = (itemId: string) => {
    const { hookState, updateHookState } = useHookState(HookStateValue.LOADING);

    const [itemDetails, setItemDetails] = useState<IItemDetails>(
        cloneDeep(initialItemDetails)
    );

    const [itemEditDetails, setItemEditDetails] = useReducer(
        itemDetailReducer,
        cloneDeep(initialItemDetails)
    );

    const [measurements, setMeasurements] = useState<IItemMeasurementUnits>({});
    const [currencies, setCurrencies] = useState<ICurrencyDetails[]>([]);
    const [globalTags, setGlobalTags] = useState<string[]>([]);

    const [isValidDetails, setIsValidDetails] = useState<boolean>(true);

    const { data: itemMeasurements, refetch: fetchItemMeasurements } =
        useGetItemMeasurementsQuery({});

    useEffect(() => {
        if (itemMeasurements) {
            setMeasurements(itemMeasurements);
        }
    }, [itemMeasurements]);

    const { data: adminAdditionalCost } = useGetAdditionalCostFromAdminQuery(
        {}
    );

    const updateAdditionalCostInItemDetails = useCallback(
        async (itemDetails: IItemDetails) => {
            let newItemDetail = cloneDeep(itemDetails);

            if (adminAdditionalCost) {
                newItemDetail.additionalCost =
                    newItemDetail.additionalCost?.filter((itemAC) =>
                        adminAdditionalCost?.some(
                            (adminAC) =>
                                adminAC.additional_cost_id ===
                                itemAC.additional_cost_id
                        )
                    );

                if (newItemDetail.buyerPricingInformation?.additionalCost) {
                    newItemDetail.buyerPricingInformation.additionalCost =
                        newItemDetail.buyerPricingInformation?.additionalCost?.filter(
                            (buyerItemAC) =>
                                adminAdditionalCost?.some(
                                    (adminAC) =>
                                        adminAC.additional_cost_id ===
                                        buyerItemAC.additional_cost_id
                                )
                        );
                }
                if (newItemDetail.sellerPricingInformation?.additionalCost) {
                    newItemDetail.sellerPricingInformation.additionalCost =
                        newItemDetail.sellerPricingInformation?.additionalCost?.filter(
                            (sellerItemAC) =>
                                adminAdditionalCost?.some(
                                    (adminAC) =>
                                        adminAC.additional_cost_id ===
                                        sellerItemAC.additional_cost_id
                                )
                        );
                }
            }
            return newItemDetail;
        },
        [adminAdditionalCost]
    );

    const getItemDetails = useCallback(async () => {
        try {
            const [itemDetails, currencies, globalTags]: [
                IItemDetails,
                // IItemMeasurementUnits,
                ICurrencyDetails[],
                string[]
            ] = await Promise.all([
                fetchItemDetails(itemId),
                getAllCurrencies(),
                fetchGlobalItemTags(),
            ]);

            const updatedItemDetails = await updateAdditionalCostInItemDetails(
                itemDetails
            );

            setItemDetails(cloneDeep(updatedItemDetails));

            setItemEditDetails({
                type: ItemUpdateActions.RESET,
                value: cloneDeep(updatedItemDetails),
            });
            // setMeasurements(measurements);
            setCurrencies(currencies);
            setGlobalTags(globalTags);
            updateHookState(HookStateValue.READY);
        } catch (error) {
            updateHookState(HookStateValue.ERROR);
        }
    }, [itemId, updateAdditionalCostInItemDetails, updateHookState]);

    const updateEditDetails = (data: ItemReducerAction) => {
        setItemEditDetails(data);
    };

    const handleItemSave = async (): Promise<IItemAddEditResponse> => {
        updateHookState(HookStateValue.LOADING);

        try {
            const payload: IItemValuePayload = {
                name: itemEditDetails.itemName,
                rss_feed_links: itemEditDetails.rss_feed_links,
                code: itemEditDetails.itemCode,
                primary_index: itemEditDetails.primary_index ?? null,
                secondary_indices: itemEditDetails.secondary_indices ?? [],
                description: itemEditDetails.description,
                custom_fields: itemEditDetails.custom_fields,
                image_id:
                    itemEditDetails.image.map(
                        (image) => image?.attachment_id
                    ) ?? [],
                custom_sections: modifyCustomSectionForUpdate(
                    itemEditDetails.custom_sections
                ),
                attributes: (itemEditDetails.attributes ?? []).map(
                    (singleAttribute) => ({
                        attribute_linkage_id:
                            singleAttribute.attribute_linkage_id || null,
                        attribute_id: singleAttribute.attribute_id,
                        attribute_name: singleAttribute.attribute_name,
                        attribute_type: singleAttribute.attribute_type,
                        attribute_values: singleAttribute.attribute_values.map(
                            (singleVal) => ({
                                attribute_value_linkage_id:
                                    singleVal.attribute_value_linkage_id ||
                                    null,
                                attribute_value_id:
                                    singleVal.attribute_value_id || null,
                                value: singleVal.value,
                                measurement_unit_id:
                                    singleVal.measurement_unit
                                        ?.measurement_unit_id ?? null,
                                currency_id:
                                    singleVal.currency?.entry_id ?? null,
                            })
                            //      ({
                            //     attribute_value_linkage_id:
                            //         singleVal.attribute_value_linkage_id ??
                            //         null,
                            //     attribute_value_id:
                            //         singleVal.attribute_value_id,
                            //     value: singleVal.value,
                            //     measurement_unit_id:
                            //         singleVal.measurement_unit_id ?? null,
                            //     currency_id: singleVal.currency_id ?? null,
                            // })
                        ),
                    })
                ),
                // attributes: {
                //     attributes:
                //         itemEditDetails?.specifications?.map((spec) => {
                //             return {
                //                 attribute_name: spec.name,
                //                 attribute_value: spec.value,
                //                 attribute_value_type: 'TEXT',
                //                 attribute_exclude: false,
                //             };
                //         }) ?? [],
                // },
                measurement_units: itemEditDetails.measurementUnits.map(
                    (m) => m.id
                ),
                // currency_code_id:
                //     itemEditDetails.defaultCurrency?.entry_id || null,
                // price: +itemEditDetails.defaultPrice,
                is_buyer: itemEditDetails.isBuyer,
                is_seller: itemEditDetails.isSeller,
                buyer_pricing_information: {
                    price:
                        itemEditDetails.buyerPricingInformation.price ?? null,
                    currency_code_id:
                        itemEditDetails.buyerPricingInformation
                            .currencyCodeId ?? null,
                    additional_costs:
                        itemEditDetails.buyerPricingInformation
                            .additionalCost ?? [],
                    taxes: [],
                },
                seller_pricing_information: {
                    price:
                        itemEditDetails.sellerPricingInformation.price ?? null,
                    currency_code_id:
                        itemEditDetails.sellerPricingInformation
                            .currencyCodeId ?? null,
                    additional_costs:
                        itemEditDetails.buyerPricingInformation
                            .additionalCost ?? [],
                    taxes: [],
                },
                notes: itemEditDetails.notes,
                internal_notes: itemEditDetails.internal_notes,
                custom_ids: {
                    custom_ids: itemEditDetails.customIds,
                },
                item_type: itemEditDetails.item_type ?? 'RAW_MATERIAL',
                status: itemEditDetails.status,
                tags: itemEditDetails.tags ?? [],
            };

            const itemUpdated = await updateItemValue(itemId, payload);
            if (itemUpdated) setItemDetails(cloneDeep(itemEditDetails));
            updateHookState(HookStateValue.READY);
            return {
                itemId: '',
                isFinishedGood:
                    (itemEditDetails.item_type ?? 'RAW_MATERIAL') ===
                    'FINISHED_GOOD',
                success: itemUpdated,
            };
        } catch (error) {
            updateHookState(HookStateValue.ERROR);
            return {
                itemId: '',
                isFinishedGood: false,
                success: false,
            };
        }
    };

    useEffect(() => {
        getItemDetails();
    }, [getItemDetails]);

    useEffect(() => {
        let errors = false;
        if (isEmpty(itemEditDetails.itemCode)) errors = true;
        if (isEmpty(itemEditDetails.itemName)) errors = true;
        if (itemEditDetails?.measurementUnits?.length === 0) errors = true;

        if (
            itemEditDetails?.custom_fields &&
            itemEditDetails?.custom_fields?.section_list &&
            itemEditDetails.custom_fields.section_list?.some((section) => {
                return section.fields?.some(
                    (field) =>
                        field.is_required &&
                        (field.type === 'BOOLEAN'
                            ? field.value === null ||
                              field.value === '' ||
                              field.value === undefined
                            : !Boolean(field.value) || isEqual(field.value, []))
                );
            })
        ) {
            errors = true;
        }

        if ((itemEditDetails?.attributes?.length ?? 0) > 0) {
            for (const spec of itemEditDetails?.attributes ?? []) {
                if (isEmpty(spec.attribute_name.trim())) {
                    errors = true;
                    break;
                }
                // eslint-disable-next-line @typescript-eslint/no-unused-vars
                // for (const val of spec.attribute_name) {
                //     if (isEmpty(val.trim())) {
                //         console.log('valllll', val);
                //         console.log('meooww', spec.attribute_name);
                //         errors = true;
                //         console.log('olivia', cloneDeep(errors));
                //         break;
                //     }
                // }
            }
        }
        if (itemEditDetails?.customIds?.length > 0) {
            for (const customId of itemEditDetails.customIds) {
                if (isEmpty(customId.name.trim())) {
                    errors = true;
                    break;
                }
                if (isEmpty(customId.value.trim())) {
                    errors = true;
                    break;
                }
            }
        }
        const duplicateSpecs = getDuplicateNames(
            itemEditDetails.specifications
                ?.map((s) => s.name.toLowerCase().trim())
                .filter((c) => !isEmpty(c)) || []
        );

        const duplicateCustomIds = getDuplicateNames(
            itemEditDetails.customIds
                ?.map((s) => s.name.toLowerCase().trim())
                .filter((c) => !isEmpty(c)) ?? []
        );

        if (duplicateSpecs.length > 0 || duplicateCustomIds.length > 0) {
            errors = true;
        }
        if (
            itemEditDetails.buyerPricingInformation?.price &&
            !itemEditDetails.buyerPricingInformation?.currencyCodeId
        ) {
            errors = true;
        }
        if (
            itemEditDetails.sellerPricingInformation?.price &&
            !itemEditDetails.sellerPricingInformation?.currencyCodeId
        ) {
            errors = true;
        }

        if ((itemEditDetails.attributes?.length ?? 0) > 0) {
            itemEditDetails.attributes?.forEach((spec) => {
                if (spec.attribute_values.length === 0) {
                    errors = true;
                }
                if (spec.attribute_name === '') {
                    errors = true;
                }
            });

            itemEditDetails.attributes?.forEach((attribute) => {
                const seenValues = new Set();

                attribute.attribute_values.forEach((attrValue) => {
                    if (seenValues.has(attrValue.value)) {
                        errors = true;
                    } else {
                        seenValues.add(attrValue.value.trim().toLowerCase());
                    }

                    if (attrValue.value.trim() === '') {
                        errors = true;
                    }
                });

                const atLeastOneVal = attribute.attribute_values.some(
                    (attrValue) => attrValue.value.trim() !== ''
                );
                if (!atLeastOneVal) {
                    errors = true;
                }
            });
        }

        // itemEditDetails.specifications?.forEach((spec) => {
        //     const duplicateSpecName = getDuplicateNames(
        //         spec.value.filter((c) => !isEmpty(c))
        //     );
        //     if (duplicateSpecName.length > 0) {
        //         errors = true;
        //     }
        // });

        setIsValidDetails(!errors);
    }, [itemEditDetails]);

    const itemDetailSummaryProviders: IItemDetailSummaryProviders = {
        globalTags,
        hookState,
        itemDetails,
        itemEditDetails,
        updateEditDetails,
        measurements,
        currencies,
        isValidDetails,
        handleItemSave,
        fetchItemMeasurements,
    };

    return itemDetailSummaryProviders;
};

/*****
 Item add hook
 *****/

export interface IItemAddProviders {
    hookState: IHookState;
    newItemDetails: IItemDetails;
    updateItemDetails: (data: ItemReducerAction) => void;
    globalTags: string[];
    measurements: IItemMeasurementUnits;
    currencies: ICurrencyDetails[];
    isValidDetails: boolean;
    handleItemSave: () => Promise<IItemAddEditResponse>;
    fetchItemMeasurements: () => void;
}

export const useItemAdd = (
    defaultItemName: string,
    entityIDToLink: string | null = null,
    templateDetails: IINewItemTemplate | undefined
) => {
    const { hookState, updateHookState } = useHookState(HookStateValue.LOADING);

    const [newItemDetails, setNewItemDetails] = useReducer(
        itemDetailReducer,
        cloneDeep({ ...initialItemDetails, itemName: defaultItemName })
    );

    const [measurements, setMeasurements] = useState<IItemMeasurementUnits>({});
    const [globalTags, setGlobalTags] = useState<string[]>([]);
    const [currencies, setCurrencies] = useState<ICurrencyDetails[]>([]);

    const [isValidDetails, setIsValidDetails] = useState<boolean>(true);

    const { data: itemMeasurements, refetch: fetchItemMeasurements } =
        useGetItemMeasurementsQuery({});

    useEffect(() => {
        if (itemMeasurements) {
            setMeasurements(itemMeasurements);
        }
    }, [itemMeasurements]);

    useEffect(() => {
        if (templateDetails) {
            let customSection: ICustomSection = {
                name: templateDetails.custom_sections[0].name,
                section_type: 'ITEM',
                custom_fields:
                    templateDetails.custom_sections[0].custom_fields.map(
                        (field) => {
                            return {
                                name: field.name,
                                type: field.type as CustomFieldType,
                                value:
                                    field.type === 'MULTI_CHOICE' ? [] : null,
                                is_locked: true,
                                is_visible: true,
                                description: field.description,
                                is_required: field.is_required,
                                is_negotiable: true,
                            };
                        }
                    ),
            };
            let newDetails = cloneDeep(newItemDetails);
            newDetails.custom_sections = [customSection];
            setNewItemDetails({
                type: ItemUpdateActions.RESET,
                value: newDetails,
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [templateDetails]);

    const getMeasurementsAndCurrencies =
        useCallback(async (): Promise<IItemDetails> => {
            try {
                /* const measurements: IItemMeasurementUnits =
                await fetchItemMeasurements(); */
                const [currencies, entities, globalTags] = await Promise.all([
                    // fetchItemMeasurements(),
                    getAllCurrencies(),
                    fetchEntities(),
                    fetchGlobalItemTags(),
                ]);

                // setMeasurements(measurements);
                setCurrencies(currencies);
                setGlobalTags(globalTags);

                let defaultCurrency: ICurrencyDetails | null = null;
                let selectedEntity = entityIDToLink
                    ? entities.find((e) => e.entityId === entityIDToLink)
                    : null;

                if (selectedEntity) {
                    defaultCurrency =
                        selectedEntity.default_procurement_currency;
                } else if (entities.length > 0) {
                    defaultCurrency = entities[0].default_procurement_currency;
                }

                if (defaultCurrency) {
                    // setNewItemDetails({
                    //     type: ItemUpdateActions.DEFAULT_CURRENCY,
                    //     value: defaultCurrency,
                    // });
                }

                updateHookState(HookStateValue.READY);
            } catch (error) {
                updateHookState(HookStateValue.ERROR);
            }
            return cloneDeep(initialItemDetails);
        }, [entityIDToLink, updateHookState]);

    const updateItemDetails = (data: ItemReducerAction) => {
        setNewItemDetails(data);
    };

    const handleItemSave = async (): Promise<IItemAddEditResponse> => {
        updateHookState(HookStateValue.LOADING);
        try {
            let itemSaved;
            if (entityIDToLink === null) {
                itemSaved = await addNewItemToEnterprise(newItemDetails);
            } else {
                itemSaved = await addNewItemToEntity(
                    newItemDetails,
                    entityIDToLink
                );
            }
            setNewItemDetails({
                type: ItemUpdateActions.RESET,
                value: newItemDetails,
            });
            updateHookState(HookStateValue.READY);
            toast.success('Item added successfully');
            return itemSaved;
        } catch (error) {
            updateHookState(HookStateValue.ERROR);
            return {
                itemId: '',
                isFinishedGood: false,
                success: false,
            };
        }
    };

    useEffect(() => {
        getMeasurementsAndCurrencies();
    }, [getMeasurementsAndCurrencies]);

    useEffect(() => {
        let errors = false;
        if (isEmpty(newItemDetails.itemCode.trim())) errors = true;

        if (isEmpty(newItemDetails.itemName.trim())) errors = true;
        if (newItemDetails.measurementUnits.length === 0) errors = true;
        if (
            newItemDetails.custom_fields.section_list.some((section) => {
                return section.fields.some(
                    (field) =>
                        field.is_required &&
                        (field.type === 'BOOLEAN'
                            ? field.value === null
                            : !Boolean(field.value) || isEqual(field.value, []))
                );
            })
        )
            errors = true;
        if ((newItemDetails?.specifications?.length ?? 0) > 0) {
            for (const spec of newItemDetails.specifications ?? []) {
                if (isEmpty(spec.name.trim())) {
                    errors = true;
                    break;
                }
                for (const val of spec.value) {
                    if (isEmpty(val.trim())) {
                        errors = true;
                        break;
                    }
                }
            }
        }
        if (newItemDetails.customIds.length > 0) {
            for (const customId of newItemDetails.customIds) {
                if (isEmpty(customId.name.trim())) {
                    errors = true;
                    break;
                }
                if (isEmpty(customId.value.trim())) {
                    errors = true;
                    break;
                }
            }
        }
        const duplicateSpecs = getDuplicateNames(
            newItemDetails?.specifications?.map((s) =>
                s.name.toLowerCase().trim()
            ) || []
        );

        const duplicateCustomIds = getDuplicateNames(
            newItemDetails.customIds.map((s) => s.name.toLowerCase().trim())
        );
        if (duplicateSpecs.length > 0 || duplicateCustomIds.length > 0) {
            errors = true;
        }

        if (!newItemDetails.item_type) {
            errors = true;
        }

        if (
            newItemDetails.buyerPricingInformation.price &&
            !newItemDetails.buyerPricingInformation.currencyCodeId
        ) {
            errors = true;
        }
        if (
            newItemDetails.sellerPricingInformation.price &&
            !newItemDetails.sellerPricingInformation.currencyCodeId
        ) {
            errors = true;
        }
        if ((newItemDetails.attributes?.length ?? 0) > 0) {
            newItemDetails.attributes?.forEach((spec) => {
                if (spec.attribute_values.length === 0) {
                    errors = true;
                }
                if (spec.attribute_name === '') {
                    errors = true;
                }
            });

            newItemDetails.attributes?.forEach((attribute) => {
                const seenValues = new Set();

                attribute.attribute_values.forEach((attrValue) => {
                    if (seenValues.has(attrValue.value)) {
                        errors = true;
                    } else {
                        seenValues.add(attrValue.value.trim().toLowerCase());
                    }
                    if (attrValue.value.trim() === '') {
                        errors = true;
                    }
                });

                const atLeastOneVal = attribute.attribute_values.some(
                    (attrValue) => attrValue.value.trim() !== ''
                );
                if (!atLeastOneVal) {
                    errors = true;
                }
            });
        }
        if (
            templateDetails?.itemFields['Description'].is_required &&
            isEmpty(newItemDetails.description)
        ) {
            errors = true;
        }
        // console.log(
        //     'default',
        //     templateDetails?.itemFields.Procurement_item.is_required,
        //     !Boolean(newItemDetails.buyerPricingInformation.price),
        //     newItemDetails.buyerPricingInformation.currencyCodeId === null
        // );
        // console.log(
        //     'ans',
        //     templateDetails?.itemFields.Procurement_item.is_required &&
        //         (!Boolean(newItemDetails.buyerPricingInformation.price) ||
        //             newItemDetails.buyerPricingInformation.currencyCodeId ===
        //                 null)
        // );
        if (
            templateDetails?.itemFields.Procurement_item_price.is_required &&
            (!Boolean(newItemDetails.buyerPricingInformation.price) ||
                newItemDetails.buyerPricingInformation.currencyCodeId === null)
        ) {
            errors = true;
        }

        // if (
        //     templateDetails?.itemFields.Sales_item.is_required &&
        //     (isEmpty(newItemDetails.sellerPricingInformation.price) ||
        //         newItemDetails.sellerPricingInformation.currencyCodeId === null)
        // ) {
        //     errors = true;
        // }

        if (
            templateDetails?.itemFields.Notes.is_required &&
            isEmpty(newItemDetails.notes)
        ) {
            errors = true;
        }
        if (
            templateDetails?.itemFields['Internal_notes'].is_required &&
            isEmpty(newItemDetails.internal_notes)
        ) {
            errors = true;
        }

        setIsValidDetails(!errors);
    }, [newItemDetails, templateDetails?.itemFields]);

    const itemAddProviders: IItemAddProviders = {
        hookState,
        newItemDetails,
        updateItemDetails,
        globalTags,
        measurements,
        currencies,
        isValidDetails,
        handleItemSave,
        fetchItemMeasurements,
    };

    return itemAddProviders;
};

/*****
 Item entities hook
 *****/

export interface IItemEntitiesProviders {
    hookState: IHookState;
    entitiesList: IItemEntityDetail[];
    allEntities: IEntity[];
    addEntityToItem: (entityId: string) => Promise<boolean>;
    refreshItemEntities: () => void;
    updatePreferredVendorsCount: (count: number, index: number) => void;
}

export const useItemEntities = (itemId: string) => {
    const { hookState, updateHookState } = useHookState(HookStateValue.LOADING);
    const [entitiesList, setEntitiesList] = useState<IItemEntityDetail[]>([]);
    const [allEntities, setAllEntities] = useState<IEntity[]>([]);

    const getEntitiesList = useCallback(async () => {
        updateHookState(HookStateValue.LOADING);
        try {
            const [itemEntities, allEntities]: [
                IItemEntityDetail[],
                IEntity[]
            ] = await Promise.all([
                fetchItemEntitiesList(itemId),
                fetchEntities({ filter: 'buyerActive' }),
            ]);

            const itemLinkedEntityUids = itemEntities.map((e) => e.entityId);

            const filteredEntitiesList: IItemEntityDetail[] = allEntities
                .filter((e) => !itemLinkedEntityUids.includes(e.entityId))
                .map((e) => {
                    const remEntity: IItemEntityDetail = {
                        id: 0,
                        preferredVendors: 0,
                        blockedVendors: 0,
                        status: ItemEntityStatus.INACTIVE,
                        entityId: e.entityId,
                        entityName: e.entityName,
                        entityType: e.entityType,
                        verificationStatus: e.verificationStatus,
                        linked: false,
                        setupPending: false,
                        createdOn: '',
                        default_procurement_currency: {
                            decimals: 2,
                            entry_id: '',
                            currency_code_abbreviation: '',
                            currency_name: '',
                            currency_symbol: '',
                        },
                    };
                    return remEntity;
                });

            const sortedItemEntities = itemEntities.sort((a, b) =>
                a.status === ItemEntityStatus.INACTIVE ? 1 : -1
            );

            const finalEntitiesList = [
                ...sortedItemEntities,
                ...filteredEntitiesList,
            ].map((e, idx) => ({
                ...e,
                id: idx,
            }));

            setEntitiesList(finalEntitiesList);
            // setAllEntities(filtered);
            updateHookState(HookStateValue.READY);
        } catch (error) {
            setEntitiesList([]);
            setAllEntities([]);
            updateHookState(HookStateValue.ERROR);
        }
    }, [itemId, updateHookState]);

    const addEntityToItem = async (entityId: string): Promise<boolean> =>
        await updateEntitiesList(itemId, {
            entityId,
            status: ItemEntityStatus.ACTIVE,
        });

    const refreshItemEntities = () => getEntitiesList();

    const updatePreferredVendorsCount = (count: number, index: number) => {
        setEntitiesList((prevEntities) => {
            const entity = prevEntities[index];
            entity.preferredVendors = count;
            return [...prevEntities];
        });
    };

    useEffect(() => {
        getEntitiesList();
    }, [getEntitiesList]);

    const itemEntitiesProviders: IItemEntitiesProviders = {
        hookState,
        entitiesList,
        allEntities,
        addEntityToItem,
        refreshItemEntities,
        updatePreferredVendorsCount,
    };

    return itemEntitiesProviders;
};

export const useDownloadItemsAndVendors = (
    type: 'ITEM' | 'VENDORS' | 'BUYERS',
    buyer_entity_id?: string
) => {
    let error_message =
        type === 'BUYERS' ? 'Error downloading CSV' : 'Error downloading Excel';
    const ItemsOrBuyersOrVendorsListCSV = async () => {
        try {
            const url = await fetchItemsOrBuyersOrVendorsListCSVLink(
                type,
                buyer_entity_id
            );

            if (url) {
                window.location.href = url;
            } else {
                toast.error(error_message);
            }
        } catch (error) {
            toast.error(error_message);
        }
    };
    return { ItemsOrBuyersOrVendorsListCSV };
};
