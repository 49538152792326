import {
    Box,
    Button,
    Checkbox,
    DialogActions,
    DialogContent,
    FormControlLabel,
    FormGroup,
    IconButton,
} from '@mui/material';
import { cloneDeep } from 'lodash';
import { Dispatch, Fragment, SetStateAction, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { FWMenuItem } from '../../../../../Common/FWCustomMenu';
import FWIcon from '../../../../../Common/FWIcon';
import { FWInput } from '../../../../../Common/FWInput';
import { FWPopupWithChildren } from '../../../../../Common/FWPopupWithChildren';
import FWSelect from '../../../../../Common/FWSelect';
import { FWTypography } from '../../../../../Common/FWTypograhy';
import {
    CustomTemplateItemTypes,
    CustomTemplateTypes,
} from '../../../../../Global/Interfaces/CustomTemplate.interface';
import {
    ASModule,
    IAdminCustomFieldsAndChecklist,
} from '../../../Interfaces/AdminSettingsInterface';
import {
    createCustomTemplate,
    updateCustomTemplate,
} from '../../../Services/AdminSettingsService';
import { CustomFieldType } from '../../../../../Global/Interfaces/TemplateInterface';

interface IChecklistAddPopupProps {
    open: boolean;
    handleClose: () => void;
    setAdminTemplateSettings: Dispatch<
        SetStateAction<IAdminCustomFieldsAndChecklist>
    >;
    selectedEntity: string;
    module_name: ASModule;
}

const getCustomTemplateType = (moduleName: ASModule): CustomTemplateTypes => {
    switch (moduleName) {
        case ASModule.GOODS_RECEIPT:
            return CustomTemplateTypes.GRN_CHECKLIST;
        case ASModule.QUALITY_CHECK:
            return CustomTemplateTypes.QC_CHECKLIST;
        default:
            return CustomTemplateTypes.GRN_CHECKLIST;
    }
};

const getBlankTemplate = (TemplateType: CustomTemplateTypes) => ({
    template_id: '',
    template_name: '',
    type: TemplateType,
    enterprise: '',
    entity: '',
    items: [
        {
            template_item_id: '',
            template_item_name: '',
            template_item_type: CustomFieldType.SHORTTEXT,
            is_required: false,
            template: '',
            additional_information: {},
        },
    ],
});

const ChecklistAddPopup = ({
    open,
    handleClose,
    setAdminTemplateSettings,
    selectedEntity,
    module_name,
}: IChecklistAddPopupProps) => {
    const TemplateType = getCustomTemplateType(module_name);
    const [checklistDetails, setChecklistDetails] = useState(
        getBlankTemplate(TemplateType)
    );
    const [errors, setErrors] = useState<{ [key: string]: string }>({});

    // useEffect that resets checklist details when open is changed to true
    useEffect(() => {
        if (open) {
            setChecklistDetails(getBlankTemplate(TemplateType));
        }
    }, [TemplateType, open]);

    const handleFieldChange = (
        type: 'MANDATORY' | 'NAME' | 'TYPE',
        e: any,
        index: number
    ) => {
        let newObj = {
            ...checklistDetails,
            items: [...checklistDetails.items],
        };
        switch (type) {
            case 'MANDATORY':
                newObj.items[index].is_required = e.target.checked;
                break;
            case 'NAME':
                newObj.items[index].template_item_name = e.target.value;
                break;
            case 'TYPE':
                newObj.items[index].template_item_type = e.target.value;
                break;
        }
        let newErrors = calculateErrors(newObj);
        setChecklistDetails(newObj);
        setErrors(newErrors);
    };

    const handleRowUpdate = (type: 'ADD' | 'REMOVE', index: number) => {
        setChecklistDetails((prev) => {
            let newObj = { ...prev };
            switch (type) {
                case 'ADD':
                    newObj.items.push({
                        template_item_id: '',
                        template_item_name: '',
                        template_item_type: CustomFieldType.SHORTTEXT,
                        is_required: false,
                        template: '',
                        additional_information: {},
                    });
                    break;
                case 'REMOVE':
                    newObj.items.splice(index, 1);
                    break;
            }

            return newObj;
        });
    };

    const saveCustomFields = async () => {
        try {
            if (checklistDetails.template_id === '') {
                let resp = await createCustomTemplate({
                    ...checklistDetails,
                    entity: selectedEntity,
                });
                setAdminTemplateSettings((prev) => {
                    let newObj = cloneDeep(prev);
                    newObj[module_name].checklist.push(resp);
                    return newObj;
                });
            } else {
                let resp = await updateCustomTemplate({
                    ...checklistDetails,
                    entity: selectedEntity,
                });
                setAdminTemplateSettings((prev) => {
                    let newObj = cloneDeep(prev);
                    newObj[module_name].checklist.push(resp);
                    return newObj;
                });
            }
        } catch (err) {
            toast.error("Couldn't update custom fields");
        }
        handleClose();
    };

    const calculateErrors = (checklistDetails: any) => {
        let newErrors: { [key: string]: string } = {};
        const namesList: string[] = [];
        if (checklistDetails.template_name.trim() === '') {
            newErrors['template_name'] = 'Name is required';
        }
        let idx = 0;
        for (const row of checklistDetails.items) {
            if (row.template_item_name.trim() === '') {
                newErrors[`template_item_name_${idx}`] = 'Name is required';
            } else if (row.template_item_type.trim() === '') {
                newErrors[`template_item_type_${idx}`] = 'Type is required';
            } else {
                namesList.push(row.template_item_name.trim().toLowerCase());
            }
            idx++;
        }
        namesList.forEach((item, index) => {
            if (namesList.indexOf(item) !== index) {
                newErrors[`template_item_name_${index}`] = 'Duplicate name';
            }
        });
        return newErrors;
    };

    return (
        <>
            <FWPopupWithChildren
                open={open}
                handleClose={handleClose}
                title="Add checklist"
            >
                <DialogContent
                    sx={{
                        padding: '12px 24px',
                    }}
                >
                    <FWInput
                        label="Checklist name"
                        value={checklistDetails.template_name}
                        name="CHECKLIST_NAME"
                        onChange={(e) => {
                            setChecklistDetails((prevDetails) => {
                                prevDetails.template_name = e.target.value;
                                return { ...prevDetails };
                            });
                        }}
                        sx={{
                            mb: '15px',
                        }}
                    />
                    <Box
                        display="grid"
                        gridTemplateColumns={'1.25fr 1.25fr 1fr 0.5fr'}
                        columnGap="10px"
                        rowGap="10px"
                        alignItems={'flex-start'}
                    >
                        {checklistDetails.items.map((field, index) => {
                            return (
                                <Fragment key={index}>
                                    <div>
                                        <FWInput
                                            label="Field name"
                                            value={field.template_item_name}
                                            name="NAME"
                                            onChange={(e) => {
                                                handleFieldChange(
                                                    'NAME',
                                                    e,
                                                    index
                                                );
                                            }}
                                            error={
                                                `template_item_name_${index}` in
                                                errors
                                            }
                                            helper={{
                                                text:
                                                    `template_item_name_${index}` in
                                                    errors
                                                        ? errors[
                                                              `template_item_name_${index}`
                                                          ]
                                                        : '',
                                            }}
                                        />
                                    </div>
                                    <Box>
                                        <FWTypography color="text.secondary">
                                            Field type
                                        </FWTypography>
                                        <FWSelect
                                            fullWidth
                                            value={field.template_item_type}
                                            onChange={(e) => {
                                                handleFieldChange(
                                                    'TYPE',
                                                    e,
                                                    index
                                                );
                                            }}
                                            name="TYPE"
                                        >
                                            <FWMenuItem
                                                value={
                                                    CustomTemplateItemTypes.SHORTTEXT
                                                }
                                            >
                                                Text
                                            </FWMenuItem>
                                            <FWMenuItem
                                                value={
                                                    CustomTemplateItemTypes.FLOAT
                                                }
                                            >
                                                Number
                                            </FWMenuItem>
                                        </FWSelect>
                                    </Box>
                                    <Box marginTop={'15px'}>
                                        <FormGroup>
                                            <FormControlLabel
                                                control={<Checkbox />}
                                                label="Mandatory"
                                                checked={field.is_required}
                                                onChange={(e) => {
                                                    handleFieldChange(
                                                        'MANDATORY',
                                                        e,
                                                        index
                                                    );
                                                }}
                                                name="MANDATORY"
                                            />
                                        </FormGroup>
                                    </Box>
                                    <Box
                                        marginTop={'15px'}
                                        className="flex flex--aic"
                                    >
                                        <IconButton
                                            color="error"
                                            onClick={() => {
                                                handleRowUpdate(
                                                    'REMOVE',
                                                    index
                                                );
                                            }}
                                            disabled={
                                                checklistDetails.items
                                                    .length === 1
                                            }
                                        >
                                            <FWIcon
                                                name="bi bi-dash-circle"
                                                size={20}
                                            />
                                        </IconButton>
                                        &nbsp;
                                        {index ===
                                            checklistDetails.items.length -
                                                1 && (
                                            <IconButton
                                                color="primary"
                                                onClick={() => {
                                                    handleRowUpdate(
                                                        'ADD',
                                                        index
                                                    );
                                                }}
                                            >
                                                <FWIcon
                                                    name="bi bi-plus-circle"
                                                    size={20}
                                                />
                                            </IconButton>
                                        )}
                                    </Box>
                                </Fragment>
                            );
                        })}
                    </Box>
                </DialogContent>
                <DialogActions sx={{ m: '12px' }}>
                    <Button variant="outlined" onClick={() => handleClose()}>
                        Cancel
                    </Button>
                    &nbsp;
                    <Button
                        variant="contained"
                        disabled={
                            !(
                                Object.keys(calculateErrors(checklistDetails))
                                    .length === 0
                            )
                        }
                        onClick={() => saveCustomFields()}
                    >
                        Submit
                    </Button>
                </DialogActions>
            </FWPopupWithChildren>
        </>
    );
};

export default ChecklistAddPopup;
