import { IFieldTypeConstraints } from '../../FieldTypes/FieldTypes.model';
import {
    ITemplatePaymentTermsOptionsInvoiceItemValues,
    ITemplatePaymentTermsOptionsPoItemPercentages,
} from '../../Global/Interfaces/TemplateInterface';

export enum CLMTemplateContractFieldsEnum {
    CONTRACT_ID = 'CONTRACT_ID',
    CONTRACT_NAME = 'CONTRACT_NAME',
    CONTRACT_START_DATE = 'CONTRACT_START_DATE',
    CONTRACT_END_DATE = 'CONTRACT_END_DATE',
    BUYER_ENTITY = 'BUYER_ENTITY',
    BUYER_IDENTIFICATION = 'BUYER_IDENTIFICATION',
    BUYER_CONTACT = 'BUYER_CONTACT',
    BUYER_ADDRESS = 'BUYER_ADDRESS',
    PROJECT_CODE = 'PROJECT_CODE',
}

export enum CLMTemplateVendorFieldsEnum {
    VENDOR_ENTITY = 'VENDOR_ENTITY',
    VENDOR_IDENTIFICATION = 'VENDOR_IDENTIFICATION',
    VENDOR_CONTACT = 'VENDOR_CONTACT',
    VENDOR_ADDRESS = 'VENDOR_ADDRESS',
}

export enum CLMTemplateItemDetailsFieldsEnum {
    BUYER_SKUS = 'BUYER_SKUS',
    SELLER_SKUS = 'SELLER_SKUS',
}

export enum CLMTemplateEssentialTermsFieldsEnum {
    PRICE = 'PRICE',
    QUANTITY = 'QUANTITY',
}

export enum CLMTemplatePaymentTermsFieldsEnum {
    PREPAYMENT = 'PREPAYMENT',
    REMAINING_PAYMENT = 'REMAINING_PAYMENT',
    LEAD_TIME = 'LEAD_TIME',
    PAYMENT_METHOD = 'PAYMENT_METHOD',
}
export enum CLMTemplateAdditionalDetailsFieldsEnum {
    ADDITIONAL_INFORMATION = 'ADDITIONAL_INFORMATION',
}

export enum CLMTemplateTermsAndConditionsFieldsEnum {
    GENERAL_TERMS = 'GENERAL_TERMS',
}

export const ContractStandardFieldBackendNames: {
    [BackendTemplateItemName: string]: CLMTemplateContractFieldsEnum;
} = {
    'Contract ID': CLMTemplateContractFieldsEnum.CONTRACT_ID,
    'Contract name': CLMTemplateContractFieldsEnum.CONTRACT_NAME,
    'Contract start date': CLMTemplateContractFieldsEnum.CONTRACT_START_DATE,
    'Contract end date': CLMTemplateContractFieldsEnum.CONTRACT_END_DATE,
    'Buyer entity': CLMTemplateContractFieldsEnum.BUYER_ENTITY,
    'Buyer identification': CLMTemplateContractFieldsEnum.BUYER_IDENTIFICATION,
    'Buyer contact': CLMTemplateContractFieldsEnum.BUYER_CONTACT,
    'Buyer address': CLMTemplateContractFieldsEnum.BUYER_ADDRESS,
    Project: CLMTemplateContractFieldsEnum.PROJECT_CODE,
};

export const VendorStandardFieldBackendNames: {
    [BackendTemplateItemName: string]: CLMTemplateVendorFieldsEnum;
} = {
    'Vendor entity': CLMTemplateVendorFieldsEnum.VENDOR_ENTITY,
    'Vendor identification': CLMTemplateVendorFieldsEnum.VENDOR_IDENTIFICATION,
    'Vendor contact': CLMTemplateVendorFieldsEnum.VENDOR_CONTACT,
    'Vendor address': CLMTemplateVendorFieldsEnum.VENDOR_ADDRESS,
};

export const ItemDetailstandardFieldBackendNames: {
    [BackendTemplateItemName: string]: CLMTemplateItemDetailsFieldsEnum;
} = {
    'Buyer SKUs': CLMTemplateItemDetailsFieldsEnum.BUYER_SKUS,
    'Seller SKUs': CLMTemplateItemDetailsFieldsEnum.SELLER_SKUS,
};

export const EssentialTermsstandardFieldBackendNames: {
    [BackendTemplateItemName: string]: CLMTemplateEssentialTermsFieldsEnum;
} = {
    Price: CLMTemplateEssentialTermsFieldsEnum.PRICE,
    Quantity: CLMTemplateEssentialTermsFieldsEnum.QUANTITY,
};

export const PaymentTermsstandardFieldBackendNames: {
    [BackendTemplateItemName: string]: CLMTemplatePaymentTermsFieldsEnum;
} = {
    'Remaining payment': CLMTemplatePaymentTermsFieldsEnum.REMAINING_PAYMENT,
    'Lead time': CLMTemplatePaymentTermsFieldsEnum.LEAD_TIME,
    'Payment terms': CLMTemplatePaymentTermsFieldsEnum.PAYMENT_METHOD,
};

export const AdditionalDetailstandardFieldBackendNames: {
    [BackendTemplateItemName: string]: CLMTemplateAdditionalDetailsFieldsEnum;
} = {
    'Additional inforrmation':
        CLMTemplateAdditionalDetailsFieldsEnum.ADDITIONAL_INFORMATION,
};

export const TermsAndConditionstandardFieldBackendNames: {
    [BackendTemplateItemName: string]: CLMTemplateTermsAndConditionsFieldsEnum;
} = {
    'General terms': CLMTemplateTermsAndConditionsFieldsEnum.GENERAL_TERMS,
};

export enum CLMtandardSections {
    CONTRACT_DETAILS = 'CONTRACT_DETAILS',
    VENDOR_DETAILS = 'VENDOR_DETAILS',
    ITEM_DETAILS = 'ITEM_DETAILS',
    ESSENTIAL_TERMS = 'ESSENTIAL_TERMS',
    PAYMENT_TERMS = 'PAYMENT_TERMS',
    ADDITIONAL_DETAILS = 'ADDITIONAL_DETAILS',
    TERMS_AND_CONDITIONS = 'TERMS_AND_CONDITIONS',
}

export type CLMstandardSectionsBackendName =
    | '"Contract Details'
    | 'Vendor Details'
    | 'Item Details'
    | 'Essential Terms'
    | 'Payment Terms'
    | 'Additional Details'
    | 'Terms and Conditions';

export interface ICLMPaymentTermsOptions extends ITemplateStandardFieldOptions {
    standardFieldCategory: 'PAYMENT_TERMS';
    paymentTermOptions:
        | ITemplatePaymentTermsOptionsInvoiceItemValues
        | ITemplatePaymentTermsOptionsPoItemPercentages;
}
export interface ICLMTemplateContractFieldSetting {
    standardFields: {
        [field in CLMTemplateContractFieldsEnum]: ITemplateStandardFieldOptions;
    };
    customFields: {
        [field: string]: ITemplateCustomFieldOptions;
    };
}

export interface ICLMTemplateVendorFieldSetting {
    standardFields: {
        [field in CLMTemplateVendorFieldsEnum]: ITemplateStandardFieldOptions;
    };
    customFields: {
        [field: string]: ITemplateCustomFieldOptions;
    };
}

export interface ICLMTemplateItemFieldSetting {
    standardFields: {
        [field in CLMTemplateItemDetailsFieldsEnum]: ITemplateStandardFieldOptions;
    };
    customFields: {
        [field: string]: ITemplateCustomFieldOptions;
    };
}

export interface ICLMTemplateEssentialTermsFieldSetting {
    standardFields: {
        [field in CLMTemplateEssentialTermsFieldsEnum]: ITemplateStandardFieldOptions;
    };
    customFields: {
        [field: string]: ITemplateCustomFieldOptions;
    };
}

export interface ICLMTemplatePaymentTermsFieldSetting {
    standardFields: {
        [CLMTemplatePaymentTermsFieldsEnum.LEAD_TIME]: ITemplateStandardFieldOptions;
        [CLMTemplatePaymentTermsFieldsEnum.PAYMENT_METHOD]: ICLMPaymentTermsOptions;
        [CLMTemplatePaymentTermsFieldsEnum.PREPAYMENT]: ITemplateStandardFieldOptions;
        [CLMTemplatePaymentTermsFieldsEnum.REMAINING_PAYMENT]: ITemplateStandardFieldOptions;
    };
    customFields: {
        [field: string]: ITemplateCustomFieldOptions;
    };
}

export interface ICLMTemplateAdditionalDetailsFieldSetting {
    standardFields: {
        [field in CLMTemplateAdditionalDetailsFieldsEnum]: ITemplateStandardFieldOptions;
    };
    customFields: {
        [field: string]: ITemplateCustomFieldOptions;
    };
}

export interface ICLMTemplateTermsAndConditionsFieldSetting {
    standardFields: {
        [field in CLMTemplateTermsAndConditionsFieldsEnum]: ITemplateStandardFieldOptions;
    };
    customFields: {
        [field: string]: ITemplateCustomFieldOptions;
    };
}

export interface ITemplateStandardFieldOptions {
    label: string;
    description: string;
    buyerSettings: {
        showField: boolean;
        required: boolean;
    };
}

export interface ITemplateCustomFieldOptions
    extends ITemplateStandardFieldOptions {
    type: CustomFieldType;
    constraints: IFieldTypeConstraints;
}

// USE TEMPLATE_INTERFACE CUSTOM-FIELD-TYPE -> Global\Interfaces\TemplateInterface.ts
export enum CustomFieldType {
    SHORT_TEXT = 'SHORT_TEXT',
    LONG_TEXT = 'LONG_TEXT',
    SHORTTEXT = 'SHORTTEXT',
    LONGTEXT = 'LONGTEXT',
    DECIMAL = 'DECIMAL',
    LOCATION = 'LOCATION',
    CURRENCY = 'CURRENCY',
    INTEGER = 'INTEGER',
    CHOICE = 'CHOICE',
    BOOLEAN = 'BOOLEAN',
    DATE = 'DATE',
    DATETIME = 'DATETIME',
    FLOAT = 'FLOAT',
    MULTI_CHOICE = 'MULTI_CHOICE',
    ATTACHMENT = 'ATTACHMENT',
    TEMPLATE = 'TEMPLATE',
}

export interface ICLMTemplate {
    templateId: string;
    templateName: string;
    buyerEntityId: string;
    buyerEntityName: string;
    fieldSettingsContract: ICLMTemplateContractFieldSetting;
    fieldSettingsVendor: ICLMTemplateVendorFieldSetting;
    fieldSettingsItem: ICLMTemplateItemFieldSetting;
    fieldSettingsEssentialTerms: ICLMTemplateEssentialTermsFieldSetting;
    fieldSettingsPaymentTerms: ICLMTemplatePaymentTermsFieldSetting;
    fieldSettingsAdditionalDetails: ICLMTemplateAdditionalDetailsFieldSetting;
    fieldSettingsTermsAndConditions: ICLMTemplateTermsAndConditionsFieldSetting;
    sectionContract: ICLMTemplateContractSections;
    sectionVendor: ICLMTemplateVendorSections;
    sectionItem: ICLMTemplateItemSections;
    sectionEssentialTerms: ICLMTemplateEssentialTermsSections;
    sectionPaymentTerms: ICLMTemplatePaymentTermsSections;
    sectionAdditionalDetails: ICLMTemplateAdditionalDetailsSections;
    sectionTermsAndConditions: ICLMTemplateTermsAndConditionsSections;
}

export type ICLMTemplateContractSections = {
    [section in CLMtandardSections.CONTRACT_DETAILS]: ICLMTemplateContractSectionDetails;
};

export type ICLMTemplateVendorSections = {
    [section in CLMtandardSections.VENDOR_DETAILS]: ICLMTemplateVendorSectionDetails;
};

export type ICLMTemplateItemSections = {
    [section in CLMtandardSections.ITEM_DETAILS]: ICLMTemplateItemSectionDetails;
};

export type ICLMTemplateEssentialTermsSections = {
    [section in CLMtandardSections.ESSENTIAL_TERMS]: ICLMTemplateEssentialTermsSectionDetails;
};

export type ICLMTemplatePaymentTermsSections = {
    [section in CLMtandardSections.PAYMENT_TERMS]: ICLMTemplatePaymentTermsSectionDetails;
};

export type ICLMTemplateAdditionalDetailsSections = {
    [section in CLMtandardSections.ADDITIONAL_DETAILS]: ICLMTemplateAdditionalDetailsSectionDetails;
};

export type ICLMTemplateTermsAndConditionsSections = {
    [section in CLMtandardSections.TERMS_AND_CONDITIONS]: ICLMTemplateTermsAndConditionsSectionDetails;
};

export interface ICLMTemplateContractSectionDetails {
    label: string;
    fieldList: ISectionContractField[];
}

export interface ICLMTemplateVendorSectionDetails {
    label: string;
    fieldList: ISectionVendorField[];
}

export interface ICLMTemplateItemSectionDetails {
    label: string;
    fieldList: ISectionItemField[];
}

export interface ICLMTemplateEssentialTermsSectionDetails {
    label: string;
    fieldList: ISectionEssentialTermsField[];
}

export interface ICLMTemplatePaymentTermsSectionDetails {
    label: string;
    fieldList: ISectionPaymentTermsField[];
}

export interface ICLMTemplateAdditionalDetailsSectionDetails {
    label: string;
    fieldList: ISectionAdditionalDetailsField[];
}

export interface ICLMTemplateTermsAndConditionsSectionDetails {
    label: string;
    fieldList: ISectionTermsAndConditionsField[];
}

export type ISectionContractField =
    | {
          fieldType: 'STANDARD';
          field: CLMTemplateContractFieldsEnum;
      }
    | {
          fieldType: 'CUSTOM';
          field: string;
      };

export type ISectionVendorField =
    | {
          fieldType: 'STANDARD';
          field: CLMTemplateVendorFieldsEnum;
      }
    | {
          fieldType: 'CUSTOM';
          field: string;
      };

export type ISectionItemField =
    | {
          fieldType: 'STANDARD';
          field: CLMTemplateItemDetailsFieldsEnum;
      }
    | {
          fieldType: 'CUSTOM';
          field: string;
      };

export type ISectionEssentialTermsField =
    | {
          fieldType: 'STANDARD';
          field: CLMTemplateEssentialTermsFieldsEnum;
      }
    | {
          fieldType: 'CUSTOM';
          field: string;
      };

export type ISectionPaymentTermsField =
    | {
          fieldType: 'STANDARD';
          field: CLMTemplatePaymentTermsFieldsEnum;
      }
    | {
          fieldType: 'CUSTOM';
          field: string;
      };

export type ISectionAdditionalDetailsField =
    | {
          fieldType: 'STANDARD';
          field: CLMTemplateAdditionalDetailsFieldsEnum;
      }
    | {
          fieldType: 'CUSTOM';
          field: string;
      };

export type ISectionTermsAndConditionsField =
    | {
          fieldType: 'STANDARD';
          field: CLMTemplateTermsAndConditionsFieldsEnum;
      }
    | {
          fieldType: 'CUSTOM';
          field: string;
      };

export interface ICLMTemplateBackendResponse {
    template_id: string;
    section_list: Array<{
        section_id: string;
        section_items: Array<{
            section_item_id: string;
            created_datetime: string;
            modified_datetime: string;
            deleted_datetime: any;
            sequence: number;
            name: string;
            alternate_name: string;
            description: string;
            constraints: {
                choices?: Array<any>;
                field_type: string;
                choice_type?: string;
                max_limit?: number;
                min_limit?: number;
                decimal_places?: number;
            };
            can_remove: boolean;
            is_builtin_field: boolean;
            is_required: boolean;
            is_variable: boolean;
            additional_information: {
                is_hidden: boolean;
                //is_visible: boolean;
            };
            created_by_user: string;
            modified_by_user: string;
            deleted_by_user: any;
            section: string;
            parent_section_item: any;
        }>;
        created_datetime: string;
        modified_datetime: string;
        deleted_datetime: any;
        name: string;
        alternate_name: string;
        section_type: string;
        description: string;
        sequence: number;
        is_visible: boolean;
        is_optional: boolean;
        created_by_user: string;
        modified_by_user: string;
        deleted_by_user: any;
        template: string;
    }>;
    entity: {
        entity_name: string;
        entity_id: string;
    };
    created_datetime: string;
    modified_datetime: string;
    deleted_datetime: any;
    name: string;
    type: string;
    description: string;
    tags: Array<string>;
    is_global: boolean;
    status: string;
    published_on: any;
    created_on: string;
    last_edited_on: string;
    created_by_user: string;
    modified_by_user: string;
    deleted_by_user: any;
    enterprise: string;
    creator: string;
    last_edited_by: string;
}

export interface IContractCustomField {
    name: string;
    description: string;
    type: CustomFieldType;
    value: string;
}

export interface IContractCustomFieldsBackend {
    section_list: {
        name: string;
        fields: IContractCustomField[];
    }[];
}
