import {
    IItemAnalyticsDashboardData,
    IItemCartLevelCustomField,
} from './itemCartItemInterfaces';

export const ITEM_ANALYTICS_ROW_GAP = 2;
export const ITEM_ANALYTICS_GAP = 2;
export const ITEM_ANALYTICS_FIELD_SIZE = 2;
export const CART_ITEM_PER_PAGE = 10;

export enum TransactionStatusEnum {
    ALL = 'ALL',
    DRAFT = 'DRAFT',
    REVISED = 'REVISED',
    AWARD_STAGE = 'AWARD_STAGE',
    APPROVAL_PENDING = 'APPROVAL_PENDING',
    REWORK = 'REWORK',
    REJECTED = 'REJECTED',
    ONGOING = 'ONGOING',
    PAUSED = 'PAUSED',
    PURCHASE_ORDER_ISSUED = 'PURCHASE_ORDER_ISSUED',
    PURCHASE_ORDER_ACCEPTED = 'PURCHASE_ORDER_ACCEPTED',
    TERMINATED = 'TERMINATED',
    COMPLETED = 'COMPLETED',
}

export const TransactionStatusEnumNameMapping = {
    [TransactionStatusEnum.DRAFT]: 'Draft',
    [TransactionStatusEnum.REVISED]: 'Revised',
    [TransactionStatusEnum.AWARD_STAGE]: 'Award stage',
    [TransactionStatusEnum.APPROVAL_PENDING]: 'Approval pending',
    [TransactionStatusEnum.REWORK]: 'Rework',
    [TransactionStatusEnum.REJECTED]: 'Rejected',
    [TransactionStatusEnum.ONGOING]: 'Ongoing',
    [TransactionStatusEnum.PAUSED]: 'Paused',
    [TransactionStatusEnum.PURCHASE_ORDER_ISSUED]: 'PO issued',
    [TransactionStatusEnum.PURCHASE_ORDER_ACCEPTED]: 'PO accepted',
    [TransactionStatusEnum.TERMINATED]: 'Terminated',
    [TransactionStatusEnum.COMPLETED]: 'Completed',
};

export const TransactionStatusEnumEnumMapping: {
    [key: string]: TransactionStatusEnum;
} = {
    Draft: TransactionStatusEnum.DRAFT,
    Revised: TransactionStatusEnum.REVISED,
    'Award stage': TransactionStatusEnum.AWARD_STAGE,
    'Approval pending': TransactionStatusEnum.APPROVAL_PENDING,
    Rework: TransactionStatusEnum.REWORK,
    Rejected: TransactionStatusEnum.REJECTED,
    Ongoing: TransactionStatusEnum.ONGOING,
    Paused: TransactionStatusEnum.PAUSED,
    'PO issued': TransactionStatusEnum.PURCHASE_ORDER_ISSUED,
    'PO accepted': TransactionStatusEnum.PURCHASE_ORDER_ACCEPTED,
    Terminated: TransactionStatusEnum.TERMINATED,
    Completed: TransactionStatusEnum.COMPLETED,
};

export interface ITranscations {
    modified_datetime: string;
    custom_event_id: string;
    sub_event_id: string;
    sub_event_item_id: string;
    event_type: string;
    event_name: string;
    sub_event_status: string;
    vendor_count: number;
    offer_count: number;
    currency_symbol: string;
    currency_code_abbreviation: string;
    po_count: number;
    quantity_measurement_unit_name: string;
    quantity: number;
    po_custom_ids: string[];
    po_seller_entity_names: string[];
    avg_eff_rate: number;
    amount: number;
}

export interface IItemAnalyticsNotes {
    note_id: string;
    creator: string;
    title: string;
    notes: string;
    buyer_entity: string;
}

export interface IBlankNote {
    title: string;
    module: string;
    buyer_entity_id: string;
    notes: string;
    enterprise_item_id: string;
}
export interface IBlankNoteCRM {
    title: string;
    module: string;
    buyer_entity_id: string;
    notes: string;
    enterprise_buyer_id: string;
}

export enum NotesModuleEnum {
    ITEM_ANALYTICS = 'ITEM_ANALYTICS',
}

export enum NotesModuleEnumCRM {
    CRM = 'CRM',
}

export enum ItemCartModuleSectionEnum {
    CART = 'CART',
    SAVE_FOR_LATER = 'SAVE_FOR_LATER',
    ORDERS = 'ORDERS',
    ALL = 'ALL',
}

export const ItemCartModuleNameMapping = {
    [ItemCartModuleSectionEnum.CART]: 'Cart',
    [ItemCartModuleSectionEnum.SAVE_FOR_LATER]: 'Save for later',
    [ItemCartModuleSectionEnum.ORDERS]: 'Orders',
    [ItemCartModuleSectionEnum.ALL]: 'All',
};

export const ItemCartModuleEnumMapping: {
    [key: string]: ItemCartModuleSectionEnum;
} = {
    Cart: ItemCartModuleSectionEnum.CART,
    'Save for later': ItemCartModuleSectionEnum.SAVE_FOR_LATER,
    // Orders: ItemCartModuleSectionEnum.ORDERS,
    All: ItemCartModuleSectionEnum.ALL,
};

export type itemCartTabSortFieldColumns =
    | 'created_datetime'
    | 'modified_datetime'
    | 'custom_cart_id'
    | 'cart_name'
    | 'cart_owner_name'
    | 'item_count'
    | 'buyer_entity_name';

export type itemAnalyticsTabSortFieldColumns =
    | 'created_datetime'
    | 'modified_datetime'
    | 'custom_cart_id'
    | 'cart_name'
    | 'cart_owner_name'
    | 'item_count'
    | 'buyer_entity_name';

export interface IItemCartDashboardData {
    cart_id: string;
    buyer_entity_name: string;
    cart_owner_name: string;
    item_count: number;
    cart_item_names: string[];
    created_datetime: string;
    modified_datetime: string;
    deleted_datetime: string | null;
    custom_cart_id: string;
    cart_name: string;
    created_by_user: string;
    modified_by_user: string;
    deleted_by_user: string | null;
    buyer_enterprise: string;
    buyer_entity: string;
    cart_owner: string;
}

export interface IFetchItemCartDashboardData {
    data: IItemCartDashboardData[];
    metadata: {
        current_page: number;
        has_next: boolean;
        has_previous: boolean;
        total_pages: number;
        page_range: {
            start: number;
            stop: number;
        };
        count: number;
    };
    counts: {
        all: number;
    };
}

export interface ICartItemHistoryLogs {
    purchase_order_item_id: string;
    purchase_order: {
        purchase_order_id: string;
        custom_purchase_order_id: 'PO000428';
        internal_status: string;
        status: string;
        buyer_entity: string;
        buyer_entity_name: string;
        seller_entity: string;
        seller_entity_name: string;
        purchase_order_type: string;
        created_by_user: string;
        created_by_user_name: string;
        final_buyer_approval: string;
        final_buyer_approval_name: string;
        final_seller_approval: null;
        submission_datetime: string;
        acceptance_datetime: null;
    };
    event: string;
    buyer_enterprise: string;
    seller_enterprise: string;
    buyer_entity: string;
    seller_entity: string;
    buyer_item: null;
    item_information: {
        custom_ids: { name: string; value: string }[];
        buyer_item_id: string;
        global_item_id: string;
        buyer_item_name: string;
        custom_item_name: string;
        buyer_item_description: string;
        item_additional_details: string;
    };
    fulfilment_information: {
        on_hold: number;
        invoiced: number;
        delivered: number;
        discarded: number;
        tolerated: number;
        rescheduled: number;
        over_delivered: number;
    };
    quantity_information: {
        quantity: number;
        measurement_unit_id: string;
        measurement_unit_name: string;
        quantity_tolerance_percentage: number;
        alternate_measurement_unit_list: any[];
    };
    pricing_information: {
        price: number;
        shipping_per_unit: number;
        currency_code_id: string;
        currency_name: string;
        currency_symbol: string;
        currency_code_abbreviation: string;
        total: number;
        additional_charges: any[];
        taxes: any[];
        additional_costs: any[];
        discounts: any[];
    };
    attribute_information: {
        buyer_item_attributes: {
            attribute_name: string;
            attribute_value: string[];
            attribute_exclude: boolean;
        }[];
        custom_item_attributes: any[];
    };
    property_information: any[];
    additional_details: {
        po_group_id: string;
        po_group_item_id: string;
    };
    incoterm: string;
    incoterm_abbreviation: string;
    prepayment_percentage: 0;
    payment_type: string;
    payment_terms: {
        term: 1;
        period: string;
        applied_from: string;
    };
    deliverables_payment_terms: null;
    procurement_information: {
        lead_time: 4;
        lead_time_period: string;
    };
    internal_notes: {
        notes: '';
        modified_datetime: string;
        modified_by_user_id: string;
    };
    external_notes: {
        notes: '';
        modified_datetime: string;
        modified_by_user_id: string;
    };
    internal_status: string;
    created_by_user: string;
    created_datetime: string;
    modified_datetime: string;
    delivery_schedule_items: [
        {
            delivery_schedule_item_id: string;
            delivery_schedule: string;
            purchase_order_item: string;
            quantity: 1;
            delivery_date: null;
            requisition_department: null;
            department: null;
            buyer_enterprise: string;
            seller_enterprise: string;
            buyer_entity: string;
            seller_entity: string;
            general_ledger: null;
            cost_centre: null;
            project: null;
            parent_delivery_schedule_item: null;
        }
    ];
    attachments: [];
    custom_fields: {
        section_list: { name: string; field: IItemCartLevelCustomField[] }[];
    };
    custom_fields_negotiate: {
        section_list: { name: string; field: IItemCartLevelCustomField[] }[];
    };
    additional_costs: any[];
    taxes: any[];
    discounts: any[];
}
export interface IFetchCartItemHistoryDashboardData {
    data: ICartItemHistoryLogs[];
    metadata: {
        current_page: number;
        has_next: boolean;
        has_previous: boolean;
        total_pages: number;
        page_range: {
            start: number;
            stop: number;
        };
        count: number;
    };
    counts: {
        all: number;
    };
}

export interface IFetchItemAnalyticsDashboardData {
    data: IItemAnalyticsDashboardData[];
    metadata: {
        current_page: number;
        has_next: boolean;
        has_previous: boolean;
        total_pages: number;
        page_range: {
            start: number;
            stop: number;
        };
        count: number;
    };
    counts: {
        all: number;
    };
}

export interface IItemCustomtext {
    custom_text_id: string;
    created_datetime: string;
    modified_datetime: string;
    deleted_datetime: string | null;
    text: string;
    created_by_user: string;
    modified_by_user: string;
    deleted_by_user: string | null;
    enterprise_item: string;
}

export interface IItemCustomtextDashboardResponse {
    data: IItemCustomtext[];
    metadata: {
        current_page: number;
        has_next: boolean;
        has_previous: boolean;
        total_pages: number;
        page_range: {
            start: number;
            stop: number;
        };
        count: number;
    };
    counts: {
        all: number;
    };
}

export interface IFetchItemAnalyticsEntityDashboardData {
    data: IItemAnalyticsEntityDashboardData[];
    metadata: {
        current_page: number;
        has_next: boolean;
        has_previous: boolean;
        total_pages: number;
        page_range: {
            start: number;
            stop: number;
        };
        count: number;
    };
    counts: {
        all: number;
    };
}

export interface IItemAnalyticsEntityDashboardData {
    entity_item_id: string;
    custom_text_count: 0;
    linked_currencies_count: 0;
    indices_count: 0;
    enterprise_item: {
        enterprise_item_id: string;
        modified_by_user_name: string;
        created_datetime: string;
        modified_datetime: string;
        deleted_datetime: null;
        code: string;
        name: string;
        item_type: string;
        description: string;
        notes: string;
        internal_notes: string | null;
        measurement_units: {
            item_measurement_units: {
                abbreviation: string;
                measurement_unit_id: string;
                measurement_unit_category: string;
                measurement_unit_value_type: string;
                measurement_unit_primary_name: string;
            }[];
        };
        attributes: {
            attributes: {
                attribute_name: string;
                attribute_value: string[];
                attribute_exclude: false;
            }[];
        };
        custom_ids: {
            custom_ids: {
                name: string;
                value: string;
            }[];
        };
        is_buyer: false;
        buyer_pricing_information: {
            price: number;
            currency_name: string;
            currency_symbol: string;
            currency_code_id: string;
            currency_code_abbreviation: string;
        };
        is_seller: true;
        seller_pricing_information: {
            price: number;
            currency_name: string;
            currency_symbol: string;
            currency_code_id: string;
            currency_code_abbreviation: string;
        };
        status: string;
        preferred_vendor_status: string;
        tags: string[];
        linked_currencies: string[] | null;
        primary_index: string | null;
        secondary_indices: string[] | null;
        custom_fields: {
            section_list: { name: string; field: IItemCartLevelCustomField }[];
        };
        additional_details: {
            template_id: string;
        };
        created_by_user: string;
        modified_by_user: string;
        deleted_by_user: string | null;
        buyer_enterprise: string;
    };
    created_datetime: string;
    modified_datetime: string;
    deleted_datetime: string | null;
    status: string;
    created_by_user: string;
    modified_by_user: string;
    deleted_by_user: string | null;
    buyer_enterprise: string;
    buyer_entity: string;
}

export interface IItemIndices {
    symbol: string;
    description: string;
    type: string;
    exchange: string;
    currency_code: string;
    logoid: string;
    provider_id: string;
    source2: {
        id: string;
        name: string;
        description: string;
    };
    source_id: string;
    typespecs: string[];
}

export interface IItemRSSFeed {
    title: string;
    summary: string;
    media_content: {
        height: number;
        medium: string;
        type: string;
        url: string;
        width: string;
    }[];
    published: string;
    link: string;
    author: string;
}
