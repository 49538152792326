import { IDeliveryScheduleList } from '../../Common/FWDeliverySchedulePopup';
import { IRFQItemCustomIDs } from '../../Events/Interfaces/Buyer/SharedRFQ.model';
import {
    CustomTemplateItemTypes,
    CustomTemplateTypes,
} from '../../Global/Interfaces/CustomTemplate.interface';
import {
    IItemHoldTerminationInfo,
    IPOHoldTerminationInfo,
    IPOItemHoldTerminationInfo,
} from '../../Global/Interfaces/ItemAndPOHelperInterfaces';
import { CustomFieldType } from '../../Global/Interfaces/TemplateInterface';
import { INewGetInvoiceBatchDetail } from '../../Invoices/Interfaces/NewInvoice.model';
import { IAttachment } from '../../Models/RFQ.model';

export interface IPageDetails {
    current_page: number;
    has_next: boolean;
    has_previous: boolean;
    total_pages: number;
}

export enum GRDashboardType {
    PENDING = 'pending',
    PAST = 'past',
}

export interface IExtraCustomFields {
    field1: string;
    field2: string;
    field3: string;
    field4: string;
}

//MrTea:Interface of Response of GR Pending List recived from API
export interface GrInvoiceResponseInterface {
    invoice_id: string | null;
    custom_invoice_id: string | null;
    reference_id: string | null;
    event_id: string;
    status: string;
    buyer_entity: string;
    seller_entity: string;
    verification_status: string;
    buyer_information: {
        billing_address: string;
        shipping_address: string;
        buyer_entity_name: string;
        billing_address_id: string;
        shipping_address_id: string;
        buyer_entity_address: string;
        buyer_entity_address_id: string;
        billing_address_nickname: string;
        buyer_identification_info: {
            identification_id: string;
            identification_name: string;
            identification_value: string;
        }[];
        shipping_address_nickname: string;
        buyer_entity_address_nickname: string;
    };
    seller_information: {
        seller_address_id: string;
        seller_entity_name: string;
        seller_entity_address: string;
        seller_address_nickname: string;
        seller_identification_information: {
            identification_id: string;
            identification_name: string;
            identification_value: string;
        }[];
    };
    item_hold_status: string;
    item_termination: string;
    invoice_date: string | null;
    dispatch_date: string;
    expected_delivery_date: string;
    invoice_item_count: number;
    goods_receipt_count: number;
    purchase_order: {
        purchase_order_id: string;
        event: string;
        custom_purchase_order_id: string;
        status: string;
        hold_information: string;
        delivery_information: {
            lot_count: number;
            delivery_date: string | null;
        };
        termination_information: {
            status: string;
        } | null;
    };
}
export interface IGRInvoiceListSummary extends IPOItemHoldTerminationInfo {
    poId: string;
    poUid: string;
    invoiceId: string;
    invoiceUid: string;
    invoiceDate: string;
    buyerEntityName: string;
    vendorName: string;
    deliveryDate: string | null;
    lots: number;
    noOfItems: number;
    grCount: number;
    status: string;
    shippingAddress: {
        nickName: string;
        fullAddress: string;
    };
    measurementUnit: string;
    invoiceVerified: boolean;
    hasInvoice: boolean;
    referenceId: string | null;
    entityUid: string;
    pendingInvoiceCount?: number;
}

export interface IGRItemSummary {
    itemId: string;
    itemName: string;
    description: string;
    additionalInfo: string;
    attributes: IItemAttributes;
    customIDs: IRFQItemCustomIDs[];
}

//MrTea:Interface of Response of GR Completed List recived from API
export interface GoodsReceiptsResponseInterface {
    goods_receipt_id: string;
    custom_goods_receipt_id: string;
    invoice: string;
    buyer_entity: string;
    goods_receipt_date: string;
    created_by_user_name: string;
    created_by_user_id: string;
    modified_by_user_id: string;
    created_datetime: string;
    modified_datetime: string;
    quantity_information: {
        qc_accepted: string;
        qc_rejected: string;
        total_quantity: string;
        quantity_accepted: string;
        measurement_unit_id: string;
        measurement_unit_name: string;
        quantity_over_delivered: string;
        measurement_unit_category: string;
        measurement_unit_value_type: string;
    };
    buyer_information: {
        billing_address: string;
        shipping_address: string;
        buyer_entity_name: string;
        billing_address_id: string;
        shipping_address_id: string;
        buyer_entity_address: string;
        buyer_entity_address_id: string;
        billing_address_nickname: string;
        buyer_identification_info: {
            identification_id: string;
            identification_name: string;
            identification_value: string;
        };
        shipping_address_nickname: string;
        buyer_entity_address_nickname: string;
    };
    seller_information: {
        seller_address_id: null;
        seller_entity_name: string;
        seller_entity_address: string;
        seller_address_nickname: string;
        seller_identification_information: {
            identification_id: string;
            identification_name: string;
            identification_value: string;
        }[];
    };
    additional_information: {
        invoice_information: {
            invoice_id: string;
            invoice_date: string;
            reference_id: null;
            custom_fields: {};
            dispatch_date: string;
            invoice_creator: string;
            custom_invoice_id: string;
            invoice_creator_name: string;
            expected_delivery_date: string;
        };
        purchase_order_information: {
            purchase_order_id: string;
            seller_contact_list: {
                seller_contact_id: null;
                seller_contact_name: string;
                seller_contact_email: string;
                seller_contact_phone: string;
            }[];
            purchase_order_approver: string;
            custom_purchase_order_id: string;
            purchase_order_approver_name: string;
            purchase_order_submission_datetime: string;
        };
    };
    invoice_item: {
        invoice_item_id: string;
        purchase_order_item: string;
        batch_number: string;
        batch_expiry_date: string;
        item_information: {
            custom_ids: {
                name: string;
                value: string;
            };
            buyer_item_id: string;
            global_item_id: string;
            buyer_item_name: string;
            custom_item_name: string;
            buyer_item_description: string;
            item_additional_details: string;
        };
        quantity_information: {
            quantity: string;
            measurement_unit_id: string;
            measurement_unit_name: string;
            measurement_unit_category: string;
            measurement_unit_value_type: string;
            quantity_tolerance_percentage: string;
        };
        fulfilment_information: {
            terminated: string;
            gr_rejection: string;
            qc_rejection: string;
            gr_acceptance: string;
        };
        attribute_information: {
            buyer_item_attributes: {
                attribute_name: string;
                attribute_value: string[];
                attribute_exclude: boolean;
            }[];
            custom_item_attributes: any[];
        };
        status: string;
        notes: string;
        custom_fields: {};
    };
    attachments: any[];
}

export interface IGRPastListSummary {
    grnNo: string;
    grnUid: string;
    vendorName: string;
    buyerEntityName: string;
    deliveryDate: string | null;
    grnIssueDate: string;
    grnCreator: string;
    status: string;
    poId: string;
    poItemUid: string;
    invoiceId: string;
    invoiceUid: string;
    itemName: string;
    grnAcceptedQty: number;
    grnRejectedQty: number;
    grnInvoicedQty: number;
    itemDetails: {
        name: string;
        itemId: string;
        description: string;
        additionalInfo: string;
        attributes: IItemAttributes;
    };
    measurementUnit: string;
}

export interface IGRInvoiceDetails extends IPOHoldTerminationInfo {
    vendorName: string;
    poId: string;
    poUid: string;
    invoiceId: string;
    contactPerson: string;
    contactPersonUid: string;
    createdOn: string;
    deliveryDate: string | null;
    invoiceVerified: boolean;
    invoiceCreated: boolean;
    referenceId: string | null;
    attachments: IAttachment[];
    entityUid: string;
    vendorContact: string;
    vendorContactUid: string;
}

export enum ItemGRStatus {
    GOODS_RECEIVED = 'GOODS_RECEIVED',
    GOODS_PENDING = 'GOODS_PENDING',
    TERMINATED = 'Terminated',
    COMPLETED = 'Completed',
}

interface IItemAttributes {
    buyerAttributes: {
        name: string;
        value: string[];
    }[];
    customAttributes: {
        name: string;
        value: string[];
    }[];
}

export interface IInvoiceItemsSummary extends IItemHoldTerminationInfo {
    id: string;
    uId: string;
    itemId: string;
    invoiceItemUid: string;
    name: string;
    batchNo: string;
    invoiceItemBatchUid: string;
    batchNotes: string;
    batchExpiryDate: string;
    pendingQuantity: number;
    invoicedBatchQuantity: string;
    measurementUnit: string;
    measurementUnitId: string;
    measurementType: string;
    grAcceptedQuantity: string;
    grRejectedQuantity: string;
    grOverDeliveredQuantity: string;
    qcRejectionQuantity: string;
    qcAcceptedQuantity: string;
    itemStatus: ItemGRStatus;
    rejectionReason: string;
    allowedToleranceFromPO: string;
    toleranceQty: string;
    description: string;
    additionalInfo: string;
    attributes: IItemAttributes;
    grnUid: string | null;
    expectedDeliveryDate: string | null;
    poItemUid: string;
    attachments: IAttachment[];
    customIDs: IRFQItemCustomIDs[];
    customFields: {
        template_id: string;
        template_name: string;
        type: CustomTemplateTypes.GRN_CUSTOM_FIELDS;
        enterprise: string;
        entity: string;
        items: {
            value: string;
            template_item_id: string;
            template_item_name: string;
            template_item_type: CustomFieldType;
            is_required: boolean;
            template: string;
        }[];
    };
}

export interface IGroupedInvoiceItemsSummary extends IItemHoldTerminationInfo {
    index: number;
    itemName: string;
    pendingQuantity: number;
    remainingQuantity: number;
    measurementUnit: string;
    expectedDeliverySchedule: IDeliveryScheduleList[];
    itemDetails: IGRItemSummary;
    isGRDone: boolean;
    checked: boolean;
    poItemUid: string;
    checklist: {
        template_id: string;
        template_name: string;
        type: CustomTemplateTypes.GRN_CHECKLIST;
        enterprise: string;
        entity: string;
        items: {
            value: string;
            template_item_id: string;
            template_item_name: string;
            template_item_type: CustomFieldType;
            is_required: boolean;
            template: string;
        }[];
    }[];
    negativeTolerance: number;
    positiveTolerance: number;
    plusToleranceQty: number;
    minusToleranceQty: number;
    itemsList: IInvoiceItemsSummary[]; // Lit of batches
}

export interface IGroupedInvoiceItemsDict {
    [invoiceItemId: string]: IGroupedInvoiceItemsSummary;
}

export interface IGRNDetails {
    grnUid: string;
    grnNo: string;
    grCreatedBy: string;
    grCreatedByDate: string;
    grContactUid: string;
    poId: string;
    poCreatedBy: string;
    poCreatedByDate: string;
    poContactUid: string;
    invoiceId: string;
    invoiceUid: string;
    invoiceCreatedBy: string;
    invoiceCreatedByDate: string;
    invoiceContactUid: string;
    vendorName: string;
    vendorContactName: string;
    vendorContactUid: string;
    receivedBy: string;
    grnIssueDate: string | null;
    referenceId: string;
    invoiceAttachments: IAttachment[];
}

export interface IGRNItemSummary {
    itemId: string;
    batchNo: string;
    name: string;
    description: string;
    attributes: IItemAttributes;
    invoiceQuantity: number;
    acceptedQuantity: number;
    rejectedQuantity: number;
    overdeliveredQuantity: number;
    toleranceQuantity: number;
    measurementUnit: string;
    deliveryDate: string | null;
    rejectionReason: string;
    attachments: IAttachment[];
    poItemUid: string;
    customFields: {
        template_id: string;
        template_name: string;
        template_type: CustomTemplateTypes;
        template_items: {
            value: string;
            template: string;
            is_required: boolean;
            template_item_id: string;
            template_item_name: string;
            template_item_type: CustomTemplateItemTypes;
        }[];
    };
    checklists: {
        template_id: string;
        template_name: string;
        template_type: CustomTemplateTypes;
        template_items: {
            value: string;
            template: string;
            is_required: boolean;
            template_item_id: string;
            template_item_name: string;
            template_item_type: CustomTemplateItemTypes;
        }[];
    }[];
}

export interface IGRPOListSummary {
    poId: string;
    poUid: string;
    poOnHold: boolean;
    poTermination: boolean;
    poTerminationAccepted: boolean;
    entityUid: string;
    vendorName: string;
    itemCount: number;
    createdBy: string;
}

export enum PODocumentType {
    INVOICE = 'Invoice',
    OTHER_DOCUMENT = 'Other document',
}

export enum PODocumentStatus {
    CONFIRMED = 'CONFIRMED',
    CONFIRMATION_PENDING = 'CONFIRMATION_PENDING',
}

export enum InvoiceType {
    SELLER_GOODS_INVOICE = 'SELLER_GOODS_INVOICE',
    BUYER_GR_INVOICE = 'BUYER_GR_INVOICE',
}

export interface IGRPODocumentListSummary {
    invoiceId: string;
    invoiceUid: string;
    referenceId: string;
    vendorName: string;
    totalItems: number;
    documentStatus: PODocumentStatus;
    documentType: PODocumentType;
    invoiceVerified: boolean;
    invoiceType: InvoiceType;
}

export interface IGRInvoiceItemBatchGetApi extends INewGetInvoiceBatchDetail {
    batch_expiry_date: string;
    invoice_item_batch_id: string;
}

export interface IGRInvoiceItemGetApi {
    invoice_item_id: string;
    purchase_order_item: string;
    batch_items: IGRInvoiceItemBatchGetApi[];
    item_information: {
        custom_ids: IRFQItemCustomIDs[];
        buyer_item_id: string;
        global_item_id: string;
        buyer_item_name: string;
        custom_item_name: string;
        buyer_item_description: string;
        item_additional_details: string;
    };
    hold_information: null | { [key: string]: any };
    termination_information: null | { [key: string]: any };
    attribute_information: {
        buyer_item_attributes: string[];
        custom_item_attributes: string[];
    };
    quantity_information: {
        quantity: string;
        measurement_unit_id: string;
        measurement_unit_name: string;
        measurement_unit_category: string;
        measurement_unit_value_type: string;
        quantity_tolerance_percentage: string;
    };
    fulfilment_information: {
        terminated: string;
        qc_accepted: string;
        gr_rejection: string;
        qc_rejection: string;
        gr_acceptance: string;
        gr_overdeliver: string;
    };
    additional_information: {
        // later updated this structure to actual structure when it get used
        [key: string]: any;
    };
    po_item_quantity_information: {
        quantity: string;
        measurement_unit_id: string;
        measurement_unit_name: string;
        quantity_tolerance_percentage: string;
        alternate_measurement_unit_list: string[]; // not sure what it will be
    };
    po_item_fulfilment_information: {
        on_hold: string;
        invoiced: string;
        delivered: string;
        discarded: string;
        rescheduled: string;
        over_delivered: string;
    };
    status: ItemGRStatus;
    notes: string;
    checklist: {
        template_id: string;
        template_name: string;
        type: CustomTemplateTypes.GRN_CHECKLIST;
        enterprise: string;
        entity: string;
        items: {
            value: string;
            template_item_id: string;
            template_item_name: string;
            template_item_type: CustomTemplateItemTypes;
            is_required: boolean;
            template: string;
        }[];
    };
}

export interface IGRNewCreatePayload {
    invoice_attachment_ids?: string[];
    invoice_item_id: string;
    batch_items: IGRNewCreateBatchPayload[];
    checklist?: any[]; // ask devanshi for structure
}

export interface IGRNewCreateBatchPayload {
    invoice_item_batch_id: string;
    quantity_accepted: string;
    quantity_rejected: string;
    quantity_over_delivered: string;
    quantity_tolerated: number;
    notes: string;
    gr_attachment_ids: string[];
    custom_fields?: IGRNewCreateBatchCustomFieldPayload | null; // confirm structure with devanshi
    gr_custom_fields?: IGRNewCreateBatchCustomFieldPayload | null; // confirm structure with devanshi
    gr_checklist?: any[];
}

export interface IGRNewCreateBatchCustomFieldPayload {
    template_id: string;
    template_name: string;
    template_type: CustomTemplateTypes.GRN_CUSTOM_FIELDS;
    template_items: {
        template: string;
        template_item_id: string;
        value: string;
        template_item_name: string;
        template_item_type: CustomFieldType;
        is_required: boolean;
    }[];
    item_checklist?: any;
}

// tier 2 create gr interfaces
export interface IGRTier2CreateBatchPayload
    extends Omit<IGRNewCreateBatchPayload, 'invoice_item_batch_id'> {
    quantity: number;
    batch_expiry_date: string | null;
    custom_batch_id: string;
    gr_notes: string;
}

export interface IGRTier2CreateItemPayload {
    checklist: any[];
    batch_items: IGRTier2CreateBatchPayload[];
    purchase_order_item_id: string;
    quantity: number;
}

export interface IGRTier2CreatePayload {
    purchase_order_id: string;
    custom_invoice_id: string | null;
    reference_id: string | null;
    notes: string;
    invoice_items: IGRTier2CreateItemPayload[];
    invoice_attachment_ids: string[];
}
