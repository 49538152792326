import {
    Box,
    Checkbox,
    Chip,
    CircularProgress,
    DialogActions,
    DialogContent,
    Divider,
    Grid,
    TextField,
    Typography,
} from '@mui/material';
import { cloneDeep } from 'lodash';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import {
    IUserPermissionFEStructure,
    PermissionsPriorityList,
    SELECT_ALL_OPTION,
} from '../../BuyerTemplates/Component/ShareSectionPopup';
import FWAutocomplete, {
    AutocompleteStyledPaper,
} from '../../Common/FWAutocomplete';
import { FWButton } from '../../Common/FWButton';
import { FWMenuItem } from '../../Common/FWCustomMenu';
import { FWTooltip } from '../../Common/FWCustomTooltip';
import { FWInput } from '../../Common/FWInput';
import { FWPopupWithChildren } from '../../Common/FWPopupWithChildren';
import { FWTypography } from '../../Common/FWTypograhy';
import { HookStateValue } from '../../Common/Hooks/StateHook';
import { getFWDate, getFWDateTime } from '../../Common/Utils/DateUtils';
import { ProjectPermissions } from '../../Organizations/Admin/Interfaces/UsersInterface';
import { getProjectStatusTextAndColor } from '../helpers/projectHelpers';
import useProjectHook from '../hooks/useProjectHook';
import {
    IAssignUserAndPM,
    IProjectAssigenUserListResponse,
    IProjectEssentials,
    IProjectPayload,
    IUpdateProjectPermissionPayload,
    ProjectStatusEnum,
} from '../interface/project.model';
import {
    updateProjectPermission,
    useGetAssignUserListQuery,
} from '../services/project.services';
import UserNamePopover from './UserNamePopover';

export interface IAssignUserAndManagerFOrProjectProps {
    currentUserId: string;
    projectEssentials: IProjectEssentials;
    canUserAssignPMFromAdmin: boolean;
    canUserAssignUserFromAdmin: boolean;
    open: boolean;
    handleClose: () => void;
    isUserShortlistedAsPMForProject?: boolean;
    assignableSectionsForProject?: string[];
    currentProjectDetails?: IProjectPayload;
}

export default function AssignUserAndManagerFOrProject({
    canUserAssignPMFromAdmin,
    canUserAssignUserFromAdmin,
    projectEssentials,
    currentUserId,
    handleClose,
    isUserShortlistedAsPMForProject,
    assignableSectionsForProject,
    currentProjectDetails,
    open,
}: IAssignUserAndManagerFOrProjectProps) {
    const [userOptions, setUserOptions] = useState<
        IUserPermissionFEStructure[]
    >([]);

    const [popupHookState, setPopupHookState] = useState<HookStateValue>(
        HookStateValue.LOADING
    );

    const {
        projectDetails: projectDetailsFromHook,
        templateDetails,
        // updateProject,
        hookState,
        refetchData,
    } = useProjectHook(projectEssentials.projectId);

    useEffect(() => {
        if (hookState.state === HookStateValue.READY) {
            setPopupHookState(HookStateValue.READY);
        }

        return () => {
            setPopupHookState(HookStateValue.LOADING);
        };
    }, [hookState]);

    const projectDetails = useMemo(() => {
        if (currentProjectDetails) {
            return currentProjectDetails;
        } else return projectDetailsFromHook;
    }, [currentProjectDetails, projectDetailsFromHook]);

    const dispatch = useDispatch();
    useEffect(() => {
        if (open) {
            try {
                refetchData();
            } catch (e) {}
        }
        // return () => {
        //     dispatch(projectApiSlice.util.resetApiState());
        // };
    }, [dispatch, open, refetchData]);

    const allowedPermissionList = [
        'PROJECT_EDIT',
        'PROJECT_ASSIGN_USERS',
        'PROJECT_ASSIGN_MANAGERS',
    ];

    const [projectManagers, setProjectManagers] = useState<
        IUserPermissionFEStructure[]
    >([]);

    const [rfqResponsible, setRFQResponsible] = useState<
        IUserPermissionFEStructure[]
    >([]);

    const [assignedUserForProject, setAssignedUserForProject] =
        useState<IAssignUserAndPM>({
            PROJECT_DETAILS: [],
        });
    const [
        originallyAssignedUserForProject,
        setOriginallyAssignedUserForProject,
    ] = useState<IAssignUserAndPM>({
        PROJECT_DETAILS: [],
    });

    const [assignedUsers, setAssignedUsers] = useState<IAssignUserAndPM>({
        PROJECT_DETAILS: [],
    });

    const [assignableSections, setAssignableSections] = useState<string[]>(
        assignableSectionsForProject ?? []
    );

    const { data: usersWithProjectEditPermissionInAdmin } =
        useGetAssignUserListQuery(
            {
                permissionList: allowedPermissionList,
                entityid: projectDetails?.buyer_entity_id,
                allUsers: true,
            },
            {
                skip: !Boolean(projectDetails?.buyer_entity_id) || !open,
            }
        );

    const { data: listOfUsersWithProjectViewOrEventEditPermission } =
        useGetAssignUserListQuery(
            {
                permissionList: ['PROJECT_VIEW', 'EVENT_EDIT'],
                entityid: projectEssentials.buyerEntityId,
                allUsers: true,
            },
            {
                skip: !Boolean(projectEssentials.buyerEntityId) || !open,
            }
        );

    const usersWithProjectEditAndEventEdit = useMemo(() => {
        if (listOfUsersWithProjectViewOrEventEditPermission === undefined)
            return [];

        const uniqueList: IUserPermissionFEStructure[] = [];

        listOfUsersWithProjectViewOrEventEditPermission.forEach((user) => {
            const currentUserIndexInList = uniqueList.findIndex(
                (unqUser) => unqUser.user_id === user.user_id
            );

            if (currentUserIndexInList === -1) {
                uniqueList.push({
                    name: user.name,
                    user_id: user.user_id,
                    permissions: [user.action_api_group],
                });
            } else {
                const currentUserData = uniqueList[currentUserIndexInList];

                if (currentUserData.permissions?.length > 0) {
                    if (
                        currentUserData.permissions[0] !== user.action_api_group
                    ) {
                        uniqueList[currentUserIndexInList].permissions.push(
                            user.action_api_group
                        );
                    }
                }
            }
        });

        return uniqueList.filter((user) => user.permissions?.length === 2);
    }, [listOfUsersWithProjectViewOrEventEditPermission]);

    useEffect(() => {
        if (usersWithProjectEditPermissionInAdmin !== undefined) {
            let userPermissionListFiltered: IUserPermissionFEStructure[] = [];

            usersWithProjectEditPermissionInAdmin.forEach((user) => {
                const currUserIndexInList =
                    userPermissionListFiltered.findIndex(
                        (userInList) => userInList.user_id === user.user_id
                    );

                if (currUserIndexInList === -1) {
                    userPermissionListFiltered.push({
                        name: user.name,
                        user_id: user.user_id,
                        permissions: [user.action_api_group],
                    });
                } else {
                    userPermissionListFiltered[
                        currUserIndexInList
                    ].permissions = Array.from(
                        new Set([
                            ...(userPermissionListFiltered[currUserIndexInList]
                                .permissions as (keyof typeof PermissionsPriorityList)[]),
                            user.action_api_group as keyof typeof PermissionsPriorityList,
                        ])
                    ).sort(
                        (
                            a: keyof typeof PermissionsPriorityList,
                            b: keyof typeof PermissionsPriorityList
                        ) =>
                            PermissionsPriorityList[b] -
                            PermissionsPriorityList[a]
                    );
                }
            });

            setUserOptions(userPermissionListFiltered);
        }
    }, [usersWithProjectEditPermissionInAdmin]);

    const isUserShortlistedAsPM = useMemo(() => {
        return (
            isUserShortlistedAsPMForProject ||
            (canUserAssignPMFromAdmin &&
                (templateDetails.assigned_users.TEMPLATE_LEVEL?.length > 0
                    ? templateDetails.assigned_users.TEMPLATE_LEVEL.some(
                          (user) =>
                              user.user_id === currentUserId &&
                              user.permission ===
                                  ProjectPermissions.PROJECT_ASSIGN_MANAGERS
                      )
                    : true)) ||
            projectDetails.project_manager_ids.includes(currentUserId)
        );
    }, [
        canUserAssignPMFromAdmin,
        currentUserId,
        isUserShortlistedAsPMForProject,
        projectDetails.project_manager_ids,
        templateDetails,
    ]);

    const assignUserAccessForEachSection = useMemo(() => {
        let userAssignedToList: IAssignUserAndPM = {
            PROJECT_DETAILS: [],
        };
        if (usersWithProjectEditPermissionInAdmin) {
            Object.keys(templateDetails.sections).forEach((section) => {
                // current section name in payload
                // const sectionNameInPaylod =
                //     section === 'PROJECT_DETAILS'
                //         ? templateDetails.sections.PROJECT_DETAILS.label
                //         : section;

                // current section details in payload
                // const sectionInPayload = projectDetails.custom_sections.find(
                //     (customSection) =>
                //         customSection.name === sectionNameInPaylod
                // );

                // current section name in template
                section =
                    section === templateDetails.sections.PROJECT_DETAILS.label
                        ? 'PROJECT_DETAILS'
                        : section;

                const canUserAssignedToCurrentSection = isUserShortlistedAsPM
                    ? true
                    : templateDetails.assigned_users[section]?.length > 0
                    ? templateDetails.assigned_users[section]?.some(
                          (user) =>
                              user.user_id === currentUserId &&
                              user.permission ===
                                  ProjectPermissions.PROJECT_ASSIGN_USERS
                      ) ||
                      templateDetails.assigned_users.TEMPLATE_LEVEL?.some(
                          (user) =>
                              user.user_id === currentUserId &&
                              user.permission ===
                                  ProjectPermissions.PROJECT_ASSIGN_MANAGERS
                      )
                    : canUserAssignUserFromAdmin;

                if (canUserAssignedToCurrentSection) {
                    const currentUserInfo = userOptions.find(
                        (user) => user.user_id === currentUserId
                    );
                    if (section === 'PROJECT_DETAILS' && currentUserInfo) {
                        userAssignedToList.PROJECT_DETAILS.push(
                            currentUserInfo
                        );
                    } else if (currentUserInfo) {
                        if (userAssignedToList[section]) {
                            userAssignedToList[section].push(currentUserInfo);
                        } else {
                            userAssignedToList[section] = [currentUserInfo];
                        }
                    }
                }
            });
        }

        return userAssignedToList;
    }, [
        canUserAssignUserFromAdmin,
        currentUserId,
        isUserShortlistedAsPM,
        templateDetails.assigned_users,
        templateDetails.sections,
        userOptions,
        usersWithProjectEditPermissionInAdmin,
    ]);

    const handlePopupClose = useCallback(() => {
        handleClose();
    }, [handleClose]);

    useEffect(() => {
        if (projectDetails && usersWithProjectEditPermissionInAdmin) {
            const projectManagers = userOptions.filter((userWithEditPerm) =>
                projectDetails.project_manager_ids.some(
                    (managerId) => managerId === userWithEditPerm.user_id
                )
            );

            projectDetails.custom_sections.forEach((customSection) => {
                // eslint-disable-next-line @typescript-eslint/no-unused-vars
                const assignedUserForCurrentSection =
                    usersWithProjectEditPermissionInAdmin.filter(
                        (userWithEditPerm) =>
                            customSection.assigned_users.some(
                                (managerId) =>
                                    managerId === userWithEditPerm.user_id
                            )
                    );
            });

            setProjectManagers(projectManagers);
        }
    }, [projectDetails, userOptions, usersWithProjectEditPermissionInAdmin]);

    useEffect(() => {
        if (projectDetails && usersWithProjectEditAndEventEdit) {
            const rfqResponsible = usersWithProjectEditAndEventEdit.filter(
                (userWithEditPerm) =>
                    projectDetails.rfq_responsible_user_ids.some(
                        (managerId) => managerId === userWithEditPerm.user_id
                    )
            );

            setRFQResponsible(rfqResponsible);
        }
    }, [projectDetails, usersWithProjectEditAndEventEdit]);

    useEffect(() => {
        if (projectDetails && usersWithProjectEditPermissionInAdmin) {
            const currentlyAssignedUsers: IAssignUserAndPM = {
                PROJECT_DETAILS: [],
            };

            projectDetails.custom_sections.forEach((customSection) => {
                let assignedUsers = userOptions.filter((user) =>
                    customSection.assigned_users.includes(user.user_id)
                );

                if (assignedUsers?.length === 0) {
                    assignedUsers =
                        customSection.name ===
                        templateDetails.sections.PROJECT_DETAILS.label
                            ? assignUserAccessForEachSection.PROJECT_DETAILS
                            : assignUserAccessForEachSection[
                                  customSection.name
                              ];
                }

                if (
                    customSection.name ===
                    templateDetails.sections.PROJECT_DETAILS.label
                ) {
                    currentlyAssignedUsers.PROJECT_DETAILS = assignedUsers;
                } else {
                    currentlyAssignedUsers[customSection.name] = assignedUsers;
                }
            });

            setAssignedUserForProject(currentlyAssignedUsers);
            setOriginallyAssignedUserForProject(currentlyAssignedUsers);
        }
    }, [
        assignUserAccessForEachSection,
        projectDetails,
        templateDetails.sections.PROJECT_DETAILS.label,
        userOptions,
        usersWithProjectEditPermissionInAdmin,
    ]);

    useEffect(() => {
        if (projectDetails && usersWithProjectEditPermissionInAdmin) {
            const sectionNamesInTemplate = Object.keys(
                templateDetails.sections
            );

            const assignedUserForProject: IAssignUserAndPM = {
                PROJECT_DETAILS: [],
            };

            sectionNamesInTemplate.forEach((section) => {
                const currentCustomSectionIndex =
                    projectDetails.custom_sections.findIndex(
                        (customSection) => customSection.name === section
                    );

                if (currentCustomSectionIndex !== -1) {
                    const assignedUserList = userOptions.filter((user) =>
                        projectDetails.custom_sections[
                            currentCustomSectionIndex
                        ].assigned_users.includes(user.user_id)
                    );
                    assignedUserForProject[section] = assignedUserList;
                }
            });
        }
    }, [
        projectDetails,
        templateDetails.sections,
        userOptions,
        usersWithProjectEditPermissionInAdmin,
    ]);

    useEffect(() => {
        if (templateDetails && usersWithProjectEditPermissionInAdmin) {
            const sectionList: string[] = [];
            const userAssignedToList: IAssignUserAndPM = {
                PROJECT_DETAILS: [],
            };

            const assignedUserList = Object.keys(
                templateDetails.assigned_users
            );

            assignedUserList.forEach((assignLevel) => {
                if (assignLevel !== 'TEMPLATE_LEVEL') {
                    const assignUserPermission =
                        templateDetails.assigned_users[assignLevel];

                    const currentUserIndexInList =
                        assignUserPermission.findIndex(
                            (user) => user.user_id === currentUserId
                        );

                    if (
                        (currentUserIndexInList !== -1 &&
                            assignUserPermission[currentUserIndexInList]
                                .permission ===
                                ProjectPermissions.PROJECT_ASSIGN_USERS) ||
                        isUserShortlistedAsPM
                    ) {
                        const sectionName =
                            assignLevel === 'PROJECT_DETAILS'
                                ? templateDetails.sections.PROJECT_DETAILS.label
                                : assignLevel;
                        sectionList.push(sectionName);

                        if (assignLevel === 'PROJECT_DETAILS') {
                            userAssignedToList.PROJECT_DETAILS =
                                userOptions?.filter((user) =>
                                    assignUserPermission.some(
                                        (u) => u.user_id === user.user_id
                                    )
                                ) ?? [];
                        } else {
                            userAssignedToList[assignLevel] =
                                userOptions?.filter((user) =>
                                    assignUserPermission.some(
                                        (u) => u.user_id === user.user_id
                                    )
                                ) ?? [];
                        }

                        if (userAssignedToList[assignLevel]?.length === 0) {
                            userAssignedToList[assignLevel] = userOptions;
                        }
                    } else if (assignUserPermission?.length === 0) {
                        userAssignedToList[assignLevel] = userOptions;

                        const sectionName =
                            assignLevel === 'PROJECT_DETAILS'
                                ? templateDetails.sections.PROJECT_DETAILS.label
                                : assignLevel;
                        sectionList.push(sectionName);
                    }
                }
            });

            setAssignableSections(
                isUserShortlistedAsPM
                    ? Object.keys(templateDetails.sections).map((sectionName) =>
                          sectionName === 'PROJECT_DETAILS'
                              ? templateDetails.sections.PROJECT_DETAILS.label
                              : sectionName
                      )
                    : sectionList
            );

            setAssignedUsers(userAssignedToList);
        }
    }, [
        currentUserId,
        isUserShortlistedAsPM,
        projectEssentials.projectName,
        templateDetails,
        userOptions,
        usersWithProjectEditPermissionInAdmin,
    ]);

    const handleAssignAllUsersAsProjectManagers = useCallback(
        (selectAll: boolean) => {
            if (userOptions && selectAll) {
                setProjectManagers(userOptions);
            } else {
                setProjectManagers([]);
            }
        },
        [userOptions]
    );
    const handleAssignAllUsersForSection = useCallback(
        (selectAll: boolean, sectionName) => {
            if (usersWithProjectEditPermissionInAdmin && selectAll) {
                const newAssignedUserList = cloneDeep(assignedUserForProject);
                newAssignedUserList[sectionName] = assignedUsers[sectionName];
                setAssignedUserForProject(newAssignedUserList);
            } else {
                const newAssignedUserList = cloneDeep(assignedUserForProject);
                newAssignedUserList[sectionName] = [];
                setAssignedUserForProject(newAssignedUserList);
            }
        },
        [
            assignedUserForProject,
            assignedUsers,
            usersWithProjectEditPermissionInAdmin,
        ]
    );

    const handleProjectManagerAccessChange = useCallback(
        (user: IUserPermissionFEStructure) => {
            let newProjectManager = cloneDeep(projectManagers);

            const currentProjectManagerIndex = newProjectManager.findIndex(
                (manager) => manager.user_id === user.user_id
            );

            if (currentProjectManagerIndex !== -1) {
                newProjectManager.splice(currentProjectManagerIndex, 1);
            } else {
                newProjectManager.push(user);
            }

            setProjectManagers(newProjectManager);
        },
        [projectManagers]
    );

    const handleRfqResponsibleAccessChange = useCallback(
        (user: IUserPermissionFEStructure) => {
            setRFQResponsible((prev) => {
                let newPrev = cloneDeep(prev);

                const currentRFQresponsibleIndex = newPrev.findIndex(
                    (rfqResponsible) => rfqResponsible.user_id === user.user_id
                );

                if (currentRFQresponsibleIndex !== -1) {
                    newPrev.splice(currentRFQresponsibleIndex, 1);
                } else {
                    newPrev.push(user);
                }

                return newPrev;
            });
        },
        []
    );

    const handleAssignAllUsersAsRFQResponsible = useCallback(
        (selectAll: boolean) => {
            if (usersWithProjectEditAndEventEdit && selectAll) {
                setRFQResponsible(usersWithProjectEditAndEventEdit);
            } else {
                setRFQResponsible([]);
            }
        },
        [usersWithProjectEditAndEventEdit]
    );

    const handleAssignUserChange = useCallback(
        (user: IUserPermissionFEStructure, section: string) => {
            const updatedAssignedUserForProject = cloneDeep(
                assignedUserForProject
            );

            if (section === 'PROJECT_DETAILS') {
                const selectedUserIndex =
                    updatedAssignedUserForProject.PROJECT_DETAILS.findIndex(
                        (selectedUser) => selectedUser.user_id === user.user_id
                    );
                if (selectedUserIndex !== -1) {
                    updatedAssignedUserForProject.PROJECT_DETAILS.splice(
                        selectedUserIndex,
                        1
                    );
                } else {
                    updatedAssignedUserForProject.PROJECT_DETAILS = [
                        ...updatedAssignedUserForProject.PROJECT_DETAILS,
                        user,
                    ];
                }
            } else {
                if (updatedAssignedUserForProject[section] === undefined) {
                    updatedAssignedUserForProject[section] = [];
                }
                const selectedUserIndex = updatedAssignedUserForProject[
                    section
                ].findIndex(
                    (selectedUser) => selectedUser.user_id === user.user_id
                );
                if (selectedUserIndex !== -1) {
                    updatedAssignedUserForProject[section].splice(
                        selectedUserIndex,
                        1
                    );
                } else {
                    updatedAssignedUserForProject[section] = [
                        ...updatedAssignedUserForProject[section],
                        user,
                    ];
                }
            }

            setAssignedUserForProject(updatedAssignedUserForProject);
        },
        [assignedUserForProject]
    );

    const [updatingPermissions, setUpdatingPermissions] = useState(false);

    const FWInputRef = useRef<HTMLInputElement | null>(null);
    const chipRef = useRef<HTMLInputElement>(null);
    const [limitTags, setLimitTags] = useState(1);
    useEffect(() => {
        if (FWInputRef.current && chipRef.current) {
            const inputWidth = FWInputRef.current.getBoundingClientRect().width;
            const tagWidth = chipRef.current.getBoundingClientRect().width;

            const tagsThatCanFit = Math.floor(inputWidth / tagWidth);

            if (tagsThatCanFit === 0) setLimitTags(1);
            else setLimitTags(tagsThatCanFit);
        }
    }, [FWInputRef, chipRef]);

    const disableSaveButton = useMemo(() => {
        // let isAnySectionNotAssigned: boolean = false;
        // Object.keys(assignedUserForProject).forEach((section) => {
        //     if (assignedUserForProject[section]?.length === 0) {
        //         isAnySectionNotAssigned = true;
        //     }
        // });

        const isProjectManagerAssigned = projectManagers?.length > 0;

        return !isProjectManagerAssigned;
        // return isAnySectionNotAssigned || !isProjectManagerAssigned;
    }, [projectManagers?.length]);

    const handlePermissionUpdate = useCallback(async () => {
        setUpdatingPermissions(true);
        let newProjectdetails = cloneDeep(projectDetails);

        newProjectdetails.project_manager_ids = projectManagers.map(
            (manager) => manager.user_id
        );

        newProjectdetails.rfq_responsible_user_ids = rfqResponsible.map(
            (user) => user.user_id
        );

        Object.keys(assignedUserForProject).forEach((section) => {
            const sectionName = cloneDeep(section);
            section =
                section === 'PROJECT_DETAILS'
                    ? templateDetails.sections.PROJECT_DETAILS.label
                    : section;
            const sectionIndexInPayload =
                newProjectdetails.custom_sections.findIndex(
                    (customSection) => customSection.name === section
                );

            if (sectionIndexInPayload !== -1) {
                newProjectdetails.custom_sections[
                    sectionIndexInPayload
                ].assigned_users = (
                    assignedUserForProject[sectionName] ?? []
                ).map((user) => user.user_id);
            }
        });

        let payload: IUpdateProjectPermissionPayload = {
            project_manager_ids: newProjectdetails.project_manager_ids,
            rfq_responsible_user_ids:
                newProjectdetails.rfq_responsible_user_ids,
            custom_sections: newProjectdetails.custom_sections.map(
                (section) => ({
                    assigned_user_ids: section.assigned_users,
                    custom_section_id: section.custom_section_id,
                    name: section.name,
                })
            ),
        };

        await updateProjectPermission(projectEssentials.projectId, payload);
        // updateProject(newProjectdetails, 'assigned_users');
        setUpdatingPermissions(false);
        handlePopupClose();
    }, [
        assignedUserForProject,
        handlePopupClose,
        projectDetails,
        projectEssentials.projectId,
        projectManagers,
        rfqResponsible,
        templateDetails.sections.PROJECT_DETAILS.label,
    ]);

    const allowUserToAlterChanges = useMemo(() => {
        return (
            isUserShortlistedAsPM ||
            Object.keys(assignUserAccessForEachSection).some((section) =>
                assignUserAccessForEachSection[section].some(
                    (user) =>
                        user.user_id === currentUserId &&
                        user.permissions.includes(
                            ProjectPermissions.PROJECT_ASSIGN_USERS
                        )
                )
            )
        );
    }, [assignUserAccessForEachSection, currentUserId, isUserShortlistedAsPM]);

    useEffect(() => {}, [popupHookState]);

    return popupHookState === HookStateValue.LOADING ? (
        <FWPopupWithChildren
            open={open}
            handleClose={handlePopupClose}
            title={`${projectEssentials.projectName}`}
            applyTitleStyling={false}
            size="custom"
            popupWidth={'50vw'}
        >
            <DialogContent>
                <Grid
                    container
                    sx={{
                        transition: 'all 0.3s ease-in-out',
                    }}
                    width="100%"
                    height={'100%'}
                    borderRadius={1}
                >
                    <Box
                        marginY={'auto'}
                        marginX={'auto'}
                        padding={10}
                        sx={{ width: 'fit-content', height: 'fit-content' }}
                    >
                        <CircularProgress
                            size={30}
                            style={{ color: 'black' }}
                        />
                    </Box>
                </Grid>
            </DialogContent>
        </FWPopupWithChildren>
    ) : (
        <FWPopupWithChildren
            open={open}
            handleClose={handlePopupClose}
            title={`${projectEssentials.projectName}`}
            applyTitleStyling={false}
            size="custom"
            popupWidth={'50vw'}
        >
            <DialogContent>
                {
                    <Grid container gap={2} marginBottom={2}>
                        <Grid item xs={2.5}>
                            <FWTypography color={'text.secondary'}>
                                Project created date
                            </FWTypography>
                            <FWTypography color={'text.secondary'}>
                                {Boolean(projectEssentials.projectCreationDate)
                                    ? getFWDate(
                                          projectEssentials.projectCreationDate
                                      )
                                    : '-'}
                            </FWTypography>
                        </Grid>
                        <Grid item xs={2.5}>
                            <FWTypography color={'text.secondary'}>
                                Project status
                            </FWTypography>
                            <FWTypography
                                color={
                                    getProjectStatusTextAndColor(
                                        projectDetails?.project_status
                                    ).color
                                }
                            >
                                {Boolean(projectDetails?.project_status)
                                    ? getProjectStatusTextAndColor(
                                          projectDetails?.project_status
                                      ).label
                                    : '-'}
                            </FWTypography>
                        </Grid>

                        <Grid item xs={6.4}>
                            <FWTypography color={'text.secondary'}>
                                Project manager
                            </FWTypography>
                            {isUserShortlistedAsPM ? (
                                <FWAutocomplete
                                    popupIcon={
                                        <i
                                            className="bi bi-chevron-down"
                                            style={{
                                                fontSize: '20px',
                                            }}
                                        />
                                    }
                                    fullWidth
                                    multiple
                                    sx={{ height: 'fit-content' }}
                                    value={projectManagers}
                                    options={
                                        userOptions
                                            ? [
                                                  SELECT_ALL_OPTION,
                                                  ...userOptions,
                                              ]
                                            : []
                                    }
                                    renderTags={(value, getTagProps) =>
                                        value.map(
                                            (
                                                option: IUserPermissionFEStructure,
                                                index
                                            ) => (
                                                <Chip
                                                    {...getTagProps({ index })}
                                                    label={option.name}
                                                    sx={{
                                                        marginX: '5px',
                                                    }}
                                                    onDelete={() => {
                                                        handleProjectManagerAccessChange(
                                                            option
                                                        );
                                                    }}
                                                />
                                            )
                                        )
                                    }
                                    getOptionLabel={(
                                        option: IProjectAssigenUserListResponse
                                    ) => {
                                        return option.name;
                                    }}
                                    renderInput={(params) => (
                                        <FWInput
                                            {...params}
                                            placeholder="Search user"
                                        />
                                    )}
                                    limitTags={2}
                                    getLimitTagsText={() => {
                                        const pendingTags =
                                            projectManagers?.length > 2;

                                        const moreTags =
                                            projectManagers?.length - 2;

                                        const allUserNames =
                                            projectManagers.map(
                                                (user) => user.name
                                            );

                                        return (
                                            <FWTooltip
                                                title={`${allUserNames
                                                    .slice(2)
                                                    .join(', ')}`}
                                            >
                                                <Box>
                                                    <Typography>
                                                        {pendingTags &&
                                                        moreTags >= 0
                                                            ? `+ ${moreTags}`
                                                            : ''}
                                                    </Typography>
                                                </Box>
                                            </FWTooltip>
                                        );
                                    }}
                                    disableClearable
                                    renderOption={(
                                        props,
                                        option: IUserPermissionFEStructure
                                    ) => {
                                        const accessSharedToAllUsers =
                                            usersWithProjectEditPermissionInAdmin?.length ===
                                            projectManagers?.length;

                                        if (option.user_id === 'SELECT_ALL') {
                                            return (
                                                <FWMenuItem
                                                    key={option.user_id}
                                                    sx={{
                                                        display: 'flex',
                                                        paddingY: 0,
                                                    }}
                                                    value={option.user_id}
                                                    onClick={() => {
                                                        handleAssignAllUsersAsProjectManagers(
                                                            !accessSharedToAllUsers
                                                        );
                                                    }}
                                                >
                                                    <Checkbox
                                                        sx={{
                                                            paddingLeft: 0,
                                                        }}
                                                        checked={
                                                            accessSharedToAllUsers
                                                        }
                                                    />
                                                    <FWTypography>
                                                        Select all
                                                    </FWTypography>
                                                </FWMenuItem>
                                            );
                                        }
                                        const isUserSelected =
                                            projectManagers.some(
                                                (manager) =>
                                                    manager.user_id ===
                                                    option.user_id
                                            );

                                        return (
                                            <FWMenuItem
                                                key={option.user_id}
                                                sx={{
                                                    display: 'flex',
                                                    paddingY: 0,
                                                }}
                                                value={option.user_id}
                                                onClick={() => {
                                                    handleProjectManagerAccessChange(
                                                        option
                                                    );
                                                }}
                                            >
                                                <Checkbox
                                                    sx={{
                                                        paddingLeft: 0,
                                                    }}
                                                    checked={isUserSelected}
                                                />
                                                <FWTypography>
                                                    {option.name}
                                                </FWTypography>
                                            </FWMenuItem>
                                        );
                                    }}
                                />
                            ) : projectManagers?.length > 0 ? (
                                <UserNamePopover
                                    popoverTitle=""
                                    names={projectManagers.map(
                                        (manager) => manager.name
                                    )}
                                />
                            ) : (
                                '-'
                            )}
                        </Grid>
                    </Grid>
                }

                <Grid item xs={12} marginTop={3}>
                    <Divider />
                </Grid>

                <Grid container gap={2} marginTop={2}>
                    <Grid item xs={2.5}>
                        <FWTypography color={'text.secondary'}>
                            Project created date
                        </FWTypography>
                        <FWTypography color={'text.secondary'}>
                            {Boolean(projectEssentials.projectCreationDate)
                                ? getFWDate(
                                      projectEssentials.projectCreationDate
                                  )
                                : '-'}
                        </FWTypography>
                    </Grid>
                    <Grid item xs={2.5}>
                        <FWTypography color={'text.secondary'}>
                            Assignment status
                        </FWTypography>
                        <FWTypography
                            color={
                                rfqResponsible?.length > 0 ? 'success.main' : ''
                            }
                        >
                            {rfqResponsible?.length > 0
                                ? 'Assigned'
                                : 'Pending'}
                        </FWTypography>
                    </Grid>
                    <Grid item xs={6.4}>
                        <FWTypography color={'text.secondary'}>
                            RfQ assignee
                        </FWTypography>
                        {/* {isUserShortlistedAsPM ? (
                            <Autocomplete
                                clearOnBlur={false}
                                popupIcon={
                                    <i
                                        className="bi bi-chevron-down"
                                        style={{
                                            fontSize: '20px',
                                        }}
                                    />
                                }
                                sx={{
                                    height: 'fit-content',
                                }}
                                value={rfqResponsible}
                                PaperComponent={(props) => (
                                    <AutocompleteStyledPaper {...props} />
                                )}
                                disableClearable
                                // getLimitTagsText={() => {
                                //     const pendingTags =
                                //         rfqResponsible?.length > 2;

                                //     const moreTags = rfqResponsible?.length - 2;

                                //     const allUserNames = rfqResponsible.map(
                                //         (user) => user.name
                                //     );

                                //     return (
                                //         <FWTooltip
                                //             title={`${allUserNames
                                //                 .slice(2)
                                //                 .join(', ')}`}
                                //         >
                                //             <Box>
                                //                 <Typography>
                                //                     {pendingTags &&
                                //                     moreTags >= 0
                                //                         ? `+ ${moreTags}`
                                //                         : ''}
                                //                 </Typography>
                                //             </Box>
                                //         </FWTooltip>
                                //     );
                                // }}
                                renderTags={(value, getTagProps) =>
                                    value.map(
                                        (
                                            option: IUserPermissionFEStructure,
                                            index
                                        ) => (
                                            <Chip
                                                {...getTagProps({ index })}
                                                label={option.name}
                                                sx={{
                                                    marginX: '5px',
                                                }}
                                                onDelete={() => {
                                                    handleRfqResponsibleAccessChange(
                                                        option
                                                    );
                                                }}
                                            />
                                        )
                                    )
                                }
                                getOptionLabel={(
                                    option: IUserPermissionFEStructure
                                ) => {
                                    return option.name;
                                }}
                                fullWidth
                                multiple
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        variant="outlined"
                                        placeholder="Search user"
                                    />
                                )}
                                options={
                                    usersWithProjectEditAndEventEdit
                                        ? [
                                              SELECT_ALL_OPTION,
                                              ...usersWithProjectEditAndEventEdit,
                                          ]
                                        : []
                                }
                                limitTags={2}
                                renderOption={(props, option: any) => {
                                    const accessSharedToAllUsers =
                                        usersWithProjectEditAndEventEdit?.length ===
                                        rfqResponsible?.length;
                                    if (option.user_id === 'SELECT_ALL') {
                                        return (
                                            <FWMenuItem
                                                key={option.user_id}
                                                sx={{
                                                    display: 'flex',
                                                    paddingY: 0,
                                                }}
                                                value={option.user_id}
                                                onClick={() => {
                                                    handleAssignAllUsersAsRFQResponsible(
                                                        !accessSharedToAllUsers
                                                    );
                                                }}
                                            >
                                                <Checkbox
                                                    sx={{
                                                        paddingLeft: 0,
                                                    }}
                                                    checked={
                                                        accessSharedToAllUsers
                                                    }
                                                />
                                                <FWTypography>
                                                    Select all
                                                </FWTypography>
                                            </FWMenuItem>
                                        );
                                    }
                                    const isUserSelected = rfqResponsible.some(
                                        (rfqResponsible) =>
                                            rfqResponsible.user_id ===
                                            option.user_id
                                    );
                                    return (
                                        <React.Fragment>
                                            <FWMenuItem
                                                key={option.user_id}
                                                sx={{
                                                    display: 'flex',
                                                    paddingY: 0,
                                                }}
                                                value={option.user_id}
                                                onClick={() => {
                                                    handleRfqResponsibleAccessChange(
                                                        option
                                                    );
                                                }}
                                            >
                                                <Checkbox
                                                    sx={{
                                                        paddingLeft: 0,
                                                    }}
                                                    checked={isUserSelected}
                                                />
                                                <FWTypography>
                                                    {option.name}
                                                </FWTypography>
                                            </FWMenuItem>
                                        </React.Fragment>
                                    );
                                }}
                            />
                        ) : rfqResponsible?.length > 0 ? (
                            <UserNamePopover
                                popoverTitle=""
                                names={rfqResponsible.map(
                                    (manager) => manager.name
                                )}
                            />
                        ) : (
                            '-'
                        )} */}

                        {isUserShortlistedAsPM ? (
                            <FWAutocomplete
                                popupIcon={
                                    <i
                                        className="bi bi-chevron-down"
                                        style={{
                                            fontSize: '20px',
                                        }}
                                    />
                                }
                                PaperComponent={(props) => (
                                    <AutocompleteStyledPaper {...props} />
                                )}
                                fullWidth
                                multiple
                                sx={{
                                    height: 'fit-content',
                                }}
                                value={rfqResponsible}
                                options={
                                    usersWithProjectEditAndEventEdit
                                        ? [
                                              SELECT_ALL_OPTION,
                                              ...usersWithProjectEditAndEventEdit,
                                          ]
                                        : []
                                }
                                ref={FWInputRef}
                                renderTags={(value, getTagProps) =>
                                    value.map(
                                        (
                                            option: IUserPermissionFEStructure,
                                            index
                                        ) => (
                                            <Chip
                                                ref={chipRef}
                                                {...getTagProps({ index })}
                                                label={option.name}
                                                sx={{
                                                    marginX: '5px',
                                                }}
                                                onDelete={() => {
                                                    handleRfqResponsibleAccessChange(
                                                        option
                                                    );
                                                }}
                                            />
                                        )
                                    )
                                }
                                limitTags={limitTags}
                                getLimitTagsText={() => {
                                    const pendingTags =
                                        rfqResponsible?.length > limitTags;

                                    const moreTags =
                                        rfqResponsible?.length - limitTags;

                                    const allUserNames = rfqResponsible.map(
                                        (user) => user.name
                                    );

                                    return (
                                        <FWTooltip
                                            title={`${allUserNames
                                                .slice(limitTags)
                                                .join(', ')}`}
                                        >
                                            <Box>
                                                <Typography>
                                                    {pendingTags &&
                                                    moreTags >= 0
                                                        ? `+ ${moreTags}`
                                                        : ''}
                                                </Typography>
                                            </Box>
                                        </FWTooltip>
                                    );
                                }}
                                getOptionLabel={(
                                    option: IProjectAssigenUserListResponse
                                ) => {
                                    return option.name;
                                }}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        variant="outlined"
                                        placeholder="Search user"
                                    />
                                )}
                                disableClearable
                                renderOption={(
                                    props,
                                    option: IUserPermissionFEStructure
                                ) => {
                                    const accessSharedToAllUsers =
                                        usersWithProjectEditAndEventEdit?.length ===
                                        rfqResponsible?.length;

                                    if (option.user_id === 'SELECT_ALL') {
                                        return (
                                            <FWMenuItem
                                                key={option.user_id}
                                                sx={{
                                                    display: 'flex',
                                                    paddingY: 0,
                                                }}
                                                value={option.user_id}
                                                onClick={() => {
                                                    handleAssignAllUsersAsRFQResponsible(
                                                        !accessSharedToAllUsers
                                                    );
                                                }}
                                            >
                                                <Checkbox
                                                    sx={{
                                                        paddingLeft: 0,
                                                    }}
                                                    checked={
                                                        accessSharedToAllUsers
                                                    }
                                                />
                                                <FWTypography>
                                                    Select all
                                                </FWTypography>
                                            </FWMenuItem>
                                        );
                                    }
                                    const isUserSelected = rfqResponsible.some(
                                        (rfqResponsible) =>
                                            rfqResponsible.user_id ===
                                            option.user_id
                                    );

                                    return (
                                        <FWMenuItem
                                            key={option.user_id}
                                            sx={{
                                                display: 'flex',
                                                paddingY: 0,
                                            }}
                                            value={option.user_id}
                                            onClick={() => {
                                                handleRfqResponsibleAccessChange(
                                                    option
                                                );
                                            }}
                                        >
                                            <Checkbox
                                                sx={{
                                                    paddingLeft: 0,
                                                }}
                                                checked={isUserSelected}
                                            />
                                            <FWTypography>
                                                {option.name}
                                            </FWTypography>
                                        </FWMenuItem>
                                    );
                                }}
                            />
                        ) : rfqResponsible?.length > 0 ? (
                            <UserNamePopover
                                popoverTitle=""
                                names={rfqResponsible.map(
                                    (manager) => manager.name
                                )}
                            />
                        ) : (
                            '-'
                        )}
                    </Grid>
                </Grid>

                {assignableSections.map((section, index) => {
                    const sectionName = cloneDeep(section);

                    const sectionInfoInProjectDetails =
                        projectDetails.custom_sections.find(
                            (customSection) =>
                                customSection.name === sectionName
                        );

                    section =
                        section ===
                        templateDetails.sections.PROJECT_DETAILS.label
                            ? 'PROJECT_DETAILS'
                            : section;

                    // eslint-disable-next-line @typescript-eslint/no-unused-vars
                    const optionsForCurrentSection = assignedUsers[section];

                    if (sectionInfoInProjectDetails === undefined) {
                        return <></>;
                    }
                    return (
                        <Grid container gap={2}>
                            {(isUserShortlistedAsPM || index !== 0) && (
                                <Grid item xs={12} marginTop={3}>
                                    <Divider />
                                </Grid>
                            )}
                            <FWTypography variant="h4">
                                {sectionInfoInProjectDetails.name}
                            </FWTypography>
                            <Grid container gap={2}>
                                <Grid item xs={2.5}>
                                    <FWTypography color={'text.secondary'}>
                                        Start date
                                    </FWTypography>
                                    <FWTypography color={'text.secondary'}>
                                        {Boolean(
                                            sectionInfoInProjectDetails.start_time
                                        )
                                            ? getFWDateTime(
                                                  sectionInfoInProjectDetails.start_time
                                              )
                                            : '-'}
                                    </FWTypography>
                                </Grid>
                                <Grid item xs={2.5}>
                                    <FWTypography color={'text.secondary'}>
                                        Section status
                                    </FWTypography>
                                    <FWTypography
                                        color={
                                            getProjectStatusTextAndColor(
                                                (sectionInfoInProjectDetails?.status as ProjectStatusEnum) ??
                                                    ''
                                            ).color
                                        }
                                    >
                                        {Boolean(
                                            sectionInfoInProjectDetails?.status
                                        )
                                            ? getProjectStatusTextAndColor(
                                                  sectionInfoInProjectDetails?.status as ProjectStatusEnum
                                              ).label
                                            : '-'}
                                    </FWTypography>
                                </Grid>

                                <Grid item xs={6.4}>
                                    <FWTypography color={'text.secondary'}>
                                        Assigned to
                                        <span style={{ display: 'none' }}>
                                            {sectionName}
                                        </span>
                                    </FWTypography>
                                    {isUserShortlistedAsPM ||
                                    assignUserAccessForEachSection[
                                        section
                                    ]?.some(
                                        (user) =>
                                            user.user_id === currentUserId &&
                                            user.permissions.includes(
                                                ProjectPermissions.PROJECT_ASSIGN_USERS
                                            )
                                    ) ? (
                                        <FWAutocomplete
                                            popupIcon={
                                                <i
                                                    className="bi bi-chevron-down"
                                                    style={{
                                                        fontSize: '20px',
                                                    }}
                                                />
                                            }
                                            fullWidth
                                            multiple
                                            value={
                                                assignedUserForProject[
                                                    section
                                                ] ?? []
                                            }
                                            disableClearable
                                            options={
                                                assignedUsers[section]
                                                    ? [
                                                          SELECT_ALL_OPTION,
                                                          ...assignedUsers[
                                                              section
                                                          ],
                                                      ]
                                                    : []
                                            }
                                            getOptionLabel={(
                                                option: IUserPermissionFEStructure
                                            ) => {
                                                return option.name;
                                            }}
                                            renderInput={(params) => (
                                                <FWInput
                                                    {...params}
                                                    placeholder="Search user"
                                                />
                                            )}
                                            sx={{ height: 'fit-content' }}
                                            renderTags={(
                                                value,
                                                getTagProps
                                            ) => {
                                                return (
                                                    <>
                                                        {value.map(
                                                            (
                                                                option: IUserPermissionFEStructure,
                                                                index
                                                            ) => (
                                                                <Chip
                                                                    {...getTagProps(
                                                                        {
                                                                            index,
                                                                        }
                                                                    )}
                                                                    label={
                                                                        option.name
                                                                    }
                                                                    sx={{
                                                                        marginX:
                                                                            '5px',
                                                                    }}
                                                                    onDelete={() => {
                                                                        handleAssignUserChange(
                                                                            option,
                                                                            section
                                                                        );
                                                                    }}
                                                                />
                                                            )
                                                        )}
                                                    </>
                                                );
                                            }}
                                            renderOption={(
                                                props,
                                                option: IUserPermissionFEStructure
                                            ) => {
                                                const accessSharedToAllUsers =
                                                    assignedUsers[section]
                                                        ?.length ===
                                                    assignedUserForProject[
                                                        section
                                                    ]?.length;

                                                if (
                                                    option.user_id ===
                                                    'SELECT_ALL'
                                                ) {
                                                    return (
                                                        <FWMenuItem
                                                            key={option.user_id}
                                                            sx={{
                                                                display: 'flex',
                                                                paddingY: 0,
                                                            }}
                                                            value={
                                                                option.user_id
                                                            }
                                                            onClick={() => {
                                                                handleAssignAllUsersForSection(
                                                                    !accessSharedToAllUsers,
                                                                    section
                                                                );
                                                            }}
                                                        >
                                                            <Checkbox
                                                                sx={{
                                                                    paddingLeft: 0,
                                                                }}
                                                                checked={
                                                                    accessSharedToAllUsers
                                                                }
                                                            />
                                                            <FWTypography>
                                                                Select all
                                                            </FWTypography>
                                                        </FWMenuItem>
                                                    );
                                                }

                                                const isUserSelected =
                                                    assignedUserForProject[
                                                        section
                                                    ].some(
                                                        (manager) =>
                                                            manager.user_id ===
                                                            option.user_id
                                                    );

                                                return (
                                                    <FWMenuItem
                                                        key={option.user_id}
                                                        sx={{
                                                            display: 'flex',
                                                        }}
                                                        value={option.user_id}
                                                        onClick={() => {
                                                            handleAssignUserChange(
                                                                option,
                                                                section
                                                            );
                                                        }}
                                                    >
                                                        <Checkbox
                                                            sx={{
                                                                paddingLeft: 0,
                                                            }}
                                                            checked={
                                                                isUserSelected
                                                            }
                                                        />
                                                        <FWTypography>
                                                            {option.name}
                                                        </FWTypography>
                                                    </FWMenuItem>
                                                );
                                            }}
                                        />
                                    ) : assignedUserForProject[section]
                                          ?.length > 0 ? (
                                        <UserNamePopover
                                            popoverTitle=""
                                            names={assignedUserForProject[
                                                section
                                            ]?.map((user) => user.name)}
                                        />
                                    ) : (
                                        <FWTypography>-</FWTypography>
                                    )}
                                </Grid>
                            </Grid>
                        </Grid>
                    );
                })}
            </DialogContent>
            {allowUserToAlterChanges && (
                <DialogActions sx={{ marginRight: 4, marginBottom: 2 }}>
                    <FWButton
                        variant="outlined"
                        color="primary"
                        sx={{
                            textAlign: 'center',
                        }}
                        onClick={() => {
                            setAssignedUserForProject(
                                originallyAssignedUserForProject
                            );
                            handlePopupClose();
                        }}
                    >
                        Cancel
                    </FWButton>
                    <FWButton
                        variant="contained"
                        type="submit"
                        disabled={disableSaveButton}
                        onClick={() => {
                            handlePermissionUpdate();
                        }}
                    >
                        {updatingPermissions ? (
                            <CircularProgress
                                size={24}
                                style={{ color: '#c4c4c8' }}
                            />
                        ) : (
                            'Save'
                        )}
                    </FWButton>
                </DialogActions>
            )}
        </FWPopupWithChildren>
    );
}
