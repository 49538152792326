import { useEffect, useState } from 'react';
import { HookStateValue, useHookState } from '../../Common/Hooks/StateHook';
import { blankVisitTemplate, IVisitTemplate } from './VisitConstants';
import { useGetVisitTemplateByIdQuery } from '../Service/Visits.service';
import { cloneDeep } from 'lodash';

const useVisitTemplate = (templateId: string, entityID: string) => {
    const { hookState, updateHookState } = useHookState(HookStateValue.LOADING);
    const [visitTemplateDetails, setVisitTemplateDetails] =
        useState<IVisitTemplate>(blankVisitTemplate);

    const { data, isLoading, isError, error, isFetching, refetch } =
        useGetVisitTemplateByIdQuery(
            {
                entityID: entityID
                    ? entityID
                    : '097adb21-7e53-47aa-896c-fa2197396662',
                templateId: templateId ? templateId : '',
            },
            {
                skip:
                    templateId === '' ||
                    templateId === null ||
                    templateId === 'DEFAULT' ||
                    entityID === '',
            }
        );

    useEffect(() => {
        if (data && !isLoading && !isFetching) {
            const myTemplateData = cloneDeep(data);
            setVisitTemplateDetails(myTemplateData);
            updateHookState(HookStateValue.READY);
        }
    }, [data, isFetching, isLoading, updateHookState]);
    useEffect(() => {
        if (isError && error) {
            updateHookState(HookStateValue.ERROR);
        }
    }, [isError, error, updateHookState]);

    return {
        refetch,
        hookState,
        isFetching,
        visitTemplateDetails,
        templateErrorL: error,
    };
};

export default useVisitTemplate;
