import { createSlice } from '@reduxjs/toolkit';
import { IAdditionalCostsBackend } from '../../../AdditionalCost/models/AdditionalCost.model';
import { roundToNDecimalPlace } from '../../../Common/CurrencyUtilsComponent';
import { IMeasurementUnitDetails } from '../../../Models/MeasurementUnit.model';
import { IItemSummary } from '../../../Organizations/Admin/Interfaces/ItemDirectoryInterface';
import { getSubBomItemsTotal } from '../helpers/helper';
import {
    IBOMForBomAdmin,
    IBOMItemForAdmin,
} from '../inerfaces/BomAdminInterface';

export interface IBomStore {
    BomDetails: IBOMForBomAdmin | null;
    addBomItem: boolean;
    changeMade: number;
    changesSaved: 'SAVED' | 'UNSAVED' | 'SAVING';
}

export interface IUpdateBomStoreACtion {
    type: string;
    payload:
        | {
              type: 'REMOVE_BOM_CODE';
              value: null;
          }
        | {
              type: 'INITIALIZE_BOMDETAILS';
              value: IBOMForBomAdmin;
          }
        | {
              type: 'APPEND_BOM_ITEMS';
              value: IBOMItemForAdmin[];
          }
        | {
              type: 'UPDATE_SELECTED_FINSIHED_GOOD';
              value: IItemSummary & {
                  measurementUnitsDetail: IMeasurementUnitDetails[];
              };
          }
        | {
              type: 'UPDATE_ADD_BOM_ITEM';
              value: boolean;
          }
        | {
              type: 'UPDATE_TOP_LEVEL_BOM_DETAILS';
              value:
                  | {
                        key:
                            | 'bom_code'
                            | 'bom_name'
                            | 'currency'
                            | 'quantity'
                            | 'measurement_unit';
                        value: string;
                    }
                  | {
                        key: 'entity';
                        value: {
                            buyer_entity_id: string;
                            buyer_entity_name: string;
                        }[];
                    }
                  | {
                        key: 'additional_cost';
                        value: IAdditionalCostsBackend[];
                    };
          }
        | {
              type: 'UPDATE_TOP_LEVEL_BOM_CUSTOM_FIELDS_DETAILS';
              value: {
                  sectionIndex: number;
                  fieldIndex: number;
                  updatePayload: {
                      value: string | null | string[] | boolean;
                  };
              };
          }
        | {
              type: 'UPDATE_ITEM_LEVEL_DETAILS';
              value: {
                  listOfIndex: number[];
                  updatePayload:
                      | {
                            field:
                                | 'quantity'
                                | 'cost_per_unit'
                                | 'measurement_unit';
                            value: string;
                        }
                      | {
                            field: 'additional_cost';
                            value: IAdditionalCostsBackend[];
                        };
              };
          }
        | {
              type: 'UPDATE_ITEM_LEVEL_DETAILS_FOR_ALTERNATIVES';
              value: {
                  listOfIndex: number[];
                  alternativeIndex: number;
                  updatePayload:
                      | {
                            field:
                                | 'quantity'
                                | 'cost_per_unit'
                                | 'measurement_unit';
                            value: string;
                        }
                      | {
                            field: 'additional_cost';
                            value: IAdditionalCostsBackend[];
                        };
              };
          }
        | {
              type: 'REMOVE_BOM_ITEM';
              value: {
                  listOfIndex: number[];
              };
          }
        | {
              type: 'REMOVE_BOM_ITEM_PARTICULAR_ALTERNATIVES';
              value: {
                  alternativeIndex: number;
                  listOfIndex: number[];
              };
          }
        | {
              type: 'UPDATE_ITEM_LEVEL_CUSTOM_FIELDSDETAILS';
              value: {
                  listOfIndex: number[];
                  sectionIndex: number;
                  fieldIndex: number;
                  updatePayload: {
                      value: string | null | string[] | boolean;
                  };
              };
          }
        | {
              type: 'UPDATE_ITEM_LEVEL_CUSTOM_FIELDSDETAILS_FOR_ALTERNATIVES';
              value: {
                  listOfIndex: number[];
                  alternativeIndex: number;
                  sectionIndex: number;
                  fieldIndex: number;
                  updatePayload: {
                      value: string | null | string[] | boolean;
                  };
              };
          }
        | {
              type: 'UPDATE_BOM_STATUS';
              value: 'DRAFT' | 'ONGOING' | 'REVISED' | 'DEPRECATED';
          }
        | {
              type: 'UPDATE_CHANGES_SAVED_STATUS';
              value: 'SAVED' | 'UNSAVED' | 'SAVING';
          }
        | {
              type: 'RESET';
              value: null;
          }
        | {
              type: 'REMOVE_ITEM_CODE';
              value: null;
          }
        | {
              type: 'UPDATE_BOM_TOTAL';
              value: number;
          };
}

export const BomCreationSlice = createSlice<
    IBomStore,
    {
        updateBomDataStore: (
            state: IBomStore,
            action: IUpdateBomStoreACtion
        ) => void;
    }
>({
    initialState: {
        BomDetails: null,
        addBomItem: false,
        changeMade: 0,
        changesSaved: 'SAVED',
    },
    name: 'BomCreateStore',
    reducers: {
        updateBomDataStore: (state, action) => {
            switch (action.payload.type) {
                case 'INITIALIZE_BOMDETAILS':
                    if (state.BomDetails) {
                        state.changeMade += 1;
                    }

                    state.BomDetails = action.payload.value;

                    if (action.payload.value.bom_items.length === 0) {
                        state.addBomItem = true;
                    }

                    break;

                case 'APPEND_BOM_ITEMS':
                    if (state.BomDetails) {
                        state.BomDetails.bom_items.push(
                            ...action.payload.value
                        );
                    }

                    break;

                case 'UPDATE_SELECTED_FINSIHED_GOOD':
                    if (state.BomDetails) {
                        state.BomDetails.enterprise_item.enterprise_item_id =
                            action.payload.value.id;
                        state.BomDetails.additional_details.template_id =
                            action.payload.value.bom_template?.template_id ??
                            '';
                        state.BomDetails.enterprise_item.bom_template.template_id =
                            action.payload.value.bom_template?.template_id ??
                            '';
                        state.BomDetails.enterprise_item.name =
                            action.payload.value.name;
                        state.BomDetails.enterprise_item.code =
                            action.payload.value.itemCode;
                        state.BomDetails.entities =
                            action.payload.value.entities_id;
                        state.BomDetails.enterprise_item.measurement_units =
                            action.payload.value.measurementUnitsDetail.map(
                                (measureUnit) => ({
                                    abbreviation: '',
                                    category:
                                        measureUnit.measurement_unit_category,
                                    id: measureUnit.measurement_unit_id,
                                    isVerifiedUnit: true,
                                    name: measureUnit.measurement_unit_primary_name,
                                    type: measureUnit.measurement_unit_value_type,
                                    measurement_unit_primary_name:
                                        measureUnit.measurement_unit_primary_name,
                                })
                            );
                        //COMEBACKTO THIS
                        state.BomDetails.selectedMeasurmentUnit =
                            action.payload.value.measurementUnitsDetail[0].measurement_unit_id;
                    }
                    state.changeMade += 1;
                    break;

                case 'REMOVE_BOM_ITEM':
                    if (
                        state.BomDetails &&
                        action.payload.value.listOfIndex.length === 1
                    ) {
                        state.BomDetails.bom_items.splice(
                            action.payload.value.listOfIndex[0],
                            1
                        );
                    }

                    state.changeMade += 1;

                    break;
                case 'REMOVE_BOM_ITEM_PARTICULAR_ALTERNATIVES':
                    if (
                        state.BomDetails &&
                        action.payload.value.listOfIndex.length === 1
                    ) {
                        state.BomDetails.bom_items[
                            action.payload.value.listOfIndex[0]
                        ].alternates.alternate_bom_item.splice(
                            action.payload.value.alternativeIndex,
                            1
                        );
                    }
                    state.changeMade += 1;

                    break;
                case 'REMOVE_ITEM_CODE':
                    if (state.BomDetails) {
                        state.BomDetails.enterprise_item.code = '';
                    }
                    state.changeMade += 1;
                    break;
                case 'UPDATE_ADD_BOM_ITEM':
                    state.addBomItem = action.payload.value;

                    break;

                case 'UPDATE_TOP_LEVEL_BOM_DETAILS':
                    if (state.BomDetails)
                        switch (action.payload.value.key) {
                            case 'bom_code':
                                state.BomDetails.bom_code =
                                    action.payload.value.value;
                                break;
                            case 'bom_name':
                                state.BomDetails.bom_name =
                                    action.payload.value.value;
                                break;
                            case 'currency':
                                state.BomDetails.currency =
                                    action.payload.value.value;
                                break;
                            case 'quantity':
                                state.BomDetails.quantity =
                                    action.payload.value.value;
                                break;
                            case 'entity':
                                state.BomDetails.entities =
                                    action.payload.value.value;
                                break;
                            case 'measurement_unit':
                                state.BomDetails.selectedMeasurmentUnit =
                                    action.payload.value.value;
                                break;
                            case 'additional_cost':
                                state.BomDetails.additional_costs =
                                    action.payload.value.value;
                                break;

                            default:
                                break;
                        }
                    state.changeMade += 1;
                    break;

                case 'UPDATE_TOP_LEVEL_BOM_CUSTOM_FIELDS_DETAILS':
                    if (state.BomDetails) {
                        state.BomDetails.custom_sections[
                            action.payload.value.sectionIndex
                        ].custom_fields[action.payload.value.fieldIndex].value =
                            action.payload.value.updatePayload.value;
                    }
                    state.changeMade += 1;
                    break;

                case 'UPDATE_ITEM_LEVEL_CUSTOM_FIELDSDETAILS':
                    if (state.BomDetails) {
                        if (action.payload.value.listOfIndex.length === 1) {
                            state.BomDetails.bom_items[
                                action.payload.value.listOfIndex[0]
                            ].custom_sections[
                                action.payload.value.sectionIndex
                            ].custom_fields[
                                action.payload.value.fieldIndex
                            ].value = action.payload.value.updatePayload.value;
                        }
                        for (let bomItem of state.BomDetails.bom_items.filter(
                            (i) => i.sub_bom_items !== null
                        )) {
                            bomItem.cost_per_unit = roundToNDecimalPlace(
                                getSubBomItemsTotal(bomItem.sub_bom_items!)
                                    .totalCostForBom / +bomItem.quantity,
                                10
                            ).toString();
                        }
                    }

                    state.changeMade += 1;
                    break;

                case 'UPDATE_ITEM_LEVEL_CUSTOM_FIELDSDETAILS_FOR_ALTERNATIVES':
                    if (state.BomDetails) {
                        if (action.payload.value.listOfIndex.length === 1) {
                            state.BomDetails.bom_items[
                                action.payload.value.listOfIndex[0]
                            ].alternates.alternate_bom_item[
                                action.payload.value.alternativeIndex
                            ].custom_sections[
                                action.payload.value.sectionIndex
                            ].custom_fields[
                                action.payload.value.fieldIndex
                            ].value = action.payload.value.updatePayload.value;
                        } else if (
                            action.payload.value.listOfIndex.length > 1
                        ) {
                            const listOfSubBomInternalIndex =
                                action.payload.value.listOfIndex.slice(1);
                            let currentSubBomItems: IBOMItemForAdmin[] =
                                state.BomDetails.bom_items[
                                    action.payload.value.listOfIndex[0]
                                ].sub_bom_items !== null
                                    ? state.BomDetails.bom_items[
                                          action.payload.value.listOfIndex[0]
                                      ].sub_bom_items!
                                    : [];

                            for (let innerIndex of listOfSubBomInternalIndex.slice(
                                0,
                                -1
                            )) {
                                if (currentSubBomItems[innerIndex]) {
                                    currentSubBomItems =
                                        currentSubBomItems[innerIndex]
                                            .sub_bom_items !== null
                                            ? currentSubBomItems[innerIndex]
                                                  .sub_bom_items!
                                            : [];
                                } else {
                                    return null;
                                }
                            }

                            const currentSuBItemWeAreRefereing =
                                currentSubBomItems[
                                    listOfSubBomInternalIndex[
                                        listOfSubBomInternalIndex.length - 1
                                    ]
                                ];
                            currentSuBItemWeAreRefereing.alternates.alternate_bom_item[
                                action.payload.value.alternativeIndex
                            ].custom_sections[
                                action.payload.value.sectionIndex
                            ].custom_fields[
                                action.payload.value.fieldIndex
                            ].value = action.payload.value.updatePayload.value;
                        }
                    }

                    state.changeMade += 1;
                    break;

                case 'UPDATE_ITEM_LEVEL_DETAILS':
                    if (state.BomDetails) {
                        if (action.payload.value.listOfIndex.length === 1) {
                            switch (action.payload.value.updatePayload.field) {
                                case 'quantity':
                                    state.BomDetails.bom_items[
                                        action.payload.value.listOfIndex[0]
                                    ].quantity =
                                        action.payload.value.updatePayload.value;

                                    break;
                                case 'cost_per_unit':
                                    state.BomDetails.bom_items[
                                        action.payload.value.listOfIndex[0]
                                    ].cost_per_unit =
                                        action.payload.value.updatePayload.value;

                                    break;
                                case 'measurement_unit':
                                    state.BomDetails.bom_items[
                                        action.payload.value.listOfIndex[0]
                                    ].measurement_unit =
                                        action.payload.value.updatePayload.value;

                                    break;

                                case 'additional_cost':
                                    state.BomDetails.bom_items[
                                        action.payload.value.listOfIndex[0]
                                    ].additional_costs =
                                        action.payload.value.updatePayload.value;

                                    break;

                                default:
                                    break;
                            }
                            const subBoms =
                                state.BomDetails.bom_items[
                                    action.payload.value.listOfIndex[0]
                                ].sub_bom_items;

                            if (subBoms)
                                helperFUnctionForUpdatingQuantityOfBoms(
                                    subBoms,
                                    state.BomDetails.bom_items[
                                        action.payload.value.listOfIndex[0]
                                    ].quantity
                                );
                        } else if (
                            action.payload.value.listOfIndex.length > 1
                        ) {
                            const listOfSubBomInternalIndex =
                                action.payload.value.listOfIndex.slice(1);
                            let currentSubBomItems: IBOMItemForAdmin[] =
                                state.BomDetails.bom_items[
                                    action.payload.value.listOfIndex[0]
                                ].sub_bom_items !== null
                                    ? state.BomDetails.bom_items[
                                          action.payload.value.listOfIndex[0]
                                      ].sub_bom_items!
                                    : [];

                            for (let innerIndex of listOfSubBomInternalIndex.slice(
                                0,
                                -1
                            )) {
                                if (currentSubBomItems[innerIndex]) {
                                    currentSubBomItems =
                                        currentSubBomItems[innerIndex]
                                            .sub_bom_items !== null
                                            ? currentSubBomItems[innerIndex]
                                                  .sub_bom_items!
                                            : [];
                                } else {
                                    return null;
                                }
                            }

                            const currentSuBItemWeAreRefereing =
                                currentSubBomItems[
                                    listOfSubBomInternalIndex[
                                        listOfSubBomInternalIndex.length - 1
                                    ]
                                ];

                            switch (action.payload.value.updatePayload.field) {
                                case 'quantity':
                                    currentSuBItemWeAreRefereing.quantity =
                                        action.payload.value.updatePayload.value;
                                    break;
                                case 'cost_per_unit':
                                    currentSuBItemWeAreRefereing.cost_per_unit =
                                        action.payload.value.updatePayload.value;
                                    break;

                                case 'measurement_unit':
                                    currentSuBItemWeAreRefereing.measurement_unit =
                                        action.payload.value.updatePayload.value;
                                    break;
                                case 'additional_cost':
                                    currentSuBItemWeAreRefereing.additional_costs =
                                        action.payload.value.updatePayload.value;

                                    break;
                                default:
                                    break;
                            }
                            const subBoms =
                                currentSuBItemWeAreRefereing.sub_bom_items;

                            if (subBoms)
                                helperFUnctionForUpdatingQuantityOfBoms(
                                    subBoms,
                                    state.BomDetails.bom_items[
                                        action.payload.value.listOfIndex[0]
                                    ].quantity
                                );
                        }

                        for (let bomItem of state.BomDetails.bom_items.filter(
                            (i) => i.sub_bom_items !== null
                        )) {
                            bomItem.cost_per_unit = roundToNDecimalPlace(
                                getSubBomItemsTotal(bomItem.sub_bom_items!)
                                    .totalCostForBom / +(+bomItem.quantity),
                                10
                            ).toString();
                            bomItem.subtotal = roundToNDecimalPlace(
                                +(bomItem.cost_per_unit ?? '') *
                                    +(bomItem.quantity ?? ''),
                                10
                            ).toString();
                        }
                    }

                    state.changeMade += 1;
                    break;

                case 'UPDATE_ITEM_LEVEL_DETAILS_FOR_ALTERNATIVES':
                    if (state.BomDetails) {
                        if (action.payload.value.listOfIndex.length === 1) {
                            switch (action.payload.value.updatePayload.field) {
                                case 'quantity':
                                    state.BomDetails.bom_items[
                                        action.payload.value.listOfIndex[0]
                                    ].alternates.alternate_bom_item[
                                        action.payload.value.alternativeIndex
                                    ].quantity =
                                        action.payload.value.updatePayload.value;

                                    break;
                                case 'cost_per_unit':
                                    state.BomDetails.bom_items[
                                        action.payload.value.listOfIndex[0]
                                    ].alternates.alternate_bom_item[
                                        action.payload.value.alternativeIndex
                                    ].cost_per_unit =
                                        action.payload.value.updatePayload.value;

                                    break;
                                case 'measurement_unit':
                                    state.BomDetails.bom_items[
                                        action.payload.value.listOfIndex[0]
                                    ].alternates.alternate_bom_item[
                                        action.payload.value.alternativeIndex
                                    ].measurement_unit =
                                        action.payload.value.updatePayload.value;

                                    break;
                                case 'additional_cost':
                                    state.BomDetails.bom_items[
                                        action.payload.value.listOfIndex[0]
                                    ].alternates.alternate_bom_item[
                                        action.payload.value.alternativeIndex
                                    ].additional_costs =
                                        action.payload.value.updatePayload.value;

                                    break;

                                default:
                                    break;
                            }
                        } else if (
                            action.payload.value.listOfIndex.length > 1
                        ) {
                            const listOfSubBomInternalIndex =
                                action.payload.value.listOfIndex.slice(1);
                            let currentSubBomItems: IBOMItemForAdmin[] =
                                state.BomDetails.bom_items[
                                    action.payload.value.listOfIndex[0]
                                ].sub_bom_items !== null
                                    ? state.BomDetails.bom_items[
                                          action.payload.value.listOfIndex[0]
                                      ].sub_bom_items!
                                    : [];

                            for (let innerIndex of listOfSubBomInternalIndex.slice(
                                0,
                                -1
                            )) {
                                if (currentSubBomItems[innerIndex]) {
                                    currentSubBomItems =
                                        currentSubBomItems[innerIndex]
                                            .sub_bom_items !== null
                                            ? currentSubBomItems[innerIndex]
                                                  .sub_bom_items!
                                            : [];
                                } else {
                                    return null;
                                }
                            }

                            const currentSuBItemWeAreRefereing =
                                currentSubBomItems[
                                    listOfSubBomInternalIndex[
                                        listOfSubBomInternalIndex.length - 1
                                    ]
                                ];

                            switch (action.payload.value.updatePayload.field) {
                                case 'quantity':
                                    currentSuBItemWeAreRefereing.alternates.alternate_bom_item[
                                        action.payload.value.alternativeIndex
                                    ].quantity =
                                        action.payload.value.updatePayload.value;
                                    break;
                                case 'cost_per_unit':
                                    currentSuBItemWeAreRefereing.alternates.alternate_bom_item[
                                        action.payload.value.alternativeIndex
                                    ].cost_per_unit =
                                        action.payload.value.updatePayload.value;
                                    break;

                                case 'measurement_unit':
                                    currentSuBItemWeAreRefereing.alternates.alternate_bom_item[
                                        action.payload.value.alternativeIndex
                                    ].measurement_unit =
                                        action.payload.value.updatePayload.value;
                                    break;
                                default:
                                    break;
                            }
                        }

                        for (let bomItem of state.BomDetails.bom_items.filter(
                            (i) => i.sub_bom_items !== null
                        )) {
                            bomItem.cost_per_unit = roundToNDecimalPlace(
                                getSubBomItemsTotal(bomItem.sub_bom_items!)
                                    .totalCostForBom / +(+bomItem.quantity),
                                10
                            ).toString();
                        }
                    }

                    state.changeMade += 1;
                    break;

                case 'UPDATE_BOM_TOTAL':
                    if (state.BomDetails) {
                        state.BomDetails.total = roundToNDecimalPlace(
                            action.payload.value,
                            10
                        ).toString();
                    }
                    break;
                case 'REMOVE_BOM_CODE':
                    if (state.BomDetails) {
                        state.BomDetails.enterprise_item.code = '';

                        state.changeMade += 1;
                    }
                    break;
                case 'UPDATE_CHANGES_SAVED_STATUS':
                    state.changesSaved = action.payload.value;
                    break;

                case 'UPDATE_BOM_STATUS':
                    if (state.BomDetails) {
                        state.BomDetails.bom_status = action.payload.value;
                    }
                    break;

                case 'RESET':
                    state.BomDetails = null;
                    state.addBomItem = false;
                    state.changeMade = 0;
                    break;
                default:
                    break;
            }

            // updating all sub boms value =
        },
    },
});

export const { updateBomDataStore } = BomCreationSlice.actions;

const helperFUnctionForUpdatingQuantityOfBoms = (
    items: IBOMItemForAdmin[],
    bomItemQUantity: string | number
) => {
    for (let item of items) {
        item.quantity = (
            +item.rawMaterialQuantityPerUnitBom * +bomItemQUantity
        ).toString();

        // if (item.delivery_schedule.length === 1) {
        //     item.delivery_schedule[0].quantity = item.quantity;
        // } else {
        //     const ogQuantity = item.delivery_schedule.reduce(
        //         (acc, next) => acc + +next.quantity,
        //         0
        //     );

        //     for (let i = 0; i < item.delivery_schedule.length - 1; i++) {
        //         item.delivery_schedule[i].quantity = roundToNDecimalPlace(
        //             (+item.delivery_schedule[i] / ogQuantity) * +item.quantity,
        //             4
        //         ).toString();
        //     }
        //     item.delivery_schedule[item.delivery_schedule.length - 1].quantity =
        //         (
        //             +item.quantity -
        //             item.delivery_schedule
        //                 .filter((ds, idx) =>
        //                     idx === item.delivery_schedule.length - 1
        //                         ? false
        //                         : true
        //                 )
        //                 .reduce((acc, next) => acc + +next.quantity, 0)
        //         ).toString();
        // }

        if (item.sub_bom_items) {
            helperFUnctionForUpdatingQuantityOfBoms(
                item.sub_bom_items,
                item.quantity
            );
        }
    }
};
