import { createSlice } from '@reduxjs/toolkit';
import { IVendorData } from '../Services/documents.service';
import { IDocumentCustomField } from './Document.model';
import { CustomFieldType } from '../../Global/Interfaces/TemplateInterface';
const getDownloadableCustomFields = (vendor_document: IVendorData) => {
    let downloadableFields: IDocumentCustomField[] = [];

    vendor_document.custom_fields.section_list.forEach((section) => {
        section?.fields?.forEach((field) => {
            if (
                field.type === CustomFieldType.TEMPLATE &&
                !downloadableFields.some(
                    (downloadField) => downloadField.name === field.name
                )
            ) {
                downloadableFields.push(field);
            }
        });
    });
    vendor_document.custom_fields_negotiate.section_list.forEach((section) => {
        section?.fields?.forEach((field) => {
            if (
                field.type === CustomFieldType.TEMPLATE &&
                !downloadableFields.some(
                    (downloadField) => downloadField.name === field.name
                )
            ) {
                downloadableFields.push(field);
            }
        });
    });

    return downloadableFields;
};

export interface ISRMDocumentStore {
    sellerDocuments: {
        [documentId: string]: IDocumentCustomField[];
    };
    sellerDocumentsLength: number;
    selectedDocuments: string[];
}

export interface IUpdateSRMDocStoreAction {
    type: string;
    payload:
        | {
              type: 'APPEND_DOCUMENT';
              value: {
                  vendor_id: string;
                  vendor_document: IVendorData;
              };
          }
        | {
              type: 'SELECT_DOCUMENT';
              value: string; // document_id
          }
        | {
              type: 'UNSELECT_DOCUMENT';
              value: string; // document_id
          }
        | {
              type: 'SELECT_ALL_DOCUMENTS';
              value: boolean; // to select and unselect all documents using boolean value
          }
        | {
              type: 'RESET_ALL_DOCUMENTS';
          }
        | {
              type: 'APPEND_DOCUMENTS_IN_BULK';
              value: IVendorData[]; // get vendor_id from each response and store in the object.
          }
        | {
              type: 'SET_DOCUMENTS';
              value: IVendorData[]; // get vendor_id from each response and store in the object.
          };
}

export const SRMDocumentSlice = createSlice<
    ISRMDocumentStore,
    {
        updateSRMDataStore: (
            state: ISRMDocumentStore,
            action: IUpdateSRMDocStoreAction
        ) => void;
    },
    string
>({
    name: 'SRMDocumentstore',
    initialState: {
        selectedDocuments: [],
        sellerDocuments: {},
        sellerDocumentsLength: 0,
    },
    reducers: {
        updateSRMDataStore: (
            state: ISRMDocumentStore,
            action: IUpdateSRMDocStoreAction
        ) => {
            try {
                switch (action.payload.type) {
                    case 'APPEND_DOCUMENT':
                        const { vendor_document, vendor_id } =
                            action.payload.value;

                        let downloadableFields: IDocumentCustomField[] =
                            getDownloadableCustomFields(vendor_document);

                        state.sellerDocuments[vendor_id] = downloadableFields;
                        state.sellerDocumentsLength += 1;

                        break;
                    case 'APPEND_DOCUMENTS_IN_BULK':
                        const vendorDocuments = action.payload.value;

                        const sellerDocuments: {
                            [documentId: string]: IDocumentCustomField[];
                        } = {};

                        vendorDocuments.forEach((vendorDoc) => {
                            let downloadableFields: IDocumentCustomField[] =
                                getDownloadableCustomFields(vendorDoc);

                            sellerDocuments[vendorDoc.document_id] =
                                downloadableFields;
                        });

                        const updateSellerDocuments = {
                            ...state.sellerDocuments,
                            ...sellerDocuments,
                        };

                        state.sellerDocuments = updateSellerDocuments;

                        state.sellerDocumentsLength = Object.keys(
                            updateSellerDocuments
                        ).length;

                        break;
                    case 'SET_DOCUMENTS':
                        const allVendorDocs = action.payload.value;

                        const allSellerDocs: {
                            [documentId: string]: IDocumentCustomField[];
                        } = {};

                        allVendorDocs.forEach((vendorDoc) => {
                            let downloadableFields: IDocumentCustomField[] =
                                getDownloadableCustomFields(vendorDoc);

                            allSellerDocs[vendorDoc.document_id] =
                                downloadableFields;
                        });

                        state.sellerDocuments = allSellerDocs;

                        state.sellerDocumentsLength =
                            Object.keys(allSellerDocs).length;

                        break;
                    case 'UNSELECT_DOCUMENT': {
                        const documentIdToBeDeleted = action.payload.value;

                        state.selectedDocuments =
                            state.selectedDocuments.filter(
                                (id) => id !== documentIdToBeDeleted
                            );
                        break;
                    }
                    case 'SELECT_DOCUMENT': {
                        state.selectedDocuments = [
                            ...state.selectedDocuments,
                            action.payload.value,
                        ];
                        break;
                    }
                    case 'SELECT_ALL_DOCUMENTS': {
                        if (action.payload.value) {
                            state.selectedDocuments = Object.keys(
                                state.sellerDocuments
                            );
                        } else {
                            state.selectedDocuments = [];
                        }
                        break;
                    }
                    case 'RESET_ALL_DOCUMENTS': {
                        state.selectedDocuments = [];
                        state.sellerDocuments = {};
                        state.sellerDocumentsLength = 0;
                        break;
                    }
                }
            } catch (err) {}
        },
    },
});

export const { updateSRMDataStore } = SRMDocumentSlice.actions;

const SRMDocumentStore = SRMDocumentSlice.reducer;
export default SRMDocumentStore;
