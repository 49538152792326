import {
    Fragment,
    memo,
    useCallback,
    useEffect,
    useMemo,
    useState,
} from 'react';
import { useSelector } from 'react-redux';
import { IGlobalAppStore } from '../../../../../Redux/Store';
import {
    FWAccordion,
    FWAccordionDetails,
    FWAccordionSummary,
} from '../../../../../Common/FWAccordian';
import { Badge, Box, Divider, Grid, InputAdornment } from '@mui/material';
import { nestedDeepEqual } from '../../../../../Global/Helpers/helpers';
import { FWTypography } from '../../../../../Common/FWTypograhy';
import {
    FWCurrencyTypoGraphyWithCurrencyUid,
    roundToNDecimalPlace,
} from '../../../../../Common/CurrencyUtilsComponent';
import { useRfqTemplate } from '../../../../../Global/Hooks/useRfqTemplate';
import {
    IRfqTemplate,
    TemplateStandardFieldsEnum,
} from '../../../../../Global/Interfaces/TemplateInterface';
import {
    IBomItemDeliverScheduleStructure,
    IEventItemDetails,
    IParentSubBomItemForIBomdeliveryScheduleStrutcure,
} from '../../../../../Events/Interfaces/Buyer/RFQ.model';
import { FWInput } from '../../../../../Common/FWInput';
import { cloneDeep, isEqual } from 'lodash';
import { useDispatch } from 'react-redux';
import { updateDSAllocationPopupStore } from '../quoteComparisionSlice';
import { getFWDate } from '../../../../../Common/Utils/DateUtils';
import {
    getBomHierarachyLabelFromBoms,
    getMostDeepestSubBomCode,
} from '../../../../../Events/RFQAnalytics/Helpers/BomDsHelper';
import { FWTooltip } from '../../../../../Common/FWCustomTooltip';
// import { StyledRFQItemNumberDiv } from '../../../../../Events/Constants/Buyer/RFQHelperFunctions';
import styled from '@emotion/styled';

interface ISingleDsAllocationBidItemrendererProps {
    rfqBidItemId: string;
    idx?: string;
}

const SingleDsAllocationBidItemrenderer = ({
    rfqBidItemId,
    idx,
}: ISingleDsAllocationBidItemrendererProps) => {
    const rfqBidItemDetails = useSelector(
        (store: IGlobalAppStore) =>
            store.QuoteComparison.DSAllocationPopupData.listofBidItems.find(
                (item) => item.rfq_bid_item_id === rfqBidItemId
            ) ?? null,
        nestedDeepEqual
    );

    const rfqItemDetails = useSelector(
        (store: IGlobalAppStore) =>
            rfqBidItemDetails &&
            store.QuoteComparison.cachingRfqData[rfqBidItemDetails.rfq_item]
                ? store.QuoteComparison.cachingRfqData[
                      rfqBidItemDetails.rfq_item
                  ]
                : null,
        nestedDeepEqual
    );

    const [expanded, setExpanded] = useState(false);

    const StyledRFQItemNumberDiv = styled(Box)({
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        position: 'relative',
        width: '100%',
        '& .itemBoxHeaderNumber': {
            position: 'absolute',
            left: '1rem',
            fontSize: '1.3rem',
            top: '-1.8rem',
            backgroundColor: 'white',
        },
        '& .itemBoxHeaderName': {
            fontWeight: '500',
            fontSize: '24px',
            zIndex: 1,
        },
    });

    return rfqBidItemDetails && rfqItemDetails ? (
        <FWAccordion
            sx={{
                '&': {
                    padding: 0,
                    maxWidth: `${100}% !important`,
                    minWidth: '100% !important',
                    marginBottom: '25px',
                },
                '&, .MuiPaper-root': {
                    width: '100% !important',
                    minWidth: '75% !important',
                },
                backgroundColor: 'white',
            }}
            onChange={(e, newexpanded) => setExpanded(newexpanded)}
        >
            <FWAccordionSummary
                sx={{
                    '&': {
                        margin: 0,
                        padding: 0,
                        backgroundColor: 'transparent',
                        maxWidth: '100% !important',
                        minWidth: '100% !important',
                        borderBottomLeftRadius: '12px',
                        borderBottomRightRadius: '12px',
                    },
                    '&.Mui-focusVisible': {
                        backgroundColor: 'transparent',
                    },
                    '.css-zlrzio-MuiModal-root-MuiDialog-root .MuiPaper-root': {
                        minWidth: '100% !important',
                    },
                    '& .MuiPaper-root': {
                        width: '100% !important',
                        minWidth: '100% !important',
                    },
                    minWidth: '100% !important',
                    backgroundColor: 'transparent',
                }}
                expandIcon={null}
            >
                <Box width={'100%'}>
                    <Box
                        className="flex flex--aic"
                        width={'100%'}
                        sx={{
                            backgroundColor: 'transparent',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                        }}
                    >
                        <StyledRFQItemNumberDiv
                        // paddingLeft={'1rem'}
                        // maxWidth={{ lg: '40rem', sm: '25rem', xs: '10rem' }}
                        // maxWidth={'100%'}
                        >
                            <FWTypography className="itemBoxHeaderNumber">
                                {idx}
                            </FWTypography>

                            <Grid
                                container
                                marginLeft={2}
                                xs={12}
                                justifyContent="space-between"
                                width={'100%'}
                            >
                                <FWTypography fontSize="17px" fontWeight="500">
                                    {
                                        rfqItemDetails?.item_information
                                            .buyer_item_name
                                    }
                                </FWTypography>
                                <Box
                                    color="primary.main"
                                    fontSize={'1.5rem'}
                                    paddingRight={3}
                                >
                                    {expanded ? (
                                        <i className="bi bi-chevron-up"></i>
                                    ) : (
                                        <i className="bi bi-chevron-down"></i>
                                    )}
                                </Box>
                            </Grid>
                        </StyledRFQItemNumberDiv>
                    </Box>
                </Box>
            </FWAccordionSummary>

            <FWAccordionDetails sx={{ marginTop: '8px' }}>
                <Grid container xs={12} padding={'0 18px 0 18px'} rowGap={2}>
                    <Grid item xs={12} color="text.secondary">
                        {`Item ID: ${rfqItemDetails.item_information.buyer_item_id}`}
                        {(rfqItemDetails.item_information.custom_ids ?? []).map(
                            (id, idx) => {
                                return (
                                    <Fragment key={idx}>
                                        &nbsp; | &nbsp;
                                        {id.name}: {id.value}
                                    </Fragment>
                                );
                            }
                        )}
                        {rfqItemDetails.attributes
                            .filter((attr) => attr.attribute_id !== null)
                            .map((attr, idx) => {
                                return (
                                    <Fragment key={idx}>
                                        &nbsp; | &nbsp;
                                        {attr.attribute_name}:{' '}
                                        {attr.attribute_values
                                            .map((val) => val.value)
                                            .join('/')}
                                    </Fragment>
                                );
                            })}
                        {rfqItemDetails.attributes
                            .filter((attr) => attr.attribute_id === null)
                            .map((attr, idx) => {
                                return (
                                    <Fragment key={idx}>
                                        &nbsp; | &nbsp;
                                        <Box
                                            component="span"
                                            sx={{ color: 'warning.main' }}
                                        >
                                            {attr.attribute_name}:{' '}
                                            {attr.attribute_values
                                                .map((val) => val.value)
                                                .join('/')}
                                        </Box>
                                    </Fragment>
                                );
                            })}
                    </Grid>
                    <Grid item xs={12} container>
                        <FWTypography color="text.secondary">
                            {'Seller bid quantity:'}
                        </FWTypography>
                        &nbsp;
                        {rfqBidItemDetails.quantity_information === null ? (
                            '-'
                        ) : (
                            <FWCurrencyTypoGraphyWithCurrencyUid
                                currencyUidOrAbbrevaition={
                                    rfqBidItemDetails.pricing_information
                                        ?.currency_code_abbreviation
                                }
                                currency_code_abbreviation={
                                    rfqBidItemDetails.pricing_information
                                        ?.currency_code_abbreviation
                                }
                                value={
                                    rfqBidItemDetails.quantity_information
                                        .quantity
                                }
                                measurementUnitName={
                                    rfqBidItemDetails.quantity_information
                                        .measurement_unit_name
                                }
                                dontshowSlash
                                hideCurrency
                            />
                        )}
                    </Grid>
                    <Grid item xs={12}>
                        <Divider sx={{ width: '100%' }} />
                    </Grid>
                    <Grid item xs={12}>
                        <SingleCsAllocationBidItemDSRendering
                            rfqBidItemId={rfqBidItemId}
                        />
                    </Grid>
                </Grid>
            </FWAccordionDetails>
        </FWAccordion>
    ) : null;
};

export default memo(SingleDsAllocationBidItemrenderer);

interface ILabelOption {
    label: string;
    value: {
        bom_item: IBomItemDeliverScheduleStructure | null;
        cost_centre_id: string | null;
        cost_centre_name: string | null;
        gl_code: string | null;
        general_ledger_id: string | null;
        project_code: string | null;
        project_id: string | null;
    };
}

const SingleCsAllocationBidItemDSRendering = memo(
    ({ rfqBidItemId }: ISingleDsAllocationBidItemrendererProps) => {
        const rfqBidItemDetails = useSelector(
            (store: IGlobalAppStore) =>
                store.QuoteComparison.DSAllocationPopupData.listofBidItems.find(
                    (item) => item.rfq_bid_item_id === rfqBidItemId
                ) ?? null,
            nestedDeepEqual
        );

        const rfqItemDetails = useSelector(
            (store: IGlobalAppStore) =>
                rfqBidItemDetails &&
                store.QuoteComparison.cachingRfqData[rfqBidItemDetails.rfq_item]
                    ? store.QuoteComparison.cachingRfqData[
                          rfqBidItemDetails.rfq_item
                      ]
                    : null,
            nestedDeepEqual
        );

        const { entityId, templateId } = useSelector(
            (store: IGlobalAppStore) =>
                store.QuoteComparison.templateInformation,
            nestedDeepEqual
        );

        const { templateDetails } = useRfqTemplate(
            templateId ? templateId : 'DEFAULT',
            entityId ? entityId : ''
        );

        const showDeliveryDate = useMemo(() => {
            return templateDetails.fieldSettings.standardFields[
                TemplateStandardFieldsEnum.DELIVERY_DATE
            ].buyerSettings.showField;
        }, [templateDetails.fieldSettings.standardFields]);
        const showProject = useMemo(() => {
            return (
                templateDetails.fieldSettings.standardFields[
                    TemplateStandardFieldsEnum.PROJECT
                ].buyerSettings.showField &&
                templateDetails.sections.EVENT_DETAILS.fieldList.find(
                    (field) =>
                        field.field === TemplateStandardFieldsEnum.PROJECT
                ) === undefined
            );
        }, [
            templateDetails.fieldSettings.standardFields,
            templateDetails.sections.EVENT_DETAILS.fieldList,
        ]);
        const showCostCenter = useMemo(() => {
            return (
                templateDetails.fieldSettings.standardFields[
                    TemplateStandardFieldsEnum.COST_CENTER
                ].buyerSettings.showField &&
                templateDetails.sections.EVENT_DETAILS.fieldList.find(
                    (field) =>
                        field.field === TemplateStandardFieldsEnum.COST_CENTER
                ) === undefined
            );
        }, [
            templateDetails.fieldSettings.standardFields,
            templateDetails.sections.EVENT_DETAILS.fieldList,
        ]);
        const showGL = useMemo(() => {
            return (
                templateDetails.fieldSettings.standardFields[
                    TemplateStandardFieldsEnum.GL
                ].buyerSettings.showField &&
                templateDetails.sections.EVENT_DETAILS.fieldList.find(
                    (field) => field.field === TemplateStandardFieldsEnum.GL
                ) === undefined
            );
        }, [
            templateDetails.fieldSettings.standardFields,
            templateDetails.sections.EVENT_DETAILS.fieldList,
        ]);

        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const listOfDeliveryDateOption = useMemo(() => {
            const options: { label: string; value: string | null }[] = [];

            if (rfqBidItemDetails) {
                for (let ds of rfqBidItemDetails.delivery_schedule) {
                    const temp: { label: string; value: string | null } = {
                        label:
                            ds.delivery_date === null
                                ? 'TBD'
                                : getFWDate(ds.delivery_date),
                        value: ds.delivery_date,
                    };

                    if (
                        options.findIndex((v) => nestedDeepEqual(v, temp)) ===
                        -1
                    ) {
                        options.push(temp);
                    }
                }
            }

            return options;
        }, [rfqBidItemDetails]);

        const dropDownOption: ILabelOption[] = useMemo(() => {
            return getListOfBomFromAllRfqItemDS(
                rfqItemDetails,
                templateDetails
            );
        }, [rfqItemDetails, templateDetails]);

        const rfqBidId = useSelector(
            (store: IGlobalAppStore) =>
                store.QuoteComparison.DSAllocationPopupData.rfqBidIdSelected
        );
        const DSForCurrentRfqBidItem = useSelector(
            (store: IGlobalAppStore) =>
                rfqBidId &&
                store.QuoteComparison.DSAllocationPopupData.allocationPopupData[
                    rfqBidId
                ] &&
                store.QuoteComparison.DSAllocationPopupData.allocationPopupData[
                    rfqBidId
                ][rfqBidItemId]
                    ? store.QuoteComparison.DSAllocationPopupData
                          .allocationPopupData[rfqBidId][rfqBidItemId]
                    : null,
            nestedDeepEqual
        );

        const dispatch = useDispatch();
        const updateDsValue = useCallback(
            (
                field: 'quantity' | 'delivery_date',
                idx: number,
                value: string | null
            ) => {
                const ds = cloneDeep(DSForCurrentRfqBidItem);

                if (ds) {
                    ds.updated = true;

                    if (field === 'quantity')
                        ds.dsData[idx].quantity = value ? value : '';

                    if (field === 'delivery_date')
                        ds.dsData[idx].delivery_date = value;

                    dispatch(
                        updateDSAllocationPopupStore({
                            type: 'UPDATE_DS_ALLOCATION_SINGLE_RFQ_BID_ITEM',
                            value: {
                                rfqbidId: rfqBidId ? rfqBidId : '',
                                rfqBidItemid: rfqBidItemId,
                                wholeDsData: ds,
                            },
                        })
                    );
                }
            },
            [DSForCurrentRfqBidItem, dispatch, rfqBidId, rfqBidItemId]
        );

        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const updateDsBomGlCcProjectValue = useCallback(
            (option: ILabelOption, index: number) => {
                const ds = cloneDeep(DSForCurrentRfqBidItem);

                if (ds) {
                    if (ds.dsData.length > index) {
                        ds.dsData[index].bom_item = option.value.bom_item;

                        ds.dsData[index].cost_centre_id =
                            option.value.cost_centre_id;

                        ds.dsData[index].cost_centre_name =
                            option.value.cost_centre_name;

                        ds.dsData[index].general_ledger_id =
                            option.value.general_ledger_id;

                        ds.dsData[index].gl_code = option.value.gl_code;

                        ds.dsData[index].project_code =
                            option.value.project_code;

                        ds.dsData[index].project_id = option.value.project_id;
                    }

                    dispatch(
                        updateDSAllocationPopupStore({
                            type: 'UPDATE_DS_ALLOCATION_SINGLE_RFQ_BID_ITEM',
                            value: {
                                rfqbidId: rfqBidId ? rfqBidId : '',
                                rfqBidItemid: rfqBidItemId,
                                wholeDsData: ds,
                            },
                        })
                    );
                }
            },
            [DSForCurrentRfqBidItem, dispatch, rfqBidId, rfqBidItemId]
        );

        useEffect(() => {
            if (
                (DSForCurrentRfqBidItem?.dsData.length ?? 0) === 0 &&
                rfqBidItemDetails &&
                rfqItemDetails
            ) {
                dispatch(
                    updateDSAllocationPopupStore({
                        type: 'UPDATE_DS_ALLOCATION_SINGLE_RFQ_BID_ITEM',
                        value: {
                            rfqbidId: rfqBidId ? rfqBidId : '',
                            rfqBidItemid: rfqBidItemId,
                            wholeDsData: {
                                dsData: autoAllocateBasedOnDsWithoutDate(
                                    JSON.parse(
                                        JSON.stringify(
                                            rfqItemDetails.delivery_schedule
                                        )
                                    ),
                                    JSON.parse(
                                        JSON.stringify(
                                            rfqBidItemDetails.delivery_schedule
                                        )
                                    )
                                ),
                                updated: true,
                            },
                        },
                    })
                );
            }
        }, [
            DSForCurrentRfqBidItem,
            dispatch,
            dropDownOption,
            rfqBidId,
            rfqBidItemDetails,
            rfqBidItemId,
            rfqItemDetails,
        ]);
        const showBom = useSelector(
            (store: IGlobalAppStore) =>
                store.QuoteComparison.listOfAllBomUse.length > 0
        );
        const showReq = useSelector(
            (store: IGlobalAppStore) =>
                store.QuoteComparison.requisitionIds.length > 0
        );
        const noOfShown = useMemo(() => {
            let noOfShown = 0;

            if (showBom) {
                noOfShown += 1;
            }
            if (showProject) {
                noOfShown += 1;
            }
            if (showCostCenter) {
                noOfShown += 1;
            }
            if (showGL) {
                noOfShown += 1;
            }
            if (showDeliveryDate) {
                noOfShown += 1;
            }
            if (showReq) {
                noOfShown += 1;
            }

            return noOfShown;
        }, [
            showBom,
            showCostCenter,
            showDeliveryDate,
            showGL,
            showProject,
            showReq,
        ]);
        const sizeOfEachItem = useMemo(() => {
            let noOfHidden = 0;

            if (!showBom) {
                noOfHidden += 1;
            }
            if (!showProject) {
                noOfHidden += 1;
            }
            if (!showCostCenter) {
                noOfHidden += 1;
            }
            if (!showGL) {
                noOfHidden += 1;
            }
            if (!showDeliveryDate) {
                noOfHidden += 1;
            }
            if (!showReq) {
                noOfHidden += 1;
            }

            return 12 / (8 - noOfHidden);
        }, [
            showBom,
            showCostCenter,
            showDeliveryDate,
            showGL,
            showProject,
            showReq,
        ]);

        return rfqBidItemDetails && rfqItemDetails ? (
            <>
                <Grid container xs={12} rowSpacing={1}>
                    <Grid item xs={12}>
                        <Grid container xs={12}>
                            {/* BOM,project,gl,cost center, delivery date,Requested quantity and Allocated quantity */}
                            {showBom && (
                                <Grid item xs={sizeOfEachItem}>
                                    <FWTypography color="text.secondary">
                                        BOM ID
                                    </FWTypography>
                                </Grid>
                            )}
                            {showReq && (
                                <Grid item xs={sizeOfEachItem}>
                                    <FWTypography color="text.secondary">
                                        Requisition ID
                                    </FWTypography>
                                </Grid>
                            )}
                            {showProject && (
                                <Grid item xs={sizeOfEachItem}>
                                    <FWTypography color="text.secondary">
                                        {
                                            templateDetails.fieldSettings
                                                .standardFields[
                                                TemplateStandardFieldsEnum
                                                    .PROJECT
                                            ].label
                                        }
                                    </FWTypography>
                                </Grid>
                            )}
                            {showCostCenter && (
                                <Grid item xs={sizeOfEachItem}>
                                    <FWTypography color="text.secondary">
                                        {
                                            templateDetails.fieldSettings
                                                .standardFields[
                                                TemplateStandardFieldsEnum
                                                    .COST_CENTER
                                            ].label
                                        }
                                    </FWTypography>
                                </Grid>
                            )}
                            {showGL && (
                                <Grid item xs={sizeOfEachItem}>
                                    <FWTypography color="text.secondary">
                                        {
                                            templateDetails.fieldSettings
                                                .standardFields[
                                                TemplateStandardFieldsEnum.GL
                                            ].label
                                        }
                                    </FWTypography>
                                </Grid>
                            )}
                            {showDeliveryDate && (
                                <Grid item xs={sizeOfEachItem}>
                                    <FWTypography color="text.secondary">
                                        {
                                            templateDetails.fieldSettings
                                                .standardFields[
                                                TemplateStandardFieldsEnum
                                                    .DELIVERY_DATE
                                            ].label
                                        }
                                    </FWTypography>
                                </Grid>
                            )}
                            <Grid item xs={sizeOfEachItem}>
                                <FWTypography color="text.secondary">
                                    {'Requested ' +
                                        templateDetails.fieldSettings.standardFields[
                                            TemplateStandardFieldsEnum.QUANTITY
                                        ].label.toLowerCase()}
                                </FWTypography>
                            </Grid>
                            <Grid item xs={sizeOfEachItem}>
                                <FWTypography color="text.secondary">
                                    {'Allocated ' +
                                        templateDetails.fieldSettings.standardFields[
                                            TemplateStandardFieldsEnum.QUANTITY
                                        ].label.toLowerCase()}
                                </FWTypography>
                            </Grid>
                        </Grid>
                    </Grid>
                    {DSForCurrentRfqBidItem &&
                        DSForCurrentRfqBidItem.dsData.map((ds, idx) => {
                            return (
                                <Grid item xs={12}>
                                    <Grid
                                        container
                                        xs={12}
                                        columnSpacing={2}
                                        flexWrap={'nowrap'}
                                    >
                                        {showBom && (
                                            <Grid item xs={sizeOfEachItem}>
                                                <div
                                                    className="flex flex--col"
                                                    style={{
                                                        height: '100%',
                                                        justifyContent:
                                                            'center',
                                                    }}
                                                >
                                                    <div className="flex flex--row flex--aic">
                                                        <FWTooltip
                                                            title={
                                                                ds.bom_item
                                                                    ? getBomHierarachyLabelFromBoms(
                                                                          ds.bom_item
                                                                      )
                                                                    : ''
                                                            }
                                                            placement="top"
                                                        >
                                                            <Badge
                                                                badgeContent={
                                                                    ds.bom_item
                                                                        ? getMostDeepestSubBomCode(
                                                                              ds
                                                                                  .bom_item
                                                                                  .parent_sub_bom_item
                                                                          )
                                                                              ?.depth
                                                                        : undefined
                                                                }
                                                            >
                                                                {ds.bom_item
                                                                    ? ds
                                                                          .bom_item
                                                                          .parent_sub_bom_item
                                                                        ? getMostDeepestSubBomCode(
                                                                              ds
                                                                                  .bom_item
                                                                                  .parent_sub_bom_item
                                                                          )
                                                                            ? getMostDeepestSubBomCode(
                                                                                  ds
                                                                                      .bom_item
                                                                                      .parent_sub_bom_item
                                                                              )
                                                                                  ?.bomCode
                                                                            : '-'
                                                                        : ds
                                                                              .bom_item
                                                                              .enterprise_bom
                                                                              ?.bom_code ??
                                                                          '-'
                                                                    : '-'}
                                                            </Badge>
                                                        </FWTooltip>

                                                        {/* {ds.bom_item &&
                                                            ds.bom_item
                                                                .parent_sub_bom_item &&
                                                            getMostDeepestSubBomCode(
                                                                ds.bom_item
                                                                    .parent_sub_bom_item
                                                            ) && (
                                                                <FWTooltip
                                                                    title={getBomHierarachyLabelFromBoms(
                                                                        ds.bom_item
                                                                    )}
                                                                    placement="top"
                                                                >
                                                                    <Box>
                                                                        <FWTypography>
                                                                            Depth:{' '}
                                                                            {getMostDeepestSubBomCode(
                                                                                ds
                                                                                    .bom_item
                                                                                    .parent_sub_bom_item
                                                                            )
                                                                                ?.depth ??
                                                                                '-'}
                                                                        </FWTypography>
                                                                    </Box>
                                                                </FWTooltip>
                                                            )} */}
                                                    </div>
                                                </div>
                                            </Grid>
                                        )}
                                        {showReq && (
                                            <Grid item xs={sizeOfEachItem}>
                                                <div
                                                    className="flex flex--col"
                                                    style={{
                                                        height: '100%',
                                                        justifyContent:
                                                            'center',
                                                    }}
                                                >
                                                    <div className="flex flex--row flex--aic">
                                                        {/* <FWTooltip
                                                            title={
                                                                ds.parent_delivery_schedule_item
                                                                    ? ds
                                                                          .parent_delivery_schedule_item
                                                                          .requisition_item
                                                                          .custom_requisition_id
                                                                    : ''
                                                            }
                                                            placement="top"
                                                        >
                                                            <Badge
                                                                badgeContent={
                                                                    ds.bom_item
                                                                        ? getMostDeepestSubBomCode(
                                                                              ds
                                                                                  .bom_item
                                                                                  .parent_sub_bom_item
                                                                          )
                                                                              ?.depth
                                                                        : undefined
                                                                }
                                                            ></Badge>
                                                        </FWTooltip> */}

                                                        {ds.parent_delivery_schedule_item &&
                                                            ds
                                                                .parent_delivery_schedule_item
                                                                .requisition_item && (
                                                                <Box>
                                                                    <FWTypography>
                                                                        {
                                                                            ds
                                                                                .parent_delivery_schedule_item
                                                                                .requisition_item
                                                                                .custom_requisition_id
                                                                        }
                                                                    </FWTypography>
                                                                </Box>
                                                            )}
                                                    </div>
                                                </div>
                                            </Grid>
                                        )}
                                        {showProject && (
                                            <Grid item xs={sizeOfEachItem}>
                                                <div
                                                    className="flex flex--col"
                                                    style={{
                                                        height: '100%',
                                                        justifyContent:
                                                            'center',
                                                    }}
                                                >
                                                    <div>
                                                        {ds.project_code ?? '-'}
                                                    </div>
                                                </div>
                                            </Grid>
                                        )}
                                        {showCostCenter && (
                                            <Grid item xs={sizeOfEachItem}>
                                                <div
                                                    className="flex flex--col"
                                                    style={{
                                                        height: '100%',
                                                        justifyContent:
                                                            'center',
                                                    }}
                                                >
                                                    <div>
                                                        {ds.cost_centre_name ??
                                                            '-'}
                                                    </div>
                                                </div>
                                            </Grid>
                                        )}
                                        {showGL && (
                                            <Grid item xs={sizeOfEachItem}>
                                                <div
                                                    className="flex flex--col"
                                                    style={{
                                                        height: '100%',
                                                        justifyContent:
                                                            'center',
                                                    }}
                                                >
                                                    <div>
                                                        {ds.gl_code ?? '-'}
                                                    </div>
                                                </div>
                                            </Grid>
                                        )}
                                        {showDeliveryDate && (
                                            <Grid item xs={sizeOfEachItem}>
                                                <FWTypography>
                                                    {ds.delivery_date
                                                        ? getFWDate(
                                                              ds.delivery_date
                                                          )
                                                        : 'TBD'}
                                                </FWTypography>
                                            </Grid>
                                        )}
                                        <Grid item xs={sizeOfEachItem}>
                                            <FWTypography>
                                                {rfqItemDetails.delivery_schedule.find(
                                                    (rfqDs) => {
                                                        const temp1: any =
                                                            cloneDeep(rfqDs);
                                                        const temp2: any =
                                                            cloneDeep(ds);

                                                        delete temp1[
                                                            'quantity'
                                                        ];
                                                        delete temp2[
                                                            'quantity'
                                                        ];

                                                        delete temp1[
                                                            'delivery_schedule_item_id'
                                                        ];
                                                        delete temp2[
                                                            'delivery_schedule_item_id'
                                                        ];
                                                        delete temp1[
                                                            'fulfilment_information'
                                                        ];
                                                        delete temp2[
                                                            'fulfilment_information'
                                                        ];

                                                        if (
                                                            temp1.enterprise_bom
                                                        )
                                                            temp1.enterprise_bom.quantity =
                                                                (+temp1
                                                                    .enterprise_bom
                                                                    .quantity).toString();
                                                        if (
                                                            temp2.enterprise_bom
                                                        )
                                                            temp2.enterprise_bom.quantity =
                                                                (+temp2
                                                                    .enterprise_bom
                                                                    .quantity).toString();

                                                        if (temp1.bom_item)
                                                            delete temp1
                                                                .bom_item[
                                                                'index'
                                                            ];
                                                        if (temp2.bom_item)
                                                            delete temp2
                                                                .bom_item[
                                                                'index'
                                                            ];

                                                        if (temp1.bom_item) {
                                                            removeQuantityFromEveryWhere(
                                                                temp1.bom_item
                                                            );
                                                            removeIndexFromEveryWhere(
                                                                temp1.bom_item
                                                            );
                                                        }
                                                        if (temp2.bom_item) {
                                                            removeQuantityFromEveryWhere(
                                                                temp2.bom_item
                                                            );
                                                            removeIndexFromEveryWhere(
                                                                temp2.bom_item
                                                            );
                                                        }

                                                        return isEqual(
                                                            temp1,
                                                            temp2
                                                        );
                                                    }
                                                )?.quantity ?? '-'}{' '}
                                                {
                                                    rfqItemDetails
                                                        .quantity_information
                                                        .measurement_unit_name
                                                }
                                            </FWTypography>
                                        </Grid>
                                        <Grid item xs={sizeOfEachItem}>
                                            <FWInput
                                                value={ds.quantity}
                                                allowOnly="DECIMAL_NUMBER"
                                                maxNumberOfDecimal={10}
                                                onChange={(e) => {
                                                    updateDsValue(
                                                        'quantity',
                                                        idx,
                                                        e.target.value
                                                    );
                                                }}
                                                InputProps={{
                                                    endAdornment: (
                                                        <InputAdornment
                                                            position={'end'}
                                                            sx={{
                                                                m: '0 0.75rem',
                                                            }}
                                                        >
                                                            {
                                                                rfqItemDetails
                                                                    .quantity_information
                                                                    .measurement_unit_name
                                                            }
                                                        </InputAdornment>
                                                    ),
                                                }}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                            );
                        })}
                    <Grid item xs={12}>
                        <Divider sx={{ width: '100%' }} />
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container xs={12} flexWrap={'nowrap'}>
                            <Grid xs={sizeOfEachItem}>
                                <FWTypography fontWeight={500}>
                                    Total Quantity
                                </FWTypography>
                            </Grid>
                            <Grid xs={(noOfShown - 1) * sizeOfEachItem}></Grid>
                            <Grid item xs={sizeOfEachItem}>
                                <FWTypography>
                                    {rfqItemDetails.delivery_schedule.reduce(
                                        (acc, next) => acc + +next.quantity,
                                        0
                                    )}{' '}
                                    {
                                        rfqItemDetails.quantity_information
                                            .measurement_unit_name
                                    }
                                </FWTypography>
                            </Grid>
                            <Grid item xs={sizeOfEachItem}>
                                <FWTypography>
                                    {roundToNDecimalPlace(
                                        DSForCurrentRfqBidItem?.dsData.reduce(
                                            (acc, next) => acc + +next.quantity,
                                            0
                                        ) ?? 0,
                                        4
                                    )}{' '}
                                    {
                                        rfqItemDetails.quantity_information
                                            .measurement_unit_name
                                    }
                                </FWTypography>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </>
        ) : null;
    }
);

interface IDeliverSchedule {
    bom_item: IBomItemDeliverScheduleStructure | null;
    quantity: string;
    delivery_date: string | null;
    cost_centre_id: string | null;
    cost_centre_name: string | null;
    gl_code: string | null;
    customer_entity_id: string | null;
    general_ledger_id: string | null;
    project_code: string | null;
    project_id: string | null;
    delivery_schedule_item_id: string | null;
}

const autoAllocateBasedOnDsWithoutDate: (
    rfqDsValue: IDeliverSchedule[],
    SellerDsValue: IDeliverSchedule[]
) => IDeliverSchedule[] = (
    rfqDsValue: IDeliverSchedule[],
    SellerDsValue: IDeliverSchedule[]
) => {
    const totalRfqQuantity = rfqDsValue.reduce(
        (acc, next) => acc + +next.quantity,
        0
    );
    const totalSellerQuantity = SellerDsValue.reduce(
        (acc, next) => acc + +next.quantity,
        0
    );

    return rfqDsValue.map((ds) => ({
        ...ds,
        delivery_schedule_item_id: null,
        quantity: roundToNDecimalPlace(
            (+ds.quantity / totalRfqQuantity) * totalSellerQuantity,
            2
        ).toString(),
    }));
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const autoAllocateDs: (
    rfqDsValue: IDeliverSchedule[],
    SellerDsValue: IDeliverSchedule[],
    dropDownOption: ILabelOption[]
) => IDeliverSchedule[] = (rfqDsValue, SellerDsValue, dropDownOption) => {
    if (rfqDsValue.length === 1) {
        return SellerDsValue.map((sellerds) => ({
            bom_item: rfqDsValue[0].bom_item ? rfqDsValue[0].bom_item : null,
            cost_centre_id: rfqDsValue[0].cost_centre_id
                ? rfqDsValue[0].cost_centre_id
                : null,
            cost_centre_name: rfqDsValue[0].cost_centre_name
                ? rfqDsValue[0].cost_centre_name
                : null,
            customer_entity_id: rfqDsValue[0].customer_entity_id
                ? rfqDsValue[0].customer_entity_id
                : null,
            general_ledger_id: rfqDsValue[0].general_ledger_id
                ? rfqDsValue[0].general_ledger_id
                : null,
            gl_code: rfqDsValue[0].gl_code ? rfqDsValue[0].gl_code : null,
            project_code: rfqDsValue[0].project_code
                ? rfqDsValue[0].project_code
                : null,
            project_id: rfqDsValue[0].project_id
                ? rfqDsValue[0].project_id
                : null,
            delivery_schedule_item_id: null,
            delivery_date: sellerds.delivery_date,
            quantity: sellerds.quantity,
        }));
    } else if (rfqDsValue.length > 0) {
        let allocationDs: IDeliverSchedule[] = [];

        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const dropDownOptionUsed = new Set<ILabelOption>();
        if (dropDownOption.length === 1) {
            return SellerDsValue.map((ds) => ({
                bom_item: dropDownOption[0].value.bom_item,
                cost_centre_id: dropDownOption[0].value.cost_centre_id,
                cost_centre_name: null,
                customer_entity_id: null,
                general_ledger_id: dropDownOption[0].value.general_ledger_id,
                gl_code: dropDownOption[0].value.gl_code,
                project_code: dropDownOption[0].value.project_code,
                project_id: dropDownOption[0].value.project_id,
                delivery_date: ds.delivery_date,
                quantity: ds.quantity,
                delivery_schedule_item_id: null,
            }));
        }

        const copyOfRfqDsValue = cloneDeep(rfqDsValue);

        copyOfRfqDsValue.sort((a, b) => (a.delivery_date === null ? 1 : -1));
        SellerDsValue.sort((a, b) => (a.delivery_date === null ? 1 : -1));

        for (let ds of SellerDsValue) {
            let availableQuantity = +ds.quantity;

            // while (availableQuantity > 0) {
            //     if (ds.delivery_date === null) {
            //     }
            // }
            while (availableQuantity > 0) {
                if (ds.delivery_date === null) {
                    const availableRfqDsValue = copyOfRfqDsValue.find(
                        (rfqds) =>
                            rfqds.delivery_date === null && +rfqds.quantity > 0
                    );

                    if (availableRfqDsValue) {
                        if (
                            +availableRfqDsValue.quantity > +availableQuantity
                        ) {
                            allocationDs.push({
                                ...availableRfqDsValue,
                                delivery_schedule_item_id: null,
                                quantity: availableQuantity.toString(),
                            });

                            availableRfqDsValue.quantity = (
                                +availableRfqDsValue.quantity -
                                availableQuantity
                            ).toString();
                            availableQuantity = 0;
                        } else {
                            allocationDs.push({
                                ...availableRfqDsValue,
                                delivery_schedule_item_id: null,
                            });
                            availableQuantity -= +availableRfqDsValue.quantity;
                            availableRfqDsValue.quantity = (
                                +availableRfqDsValue.quantity -
                                availableQuantity
                            ).toString();
                        }
                    } else {
                        allocationDs.push({
                            ...ds,
                            delivery_schedule_item_id: null,
                        });
                        availableQuantity = 0;
                    }
                } else {
                    const availableRfqDsValue = copyOfRfqDsValue.find(
                        (rfqds) =>
                            rfqds.delivery_date === ds.delivery_date &&
                            +rfqds.quantity > 0
                    );

                    if (availableRfqDsValue) {
                        if (
                            +availableRfqDsValue.quantity > +availableQuantity
                        ) {
                            allocationDs.push({
                                ...availableRfqDsValue,
                                delivery_schedule_item_id: null,
                                quantity: availableQuantity.toString(),
                            });

                            availableRfqDsValue.quantity = (
                                +availableRfqDsValue.quantity -
                                availableQuantity
                            ).toString();
                            availableQuantity = 0;
                        } else {
                            allocationDs.push({
                                ...availableRfqDsValue,
                                delivery_schedule_item_id: null,
                            });
                            availableQuantity -= +availableRfqDsValue.quantity;
                            availableRfqDsValue.quantity = (
                                +availableRfqDsValue.quantity -
                                availableQuantity
                            ).toString();
                        }
                    } else {
                        allocationDs.push({
                            ...ds,
                            delivery_schedule_item_id: null,
                        });
                        availableQuantity = 0;
                    }
                }
            }

            // bom_item: ds.bom_item ? ds.bom_item : null,
            // cost_centre_id: ds.cost_centre_id ? ds.cost_centre_id : null,
            // cost_centre_name: ds.cost_centre_name ? ds.cost_centre_name : null,
            // customer_entity_id: ds.customer_entity_id
            //     ? ds.customer_entity_id
            //     : null,
            // general_ledger_id: ds.general_ledger_id
            //     ? ds.general_ledger_id
            //     : null,
            // gl_code: ds.gl_code ? ds.gl_code : null,
            // project_code: ds.project_code ? ds.project_code : null,
            // project_id: ds.project_id ? ds.project_id : null,
            // delivery_date: ds.delivery_date ? ds.delivery_date : null,
            // quantity: '',
        }

        if (allocationDs.length > 0) {
            return allocationDs;
        }
    }

    return [
        {
            quantity: '',
            delivery_date: null,
            bom_item: null,
            project_code: null,
            project_id: null,
            cost_centre_id: null,
            cost_centre_name: null,
            general_ledger_id: null,
            gl_code: null,
            customer_entity_id: null,
            delivery_schedule_item_id: null,
        },
    ];
};

const getListOfBomFromAllRfqItemDS = (
    rfqItemDetails: IEventItemDetails | null,
    templateDetails: IRfqTemplate
) => {
    const options: ILabelOption[] = [];

    if (rfqItemDetails) {
        for (let ds of rfqItemDetails.delivery_schedule) {
            let label: string[] = [];

            if (ds.bom_item?.enterprise_bom?.bom_code) {
                label.push(`bomId: ${ds.bom_item?.enterprise_bom?.bom_code}`);
            }
            if (ds.project_code) {
                label.push(
                    `${
                        templateDetails.fieldSettings.standardFields[
                            TemplateStandardFieldsEnum.PROJECT
                        ].label
                    }: ${ds.project_code}`
                );
            }
            if (ds.cost_centre_name) {
                label.push(
                    `${
                        templateDetails.fieldSettings.standardFields[
                            TemplateStandardFieldsEnum.COST_CENTER
                        ].label
                    }: ${ds.cost_centre_name}`
                );
            }
            if (ds.gl_code) {
                label.push(
                    `${
                        templateDetails.fieldSettings.standardFields[
                            TemplateStandardFieldsEnum.GL
                        ].label
                    }: ${ds.gl_code}`
                );
            }

            const temp: ILabelOption = {
                label: label.join(' | '),
                value: {
                    bom_item: ds.bom_item,
                    cost_centre_id: ds.cost_centre_id,
                    cost_centre_name: ds.cost_centre_name
                        ? ds.cost_centre_name
                        : null,
                    general_ledger_id: ds.general_ledger_id,
                    gl_code: ds.gl_code ? ds.gl_code : null,
                    project_code: ds.project_code ? ds.project_code : null,
                    project_id: ds.project_id,
                },
            };
            if (
                options.findIndex((singleOption) =>
                    nestedDeepEqual(singleOption, temp)
                ) === -1
            ) {
                options.push(temp);
            }
        }
    }

    return options;
};

export const removeIndexFromEveryWhere = (
    bomItem: IBomItemDeliverScheduleStructure
) => {
    delete (bomItem as any)['index'];

    if (bomItem.parent_sub_bom_item) {
        removeIndexFromEveryWhereParent(bomItem.parent_sub_bom_item);
    }
};

const removeIndexFromEveryWhereParent = (
    bomItem: IParentSubBomItemForIBomdeliveryScheduleStrutcure
) => {
    delete (bomItem as any)['index'];

    if (bomItem.parent_sub_bom_item) {
        removeIndexFromEveryWhereParent(bomItem.parent_sub_bom_item);
    }
};

export const removeQuantityFromEveryWhere = (
    bomItem: IBomItemDeliverScheduleStructure
) => {
    delete (bomItem as any)['quantity'];
    delete (bomItem as any)['measurement_unit'];
    delete (bomItem as any)['measurement_unit_abbreviation'];
    delete (bomItem as any)['measurement_unit_name'];
    delete (bomItem as any)['bom_item'];

    if (bomItem.enterprise_bom)
        bomItem.enterprise_bom.quantity = (+bomItem.enterprise_bom!
            .quantity).toString();

    if (bomItem.enterprise_bom)
        bomItem.enterprise_bom.quantity = (+bomItem.enterprise_bom!
            .quantity).toString();

    if (bomItem.parent_sub_bom_item) {
        removeQuantityFromEveryWhereParent(bomItem.parent_sub_bom_item);
    }
};

const removeQuantityFromEveryWhereParent = (
    bomItem: IParentSubBomItemForIBomdeliveryScheduleStrutcure
) => {
    delete (bomItem as any)['quantity'];
    delete (bomItem as any)['measurement_unit'];
    delete (bomItem as any)['measurement_unit_abbreviation'];
    delete (bomItem as any)['measurement_unit_name'];
    delete (bomItem as any)['bom_item'];

    if (bomItem.enterprise_bom)
        bomItem.enterprise_bom.quantity = (+bomItem.enterprise_bom!
            .quantity).toString();
    if (bomItem.parent_sub_bom_item) {
        removeQuantityFromEveryWhereParent(bomItem.parent_sub_bom_item);
    }
};
