import { createSlice } from '@reduxjs/toolkit';
import { blankVisitTemplate, IVisitTemplate } from '../Hooks/VisitConstants';

export interface IVisitStore {
    templateDetails: IVisitTemplate;
    entityID: string;
    enterprise_buyer_master_id: string;
    buyerEntityID: string;
}
const visitInitialData: IVisitStore = {
    templateDetails: blankVisitTemplate,
    entityID: '',
    enterprise_buyer_master_id: '',
    buyerEntityID: '',
};

export interface IUpdateVisitStoreAction {
    type: string;
    payload:
        | {
              type: 'UPDATE_QUOTE_TEMPLATE';
              value: IVisitTemplate;
          }
        | {
              type: 'UPDATE_ENTITY';
              value: string;
          }
        | {
              type: 'UPDATE_CUSTOMER';
              value: string;
          }
        | {
              type: 'UPDATE_BUYER_ENTITY_ID';
              value: string;
          };
}

export const VisitSlice = createSlice<
    IVisitStore,
    {
        updateVisitDataStore: (
            state: IVisitStore,
            action: IUpdateVisitStoreAction
        ) => void;
    },
    string
>({
    name: 'VisitStore',
    initialState: visitInitialData,
    reducers: {
        updateVisitDataStore: (
            state: IVisitStore,
            action: IUpdateVisitStoreAction
        ) => {
            try {
                switch (action.payload.type) {
                    case 'UPDATE_QUOTE_TEMPLATE':
                        state.templateDetails = action.payload.value;
                        break;
                    case 'UPDATE_ENTITY':
                        state.entityID = action.payload.value;
                        break;
                    case 'UPDATE_CUSTOMER':
                        state.enterprise_buyer_master_id = action.payload.value;
                        break;
                    case 'UPDATE_BUYER_ENTITY_ID':
                        state.buyerEntityID = action.payload.value;
                }
            } catch (err) {
                console.error(err);
            }
        },
    },
});

export const { updateVisitDataStore } = VisitSlice.actions;

const VisitStore = VisitSlice.reducer;
export default VisitStore;
