import { cloneDeep, isEmpty, isNull, uniqueId } from 'lodash';
import {
    Dispatch,
    SetStateAction,
    useCallback,
    useEffect,
    useState,
} from 'react';
import {
    HookStateValue,
    IHookState,
    useHookState,
} from '../../../../Common/Hooks/StateHook';
import { isValidEmail } from '../../../../Common/Utils/ValidationUtils';
import { ICustomSection } from '../../../../Global/Interfaces/TemplateInterface';
import { VerificationStatus } from '../../../../Redux/Slices/VerificationSlice';
import {
    IPreferredItem,
    IVendorAddDetails,
    IVendorListSummary,
    IVendorTemplateFrontEnd,
    PreferredItemStatus,
    VendorStatus,
    VendorVerificationStatus,
} from '../../Interfaces/VendorsInterface';
import {
    IPayloadItem,
    IVendorItemUpdatePayload,
    IVendorSearchedData,
    addCustomVendorToVendorDirectory,
    addVendorToVendorDirectory,
    getGlobalVendorTags,
    runSearchAllVendorsInFactwise,
    updateVendorItems,
} from '../../Services/VendorsService';
import { useVendorTemplateHook } from './useVendorTemplateHook';

export enum VendorDetailActions {
    VENDOR_CODE = 'VENDOR_CODE',
    VENDOR_NOTES = 'VENDOR_NOTES',
    VENDOR_TAGS = 'VENDOR_TAGS',
    USER_EMAIL = 'USER_EMAIL',
    USER_NAME = 'USER_NAME',
    USER_INTERNAL_NAME = 'USER_INTERNAL_NAME',
    USER_NUMBER = 'USER_NUMBER',
    USER_NOTES = 'USER_NOTES',
    USER_DEFAULT = 'USER_DEFAULT',
    USER_INVITE_DEFAULT = 'USER_INVITE_DEFAULT',
    USER_SECONDARY_CONTACT_ADD = 'USER_SECONDARY_CONTACT_ADD',
    USER_CONTACT_ADD = 'USER_CONTACT_ADD',
    USER_CONTACT_REMOVE = 'USER_CONTACT_REMOVE',
    USER_CONTACT_UPDATE = 'USER_CONTACT_UPDATE',
    ENTITY_ADD = 'ENTITY_ADD',
    ENTITY_REMOVE = 'ENTITY_REMOVE',
    USER_TOGGLE_CC_EMAIL = 'USER_TOGGLE_CC_EMAIL',
    USER_TOGGLE_SECONDARY_EMAIL = 'USER_TOGGLE_SECONDARY_EMAIL',
    SELLER_ADDRESS_INFORMATION = 'SELLER_ADDRESS_INFORMATION',
    IDENTIFICATION_ADD = 'IDENTIFICATION_ADD',
    IDENTIFICATION_REMOVE = 'IDENTIFICATION_REMOVE',
    IDENTIFICATION_NAME = 'IDENTIFICATION_NAME',
    IDENTIFICATION_VALUE = 'IDENTIFICATION_VALUE',
    SELLER_ADDRESS_INFORMATION_ADD = 'SELLER_ADDRESS_INFORMATION_ADD',
    SELLER_ADDRESS_INFORMATION_REMOVE = 'SELLER_ADDRESS_INFORMATION_REMOVE',
}

export interface IVendorStringActions {
    type: VendorDetailActions.VENDOR_CODE | VendorDetailActions.VENDOR_NOTES;
    value: string;
}

export interface IVendorStringArrayActions {
    type: VendorDetailActions.VENDOR_TAGS;
    value: string[];
}

export interface IVendorSecondaryContactAddAction {
    type: VendorDetailActions.USER_SECONDARY_CONTACT_ADD;
    value: string;
}

export interface IVendorContactAddAction {
    type: VendorDetailActions.USER_CONTACT_ADD;
    // value: string; // this is uid so string
}

export interface IVendorContactRemoveAction {
    type: VendorDetailActions.USER_CONTACT_REMOVE;
    uidIndex: string;
}

export interface IVendorContactUpdateAction {
    type: VendorDetailActions.USER_CONTACT_UPDATE;
    field:
        | VendorDetailActions.USER_EMAIL
        | VendorDetailActions.USER_NAME
        | VendorDetailActions.USER_NUMBER
        | VendorDetailActions.USER_NOTES
        | VendorDetailActions.USER_INTERNAL_NAME
        | VendorDetailActions.USER_TOGGLE_CC_EMAIL
        | VendorDetailActions.USER_TOGGLE_SECONDARY_EMAIL;
    uidIndex: string;
    value: string | boolean;
}

export interface IVendorContactUpdateDefaultAction {
    type: VendorDetailActions.USER_CONTACT_UPDATE;
    field: VendorDetailActions.USER_DEFAULT;
    uidIndex: string;
}

export interface IVendorContactUpdateInviteAction {
    type: VendorDetailActions.USER_CONTACT_UPDATE;
    field: VendorDetailActions.USER_INVITE_DEFAULT;
    uidIndex: string;
}

export interface IVendorEntityActions {
    type: VendorDetailActions.ENTITY_ADD | VendorDetailActions.ENTITY_REMOVE;
    value: string;
}

interface IVendorAddressAction {
    type: VendorDetailActions.SELLER_ADDRESS_INFORMATION;
    value: string;
    index: number;
}

interface IVendorAddressAddAction {
    type: VendorDetailActions.SELLER_ADDRESS_INFORMATION_ADD;
}

interface IVendorAddressRemoveAction {
    type: VendorDetailActions.SELLER_ADDRESS_INFORMATION_REMOVE;
    index: number;
}

interface IVendorIdentificationAddAction {
    type: VendorDetailActions.IDENTIFICATION_ADD;
}

interface IVendorIdentificationRemoveAction {
    type: VendorDetailActions.IDENTIFICATION_REMOVE;
    index: number;
}

interface IVendorIdentificationNameAction {
    type: VendorDetailActions.IDENTIFICATION_NAME;
    index: number;
    value: string;
}

interface IVendorIdentificationValueAction {
    type: VendorDetailActions.IDENTIFICATION_VALUE;
    index: number;
    value: string;
}

export type VendorActions =
    | IVendorStringActions
    | IVendorStringArrayActions
    | IVendorSecondaryContactAddAction
    | IVendorContactRemoveAction
    | IVendorContactUpdateAction
    | IVendorEntityActions
    | IVendorContactUpdateDefaultAction
    | IVendorContactUpdateInviteAction
    | IVendorContactAddAction
    | IVendorAddressAction
    | IVendorIdentificationAddAction
    | IVendorIdentificationRemoveAction
    | IVendorIdentificationNameAction
    | IVendorIdentificationValueAction
    | IVendorAddressAddAction
    | IVendorAddressRemoveAction;

export interface IVendorInfoErrors {
    [key: string]: {
        error: boolean;
        message: string;
        indexes?: number[];
    };
}

export interface IVendorAddProviders {
    hookState: IHookState;
    allVendors: IVendorAddDetails[];
    vendorDetails: IVendorAddDetails | null;
    setVendorDetails: Dispatch<SetStateAction<IVendorAddDetails | null>>;
    isValidDetails: boolean;
    setAllVendors: Dispatch<SetStateAction<IVendorSearchedData[]>>;
    vendorInfoErrors: IVendorInfoErrors;
    updateVendorDetails: (action: VendorActions) => void;
    searchForVendors: (query: string) => Promise<boolean>;
    validateInfo: (event: any, index?: number) => void;
    addVendorToDirectory: () => Promise<{
        enterprise_vendor_master_id: string;
        vendor_code: string;
    }>;
    resetAllVendors: () => void;
    globalVendorTags: string[];
}

export const initialVendorDetails: IVendorAddDetails = {
    vendorUid: '',
    vendorName: '',
    vendorCode: '',
    notes: '',
    tags: [],
    description: '',
    isCustom: false,
    website: '',
    email: '',
    telephone: '',
    vendorEnterpriseUid: '',
    vendorStatus: VendorStatus.ACTIVE,
    vendorVerificationStatus: VendorVerificationStatus.INVITATION_HOLD,
    primaryContacts: [
        {
            parent: null,
            uid: uniqueId(),
            default: true,
            email: '',
            fullName: '',
            contactNumber: '',
            notes: '',
            isPresentInFW: false,
            selected: false,
            internalName: '',
            isCCEmail: false,
            isSecondaryEmail: false,
        },
    ],
    entities: [],
    is_virtual: true,
    verification_status: VerificationStatus.UNVERIFIED,
    is_new_invitation_row: false,
    vendor_internal_name: null,
    seller_address_information: [''],
    seller_identifications: [
        {
            identification_name: '',
            identification_value: '',
        },
    ],
    seller_contacts: [],
    custom_sections: [],
};

export const useAddVendor = (
    initVendorDetails?: IVendorAddDetails | null,
    popupToggle: boolean = true
) => {
    const { hookState, updateHookState } = useHookState(HookStateValue.INITIAL);

    const [globalVendorTags, setGlobalVendorTags] = useState<string[]>([]);
    const [allVendors, setAllVendors] = useState<IVendorSearchedData[]>([]);

    const [vendorDetails, setVendorDetails] =
        useState<IVendorAddDetails | null>(null);

    const [isValidDetails, setIsValidDetails] = useState<boolean>(true);

    const [vendorInfoErrors, setVendorInfoErrors] = useState<IVendorInfoErrors>(
        {}
    );

    const { templateDetails } = useVendorTemplateHook();

    useEffect(() => {
        if (
            initVendorDetails &&
            (!initVendorDetails?.seller_identifications ||
                initVendorDetails?.seller_identifications.length === 0)
        ) {
            initVendorDetails.seller_identifications = [
                {
                    identification_name: '',
                    identification_value: '',
                },
            ];
        }
        if (
            initVendorDetails &&
            (!initVendorDetails?.seller_address_information ||
                initVendorDetails?.seller_address_information.length === 0)
        ) {
            initVendorDetails.seller_address_information = [''];
        }
        setVendorDetails(initVendorDetails ?? null);
        if (popupToggle) {
            getGlobalVendorTags().then((allVendorTags) => {
                setGlobalVendorTags(allVendorTags);
            });
        }
    }, [initVendorDetails, popupToggle]);

    const searchForVendors = useCallback(
        async (query: string): Promise<boolean> => {
            setVendorInfoErrors({});
            if (query && query.length >= 3) {
                updateHookState(HookStateValue.LOADING);
                try {
                    const vendors: IVendorSearchedData[] =
                        await runSearchAllVendorsInFactwise(query);
                    setAllVendors(vendors);
                    updateHookState(HookStateValue.READY);
                    return true;
                } catch (error) {
                    updateHookState(HookStateValue.ERROR);
                    return false;
                }
            } else {
                setAllVendors([]);
                updateHookState(HookStateValue.INITIAL);
                return false;
            }
        },
        [updateHookState]
    );

    const updateVendorDetails = useCallback((action: VendorActions) => {
        setVendorDetails((prev) => {
            if (!prev) return null;
            let prevDetails = cloneDeep(prev);
            switch (action.type) {
                case VendorDetailActions.VENDOR_CODE:
                    prevDetails.vendorCode = action.value;
                    break;
                case VendorDetailActions.VENDOR_NOTES:
                    prevDetails.notes = action.value;
                    break;
                case VendorDetailActions.VENDOR_TAGS:
                    prevDetails.tags = action.value;
                    break;
                case VendorDetailActions.USER_CONTACT_UPDATE:
                    const primaryContactUpdateIndex =
                        prevDetails.primaryContacts.findIndex(
                            (c) => c.uid === action.uidIndex
                        );
                    const contact =
                        prevDetails.primaryContacts[primaryContactUpdateIndex];
                    switch (action.field) {
                        case VendorDetailActions.USER_EMAIL:
                            contact.email = action.value as string;
                            break;
                        case VendorDetailActions.USER_NAME:
                            prevDetails.primaryContacts
                                .filter((c) => c.parent === contact.uid)
                                .forEach((contact) => {
                                    contact.fullName = action.value as string;
                                });
                            contact.fullName = action.value as string;
                            break;
                        case VendorDetailActions.USER_INTERNAL_NAME:
                            contact.internalName = action.value as string;
                            break;
                        case VendorDetailActions.USER_NUMBER:
                            contact.contactNumber = action.value as string;
                            break;
                        case VendorDetailActions.USER_NOTES:
                            contact.notes = action.value as string;
                            break;
                        case VendorDetailActions.USER_DEFAULT:
                            prevDetails.primaryContacts.forEach((contact) => {
                                contact.default = false;
                            });
                            contact.default = true;
                            break;
                        case VendorDetailActions.USER_TOGGLE_CC_EMAIL:
                            contact.isCCEmail = action.value as boolean;
                            break;
                        case VendorDetailActions.USER_TOGGLE_SECONDARY_EMAIL:
                            contact.isSecondaryEmail = action.value as boolean;
                            break;
                        case VendorDetailActions.USER_INVITE_DEFAULT:
                            contact.selected = !contact.selected;
                            if (!contact.selected) {
                                prevDetails.primaryContacts.forEach((c) => {
                                    c.default = false;
                                });
                                const nextContact =
                                    prevDetails.primaryContacts[1];
                                if (nextContact) {
                                    nextContact.default = true;
                                }
                            }
                            break;
                    }
                    break;
                case VendorDetailActions.USER_SECONDARY_CONTACT_ADD:
                    prevDetails.primaryContacts.push({
                        parent: action.value,
                        uid: uniqueId(),
                        default: false,
                        email: '',
                        fullName: prevDetails.primaryContacts.find(
                            (c) => c.default
                        )!.fullName,
                        contactNumber: '',
                        notes: '',
                        isPresentInFW: false,
                        selected: false,
                        internalName: '',
                        isCCEmail: false,
                        isSecondaryEmail: true,
                    });
                    break;
                case VendorDetailActions.USER_CONTACT_ADD:
                    prevDetails.primaryContacts.push({
                        parent: null,
                        uid: uniqueId(),
                        default: prevDetails.primaryContacts.find(
                            (contact) => contact.default
                        )
                            ? false
                            : true,
                        email: '',
                        fullName: '',
                        contactNumber: '',
                        notes: '',
                        isPresentInFW: false,
                        selected: false,
                        internalName: '',
                        isCCEmail: false,
                        isSecondaryEmail: false,
                    });
                    break;
                case VendorDetailActions.USER_CONTACT_REMOVE:
                    const primaryContactRemoveIndex =
                        prevDetails.primaryContacts.findIndex(
                            (c) => c.uid === action.uidIndex
                        );
                    prevDetails.primaryContacts.splice(
                        primaryContactRemoveIndex,
                        1
                    );
                    break;
                case VendorDetailActions.ENTITY_ADD:
                    prevDetails.entities.push(action.value);
                    break;
                case VendorDetailActions.ENTITY_REMOVE:
                    const index = prevDetails.entities.findIndex(
                        (e) => e === action.value
                    );
                    prevDetails.entities.splice(index, 1);
                    break;
                case VendorDetailActions.SELLER_ADDRESS_INFORMATION:
                    if (prevDetails.seller_address_information) {
                        prevDetails.seller_address_information[action.index] =
                            action.value as string;
                    }
                    break;
                case VendorDetailActions.SELLER_ADDRESS_INFORMATION_ADD:
                    prevDetails.seller_address_information &&
                        prevDetails.seller_address_information.push('');
                    break;
                case VendorDetailActions.SELLER_ADDRESS_INFORMATION_REMOVE:
                    prevDetails.seller_address_information &&
                        prevDetails.seller_address_information.splice(
                            action.index,
                            1
                        );
                    break;
                case VendorDetailActions.IDENTIFICATION_ADD:
                    prevDetails.seller_identifications &&
                        prevDetails.seller_identifications.push({
                            identification_name: '',
                            identification_value: '',
                        });
                    break;
                case VendorDetailActions.IDENTIFICATION_REMOVE:
                    prevDetails.seller_identifications &&
                        prevDetails.seller_identifications.splice(
                            action.index,
                            1
                        );
                    break;
                case VendorDetailActions.IDENTIFICATION_NAME:
                    if (prevDetails.seller_identifications) {
                        if (prevDetails.seller_identifications[action.index]) {
                            prevDetails.seller_identifications[
                                action.index
                            ].identification_name = action.value as string;
                        } else {
                            prevDetails.seller_identifications[action.index] = {
                                identification_name: action.value as string,
                                identification_value: '',
                            };
                        }
                    }
                    break;
                case VendorDetailActions.IDENTIFICATION_VALUE:
                    if (prevDetails.seller_identifications) {
                        if (
                            prevDetails.seller_identifications &&
                            prevDetails.seller_identifications[action.index]
                        ) {
                            prevDetails.seller_identifications[
                                action.index
                            ].identification_value = action.value as string;
                        } else {
                            prevDetails.seller_identifications[action.index] = {
                                identification_name: '',
                                identification_value: action.value as string,
                            };
                        }
                    }
                    break;
            }

            return { ...prevDetails };
        });
    }, []);

    const validateInfo = (event: any, index?: number) => {
        event.preventDefault();
        const infoKey: string = event.target.name;
        if (
            isEmpty(event.target.value.trim()) &&
            event.target.hasAttribute('required')
        ) {
            setVendorInfoErrors((prevState) => {
                prevState[infoKey] = {
                    error: true,
                    message: 'Field is required',
                };
                return { ...prevState };
            });
        } else {
            setVendorInfoErrors((prevState) => {
                prevState[infoKey] = {
                    error: false,
                    message: '',
                };

                let uid = event.target.name;
                uid = uid.replace(VendorDetailActions.USER_EMAIL, '');

                if (infoKey.includes(VendorDetailActions.USER_EMAIL)) {
                    prevState[infoKey] = {
                        error: isValidEmail(event.target.value) ? false : true,
                        message: isValidEmail(event.target.value)
                            ? ''
                            : 'Please enter a valid email address',
                    };
                }
                if (
                    !prevState[infoKey].error &&
                    vendorDetails?.primaryContacts
                        .filter((c) => c.uid !== uid)
                        .map((c) => c.email)
                        .includes(event.target.value)
                ) {
                    prevState[infoKey] = {
                        error: vendorDetails?.primaryContacts
                            .filter((c) => c.uid !== uid)
                            .map((c) => c.email)
                            .includes(event.target.value),
                        message: 'Duplicate email',
                    };
                }
                if (infoKey.includes(VendorDetailActions.USER_NUMBER)) {
                    prevState[infoKey] = {
                        error:
                            event.target.value.length > 7 &&
                            event.target.value.length <= 10
                                ? false
                                : true,
                        message:
                            event.target.value.length > 7 &&
                            event.target.value.length <= 10
                                ? ''
                                : 'Please enter a valid phone number',
                    };
                }
                // if (infoKey.includes(VendorDetailActions.USER_CONTACT_UPDATE)) {
                //     vendorDetails?.primaryContact.contactNumber.forEach(
                //         (contact) => {
                //             if (
                //                 (contact.length < 8 || contact.length > 20) &&
                //                 !isEmpty(contact)
                //             ) {
                //                 prevState[infoKey] = true;
                //             }
                //         }
                //     );
                // }

                // if (infoKey === VendorDetailActions.IDENTIFICATION_NAME) {
                //     if (
                //         event.target.value &&
                //         !vendorDetails?.seller_identifications?.[index!]
                //             ?.identification_value
                //     ) {
                //         prevState[VendorDetailActions.IDENTIFICATION_VALUE] = {
                //             error: true,
                //             message: 'Identification value cannot be empty',
                //             indexes: prevState[
                //                 VendorDetailActions.IDENTIFICATION_VALUE
                //             ]?.indexes
                //                 ? prevState[
                //                       VendorDetailActions.IDENTIFICATION_VALUE
                //                   ]?.indexes?.concat(index!)
                //                 : [index!],
                //         };
                //     } else {
                //         prevState[VendorDetailActions.IDENTIFICATION_VALUE] = {
                //             error: false,
                //             message: '',
                //             indexes: prevState[
                //                 VendorDetailActions.IDENTIFICATION_VALUE
                //             ].indexes?.filter((i) => i !== index!),
                //         };
                //     }

                //     if (
                //         !event.target.value &&
                //         !vendorDetails?.seller_identifications?.[index!]
                //             ?.identification_value
                //     ) {
                //         prevState[VendorDetailActions.IDENTIFICATION_NAME] = {
                //             error: false,
                //             message: '',
                //         };
                //     }
                // }

                // if (infoKey === VendorDetailActions.IDENTIFICATION_VALUE) {
                //     if (
                //         event.target.value &&
                //         !vendorDetails?.seller_identifications?.[index!]
                //             ?.identification_name
                //     ) {
                //         prevState[VendorDetailActions.IDENTIFICATION_NAME] = {
                //             error: true,
                //             message: 'Identification name cannot be empty',
                //             indexes: prevState[
                //                 VendorDetailActions.IDENTIFICATION_NAME
                //             ]?.indexes
                //                 ? prevState[
                //                       VendorDetailActions.IDENTIFICATION_NAME
                //                   ]?.indexes?.concat(index!)
                //                 : [index!],
                //         };
                //     } else {
                //         prevState[VendorDetailActions.IDENTIFICATION_NAME] = {
                //             error: false,
                //             message: '',
                //             indexes: prevState[
                //                 VendorDetailActions.IDENTIFICATION_NAME
                //             ].indexes?.filter((i) => i !== index!),
                //         };
                //     }

                //     if (
                //         !event.target.value &&
                //         !vendorDetails?.seller_identifications?.[index!]
                //             ?.identification_name
                //     ) {
                //         prevState[VendorDetailActions.IDENTIFICATION_VALUE] = {
                //             error: false,
                //             message: '',
                //         };
                //     }
                // }

                return { ...prevState };
            });
        }
    };

    const addVendorToDirectory = async (): Promise<{
        enterprise_vendor_master_id: string;
        vendor_code: string;
    }> => {
        return new Promise(async (resolve, reject) => {
            if (vendorDetails) {
                if (vendorDetails.isCustom) {
                    const vendorAdded = await addCustomVendorToVendorDirectory({
                        ...vendorDetails,
                        seller_address_information:
                            vendorDetails?.seller_address_information?.filter(
                                (address) => !isEmpty(address)
                            ) || null,
                        custom_sections: getCustomSections(templateDetails),
                    });
                    resolve(vendorAdded);
                } else {
                    const vendorAdded = await addVendorToVendorDirectory({
                        ...vendorDetails,
                        seller_address_information:
                            vendorDetails?.seller_address_information?.filter(
                                (address) => !isEmpty(address)
                            ) || null,
                        custom_sections: getCustomSections(templateDetails),
                    });
                    resolve(vendorAdded);
                }
            }
            reject();
        });
    };

    const getCustomSections = (templateDetails: IVendorTemplateFrontEnd) => {
        let customSection: ICustomSection = {
            name: templateDetails.sections.VENDOR_DETAILS.label,
            section_type: 'OTHER',
            custom_fields: templateDetails.sections.VENDOR_DETAILS.fieldList
                .filter((field) => field.fieldType === 'CUSTOM')
                .map((currField) => {
                    let customField =
                        templateDetails.fieldSettings.customFields[
                            currField.field
                        ];
                    return {
                        name: customField.label,
                        type: customField.type,
                        value: customField.type === 'MULTI_CHOICE' ? [] : null,
                        //FOR NOW,WHOEVER WORKS SET IT CORRECTLY WHEN ON THE FLY IS DONE
                        is_locked: true,
                        is_visible: false,
                        description: customField.description,
                        is_required: customField.is_required,
                        is_negotiable: false,
                    };
                }),
        };
        return [customSection];
    };

    useEffect(() => {
        if (!isNull(vendorDetails)) {
            let errors = false;
            if (isEmpty(vendorDetails.vendorCode.trim())) errors = true;

            for (const contact of vendorDetails.primaryContacts) {
                if (isEmpty(contact.fullName.trim())) {
                    errors = true;
                    //

                    break;
                }
                if (
                    isEmpty(contact.email.trim()) ||
                    !isValidEmail(contact.email.trim())
                ) {
                    errors = true;
                    break;
                }
                if (
                    contact.parent !== null &&
                    contact.isSecondaryEmail === false &&
                    contact.isCCEmail === false
                ) {
                    errors = true;
                    break;
                }
                if (
                    contact.contactNumber.length > 0 &&
                    (contact.contactNumber.length < 8 ||
                        contact.contactNumber.length > 10)
                ) {
                    errors = true;
                    break;
                }
            }

            const setOfEmails = new Set(
                vendorDetails.primaryContacts.map((c) => c.email)
            );

            if (
                Array.from(setOfEmails).length <
                vendorDetails.primaryContacts.length
            ) {
                errors = true;
            } else {
                setVendorInfoErrors((prev) => {
                    const keys = Object.keys(prev);
                    for (let key of keys) {
                        if (
                            key.includes(VendorDetailActions.USER_EMAIL) &&
                            prev[key].message.includes('Duplicate email')
                        ) {
                            delete prev[key];
                        }
                    }
                    return prev;
                });
            }

            setIsValidDetails(!errors);
        }
    }, [vendorDetails]);

    // useEffect(() => {
    //     vendorDetails?.seller_identifications?.forEach(
    //         (identification, index) => {
    //             if (
    //                 (identification.identification_name &&
    //                     !identification.identification_value) ||
    //                 (!identification.identification_name &&
    //                     identification.identification_value)
    //             ) {
    //                 setIsValidDetails(false);
    //             }
    //         }
    //     );
    //     if (
    //         !(
    //             vendorDetails?.seller_identifications?.[0]
    //                 .identification_name &&
    //             vendorDetails?.seller_identifications?.[0].identification_value
    //         )
    //     ) {
    //         setIsValidDetails(false);
    //     }
    // }, [vendorDetails?.seller_identifications]);

    // useEffect(() => {
    //     if (isValidDetails === false) {
    //         for (let key of Object.keys(vendorInfoErrors)) {
    //             if (vendorInfoErrors[key].error) {
    //                 setIsValidDetails(false);
    //                 break;
    //             }
    //         }
    //     }
    // }, [isValidDetails, vendorInfoErrors]);

    const resetAllVendors = () => {
        setAllVendors([]);
    };

    const vendorAddProviders: IVendorAddProviders = {
        globalVendorTags,
        hookState,
        allVendors,
        vendorDetails,
        setVendorDetails,
        updateVendorDetails,
        isValidDetails,
        searchForVendors,
        setAllVendors,
        validateInfo,
        vendorInfoErrors,
        addVendorToDirectory,
        resetAllVendors,
    };

    return vendorAddProviders;
};

export interface IEntityVendorLinkProviders {
    hookState: IHookState;
    selectedVendor: IVendorListSummary | null;
    preferredItems: IPreferredItem[];
    setSelectedVendor: Dispatch<SetStateAction<IVendorListSummary | null>>;
    setPreferredItems: Dispatch<SetStateAction<IPreferredItem[]>>;
    linkVendor: () => Promise<boolean>;
}

export const useEntityVendorLink = (entityUid: string) => {
    const { hookState } = useHookState(HookStateValue.INITIAL);

    const [selectedVendor, setSelectedVendor] =
        useState<IVendorListSummary | null>(null);

    const [preferredItems, setPreferredItems] = useState<IPreferredItem[]>([]);

    const linkVendor = async (): Promise<boolean> => {
        try {
            if (selectedVendor) {
                const updatedItems: IPayloadItem[] = preferredItems.map(
                    (item) => ({
                        enterprise_item: item.itemUid,
                        status: PreferredItemStatus.PREFERRED,
                    })
                );
                const payload: IVendorItemUpdatePayload = {
                    buyer_entity: entityUid,
                    status: 'STANDARD',
                    items: updatedItems,
                };
                const itemAdded = await updateVendorItems(
                    selectedVendor.vendorDirUid,
                    payload
                );
                return itemAdded;
            }
            return false;
        } catch (error) {
            return false;
        }
    };

    const entityVendorLinkProviders: IEntityVendorLinkProviders = {
        hookState,
        linkVendor,
        selectedVendor,
        setSelectedVendor,
        setPreferredItems,
        preferredItems,
    };

    return entityVendorLinkProviders;
};
