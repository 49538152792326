import { baseApiSlice } from './BaseApiSlice';

export type fetchTabCountType =
    | 'invoice_buyer'
    | 'purchase_order_delivery_pending'
    | 'goods_receipt'
    | 'invoice_seller'
    | 'purchase_order_seller'
    | 'invoice_buyer'
    | 'purchase_order_buyer'
    | 'po_group'
    | 'purchase_order_seller'
    | 'quality_check_pending'
    | 'quality_check_completed'
    | 'rfq_event_buyer'
    | 'rfq_event_seller'
    | 'payment_completed'
    | 'payment_pending_item_grouped'
    | 'payment_pending_invoice'
    | 'payment_pending_item'
    | 'contract_buyer'
    | 'quote_calculator_customer'
    | 'requisitions_buyer'
    | 'order'
    | 'requisition'
    | 'inbound_requisition'
    | 'inbound_order'
    | 'fields_list'
    | 'additional_cost';

export type TabCountData = {
    tab_counts: {
        drafts?: number;
        unverified?: number;
        verified?: number;
        all?: number;
        gr_pending?: number;
        draft?: number;
        ongoing?: number;
        pending?: number;
        finished?: number;
        received?: number;
        invitation?: number;
        completed?: number;
        fw?: number;
        custom?: number;
    };
};

const tabCountApiSlice = baseApiSlice.injectEndpoints({
    endpoints: (builder) => ({
        FetchTabCount: builder.mutation<TabCountData, fetchTabCountType>({
            query: (dashboard) => ({
                url: `/dashboard/tab_counts/`,
                method: 'POST',
                body: {
                    dashboard_view: dashboard,
                },
            }),
        }),
    }),
});

export const { useFetchTabCountMutation } = tabCountApiSlice;
