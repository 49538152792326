import { IForumlaWithIfElseAndOperators } from '../../AdditionalCost/Interfaces/AdditionalCostInterfaces';
import { IUserPermissionFEStructure } from '../../BuyerTemplates/Component/ShareSectionPopup';
import { templateType } from '../../BuyerTemplates/Interfaces/TemplatePageInterfaces';
import { IProjectFieldTypeContraints } from '../../FieldTypes/FieldTypes.model';
import { CustomFieldType } from '../../Global/Interfaces/TemplateInterface';
import { ICurrencyDetails } from '../../Models/Currency.model';
import { IAttachment } from '../../Models/RFQ.model';

import { TConditionsValue } from '../constants/projectDashboardConstants';
import { IProjectField } from '../helpers/projectDashboardHelper';
// import { CustomFieldType } from '../../SRM/Constants/Interfaces/documentTemplate';

export interface IprojectDashboardContext {
    updateStatusOfProject: () => void;
    canUserEditOrDeleteProject: boolean;
    fetchList: any;
    allCurrencies: ICurrencyDetails[];
    currentUserId: string;
    handleAssignableProjectIdChange: (
        projectId: string,
        projectName: string,
        projectCreationDate: string,
        buyerEntityId: string,
        isUserShortlistedAsPM: boolean
    ) => void;
    canUserAssignPMFromAdmin: boolean;
    canUserAssignUserFromAdmin: boolean;
    fetchProjectTabCount: any;
    currentTab: string;
    MAXIMUM_COLUMNS_ON_DASHBOARD: number;
    listOfAllFieldsAcrossTemplate: string[];
    fieldsInAllTemplate: {
        [templateName: string]: string[];
    };
    fieldSettingsInAllTemplate: {
        [templateName: string]: {
            [fieldName: string]: IProjectField;
        };
    };
    multiSelectDropDown: { [key: string]: string[] };
    projectManagerList: IUserPermissionFEStructure[];
}

export const DASHBOARD_FIELD_NAME = 'fieldNameForBE';
export const DASHBOARD_FIELD_COLUMN = 'fieldColumnForBE';
export const DASHBOARD_SECTION_NAME = 'sectionNameForBE';
export const DASHBOARD_FIELD_TYPE = 'fieldTypeForBE';
export const DASHBOARD_FIELD = 'fieldForBE';
export const TEMPLATE_FIELD_TYPE = 'isFieldBuiltIn';

export type TFilterFieldType = 'CUSTOM_FIELD' | 'BUILTIN' | null;
export type TFilterType =
    | 'ATTACHMENT'
    | 'LONGTEXT'
    | 'TEXT'
    | 'SHORTTEXT'
    | 'BOOLEAN'
    | 'DATETIME'
    | 'DATE'
    | 'CHOICE'
    | 'MULTI_CHOICE'
    | 'FLOAT';

export interface FilterCondition {
    condition_type: TConditionsValue;
    datetime_from: string | null;
    datetime_to: string | null;
    field: string;
    field_type: TFilterFieldType;
    section: string | null;
    type: CustomFieldType;
    value: string | null;
    value_from: string | null;
    value_to: string | null;
    values: string[];
}

export interface DashBoardFilter {
    and_: DashBoardFilter[];
    or_: DashBoardFilter[];
    single: FilterCondition | null;
}

export interface DashboardSort {
    ascending: boolean;
    type: CustomFieldType;
    field: string;
    field_type: TFilterFieldType;
}

export interface DashbordSortOptions {
    label: string;
    ascending: boolean;
}

// export interface DashboardSort {
//     field: string;
//     ascending: boolean;
//     type: string | null;
//     field_type: string;
// }

export interface IUserPermissionForSection {
    user_id: string;
    permission: string;
}

export interface IAssignUserForTemplateAndPerSection {
    TEMPLATE_LEVEL: IUserPermissionForSection[];
    PROJECT_DETAILS: IUserPermissionForSection[];
    [sectionName: string]: IUserPermissionForSection[];
}

export interface IAssignUserAndPM {
    PROJECT_DETAILS: IUserPermissionFEStructure[];
    [sectionName: string]: IUserPermissionFEStructure[];
}

export interface IMultipleProjectTemplate {
    template_id: string;
    type: string;
    name: string;
    tags: string[];
    status: string;
    published_on: any;
    description: string;
    is_global: boolean;
    is_default: boolean;
    created_on: string;
    creator_name: string;
    last_edited_on: string;
    entity_id: string;
    entity_name: string;
    auto_reminders: any;
    last_edited_by_name: string;
    deleted_datetime: string | null;
    assigned_users: { user_id: string; permission: string }[];
    section_list: {
        section_id: string;
        name: string;
        alternate_name: string;
        assigned_user: { user_id: string; permission: string }[];
        section_items: {
            name: string;
            alternate_name: string;
            is_builtin_field: boolean;
            type: CustomFieldType;
            constraints: ProjectCustomFieldSectionFieldConstraint;
        }[];
    }[];
}
export interface IMultipleProjectTemplateApiResponse {
    type: templateType;
    count: number;
    templates: IMultipleProjectTemplate[];
}

export interface ProjectApiResponse {
    project_id: string;
    cost_centre_count: number;
    buyer_entity_name: string;
    attachments: IAttachment[];
    additional_details: {
        template_id: string;
    };
    customer_entity: string | null;
    customer_entity_name: string | null;
    custom_sections: IProjectCustomSection[];
    created_datetime: string;
    modified_datetime: string;
    deleted_datetime: any;
    project_code: string;
    project_name: string;
    validity_from: string;
    validity_to: string;
    location: any;
    description: any;
    internal_notes: any;
    tags: Array<any>;
    status: string;
    project_status: string;
    custom_fields: IProjectCustomField;
    created_by_user: {
        user_id: string;
        name: string;
    };
    modified_by_user: {
        user_id: string;
        name: string;
    };
    deleted_by_user: any;
    buyer_enterprise: string;
    buyer_entity: string;
    project_managers: { user_id: string; name: string }[];
    cost_centres: Array<{
        project_cost_centre_linkage_id: string;
        cost_centre: {
            cost_centre_entry_id: string;
            created_datetime: string;
            modified_datetime: string;
            deleted_datetime: any;
            cost_centre_id: string;
            cost_centre_name: string;
            description: string;
            notes: string;
            validity_from: any;
            validity_to: any;
            status: string;
            custom_fields: {
                section_list: Array<any>;
            };
            created_by_user: string;
            modified_by_user: string;
            deleted_by_user: any;
            enterprise: string;
        };
        created_datetime: string;
        modified_datetime: string;
        deleted_datetime: any;
        created_by_user: string;
        modified_by_user: string;
        deleted_by_user: any;
        project: string;
    }>;
}
export default interface IProject {
    projectUUid: string;
    projectManagers: { user_id: string; name: string }[];
    templateId: string;
    projectCode: string;
    attachments: IAttachment[];
    projectName: string;
    internalNotes: string;
    customerEntity: { entity_id: string; entity_name: string } | null;
    description: string;
    createdByUsername: string;
    createdByUserId: string;
    projectCustomSections: IProjectCustomSection[];
    modifiedByUsername: string;
    status: ProjectStatusEnum;
    linkedCostCenterCount: number;
    projectStatus: ProjectStatusEnum;
    createdOn: string;
    entityName: string;
    entityUid: string;
    listCostCenterNames: string[];
    validityFrom: string;
    validityTo: string;
    modifiedOn: string;
    submittedOn: string;
}

export interface IProjectBackendResponsePagiation {
    data: ProjectApiResponse[];
    metadata: {
        current_page: number;
        has_next: boolean;
        has_previous: boolean;
        total_pages: number;
        page_range: {
            start: number;
            stop: number;
        };
        count: number;
    };
    counts: {
        all: number;
    };
}
export interface IProjectBackendResponseEntityPagiation {
    data: ProjectApiResponse[];
    metadata: {
        current_page: number;
        has_next: boolean;
        has_previous: boolean;
        total_pages: number;
        page_range: {
            start: number;
            stop: number;
        };
        count: number;
    };
    counts: {
        all: number;
    };
}

export enum ProjectStatusEnum {
    DRAFT = 'DRAFT',
    ONGOING = 'ONGOING',
    ACTIVE = 'ACTIVE',
    INACTIVE = 'INACTIVE',
    ASSIGNING_USERS = 'ASSIGNING_USERS',
    EXPIRED = 'EXPIRED',
    REVISED = 'REVISED',
    SUBMITTED = 'SUBMITTED',
}

export enum ProjectSectionMode {
    VIEW = 'VIEW',
    EDIT = 'EDIT',
}

export interface IProjectSectionMode {
    PROJECT_DETAILS: ProjectSectionMode.EDIT | ProjectSectionMode.VIEW;
    [sectionName: string]: ProjectSectionMode.EDIT | ProjectSectionMode.VIEW;
}

export enum CreateProjectErrorFieldEnum {
    project_code = 'project_code',
    customer_entity_id = 'customer_entity_id',
    project_name = 'project_name',
    description = 'description',
    project_manager_ids = 'project_manager_ids',
    validity_from = 'validity_from',
    validity_to = 'validity_to',
    custom_fields = 'custom_fields',
    assigned_users = 'assigned_users',
}

export type createProjectDetailsActionType = keyof ICreateProjectPayload;

export interface ICreateProjectPayload {
    template_id: string;
    project_code: string;
    project_name: string | null;
    project_manager_ids: string[];
    buyer_entity_id: string;
}

export type IProjectErrors = {
    PROJECT_DETAILS: {
        [fieldName: string]: {
            error: string;
        };
    };
    [sectionName: string]: {
        [fieldName: string]: {
            error: string;
        };
    };
};

export interface IProjectPayload {
    project_code: string;
    project_name: string;
    buyer_entity_id: string;
    template_id: string;
    description: string;
    customer_entity_id: string;
    internal_notes: string;
    validity_from: string | null;
    validity_to: string | null;
    tags: Array<string>;
    project_type: string;
    attachment_ids: string[];
    attachments?: IAttachment[];
    cost_centre_ids: string[];
    project_manager_ids: string[];
    rfq_responsible_user_ids: string[];
    project_managers?: { user_id: string; name: string }[];
    status?: ProjectStatusEnum.ACTIVE | ProjectStatusEnum.INACTIVE;
    project_status: ProjectStatusEnum;
    custom_sections: IProjectCustomSection[];
}

export interface IUpdateProjectPermissionPayload {
    project_manager_ids: string[];
    rfq_responsible_user_ids: string[];
    custom_sections: {
        name: string;
        custom_section_id: string | null;
        assigned_user_ids: string[];
    }[];
}

export interface IProjectCustomSection {
    custom_section_id: string | null;
    name: string;
    status: string;
    assigned_users: string[];
    submission_time: string | null;
    created_datetime: string | null;
    start_time: string | null;
    last_modified_time: string | null;
    target_duration: string | null;
    target_duration_period: string | null;
    custom_fields: IProjectCustomField[];
}

// export interface IProjectCustomField {
//     section_list: IProjectCustomFieldSection[];
// }

export interface IProjectCustomFieldBaseStructure {
    custom_field_id: string | null;
    name: string;
    is_locked: boolean;
    is_required: boolean;
    is_visible: boolean;
}

export type IProjectCustomFieldTypeAndValue =
    | {
          type:
              | CustomFieldType.SHORTTEXT
              | CustomFieldType.LONGTEXT
              | CustomFieldType.CHOICE;
          text_value: string | null;
      }
    | {
          type: CustomFieldType.MULTI_CHOICE;
          multi_choice_value: string[] | null;
      }
    | {
          type: CustomFieldType.INTEGER;
          integer_value: string | null;
      }
    | {
          type: CustomFieldType.CURRENCY;
          currency: string | null;
          decimal_value: string | null;
      }
    | {
          type: CustomFieldType.BOOLEAN;
          boolean_value: boolean | null;
      }
    | {
          type: CustomFieldType.FLOAT;
          decimal_value: number | null;
      }
    | {
          type: CustomFieldType.ATTACHMENT | CustomFieldType.TEMPLATE;
          attachment_values: string[] | null;
      }
    | {
          type: CustomFieldType.DATETIME;
          datetime_value: string | null;
      }
    | {
          type: CustomFieldType.DATE;
          date_value: string | null;
      };

export type IProjectCustomField = IProjectCustomFieldBaseStructure &
    IProjectCustomFieldTypeAndValue;

export interface IProjectCustomFieldSection {
    name: string;
    fields: IProjectCustomFieldSectionField[];
}

export interface IProjectCustomFieldSectionWithType {
    name: string;
    fields: IProjectCustomFieldSectionFieldWIthType[];
}

export type IProjectCustomFieldSectionField = {
    name: string;
    type: string;
    value: string | boolean | string[] | null;
    is_locked: boolean; // true if coming from template
    description: string;
    is_required: boolean;
};

export type IProjectCustomFieldSectionFieldWIthType = {
    name: string;
    is_required: boolean;
    is_locked: boolean;
    is_visible: boolean;
    type: string;
    value: any;
};

export enum ProjectCustomFieldSectionFieldType {
    INTEGER = 'INTEGER',
    CURRENCY = 'CURRENCY',
    STRING = 'STRING',
    FLOAT = 'FLOAT',
    BOOLEAN = 'BOOLEAN',
    DATETIME = 'DATETIME',
    DATE = 'DATE',
    CHOICE = 'CHOICE',
    MULTICHOICE = 'MULTICHOICE',
    SHORTTEXT = 'SHORTTEXT',
    LONGTEXT = 'LONGTEXT',
    DROPDOWN = 'DROPDOWN',
    ATTACHMENT = 'ATTACHMENT',
    LINK = 'LINK',
}

export type ProjectCustomFieldSectionFieldConstraint =
    | {
          field_type:
              | ProjectCustomFieldSectionFieldType.INTEGER
              | ProjectCustomFieldSectionFieldType.LONGTEXT
              | ProjectCustomFieldSectionFieldType.SHORTTEXT
              | ProjectCustomFieldSectionFieldType.STRING;
          min_limit: number;
          max_limit: number;
      }
    | {
          field_type: ProjectCustomFieldSectionFieldType.FLOAT;
          min_limit: number;
          max_limit: number;
          decimal_places: number;
      }
    | {
          field_type: ProjectCustomFieldSectionFieldType.BOOLEAN;
      }
    | {
          field_type: ProjectCustomFieldSectionFieldType.CURRENCY;
      }
    | {
          field_type: ProjectCustomFieldSectionFieldType.CHOICE;
          choices: string[];
          choice_type: 'DROPDOWN';
      }
    | {
          field_type: ProjectCustomFieldSectionFieldType.MULTICHOICE;
          choices: string[];
          choice_type: 'MULTI_SELECT';
      }
    | {
          field_type: ProjectCustomFieldSectionFieldType.DATETIME;
      }
    | {
          field_type: ProjectCustomFieldSectionFieldType.DATE;
      };

export type ProjectCustomFieldSectionFieldConstraintTypeWithValue =
    | {
          constraints: {
              field_type: 'STRING' | 'SHORTTEXT' | 'LONGTEXT';
              min_limit: number;
              max_limit: number;
          };
          value: string;
      }
    | {
          constraints: {
              field_type: ProjectCustomFieldSectionFieldType.INTEGER;
              min_limit: number;
              max_limit: number;
          };
          value: string;
      }
    | {
          constraints: {
              field_type: ProjectCustomFieldSectionFieldType.CURRENCY;
          };
          value: string;
      }
    | {
          constraints: {
              field_type: ProjectCustomFieldSectionFieldType.FLOAT;
              min_limit: number;
              max_limit: number;
              decimal_places: number;
          };
          value: string;
      }
    | {
          constraints: {
              field_type: ProjectCustomFieldSectionFieldType.BOOLEAN;
          };
          value: boolean;
      }
    | {
          constraints: {
              field_type: ProjectCustomFieldSectionFieldType.DATETIME;
          };
          value: string | null;
      }
    | {
          constraints: {
              field_type: ProjectCustomFieldSectionFieldType.DATE;
          };
          value: string | null;
      }
    | {
          constraints: {
              field_type: ProjectCustomFieldSectionFieldType.MULTICHOICE;
              choices: string[];
              choice_type: 'MULTI_SELECT';
          };
          value: string[];
      }
    | {
          constraints: {
              field_type: ProjectCustomFieldSectionFieldType.CHOICE;
              choices: string[];
              choice_type: 'DROPDOWN';
          };
          value: string | null;
      };

export const projectCustomFieldTypeMappedTOsingleCustomFIeld = {
    TEXT: ProjectCustomFieldSectionFieldType.STRING,
    STRING: ProjectCustomFieldSectionFieldType.STRING,
    LONGTEXT: ProjectCustomFieldSectionFieldType.LONGTEXT,
    SHORTEXT: ProjectCustomFieldSectionFieldType.SHORTTEXT,
    INTEGER: ProjectCustomFieldSectionFieldType.INTEGER,
    DECIMAL: ProjectCustomFieldSectionFieldType.FLOAT,
    DATE: ProjectCustomFieldSectionFieldType.DATE,
    CHOICE: ProjectCustomFieldSectionFieldType.CHOICE,
    BOOLEAN: ProjectCustomFieldSectionFieldType.BOOLEAN,
};

export interface IProjectTemplateNewStructureResponse {
    template_id: string;
    section_list: Sectionlist[];
    entity: Entity;
    last_edited_by_name: string;
    created_datetime: string;
    modified_datetime: string;
    deleted_datetime: string | null;
    name: string;
    type: string;
    description: string;
    tags: string[];
    is_default: boolean;
    is_global: boolean;
    auto_reminders: Autoreminders;
    status: string;
    published_on: string | null;
    created_on: string;
    last_edited_on: string;
    created_by_user: string;
    modified_by_user: string;
    deleted_by_user: string | null;
    enterprise: string;
    creator: string;
    last_edited_by: string;
    assigned_users: {
        user: {
            user_id: string;
            name: string;
        };
        permission: string;
    }[];
}

interface Autoreminders {
    max_reminders: number;
    reminder_interval: number;
    is_remainder_required: boolean;
    initial_reminder_hours: number;
}
interface Entity {
    entity_name: string;
    entity_id: string;
}
interface Sectionlist {
    section_id: string;
    section_items: Sectionitem[];
    assigned_users: {
        user: {
            user_id: string;
            name: string;
        };
        permission: string;
    }[];
    created_datetime: string;
    modified_datetime: string;
    deleted_datetime: string | null;
    name: string;
    alternate_name: string;
    section_type: string;
    is_builtin: boolean;
    description: string;
    sequence: number;
    is_visible: boolean;
    is_optional: boolean;
    created_by_user: string;
    modified_by_user: string;
    deleted_by_user: string | null;
    template: string;
}
interface Sectionitem {
    section_item_id: string;
    options: (Option[][] | null)[];
    created_datetime: string;
    modified_datetime: string;
    deleted_datetime: string | null;
    sequence: number;
    name: string;
    alternate_name: string;
    description: string;
    constraints: Constraints;
    can_remove: boolean;
    is_builtin_field: boolean;
    is_required: boolean;
    field_level: string;
    is_variable: boolean;
    is_mandatory: boolean;
    additional_information: Additionalinformation;
    created_by_user: string;
    modified_by_user: string;
    deleted_by_user: string | null;
    section: string;
    parent_section_item: string | null;
}
interface Additionalinformation {
    is_hidden: boolean;
    is_visible?: boolean;
    is_negotiable?: boolean;
}
interface Constraints {
    choices?: (string | string)[];
    selected?: any;
    field_type: CustomFieldType;
    // | 'BOOLEAN'
    // | 'DATE'
    // | 'DATETIME'
    // | 'INTEGER'
    // | 'FLOAT'
    // | 'SHORTTEXT'
    // | 'LONGTEXT'
    // | 'STRING'
    // | 'CHOICE'
    // | 'ATTACHMENT';
    choice_type?: 'DROPDOWN' | 'MULTI_SELECT';
    max_limit?: number;
    min_limit?: number;
}
interface Option {
    section_item_option_id: string;
    created_datetime: string;
    modified_datetime: string;
    deleted_datetime: string | null;
    name: string;
    alternate_name: string;
    description: string;
    is_builtin: boolean;
    conditions: string[];
    created_by_user: string;
    modified_by_user: string;
    deleted_by_user: string | null;
    section: string;
    section_item: string;
}

export enum ProjectTemplateFWSectionFieldsEnum {
    PROJECT_CODE = 'PROJECT_CODE',
    CUSTOMER_NAME = 'CUSTOMER_NAME',
    PROJECT_NAME = 'PROJECT_NAME',
    DESCRIPTION = 'DESCRIPTION',
    INTERNAL_NOTES = 'INTERNAL_NOTES',
    START_DATE = 'START_DATE',
    END_DATE = 'END_DATE',
    PROJECT_MANAGER = 'PROJECT_MANAGER',
}
export type IProjecttemplateFieldOptionBase = {
    label: string;
    description: string;
    formula?: IForumlaWithIfElseAndOperators;
    buyerSettings: {
        showField: boolean;
        required: boolean;
    };
};

export type IProjectTemplateStandardFieldOptions =
    IProjecttemplateFieldOptionBase & {
        standardFieldCategory: 'STANDARD';
        constraints: IProjectFieldTypeContraints;
    };

export interface IProjectTemplateCustomFieldOptions
    extends IProjecttemplateFieldOptionBase {
    type: CustomFieldType;
    constraints: IProjectFieldTypeContraints;
}

export enum ProjectTemplateCustomSectionFieldEnum {
    DEADLINE_DATE = 'DEADLINE_DATE',
    ASSIGNED_TO = 'ASSIGNED_TO',
    ATTACHMENTS = 'ATTACHMENTS',
    STATUS = 'STATUS',
}

export const ProjectTemplateStandardFieldBackendNames: {
    [BackendTemplateItemName: string]:
        | ProjectTemplateFWSectionFieldsEnum
        | ProjectTemplateCustomSectionFieldEnum;
} = {
    'Project code': ProjectTemplateFWSectionFieldsEnum.PROJECT_CODE,
    'Project name': ProjectTemplateFWSectionFieldsEnum.PROJECT_NAME,
    Description: ProjectTemplateFWSectionFieldsEnum.DESCRIPTION,
    Customer: ProjectTemplateFWSectionFieldsEnum.CUSTOMER_NAME,
    'End date': ProjectTemplateFWSectionFieldsEnum.END_DATE,
    'Internal notes': ProjectTemplateFWSectionFieldsEnum.INTERNAL_NOTES,
    'Project managers': ProjectTemplateFWSectionFieldsEnum.PROJECT_MANAGER,
    'Start date': ProjectTemplateFWSectionFieldsEnum.START_DATE,
    'Deadline date': ProjectTemplateCustomSectionFieldEnum.DEADLINE_DATE,
    'Assigned to': ProjectTemplateCustomSectionFieldEnum.ASSIGNED_TO,
    Attachments: ProjectTemplateCustomSectionFieldEnum.ATTACHMENTS,
    Status: ProjectTemplateCustomSectionFieldEnum.STATUS,
};

export type ProjectTemplateStandardFieldSettingObjOptions =
    | 'PROJECT_CODE'
    | 'PROJECT_NAME'
    | 'PROJECT_MANAGER'
    | 'DESCRIPTION'
    | 'INTERNAL_NOTES'
    | 'START_DATE'
    | 'END_DATE'
    | 'DESCRIPTION'
    | 'ASSIGNED_TO'
    | 'ATTACHMENTS'
    | 'DEADLINE_DATE'
    | 'STATUS';

export type ProjectTemplateStandardFieldSettingObj = {
    [ProjectTemplateFWSectionFieldsEnum.PROJECT_CODE]: IProjectTemplateStandardFieldOptions;
    [ProjectTemplateFWSectionFieldsEnum.CUSTOMER_NAME]: IProjectTemplateStandardFieldOptions;
    [ProjectTemplateFWSectionFieldsEnum.PROJECT_NAME]: IProjectTemplateStandardFieldOptions;
    [ProjectTemplateFWSectionFieldsEnum.PROJECT_MANAGER]: IProjectTemplateStandardFieldOptions;
    [ProjectTemplateFWSectionFieldsEnum.DESCRIPTION]: IProjectTemplateStandardFieldOptions;
    [ProjectTemplateFWSectionFieldsEnum.INTERNAL_NOTES]: IProjectTemplateStandardFieldOptions;
    [ProjectTemplateFWSectionFieldsEnum.START_DATE]: IProjectTemplateStandardFieldOptions;
    [ProjectTemplateFWSectionFieldsEnum.END_DATE]: IProjectTemplateStandardFieldOptions;
    [ProjectTemplateFWSectionFieldsEnum.DESCRIPTION]: IProjectTemplateStandardFieldOptions;
    [ProjectTemplateCustomSectionFieldEnum.ASSIGNED_TO]: IProjectTemplateStandardFieldOptions;
    [ProjectTemplateCustomSectionFieldEnum.ATTACHMENTS]: IProjectTemplateStandardFieldOptions;
    [ProjectTemplateCustomSectionFieldEnum.DEADLINE_DATE]: IProjectTemplateStandardFieldOptions;
    [ProjectTemplateCustomSectionFieldEnum.STATUS]: IProjectTemplateStandardFieldOptions;
};

export interface IProjectTemplateFieldSetting {
    PROJECT_DETAILS: {
        standardFields: ProjectTemplateStandardFieldSettingObj;
        customFields: {
            [field: string]: IProjectTemplateCustomFieldOptions;
        };
    };
    [key: string]: {
        standardFields: ProjectTemplateStandardFieldSettingObj;
        customFields: {
            [field: string]: IProjectTemplateCustomFieldOptions;
        };
    };
}

export type IProjectTemplateSections = {
    PROJECT_DETAILS: IProjectTemplateSectionDetails;
    [key: string]: IProjectTemplateSectionDetails;
};

export interface IProjectTemplateSectionDetails {
    label: string;
    fieldList: ISectionField[];
}

export type ISectionField =
    | {
          fieldType: 'STANDARD';
          field:
              | ProjectTemplateFWSectionFieldsEnum
              | ProjectTemplateCustomSectionFieldEnum;
      }
    | {
          fieldType: 'CUSTOM';
          field: string;
      };

export type IProjectTemplateSection = {};

export interface IProjectEssentials {
    projectId: string;
    projectName: string;
    buyerEntityId: string;
    projectCreationDate: string;
    isUserShortlistedAsPM: boolean;
}
export interface IProjectTemplate {
    [x: string]: any;
    templateId: string;
    templateName: string;
    sections: IProjectTemplateSections;
    fieldSettings: IProjectTemplateFieldSetting;
    assigned_users: IAssignUserForTemplateAndPerSection;
}

export interface IProjectAssignuserListResponse {
    user_id: string;
    enterprise: string;
    enterprise_name: string;
    email: string;
    name: string;
    first_name: string | null;
    last_name: string | null;
    additional_details: string | null;
    role: string;
    description: string | null;
    status: string;
    is_email_verified: boolean;
    manager_count: number;
    designation: any;
    permission_count: number;
    user_picture_url: string;
    contacts: any;
    defaults: any;
    created_datetime: string;
    modified_datetime: string;
}
export interface IProjectAssigenUserListResponse {
    user_id: string;
    name: string;
    first_name: string | null;
    last_name: string | null;
    entity_id: string;
    entity_name: string;
    action_api_group: string;
    criteria: any;
}

export interface IUserList {
    user_id: string;
    name: string;
}
export interface ICustomerList {
    customer_entity_id: string;
    customer_entity_name: string;
}

export interface IEntityList {
    buyer_entity_id: string;
    buyer_entity_name: string;
}
export interface ICustomerEntityList {
    customer_entity_id: string;
    customer_entity_name: string;
}

export interface IProjects {
    project_id: string;
    project_code: string;
    project_name: string;
}

export interface ISectionName {
    section_names: string;
}

export interface ISectionName {
    section_names: string;
}

export interface ISellerEntity {
    seller_entity_id: string;
    seller_entity_name: string;
}
export type TProjectUsersList = {
    project_managers: IUserList[];
    created_by_users: IUserList[];
    modified_by_users: IUserList[];
    submitted_by_users: IUserList[];
    customers: ICustomerList[];
    section_names: ISectionName[];
};
export type TEventsUserList = {
    projects: IProjects[];
    customers: ICustomerList[];
    created_by_users: IUserList[];
    buyer_entities: IEntityList[];
};

export type TEventSellerList = {
    seller_entities: IEntityList[];
    buyer_entities: IEntityList[];
    created_by_users: IEntityList[];
    customers: ICustomerEntityList[];
};

export interface IProjectSectionStatus {
    [x: string]: ProjectStatusEnum | null;
}

export type TPOUserList = {
    buyer_entities: IEntityList[];
    seller_entities: ISellerEntity[];
};

export interface IPSVendorList {
    email: string;
    name: string;
    vendor_contact_id: string;
}

export type TVendorList = {
    created_by_users: IUserList[];
    modified_by_users: IUserList[];
    primary_contacts: IPSVendorList[];
    secondary_contacts: IPSVendorList[];
};

export interface IProjectInformation {
    enterpriseName: string;
    createdBy: string;
    createdOn: string;
    modifiedBy: string;
    modifiedOn: string;
    status: string;
    buyerEntityName: string;
    projectName: string;
    projectId: string;
    sectionStatus: IProjectSectionStatus;
}

export interface IProjectDetailsResponse {
    project_id: string;
    buyer_entity_name: string;
    cost_centres: any[];
    attachments: IAttachment[];
    customer_entity: string;
    created_datetime: string;
    modified_datetime: string;
    deleted_datetime: string | null;
    project_code: string;
    project_name: string;
    validity_from: string;
    validity_to: string;
    location: string | null;
    description: string;
    internal_notes: string;
    tags: string[];
    status: ProjectStatusEnum.ACTIVE | ProjectStatusEnum.INACTIVE;
    project_status: ProjectStatusEnum;
    custom_sections: IProjectCustomSection[];
    additional_details: AdditionalDetails;
    created_by_user: { user_id: string; name: string };
    modified_by_user: { user_id: string; name: string };
    deleted_by_user: any;
    buyer_enterprise: string;
    buyer_entity: string;
    project_managers: { user_id: string; name: string }[];
    rfq_responsible_users: { user_id: string; name: string }[];
}
export interface AdditionalDetails {
    template_id: string;
}
