import { FormGroup, styled } from '@mui/material';
import { cloneDeep, debounce } from 'lodash';
import {
    Dispatch,
    Fragment,
    SetStateAction,
    useContext,
    useMemo,
    useState,
} from 'react';
import {
    FWAccordion,
    FWAccordionDetails,
    FWAccordionSummary,
} from '../../../../Common/FWAccordian';
import { FWTypography } from '../../../../Common/FWTypograhy';
import { ICurrencyDetails } from '../../../../Models/Currency.model';
import {
    asModuleNameMap,
    asModuleValuesMap,
} from '../../Constants/AdminSettingsConstants';
import {
    ASModule,
    IAdminCustomFieldsAndChecklist,
    IAdminSettingModule,
} from '../../Interfaces/AdminSettingsInterface';
import { Spacer } from '../../Pages/Settings/AdminSettingsHomePage';
import ModuleSetting from './ModuleSetting';
import GRNQCSettings from './GRNQC/GRNQCSettings';
import InvoiceSetting from './Invoice/InvoiceSetting';
import EventSettings from './Events/EventSettings';
import RequisitionSettings from './Requisitions/RequisitionSettings';
import ProjectSettings from './Project/ProjectSettings';
import { AuthContext } from '../../../../Contexts/AuthContext';
import QuoteCalculatorSettings from './QuoteCalculator/QuoteCalculatorSettings';

const Accordion = styled(FWAccordion)({
    '&.MuiPaper-root': {
        minWidth: 'auto',
        '&:before': {
            display: 'none',
        },
    },
    '.MuiButtonBase-root': {
        width: 'max-content !important',
    },
    '.MuiAccordionSummary-content': {
        width: 'max-content !important',
    },
    '.MuiAccordionSummary-expandIconWrapper': {
        marginLeft: '10px !important',
        zoom: 0.7,
    },
    '.MuiCollapse-root': {
        paddingLeft: '10px !important',
    },
});

interface IASModuleAccordionProps {
    setting: IAdminSettingModule;
    parentIndex: number;
    currencies: ICurrencyDetails[];
    setSettingsValues: Dispatch<SetStateAction<IAdminSettingModule[]>>;
    setAdminTemplateSettings: Dispatch<
        SetStateAction<IAdminCustomFieldsAndChecklist>
    >;
    adminTemplateSettings: IAdminCustomFieldsAndChecklist;
    selectedEntity: string;
}

const ASModuleAccordion = ({
    setting,
    currencies,
    setSettingsValues,
    setAdminTemplateSettings,
    parentIndex,
    adminTemplateSettings,
    selectedEntity,
}: IASModuleAccordionProps) => {
    const [moduleValues, setModuleValues] = useState<IAdminSettingModule>(
        cloneDeep(setting)
    );

    const enterprise_id =
        useContext(AuthContext).authData.details?.enterprise_id;

    const debounceUpdate = useMemo(
        () =>
            debounce(async () => {
                setSettingsValues((prevSettingValues) => {
                    prevSettingValues[parentIndex] = moduleValues;
                    return [...prevSettingValues];
                });
            }, 500),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        []
    );

    useMemo(() => {
        debounceUpdate();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [moduleValues, parentIndex, setSettingsValues]);
    const getCorrectComponentAsPerModule = () => {
        switch (setting.module) {
            case ASModule.GOODS_RECEIPT:
                return (
                    <GRNQCSettings
                        settings={moduleValues.settings}
                        currencies={currencies}
                        setModuleValues={setModuleValues}
                        customFieldsAndChecklist={
                            adminTemplateSettings[ASModule.GOODS_RECEIPT]
                        }
                        selectedEntity={selectedEntity}
                        setAdminTemplateSettings={setAdminTemplateSettings}
                        type={'GRN'}
                    />
                );
            case ASModule.QUALITY_CHECK:
                return (
                    <GRNQCSettings
                        settings={moduleValues.settings}
                        currencies={currencies}
                        setModuleValues={setModuleValues}
                        customFieldsAndChecklist={
                            adminTemplateSettings[ASModule.QUALITY_CHECK]
                        }
                        selectedEntity={selectedEntity}
                        setAdminTemplateSettings={setAdminTemplateSettings}
                        type={'QC'}
                    />
                );
            case ASModule.INVOICE:
                return (
                    <InvoiceSetting
                        settings={moduleValues.settings}
                        currencies={currencies}
                        setModuleValues={setModuleValues}
                        customFieldsAndChecklist={
                            adminTemplateSettings[ASModule.INVOICE]
                        }
                        selectedEntity={selectedEntity}
                        setAdminTemplateSettings={setAdminTemplateSettings}
                    />
                );
            case ASModule.EVENT:
                return (
                    <EventSettings
                        settings={moduleValues.settings}
                        currencies={currencies}
                        setModuleValues={setModuleValues}
                        customFieldsAndChecklist={
                            adminTemplateSettings[ASModule.EVENT]
                        }
                        selectedEntity={selectedEntity}
                        setAdminTemplateSettings={setAdminTemplateSettings}
                    />
                );
            case ASModule.REQUISITION:
                if (enterprise_id === '9c8e0e18-0c17-4324-b7a2-1b0c8cbd2241') {
                    return null;
                } else {
                    return (
                        <RequisitionSettings
                            settings={moduleValues.settings}
                            currencies={currencies}
                            setModuleValues={setModuleValues}
                            customFieldsAndChecklist={
                                adminTemplateSettings[ASModule.EVENT]
                            }
                            selectedEntity={selectedEntity}
                            setAdminTemplateSettings={setAdminTemplateSettings}
                        />
                    );
                }
            case ASModule.PROJECT:
                return (
                    <ProjectSettings
                        settings={moduleValues.settings}
                        currencies={currencies}
                        setModuleValues={setModuleValues}
                    />
                );
            case ASModule.QUOTE_CALCULATOR:
                return (
                    <QuoteCalculatorSettings
                        settings={moduleValues.settings}
                        currencies={currencies}
                        setModuleValues={setModuleValues}
                    />
                );
            default:
                return (
                    <FormGroup>
                        {moduleValues.settings.map((settingItem, index) => {
                            return settingItem.option in asModuleValuesMap &&
                                !asModuleValuesMap[settingItem.option]
                                    .hidden ? (
                                <Fragment key={settingItem.option}>
                                    <ModuleSetting
                                        setting={settingItem}
                                        currencies={currencies}
                                        setModuleValues={setModuleValues}
                                        childIndex={index}
                                    />
                                    {moduleValues.settings.length - 1 !==
                                        index && <Spacer />}
                                </Fragment>
                            ) : (
                                <Fragment key={settingItem.option}></Fragment>
                            );
                        })}
                    </FormGroup>
                );
        }
    };

    return (
        <Accordion defaultExpanded>
            <FWAccordionSummary>
                <FWTypography variant="h4">
                    {asModuleNameMap[moduleValues.module]}
                </FWTypography>
            </FWAccordionSummary>
            <FWAccordionDetails>
                {getCorrectComponentAsPerModule()}
            </FWAccordionDetails>
        </Accordion>
    );
};

export default ASModuleAccordion;
