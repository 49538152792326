import { cloneDeep, isEmpty } from 'lodash';
import {
    Dispatch,
    SetStateAction,
    useCallback,
    useContext,
    useEffect,
    useMemo,
    useState,
} from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import {
    HookStateValue,
    IHookState,
    useHookState,
} from '../../../Common/Hooks/StateHook';
import { AuthContext } from '../../../Contexts/AuthContext';
// import { custom_field_backend_section_names } from '../../../Events/Interfaces/Buyer/RFQ.model';
import { CustomTemplateTypes } from '../../../Global/Interfaces/CustomTemplate.interface';
import { getAllTemplatesForEntity } from '../../../Global/Services/CustomTemplate.service';
import {
    AdminSettingsUpdateType,
    IAdminSettingsState,
} from '../../../Redux/Interfaces/AdminSettingsReduxInterface';
import { updateSelectedSetting } from '../../../Redux/Slices/AdminSelectedSettingSlice';
import { updateAdminSettings } from '../../../Redux/Slices/AdminSettingsSlice';
import { IVerificationState } from '../../../Redux/Slices/VerificationSlice';
import { IGlobalAppStore } from '../../../Redux/Store';
import {
    ASModule,
    ASModuleSetting,
    ASOptionType,
    IAdminCustomFieldsAndChecklist,
    IAdminSettingModule,
} from '../Interfaces/AdminSettingsInterface';
import {
    fetchEntitySettings,
    updateEntitySettings,
} from '../Services/AdminSettingsService';

export interface IAdminSettingsProviders {
    hookState: IHookState;
    settings: IAdminSettingModule[];
    updateSettings: (settings: IAdminSettingModule[]) => void;
    updateAllEntitySettings: (
        settings: IAdminSettingModule[]
    ) => Promise<boolean>;
    adminTemplateSettings: IAdminCustomFieldsAndChecklist;
    setAdminTemplateSettings: Dispatch<
        SetStateAction<IAdminCustomFieldsAndChecklist>
    >;
}

const blankTemplate = {
    template_id: '',
    template_name: 'CUSTOM_FIELDS',
    type: CustomTemplateTypes.QC_CUSTOM_FIELDS,
    enterprise: '',
    entity: '',
    items: [],
};

const getInitObj = (entityUid: string): IAdminCustomFieldsAndChecklist => {
    return {
        // removed this for prod
        // [ASModule.ADMIN]: {
        //     checklist: [],
        //     customFields: [],
        // },
        [ASModule.REQUISITION]: {
            customFields: [
                {
                    ...blankTemplate,
                },
            ],
            checklist: [],
        },
        [ASModule.EVENT]: {
            customFields: [
                {
                    ...blankTemplate,
                    template_name: 'payment_and_delivery_terms',
                    type: CustomTemplateTypes.RFQ_ITEM_CUSTOM_FIELDS,
                    entity: entityUid,
                },
                {
                    ...blankTemplate,
                    template_name: 'payment_and_delivery_terms',
                    type: CustomTemplateTypes.RFQ_ITEM_CUSTOM_FIELDS,
                    entity: entityUid,
                },
            ],
            checklist: [],
        },
        [ASModule.GOODS_RECEIPT]: {
            customFields: [
                {
                    ...blankTemplate,
                    type: CustomTemplateTypes.GRN_CUSTOM_FIELDS,
                    entity: entityUid,
                },
            ],
            checklist: [],
        },
        [ASModule.INVOICE]: {
            customFields: [
                {
                    ...blankTemplate,
                    type: CustomTemplateTypes.INVOICE_BATCH_ITEM_CUSTOM_FIELDS,
                    entity: entityUid,
                },
            ],
            checklist: [],
        },
        [ASModule.PAYMENT]: {
            customFields: [],
            checklist: [],
        },
        [ASModule.PURCHASE_ORDER]: {
            customFields: [],
            checklist: [],
        },
        [ASModule.QUALITY_CHECK]: {
            customFields: [
                {
                    ...blankTemplate,
                    type: CustomTemplateTypes.QC_CUSTOM_FIELDS,
                    entity: entityUid,
                },
            ],
            checklist: [],
        },
        [ASModule.PROJECT]: {
            customFields: [],
            checklist: [],
        },
        [ASModule.QUOTE_CALCULATOR]: {
            customFields: [],
            checklist: [],
        },
    };
};

export const useAdminSettings = (entityUid: string) => {
    const dispatch = useDispatch();

    const { allUserEntities } = useSelector(
        ({ VerificationStore }: { VerificationStore: IVerificationState }) =>
            VerificationStore
    );

    const { hookState, updateHookState } = useHookState(HookStateValue.INITIAL);

    const [settings, setSettings] = useState<IAdminSettingModule[]>([]);

    const [adminTemplateSettings, setAdminTemplateSettings] =
        useState<IAdminCustomFieldsAndChecklist>(getInitObj(''));

    const getSettings = useCallback(async () => {
        try {
            updateHookState(HookStateValue.LOADING);
            if (!isEmpty(entityUid)) {
                const [settings, allTemplates] = await Promise.all([
                    fetchEntitySettings(entityUid),
                    getAllTemplatesForEntity(
                        entityUid,
                        Object.values(CustomTemplateTypes).map((val) => val)
                    ),
                ]);
                // setting the templates correctly for each module

                setAdminTemplateSettings(() => {
                    let obj = cloneDeep(getInitObj(entityUid));
                    for (let template of allTemplates) {
                        switch (template.type) {
                            case CustomTemplateTypes.QC_CUSTOM_FIELDS:
                                obj[ASModule.QUALITY_CHECK].customFields = [
                                    template,
                                ];
                                break;
                            case CustomTemplateTypes.QC_CHECKLIST:
                                obj[ASModule.QUALITY_CHECK].checklist.push(
                                    template
                                );
                                break;
                            case CustomTemplateTypes.GRN_CUSTOM_FIELDS:
                                obj[ASModule.GOODS_RECEIPT].customFields = [
                                    template,
                                ];
                                break;
                            case CustomTemplateTypes.GRN_CHECKLIST:
                                obj[ASModule.GOODS_RECEIPT].checklist.push(
                                    template
                                );
                                break;
                            case CustomTemplateTypes.INVOICE_BATCH_ITEM_CUSTOM_FIELDS:
                                obj[ASModule.INVOICE].customFields = [template];
                                break;
                            case CustomTemplateTypes.RFQ_ITEM_CUSTOM_FIELDS:
                                if (
                                    template.template_name ===
                                    'payment_and_delivery_terms'
                                ) {
                                    obj[ASModule.EVENT].customFields[0] =
                                        template;
                                }
                                if (
                                    template.template_name ===
                                    'additional_information'
                                ) {
                                    obj[ASModule.EVENT].customFields[1] =
                                        template;
                                }
                                break;
                        }
                    }
                    return obj;
                });
                // -------------------------------------------------
                //TODO: PO module temporarily hidden as there are no working settings in it. Revert this when PO settings are implemented.
                const settingsWithoutPo = settings.filter(
                    (s) =>
                        s.module !== ASModule.PURCHASE_ORDER &&
                        s.module !== ('ADMIN' as ASModule)
                );

                dispatch(
                    updateAdminSettings({
                        type: AdminSettingsUpdateType.INIT_ENTITY,
                        entityUid,
                        value: cloneDeep(settingsWithoutPo),
                    })
                );
                setSettings(cloneDeep(settingsWithoutPo));
            }
            updateHookState(HookStateValue.READY);
        } catch (error) {
            updateHookState(HookStateValue.ERROR);
        }
    }, [dispatch, entityUid, updateHookState]);

    const updateSettings = (settings: IAdminSettingModule[]) => {
        try {
            setSettings(cloneDeep(settings));
            dispatch(
                updateAdminSettings({
                    type: AdminSettingsUpdateType.INIT_ENTITY,
                    entityUid,
                    value: settings,
                })
            );
        } catch (error) {}
    };

    const updateAllEntitySettings = async (
        settings: IAdminSettingModule[]
    ): Promise<boolean> => {
        try {
            const promiseList: any[] = [];
            allUserEntities
                .map((e) => e.entityId)
                .forEach(async (entityUid) => {
                    promiseList.push(
                        updateEntitySettings(cloneDeep(settings), entityUid)
                    );
                });

            for await (const updated of promiseList) {
                if (updated) {
                    setSettings(cloneDeep(settings));
                    dispatch(
                        updateAdminSettings({
                            type: AdminSettingsUpdateType.INIT_ENTITY,
                            entityUid,
                            value: settings,
                        })
                    );
                }
            }

            return true;
        } catch (error) {
            return false;
        }
    };

    useEffect(() => {
        getSettings();

        return () => {
            setSettings([]);
        };
    }, [getSettings, entityUid]);

    const { authData } = useContext(AuthContext);

    useEffect(() => {
        if (authData) {
        }
    }, [authData]);

    // using it here by default so the hook is called at least once
    useAdminSelectedSetting();

    const adminSettingsProvider: IAdminSettingsProviders = {
        hookState,
        settings,
        adminTemplateSettings,
        setAdminTemplateSettings,
        updateSettings,
        updateAllEntitySettings,
    };
    return adminSettingsProvider;
};

export const useAdminSelectedSetting: () => {
    updateSelectedSettingForEntity: (entityUid: string | null) => void;
    selectedEntity: string | null;
    selectedSetting: IAdminSettingModule[] | null;
    // removed this for prod
    // maxDecimalPlacesSetting: number;
} = () => {
    const dispatch = useDispatch();

    const { selectedEntity, selectedSetting } = useSelector(
        (store: IGlobalAppStore) => store.AdminSelectedSetting
    );

    // removed this for prod
    // const maxDecimalPlacesSetting = useMemo(() => {
    //     if (selectedSetting) {
    //         let findMaxDecimalSetting = selectedSetting
    //             .find((setting) => setting.module === ASModule.ADMIN)
    //             ?.settings.find(
    //                 (setting) =>
    //                     setting.option === ASModuleSetting.MAX_DECIMAL_PRECISION
    //             );
    //         if (
    //             findMaxDecimalSetting &&
    //             findMaxDecimalSetting.settings.type ===
    //                 ASOptionType.MAX_VALUE &&
    //             findMaxDecimalSetting.settings.value.trim() !== ''
    //         ) {
    //             return Number(findMaxDecimalSetting.settings.value);
    //         }
    //     }
    //     return 2;
    // }, [selectedSetting]);

    const AdminSettingsStore = useSelector(
        (store: IGlobalAppStore) => store.AdminSettingsStore
    );
    const { authData } = useContext(AuthContext);

    useEffect(() => {
        if (authData) {
            if (authData.details?.defaults?.primary_entity_id) {
                dispatch(
                    updateSelectedSetting({
                        selectedEntity:
                            authData.details?.defaults?.primary_entity_id ??
                            null,
                        selectedSetting: authData.details?.defaults
                            ?.primary_entity_id
                            ? AdminSettingsStore[
                                  authData.details?.defaults?.primary_entity_id
                              ] ?? null
                            : null,
                    })
                );
            }
        }
    }, [AdminSettingsStore, authData, dispatch]);

    const updateSelectedSettingForEntity = useCallback(
        (entityUid: string | null) => {
            if (entityUid) {
                dispatch(
                    updateSelectedSetting({
                        selectedEntity: entityUid,
                        selectedSetting: AdminSettingsStore[entityUid] ?? null,
                    })
                );
            }
        },
        [AdminSettingsStore, dispatch]
    );
    return {
        updateSelectedSettingForEntity,
        selectedEntity,
        selectedSetting,
        //removed this for prod
        // maxDecimalPlacesSetting,
    };
};

export const useSetAdminSettings = () => {
    const dispatch = useDispatch();

    const allUserEntityIDs = useSelector(
        ({ VerificationStore }: { VerificationStore: IVerificationState }) =>
            VerificationStore.allUserEntities.map((e) => e.entityId),
        shallowEqual
    );

    useAdminSelectedSetting();

    useEffect(() => {
        allUserEntityIDs.forEach(async (entityUid) => {
            if (!isEmpty(entityUid)) {
                const settings = await fetchEntitySettings(entityUid);
                dispatch(
                    updateAdminSettings({
                        type: AdminSettingsUpdateType.INIT_ENTITY,
                        entityUid,
                        value: cloneDeep(settings),
                    })
                );
            }
        });
    }, [allUserEntityIDs, dispatch]);
};

export interface IUseAdminSetting {
    value?: string;
    enabled?: boolean;
    currencyUid?: string;
}

export const useGetAdminSettings = (
    entityUid: string,
    settingName: ASModuleSetting
) => {
    const settings = useSelector(
        ({ AdminSettingsStore }: { AdminSettingsStore: IAdminSettingsState }) =>
            AdminSettingsStore
    );

    const [settingsList, setSettingsList] = useState<IUseAdminSetting>({});

    useMemo(() => {
        if (!isEmpty(entityUid)) {
            const modules = settings[entityUid];
            const settingsList: IUseAdminSetting = {};
            if (modules) {
                for (const module of modules) {
                    for (const setting of module.settings) {
                        if (settingName.includes(setting.option)) {
                            switch (setting.settings.type) {
                                case ASOptionType.BOOLEAN:
                                    settingsList['enabled'] =
                                        setting.settings.enabled;
                                    break;
                                case ASOptionType.MAX_CURRENCY:
                                    settingsList['enabled'] =
                                        setting.settings.enabled;
                                    settingsList['value'] =
                                        setting.settings.value;
                                    settingsList['currencyUid'] =
                                        setting.settings.currencyUid;
                                    break;
                                case ASOptionType.MAX_VALUE:
                                    settingsList['enabled'] =
                                        setting.settings.enabled;
                                    settingsList['value'] =
                                        setting.settings.value;
                                    break;
                            }
                            break;
                        }
                    }
                }
            }
            setSettingsList(settingsList);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [settings, entityUid]);

    return [settingsList] as const;
};
