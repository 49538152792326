import { ICustomSection } from '../../Global/Interfaces/TemplateInterface';
import { IAttachment } from '../../Models/RFQ.model';

export type updateCustomDocumentDetailsActionType = keyof INewDocument;

export type createCustomDocumentDetailsActionType = keyof ICreateDocument;

export enum DocumentStateEnum {
    DRAFT = 'DRAFT',
    SUBMITTED = 'SUBMITTED',
    REVISING = 'REVISING',
}

export interface IDocumentCustomField {
    name: string;
    type: string;
    value: string | boolean | string[] | null | number;
    is_locked: boolean;
    is_visible: boolean;
    description: string;
    is_required: boolean;
    is_negotiable: boolean;
}

export interface INewDocument {
    document_id: string;
    document_entry_id: string | null;
    document_history_id: string | null;
    document_name: string;
    entity_id: string;
    seller_entity_id: string | null;
    template_id: string;
    custom_fields: {
        section_list: {
            name: string;
            fields: IDocumentCustomField[];
        }[];
    };
    custom_fields_negotiate: {
        section_list: {
            name: string;
            fields: IDocumentCustomField[];
        }[];
    };
    custom_sections: ICustomSection[];
    role: 'BUYER' | 'SELLER' | '';
    attachment_ids: string[];
    document_description: string | null;
    document_validity_datetime: string | null;
    document_logo_id: string[];
    document_banner_id: string[];
}

export interface ICreateDocument {
    document_name: string;
    entity_id: string;
    seller_entity_id: string | null;
    template_id: string;
    document_entry_id: string | null;
    document_history_id: string | null;
    custom_sections: ICustomSection[];
}

export type CreateDocumentErrorDesc = {
    type: 'WARNING' | 'ERROR';
    message: string;
};

export type IDocumentDetailsErrors = {
    document_name?: CreateDocumentErrorDesc;
    entity_id?: CreateDocumentErrorDesc;
    template_id?: CreateDocumentErrorDesc;
    document_validity_datetime?: CreateDocumentErrorDesc;
    document_description?: CreateDocumentErrorDesc;
    document_banner_id?: CreateDocumentErrorDesc;
    document_logo_id?: CreateDocumentErrorDesc;
    custom_fields?: {
        [key: string]: CreateDocumentErrorDesc;
    };
    custom_fields_negotiate?: {
        [key: string]: CreateDocumentErrorDesc;
    };
    custom_sections?: {
        [key: string]: CreateDocumentErrorDesc;
    };
};

export enum CreateDocumentErrorFieldEnum {
    document_name = 'document_name',
    entity_id = 'entity_id',
    document_validity_datetime = 'document_validity_datetime',
    document_description = 'document_description',
    document_banner_id = 'document_banner_id',
    document_logo_id = 'document_logo_id',
    template_id = 'template_id',
    custom_fields = 'custom_fields',
    custom_fields_negotiate = 'custom_fields_negotiate',
    custom_sections = 'custom_sections',
}

export interface IDocumentDetails {
    document_id: string;
    document_entry_id: string | null;
    document_history_id: string | null;
    document_name: string;
    created_by_user_name: string;
    created_by_user_id: string;
    created_datetime: string;
    state: DocumentStateEnum;
    modified_datetime: string;
    buyer_enterprise_id: string;
    seller_enterprise_id: string;
    buyer_entity_id: string;
    buyer_entity_name: string;
    seller_entity_id: string | null;
    seller_entity_name: string | null;
    attachments: IAttachment[];
    document_description: string;
    document_validity_datetime: string;
    document_logo: IAttachment[];
    document_banner: IAttachment[];

    additional_details: {
        template_id: string;
    };
    custom_fields: {
        section_list: {
            name: string;
            fields: IDocumentCustomField[];
        }[];
    };
    custom_sections: ICustomSection[];
    custom_fields_negotiate: {
        section_list: {
            name: string;
            fields: IDocumentCustomField[];
        }[];
    };
}

export interface IDocumentSellerDetails {
    seller_document_access_id: string;
    seller_enterprise_id: string;
    seller_entity_id: string;
    seller_entity_name: string;
}
