import { ITemplateStandardFieldOptions } from '../../Costing/Interfaces/CostingTemplate.interface';
import { IFieldTypeConstraints } from '../../FieldTypes/FieldTypes.model';
import {
    CustomFieldType,
    ITemplateStandardFieldOptionsBase,
} from '../../Global/Interfaces/TemplateInterface';

export enum TemplateStandardFieldsEnum {
    VISIT_NAME = 'VISIT_NAME',
    VISIT_DATE = 'VISIT_DATE',
    VISIT_ADDRESS = 'VISIT_ADDRESS',
    MODE = 'MODE',
    CUSTOMER = 'CUSTOMER',
    CUSTOMER_ADDRESS = 'CUSTOMER_ADDRESS',
    NOTES = 'NOTES',
    ATTACHMENTS = 'ATTACHMENTS',
}

export type VisitTemplateStandardFieldSettingsObj = {
    [TemplateStandardFieldsEnum.VISIT_NAME]: ITemplateStandardFieldOptions;
    [TemplateStandardFieldsEnum.VISIT_DATE]: ITemplateStandardFieldOptions;
    [TemplateStandardFieldsEnum.VISIT_ADDRESS]: ITemplateStandardFieldOptions;
    [TemplateStandardFieldsEnum.MODE]: ITemplateStandardFieldOptions;
    [TemplateStandardFieldsEnum.CUSTOMER]: ITemplateStandardFieldOptions;
    [TemplateStandardFieldsEnum.CUSTOMER_ADDRESS]: ITemplateStandardFieldOptions;
    [TemplateStandardFieldsEnum.NOTES]: ITemplateStandardFieldOptions;
    [TemplateStandardFieldsEnum.ATTACHMENTS]: ITemplateStandardFieldOptions;
};

export interface ITemplateCustomFieldOptions
    extends ITemplateStandardFieldOptionsBase {
    type: CustomFieldType;
    constraints: IFieldTypeConstraints;
}

export interface IVisitTemplateFieldSetting {
    standardFields: VisitTemplateStandardFieldSettingsObj;
    customFields: {
        [field: string]: ITemplateCustomFieldOptions;
    };
}

export enum CostingStandardSections {
    VISIT_DETAILS = 'VISIT_DETAILS',
}

export type ISectionField =
    | {
          fieldType: 'STANDARD';
          field: TemplateStandardFieldsEnum;
      }
    | {
          fieldType: 'CUSTOM';
          field: string;
      };

export interface IVisitTemplateSectionDetails {
    label: string;
    section_type: 'OTHER' | 'BOM' | 'ITEM';
    assigned_users: {
        permission: 'VIEW' | 'EDIT' | 'ASSIGN';
        user: { user_id: string; name: string };
    }[];
    fieldList: ISectionField[];
}

export type IVisitTemplateSections = {
    [section in CostingStandardSections]: IVisitTemplateSectionDetails;
};

export interface IVisitTemplate {
    templateId: string;
    templateName: string;
    assigned_users: {
        permission: 'CREATE' | 'ASSIGN';
        user: { user_id: string; name: string };
    }[];
    fieldSettings: IVisitTemplateFieldSetting;
    sections: IVisitTemplateSections;
}

export const VisitTemplateStandardFieldBackendNames: {
    [BackendTemplateItemName: string]: TemplateStandardFieldsEnum;
} = {
    'Visit name': TemplateStandardFieldsEnum.VISIT_NAME,
    'Visit date': TemplateStandardFieldsEnum.VISIT_DATE,
    Mode: TemplateStandardFieldsEnum.MODE,
    Customer: TemplateStandardFieldsEnum.CUSTOMER,
    'Customer address': TemplateStandardFieldsEnum.CUSTOMER_ADDRESS,
    Notes: TemplateStandardFieldsEnum.NOTES,
    Attachments: TemplateStandardFieldsEnum.ATTACHMENTS,
};

export const blankVisitTemplate: IVisitTemplate = {
    fieldSettings: {
        customFields: {},
        standardFields: {
            VISIT_NAME: {
                buyerSettings: {
                    required: false,
                    showField: false,
                },
                description: '',
                label: 'Visit name',
                sellerSettings: {
                    negotiable: false,
                    required: false,
                    showField: false,
                },
                standardFieldCategory: 'STANDARD',
            },
            VISIT_DATE: {
                buyerSettings: {
                    required: false,
                    showField: false,
                },
                description: '',
                label: 'Visit date',
                sellerSettings: {
                    negotiable: false,
                    required: false,
                    showField: false,
                },
                standardFieldCategory: 'STANDARD',
            },
            VISIT_ADDRESS: {
                buyerSettings: {
                    required: false,
                    showField: false,
                },
                description: '',
                label: 'Visit address',
                sellerSettings: {
                    negotiable: false,
                    required: false,
                    showField: false,
                },
                standardFieldCategory: 'STANDARD',
            },
            MODE: {
                buyerSettings: {
                    required: false,
                    showField: false,
                },
                description: '',
                label: 'Mode',
                sellerSettings: {
                    negotiable: false,
                    required: false,
                    showField: false,
                },
                standardFieldCategory: 'STANDARD',
            },
            CUSTOMER: {
                buyerSettings: {
                    required: false,
                    showField: false,
                },
                description: '',
                label: 'Customer',
                sellerSettings: {
                    negotiable: false,
                    required: false,
                    showField: false,
                },
                standardFieldCategory: 'STANDARD',
            },
            CUSTOMER_ADDRESS: {
                buyerSettings: {
                    required: false,
                    showField: false,
                },
                description: '',
                label: 'Customer address',
                sellerSettings: {
                    negotiable: false,
                    required: false,
                    showField: false,
                },
                standardFieldCategory: 'STANDARD',
            },
            NOTES: {
                buyerSettings: {
                    required: false,
                    showField: false,
                },
                description: '',
                label: 'Notes',
                sellerSettings: {
                    negotiable: false,
                    required: false,
                    showField: false,
                },
                standardFieldCategory: 'STANDARD',
            },
            ATTACHMENTS: {
                buyerSettings: {
                    required: false,
                    showField: false,
                },
                description: '',
                label: 'Attachments',
                sellerSettings: {
                    negotiable: false,
                    required: false,
                    showField: false,
                },
                standardFieldCategory: 'STANDARD',
            },
        },
    },
    sections: {
        VISIT_DETAILS: {
            fieldList: [],
            assigned_users: [],
            section_type: 'OTHER',
            label: 'Visit Details',
        },
    },
    assigned_users: [],
    templateId: '',
    templateName: '',
};
