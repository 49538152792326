import { CircularProgress, styled } from '@mui/material';
import { Variant } from '@mui/material/styles/createTypography';
import { isUndefined } from 'lodash';
import { useState } from 'react';
import { toast } from 'react-toastify';
import { FWTooltip } from '../../Common/FWCustomTooltip';
import FWIcon from '../../Common/FWIcon';
import { FWTypography } from '../../Common/FWTypograhy';
import { uploadFile } from '../../Global/Services/FileStorageService';
import { IAttachment } from '../../Models/RFQ.model';

export enum IFileResourceType {
    IDENTIFICATION = 'IDENTIFICATION',
    RFQ = 'RFQ',
    BULK_ITEMS = 'BULK_ITEMS',
    RFQ_ITEM = 'RFQ_ITEM',
    RFQ_BID_ITEM = 'RFQ_BID_ITEM',
    RFQ_BID = 'RFQ_BID',
    INVOICE = 'INVOICE',
    QUALITY_CHECK = 'QUALITY_CHECK',
    GOODS_RECEIPT = 'GOODS_RECEIPT',
    INVOICE_BUYER = 'INVOICE_BUYER',
    INVOICE_SELLER = 'INVOICE_SELLER',
    PAYMENT = 'PAYMENT',
    DIRECT_PO_ITEM = 'PO_GROUP_ITEM',
    PO_ITEM = 'PO_ITEM',
    AWARD = 'AWARD',
    ITEM = 'ITEM',
    VENDOR = 'VENDOR',
    BUYER = 'BUYER',
    CHAT = 'CHAT',
    PO_GROUP_ITEM = 'PO_GROUP_ITEM',
    VENDOR_PROFILE_CERTIFICATE = 'VENDOR_PROFILE_CERTIFICATE',
    VENDOR_PROFILE_QC = 'VENDOR_PROFILE_QC',
    VENDOR_PROFILE_CUSTOM_SECTION = 'VENDOR_PROFILE_CUSTOM_SECTION',
    VENDOR_PROFILE_FACTORY_LOCATION = 'VENDOR_PROFILE_FACTORY_LOCATION',
    ENTITY_LOGO = 'ENTITY_LOGO',
    BOM = 'BOM',
    ENTERPRISE_ITEM = 'ENTERPRISE_ITEM',
    CONTRACT = 'CONTRACT',
    CONTRACT_ITEM = 'CONTRACT_ITEM',
    BID_ITEM_SELLER = 'BID_ITEM_SELLER',
    BID_ITEM_BUYER = 'BID_ITEM_BUYER',
    SRM = 'SRM',
    // for the following key, an additional key named type will be send in the payload.. check in FileStorageService.ts:114 func fetchUploadUrl
    DOCUMENT_TEMPLATE = 'DOCUMENT_TEMPLATE',
    DOCUMENT_ATTACHMENT = 'DOCUMENT_ATTACHMENT',
    DOCUMENT_BANNER = 'DOCUMENT_BANNER',
    DOCUMENT_LOGO = 'DOCUMENT_LOGO',
    PROJECT = 'PROJECT',
    VISIT = 'VISIT',
}

export interface IResourceFields {
    key: string;
    value: number | null;
    isOptional?: boolean;
}

interface IUploadFileProps {
    id?: string; // unique id for the DOM element
    label?: string; // text besides the add file icon
    labelColor?: string; // FWTypography colors (defaulted to text.secondary)
    labelvariant?: Variant; // FWTypography variant (defaulted to body1)
    icon?: 'CLIP' | 'PLUS';
    allowedFileSizeInMB?: number; // in MB (defaulted to  MB)
    existingFiles: IAttachment[]; // existing list of the IAttachment list from your main state
    maxAllowedFiles?: number;
    multiple?: boolean;
    resourceType: IFileResourceType;
    allowedFileTypes?: string[];
    disabled?: boolean;
    onFileUpload?: (file: IAttachment[]) => void;
    clipIconSize?: number;
    maxFileNameLength?: number;
    allowFileUpload?: boolean;
    showAddFileToolip?: boolean;

    CustomComponentForFileUploadIcon?: () => JSX.Element;
    CustomLoaderForFileUpload?: () => JSX.Element;
}

const Label = styled('label')({
    cursor: 'pointer',
    '&.disabled': {
        cursor: 'not-allowed',
    },
});

const fileType: {
    [key: string]: string;
} = {
    'text/csv': 'csv',
    'image/jpeg': 'jpeg',
    'image/jpg': 'jpg',
    'image/png': 'png',
    'image/svg': 'svg',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': 'xlsx',
};

const UploadFile = ({
    id,
    label = 'File',
    labelColor = 'text.secondary',
    labelvariant = 'body1',
    icon = 'PLUS',
    allowedFileSizeInMB = 5,
    existingFiles,
    maxAllowedFiles,
    onFileUpload,
    multiple,
    resourceType,
    allowedFileTypes,
    disabled,
    clipIconSize = 16,
    maxFileNameLength = 100,
    allowFileUpload = true,
    CustomComponentForFileUploadIcon,
    CustomLoaderForFileUpload,
    showAddFileToolip = true,
}: IUploadFileProps) => {
    const buttonIcon =
        icon === 'PLUS' ? 'bi bi-plus-circle' : 'bi bi-paperclip';
    const [uploading, setUploading] = useState<boolean>(false);

    const allowedFileSizeInBytes = allowedFileSizeInMB * 1024 * 1024;
    const toastTimeout = 6000;
    const existingFileNames = existingFiles.map((file) => file.file_name);

    const reachedMaxFileLimit =
        existingFiles.length >= (maxAllowedFiles || Infinity);

    const handleFileUpload = async (e: any) => {
        if (e.target.files) {
            const selectedFiles: File[] = Array.from(e.target.files);
            const validFiles: File[] = [];
            const overSizeLimitFiles: File[] = [];
            const duplicateFiles: File[] = [];
            const longNameFiles: File[] = [];
            console.log('bdakbdkadba', e.target.files);
            if (allowedFileTypes) {
                for (const file of selectedFiles) {
                    const ft = fileType[file.type];
                    if (
                        !allowedFileTypes
                            .map((fttt) => fttt.slice(1))
                            .includes(ft)
                    ) {
                        toast.error(
                            `File type '${ft || file.type}' not allowed - ${
                                file.name
                            }`
                        );
                        e.target.value = null;
                        return false;
                    }
                }
            }

            // stop upload if more than allowed files selected
            if (
                !isUndefined(maxAllowedFiles) &&
                selectedFiles.length > maxAllowedFiles - existingFiles.length
            ) {
                toast.error(`Maximum of ${maxAllowedFiles} file(s) allowed`);
                e.target.value = null;
                return false;
            }

            // sort out valid and invalid files
            for (let currentFile of selectedFiles) {
                if (
                    existingFileNames.includes(currentFile.name) ||
                    validFiles
                        .map((file) => file.name)
                        .includes(currentFile.name)
                ) {
                    duplicateFiles.push(currentFile);
                } else if (currentFile.size > allowedFileSizeInBytes) {
                    overSizeLimitFiles.push(currentFile);
                } else if (currentFile.name.length > maxFileNameLength) {
                    longNameFiles.push(currentFile);
                } else {
                    validFiles.push(currentFile);
                }
            }

            // show error for files which are duplicate
            if (Array.from(duplicateFiles).length !== 0) {
                toast.error(
                    duplicateFiles.length > 1
                        ? `${duplicateFiles.length} files had duplicate names`
                        : `File had duplicate name`,
                    {
                        autoClose: toastTimeout,
                    }
                );
                for (let file of duplicateFiles) {
                    toast.warn(`${file.name} not added`, {
                        autoClose: toastTimeout,
                    });
                }
            }

            // show error for files which are over the size limit
            if (Array.from(overSizeLimitFiles).length !== 0) {
                toast.error(
                    overSizeLimitFiles.length > 1
                        ? `${overSizeLimitFiles.length} files exceed maximum size of ${allowedFileSizeInMB} MB`
                        : `File exceeds maximum size of ${allowedFileSizeInMB} MB`,
                    {
                        autoClose: toastTimeout,
                    }
                );
                for (let file of overSizeLimitFiles) {
                    toast.warn(`${file.name} not added`, {
                        autoClose: toastTimeout,
                    });
                }
            }

            //Show error for files which are over the name limit
            if (Array.from(longNameFiles).length !== 0) {
                toast.error(
                    longNameFiles.length > 1
                        ? `${longNameFiles.length} files exceed maximum name length of ${maxFileNameLength} characters`
                        : `File exceeds maximum name length of ${maxFileNameLength} characters`,
                    {
                        autoClose: toastTimeout,
                    }
                );
                for (let file of longNameFiles) {
                    toast.warn(`${file.name} not added`, {
                        autoClose: toastTimeout,
                    });
                }
            }
            try {
                setUploading(true);

                // create a promise array to upload single file at a time
                const fileUploadPromiseList: any[] = [];

                validFiles.forEach((file) => {
                    fileUploadPromiseList.push(uploadFile(file, resourceType));
                });
                let attachmentsList = await Promise.all(fileUploadPromiseList);

                console.log('attachmentsList', attachmentsList);
                // final callback to update the main state
                if (onFileUpload) onFileUpload(attachmentsList);
            } catch (e) {
                toast.error('File upload failed');
            } finally {
                setUploading(false);
                //     // reset the input for more files to be added
                // e.target.value = null;
            }
        }
    };

    return (
        <div
            className={CustomComponentForFileUploadIcon ? '' : 'flex flex--aic'}
        >
            {label && (
                <FWTypography color={labelColor} variant={labelvariant}>
                    {label}
                </FWTypography>
            )}
            <FWTooltip
                title={
                    reachedMaxFileLimit
                        ? `Reached maximum file upload limit of ${maxAllowedFiles} file(s)`
                        : disabled
                        ? ''
                        : showAddFileToolip
                        ? 'Add file(s)'
                        : ''
                }
            >
                <Label
                    className={`${
                        reachedMaxFileLimit || uploading || disabled
                            ? 'disabled'
                            : ''
                    }`}
                >
                    <input
                        id={id}
                        type="file"
                        hidden
                        value={[]}
                        multiple={multiple}
                        onChange={handleFileUpload}
                        accept={
                            allowedFileTypes ? allowedFileTypes?.join(',') : '*'
                        }
                        onClick={(e) => {
                            if (!allowFileUpload) {
                                e.preventDefault();
                            }
                            if (reachedMaxFileLimit) e.preventDefault();
                        }}
                        disabled={disabled}
                        style={{
                            width: '100%',
                        }}
                    />

                    {
                        <div
                            className={label ? 'ml--5' : ''}
                            id={id ?? 'createEvents_uploadAttachments'}
                            style={{
                                width: '100%',
                            }}
                        >
                            {!uploading ? (
                                CustomComponentForFileUploadIcon ? (
                                    <CustomComponentForFileUploadIcon />
                                ) : allowFileUpload ? (
                                    <FWIcon
                                        name={buttonIcon}
                                        size={clipIconSize}
                                        color={
                                            reachedMaxFileLimit || disabled
                                                ? 'text.secondary'
                                                : 'primary.main'
                                        }
                                    />
                                ) : (
                                    <></>
                                )
                            ) : CustomLoaderForFileUpload ? (
                                <CustomLoaderForFileUpload />
                            ) : (
                                <CircularProgress size={20} />
                            )}
                        </div>
                    }
                </Label>
            </FWTooltip>
        </div>
    );
};

export default UploadFile;
