import { cloneDeep, isEqual } from 'lodash';
import moment from 'moment';
import { leadTimeSurpassed } from '../../Components/Shared/Utils';
import {
    CustomFieldType,
    ICustomSection,
} from '../../Global/Interfaces/TemplateInterface';
import { IItemCartLevelCustomField } from '../../ItemAnalytics/Interfaces/itemCartItemInterfaces';
import {
    ICosting,
    ICostingBOM,
    ICostingErrors,
    ICostingItem,
    IQuoteCustomField,
} from '../Interfaces/CostingInterfaces.interface.';
import {
    ICostingTemplate,
    ISectionField,
    TemplateStandardFieldsEnum,
} from '../Interfaces/CostingTemplate.interface';

export const convertCostingTemplateCFToPayload = (
    templateCF: ISectionField,
    templateDetails: ICostingTemplate,
    fieldInPayload: IItemCartLevelCustomField | undefined
): IQuoteCustomField => {
    const customFieldInfoInTemplate =
        templateDetails.fieldSettings.customFields[templateCF.field];

    switch (customFieldInfoInTemplate?.constraints?.fieldType) {
        case CustomFieldType.INTEGER:
            return {
                // custom_field_id: null,
                name: customFieldInfoInTemplate.label,
                type: CustomFieldType.INTEGER,
                value: fieldInPayload
                    ? fieldInPayload.type ===
                      customFieldInfoInTemplate.constraints.fieldType
                        ? fieldInPayload.value ?? ''
                        : ''
                    : '',

                is_locked: true,
                is_negotiable: false,
                is_visible: customFieldInfoInTemplate.sellerSettings.showField,
                description: customFieldInfoInTemplate.description,
                is_required: customFieldInfoInTemplate.sellerSettings.required,
            };
        case CustomFieldType.CURRENCY:
            return {
                // custom_field_id: null,
                name: customFieldInfoInTemplate.label,
                value: fieldInPayload
                    ? fieldInPayload.type ===
                      customFieldInfoInTemplate.constraints.fieldType
                        ? fieldInPayload.value ?? ''
                        : ''
                    : '',
                type: CustomFieldType.CURRENCY,
                is_locked: true,
                is_negotiable: false,
                description: customFieldInfoInTemplate.description,
                is_visible: customFieldInfoInTemplate.sellerSettings.showField,
                is_required: customFieldInfoInTemplate.sellerSettings.required,
            };
        case CustomFieldType.BOOLEAN:
            return {
                // custom_field_id: null,
                name: customFieldInfoInTemplate.label,
                value: fieldInPayload
                    ? fieldInPayload.type ===
                      customFieldInfoInTemplate.constraints.fieldType
                        ? fieldInPayload.value
                        : null
                    : null,
                type: CustomFieldType.BOOLEAN,
                is_locked: true,
                is_negotiable: false,
                description: customFieldInfoInTemplate.description,
                is_visible: customFieldInfoInTemplate.sellerSettings.showField,
                is_required: customFieldInfoInTemplate.sellerSettings.required,
            };
        case CustomFieldType.SHORTTEXT:
            return {
                // custom_field_id: null,
                name: customFieldInfoInTemplate.label,
                value: fieldInPayload
                    ? fieldInPayload.type ===
                      customFieldInfoInTemplate.constraints.fieldType
                        ? fieldInPayload.value
                        : null
                    : null,
                type: CustomFieldType.SHORTTEXT,
                is_locked: true,
                is_negotiable: false,
                description: customFieldInfoInTemplate.description,
                is_visible: customFieldInfoInTemplate.sellerSettings.showField,
                is_required: customFieldInfoInTemplate.sellerSettings.required,
            };
        case CustomFieldType.LONGTEXT:
            return {
                // custom_field_id: null,
                name: customFieldInfoInTemplate.label,
                value: fieldInPayload
                    ? fieldInPayload.type ===
                      customFieldInfoInTemplate.constraints.fieldType
                        ? fieldInPayload.value
                        : null
                    : null,
                type: CustomFieldType.LONGTEXT,
                is_locked: true,
                is_negotiable: false,
                description: customFieldInfoInTemplate.description,
                is_visible: customFieldInfoInTemplate.sellerSettings.showField,
                is_required: customFieldInfoInTemplate.sellerSettings.required,
            };
        case CustomFieldType.DATE:
            return {
                // custom_field_id: null,
                name: customFieldInfoInTemplate.label,
                value: fieldInPayload
                    ? fieldInPayload.type ===
                      customFieldInfoInTemplate.constraints.fieldType
                        ? fieldInPayload.value
                            ? moment(fieldInPayload.value as string).format(
                                  'YYYY-MM-DD'
                              )
                            : null
                        : null
                    : null,
                type: CustomFieldType.DATE,
                is_locked: true,
                is_negotiable: false,
                description: customFieldInfoInTemplate.description,
                is_visible: customFieldInfoInTemplate.sellerSettings.showField,
                is_required: customFieldInfoInTemplate.sellerSettings.required,
            };

        case CustomFieldType.DATETIME:
            return {
                // custom_field_id: null,
                name: customFieldInfoInTemplate.label,
                value: fieldInPayload
                    ? fieldInPayload.type ===
                      customFieldInfoInTemplate.constraints.fieldType
                        ? fieldInPayload.value
                            ? moment(fieldInPayload.value as string).format(
                                  'YYYY-MM-DDTHH:mm'
                              )
                            : null
                        : null
                    : null,
                type: CustomFieldType.DATETIME,
                is_locked: true,
                is_negotiable: false,
                description: customFieldInfoInTemplate.description,
                is_visible: customFieldInfoInTemplate.sellerSettings.showField,
                is_required: customFieldInfoInTemplate.sellerSettings.required,
            };

        case CustomFieldType.FLOAT:
            return {
                // custom_field_id: null,
                name: customFieldInfoInTemplate.label,
                value: fieldInPayload
                    ? fieldInPayload.type ===
                      customFieldInfoInTemplate.constraints.fieldType
                        ? fieldInPayload.value ?? ''
                        : ''
                    : '',
                type: CustomFieldType.FLOAT,
                is_locked: true,
                is_negotiable: false,
                description: customFieldInfoInTemplate.description,
                is_visible: customFieldInfoInTemplate.sellerSettings.showField,
                is_required: customFieldInfoInTemplate.sellerSettings.required,
            };

        case CustomFieldType.CHOICE:
            if (
                customFieldInfoInTemplate.constraints.choiceType === 'DROPDOWN'
            ) {
                return {
                    // custom_field_id: null,
                    name: customFieldInfoInTemplate.label,
                    value: fieldInPayload
                        ? fieldInPayload.type ===
                          customFieldInfoInTemplate.constraints.fieldType
                            ? fieldInPayload.value
                            : null
                        : null,
                    type: CustomFieldType.CHOICE,
                    is_locked: true,
                    is_negotiable: false,
                    description: customFieldInfoInTemplate.description,
                    is_visible:
                        customFieldInfoInTemplate.sellerSettings.showField,
                    is_required:
                        customFieldInfoInTemplate.sellerSettings.required,
                };
            } else {
                return {
                    // custom_field_id: null,
                    name: customFieldInfoInTemplate.label,
                    value: fieldInPayload
                        ? fieldInPayload.type === CustomFieldType.MULTI_CHOICE
                            ? fieldInPayload.value
                            : []
                        : [],
                    type: CustomFieldType.MULTI_CHOICE,
                    is_locked: true,
                    is_negotiable: false,
                    description: customFieldInfoInTemplate.description,
                    is_visible:
                        customFieldInfoInTemplate.sellerSettings.showField,
                    is_required:
                        customFieldInfoInTemplate.sellerSettings.required,
                };
            }
        case CustomFieldType.ATTACHMENT:
            return {
                // custom_field_id: null,
                name: customFieldInfoInTemplate.label,
                value: fieldInPayload
                    ? fieldInPayload.type === CustomFieldType.ATTACHMENT
                        ? fieldInPayload.value
                        : []
                    : [],
                type: CustomFieldType.ATTACHMENT,
                is_locked: true,
                is_negotiable: false,
                description: customFieldInfoInTemplate.description,
                is_visible: customFieldInfoInTemplate.sellerSettings.showField,
                is_required: customFieldInfoInTemplate.sellerSettings.required,
            };
        case CustomFieldType.TEMPLATE:
            return {
                // custom_field_id: null,
                name: customFieldInfoInTemplate.label,
                value: fieldInPayload
                    ? fieldInPayload.type === CustomFieldType.TEMPLATE
                        ? fieldInPayload.value
                        : []
                    : [],
                type: CustomFieldType.TEMPLATE,
                is_locked: true,
                is_negotiable: false,
                description: customFieldInfoInTemplate.description,
                is_visible: customFieldInfoInTemplate.sellerSettings.showField,
                is_required: customFieldInfoInTemplate.sellerSettings.required,
            };
        default:
            return {
                // custom_field_id: null,
                name: customFieldInfoInTemplate.label,
                value: null,
                type: CustomFieldType.SHORTTEXT,
                is_locked: true,
                is_negotiable: false,
                description: customFieldInfoInTemplate.description,
                is_visible: customFieldInfoInTemplate.sellerSettings.showField,
                is_required: customFieldInfoInTemplate.sellerSettings.required,
            };
    }
};

export const validateCustomFields = (
    custom_fields: ICustomSection[],
    templateDetails: ICostingTemplate,
    newErrors: ICostingErrors,
    itemUUid: string
) => {
    let errorObj =
        itemUUid === '-1'
            ? newErrors.qoute_level.custom_fields
            : newErrors.item_level[itemUUid].custom_fields;

    custom_fields?.forEach((section) => {
        section.custom_fields.forEach((field) => {
            if (
                templateDetails?.fieldSettings?.customFields[field.name]
                    ?.sellerSettings?.required
            ) {
                if (errorObj && errorObj[field.name])
                    delete errorObj[field.name];

                if (
                    field.value === '' ||
                    isEqual(field?.value, []) ||
                    field.value === null ||
                    field.value === undefined
                ) {
                    if (errorObj !== undefined)
                        errorObj[field.name] = 'Field is required';
                    else {
                        errorObj = {
                            [field.name]: 'Field is required',
                        };
                    }
                } else {
                    if (errorObj) delete errorObj[field.name];
                }
            }
        });
    });

    if (itemUUid === '-1') {
        newErrors.qoute_level.custom_fields = errorObj;
    } else {
        newErrors.item_level[itemUUid].custom_fields = errorObj;
    }
    if (
        isEqual(newErrors.qoute_level.custom_fields, {}) ||
        newErrors.qoute_level?.custom_fields === undefined
    ) {
        delete newErrors.qoute_level.custom_fields;
    }

    if (
        itemUUid !== '-1' &&
        (isEqual(newErrors.item_level[itemUUid].custom_fields, {}) ||
            newErrors.item_level[itemUUid]?.custom_fields === undefined)
    )
        delete newErrors.item_level[itemUUid].custom_fields;
};

export const validateItemDetails = (
    item: ICostingItem | ICostingBOM,
    templateDetails: ICostingTemplate,
    newErrors: ICostingErrors,
    itemUUid: string,
    itemField: keyof ICostingItem | 'ALL' | 'NONE'
) => {
    if (!Boolean(newErrors.item_level[itemUUid])) {
        newErrors.item_level[itemUUid] = {};
    }
    if (
        (itemField === 'ALL' || itemField === 'quantity') &&
        templateDetails.fieldSettings.standardFields[
            TemplateStandardFieldsEnum.QUANTITY
        ].sellerSettings.showField
    ) {
        if (
            templateDetails.fieldSettings.standardFields[
                TemplateStandardFieldsEnum.QUANTITY
            ].sellerSettings.required &&
            !Boolean(item?.quantity)
        ) {
            newErrors.item_level[itemUUid].quantity = `Enter valid ${
                templateDetails.fieldSettings.standardFields[
                    TemplateStandardFieldsEnum.QUANTITY
                ].label
            }`;
        } else {
            delete newErrors.item_level[itemUUid].quantity;
        }
    }

    if (
        (itemField === 'ALL' || itemField === 'rate') &&
        templateDetails.fieldSettings.standardFields[
            TemplateStandardFieldsEnum.BASE_RATE
        ].sellerSettings.showField
    ) {
        if (
            templateDetails.fieldSettings.standardFields[
                TemplateStandardFieldsEnum.BASE_RATE
            ].sellerSettings.required &&
            !Boolean(+item.rate)
        ) {
            newErrors.item_level[itemUUid].rate = `Enter valid ${
                templateDetails.fieldSettings.standardFields[
                    TemplateStandardFieldsEnum.BASE_RATE
                ].label
            }`;
        } else {
            delete newErrors.item_level[itemUUid].rate;
        }
    }

    if (
        (itemField === 'ALL' || itemField === 'lead_time') &&
        templateDetails.fieldSettings.standardFields[
            TemplateStandardFieldsEnum.LEAD_TIME
        ].sellerSettings.showField
    ) {
        if (
            templateDetails.fieldSettings.standardFields[
                TemplateStandardFieldsEnum.LEAD_TIME
            ]?.sellerSettings?.required &&
            !Boolean(item.lead_time)
        ) {
            newErrors.item_level[itemUUid].lead_time =
                'Lead time input required';
        } else if (item.lead_time !== null && isNaN(+item.lead_time)) {
            newErrors.item_level[itemUUid].lead_time = 'Invalid lead time';
        } else if (
            item.lead_time_period !== '' &&
            leadTimeSurpassed(item.lead_time, item.lead_time_period)
        ) {
            newErrors.item_level[itemUUid].lead_time =
                'Lead time exceeds one year';
        } else {
            delete newErrors.item_level[itemUUid].lead_time;
        }
    }

    if (
        (itemField === 'ALL' || itemField === 'notes') &&
        templateDetails.fieldSettings.standardFields[
            TemplateStandardFieldsEnum.NOTES
        ].sellerSettings.showField
    ) {
        if (
            templateDetails.fieldSettings.standardFields[
                TemplateStandardFieldsEnum.NOTES
            ].sellerSettings.required &&
            !Boolean(item.notes)
        ) {
            newErrors.item_level[itemUUid].notes = `Notes are required`;
        } else {
            delete newErrors.item_level[itemUUid].notes;
        }
    }

    if (
        (itemField === 'ALL' || itemField === 'additional_costs') &&
        templateDetails.fieldSettings.standardFields[
            TemplateStandardFieldsEnum.ADDITIONAL_COSTS
        ].sellerSettings.showField &&
        templateDetails.fieldSettings.standardFields[
            TemplateStandardFieldsEnum.ADDITIONAL_COSTS
        ].sellerSettings.required
    ) {
        const templateOption =
            templateDetails.fieldSettings.standardFields[
                TemplateStandardFieldsEnum.ADDITIONAL_COSTS
            ].additionalCostOptions;
        if (
            item.additional_costs.length <
            templateOption.filter((opt) => opt.is_required).length
        ) {
            if (newErrors.item_level[itemUUid])
                newErrors.item_level[itemUUid].additionalCost =
                    'Field is required';
            else {
                newErrors.item_level[itemUUid] = {
                    additionalCost: 'Field is required',
                };
            }
        } else {
            if (newErrors.item_level[itemUUid])
                delete newErrors.item_level[itemUUid].additionalCost;
        }

        if (
            item.additional_costs.some(
                (localAdditionalCopy) =>
                    !Boolean(localAdditionalCopy.cost_value?.toString())
            )
        ) {
            newErrors.item_level[itemUUid].additionalCost = 'Field is required';
        } else {
            if (newErrors.item_level[itemUUid])
                delete newErrors.item_level[itemUUid].additionalCost;
        }
    }

    if (itemField === 'ALL' || itemField === 'custom_sections') {
        validateCustomFields(
            item.custom_sections,
            templateDetails,
            newErrors,
            itemUUid
        );
    }
    // if (itemField === 'ALL' || itemField === 'custom_fields_negotiate') {
    //     validateCustomFields(
    //         item.custom_fields_negotiate,
    //         templateDetails,
    //         newErrors,
    //         itemUUid
    //     );
    // }
};

export const validateCostingDetails = (
    errors: ICostingErrors,
    templateDetails: ICostingTemplate,
    costing: ICosting,
    field: keyof ICosting | 'ALL',
    itemField: keyof ICostingItem | 'ALL' | 'NONE',
    itemUUid: string,
    isSheetNameDuplicate: boolean
) => {
    let newErrors = cloneDeep(errors);
    // quote_level_data validation start
    if (field === 'ALL' || field === 'costingName') {
        if (
            templateDetails.fieldSettings.standardFields[
                TemplateStandardFieldsEnum.QUOTE_NAME
            ].sellerSettings.showField &&
            templateDetails.fieldSettings.standardFields[
                TemplateStandardFieldsEnum.QUOTE_NAME
            ].sellerSettings.required &&
            !Boolean(costing.costingName)
        ) {
            newErrors.qoute_level['quote_name'] = 'Field is required';
        } else if (isSheetNameDuplicate) {
            newErrors.qoute_level['quote_name'] = 'Duplicate Quote name';
        } else {
            delete newErrors.qoute_level['quote_name'];
        }
    }

    if (field === 'ALL' || field === 'customer_entity_id') {
        if (
            templateDetails.fieldSettings.standardFields[
                TemplateStandardFieldsEnum.CUSTOMER_NAME
            ].sellerSettings.showField &&
            templateDetails.fieldSettings.standardFields[
                TemplateStandardFieldsEnum.CUSTOMER_NAME
            ].sellerSettings.required &&
            !Boolean(costing.customer_entity_id)
        ) {
            newErrors.qoute_level['customer_name'] = 'Field is required';
        } else {
            delete newErrors.qoute_level['customer_name'];
        }
    }

    if (field === 'ALL' || field === 'deadline_datetime') {
        if (
            templateDetails.fieldSettings.standardFields[
                TemplateStandardFieldsEnum.QUOTE_DEADLINE
            ].sellerSettings.showField &&
            templateDetails.fieldSettings.standardFields[
                TemplateStandardFieldsEnum.QUOTE_DEADLINE
            ].sellerSettings.required &&
            !Boolean(costing.deadline_datetime)
        ) {
            newErrors.qoute_level['deadline_datetime'] = 'Field is required';
        } else {
            delete newErrors.qoute_level['deadline_datetime'];
        }
    }

    if (field === 'ALL' || field === 'validity_datetime') {
        if (
            templateDetails.fieldSettings.standardFields[
                TemplateStandardFieldsEnum.QUOTE_VALIDITY
            ].sellerSettings.showField &&
            templateDetails.fieldSettings.standardFields[
                TemplateStandardFieldsEnum.QUOTE_VALIDITY
            ].sellerSettings.required &&
            !Boolean(costing.validity_datetime)
        ) {
            newErrors.qoute_level['validity_datetime'] = 'Field is required';
        } else {
            delete newErrors.qoute_level['validity_datetime'];
        }
    }

    if (field === 'ALL' || field === 'custom_sections') {
        validateCustomFields(
            costing.custom_sections,
            templateDetails,
            newErrors,
            '-1'
        );
    }
    // quote_level_data validation ends

    // item_level_validation starts
    if (
        field === 'ALL' ||
        (field === 'costingData' && (itemUUid !== '-1' || itemField === 'ALL'))
    ) {
        if (itemUUid === '-1' && Boolean(itemUUid)) {
            costing.costingData.forEach((item, index) => {
                if (Boolean(item.uuid)) {
                    validateItemDetails(
                        item,
                        templateDetails,
                        newErrors,
                        item.uuid ?? '',
                        itemField
                    );
                }
                if (
                    item.uuid !== null &&
                    Boolean(item.uuid) &&
                    isEqual(newErrors.item_level[item.uuid], {})
                ) {
                    delete newErrors.item_level[item.uuid];
                }
            });
        } else {
            const item = costing.costingData.find(
                (item) => item.uuid === itemUUid
            );

            if (item && item.uuid !== '') {
                validateItemDetails(
                    item,
                    templateDetails,
                    newErrors,
                    item.uuid ?? '',
                    itemField
                );
                if (
                    item.uuid !== null &&
                    Boolean(item.uuid) &&
                    isEqual(newErrors.item_level[item.uuid], {})
                ) {
                    delete newErrors.item_level[item.uuid];
                }
            }
        }
    }
    // item_level_validation ends

    return newErrors;
};
