import {
    AllocationTypeEnum,
    CostTypeEnum,
    IAdditionalCostsBackend,
    INewAdditionalCostBackend,
} from '../../AdditionalCost/models/AdditionalCost.model';
import { INewTemplateField } from '../Containers/TemplateSections';

export const blankNewSectionData: INewTemplateField = {
    alternate_name: '',
    description: '',
    is_optional: false,
    is_visible: true,
    name: '',
    sequence: 1,
};

export type templateType =
    | 'VISIT'
    | 'RFQ'
    | 'PO GROUP'
    | 'CLM'
    | 'COST CENTRE'
    | 'REQUISITION'
    | 'ITEM CART'
    | 'BUYER'
    | 'GR'
    | 'PROJECT'
    | 'QC'
    | 'ITEM'
    | 'ITEM CART'
    | 'SRM'
    | 'VENDOR'
    | 'GL'
    | 'PROJECT'
    | 'QUOTE CALCULATOR'
    | 'ITEM CART'
    | 'BOM';

export enum TemplateItemtypesKeys {
    SHORTTEXT = 'SHORTTEXT',
    LONGTEXT = 'LONGTEXT',
    CURRENCY = 'CURRENCY',
    NUMBER = 'FLOAT',
    INTEGER = 'INTEGER',
    CHOICE = 'CHOICE',
    MULTI_SELECT = 'MULTI_SELECT',
    BOOLEAN = 'BOOLEAN',
    LOCATION = 'LOCATION',
    DATETIME = 'DATETIME',
    DATE = 'DATE',
}

export enum TemplateStatusKeys {
    DRAFT = 'DRAFT',
    ONGOING = 'ONGOING',
    DISABLED = 'DISABLED',
    REVISED = 'REVISED',
}

export enum Keys {
    FACTWISE = 'FACTWISE',
    ENTITY = 'ENTITY',
}

export type TemplateItemTypes =
    | 'PERIOD'
    | 'SHORTTEXT'
    | 'LONGTEXT'
    | 'FLOAT'
    | 'CHOICE'
    | 'CURRENCY'
    | 'MULTI_SELECT'
    | 'DROPDOWN'
    | 'BOOLEAN'
    | 'LOCATION'
    | 'DATETIME'
    | 'DATE'
    | 'PERCENTAGE'
    | 'ATTACHMENT'
    | 'TEMPLATE'
    | 'INTEGER';

export type TemplateStatus = 'DRAFT' | 'ONGOING' | 'DISABLED' | 'REVISED';

export type TemplateItemsSuggestedFrom = 'FACTWISE' | 'ENTITY';

export interface TemplateItemConstraints {
    max_limit?: number;
    min_limit?: number;
    min?: number;
    max?: number;
    choices?: string[];
    selected?: {
        choice: string;
    };
    choice_type?: 'DROPDOWN' | 'MULTI_SELECT';
    decimal_places?: number;
    field_type: TemplateItemTypes;
}
export interface TemplateData {
    template_id: string;
    template_name: string;
    linked_templates?: {
        linked_po_template_id: string;
        linked_po_action: string;
    } | null;
    auto_reminders: {
        is_remainder_required: boolean;
        initial_reminder_hours: number;
        reminder_interval: number;
        max_reminders: number;
        // default_reminder_required: boolean;
        // default_intital_reminder_hours: number;
        // default_reminder_interval: number;
        // default_max_reminders: number;
    };
    template_type: templateType;
    template_status: TemplateStatus;
    is_default: boolean;
    is_global: boolean;
    description: string;
    tags: string[];
    template_entity_id: string;
    template_entity_name: string;
    template_description: string;
    created_by: string;
    created_at: string;
    last_modified: string;
    last_modified_by: string;
    deleted_datetime: string | null;
    last_edited_by_name?: string;
    section_list?: any;
    items: {
        enterprise_item_id: string;
        name: string;
    }[];
}

export interface IPrevTemplateItem {
    name: string;
    type: string;
    created_datetime: string;
    created_by_user: string;
    modified_datetime: string;
    modified_by_user: string;
    deleted_datetime: string;
    deleted_by_user: string;
    item_type: 'ADDITIONAL_COST' | 'TAXES';
    is_requed: boolean;
    description: string;
    entity_item_id: string;
    entity: string;
    constrains: {
        decimal_places: number;
        field_type: string;
        max_limit: number;
        min_limit: number;
    };
}

export interface TemplateItem {
    section_item_id: string;
    name: string;
    field_level: 'OTHER' | 'ITEM' | 'BOM' | 'DELIVERY_SCHEDULE';
    field_used?: boolean;
    alternate_name: string;
    sequence: number;
    description: string;
    constraints: TemplateItemConstraints;
    can_remove: boolean;
    is_required: boolean;
    is_mandatory: boolean;
    is_variable?: boolean;
    is_builtin_field: boolean;
    can_split: boolean;
    send_to_po: boolean;
    additional_information: {
        is_visible?: boolean;
        is_negotiable?: boolean;
        is_hidden?: boolean;
        additional_cost_information?: Omit<
            IAdditionalCostsBackend,
            'cost_value' | 'cost_name'
        >;
        shipping_rate_information?: Omit<
            IAdditionalCostsBackend,
            'cost_value' | 'cost_name'
        >;
        taxes_information?: Omit<
            IAdditionalCostsBackend,
            'cost_value' | 'cost_name' | 'additional_cost_id'
        >;
        discount_information?: Omit<
            IAdditionalCostsBackend,
            'cost_value' | 'cost_name' | 'additional_cost_id'
        >;
        payment_from_options?: string[];
        payment_type?: string;
        allow_prepayment?: boolean;
    };
    parent_section_item: string | null;
    parent_section_item_id?: string | null; // this is used for payload
    options?: TemplateItemOption[] | null;
    additional_cost?: string;
    item_type?: 'FW' | 'OTHER';
    used_in_template?: boolean;
}

export interface NewTemplateItem {
    section_item_id: string;
    name: string;
    alternate_name: string;
    sequence: number;
    description: string;
    constraints: TemplateItemConstraints;
    can_remove: boolean;
    is_required: boolean;
    is_mandatory: boolean;
    is_builtin_field: boolean;
    send_to_po: boolean;
    can_split: boolean;
    additional_information: {
        is_visible?: boolean;
        is_negotiable?: boolean;
        is_hidden?: boolean;
        additional_cost_information?: Omit<
            INewAdditionalCostBackend,
            'cost_value' | 'cost_name'
        >;
        shipping_rate_information?: Omit<
            IAdditionalCostsBackend,
            'cost_value' | 'cost_name'
        >;
        taxes_information?: Omit<
            IAdditionalCostsBackend,
            'cost_value' | 'cost_name'
        >;
        discount_information?: Omit<
            IAdditionalCostsBackend,
            'cost_value' | 'cost_name'
        >;
    };
    parent_section_item: string | null;
    parent_section_item_id?: string | null; // this is used for payload
    options?: TemplateItemOption[] | null;
}

export interface NewTemplateSection {
    sequence: number;
    section_id: string;
    name: string;
    alternate_name: string;
    is_optional: boolean;
    section_items: NewTemplateItem[];
    template: string;
}

export interface TemplateSection {
    sequence: number;
    assigned_users?: {
        user: {
            user_id: string;
            name: string;
        };
        permission: string;
    }[];
    section_id: string;
    name: string;
    alternate_name: string;
    is_optional: boolean;
    is_builtin: boolean;
    section_items: TemplateItem[];
    template: string;
    linked_templates?: {
        linked_po_template_id: string;
        linked_po_action: string;
    };
}

export interface TemplateDetails extends TemplateData {
    template_sections: TemplateSection[];
    assigned_users?: {
        user: {
            user_id: string;
            name: string;
        };
        permission: string;
    }[];
}
export interface NewTemplateDetails extends TemplateData {
    template_sections: NewTemplateSection[];
}

export interface TemplateListObject {
    template_type: templateType;
    template_count: number;
    template_rows: TemplateData[];
}
export interface TemplateSuggestedFieldObject {
    fields_module: TemplateItemsSuggestedFrom;
    fields_count: number;
    fields_rows: TemplateItem[];
}

export interface TemplateFormDetails {
    template_id: string;
    template_name: string;
    template_type: templateType;
    description: string;
    tags: string[];
    entity_id: string;
    linked_templates?: {
        linked_po_template_id: string;
        linked_po_action: string;
    } | null;
    auto_reminders: {
        is_remainder_required: boolean;
        initial_reminder_hours: number;
        reminder_interval: number;
        max_reminders: number;
    };
    items?: {
        enterprise_item_id: string;
        name: string;
    }[];
}

export interface TemplateSectionFormDetails {
    section_id: string;
    name: string;
    alternate_name: string;
    is_visible: boolean;
    sequence: number;
    description: string;
    is_optional: boolean;
}

export interface TemplateItemFormDetails {
    section_item_id: string;
    name: string;
    alternate_name: string;
    sequence?: number;

    description: string;
    constraints: {
        field_type: TemplateItemTypes | 'COLLECTION';
        min?: number;
        max?: number;
        choices?: string[];
        decimal_places?: number;
        min_limit?: number;
        max_limit?: number;
    };
    field_level?: 'ITEM' | 'BOM' | 'OTHER' | 'DELIVERY_SCHEDULE';
    is_required: boolean;
    is_mandatory: boolean;
    is_builtin_field?: boolean;
    // send_to_po: boolean;
    // can_split: boolean;
    additional_information:
        | {
              is_visible?: boolean;
              is_negotiable?: boolean;
              optional_values?: string[];
          }
        | {
              is_hidden: boolean;
              is_visible: boolean;
              is_negotiable: boolean;

              discount_information: {
                  cost_type: CostTypeEnum;
                  allocation_type: AllocationTypeEnum | null;
              };
          };
}

export interface TemplateItemOption {
    section_item_option_id: string;
    created_datetime: string;
    modified_datetime: string;
    deleted_datetime: string;
    name: string;
    alternate_name: string;
    description: string;
    is_builtin: boolean;
    send_to_po?: boolean;
    can_split?: boolean;
    conditions: {
        name: string;
        value: string;
    };
    created_by_user: string;
    modified_by_user: string;
    deleted_by_user: string;
    section_item: string;
}
