import { isArray, isBoolean, isNumber } from 'lodash';
import { FWCurrencyTypoGraphyWithCurrencyUid } from '../../Common/CurrencyUtilsComponent';
import { getFWDate } from '../../Common/Utils/DateUtils';
import { CustomFieldType } from '../Interfaces/TemplateInterface';
import { ICurrencyDetails } from '../../Models/Currency.model';

export const isValueValidForStringArrNull = (
    value: string | string[] | number | null | boolean | undefined
) => {
    if (value === undefined) {
        return false;
    }

    if (isBoolean(value)) {
        return true;
    }
    if (value) {
        if (isBoolean(value)) {
            return true;
        } else if (isNumber(value)) {
            return true;
        } else if (
            isArray(value) &&
            value?.filter((v) => v.trim().length > 0)?.length > 0
        ) {
            return true;
        } else if (!isArray(value) && value?.trim()?.length > 0) {
            return true;
        } else {
            return false;
        }
    } else {
        return false;
    }
};

export const stringValueForStringArrNull = (
    value: string | string[] | null | boolean | number,
    // fieldName: string,
    fieldType?: CustomFieldType,
    currencyCodeAbbreviation?: string
) => {
    //

    if (value !== null && value !== undefined && value !== '-') {
        if (isBoolean(value)) {
            return value ? 'Yes' : 'No';
        } else if (isNumber(value)) {
            return value.toString();
        } else if (isArray(value)) {
            return value.filter((v) => v?.trim().length > 0).join('; ');
        } else if (isNumber(value)) {
            return value;
        } else if (
            fieldType === CustomFieldType.DATE ||
            fieldType === CustomFieldType.DATETIME
        ) {
            return getFWDate(value);
        }
        // else if (fieldType === CustomFieldType.FLOAT) {
        //     return (
        //         <FWCurrencyTypoGraphyWithCurrencyUid
        //             currencyUidOrAbbrevaition={
        //                 currencyCodeAbbreviation ?? 'USD'
        //             }
        //             currency_code_abbreviation={
        //                 currencyCodeAbbreviation ?? 'USD'
        //             }
        //             value={value}
        //         />
        //     );
        // }
        else if (value.trim().length > 0) {
            return value.trim();
        } else {
            return '-';
        }
    } else {
        return '-';
    }
};

export const stringValueForStringArrNullComponent = ({
    value,
    currencyCodeAbbreviation,
    fieldType,
}: {
    value: string | string[] | null | boolean | number;
    // fieldName: string,
    fieldType?: CustomFieldType;
    currencyCodeAbbreviation?: string;
}) => {
    if (value !== null) {
        if (isBoolean(value)) {
            return value ? 'Yes' : 'No';
        } else if (isNumber(value)) {
            return value.toString();
        } else if (isArray(value)) {
            return value?.filter((v) => v?.trim()?.length > 0).join('; ');
        } else if (
            fieldType === CustomFieldType.DATE ||
            fieldType === CustomFieldType.DATETIME
        ) {
            return getFWDate(value);
        } else if (fieldType === CustomFieldType.FLOAT) {
            return (
                <FWCurrencyTypoGraphyWithCurrencyUid
                    currencyUidOrAbbrevaition={
                        currencyCodeAbbreviation ?? 'USD'
                    }
                    currency_code_abbreviation={
                        currencyCodeAbbreviation ?? 'USD'
                    }
                    value={value}
                    hideCurrency={true}
                />
            );
        } else if (value.trim().length > 0) {
            return value.trim();
        } else {
            return '-';
        }
    } else {
        return '-';
    }
};

export const getCurrencyNameAndSymbol = (
    allCurrencies: ICurrencyDetails[] | undefined,
    currencyUid: string | null
) => {
    if (!allCurrencies || !currencyUid) return undefined;

    const currency = allCurrencies.filter(
        (currency) => currency.entry_id === currencyUid
    );

    return currency.length > 0
        ? {
              currency_abbreviation: currency[0].currency_code_abbreviation,
              currency_name: currency[0].currency_name,
              currency_symbol: currency[0].currency_symbol,
          }
        : undefined;
};

// function toTitleCase(text: string) {
//     return text.replace(
//         /\w\S*/g,
//         function (txt) {
//             return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
//         }
//     );
// }

export const toTitleCase = (text: string) => {
    return text.replace(/\w\S*/g, function (txt) {
        return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
};

export const toSentenceCase = (text: string) => {
    if (!text) return '';
    return text.charAt(0).toUpperCase() + text.slice(1).toLowerCase();
};

const isHexaCharacter = (character: any) =>
    '0123456789abcdef'.includes(character.toLowerCase());
// checks given string is valid UUID
export const isValidUUID = (input: any) => {
    // replace the hyphen with space
    input = input.replaceAll('-', '');
    // check input length is 32, and each character is hexa decimal
    return input.length === 32 && [...input].every(isHexaCharacter);
};

export const nestedDeepEqual = (obj1: any, obj2: any) => {
    // Base case: If both objects are identical, return true.
    if (obj1 === obj2) {
        return true;
    }
    // Check if both objects are objects and not null.
    if (
        typeof obj1 !== 'object' ||
        typeof obj2 !== 'object' ||
        obj1 === null ||
        obj2 === null
    ) {
        return false;
    }
    // Get the keys of both objects.
    const keys1 = Object.keys(obj1);
    const keys2 = Object.keys(obj2);
    // Check if the number of keys is the same.
    if (keys1.length !== keys2.length) {
        return false;
    }
    // Iterate through the keys and compare their values recursively.
    for (const key of keys1) {
        if (!keys2.includes(key) || !nestedDeepEqual(obj1[key], obj2[key])) {
            return false;
        }
    }
    // If all checks pass, the objects are deep equal.
    return true;
};
