import {
    AllocationTypeEnum,
    CostSourceEnum,
    CostTypeEnum,
    IAdditionalCostsBackend,
} from '../../../AdditionalCost/models/AdditionalCost.model';
import { templateType } from '../../../BuyerTemplates/Interfaces/TemplatePageInterfaces';
import {
    CustomFieldType,
    ICustomSection,
} from '../../../Global/Interfaces/TemplateInterface';
import { IEntityIdentification } from '../../../Models/AwardsAndPO.model';
import { VerificationStatus } from '../../../Redux/Slices/VerificationSlice';
import { IEntity } from './EnterpriseInterface';

export enum VendorStatus {
    ACTIVE = 'ACTIVE',
    INACTIVE = 'INACTIVE',
    INVITED = 'INVITED',
    DELETED = 'DELETED', // a status for just the FE to refer and is not used in BE
}

export enum VendorEntityStatus {
    VERIFIED = 'VERIFIED',
    UNVERIFIED = 'UNVERIFIED',
    VERIFICATION_PENDING = 'VERIFICATION PENDING',
    INVITATION_PENDING = 'INVITATION PENDING',
}

export enum VendorVerificationStatus {
    INVITATION_HOLD = 'INVITATION_HOLD',
    INVITATION_PENDING = 'INVITATION_PENDING',
    UNVERIFIED = 'UNVERIFIED',
    VERIFIED = 'VERIFIED',
    PENDING = 'PENDING',
}

export interface IVendor {
    vendorUid: string;
    vendorEnterpriseUid: string;
    vendorName: string;
    vendorStatus: VendorStatus;
    vendorVerificationStatus: VendorVerificationStatus;
    isCustom?: boolean;
    tags: string[];
    notes?: string;
}

export interface IVendorLinkSecondaryContactResponse {
    vendor_contact_id: string;
    buyer_entity: string;
    seller_entity: string;
    full_name: string;
    primary_email: string;
    emails: string[];
    phone_numbers: string[];
    tags: string[] | null;
    is_primary: boolean;
    user: string;
    status: 'ACTIVE' | 'INVITED';
}
export interface IVendorLinked {
    vendorCode: string;
    primaryContact: {
        fullName: string;
        email: string;
        contacts: string[];
        userUid: string;
        vendorContactUid: string;
        invitationStatus: string;
        notes: string;
    };
    secondaryContact: {
        fullName: string;
        emails: {
            type: string;
            email: string;
        }[];
        userUid: string;
        primary_email: string;
        vendorContactUid: string;
        invitationStatus: 'ACTIVE' | 'INVITED';
    }[];
    vendorDirUid: string;
}

export interface IVendorBuyerEntity {
    entityUid: string;
    entityName: string;
    preferredItemsCount: number;
    vendorEntitySelected: boolean;
}

export interface IVendorListSummary extends IVendor, IVendorLinked {
    index: number;
    buyerEntities: IVendorBuyerEntity[] | null;
    buyerEntitiesCount: number;
    description: string;
    modifiedDateTime: string;
    hasNext?: boolean;
}

export interface IVendorMasterRawData {
    buyer_enterprise: string;
    buyer_entity_count: number;
    enterprise_vendor_master_id: string;
    tags: string[];
    notes: string;
    primary_vendor_contact: {
        full_name: string;
        phone_numbers: string[];
    };
    seller_enterprise: string;
    status: string;
    vendor_code: string;
    vendor_name: string;
    seller_entity: {
        entity_id: string;
        entity_description: string;
        entity_name: string;
        is_virtual: boolean;
        verification_status: string;
    };
    seller_address_information: string[];
    seller_identifications: IEntityIdentification[] | null;
}

export interface IVendorAddDetails extends IVendor {
    vendorCode: string;
    notes: string;
    tags: string[];
    description: string;
    website: string;
    email: string;
    telephone: string;
    primaryContacts: {
        uid: string;
        parent: string | null;
        default: boolean;
        email: string;
        fullName: string;
        contactNumber: string;
        notes: string;
        isPresentInFW: boolean;
        selected: boolean;
        internalName: string;
        isCCEmail: boolean;
        isSecondaryEmail: boolean;
    }[];
    seller_contacts: {
        vendor_contact_id: string;
        full_name: string;
        primary_email: string;
        emails: {
            email: string;
            type: string;
        }[];
        phone_numbers: string[];
        tags: string[];
    }[];

    entities: string[];
    is_virtual: boolean;
    verification_status: VerificationStatus;
    is_new_invitation_row: boolean;
    vendor_internal_name: string | null;
    seller_address_information: string[] | null;
    seller_identifications:
        | {
              identification_name: string;
              identification_value: string;
          }[]
        | null;
    custom_sections: ICustomSection[];
}

export interface IItemTemplateCustomField {
    type:
        | 'DATE'
        | 'BOOLEAN'
        | 'DATETIME'
        | 'INTEGER'
        | 'FLOAT'
        | 'SHORTTEXT'
        | 'LONGTEXT'
        | 'PERCENTAGE'
        | 'LONGTEXT'
        | 'MULTI_CHOICE'
        | 'CHOICE';
    is_required: boolean;
    is_locked: boolean;
    description: string;
    name: string;
    value: string | boolean | null | string[] | number;
}

export interface IItemCustomFieldsBackend {
    section_list: {
        name: string;
        fields: IItemTemplateCustomField[];
    }[];
}

export interface IVendorDirDetails extends IVendor, IVendorLinked {
    notes: string;
    contactsList: {
        fullName: string;
        email: string;
        phone: string;
        invited: boolean;
    }[];
    tags: string[];
    custom_sections: ICustomSection[];
    additional_costs: IAdditionalCostsBackend[];
    seller_address_information: string[] | null;
    seller_identifications:
        | {
              identification_name: string;
              identification_value: string;
          }[]
        | null;
}

export enum PreferredItemStatus {
    STANDARD = 'STANDARD',
    PREFERRED = 'PREFERRED',
}

export interface IPreferredItem {
    itemName: string;
    itemId: string;
    itemUid: string;
    itemStatus: PreferredItemStatus;
}

export interface IEntityVendorListSummary extends IVendor, IVendorLinked {
    preferredItemsCount: number;
    modifiedDateTime: string;
}

export interface IVendorEmail {
    email: string;
    visible: boolean;
}

export interface IVendorPhone {
    number: string;
    visible: boolean;
}

export interface IVendorContact {
    index?: number;
    fullName: string;
    sellerEmails: IVendorEmail[];
    buyerEmails: IVendorEmail[];
    sellerContacts: IVendorPhone[];
    buyerContacts: IVendorPhone[];
    userUid: string;
    vendorContactUid: string;
    isPrimary: boolean;
    invitationStatus: string;
    notes: string;
    status?: 'ACTIVE' | 'INVITED';
    tags: string[];
    CCEmail: string[];
    secondaryEmails: string[];
    role?: string;
}

export interface IVendorContactAdd {
    fullName: string;
    email: string;
    buyerContacts: IVendorPhone[];
    isPrimary: boolean;
    CCEmails: string[];
    secondaryEmails: string[];
}

export interface IEntityVendorLink extends IEntity {
    linked: boolean;
}

export enum VendorTemplateStandardFieldEnum {
    VENDOR_CODE = 'VENDOR_CODE',
    VENDOR_NAME = 'VENDOR_NAME',
    VENDOR_ADDRESS = 'VENDOR_ADDRESS',
    VENDOR_IDENTIFICATION_VALUE = 'VENDOR_IDENTIFICATION_VALUE',
    VENDOR_IDENTIFICATION_NAME = 'VENDOR_IDENTIFICATION_NAME',
    VENDOR_IDENTIFICATION = 'VENDOR_IDENTIFICATION',
    PRIMARY_CONTACT_PHONE_NUMBER = 'PRIMARY_CONTACT_PHONE_NUMBER',
    PRIMARY_CONTACT_EMAIL = 'PRIMARY_CONTACT_EMAIL',
    PRIMARY_CONTACT_SECONDARY_EMAIL = 'PRIMARY_CONTACT_SECONDARY_EMAIL',
    PRIMARY_CONTACT_CC_EMAIL = 'PRIMARY_CONTACT_CC_EMAIL',
    PRIMARY_CONTACT = 'PRIMARY_CONTACT',
    PRIMARY_CONTACT_NOTES = 'PRIMARY_CONTACT_NOTES',
    PRIMARY_CONTACT_FULL_NAME = 'PRIMARY_CONTACT_FULL_NAME',
    SECONDARY_CONTACT = 'SECONDARY_CONTACT',
    SECONDARY_CONTACT_FULL_NAME = 'SECONDARY_CONTACT_FULL_NAME',
    SECONDARY_CONTACT_EMAIL = 'SECONDARY_CONTACT_EMAIL',
    SECONDARY_CONTACT_PHONE_NUMBER = 'SECONDARY_CONTACT_PHONE_NUMBER',
    SECONDARY_CONTACT_SECONDARY_EMAIL = 'SECONDARY_CONTACT_SECONDARY_EMAIL',
    SECONDARY_CONTACT_CC_EMAIL = 'SECONDARY_CONTACT_CC_EMAIL',
    SECONDARY_CONTACT_NOTES = 'SECONDARY_CONTACT_NOTES',
    ADDITIONAL_COSTS = 'ADDITIONAL_COSTS',
    TAG = 'TAG',
    NOTES = 'NOTES',
}
export const VendorTemplateStandardFieldBackendNames: {
    [BackendTemplateItemName: string]: VendorTemplateStandardFieldEnum;
} = {
    'Vendor code': VendorTemplateStandardFieldEnum.VENDOR_CODE,
    'Vendor name': VendorTemplateStandardFieldEnum.VENDOR_NAME,
    'Vendor addresses': VendorTemplateStandardFieldEnum.VENDOR_ADDRESS,
    'Vendor identification value':
        VendorTemplateStandardFieldEnum.VENDOR_IDENTIFICATION_VALUE,
    'Vendor identification':
        VendorTemplateStandardFieldEnum.VENDOR_IDENTIFICATION,
    'Vendor identification name':
        VendorTemplateStandardFieldEnum.VENDOR_IDENTIFICATION_NAME,
    'Primary contact phone number':
        VendorTemplateStandardFieldEnum.PRIMARY_CONTACT_PHONE_NUMBER,
    'Primary contact': VendorTemplateStandardFieldEnum.PRIMARY_CONTACT,
    'Primary contact full name':
        VendorTemplateStandardFieldEnum.PRIMARY_CONTACT_FULL_NAME,
    'Primary contact email':
        VendorTemplateStandardFieldEnum.PRIMARY_CONTACT_EMAIL,
    'Primary contact secondary email':
        VendorTemplateStandardFieldEnum.PRIMARY_CONTACT_SECONDARY_EMAIL,
    'Primary contact cc email':
        VendorTemplateStandardFieldEnum.PRIMARY_CONTACT_CC_EMAIL,
    'Primary contact notes':
        VendorTemplateStandardFieldEnum.PRIMARY_CONTACT_NOTES,
    'Secondary contact': VendorTemplateStandardFieldEnum.SECONDARY_CONTACT,
    'Secondary contact full name':
        VendorTemplateStandardFieldEnum.SECONDARY_CONTACT_FULL_NAME,
    'Secondary contact email':
        VendorTemplateStandardFieldEnum.SECONDARY_CONTACT_EMAIL,
    'Secondary contact phone number':
        VendorTemplateStandardFieldEnum.SECONDARY_CONTACT_PHONE_NUMBER,
    'Secondary contact secondary email':
        VendorTemplateStandardFieldEnum.SECONDARY_CONTACT_SECONDARY_EMAIL,
    'Secondary contact cc email':
        VendorTemplateStandardFieldEnum.SECONDARY_CONTACT_CC_EMAIL,
    'Secondary contact notes':
        VendorTemplateStandardFieldEnum.SECONDARY_CONTACT_NOTES,
    'Additional costs': VendorTemplateStandardFieldEnum.ADDITIONAL_COSTS,
    Notes: VendorTemplateStandardFieldEnum.NOTES,
    Tag: VendorTemplateStandardFieldEnum.TAG,
};

export type IVendorTemplateStandardFieldOptionsBase = {
    label: string;
    description: string;
    constraints: {
        max_limit?: number;
        min_limit?: number;
        decimal_places?: number;
        field_type: string;
        choices?: string[];
        choice_type?: 'DROPDOWN' | 'MULTI_SELECT';
    };
    is_required: boolean;
    is_builtin_field: boolean;
    is_mandatory: boolean;
    additional_information: {
        is_hidden: boolean;
    };
};

export type IVendorTemplateStandardFieldOptions =
    IVendorTemplateStandardFieldOptionsBase & {
        standardFieldCategory: 'STANDARD';
    };

export interface IVendorTemplateCustomFieldOptions
    extends IVendorTemplateStandardFieldOptionsBase {
    type: CustomFieldType;
}

export interface IVendorTemplateAdditionalCostOptions
    extends IVendorTemplateStandardFieldOptionsBase {
    additional_cost_information: {
        cost_type: CostTypeEnum;
        allocation_type: AllocationTypeEnum | null;
        additional_cost_id: string;
        cost_source: CostSourceEnum | null;
    };
}

export type VendorTemplateStandardFieldSettingObj = {
    [VendorTemplateStandardFieldEnum.VENDOR_NAME]: IVendorTemplateStandardFieldOptions;
    [VendorTemplateStandardFieldEnum.VENDOR_CODE]: IVendorTemplateStandardFieldOptions;
    [VendorTemplateStandardFieldEnum.VENDOR_ADDRESS]: IVendorTemplateStandardFieldOptions;
    [VendorTemplateStandardFieldEnum.VENDOR_IDENTIFICATION]: IVendorTemplateStandardFieldOptions;
    [VendorTemplateStandardFieldEnum.VENDOR_IDENTIFICATION_NAME]: IVendorTemplateStandardFieldOptions;
    [VendorTemplateStandardFieldEnum.VENDOR_IDENTIFICATION_VALUE]: IVendorTemplateStandardFieldOptions;
    [VendorTemplateStandardFieldEnum.PRIMARY_CONTACT]: IVendorTemplateStandardFieldOptions;
    [VendorTemplateStandardFieldEnum.PRIMARY_CONTACT_EMAIL]: IVendorTemplateStandardFieldOptions;
    [VendorTemplateStandardFieldEnum.PRIMARY_CONTACT_FULL_NAME]: IVendorTemplateStandardFieldOptions;
    [VendorTemplateStandardFieldEnum.PRIMARY_CONTACT_NOTES]: IVendorTemplateStandardFieldOptions;
    [VendorTemplateStandardFieldEnum.PRIMARY_CONTACT_PHONE_NUMBER]: IVendorTemplateStandardFieldOptions;
    [VendorTemplateStandardFieldEnum.PRIMARY_CONTACT_SECONDARY_EMAIL]: IVendorTemplateStandardFieldOptions;
    [VendorTemplateStandardFieldEnum.PRIMARY_CONTACT_CC_EMAIL]: IVendorTemplateStandardFieldOptions;
    [VendorTemplateStandardFieldEnum.SECONDARY_CONTACT]: IVendorTemplateStandardFieldOptions;
    [VendorTemplateStandardFieldEnum.SECONDARY_CONTACT_EMAIL]: IVendorTemplateStandardFieldOptions;
    [VendorTemplateStandardFieldEnum.SECONDARY_CONTACT_FULL_NAME]: IVendorTemplateStandardFieldOptions;
    [VendorTemplateStandardFieldEnum.SECONDARY_CONTACT_NOTES]: IVendorTemplateStandardFieldOptions;
    [VendorTemplateStandardFieldEnum.SECONDARY_CONTACT_PHONE_NUMBER]: IVendorTemplateStandardFieldOptions;
    [VendorTemplateStandardFieldEnum.SECONDARY_CONTACT_SECONDARY_EMAIL]: IVendorTemplateStandardFieldOptions;
    [VendorTemplateStandardFieldEnum.SECONDARY_CONTACT_CC_EMAIL]: IVendorTemplateStandardFieldOptions;
    [VendorTemplateStandardFieldEnum.TAG]: IVendorTemplateStandardFieldOptions;
    [VendorTemplateStandardFieldEnum.NOTES]: IVendorTemplateStandardFieldOptions;
    [VendorTemplateStandardFieldEnum.ADDITIONAL_COSTS]: IVendorTemplateStandardFieldOptions;
};

export interface IRFQTemplateFieldSetting {
    standardFields: VendorTemplateStandardFieldSettingObj;
    customFields: {
        [field: string]: IVendorTemplateCustomFieldOptions;
    };
    additionalCosts: {
        [field: string]: IVendorTemplateAdditionalCostOptions;
    };
}

export enum VendorStandardSectionsEnum {
    VENDOR_DETAILS = 'VENDOR_DETAILS',
}

export interface IVendorTemplateSectionDetails {
    label: string;
    fieldList: ISectionField[];
}

export type ISectionField =
    | {
          fieldType: 'STANDARD';
          field: VendorTemplateStandardFieldEnum;
      }
    | {
          fieldType: 'CUSTOM';
          field: string;
      }
    | {
          fieldType: 'ADDITIONAL_COST';
          field: string;
      };

export type IVendorTemplateSections = {
    [section in VendorStandardSectionsEnum]: IVendorTemplateSectionDetails;
};

export interface IVendorTemplateInfo {
    type: templateType;
    count: number;
    templates: {
        template_id: string;
        type: templateType;
        name: string;
        tags: string[];
        status: 'DRAFT' | 'ONGOING';
        published_on: string | null;
        description: string;
        is_global: boolean;
        is_default: boolean;
        created_on: string;
        creator_name: string;
        last_edited_on: string;
        last_edited_by_name: string;
        entity_id: string;
        entity_name: string;
    }[];
}

export interface IVendorSectionItem {
    section_item_id: string;
    options: any;
    created_datetime: string;
    modified_datetime: string;
    deleted_datetime: string | null;
    sequence: number;
    name: string;
    alternate_name: string;
    description: string;
    constraints: {
        max_limit: number;
        min_limit: number;
        choices: string[];
        choice_type: 'DROPDOWN' | 'MULTI_SELECT';
        field_type: string;
    };
    can_remove: boolean;
    is_builtin_field: boolean;
    is_required: boolean;
    is_variable: boolean;
    is_mandatory: boolean;
    additional_information: {
        is_hidden: boolean;
        additional_cost_information: {
            cost_type: CostTypeEnum;
            allocation_type: AllocationTypeEnum | null;
            additional_cost_id: string;
            cost_source: CostSourceEnum | null;
        };
    };
    created_by_user: string;
    modified_by_user: string;
    deleted_by_user: string | null;
    section: string;
    parent_section_item: string | null;
}

export interface IVendorTemplateBackend {
    template_id: string;
    section_list: {
        section_id: string;
        section_items: IVendorSectionItem[];
        created_datetime: string;
        modified_datetime: string;
        deleted_datetime: string | null;
        name: string;
        alternate_name: string;
        section_type: string;
        description: string;
        sequence: number;
        is_visible: boolean;
        is_optional: boolean;
        created_by_user: string;
        modified_by_user: string;
        deleted_by_user: string | null;
        template: string;
    }[];
    entity: {
        entity_name: string;
        entity_id: string;
    };
    last_edited_by_name: string;
    created_datetime: string;
    modified_datetime: string;
    deleted_datetime: string | null;
    name: string;
    type: string;
    description: string;
    tags: [];
    is_default: boolean;
    is_global: boolean;
    status: string;
    published_on: string | null;
    created_on: string;
    last_edited_on: string;
    created_by_user: string;
    modified_by_user: string;
    deleted_by_user: string | null;
    enterprise: string;
    creator: string;
    last_edited_by: string;
}

export interface IVendorTemplateFieldSetting {
    standardFields: VendorTemplateStandardFieldSettingObj;
    customFields: {
        [field: string]: IVendorTemplateCustomFieldOptions;
    };
    additionalCosts: {
        [field: string]: IVendorTemplateAdditionalCostOptions;
    };
}
export interface IVendorTemplateFrontEnd {
    templateId: string;
    templateName: string;
    fieldSettings: IVendorTemplateFieldSetting;
    sections: IVendorTemplateSections;
}
