import { cloneDeep } from 'lodash';
import {
    // CustomTemplateItemTypes,
    ICustomTemplateList,
} from '../../Global/Interfaces/CustomTemplate.interface';
import {
    CustomFieldType,
    ICustomField,
    ICustomSection,
    IRfqTemplate,
} from '../../Global/Interfaces/TemplateInterface';
import {
    // IEventItemCustomField,
    IEventItemCustomFieldSections,
    IEventItemCustomFieldsBackend,
    custom_field_backend_section_names,
} from '../Interfaces/Buyer/RFQ.model';
import { IBomTemplate } from '../../BOM/Admin/inerfaces/IBomTemplateInterface';

export const processAdminCustomFieldsResp = (
    adminTemplatesResp: ICustomTemplateList[]
): IEventItemCustomFieldSections => {
    let processedData: IEventItemCustomFieldSections = {
        existingSections: {
            essential_terms: [],
            payment_and_delivery_terms: [],
            additional_details: [],
        },
        newSections: {},
    };

    for (let template of adminTemplatesResp) {
        let fieldList: ICustomField[] = [];
        for (let field of template.items) {
            let processedField: ICustomField = {
                name: field.template_item_name,
                description: '',
                type: field.template_item_type,
                value: '',
                is_required: field.is_required,
                is_visible: field.additional_information?.is_visible ?? true,
                is_locked: true,
                is_negotiable:
                    field.additional_information?.is_negotiable ?? false,
            };
            fieldList.push(processedField);
        }
        switch (template.template_name) {
            case custom_field_backend_section_names.payment_and_delivery_terms_template_name:
                processedData.existingSections.payment_and_delivery_terms =
                    fieldList;
                break;
            case custom_field_backend_section_names.additional_information_template_name:
                processedData.existingSections.additional_details = fieldList;
                break;
            default:
                // processedData.newSections[template.template_name] = fieldList;
                break;
        }
    }
    return processedData;
};

export const convertTemplateTypeToCustomFieldItemTemplate = (
    type: CustomFieldType,
    choiceType?: 'DROPDOWN' | 'MULTI_SELECT',
    allowDatetime?: boolean
) => {
    switch (type) {
        case CustomFieldType.BOOLEAN:
            return CustomFieldType.BOOLEAN;
        case CustomFieldType.CHOICE:
            if (choiceType === 'MULTI_SELECT') {
                return CustomFieldType.MULTI_CHOICE;
            }
            return CustomFieldType.CHOICE;
        case CustomFieldType.DATE:
            return CustomFieldType.DATE;
        case CustomFieldType.DATETIME:
            return allowDatetime
                ? CustomFieldType.DATETIME
                : CustomFieldType.DATE;
        case CustomFieldType.DECIMAL:
            return CustomFieldType.FLOAT;
        case CustomFieldType.FLOAT:
            return CustomFieldType.FLOAT;
        case CustomFieldType.INTEGER:
            return CustomFieldType.INTEGER;
        case CustomFieldType.LONG_TEXT:
            return CustomFieldType.LONGTEXT;
        case CustomFieldType.SHORT_TEXT:
            return CustomFieldType.SHORTTEXT;

        default:
            return CustomFieldType.SHORTTEXT;
    }
};

export const processTemplateCustomFieldsResp = (
    templatesResp: IRfqTemplate
): IEventItemCustomFieldSections => {
    let processedData: IEventItemCustomFieldSections = {
        existingSections: {
            essential_terms: [],
            payment_and_delivery_terms: [],
            additional_details: [],
        },
        newSections: {},
    };

    templatesResp.sections.ESSENTIAL_TERMS.fieldList.forEach((field) => {
        if (field.fieldType === 'STANDARD') {
            // processedData.existingSections.essential_terms.push({
            //     is_locked: true,
            //     is_negotiable:
            //         templatesResp.fieldSettings.standardFields[field.field]
            //             .sellerSettings.negotiable,
            //     is_required:
            //         templatesResp.fieldSettings.standardFields[field.field]
            //             .buyerSettings.required,
            //     is_visible:
            //         templatesResp.fieldSettings.standardFields[field.field]
            //             .buyerSettings.showField,
            //     name: field.field,
            //     type: CustomTemplateItemTypes.LONGTEXT,
            //     value: '',
            // });
        } else {
            let choiceType: 'DROPDOWN' | 'MULTI_SELECT' | undefined;
            const templateConstraint =
                templatesResp.fieldSettings.customFields[field.field]
                    .constraints;
            if (templateConstraint.fieldType === 'CHOICE') {
                choiceType = templateConstraint.choiceType;
            }

            processedData.existingSections.essential_terms.push({
                is_locked: true,
                is_negotiable:
                    templatesResp.fieldSettings.customFields[field.field]
                        .sellerSettings.negotiable,
                is_required:
                    templatesResp.fieldSettings.customFields[field.field]
                        .buyerSettings.required,
                is_visible:
                    templatesResp.fieldSettings.customFields[field.field]
                        .sellerSettings.showField,
                name: field.field,
                // type: templatesResp.fieldSettings.customFields[field.field]
                //     .type as CustomTemplateItemTypes.SHORTTEXT,
                description:
                    templatesResp.fieldSettings.customFields[field.field]
                        .description ?? '',
                type: convertTemplateTypeToCustomFieldItemTemplate(
                    templatesResp.fieldSettings.customFields[field.field].type,
                    choiceType
                ),
                value: '',
            });
        }
    });

    templatesResp.sections.PAYMENT_AND_DELIVERY_TERMS.fieldList.forEach(
        (field) => {
            if (field.fieldType === 'STANDARD') {
                // processedData.existingSections.payment_and_delivery_terms.push({
                //     is_locked: true,
                //     is_negotiable:
                //         templatesResp.fieldSettings.standardFields[field.field]
                //             .sellerSettings.negotiable,
                //     is_required:
                //         templatesResp.fieldSettings.standardFields[field.field]
                //             .buyerSettings.required,
                //     is_visible:
                //         templatesResp.fieldSettings.standardFields[field.field]
                //             .buyerSettings.showField,
                //     name: field.field,
                //     type: CustomTemplateItemTypes.LONGTEXT,
                //     value: '',
                // });
            } else {
                let choiceType: 'DROPDOWN' | 'MULTI_SELECT' | undefined;
                const templateConstraint =
                    templatesResp.fieldSettings.customFields[field.field]
                        .constraints;
                if (templateConstraint.fieldType === 'CHOICE') {
                    choiceType = templateConstraint.choiceType;
                }
                processedData.existingSections.payment_and_delivery_terms.push({
                    is_locked: true,
                    is_negotiable:
                        templatesResp.fieldSettings.customFields[field.field]
                            .sellerSettings.negotiable,
                    is_required:
                        templatesResp.fieldSettings.customFields[field.field]
                            .buyerSettings.required,
                    is_visible:
                        templatesResp.fieldSettings.customFields[field.field]
                            .sellerSettings.showField,
                    name: field.field,
                    description:
                        templatesResp.fieldSettings.customFields[field.field]
                            .description ?? '',
                    // type: templatesResp.fieldSettings.customFields[field.field]
                    //     .type as CustomTemplateItemTypes.SHORTTEXT,
                    type: convertTemplateTypeToCustomFieldItemTemplate(
                        templatesResp.fieldSettings.customFields[field.field]
                            .type,
                        choiceType
                    ),
                    value: '',
                });
            }
        }
    );

    templatesResp.sections.ADDITIONAL_DETAILS.fieldList.forEach((field) => {
        if (field.fieldType === 'STANDARD') {
            // processedData.existingSections.additional_details.push({
            //     is_locked: true,
            //     is_negotiable:
            //         templatesResp.fieldSettings.standardFields[field.field]
            //             .sellerSettings.negotiable,
            //     is_required:
            //         templatesResp.fieldSettings.standardFields[field.field]
            //             .buyerSettings.required,
            //     is_visible:
            //         templatesResp.fieldSettings.standardFields[field.field]
            //             .buyerSettings.showField,
            //     name: field.field,
            //     type: CustomTemplateItemTypes.LONGTEXT,
            //     value: '',
            // });
        } else {
            let choiceType: 'DROPDOWN' | 'MULTI_SELECT' | undefined;
            const templateConstraint =
                templatesResp.fieldSettings.customFields[field.field]
                    .constraints;
            if (templateConstraint.fieldType === 'CHOICE') {
                choiceType = templateConstraint.choiceType;
            }
            processedData.existingSections.additional_details.push({
                is_locked: true,
                is_negotiable:
                    templatesResp.fieldSettings.customFields[field.field]
                        .sellerSettings.negotiable,
                is_required:
                    templatesResp.fieldSettings.customFields[field.field]
                        .buyerSettings.required,
                is_visible:
                    templatesResp.fieldSettings.customFields[field.field]
                        .sellerSettings.showField,
                name: field.field,
                description:
                    templatesResp.fieldSettings.customFields[field.field]
                        .description ?? '',
                // type: templatesResp.fieldSettings.customFields[field.field]
                //     .type as CustomTemplateItemTypes.SHORTTEXT,
                type: convertTemplateTypeToCustomFieldItemTemplate(
                    templatesResp.fieldSettings.customFields[field.field].type,
                    choiceType
                ),
                value: '',
            });
        }
    });

    //

    return processedData;
};

export const translateBackendCustomFieldSectionsToFrontEnd = (
    backendCustomFields: ICustomSection[],
    templateDetails: IRfqTemplate
): IEventItemCustomFieldSections => {
    let processedData: IEventItemCustomFieldSections = {
        existingSections: {},
        newSections: {},
    };
    if (backendCustomFields) {
        for (let section of backendCustomFields) {
            if (section.name === 'item_terms') {
                section.name =
                    custom_field_backend_section_names.essential_terms_template_name;
            }
            if (
                section.name ===
                    templateDetails.sections.ADDITIONAL_DETAILS.label ||
                section.name ===
                    templateDetails.sections.PAYMENT_AND_DELIVERY_TERMS.label ||
                section.name === templateDetails.sections.ESSENTIAL_TERMS.label
            ) {
                // processedData.push[
                //     section.name as
                //         | 'payment_and_delivery_terms'
                //         | 'additional_details'
                //         | 'essential_terms'
                // ] = section.custom_fields.map((field) => ({
                //     ...field,
                //     is_negotiable: false,
                // }));
            } else {
                // processedData.newSections[
                //     section.name as
                //         | 'payment_and_delivery_terms'
                //         | 'additional_details'
                //         | 'essential_terms'
                // ] = section.custom_fields.map((field) => ({
                //     ...field,
                //     is_negotiable: false,
                // }));
            }
        }
    }
    return processedData;
};

export const translateFrontEndCustomFieldSectionsToBackend = (
    frontendCustomFields: IEventItemCustomFieldSections,
    negotiableValue?: 'NEGOTIABLE' | 'NON_NEGOTIABLE'
): IEventItemCustomFieldsBackend => {
    let processedData: IEventItemCustomFieldsBackend = {
        section_list: [],
    };

    // for (let sectionName in frontendCustomFields.newSections) {
    //     let section = {
    //         name: sectionName,
    //         fields: cloneDeep(frontendCustomFields.newSections[sectionName]),
    //     };
    //     processedData.section_list.push(section);
    // }
    let section_payment_and_delivery_terms = {
        name: custom_field_backend_section_names.payment_and_delivery_terms_template_name,
        fields: frontendCustomFields.existingSections
            .payment_and_delivery_terms,
    };
    processedData.section_list.push(section_payment_and_delivery_terms);

    let section_additional_details = {
        name: custom_field_backend_section_names.additional_information_template_name,
        fields: frontendCustomFields.existingSections.additional_details,
    };
    processedData.section_list.push(section_additional_details);
    processedData.section_list = processedData.section_list.filter(
        (section) => section.fields.length > 0
    );

    let section_essential_details = {
        // name: custom_field_backend_section_names.essential_terms_template_name,
        name: 'item_terms',
        fields: frontendCustomFields.existingSections.essential_terms,
    };

    processedData.section_list.push(section_essential_details);
    processedData.section_list = processedData.section_list.filter(
        (section) => section.fields.length > 0
    );

    if (negotiableValue === 'NEGOTIABLE') {
    }
    if (negotiableValue) {
        processedData.section_list.forEach(
            (section) =>
                (section.fields = section.fields.filter(
                    (field) =>
                        field.is_negotiable ===
                        (negotiableValue === 'NEGOTIABLE')
                ))
        );
    }

    processedData.section_list = processedData.section_list.filter(
        (section) => section.fields.length > 0
    );

    return processedData;
};

export const addNonExistingFieldInCustomFieldBasedOntemplate = (
    existingCustomFields: ICustomSection[],
    templateDetails: IRfqTemplate,
    negotiableValueFields: boolean
) => {
    const copyOfexistingCustomFields = cloneDeep(existingCustomFields);

    // item_terms
    const listOfCustomFieldsInEssentialTerms =
        templateDetails.sections.ESSENTIAL_TERMS.fieldList.filter((field) => {
            // if (field.fieldType === 'CUSTOM') {
            //     return (
            //         negotiableValueFields ===
            //         templateDetails.fieldSettings.customFields[field.field]
            //             .sellerSettings.negotiable
            //     );
            // }
            // return false;
            return true;
        });

    const sectionIndexOfEssentialTermsWrongOne =
        copyOfexistingCustomFields.findIndex(
            (section) =>
                section.name === templateDetails.sections.ESSENTIAL_TERMS.label
        );

    if (sectionIndexOfEssentialTermsWrongOne > -1)
        copyOfexistingCustomFields.splice(
            sectionIndexOfEssentialTermsWrongOne,
            1
        );

    let sectionIndexOfEssentialTerms = copyOfexistingCustomFields.findIndex(
        (section) =>
            section.name === templateDetails.sections.ESSENTIAL_TERMS.label
    );

    if (sectionIndexOfEssentialTerms === -1) {
        copyOfexistingCustomFields.push({
            custom_fields: [],
            name: templateDetails.sections.ESSENTIAL_TERMS.label,
            section_type: 'ITEM',
        });
        sectionIndexOfEssentialTerms = copyOfexistingCustomFields.findIndex(
            (section) =>
                section.name === templateDetails.sections.ESSENTIAL_TERMS.label
        );
    } else {
        copyOfexistingCustomFields[sectionIndexOfEssentialTerms].name =
            templateDetails.sections.ESSENTIAL_TERMS.label;
    }

    for (let fieldInTemplate of listOfCustomFieldsInEssentialTerms) {
        const foundFieldIndex = copyOfexistingCustomFields[
            sectionIndexOfEssentialTerms
        ].custom_fields.findIndex(
            (field) =>
                field.name.toLowerCase() === fieldInTemplate.field.toLowerCase()
        );
        const fieldSettingFromTemplate =
            templateDetails.fieldSettings.customFields[fieldInTemplate.field];
        if (fieldSettingFromTemplate)
            if (
                foundFieldIndex === -1 &&
                templateDetails.fieldSettings.customFields[
                    fieldInTemplate.field
                ]
            ) {
                copyOfexistingCustomFields[
                    sectionIndexOfEssentialTerms
                ].custom_fields.push({
                    description: fieldSettingFromTemplate.description,
                    type:
                        fieldSettingFromTemplate.constraints.fieldType ===
                            CustomFieldType.CHOICE &&
                        fieldSettingFromTemplate.constraints.choiceType ===
                            'MULTI_SELECT'
                            ? CustomFieldType.MULTI_CHOICE
                            : fieldSettingFromTemplate.type,
                    is_locked: true,
                    is_negotiable:
                        fieldSettingFromTemplate.sellerSettings.negotiable,
                    is_required:
                        fieldSettingFromTemplate.buyerSettings.required,
                    is_visible:
                        fieldSettingFromTemplate.sellerSettings.showField,
                    name: fieldSettingFromTemplate.label,
                    value:
                        fieldSettingFromTemplate.constraints.fieldType ===
                            CustomFieldType.CHOICE &&
                        fieldSettingFromTemplate.constraints.choiceType ===
                            'MULTI_SELECT'
                            ? []
                            : null,
                });
            } else {
                copyOfexistingCustomFields[
                    sectionIndexOfEssentialTerms
                ].custom_fields[foundFieldIndex].is_locked = true;
                copyOfexistingCustomFields[
                    sectionIndexOfEssentialTerms
                ].custom_fields[foundFieldIndex].is_negotiable =
                    fieldSettingFromTemplate.sellerSettings.negotiable;
                copyOfexistingCustomFields[
                    sectionIndexOfEssentialTerms
                ].custom_fields[foundFieldIndex].is_required =
                    fieldSettingFromTemplate.buyerSettings.required;
                copyOfexistingCustomFields[
                    sectionIndexOfEssentialTerms
                ].custom_fields[foundFieldIndex].is_visible =
                    fieldSettingFromTemplate.sellerSettings.showField;
                copyOfexistingCustomFields[
                    sectionIndexOfEssentialTerms
                ].custom_fields[foundFieldIndex].description =
                    fieldSettingFromTemplate.description;
            }
    }

    // payment_and_delivery_terms
    const listOfCustomFieldsInPaymentAndDeliveryTerms =
        templateDetails.sections.PAYMENT_AND_DELIVERY_TERMS.fieldList.filter(
            (field) => {
                // if (field.fieldType === 'CUSTOM') {
                //     return (
                //         negotiableValueFields ===
                //         templateDetails.fieldSettings.customFields[field.field]
                //             .sellerSettings.negotiable
                //     );
                // }
                // return false;
                return true;
            }
        );
    let sectionIndexOfPaymentAndDeliveryTerms =
        copyOfexistingCustomFields.findIndex(
            (section) =>
                section.name ===
                templateDetails.sections.PAYMENT_AND_DELIVERY_TERMS.label
        );
    if (sectionIndexOfPaymentAndDeliveryTerms === -1) {
        copyOfexistingCustomFields.push({
            custom_fields: [],
            name: templateDetails.sections.PAYMENT_AND_DELIVERY_TERMS.label,
            section_type: 'ITEM',
        });
        sectionIndexOfPaymentAndDeliveryTerms =
            copyOfexistingCustomFields.findIndex(
                (section) =>
                    section.name ===
                    templateDetails.sections.PAYMENT_AND_DELIVERY_TERMS.label
            );
    }

    for (let fieldInTemplate of listOfCustomFieldsInPaymentAndDeliveryTerms) {
        const foundFieldIndex = copyOfexistingCustomFields[
            sectionIndexOfPaymentAndDeliveryTerms
        ].custom_fields.findIndex(
            (field) =>
                field.name.toLowerCase() === fieldInTemplate.field.toLowerCase()
        );
        const fieldSettingFromTemplate =
            templateDetails.fieldSettings.customFields[fieldInTemplate.field];
        if (fieldSettingFromTemplate)
            if (
                foundFieldIndex === -1 &&
                templateDetails.fieldSettings.customFields[
                    fieldInTemplate.field
                ]
            ) {
                copyOfexistingCustomFields[
                    sectionIndexOfPaymentAndDeliveryTerms
                ].custom_fields.push({
                    description: fieldSettingFromTemplate.description,
                    type:
                        fieldSettingFromTemplate.constraints.fieldType ===
                            CustomFieldType.CHOICE &&
                        fieldSettingFromTemplate.constraints.choiceType ===
                            'MULTI_SELECT'
                            ? CustomFieldType.MULTI_CHOICE
                            : fieldSettingFromTemplate.type,
                    is_locked: true,
                    is_negotiable:
                        fieldSettingFromTemplate.sellerSettings.negotiable,
                    is_required:
                        fieldSettingFromTemplate.buyerSettings.required,
                    is_visible:
                        fieldSettingFromTemplate.sellerSettings.showField,
                    name: fieldSettingFromTemplate.label,
                    value:
                        fieldSettingFromTemplate.constraints.fieldType ===
                            CustomFieldType.CHOICE &&
                        fieldSettingFromTemplate.constraints.choiceType ===
                            'MULTI_SELECT'
                            ? []
                            : null,
                });
            } else {
                copyOfexistingCustomFields[
                    sectionIndexOfPaymentAndDeliveryTerms
                ].custom_fields[foundFieldIndex].is_locked = true;
                copyOfexistingCustomFields[
                    sectionIndexOfPaymentAndDeliveryTerms
                ].custom_fields[foundFieldIndex].is_negotiable =
                    fieldSettingFromTemplate.sellerSettings.negotiable;
                copyOfexistingCustomFields[
                    sectionIndexOfPaymentAndDeliveryTerms
                ].custom_fields[foundFieldIndex].is_required =
                    fieldSettingFromTemplate.buyerSettings.required;
                copyOfexistingCustomFields[
                    sectionIndexOfPaymentAndDeliveryTerms
                ].custom_fields[foundFieldIndex].is_visible =
                    fieldSettingFromTemplate.sellerSettings.showField;
                copyOfexistingCustomFields[
                    sectionIndexOfPaymentAndDeliveryTerms
                ].custom_fields[foundFieldIndex].description =
                    fieldSettingFromTemplate.description;
            }
    }
    // additional_details
    const listOfCustomFieldsInAdditionalDetails =
        templateDetails.sections.ADDITIONAL_DETAILS.fieldList.filter(
            (field) => {
                // if (field.fieldType === 'CUSTOM') {
                //     return (
                //         negotiableValueFields ===
                //         templateDetails.fieldSettings.customFields[field.field]
                //             .sellerSettings.negotiable
                //     );
                // }
                // return false;
                return true;
            }
        );
    let sectionIndexOfAdditionalDetails = copyOfexistingCustomFields.findIndex(
        (section) =>
            section.name === templateDetails.sections.ADDITIONAL_DETAILS.label
    );
    if (sectionIndexOfAdditionalDetails === -1) {
        copyOfexistingCustomFields.push({
            custom_fields: [],
            name: templateDetails.sections.ADDITIONAL_DETAILS.label,
            section_type: 'ITEM',
        });
        sectionIndexOfAdditionalDetails = copyOfexistingCustomFields.findIndex(
            (section) =>
                section.name ===
                templateDetails.sections.ADDITIONAL_DETAILS.label
        );
    }
    for (let fieldInTemplate of listOfCustomFieldsInAdditionalDetails) {
        const foundFieldIndex = copyOfexistingCustomFields[
            sectionIndexOfAdditionalDetails
        ].custom_fields.findIndex(
            (field) =>
                field.name.toLowerCase() === fieldInTemplate.field.toLowerCase()
        );
        const fieldSettingFromTemplate =
            templateDetails.fieldSettings.customFields[fieldInTemplate.field];
        if (fieldSettingFromTemplate)
            if (
                foundFieldIndex === -1 &&
                templateDetails.fieldSettings.customFields[
                    fieldInTemplate.field
                ]
            ) {
                copyOfexistingCustomFields[
                    sectionIndexOfAdditionalDetails
                ].custom_fields.push({
                    description: fieldSettingFromTemplate.description,
                    type:
                        fieldSettingFromTemplate.constraints.fieldType ===
                            CustomFieldType.CHOICE &&
                        fieldSettingFromTemplate.constraints.choiceType ===
                            'MULTI_SELECT'
                            ? CustomFieldType.MULTI_CHOICE
                            : fieldSettingFromTemplate.type,
                    is_locked: true,
                    is_negotiable:
                        fieldSettingFromTemplate.sellerSettings.negotiable,
                    is_required:
                        fieldSettingFromTemplate.buyerSettings.required,
                    is_visible:
                        fieldSettingFromTemplate.sellerSettings.showField,
                    name: fieldSettingFromTemplate.label,
                    value:
                        fieldSettingFromTemplate.constraints.fieldType ===
                            CustomFieldType.CHOICE &&
                        fieldSettingFromTemplate.constraints.choiceType ===
                            'MULTI_SELECT'
                            ? []
                            : null,
                });
            } else {
                copyOfexistingCustomFields[
                    sectionIndexOfAdditionalDetails
                ].custom_fields[foundFieldIndex].is_locked = true;
                copyOfexistingCustomFields[
                    sectionIndexOfAdditionalDetails
                ].custom_fields[foundFieldIndex].is_negotiable =
                    fieldSettingFromTemplate.sellerSettings.negotiable;
                copyOfexistingCustomFields[
                    sectionIndexOfAdditionalDetails
                ].custom_fields[foundFieldIndex].is_required =
                    fieldSettingFromTemplate.buyerSettings.required;
                copyOfexistingCustomFields[
                    sectionIndexOfAdditionalDetails
                ].custom_fields[foundFieldIndex].is_visible =
                    fieldSettingFromTemplate.sellerSettings.showField;
                copyOfexistingCustomFields[
                    sectionIndexOfAdditionalDetails
                ].custom_fields[foundFieldIndex].description =
                    fieldSettingFromTemplate.description;
            }
    }
    return copyOfexistingCustomFields;
};

export const addNonExistingFieldInCustomFieldBasedOntemplateOfBom = (
    existingCustomFields: ICustomSection[],
    templateDetails: IBomTemplate,
    negotiableValueFields?: boolean
) => {
    const copyOfexistingCustomFields = cloneDeep(existingCustomFields);

    // item_terms
    const listOfCustomFieldsInEssentialTerms =
        templateDetails.section.ITEM_TERMS.fieldList.filter((field) => {
            if (field.fieldType === 'CUSTOM') {
                return (
                    negotiableValueFields === undefined ||
                    negotiableValueFields ===
                        templateDetails.fieldSettings.customFields[field.field]
                            .sellerSettings.negotiable
                );
            }
            return false;
        });
    let sectionIndexOfEssentialTerms = copyOfexistingCustomFields.findIndex(
        (section) => section.name === templateDetails.section.ITEM_TERMS.label
    );

    if (sectionIndexOfEssentialTerms === -1) {
        copyOfexistingCustomFields.push({
            custom_fields: [],
            section_type: 'ITEM',
            name: templateDetails.section.ITEM_TERMS.label,
        });
        sectionIndexOfEssentialTerms = copyOfexistingCustomFields.findIndex(
            (section) =>
                section.name === templateDetails.section.ITEM_TERMS.label
        );
    }
    copyOfexistingCustomFields[sectionIndexOfEssentialTerms].name =
        templateDetails.section.ITEM_TERMS.label;

    for (let fieldInTemplate of listOfCustomFieldsInEssentialTerms) {
        const foundFieldIndex = copyOfexistingCustomFields[
            sectionIndexOfEssentialTerms
        ].custom_fields.findIndex(
            (field) =>
                field.name.toLowerCase() === fieldInTemplate.field.toLowerCase()
        );

        const fieldSettingFromTemplate =
            templateDetails.fieldSettings.customFields[fieldInTemplate.field];
        if (fieldSettingFromTemplate)
            if (
                foundFieldIndex === -1 &&
                templateDetails.fieldSettings.customFields[
                    fieldInTemplate.field
                ]
            ) {
                copyOfexistingCustomFields[
                    sectionIndexOfEssentialTerms
                ].custom_fields.push({
                    description: fieldSettingFromTemplate.description,
                    type:
                        fieldSettingFromTemplate.constraints.fieldType ===
                            CustomFieldType.CHOICE &&
                        fieldSettingFromTemplate.constraints.choiceType ===
                            'MULTI_SELECT'
                            ? CustomFieldType.MULTI_CHOICE
                            : fieldSettingFromTemplate.type,
                    is_locked: false,
                    is_negotiable: false,
                    is_required:
                        fieldSettingFromTemplate.buyerSettings.required,
                    is_visible: false,
                    name: fieldSettingFromTemplate.label,
                    value:
                        fieldSettingFromTemplate.constraints.fieldType ===
                            CustomFieldType.CHOICE &&
                        fieldSettingFromTemplate.constraints.choiceType ===
                            'MULTI_SELECT'
                            ? []
                            : null,
                });
            } else {
                copyOfexistingCustomFields[
                    sectionIndexOfEssentialTerms
                ].custom_fields[foundFieldIndex].is_locked = true;
                copyOfexistingCustomFields[
                    sectionIndexOfEssentialTerms
                ].custom_fields[foundFieldIndex].is_negotiable =
                    fieldSettingFromTemplate.sellerSettings.negotiable;
                copyOfexistingCustomFields[
                    sectionIndexOfEssentialTerms
                ].custom_fields[foundFieldIndex].is_required =
                    fieldSettingFromTemplate.buyerSettings.required;
                copyOfexistingCustomFields[
                    sectionIndexOfEssentialTerms
                ].custom_fields[foundFieldIndex].is_visible =
                    fieldSettingFromTemplate.sellerSettings.showField;
                copyOfexistingCustomFields[
                    sectionIndexOfEssentialTerms
                ].custom_fields[foundFieldIndex].description =
                    fieldSettingFromTemplate.description;
            }
    }

    return copyOfexistingCustomFields;
};

export const addNonExistingFieldInCustomFieldBasedOntemplateOfBomForSubBom = (
    existingCustomFields: ICustomSection[],
    templateDetails: IBomTemplate,
    negotiableValueFields?: boolean
) => {
    const copyOfexistingCustomFields = cloneDeep(existingCustomFields);

    // item_terms
    const listOfCustomFieldsInEssentialTerms =
        templateDetails.section.BOM_DETAILS.fieldList.filter((field) => {
            if (field.fieldType === 'CUSTOM') {
                return (
                    negotiableValueFields === undefined ||
                    negotiableValueFields ===
                        templateDetails.fieldSettings.customFields[field.field]
                            .sellerSettings.negotiable
                );
            }
            return false;
        });
    let sectionIndexOfEssentialTerms = copyOfexistingCustomFields.findIndex(
        (section) => section.name === templateDetails.section.BOM_DETAILS.label
    );

    if (sectionIndexOfEssentialTerms === -1) {
        copyOfexistingCustomFields.push({
            custom_fields: [],
            section_type: 'BOM',
            name: templateDetails.section.BOM_DETAILS.label,
        });
        sectionIndexOfEssentialTerms = copyOfexistingCustomFields.findIndex(
            (section) =>
                section.name === templateDetails.section.BOM_DETAILS.label
        );
    }
    copyOfexistingCustomFields[sectionIndexOfEssentialTerms].name =
        templateDetails.section.BOM_DETAILS.label;

    for (let fieldInTemplate of listOfCustomFieldsInEssentialTerms) {
        const foundFieldIndex = copyOfexistingCustomFields[
            sectionIndexOfEssentialTerms
        ].custom_fields.findIndex(
            (field) =>
                field.name.toLowerCase() === fieldInTemplate.field.toLowerCase()
        );

        const fieldSettingFromTemplate =
            templateDetails.fieldSettings.customFields[fieldInTemplate.field];
        if (fieldSettingFromTemplate)
            if (
                foundFieldIndex === -1 &&
                templateDetails.fieldSettings.customFields[
                    fieldInTemplate.field
                ]
            ) {
                copyOfexistingCustomFields[
                    sectionIndexOfEssentialTerms
                ].custom_fields.push({
                    description: fieldSettingFromTemplate.description,
                    type:
                        fieldSettingFromTemplate.constraints.fieldType ===
                            CustomFieldType.CHOICE &&
                        fieldSettingFromTemplate.constraints.choiceType ===
                            'MULTI_SELECT'
                            ? CustomFieldType.MULTI_CHOICE
                            : fieldSettingFromTemplate.type,
                    is_locked: false,
                    is_negotiable: false,
                    is_required:
                        fieldSettingFromTemplate.buyerSettings.required,
                    is_visible: false,
                    name: fieldSettingFromTemplate.label,
                    value:
                        fieldSettingFromTemplate.constraints.fieldType ===
                            CustomFieldType.CHOICE &&
                        fieldSettingFromTemplate.constraints.choiceType ===
                            'MULTI_SELECT'
                            ? []
                            : null,
                });
            } else {
                copyOfexistingCustomFields[
                    sectionIndexOfEssentialTerms
                ].custom_fields[foundFieldIndex].is_locked = true;
                copyOfexistingCustomFields[
                    sectionIndexOfEssentialTerms
                ].custom_fields[foundFieldIndex].is_negotiable =
                    fieldSettingFromTemplate.sellerSettings.negotiable;
                copyOfexistingCustomFields[
                    sectionIndexOfEssentialTerms
                ].custom_fields[foundFieldIndex].is_required =
                    fieldSettingFromTemplate.buyerSettings.required;
                copyOfexistingCustomFields[
                    sectionIndexOfEssentialTerms
                ].custom_fields[foundFieldIndex].is_visible =
                    fieldSettingFromTemplate.sellerSettings.showField;
                copyOfexistingCustomFields[
                    sectionIndexOfEssentialTerms
                ].custom_fields[foundFieldIndex].description =
                    fieldSettingFromTemplate.description;
            }
    }

    return copyOfexistingCustomFields;
    // {
    //     custom_fields: [],
    //     section_type: 'ITEM',
    //     name: templateDetails.section.ITEM_TERMS.label,
    // } as ICustomSection,
};
