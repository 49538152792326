import { IAdditionalCostsBackend } from '../../AdditionalCost/models/AdditionalCost.model';
import {
    ITemplateAdditionalCostOptions,
    ITemplateCustomFieldOptions,
    ITemplateDiscountOptions,
    ITemplatePaymentTermsOptions,
    ITemplateTaxesOptions,
} from '../../Global/Interfaces/TemplateInterface';

export enum ItemCartTemplateStatusEnum {
    DRAFT = 'DRAFT',
}

export interface IItemCartTemplateDetails {
    template_id: string;
    type: 'ITEM_CART';
    name: string;
    tags: string[];
    status: ItemCartTemplateStatusEnum;
    published_on: null;
    description: '';
    is_global: false;
    is_default: false;
    created_on: string;
    creator_name: string;
    last_edited_on: string;
    entity_id: string;
    entity_name: string;
    auto_reminders: {};
    last_edited_by_name: string;
    assigned_users: [];
    section_list: {
        section_id: string;
        name: string;
        assigned_users: [];
    }[];
}

export interface IItemCartTemplateResponse {
    type: 'ITEM_CART';
    count: number;
    templates: IItemCartTemplateDetails[];
}

export interface IItemCartTemplateDetailResponse {
    template_id: string;
    section_list: SectionList[];
    entity: Entity;
    last_edited_by_name: string;
    assigned_users: any[];
    created_datetime: string;
    modified_datetime: string;
    deleted_datetime: string | null;
    name: string;
    type: string;
    description: string;
    tags: string[];
    is_default: boolean;
    is_global: boolean;
    auto_reminders: {
        initial_reminder_hours: number;
        is_remainder_required: boolean;
        max_reminders: number;
        reminder_interval: number;
    };
    status: string;
    published_on: string | null;
    created_on: string;
    last_edited_on: string;
    created_by_user: string;
    modified_by_user: string;
    deleted_by_user: string | null;
    enterprise: string;
    creator: string;
    last_edited_by: string;
}

export interface SectionList {
    section_id: string;
    field_level: 'ITEM' | 'OTHER' | 'DELIVERY_SCHEDULE';
    section_items: SectionItem[];
    assigned_users: any[];
    created_datetime: string;
    modified_datetime: string;
    deleted_datetime: any;
    name: string;
    alternate_name: string;
    section_type: string;
    is_builtin: boolean;
    description: string;
    sequence: number;
    is_visible: boolean;
    is_optional: boolean;
    created_by_user: string;
    modified_by_user: string;
    deleted_by_user: any;
    template: string;
}

export interface SectionItem {
    section_item_id: string;
    options?: Option[];
    created_datetime: string;
    modified_datetime: string;
    deleted_datetime: any;
    sequence: number;
    name: string;
    alternate_name: string;
    description: string;
    constraints: {
        max_limit?: number;
        min_limit?: number;
        field_type: string;
        decimal_places?: number;
        choices?: string[];
        choice_type?: 'DROPDOWN' | 'MULTI_SELECT' | undefined;
    };
    field_level: 'ITEM' | 'OTHER' | 'DELIVERY_SCHEDULE';
    can_remove: boolean;
    is_builtin_field: boolean;
    is_required: boolean;
    is_variable: boolean;
    is_mandatory: boolean;
    additional_information: {
        is_locked: boolean;
        // is_hidden: boolean;
        is_visible: boolean;
        is_negotiable: boolean;
        is_hidden: boolean;
        additional_cost_information?: Omit<
            IAdditionalCostsBackend,
            'cost_value' | 'cost_name'
        >;
        shipping_rate_information?: Omit<
            IAdditionalCostsBackend,
            'cost_value' | 'cost_name'
        >;
        taxes_information?: Omit<
            IAdditionalCostsBackend,
            'cost_value' | 'cost_name'
        >;
        discount_information?: Omit<
            IAdditionalCostsBackend,
            'cost_value' | 'cost_name'
        >;
    };
    created_by_user: string;
    modified_by_user: string;
    deleted_by_user: any;
    section: string;
    parent_section_item: string | null;
}

export interface Option {
    section_item_option_id: string;
    created_datetime: string;
    modified_datetime: string;
    deleted_datetime: any;
    name: string;
    alternate_name: string;
    description: string;
    is_builtin: boolean;
    conditions: Condition;
    created_by_user: string;
    modified_by_user: string;
    deleted_by_user: any;
    send_to_po?: boolean;
    can_split?: boolean;
    section_item: string;
}

export interface Condition {
    name: string;
    value: string;
}

export interface Constraints {
    max_limit?: number;
    min_limit?: number;
    field_type: string;
    decimal_places?: number;
    choices?: string[];
    selected?: Selected;
    choice_type?: 'DROPDOWN' | 'MULTI_SELECT';
}

export interface Selected {
    choice: string;
}

export interface AdditionalInformation {
    is_hidden: boolean;
    is_visible: boolean;
    is_locked: boolean;
    is_negotiable?: boolean;
    additional_cost_information?: AdditionalCostInformation;
    discount_information?: DiscountInformation;
}

export interface AdditionalCostInformation {
    cost_type: string;
    allocation_type: string;
    additional_cost_id: string;
}

export interface DiscountInformation {
    cost_type: string;
    allocation_type: string;
}

export interface Entity {
    entity_name: string;
    entity_id: string;
}

export interface AutoReminders {}

export enum ItemCartTemplateStandardFieldsEnum {
    DELIVERY_DATE = 'DELIVERY_DATE',
    QUANTITY = 'QUANTITY',
    COST_CENTER = 'COST_CENTER',
    GL = 'GL',
    PROJECT = 'PROJECT',
    TAX = 'TAX',
    ADDITIONAL_COSTS = 'ADDITIONAL_COSTS',
    DISCOUNT = 'DISCOUNT',
    TARGET_RATE = 'TARGET_RATE',
    PAYMENT_TERMS = 'PAYMENT_TERMS',
    LEAD_TIME = 'LEAD_TIME',
    INCOTERMS = 'INCOTERMS',
    GR_TOLERANCE = 'GR_TOLERANCE',
    SHIPPING_ADDRESS = 'SHIPPING_ADDRESS',
    VENDOR = 'VENDOR',
    ADDITIONAL_INFORMATION = 'ADDITIONAL_INFORMATION',
    CUSTOM_SPECIFICATION = 'CUSTOM_SPECIFICATION',
}

export const ItemCartTemplateStandardFieldBackendNames: {
    [BackendTemplateItemName: string]: ItemCartTemplateStandardFieldsEnum;
} = {
    'Delivery date': ItemCartTemplateStandardFieldsEnum.DELIVERY_DATE,
    Quantity: ItemCartTemplateStandardFieldsEnum.QUANTITY,
    'Cost center': ItemCartTemplateStandardFieldsEnum.COST_CENTER,
    GL: ItemCartTemplateStandardFieldsEnum.GL,
    Project: ItemCartTemplateStandardFieldsEnum.PROJECT,
    'Target rate': ItemCartTemplateStandardFieldsEnum.TARGET_RATE,
    Taxes: ItemCartTemplateStandardFieldsEnum.TAX,
    'Additional costs': ItemCartTemplateStandardFieldsEnum.ADDITIONAL_COSTS,
    Discount: ItemCartTemplateStandardFieldsEnum.DISCOUNT,
    'Payment terms': ItemCartTemplateStandardFieldsEnum.PAYMENT_TERMS,
    Incoterms: ItemCartTemplateStandardFieldsEnum.INCOTERMS,
    'Lead time': ItemCartTemplateStandardFieldsEnum.LEAD_TIME,
    'GR tolerance': ItemCartTemplateStandardFieldsEnum.GR_TOLERANCE,
    'Shipping Address': ItemCartTemplateStandardFieldsEnum.SHIPPING_ADDRESS,
    Vendor: ItemCartTemplateStandardFieldsEnum.VENDOR,
    'Additional information':
        ItemCartTemplateStandardFieldsEnum.ADDITIONAL_INFORMATION,
    'Custom specification':
        ItemCartTemplateStandardFieldsEnum.CUSTOM_SPECIFICATION,
};
export enum ItemCartStandardSections {
    ESSENTIAL_TERMS = 'ESSENTIAL_TERMS',
    VENDOR_INFORMATION = 'VENDOR_INFORMATION',
    PAYMENT_AND_DELIVERY_TERMS = 'PAYMENT_AND_DELIVERY_TERMS',
    ADDITIONAL_DETAILS = 'ADDITIONAL_DETAILS',
}

export type ItemCartTemplateStandardFieldSettingsObj = {
    [ItemCartTemplateStandardFieldsEnum.DELIVERY_DATE]: IItemCartTemplateStandardFieldOptions;
    [ItemCartTemplateStandardFieldsEnum.QUANTITY]: IItemCartTemplateStandardFieldOptions;
    [ItemCartTemplateStandardFieldsEnum.COST_CENTER]: IItemCartTemplateStandardFieldOptions;
    [ItemCartTemplateStandardFieldsEnum.GL]: IItemCartTemplateStandardFieldOptions;
    [ItemCartTemplateStandardFieldsEnum.PROJECT]: IItemCartTemplateStandardFieldOptions;
    [ItemCartTemplateStandardFieldsEnum.TAX]: ITemplateTaxesOptions;
    [ItemCartTemplateStandardFieldsEnum.ADDITIONAL_COSTS]: ITemplateAdditionalCostOptions;
    [ItemCartTemplateStandardFieldsEnum.DISCOUNT]: ITemplateDiscountOptions;
    [ItemCartTemplateStandardFieldsEnum.TARGET_RATE]: IItemCartTemplateStandardFieldOptions;
    [ItemCartTemplateStandardFieldsEnum.PAYMENT_TERMS]: ITemplatePaymentTermsOptions;
    [ItemCartTemplateStandardFieldsEnum.INCOTERMS]: IItemCartTemplateStandardFieldOptions;
    [ItemCartTemplateStandardFieldsEnum.LEAD_TIME]: IItemCartTemplateStandardFieldOptions;
    [ItemCartTemplateStandardFieldsEnum.GR_TOLERANCE]: IItemCartTemplateStandardFieldOptions;
    [ItemCartTemplateStandardFieldsEnum.SHIPPING_ADDRESS]: IItemCartTemplateStandardFieldOptions;
    [ItemCartTemplateStandardFieldsEnum.VENDOR]: IItemCartTemplateStandardFieldOptions;
    [ItemCartTemplateStandardFieldsEnum.ADDITIONAL_INFORMATION]: IItemCartTemplateStandardFieldOptions;
    [ItemCartTemplateStandardFieldsEnum.CUSTOM_SPECIFICATION]: IItemCartTemplateStandardFieldOptions;
};

export type IItemCartTemplateStandardFieldOptionsBase = {
    label: string;
    description: string;
    buyerSettings: {
        showField: boolean;
        required: boolean;
    };
    sellerSettings: {
        showField: boolean;
        negotiable: boolean;
        isMandatory: boolean;
    };
};

export type IItemCartTemplateStandardFieldOptions =
    IItemCartTemplateStandardFieldOptionsBase & {
        standardFieldCategory: 'STANDARD';
    };

export interface IRFQTemplateFieldSetting {
    standardFields: ItemCartTemplateStandardFieldSettingsObj;
    customFields: {
        [field: string]: ITemplateCustomFieldOptions;
    };
}

export type IItemCartTemplateSections = {
    [section in ItemCartStandardSections]: IItemCartTemplateSectionDetails;
};

export interface IItemCartTemplateSectionDetails {
    label: string;
    fieldList: ISectionField[];
}

export type ISectionField =
    | {
          fieldType: 'STANDARD';
          field: ItemCartTemplateStandardFieldsEnum;
      }
    | {
          fieldType: 'CUSTOM';
          field: string;
      };

export interface IItemCartTemplate {
    templateId: string;
    templateName: string;
    fieldSettings: IRFQTemplateFieldSetting;
    sections: IItemCartTemplateSections;
}
