import { GridSelectionModel } from '@mui/x-data-grid';
import { cloneDeep, debounce, isEqual } from 'lodash';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { batch, shallowEqual, useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { useGetContractDetailsForItemQuery } from '../../../CLM/Services/CLM.service';

import { calculateNewItemTotalWithDecimalPlaces } from '../../../Components/Shared/totalCalculator';
import { AuthContext } from '../../../Contexts/AuthContext';
import { IVendorForEventItem } from '../../../Events/Interfaces/Buyer/RFQ.model';
import { ILoading } from '../../../Events/Interfaces/Buyer/SharedRFQ.model';
import { useFetchAllVendorsForRFQMultipleItemQuery } from '../../../Events/Services/Buyer/RFQ.service';
import { useGetAddressForEntityQuery } from '../../../Events/Services/Buyer/shared.service';
import {
    IVendorMasterStoredState,
    storeDataInVendorMasterStore,
} from '../../../Events/Slices/vendorMasterTable';
import { EntityStatus } from '../../../Global/Interfaces/EntityInterface';
import { IAddresses } from '../../../Models/Address.model';
import { ICustomAttributeNew } from '../../../Models/Attributes.model';
import { ICurrencyDetails } from '../../../Models/Currency.model';
import {
    IVendorAddDetails,
    IVendorContact,
} from '../../../Organizations/Admin/Interfaces/VendorsInterface';
import { fetchVendorContactsList } from '../../../Organizations/Admin/Services/VendorsService';
import { getVendorInfo } from '../../../PurchaseOrders/Services/PO.service';
import { VerificationStatus } from '../../../Redux/Slices/VerificationSlice';
import { IGlobalAppStore, store } from '../../../Redux/Store';
import {
    convertIAdditionalCostBackendToIAdditionalCostWithValue,
    getCartItemValidationKey,
    validateCartItemDetails,
} from '../../Helpers/CartItemHelpers';
import { translateCartitemToUpdatePayload } from '../../Helpers/itemAnalyticsTemplateHelpers';
import {
    CartItemDetails,
    ICartItemValidation,
    updateCartItemDetailsActionType,
} from '../../Interfaces/itemCartItemInterfaces';
import { ItemCartAccessContext } from '../../Pages/ItemCartAccess';
import {
    deleteCartItemAPI,
    updateCartItem,
    useGetEnterpriseItemDetailsQuery,
} from '../../Services/ItemAnalytics.service';
import { updateCartDataStore } from '../../Slices/ItemAnalyticsCartSlice';

export default function useCartItemAccordionHook(
    cart_item_id: string,
    itemInCart: boolean,
    accordion_index: number
) {
    const dispatch = useDispatch();

    const [expanded, setExpanded] = useState(false);

    const [allItemVendors, setAllItemVendors] = useState<IVendorForEventItem[]>(
        []
    );

    const { cartId, buyerEntityId, templateDetails } = useContext(
        ItemCartAccessContext
    );

    const [shippingAddressOptions, setShippingAddressOptions] = useState<
        IAddresses[]
    >([]);

    const [vendorInformation, setVendorInformation] = useState<{
        [vendorUuid: string]: {
            vendorData: IVendorAddDetails;
            vendorContacts: IVendorContact[];
        };
    }>({});

    const { data: addressResp } = useGetAddressForEntityQuery(
        {
            entity_id: buyerEntityId,
        },
        {
            skip: !Boolean(buyerEntityId),
        }
    );

    useEffect(() => {
        if (addressResp) {
            let res = cloneDeep(addressResp);

            let sorted_addresses = res.sort((a, b) => {
                return (
                    (a.status === 'INACTIVE' || a.address.status === 'INACTIVE'
                        ? 1
                        : 0) -
                    (b.status === 'INACTIVE' || b.address.status === 'INACTIVE'
                        ? 1
                        : 0)
                );
            });

            setShippingAddressOptions(
                sorted_addresses.filter(
                    (address) =>
                        address.is_shipping_address &&
                        address.is_public &&
                        address.status === 'ACTIVE'
                )
            );
        }
    }, [addressResp]);

    const itemDetail = useSelector(
        (store: IGlobalAppStore) =>
            itemInCart
                ? store.ItemCartStore.cartItems[cart_item_id]
                : store.ItemCartStore.itemsSavedForLater[cart_item_id],
        shallowEqual
    );

    const ENTERPRISE_ITEM_IDS = useSelector((store: IGlobalAppStore) =>
        store.ItemCartStore.cartDetails.map((cart) => cart.enterprise_item)
    );

    const ENTERPRISE_ITEM_ID = useMemo(() => {
        return itemDetail.enterprise_item;
    }, [itemDetail]);

    const [errors, setErrors] = useState<ICartItemValidation>({
        custom_sections: {},
        delivery_information: [],
        paymentTerms_poItem_allocationRows: [],
    });

    const listOfSelectedVendorIds = useMemo(() => {
        const item_details = cloneDeep(itemDetail);

        const listOfSelectedVendorIds = cloneDeep(item_details.seller_entities);

        return listOfSelectedVendorIds;
    }, [itemDetail]);

    const preferredVendorCount = useMemo(() => {
        if (allItemVendors && listOfSelectedVendorIds) {
            return allItemVendors
                .filter((vendor, idx) =>
                    listOfSelectedVendorIds.includes(vendor.id ?? '')
                )
                .filter((v) => v.status === 'PREFERRED').length;
        }
        return listOfSelectedVendorIds?.length ?? 0;
    }, [allItemVendors, listOfSelectedVendorIds]);

    const nonpreferredVendorCount = useMemo(() => {
        if (allItemVendors && listOfSelectedVendorIds) {
            return allItemVendors
                .filter((vendor, idx) =>
                    listOfSelectedVendorIds.includes(vendor.id ?? '')
                )
                .filter((v) => v.status !== 'PREFERRED').length;
        }
        return 0;
    }, [allItemVendors, listOfSelectedVendorIds]);

    const { data: enterpriseItemDetails, refetch: refetchItemDetails } =
        useGetEnterpriseItemDetailsQuery(
            {
                enterprise_item_id: itemDetail.enterprise_item,
            },
            {
                skip: !Boolean(itemDetail.enterprise_item),
            }
        );

    useEffect(() => {
        try {
            refetchItemDetails();
        } catch (e) {}
    }, [refetchItemDetails]);

    const allVendorMasterData = useSelector(
        (state: IGlobalAppStore) => state.VendorMasterStore
    );

    const [dataState, setDataState] = useState<ILoading>({
        loading: false,
        error: false,
        saved: true,
        error_msg: '',
    });

    const { authData } = useContext(AuthContext);

    const defaultCurrencyId = useMemo(() => {
        return authData.details?.enterprise.billing_currency ?? '';
    }, [authData.details?.enterprise.billing_currency]);

    const saveCartItemDetailsDebounced = useMemo(
        () =>
            debounce((_details: CartItemDetails) => {
                let details = cloneDeep(_details);

                let itemPayload = translateCartitemToUpdatePayload(
                    details,
                    buyerEntityId,
                    cartId,
                    defaultCurrencyId,
                    templateDetails
                );

                setDataState(() => ({
                    saved: false,
                    loading: true,
                    error: false,
                    error_msg: '',
                }));

                updateCartItem(cart_item_id, itemPayload)
                    .then(() => {
                        setDataState(() => ({
                            saved: true,
                            loading: false,
                            error: false,
                            error_msg: '',
                        }));
                        batch(() => {
                            dispatch(
                                updateCartDataStore({
                                    type: 'UPDATE_ITEM_SAVE_STATE',
                                    value: {
                                        data1: 'SAVED',
                                        idx1: accordion_index,
                                    },
                                })
                            );
                        });
                    })
                    .catch((err) => {
                        setDataState((prev) => ({
                            saved: false,
                            loading: false,
                            error: true,
                            error_msg: "Couldn't save data",
                        }));
                        dispatch(
                            updateCartDataStore({
                                type: 'UPDATE_ITEM_SAVE_STATE',
                                value: {
                                    idx1: accordion_index,
                                    data1: 'ERROR',
                                },
                            })
                        );
                    });
            }, 2000),
        [
            accordion_index,
            buyerEntityId,
            cartId,
            cart_item_id,
            defaultCurrencyId,
            dispatch,
            templateDetails,
        ]
    );

    const MemoizedSaveCartItemDetails = useCallback(
        async (newCartItemInformation: CartItemDetails) => {
            try {
                setDataState(() => ({
                    saved: false,
                    loading: false,
                    error: false,
                    error_msg: '',
                }));
                dispatch(
                    updateCartDataStore({
                        type: 'UPDATE_ITEM_SAVE_STATE',
                        value: {
                            idx1: accordion_index,
                            data1: 'UNSAVED',
                        },
                    })
                );

                // UPDATE_ITEM
                saveCartItemDetailsDebounced(newCartItemInformation);
            } catch (err) {}
        },
        [accordion_index, dispatch, saveCartItemDetailsDebounced]
    );

    const deleteAttribute = useCallback(
        (index: number) => {
            let newCartItemInformation = cloneDeep(
                itemInCart
                    ? store.getState().ItemCartStore.cartItems[cart_item_id]
                    : store.getState().ItemCartStore.itemsSavedForLater[
                          cart_item_id
                      ]
            );

            newCartItemInformation.attribute_information.custom_item_attributes.splice(
                index,
                1
            );

            // VALIDATE_ITEM

            MemoizedSaveCartItemDetails(newCartItemInformation);
        },
        [MemoizedSaveCartItemDetails, cart_item_id, itemInCart]
    );

    const addNewNonPrefferedVendorToList = useCallback(
        async (vendorToAdd: IVendorAddDetails) => {
            setDataState((prev) => ({ ...prev, saved: false }));

            if (!(vendorToAdd.vendorUid in allVendorMasterData)) {
                const vendor = await getVendorInfo(vendorToAdd.vendorUid);

                if (vendor) {
                    const vendorStoreData: IVendorMasterStoredState = {};
                    vendorStoreData[vendorToAdd.vendorUid] = {
                        enterprise_vendor_master_id:
                            vendor.enterprise_vendor_master_id,
                        seller_entity_id: vendor.seller_entity.entity_id,
                        vendor_name: vendor.vendor_name,
                        tags: vendor.tags,
                    };
                    dispatch(
                        storeDataInVendorMasterStore({
                            type: 'ADD_DATA',
                            data: vendorStoreData,
                        })
                    );
                }
            }

            const vendorContacts = await fetchVendorContactsList(
                buyerEntityId,
                vendorToAdd.vendorUid,
                'VENDOR_MASTER'
            );

            let secondaryContacts = vendorContacts;

            setVendorInformation((prev) => ({
                ...prev,
                [vendorToAdd.vendorUid]: {
                    vendorContacts: secondaryContacts,
                    vendorData: vendorToAdd,
                },
            }));

            const newVendorList = cloneDeep(
                allItemVendors ? allItemVendors : []
            );
            let vendorCount = newVendorList.length;

            const seller_contacts = vendorToAdd.seller_contacts;

            secondaryContacts.forEach((sc) => {
                if (
                    seller_contacts.find(
                        (c) => c.vendor_contact_id === sc.vendorContactUid
                    ) === undefined
                ) {
                    seller_contacts.push({
                        vendor_contact_id: sc.vendorContactUid,
                        full_name: sc.fullName,
                        primary_email: sc.sellerEmails[0].email,
                        emails: sc.sellerEmails.map((sce) => ({
                            type: '',
                            email: sce.email,
                        })),
                        phone_numbers: [],
                        tags: sc.tags,
                    });
                }
            });

            const newVendor: IVendorForEventItem = {
                id: vendorToAdd.vendorUid,
                no: vendorCount,
                entity_vendor_master_id: vendorToAdd.vendorEnterpriseUid,
                enterprise_item: '',
                buyer_enterprise: '',
                status: 'STANDARD',
                seller_entity: {
                    entity_id: vendorToAdd.vendorUid,
                    enterprise: '',
                    entity_name: vendorToAdd.vendorName,
                    entity_logo: null,
                    entity_description: '',
                    buyer_status: EntityStatus.INACTIVE,
                    seller_status: EntityStatus.INACTIVE,
                    verification_status: vendorToAdd.verification_status,
                    contacts: {
                        emails: vendorToAdd.email?.split(',') ?? [''],
                    },
                    is_virtual: vendorToAdd.is_virtual,
                },
                seller_contacts: [...vendorToAdd.seller_contacts],
                vendor_code: vendorToAdd.vendorCode,
            };

            newVendorList.push(newVendor);

            setAllItemVendors(newVendorList);

            let currentItem = cloneDeep(itemDetail);

            currentItem.seller_entities = Array.from(
                new Set([...currentItem.seller_entities, vendorToAdd.vendorUid])
            );

            currentItem.seller_contacts = Array.from(
                new Set([
                    ...vendorToAdd.seller_contacts.map(
                        (sellerContact) => sellerContact.vendor_contact_id
                    ),
                    ...newVendor.seller_contacts.map(
                        (contact) => contact.vendor_contact_id
                    ),
                ])
            );

            MemoizedSaveCartItemDetails(currentItem);

            batch(() => {
                dispatch(
                    updateCartDataStore({
                        type: 'UPDATE_VENDOR_FOR_ITEM',
                        value: {
                            cart_item_id: cart_item_id,
                            vendor: currentItem.seller_entities,
                        },
                    })
                );
                dispatch(
                    updateCartDataStore({
                        type: 'UPDATE_VENDOR_CONTACT_FOR_ITEM',
                        value: {
                            cart_item_id: cart_item_id,
                            vendorContact: currentItem.seller_contacts,
                        },
                    })
                );
            });
        },
        [
            MemoizedSaveCartItemDetails,
            allItemVendors,
            allVendorMasterData,
            buyerEntityId,
            cart_item_id,
            dispatch,
            itemDetail,
        ]
    );

    const saveVendorDetails = useCallback(
        (
            newDetails: GridSelectionModel,
            isThisBeingCalledFromVendorContacts: boolean = false,
            allItemVendorsCallback?: IVendorForEventItem[] | null
        ) => {
            const existingItemVendors = cloneDeep(itemDetail);

            if (
                !isEqual(existingItemVendors, newDetails) ||
                isThisBeingCalledFromVendorContacts
            ) {
                setDataState((prev) => ({ ...prev, saved: false }));
                const item_details = cloneDeep(itemDetail);
                let _selected_vendors = (allItemVendors ?? []).filter(
                    (vendor, idx) =>
                        newDetails.includes(
                            vendor.seller_entity.entity_id ?? ''
                        )
                );

                if (allItemVendorsCallback) {
                    _selected_vendors = [
                        ..._selected_vendors,
                        ...allItemVendorsCallback.filter((v) =>
                            newDetails.includes(v.id ?? '')
                        ),
                    ];
                }

                let _errors = validateCartItemDetails({
                    field: 'vendor_count',
                    currentErrors: errors,
                    itemDetails: item_details,
                    preferredVendorCount: _selected_vendors.filter(
                        (vendor) => vendor.status === 'PREFERRED'
                    ).length,
                    nonpreferredVendorCount: _selected_vendors.filter(
                        (vendor) =>
                            vendor.status === 'STANDARD' &&
                            (vendor.contract_id === undefined ||
                                vendor.contract_id === '')
                    ).length,
                    templateDetails: templateDetails,
                });

                setErrors(_errors);

                let sellerInfos = _selected_vendors.reduce(
                    (
                        acc: {
                            seller_entity_id: string;
                            vendor_contact_list: string[];
                        }[],
                        vendor
                    ) => {
                        if (
                            acc.find(
                                (v) =>
                                    v.seller_entity_id ===
                                    vendor.seller_entity.entity_id
                            ) === undefined
                        )
                            return [
                                ...acc,
                                {
                                    seller_entity_id:
                                        vendor.seller_entity.entity_id,
                                    vendor_contact_list: Array.from(
                                        new Set(
                                            vendor.seller_contacts.map(
                                                (contact) =>
                                                    contact.vendor_contact_id
                                            )
                                        )
                                    ),
                                },
                            ];
                        else return acc;
                    },
                    []
                );

                let currentItem = cloneDeep(itemDetail);

                currentItem.seller_entities = sellerInfos.map(
                    (vendor) => vendor.seller_entity_id
                );
                currentItem.seller_contacts =
                    currentItem.seller_contacts_information
                        .filter((contact) =>
                            currentItem.seller_entities.includes(
                                contact.seller_entity
                            )
                        )
                        .map((contact) => contact.vendor_contact_id);

                batch(() => {
                    dispatch(
                        updateCartDataStore({
                            type: 'UPDATE_SINGLE_CART_ITEM',
                            value: {
                                data: currentItem,
                                idx: accordion_index,
                            },
                        })
                    );
                });

                MemoizedSaveCartItemDetails(currentItem);

                // setErrors(_errors);
            }
        },
        [
            MemoizedSaveCartItemDetails,
            accordion_index,
            allItemVendors,
            dispatch,
            errors,
            itemDetail,
            templateDetails,
        ]
    );

    const saveVendorContacts = useCallback(
        (
            vendor_id: string,
            contacts: {
                vendor_contact_id: string;
                full_name: string;
                primary_email: string;
                emails: {
                    email: string;
                    type: string;
                }[];
                phone_numbers: string[];
                tags: string[];
            }[]
        ) => {
            if (allItemVendors === null) return;
            setDataState(() => ({
                saved: true,
                loading: true,
                error: false,
                error_msg: '',
            }));

            const item_details = cloneDeep(itemDetail);

            const listOfSelectedVendorIds = item_details.seller_entities;

            setAllItemVendors((prev) => {
                let newVendorContacts = cloneDeep(prev);

                if (newVendorContacts !== null) {
                    let vendorIndex = newVendorContacts.findIndex(
                        (vendor) => vendor.seller_entity.entity_id === vendor_id
                    );

                    // making the save vendor call only here

                    let _selected_vendors = (newVendorContacts ?? []).filter(
                        (vendor, idx) =>
                            listOfSelectedVendorIds.includes(vendor.id ?? '')
                    );

                    if (newVendorContacts[vendorIndex])
                        newVendorContacts[vendorIndex].seller_contacts = [
                            ...contacts,
                        ];

                    saveVendorDetails(
                        [...(listOfSelectedVendorIds ?? [])],
                        true,
                        newVendorContacts
                    );

                    let _errors = validateCartItemDetails({
                        field: 'vendor_count',
                        currentErrors: errors,
                        itemDetails: item_details,
                        preferredVendorCount: _selected_vendors.filter(
                            (vendor) => vendor.status === 'PREFERRED'
                        ).length,
                        nonpreferredVendorCount: _selected_vendors.filter(
                            (vendor) =>
                                vendor.status === 'STANDARD' &&
                                (vendor.contract_id === undefined ||
                                    vendor.contract_id === '')
                        ).length,

                        templateDetails: templateDetails,
                    });

                    setErrors(_errors);
                    let sellerInfos = _selected_vendors.reduce(
                        (
                            acc: {
                                seller_entity_id: string;
                                vendor_contact_list: string[];
                            }[],
                            vendor
                        ) => {
                            if (
                                acc.find(
                                    (v) =>
                                        v.seller_entity_id ===
                                        vendor.seller_entity.entity_id
                                ) === undefined
                            )
                                return [
                                    ...acc,
                                    {
                                        seller_entity_id:
                                            vendor.seller_entity.entity_id,
                                        vendor_contact_list: Array.from(
                                            new Set(
                                                vendor.seller_contacts.map(
                                                    (contact) =>
                                                        contact.vendor_contact_id
                                                )
                                            )
                                        ),
                                    },
                                ];
                            else return acc;
                        },
                        []
                    );

                    setDataState((prev) => ({ ...prev, saved: false }));

                    let currentItem = cloneDeep(itemDetail);

                    currentItem.seller_entities = sellerInfos.map(
                        (vendor) => vendor.seller_entity_id
                    );
                    currentItem.seller_contacts = sellerInfos.flatMap(
                        (vendor) =>
                            vendor.vendor_contact_list.map(
                                (contactId) => contactId
                            )
                    );

                    MemoizedSaveCartItemDetails(currentItem);

                    batch(() => {
                        dispatch(
                            updateCartDataStore({
                                type: 'UPDATE_VENDOR_FOR_ITEM',
                                value: {
                                    cart_item_id: cart_item_id,
                                    vendor: currentItem.seller_entities,
                                },
                            })
                        );
                        dispatch(
                            updateCartDataStore({
                                type: 'UPDATE_VENDOR_CONTACT_FOR_ITEM',
                                value: {
                                    cart_item_id: cart_item_id,
                                    vendorContact: currentItem.seller_contacts,
                                },
                            })
                        );
                    });

                    return newVendorContacts;
                }
                return newVendorContacts;
            });
        },
        [
            MemoizedSaveCartItemDetails,
            allItemVendors,
            cart_item_id,
            dispatch,
            errors,
            itemDetail,
            saveVendorDetails,
            templateDetails,
        ]
    );

    const updateAttribute = useCallback(
        (
            attribute_id: string | null,
            attribute_name: string | null,
            attribute_value: string[] | null,
            index_of_edited_attribute: number
        ) => {
            if (attribute_name !== null && attribute_value !== null) {
                let newObj = cloneDeep(
                    itemInCart
                        ? store.getState().ItemCartStore.cartItems[cart_item_id]
                        : store.getState().ItemCartStore.itemsSavedForLater[
                              cart_item_id
                          ]
                );
                if (index_of_edited_attribute >= 0) {
                    for (
                        let i = 0;
                        i <
                        newObj.attribute_information.custom_item_attributes
                            .length;
                        i++
                    ) {
                        if (i === index_of_edited_attribute) {
                            newObj.attribute_information.custom_item_attributes[
                                i
                            ].attribute_name = attribute_name;
                            newObj.attribute_information.custom_item_attributes[
                                i
                            ].attribute_value = attribute_value;
                            break;
                        }
                    }
                } else {
                    newObj.attribute_information.custom_item_attributes.push({
                        // attribute_id: attribute_id,
                        attribute_name: attribute_name,
                        attribute_value: attribute_value,
                        attribute_exclude: false,
                        // attribute_value_type: 'TEXT',
                    });
                }

                MemoizedSaveCartItemDetails(newObj);
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [MemoizedSaveCartItemDetails, accordion_index, cart_item_id, itemInCart]
    );

    const updateAttributeNew = useCallback(
        (updatedAttribute: ICustomAttributeNew, isNewAttribute: boolean) => {
            let newObj = cloneDeep(
                itemInCart
                    ? store.getState().ItemCartStore.cartItems[cart_item_id]
                    : store.getState().ItemCartStore.itemsSavedForLater[
                          cart_item_id
                      ]
            );
            if (isNewAttribute) newObj.attributes.push(updatedAttribute);
            else {
                let temp = newObj.attributes.map((attr) => {
                    if (
                        attr.attribute_linkage_id ===
                        updatedAttribute.attribute_linkage_id
                    ) {
                        return updatedAttribute;
                    } else return attr;
                });
                newObj.attributes = temp;
            }
            batch(() => {
                dispatch(
                    updateCartDataStore({
                        type: 'UPDATE_SINGLE_CART_ITEM',
                        value: {
                            idx: accordion_index,
                            data: newObj,
                        },
                    })
                );
            });
            MemoizedSaveCartItemDetails(newObj);
        },
        [
            MemoizedSaveCartItemDetails,
            accordion_index,
            cart_item_id,
            dispatch,
            itemInCart,
        ]
    );
    const deleteAttributeNew = useCallback(
        (attr_linkage_id: string) => {
            let newObj = cloneDeep(
                itemInCart
                    ? store.getState().ItemCartStore.cartItems[cart_item_id]
                    : store.getState().ItemCartStore.itemsSavedForLater[
                          cart_item_id
                      ]
            );
            let temp = newObj.attributes.filter(
                (attr) => attr.attribute_linkage_id !== attr_linkage_id
            );
            newObj.attributes = temp;
            batch(() => {
                dispatch(
                    updateCartDataStore({
                        type: 'UPDATE_SINGLE_CART_ITEM',
                        value: {
                            idx: accordion_index,
                            data: newObj,
                        },
                    })
                );
            });
            MemoizedSaveCartItemDetails(newObj);
        },
        [
            MemoizedSaveCartItemDetails,
            accordion_index,
            cart_item_id,
            dispatch,
            itemInCart,
        ]
    );

    const updateItemData = useCallback(
        (
            params: {
                field: updateCartItemDetailsActionType;
                value: any;
            }[]
        ) => {
            try {
                setDataState((prev) => ({
                    ...prev,
                    loading: false,
                    saved: false,
                }));

                let newDetails: CartItemDetails = cloneDeep(
                    JSON.parse(
                        JSON.stringify(
                            cloneDeep(
                                store.getState().ItemCartStore.cartItems[
                                    cart_item_id
                                ] ??
                                    store.getState().ItemCartStore
                                        .itemsSavedForLater[cart_item_id]
                            )
                        )
                    )
                );

                // Object.defineProperty(newDetails, 'paymentTerms', {
                //     writable: true,
                // });

                setErrors((prev) => {
                    let _errors = prev;

                    for (let { field, value } of params) {
                        if (newDetails.paymentTerms) {
                            switch (field) {
                                case 'paymentTerms_prepaymentPercentage':
                                    newDetails = {
                                        ...newDetails,
                                        paymentTerms: {
                                            ...newDetails.paymentTerms,
                                            prepaymentPercentage: value,
                                        },
                                    };
                                    break;
                                case 'paymentTerms_invoiceItem_terms':
                                    if (
                                        newDetails.paymentTerms.paymentType ===
                                        'invoiceItemValues'
                                    ) {
                                        newDetails = {
                                            ...newDetails,
                                            paymentTerms: {
                                                ...newDetails.paymentTerms,
                                                terms: value,
                                            },
                                        };
                                    }
                                    break;
                                case 'paymentTerms_invoiceItem_period':
                                    if (
                                        newDetails.paymentTerms.paymentType ===
                                        'invoiceItemValues'
                                    ) {
                                        newDetails = {
                                            ...newDetails,
                                            paymentTerms: {
                                                ...newDetails.paymentTerms,
                                                period: value,
                                            },
                                        };
                                    }
                                    break;
                                case 'paymentTerms_invoiceItem_appliedFrom':
                                    if (
                                        newDetails.paymentTerms.paymentType ===
                                        'invoiceItemValues'
                                    ) {
                                        newDetails = {
                                            ...newDetails,
                                            paymentTerms: {
                                                ...newDetails.paymentTerms,
                                                appliedFrom: value,
                                            },
                                        };
                                    }
                                    break;
                                case 'paymentTerms_poItem_allocationRows':
                                    if (
                                        newDetails.paymentTerms.paymentType ===
                                        'poItemPercentages'
                                    ) {
                                        newDetails = {
                                            ...newDetails,
                                            paymentTerms: {
                                                ...newDetails.paymentTerms,
                                                list: value,
                                            },
                                        };
                                    }
                                    break;
                                default:
                                    newDetails = {
                                        ...newDetails,
                                        [field]: value,
                                    };
                            }
                        }
                        if (field === 'price') {
                            newDetails = {
                                ...newDetails,
                                pricing_information: {
                                    ...newDetails.pricing_information,
                                    desired_price: value,
                                },
                            };
                        }
                        if (field === 'currency') {
                            let currencyValue = cloneDeep(
                                value
                            ) as ICurrencyDetails;
                            newDetails = cloneDeep({
                                ...newDetails,
                                pricing_information: {
                                    ...newDetails.pricing_information,
                                    currency_code_id: currencyValue.entry_id,
                                    currency_code_abbreviation:
                                        currencyValue.currency_code_abbreviation,
                                    currency_name: currencyValue.currency_name,
                                    currency_symbol:
                                        currencyValue.currency_symbol,
                                },
                            });
                        }
                        if (field === 'item_additional_information') {
                            newDetails = {
                                ...newDetails,
                                item_information: {
                                    ...newDetails.item_information,
                                    item_additional_details: value,
                                },
                            };
                        }
                        if (
                            field === 'quantity' &&
                            newDetails.delivery_information.length === 1
                        ) {
                            newDetails = {
                                ...newDetails,
                                delivery_information: [
                                    {
                                        ...newDetails.delivery_information[0],
                                        delivery_date:
                                            newDetails.delivery_information[0]
                                                .delivery_date,
                                        quantity: value,
                                    },
                                ],
                            };
                        }
                        if (field === 'lead_time') {
                            newDetails = {
                                ...newDetails,
                                procurement_information: {
                                    ...newDetails.procurement_information,
                                    lead_time: value,
                                },
                            };
                        }
                        if (field === 'lead_time_period') {
                            newDetails = {
                                ...newDetails,
                                procurement_information: {
                                    ...newDetails.procurement_information,
                                    lead_time_period: value,
                                },
                            };
                        }

                        if (field === 'delivery_information') {
                            let total_quantity = 0;
                            newDetails.delivery_information.forEach(
                                (delivery: any) => {
                                    total_quantity += +delivery.quantity;
                                }
                            );
                            newDetails = {
                                ...newDetails,
                                quantity: total_quantity,
                                delivery_information: cloneDeep(value),
                            };
                        } else {
                            newDetails = {
                                ...newDetails,
                                [field]: value,
                            };
                        }

                        _errors = validateCartItemDetails({
                            field: getCartItemValidationKey(field),
                            currentErrors: _errors,
                            itemDetails: newDetails,
                            preferredVendorCount: preferredVendorCount,
                            nonpreferredVendorCount: nonpreferredVendorCount,
                            templateDetails,
                        });
                    }
                    newDetails.total = calculateNewItemTotalWithDecimalPlaces({
                        itemDS: newDetails.delivery_information,
                        item_price:
                            newDetails.pricing_information.desired_price,
                        decimalPlaces: 2,
                        additional_cost:
                            convertIAdditionalCostBackendToIAdditionalCostWithValue(
                                newDetails.additional_costs
                            ),
                        discount:
                            convertIAdditionalCostBackendToIAdditionalCostWithValue(
                                newDetails.discounts
                            ),
                        tax: convertIAdditionalCostBackendToIAdditionalCostWithValue(
                            newDetails.taxes
                        ),
                    });

                    batch(() => {
                        dispatch(
                            updateCartDataStore({
                                type: 'UPDATE_SINGLE_CART_ITEM',
                                value: {
                                    idx: accordion_index,
                                    data: newDetails,
                                },
                            })
                        );
                    });

                    MemoizedSaveCartItemDetails(newDetails);

                    return _errors;
                });
            } catch (err) {
                console.error(err);
            }
        },
        [
            MemoizedSaveCartItemDetails,
            accordion_index,
            cart_item_id,
            dispatch,
            nonpreferredVendorCount,
            preferredVendorCount,
            templateDetails,
        ]
    );

    const deleteItem = useCallback(async () => {
        try {
            await deleteCartItemAPI(cart_item_id);

            dispatch(
                updateCartDataStore({
                    type: 'DELETE_SINGLE_CART_ITEM',
                    value: cart_item_id,
                })
            );
        } catch (err) {
            toast.error('Failed to delete item!');
        }
    }, [cart_item_id, dispatch]);

    useEffect(() => {
        // let newDetails = cloneDeep(itemCollection[cart_item_id]);
        let newDetails = cloneDeep(
            itemInCart
                ? store.getState().ItemCartStore.cartItems[cart_item_id]
                : store.getState().ItemCartStore.itemsSavedForLater[
                      cart_item_id
                  ]
        );

        setErrors((prev) => {
            let _errors = cloneDeep(prev);

            _errors = validateCartItemDetails({
                field: 'ALL',
                currentErrors: _errors,
                itemDetails: newDetails,
                nonpreferredVendorCount: nonpreferredVendorCount,
                preferredVendorCount: preferredVendorCount,

                templateDetails,
            });

            return _errors;
        });
    }, [
        cart_item_id,
        itemInCart,
        nonpreferredVendorCount,
        preferredVendorCount,
        templateDetails,
    ]);

    const saveItemForLater = useCallback(() => {
        try {
            // let newItemDetails = cloneDeep(itemCollection[cart_item_id]);
            let newItemDetails = cloneDeep(
                itemInCart
                    ? store.getState().ItemCartStore.cartItems[cart_item_id]
                    : store.getState().ItemCartStore.itemsSavedForLater[
                          cart_item_id
                      ]
            );

            newItemDetails.save_for_later = true;

            batch(() => {
                dispatch(
                    updateCartDataStore({
                        type: 'SAVE_ITEM_FOR_LATER',
                        value: cart_item_id,
                    })
                );
            });
            MemoizedSaveCartItemDetails(newItemDetails);
            toast.success('Item bookmarked successfully!');
        } catch (err) {
            toast.error('Failed to add item to the cart');
        }
    }, [MemoizedSaveCartItemDetails, cart_item_id, dispatch, itemInCart]);

    const addItemToCart = useCallback(() => {
        try {
            let newItemDetails = cloneDeep(
                itemInCart
                    ? store.getState().ItemCartStore.cartItems[cart_item_id]
                    : store.getState().ItemCartStore.itemsSavedForLater[
                          cart_item_id
                      ]
            );

            newItemDetails.save_for_later = false;

            batch(() => {
                dispatch(
                    updateCartDataStore({
                        type: 'SHIFT_ITEM_TO_CART',
                        value: cart_item_id,
                    })
                );
            });

            MemoizedSaveCartItemDetails(newItemDetails);
            toast.success('Item added to cart successfully!');
        } catch (err) {
            toast.error('Failed to add item to the cart');
        }
    }, [MemoizedSaveCartItemDetails, cart_item_id, dispatch, itemInCart]);

    const {
        data: all_vendor_listforitems,
        // refetch: refetchall_vendor_listforitems,
        // error: all_vendor_listError,
    } = useFetchAllVendorsForRFQMultipleItemQuery(
        {
            entity_id: buyerEntityId,
            item_id: ENTERPRISE_ITEM_IDS,
        },
        {
            skip: buyerEntityId === null || ENTERPRISE_ITEM_IDS.length === 0,
            refetchOnFocus: false,
        }
    );

    const {
        data: listOfContractsForItem,
        // refetch: refetchlistOfContractsForItem,
        // error: listOfContractsForItemError,
    } = useGetContractDetailsForItemQuery(
        {
            enterpriseItemUid: ENTERPRISE_ITEM_ID,
            entityUid: buyerEntityId,
        },
        {
            skip: !Boolean(buyerEntityId) || !Boolean(ENTERPRISE_ITEM_ID),
            refetchOnFocus: false,
            refetchOnMountOrArgChange: 900,
        }
    );

    const all_vendor_list = useMemo(() => {
        if (
            all_vendor_listforitems !== undefined &&
            all_vendor_listforitems[itemDetail.enterprise_item]
        ) {
            return all_vendor_listforitems[itemDetail.enterprise_item];
        }
        return undefined;
    }, [all_vendor_listforitems, itemDetail]);

    useEffect(() => {
        const selectedSellerEntities = itemDetail.seller_entities;

        const selectedSellerEntityInformation =
            itemDetail.seller_entities_information;

        const sellerContactInformation = itemDetail.seller_contacts_information;

        if (
            all_vendor_list &&
            listOfContractsForItem &&
            sellerContactInformation &&
            selectedSellerEntities
        ) {
            const globalVendors: IVendorForEventItem[] = [];

            let allItemVendors: IVendorForEventItem[] = all_vendor_list
                ?.filter(
                    (vendor) =>
                        vendor?.status === 'PREFERRED' ||
                        selectedSellerEntities.includes(
                            vendor?.seller_entity?.entity_id
                        )
                )
                .map((sellerEntity) => {
                    const entityContact = sellerContactInformation?.filter(
                        (contact) =>
                            contact.seller_entity ===
                            sellerEntity.seller_entity.entity_id
                    );
                    return {
                        ...sellerEntity,
                        contract_id:
                            listOfContractsForItem.find(
                                (c) =>
                                    c.seller_entity ===
                                    sellerEntity.seller_entity.entity_id
                            )?.contract_id ?? '',
                        seller_contacts: entityContact
                            ? entityContact.map((contact) => ({
                                  vendor_contact_id: contact.vendor_contact_id,
                                  full_name: contact.full_name,
                                  primary_email: contact.primary_email,
                                  emails: [],
                                  phone_numbers: contact.phone_numbers,
                                  tags: contact.tags,
                              }))
                            : [],
                    };
                });

            const preferredAndNonPreferredVendors = all_vendor_list.map(
                (vendor) => vendor.seller_entity.entity_id
            );

            selectedSellerEntities.forEach((sellerEntity) => {
                let vendorIndex =
                    preferredAndNonPreferredVendors.indexOf(sellerEntity);

                const currentVendorDetails = vendorInformation[sellerEntity];

                const selectedSellerEntityInfo =
                    selectedSellerEntityInformation.find(
                        (seller) => seller.entity_id === sellerEntity
                    );

                const selectedSellerContactInfo =
                    sellerContactInformation.filter(
                        (seller) => seller.seller_entity === sellerEntity
                    );

                if (!(sellerEntity in allVendorMasterData)) {
                    getVendorInfo(sellerEntity).then((vendor) => {
                        if (vendor) {
                            const vendorStoreData: IVendorMasterStoredState =
                                {};
                            vendorStoreData[sellerEntity] = {
                                enterprise_vendor_master_id:
                                    vendor.enterprise_vendor_master_id,
                                seller_entity_id:
                                    vendor.seller_entity.entity_id,
                                vendor_name: vendor.vendor_name,
                                tags: vendor.tags,
                            };
                            dispatch(
                                storeDataInVendorMasterStore({
                                    type: 'ADD_DATA',
                                    data: vendorStoreData,
                                })
                            );
                        }
                    });
                }

                if (vendorIndex === -1) {
                    let nonPreferredVendor: IVendorForEventItem = {} as any;
                    if (currentVendorDetails) {
                        nonPreferredVendor = {
                            entity_vendor_master_id: '',
                            enterprise_item: '',
                            buyer_enterprise: '',
                            status: 'STANDARD',
                            seller_entity: {
                                entity_id:
                                    currentVendorDetails?.vendorData.vendorUid,
                                enterprise: '',
                                entity_name:
                                    currentVendorDetails?.vendorData.vendorName,
                                entity_logo: null,
                                entity_description: '',
                                buyer_status: EntityStatus.SETUP_PENDING,
                                seller_status: EntityStatus.SETUP_PENDING,
                                verification_status:
                                    currentVendorDetails.vendorData
                                        .verification_status,
                                contacts: {
                                    emails: [],
                                },
                                is_virtual:
                                    currentVendorDetails.vendorData.is_virtual,
                            },
                            seller_contacts:
                                currentVendorDetails.vendorContacts.map(
                                    (contact) => ({
                                        vendor_contact_id:
                                            contact.vendorContactUid,
                                        full_name: contact.fullName,
                                        primary_email:
                                            contact.sellerEmails[0].email,
                                        emails: contact.sellerEmails.map(
                                            (email) => ({
                                                type: 'SECONDARY',
                                                email: email.email,
                                            })
                                        ),
                                        phone_numbers:
                                            contact.sellerContacts.map(
                                                (phone) => phone.number
                                            ),
                                        tags: contact.tags,
                                    })
                                ),
                            vendor_code:
                                currentVendorDetails.vendorData.vendorCode,
                            no: 0,
                        };
                    } else if (
                        selectedSellerEntityInfo &&
                        selectedSellerContactInfo
                    ) {
                        nonPreferredVendor = {
                            entity_vendor_master_id: '',
                            enterprise_item: '',
                            buyer_enterprise: '',
                            status: 'STANDARD',
                            seller_entity: {
                                entity_id: selectedSellerEntityInfo.entity_id,
                                enterprise: '',
                                entity_name:
                                    selectedSellerEntityInfo.entity_name,
                                entity_logo: null,
                                entity_description: '',
                                buyer_status: EntityStatus.SETUP_PENDING,
                                seller_status: EntityStatus.SETUP_PENDING,
                                verification_status:
                                    selectedSellerEntityInfo.verification_status as VerificationStatus,
                                contacts: {
                                    emails: [],
                                },
                                is_virtual: selectedSellerEntityInfo.is_virtual,
                            },
                            seller_contacts:
                                selectedSellerContactInfo?.length === 0
                                    ? []
                                    : selectedSellerContactInfo.map(
                                          (contact) => ({
                                              vendor_contact_id:
                                                  contact.vendor_contact_id,
                                              full_name: contact.full_name,
                                              primary_email:
                                                  contact.primary_email,
                                              emails: contact.emails.map(
                                                  (email) => ({
                                                      type: '',
                                                      email,
                                                  })
                                              ),
                                              phone_numbers:
                                                  contact.phone_numbers,
                                              tags: contact.tags,
                                          })
                                      ),
                            vendor_code:
                                selectedSellerContactInfo?.length > 0
                                    ? selectedSellerContactInfo[0]
                                          .enterprise_vendor_master.vendor_code
                                    : '',
                            no: 0,
                        };
                    }
                    globalVendors.push(nonPreferredVendor);
                }
            });

            let letListOfDistinchVendor: IVendorForEventItem[] = [];

            allItemVendors.forEach((vendor) => {
                if (
                    !letListOfDistinchVendor
                        ?.map((v) => v?.seller_entity?.entity_id)
                        ?.includes(vendor?.seller_entity?.entity_id)
                ) {
                    letListOfDistinchVendor?.push({
                        ...vendor,
                        id: vendor?.seller_entity?.entity_id,
                        no: 0,
                    });
                }
            });

            globalVendors.forEach((vendor) => {
                if (
                    !letListOfDistinchVendor
                        ?.map((v) => v?.seller_entity?.entity_id)
                        ?.includes(vendor?.seller_entity?.entity_id)
                ) {
                    letListOfDistinchVendor.push({
                        ...vendor,
                        id: vendor?.seller_entity?.entity_id,
                        no: 0,
                    });
                }
            });

            letListOfDistinchVendor = letListOfDistinchVendor.map((vendor) => {
                return cloneDeep(vendor);
            });

            setAllItemVendors(
                letListOfDistinchVendor.map((v, idx) => ({
                    ...v,
                    no: idx,
                    contract_id:
                        listOfContractsForItem.find(
                            (c) =>
                                c.seller_entity === v.seller_entity?.entity_id
                        )?.contract_id ?? 'asd',
                }))
            );
        }
    }, [
        allVendorMasterData,
        all_vendor_list,
        dispatch,
        itemDetail.seller_contacts_information,
        itemDetail.seller_entities,
        itemDetail.seller_entities_information,
        listOfContractsForItem,
        vendorInformation,
    ]);

    return {
        expanded,
        dataState,
        shippingAddressOptions,
        enterpriseItemDetails,
        errors,
        allItemVendors,
        setDataState,
        saveItemForLater,
        saveVendorContacts,
        saveVendorDetails,
        deleteItem,
        addNewNonPrefferedVendorToList,
        updateItemData,
        setExpanded,
        addItemToCart,
        deleteAttribute,
        updateAttribute,
        updateAttributeNew,
        deleteAttributeNew,
    };
}
