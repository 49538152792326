import {
    IAdditionalCost,
    IDiscount,
} from '../../../AdditionalCost/models/AdditionalCost.model';
import {
    AttachmentField,
    BooleanField,
    ChoiceField,
    CurrencyField,
    DateField,
    DateTimeField,
    DecimalField,
    IntegerField,
    TemplatesField,
    TextField,
} from '../../../FieldTypes/FieldTypes.model';

export enum TemplateStandardFieldsEnum {
    // bom detals section fields

    FINISHED_GOOD_CODE = 'FINISHED_GOOD_CODE',
    BOM_ID = 'BOM_ID',
    BASE_QUANTITY = 'BASE_QUANTITY',
    BOM_NAME = 'BOM_NAME',
    // BOM_MEASUREMENT_UNIT = 'BOM_MEASUREMENT_UNIT',
    CURRENCY = 'CURRENCY',
    BOM_ADDITIONAL_COST = 'BOM_ADDITIONAL_COST',

    // bom items section fields
    RAW_MATERIAL_CODE = 'RAW_MATERIAL_CODE',
    SUB_BOM_ID = 'SUB_BOM_ID',
    DESCRIPTION = 'DESCRIPTION',
    TARGET_RATE = 'TARGET_RATE',
    QUANTITY = 'QUANTITY',
    // MEASUREMENT_UNIT = 'MEASUREMENT_UNIT',
    ADDITIONAL_COSTS = 'ADDITIONAL_COSTS',
    SHIPPING_RATE = 'SHIPPING_RATE',
    TAXES = 'TAXES',
    DISCOUNT = 'DISCOUNT',
}

export const fieldsToIgnore: string[] = [
    'BOM measurement unit',
    'Currency',
    'Measurement unit',
    'Discount information',
];

export const TemplateStandardFieldBackendNames: {
    [BackendTemplateItemName: string]: TemplateStandardFieldsEnum;
} = {
    // bom details level
    'Finished good code': TemplateStandardFieldsEnum.FINISHED_GOOD_CODE,
    'BOM ID': TemplateStandardFieldsEnum.BOM_ID,
    'Base quantity': TemplateStandardFieldsEnum.BASE_QUANTITY,
    'Cost per unit': TemplateStandardFieldsEnum.TARGET_RATE,
    'BOM name': TemplateStandardFieldsEnum.BOM_NAME,
    // 'BOM measurement unit': TemplateStandardFieldsEnum.BOM_MEASUREMENT_UNIT,      not require
    'BOM currency': TemplateStandardFieldsEnum.CURRENCY,
    'BOM additional costs': TemplateStandardFieldsEnum.BOM_ADDITIONAL_COST,

    // bom item level
    'Raw material code': TemplateStandardFieldsEnum.RAW_MATERIAL_CODE,
    'Sub BOM ID': TemplateStandardFieldsEnum.SUB_BOM_ID,
    Description: TemplateStandardFieldsEnum.DESCRIPTION,
    'Target rate': TemplateStandardFieldsEnum.TARGET_RATE,
    Quantity: TemplateStandardFieldsEnum.QUANTITY,
    // 'Measurement unit': TemplateStandardFieldsEnum.ADDITIONAL_COSTS,             not required
    'Additional costs': TemplateStandardFieldsEnum.ADDITIONAL_COSTS,
    'Shipping rate': TemplateStandardFieldsEnum.ADDITIONAL_COSTS,
    Taxes: TemplateStandardFieldsEnum.TAXES,
    // 'Discount information': TemplateStandardFieldsEnum.ADDITIONAL_COSTS,         not required
    Discount: TemplateStandardFieldsEnum.DISCOUNT,
};

export enum BomStandardSection {
    BOM_DETAILS = 'BOM_DETAILS',
    ITEM_TERMS = 'ITEM_TERMS',
}

export type standardSectionsBackendName = 'BOM Details' | 'Item Terms';

export interface IBomTemplate {
    templateId: string;
    templateName: string;
    fieldSettings: IBOMTemplateFieldSetting;
    section: {
        [section in BomStandardSection]: IBomTemplateSectionDetails;
    };
}

export type ISectionField =
    | {
          fieldType: 'STANDARD';
          field: TemplateStandardFieldsEnum;
      }
    | {
          fieldType: 'CUSTOM';
          field: string;
      };

export interface IBomTemplateSectionDetails {
    label: string;
    fieldList: ISectionField[];
}

export interface IBOMTemplateFieldSetting {
    standardFields: BOMTemplateStandardFieldSettingsObj;
    customFields: {
        [field: string]: ITemplateCustomFieldOptions;
    };
}

export type BOMTemplateStandardFieldSettingsObj = {
    // bom details section
    [TemplateStandardFieldsEnum.FINISHED_GOOD_CODE]: ITemplateStandardFieldOptions;
    [TemplateStandardFieldsEnum.BOM_ID]: ITemplateStandardFieldOptions;
    [TemplateStandardFieldsEnum.BASE_QUANTITY]: ITemplateStandardFieldOptions;
    [TemplateStandardFieldsEnum.BOM_ADDITIONAL_COST]: ITemplateAdditionalCostOptions;
    [TemplateStandardFieldsEnum.BOM_NAME]: ITemplateStandardFieldOptions;
    [TemplateStandardFieldsEnum.CURRENCY]: ITemplateStandardFieldOptions;

    [TemplateStandardFieldsEnum.RAW_MATERIAL_CODE]: ITemplateStandardFieldOptions;
    [TemplateStandardFieldsEnum.SUB_BOM_ID]: ITemplateStandardFieldOptions;
    [TemplateStandardFieldsEnum.DESCRIPTION]: ITemplateStandardFieldOptions;
    [TemplateStandardFieldsEnum.TARGET_RATE]: ITemplateStandardFieldOptions;
    [TemplateStandardFieldsEnum.QUANTITY]: ITemplateStandardFieldOptions;
    [TemplateStandardFieldsEnum.ADDITIONAL_COSTS]: ITemplateAdditionalCostOptions;
    [TemplateStandardFieldsEnum.SHIPPING_RATE]: ITemplateStandardFieldOptions;
    [TemplateStandardFieldsEnum.TAXES]: ITemplateTaxesOptions;
    [TemplateStandardFieldsEnum.DISCOUNT]: ITemplateDiscountOptions;
};

export type ITemplateStandardFieldOptions =
    ITemplateStandardFieldOptionsBase & {
        standardFieldCategory: 'STANDARD';
    };

export type ITemplateAdditionalCostOptions =
    ITemplateStandardFieldOptionsBase & {
        standardFieldCategory: 'ADDITIONAL_COSTS';
        additionalCostOptions: ({
            is_required: boolean;
        } & IAdditionalCost)[];
    };

export type ITemplateTaxesOptions = ITemplateStandardFieldOptionsBase & {
    standardFieldCategory: 'TAX';
    taxesOptions: ({
        is_required: boolean;
    } & IAdditionalCost)[];
};

export type ITemplateDiscountOptions = ITemplateStandardFieldOptionsBase & {
    standardFieldCategory: 'DISCOUNT';
    discountOptions: ({
        is_required: boolean;
    } & IDiscount)[];
};

export interface ITemplateCustomFieldOptions
    extends ITemplateStandardFieldOptionsBase {
    type: CustomFieldType;
    constraints: IFieldTypeConstraints;
}

export type ITemplateStandardFieldOptionsBase = {
    label: string;
    description: string;
    // isItemLevel: boolean;
    buyerSettings: {
        showField: boolean;
        required: boolean;
    };
    sellerSettings: {
        showField: boolean;
        negotiable: boolean;
        isMandatory: boolean;
    };
    fieldLevel?: 'ITEM' | 'OTHER' | 'DELIVERY_SCHEDULE';
    // contraints: any;
};

export enum CustomFieldType {
    SHORT_TEXT = 'SHORT_TEXT',
    LONG_TEXT = 'LONG_TEXT',
    SHORTTEXT = 'SHORTTEXT',
    LONGTEXT = 'LONGTEXT',
    DECIMAL = 'DECIMAL',
    LOCATION = 'LOCATION',
    CURRENCY = 'CURRENCY',
    INTEGER = 'INTEGER',
    CHOICE = 'CHOICE',
    BOOLEAN = 'BOOLEAN',
    DATE = 'DATE',
    DATETIME = 'DATETIME',
    FLOAT = 'FLOAT',
    MULTI_CHOICE = 'MULTI_CHOICE',
    ATTACHMENT = 'ATTACHMENT',
    TEMPLATE = 'TEMPLATE',
    // MULTI_SELECT = 'MULTI_SELECT',
}

export type IFieldTypeConstraints =
    | BooleanField
    | DateField
    | DateTimeField
    | CurrencyField
    | IntegerField
    | DecimalField
    | TextField
    | ChoiceField
    | TemplatesField
    | AttachmentField;

export const blankBomTemplate: IBomTemplate = {
    templateName: '',
    templateId: '',
    fieldSettings: {
        customFields: {},
        standardFields: {
            CURRENCY: {
                buyerSettings: {
                    required: false,
                    showField: true,
                },
                sellerSettings: {
                    isMandatory: false,
                    negotiable: false,
                    showField: false,
                },
                description: '',
                label: '',
                standardFieldCategory: 'STANDARD',
            },
            ADDITIONAL_COSTS: {
                buyerSettings: {
                    required: false,
                    showField: true,
                },
                sellerSettings: {
                    isMandatory: false,
                    negotiable: false,
                    showField: false,
                },
                description: '',
                label: '',

                additionalCostOptions: [],
                standardFieldCategory: 'ADDITIONAL_COSTS',
            },
            BOM_NAME: {
                buyerSettings: {
                    required: true,
                    showField: true,
                },
                description: '',
                standardFieldCategory: 'STANDARD',
                label: 'BOM_NAME',
                sellerSettings: {
                    isMandatory: false,
                    negotiable: false,
                    showField: false,
                },
            },
            BASE_QUANTITY: {
                buyerSettings: {
                    required: false,
                    showField: true,
                },

                sellerSettings: {
                    isMandatory: false,
                    negotiable: false,
                    showField: false,
                },
                description: '',
                label: '',
                standardFieldCategory: 'STANDARD',
            },
            BOM_ADDITIONAL_COST: {
                buyerSettings: {
                    required: false,
                    showField: true,
                },
                sellerSettings: {
                    isMandatory: false,
                    negotiable: false,
                    showField: false,
                },
                description: '',
                label: '',
                additionalCostOptions: [],
                standardFieldCategory: 'ADDITIONAL_COSTS',
            },
            BOM_ID: {
                buyerSettings: {
                    required: false,
                    showField: true,
                },
                sellerSettings: {
                    isMandatory: false,
                    negotiable: false,
                    showField: false,
                },
                description: '',
                label: '',
                standardFieldCategory: 'STANDARD',
            },
            DESCRIPTION: {
                buyerSettings: {
                    required: false,
                    showField: true,
                },
                sellerSettings: {
                    isMandatory: false,
                    negotiable: false,
                    showField: false,
                },
                description: '',
                label: '',
                standardFieldCategory: 'STANDARD',
            },
            DISCOUNT: {
                buyerSettings: {
                    required: false,
                    showField: true,
                },
                sellerSettings: {
                    isMandatory: false,
                    negotiable: false,
                    showField: false,
                },
                description: '',
                label: '',
                discountOptions: [],
                standardFieldCategory: 'DISCOUNT',
            },
            FINISHED_GOOD_CODE: {
                buyerSettings: {
                    required: false,
                    showField: true,
                },
                sellerSettings: {
                    isMandatory: false,
                    negotiable: false,
                    showField: false,
                },
                description: '',
                label: '',
                standardFieldCategory: 'STANDARD',
            },
            QUANTITY: {
                buyerSettings: {
                    required: false,
                    showField: true,
                },
                sellerSettings: {
                    isMandatory: false,
                    negotiable: false,
                    showField: false,
                },
                description: '',
                label: '',
                standardFieldCategory: 'STANDARD',
            },
            RAW_MATERIAL_CODE: {
                buyerSettings: {
                    required: false,
                    showField: true,
                },
                sellerSettings: {
                    isMandatory: false,
                    negotiable: false,
                    showField: false,
                },
                description: '',
                label: '',
                standardFieldCategory: 'STANDARD',
            },
            SHIPPING_RATE: {
                buyerSettings: {
                    required: false,
                    showField: true,
                },
                sellerSettings: {
                    isMandatory: false,
                    negotiable: false,
                    showField: false,
                },
                description: '',
                label: '',
                standardFieldCategory: 'STANDARD',
            },
            SUB_BOM_ID: {
                buyerSettings: {
                    required: false,
                    showField: true,
                },
                sellerSettings: {
                    isMandatory: false,
                    negotiable: false,
                    showField: false,
                },
                description: '',
                label: '',
                standardFieldCategory: 'STANDARD',
            },
            TARGET_RATE: {
                buyerSettings: {
                    required: false,
                    showField: true,
                },
                sellerSettings: {
                    isMandatory: false,
                    negotiable: false,
                    showField: false,
                },
                description: '',
                label: '',
                standardFieldCategory: 'STANDARD',
            },
            TAXES: {
                buyerSettings: {
                    required: false,
                    showField: true,
                },
                sellerSettings: {
                    isMandatory: false,
                    negotiable: false,
                    showField: false,
                },
                description: '',
                label: '',
                taxesOptions: [],
                standardFieldCategory: 'TAX',
            },
        },
    },
    section: {
        BOM_DETAILS: {
            fieldList: [],
            label: '',
        },
        ITEM_TERMS: {
            fieldList: [],
            label: '',
        },
    },
};
