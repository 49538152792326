import { Box } from '@mui/material';
import { createContext, useContext } from 'react';
import {
    FWAccordion,
    FWAccordionDetails,
    FWAccordionSummary,
} from '../../../Common/FWAccordian';
import useCartItemAccordionHook from '../../Hooks/Relationship/useCartItemAccordionHook';
import { ICartItemAccordionContext } from '../../Interfaces/itemCartItemInterfaces';
import { ItemCartAccessContext } from '../../Pages/ItemCartAccess';
import CartItemAccordionHeader from './CartItemAccordionHeader';
import CartItemAccordionSummary from './CartItemAccordionSummary';
import CartItemAccordionSummaryData from './CartItemAccordionSummaryData';
import CartItemAdditionalDetails from './CartItemAdditionalDetails';
import CartItemPaymentAndDeliveryTerms from './CartItemPaymentAndDeliveryTerms';
import CartItemVendorInformation from './CartItemVendorInformation';

export interface ICartItemAccordionProps {
    cart_item_id: string;
    accordion_index: number;
    itemInCart: boolean;
}

export const CartItemAccordionContext =
    createContext<ICartItemAccordionContext>({
        dataState: {
            error: false,
            error_msg: '',
            loading: false,
            saved: true,
        },
        enterpriseItemDetails: null,
        shippingAddressOptions: [],
        accordion_index: 0,
        allItemVendors: [],
        cartItemId: '',
        expandAccordion: () => {},
        addNewNonPrefferedVendorToList: async () => {},
        addItemToCart: () => {},
        saveItemForLater: () => {},
        deleteItem: () => {},
        itemInCart: true,
        open: false,
        deleteAttribute: (index: number) => {},
        updateAttribute: () => {},
        updateItemData: () => {},
        errors: {
            custom_sections: {},
            delivery_information: [],
            paymentTerms_poItem_allocationRows: [],
        },
        saveVendorDetails: () => {},
        saveVendorContacts: () => {},
        updateAttributeNew: () => {},
        deleteAttributeNew: () => {},
    });

export default function CartItemAccordion({
    cart_item_id,
    accordion_index,
    itemInCart,
}: ICartItemAccordionProps) {
    const { templateDetails } = useContext(ItemCartAccessContext);

    const {
        expanded,
        dataState,
        enterpriseItemDetails,
        shippingAddressOptions,
        errors,
        allItemVendors,
        addItemToCart,
        saveItemForLater,
        saveVendorContacts,
        saveVendorDetails,
        setExpanded,
        updateItemData,
        deleteAttribute,
        deleteItem,
        updateAttribute,
        addNewNonPrefferedVendorToList,
        updateAttributeNew,
        deleteAttributeNew,
    } = useCartItemAccordionHook(cart_item_id, itemInCart, accordion_index);

    const showPaymentDeliverySection =
        templateDetails.sections.PAYMENT_AND_DELIVERY_TERMS.fieldList?.length >
            0 &&
        (templateDetails.fieldSettings.standardFields['PAYMENT_TERMS']
            .buyerSettings.showField ||
            templateDetails.fieldSettings.standardFields['LEAD_TIME']
                .buyerSettings.showField ||
            templateDetails.fieldSettings.standardFields['INCOTERMS']
                .buyerSettings.showField ||
            templateDetails.fieldSettings.standardFields['GR_TOLERANCE']
                .buyerSettings.showField ||
            templateDetails.fieldSettings.standardFields['SHIPPING_ADDRESS']
                .buyerSettings.showField ||
            templateDetails.sections.PAYMENT_AND_DELIVERY_TERMS.fieldList.filter(
                (f) => f.fieldType === 'CUSTOM'
            ).length > 0);

    const showAdditionalInformationSection =
        templateDetails.sections.ADDITIONAL_DETAILS.fieldList?.length > 0 &&
        (templateDetails.fieldSettings.standardFields['ADDITIONAL_INFORMATION']
            .buyerSettings.showField ||
            templateDetails.sections.ADDITIONAL_DETAILS.fieldList.filter(
                (f) => f.fieldType === 'CUSTOM'
            ).length > 0);

    const showVendorInformationSection =
        templateDetails.sections.VENDOR_INFORMATION.fieldList?.length > 0 &&
        templateDetails.fieldSettings.standardFields['VENDOR'].buyerSettings
            .showField;

    return (
        <Box id={`cart-item-box-${accordion_index}`} width={'100%'}>
            <CartItemAccordionContext.Provider
                value={{
                    dataState,
                    accordion_index,
                    cartItemId: cart_item_id,
                    expandAccordion: () => {
                        setExpanded(true);
                    },
                    enterpriseItemDetails: enterpriseItemDetails ?? null,
                    itemInCart,
                    shippingAddressOptions,
                    open: expanded,
                    errors,
                    allItemVendors,
                    deleteItem,
                    deleteAttribute,
                    addNewNonPrefferedVendorToList,
                    addItemToCart,
                    saveItemForLater,
                    updateAttribute,
                    saveVendorContacts,
                    updateItemData,
                    saveVendorDetails,
                    updateAttributeNew,
                    deleteAttributeNew,
                }}
            >
                <FWAccordion
                    expanded={expanded}
                    onChange={() => setExpanded((prev) => !prev)}
                    sx={{
                        border: '1px solid',
                        '& .MuiPaper-root': {
                            borderColor: 'dividerColor.main',
                            width: '100% !important',
                            minWidth: '75% !important',
                        },
                    }}
                >
                    <FWAccordionSummary
                        expandIcon={<></>}
                        sx={{
                            '&.Mui-focusVisible': {
                                backgroundColor: 'white',
                            },
                            width: '100%',
                        }}
                    >
                        <Box width={'100%'}>
                            <CartItemAccordionHeader />
                            <CartItemAccordionSummaryData />
                            <CartItemAccordionSummary />
                        </Box>
                    </FWAccordionSummary>
                    <FWAccordionDetails sx={{ width: '100%' }}>
                        {showVendorInformationSection && (
                            <CartItemVendorInformation />
                        )}
                        {showPaymentDeliverySection && (
                            <CartItemPaymentAndDeliveryTerms />
                        )}
                        {showAdditionalInformationSection && (
                            <CartItemAdditionalDetails />
                        )}
                    </FWAccordionDetails>
                </FWAccordion>
            </CartItemAccordionContext.Provider>
        </Box>
    );
}
