import { toast } from 'react-toastify';
import { ICurrencyDetails } from '../../Models/Currency.model';
import { fetchEntities } from '../../Organizations/Admin/Services/EnterpriseService';
import { baseApiSlice } from '../../Redux/Slices/BaseApiSlice';
import { del, get, post, put } from '../../Utils/api';
import { IForumlaWithIfElseAndOperators } from '../Interfaces/AdditionalCostInterfaces';
import { INewCost } from '../components/AddAdditionalCostPopup';
import {
    CostSourceEnum,
    IAdditionalCostInformation,
} from '../models/AdditionalCost.model';

export interface IAdditionalCostData {
    additional_cost_id: string;
    created_datetime: string;
    modified_datetime: string;
    deleted_datetime: string;
    cost_name: string;
    cost_type: string;
    cost_value: number;
    currency: string;
    cost_source: CostSourceEnum;
    allocation_type: string | null;
    type: 'ADDITIONAL_COST' | 'TAX';
    is_calculated: boolean;
    is_required: boolean;
    is_visible: boolean;
    is_negotiable: boolean;
    is_mandatory: boolean;
    description: string | null;
    formula: {
        formula_id: string;
        formula_name: string;
        formula: IForumlaWithIfElseAndOperators;
    } | null;
    modules: ['EVENT'];
    status: 'ACTIVE' | 'DISABLED' | 'INACTIVE';
    created_by_user: string;
    created_by_user_name: string;
    modified_by_user: string;
    modified_by_user_name: string;
    deleted_by_user: string | null;
    enterprise: string;
    field_level: 'ITEM' | 'DELIVERY_SCHEDULE' | 'BOM' | 'OTHER';
    additional_cost_used?: boolean;
}

interface IAdditionalCostCount {
    all: number;
    active: number;
    disabled: number;
}

interface IFetchAdditionalCostData {
    data: IAdditionalCostData[];
    metadata: {
        current_page: number;
        has_next: boolean;
        has_previous: boolean;
        total_pages: number;
        page_range: {
            start: number;
            stop: number;
        };
        count: number;
    };
    counts: IAdditionalCostCount;
}

export interface IAdditionalCostPageDetails {
    current_page: number;
    has_next: boolean;
    has_previous: boolean;
    total_pages: number;
    count: number;
    page_range: {
        start: number;
        stop: number;
    };
}

export interface IAddtiionalCostTabCountData {
    tab_counts: {
        all: number;
        active: number;
        disabled: number;
    };
}
export interface Contacts {
    emails: string[];
    phone_numbers: string[];
}

export interface Defaults {
    homepage: string;
    timezone: string;
    number_format: string;
    primary_entity_id: string;
}

export interface IUserListResponse {
    user_id: string;
    enterprise: string;
    enterprise_name: string;
    email: string;
    name: string;
    first_name: any;
    last_name: any;
    additional_details: any;
    role: string;
    description: string | null;
    status: string;
    is_email_verified: boolean;
    manager_count: number;
    designation: string;
    permission_count: number;
    user_picture_url: string;
    contacts: Contacts;
    defaults: Defaults;
    created_datetime: string;
    modified_datetime: string;
}

export interface IEntityListResponse {
    entity_id: string;
    default_procurement_currency: DefaultProcurementCurrency;
    vendor_profile: any;
    entity_logo: any[];
    enterprise_name: string;
    created_datetime: string;
    modified_datetime: string;
    deleted_datetime: any;
    entity_name: string;
    entity_description: any;
    entity_doing_business_as: string;
    buyer_status: string;
    seller_status: string;
    verification_status: string;
    setup_information: SetupInformation;
    is_virtual: boolean;
    is_user_email_verified: boolean;
    contacts: EntityContact;
    created_by_user: string;
    modified_by_user: any;
    deleted_by_user: any;
    enterprise: string;
}

export interface DefaultProcurementCurrency {
    entry_id: string;
    currency_code_abbreviation: string;
    currency_name: string;
    currency_symbol: string;
}

export interface SetupInformation {
    buyer: Buyer;
    seller: Seller;
}

export interface Buyer {
    item: boolean;
    vendor: boolean;
    identification: boolean;
    billing_address: boolean;
    shipping_address: boolean;
    terms_and_conditions: boolean;
}

export interface Seller {
    identification: boolean;
    primary_address: boolean;
}

export interface EntityContact {
    emails: string[];
    websites: string[];
    phone_numbers: string[];
}

export const fetchAdditionalCostData = async ({
    search_text,
    items_per_page,
    sort_fields,
    page_number,
}: {
    page_number: number;
    items_per_page: number;
    sort_fields: { field: string; ascending: boolean }[];
    search_text: string;
}): Promise<{
    additionalCostList: IAdditionalCostData[];
    pageDetails: IAdditionalCostPageDetails;
    countDetails: IAdditionalCostCount;
}> => {
    let payload = {
        dashboard_view: 'additional_cost',
        tab: 'all',
        query_data: {},
        search_text,
        page_number,
        sort_fields,
        items_per_page,
    };

    let res = await post<IAdditionalCostData, IFetchAdditionalCostData>(
        '/dashboard/',
        payload
    );

    return {
        additionalCostList: res.data.data,
        pageDetails: res.data.metadata,
        countDetails: res.data.counts,
    };
};

export const createNewAdditionalCost = async (additionalCostData: INewCost) => {
    let response = await fetchEntities();

    let entitiyIds = response.map((entity) => entity.entityId);

    additionalCostData.buyer_entity = entitiyIds;

    let res = await post<INewCost, any>(
        '/additional_cost/create/',
        additionalCostData
    );
    return res;
};

export const updateAdditionalCost = async (
    additionalCostData: INewCost,
    additionalCostId: string
) => {
    try {
        let response = await fetchEntities();

        let entitiyIds = response.map((entity) => entity.entityId);

        additionalCostData.buyer_entity = entitiyIds;

        let res = await put<INewCost, any>(
            `/additional_cost/${additionalCostId}/update/`,
            additionalCostData
        );

        toast.success('Additional cost updated successfully');
        return res;
    } catch (e) {
        toast.error('Error while updating additional cost');
    }
};

export const deleteAdditionalCost = async (additionalCostId: string) => {
    try {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        let res = await del<any>(
            `/additional_cost/${additionalCostId}/delete/`
        );

        return true;
    } catch (e) {
        return false;
    }
};

export const getAllAdditionalCostNames = async () => {
    let res = await get<{ cost_name: string }[]>(`/additional_cost/names/`);

    let sol = res.data.map((costNameObj) =>
        costNameObj.cost_name.toLowerCase()
    );

    return sol;
};

export const getAllAdditionalCostForTemplate = async () => {
    let res = await get<any>(`/additional_cost/`);

    return res.data;
};

export const additionalCostApiSlice = baseApiSlice.injectEndpoints({
    endpoints: (builder) => ({
        getAdditionalCostTabCount: builder.mutation<
            {
                tab_counts: {
                    active: number;
                    disabled: number;
                    all: number;
                };
            },
            {}
        >({
            query: () => ({
                url: '/dashboard/tab_counts/',
                method: 'POST',
                body: {
                    dashboard_view: 'additional_cost',
                },
            }),
        }),
        getAdditionalCostDataByID: builder.query<
            IAdditionalCostInformation[],
            {
                additionalCostIds: string[];
            }
        >({
            queryFn: async ({ additionalCostIds }) => {
                try {
                    if (additionalCostIds?.length > 0) {
                        let additionalCostIdURL = additionalCostIds
                            .map((id, index) =>
                                index === 0
                                    ? `?additional_cost_ids[]=${id}`
                                    : `&additional_cost_ids[]=${id}`
                            )
                            .join('');

                        let resp = await get<IAdditionalCostInformation[]>(
                            `/additional_cost/${additionalCostIdURL}`
                        );

                        return {
                            data: resp.data,
                        };
                    }
                    return {
                        data: [] as IAdditionalCostInformation[],
                    };
                } catch (err: any) {
                    return { error: err };
                }
            },
        }),
        getAdditionalCostData: builder.mutation<
            {
                additionalCostList: IAdditionalCostData[];
                pageDetails: IAdditionalCostPageDetails;
                countDetails: IAdditionalCostCount;
            },
            {
                page_number: number;
                items_per_page: number;
                sort_fields: { field: string; ascending: boolean }[];
                search_text: string;
            }
        >({
            query: ({
                page_number,
                items_per_page,
                search_text,
                sort_fields,
            }) => ({
                url: `/dashboard/`,
                method: 'POST',
                body: {
                    dashboard_view: 'additional_cost',
                    tab: 'all',
                    query_data: {},
                    search_text,
                    page_number,
                    sort_fields,
                    items_per_page,
                },
            }),
            transformResponse: (
                baseQueryReturnValue: IFetchAdditionalCostData,
                meta,
                arg
            ) => {
                let res: {
                    additionalCostList: IAdditionalCostData[];
                    pageDetails: IAdditionalCostPageDetails;
                    countDetails: IAdditionalCostCount;
                } = {
                    additionalCostList: baseQueryReturnValue.data,
                    countDetails: baseQueryReturnValue.counts,
                    pageDetails: baseQueryReturnValue.metadata,
                };

                return res;
            },
        }),
        getAdditionalCostDataWithTax: builder.query<
            {
                additionalCostList: IAdditionalCostData[];
                pageDetails: IAdditionalCostPageDetails;
                countDetails: IAdditionalCostCount;
            },
            {
                page_number: number;
                items_per_page: number;
                sort_fields: { field: string; ascending: boolean }[];
                search_text: string;
                query_data?: {
                    type: string;
                    field_level: string;
                };
            }
        >({
            query: ({
                page_number,
                items_per_page,
                search_text,
                sort_fields,
                query_data,
            }) => ({
                url: `/dashboard/`,
                method: 'POST',
                body: {
                    dashboard_view: 'additional_cost',
                    tab: 'all',
                    query_data: query_data ?? {},
                    search_text,
                    page_number,
                    sort_fields,
                    items_per_page,
                },
            }),
            transformResponse: (
                baseQueryReturnValue: IFetchAdditionalCostData,
                meta,
                arg
            ) => {
                let res: {
                    additionalCostList: IAdditionalCostData[];
                    pageDetails: IAdditionalCostPageDetails;
                    countDetails: IAdditionalCostCount;
                } = {
                    additionalCostList: baseQueryReturnValue.data,
                    countDetails: baseQueryReturnValue.counts,
                    pageDetails: baseQueryReturnValue.metadata,
                };

                return res;
            },
        }),
        getAllCurrencyOptions: builder.query<ICurrencyDetails[], {}>({
            query: () => ({
                url: `/backbone/currency_code`,
            }),
            transformResponse: (
                baseQueryReturnValue: ICurrencyDetails[],
                meta,
                args
            ) => {
                return baseQueryReturnValue.sort((a, b) =>
                    a.currency_code_abbreviation.localeCompare(
                        b.currency_code_abbreviation
                    )
                );
            },
        }),
        getAdditionalCostFromAdmin: builder.query<
            IAdditionalCostInformation[],
            {}
        >({
            query: () => ({
                url: `/additional_cost/`,
            }),
        }),
        getAllAdditionalCostNames: builder.query<string[], {}>({
            query: () => ({
                url: `/additional_cost/names/`,
            }),
            transformResponse: (
                baseQueryReturnValue: { cost_name: string }[],
                meta,
                args
            ) => {
                return baseQueryReturnValue.map((costNameObj) =>
                    costNameObj.cost_name.toLowerCase()
                );
            },
        }),
        getDefaultCurrencyId: builder.query<
            string,
            {
                entityId: string;
            }
        >({
            query: ({ entityId }) => ({
                url: `/organization/entity/${entityId}/`,
            }),
            transformResponse: (baseQueryReturnValue: any, meta, args) => {
                return baseQueryReturnValue.default_procurement_currency
                    .entry_id;
            },
        }),
    }),
});

export const {
    useGetAdditionalCostTabCountMutation,
    useGetAdditionalCostDataMutation,
    useGetAllCurrencyOptionsQuery,
    useGetAllAdditionalCostNamesQuery,
    useGetAdditionalCostFromAdminQuery,
    useGetDefaultCurrencyIdQuery,
    useGetAdditionalCostDataByIDQuery,
    useGetAdditionalCostDataWithTaxQuery,
} = additionalCostApiSlice;

export const getAdditionalCostDataByID = async (
    additionalCostIds: string[]
) => {
    try {
        let additionalCostIdURL = additionalCostIds
            ?.filter((costId) => Boolean(costId))
            .map((id, index) =>
                index === 0
                    ? `?additional_cost_ids[]=${id}`
                    : `&additional_cost_ids[]=${id}`
            )
            .join('');

        let resp = await get<IAdditionalCostInformation[]>(
            `/additional_cost/${additionalCostIdURL}`
        );

        return resp.data;
    } catch (err: any) {
        throw new Error('Failed to get additional cost data');
    }
};

// export const additionalCostApiSlice = baseApiSlice.injectEndpoints({
//     endpoints: (builder) => ({
//         listAdditionalCosts : builder.query<IAdditionalCostData, {}>({
//             query : () => '/'

//         }),
//         listAdditionalCostsViaDashboard: builder.mutation<
//             IFetchAdditionalCostData,
//             IAdditionalCostPayload
//         >(),
//     }),
// });

// export const projectApiSlice = baseApiSlice.injectEndpoints({
//     endpoints: (builder) => ({
//         listProjectsViaDasboard: builder.mutation<
//             {
//                 data: IAdditionalCostData[];
//                 metadata: {
//                     current_page: number;
//                     has_next: boolean;
//                     has_previous: boolean;
//                     total_pages: number;
//                     page_range: {
//                         start: number;
//                         stop: number;
//                     };
//                     count: number;
//                 };
//                 counts: IAdditionalCostCount;
//             },
//             {
//                 search_text?: string;
//                 sort_fields?: { field: string; ascending: boolean }[];
//                 items_per_page?: number;
//                 page_number: number;
//                 query_data?: any;
//                 tab: string;
//             }
//         >({
//             query: ({
//                 search_text = '',
//                 sort_fields = [],
//                 page_number = 1,
//                 items_per_page = 10,
//                 query_data = {},
//                 tab = 'all',
//             }) => {
//                 return {
//                     url: '/dashboard/',
//                     method: 'POST',
//                     body: {
//                         dashboard_view: `additional_cost`,
//                         tab,
//                         search_text,
//                         sort_fields,
//                         page_number,
//                         items_per_page,
//                         query_data,
//                     },
//                 };
//             },
//         }),
//     }),
// });

export const getPrimaryEntityId = async () => {
    try {
        const res = await get<IUserListResponse[]>(`/organization/users/`);

        const primaryEntityId = res.data[0].defaults.primary_entity_id;

        return primaryEntityId;
    } catch (e) {}
};

export const getPrimayEntityCurrencyId = async (primaryEntityid: string) => {
    try {
        const res = await get<IEntityListResponse[]>(`/organization/entity/`);

        const primaryEntityData = res.data.find(
            (entity) => entity.entity_id === primaryEntityid
        );

        if (primaryEntityData) {
            return primaryEntityData.default_procurement_currency.entry_id;
        }

        return null;
    } catch (err) {
        return null;
    }
};
