import { createSlice } from '@reduxjs/toolkit';
import { cloneDeep } from 'lodash';
import { RequisitionDetails } from '../Interfaces/OrderInterfaces';

export interface IEventIntegrationStore {
    itemsForEventCreation: {
        [key: string]: string[];
    };
    fieldsToExport: {
        customFields: any[];
        standardFields: any[];
    };
    source: 'CART_ORDERS' | 'REQUISITIONS' | '';
    allOrders: RequisitionDetails[] | null;
    itemsAvailable: any;
    itemsForEvents: any;
    selectedOrders: RequisitionDetails[] | null; //ordersForEvent
    selectedReqId: string;
    requisitionTemplates: any[];
    keyMap: {
        [key: string]: string;
    };
}

export interface IUpdateEventIntegrationStoreAction {
    type: string;
    payload:
        | {
              type: 'UPDATE_ALL_ORDERS';
              value: RequisitionDetails[];
          }
        | {
              type: 'RESET_ALL_ORDERS';
              value: any;
          }
        | {
              type: 'UPDATE_SELECTED_ORDERS';
              value: any;
          }
        | {
              type: 'UPDATE_ITEMS_FOR_EVENT_CREATION';
              value: any;
          }
        | {
              type: 'UPDATE_CUSTOM_FIELDS';
              value: string[];
          }
        | {
              type: 'UPDATE_STANDARD_FIELDS';
              value: string[];
          }
        | {
              type: 'UPDATE_SOURCE';
              value: 'CART_ORDERS' | 'REQUISITIONS';
          }
        | {
              type: 'UPDATE_ITEMS_AVAILABLE';
              value: any;
          }
        | {
              type: 'UPDATE_ITEMS_FOR_EVENTS';
              value: any;
          }
        | {
              type: 'UPDATE_SELECTED_REQ_ID';
              value: string;
          }
        | {
              type: 'UPDATE_TEMPLATES';
              value: any;
          }
        | {
              type: 'REMOVE_REQUISITIONS';
              value: string[];
          }
        | {
              type: 'REMOVE_ITEMS';
              value: string[];
          }
        | {
              type: 'RESET_STATE';
              value: null;
          }
        | {
              type: 'UPDATE_KEY_MAP';
              value: { [key: string]: string };
          };
}

export const EventIntegrationSlice = createSlice<
    IEventIntegrationStore,
    {
        updateEventIntegrationStore: (
            state: IEventIntegrationStore,
            action: IUpdateEventIntegrationStoreAction
        ) => void;
    },
    string
>({
    name: 'EventIntegrationStore',
    initialState: {
        itemsForEventCreation: {},
        fieldsToExport: {
            customFields: [],
            standardFields: [],
        },
        keyMap: {},
        source: '',
        selectedOrders: null,
        allOrders: [],
        itemsAvailable: [],
        itemsForEvents: [],
        selectedReqId: '',
        requisitionTemplates: [],
    },
    reducers: {
        updateEventIntegrationStore: (
            state: IEventIntegrationStore,
            action: IUpdateEventIntegrationStoreAction
        ) => {
            try {
                switch (action.payload.type) {
                    case 'UPDATE_ALL_ORDERS':
                        const prevOrders = cloneDeep(state.allOrders);
                        state.allOrders = prevOrders
                            ? prevOrders.concat(action.payload.value)
                            : action.payload.value;
                        break;
                    case 'UPDATE_SELECTED_ORDERS':
                        state.selectedOrders = action.payload.value;
                        break;
                    case 'UPDATE_CUSTOM_FIELDS':
                        state.fieldsToExport.customFields =
                            action.payload.value;
                        break;
                    case 'UPDATE_STANDARD_FIELDS':
                        state.fieldsToExport.standardFields =
                            action.payload.value;
                        break;
                    case 'UPDATE_ITEMS_FOR_EVENT_CREATION':
                        state.itemsForEventCreation = action.payload.value;
                        break;
                    case 'UPDATE_SOURCE':
                        state.source = action.payload.value;
                        break;
                    case 'UPDATE_ITEMS_AVAILABLE':
                        state.itemsAvailable = action.payload.value;
                        const listOfIds = state.itemsAvailable.map(
                            (data: any) => data.requisition
                        );
                        state.selectedOrders =
                            state.selectedOrders?.filter((data) =>
                                listOfIds.includes(data.requisition_id)
                            ) ?? [];
                        break;
                    case 'UPDATE_ITEMS_FOR_EVENTS':
                        state.itemsForEvents = action.payload.value;
                        break;
                    case 'UPDATE_SELECTED_REQ_ID':
                        state.selectedReqId = action.payload.value;
                        break;
                    case 'UPDATE_TEMPLATES':
                        let templates = cloneDeep(state.requisitionTemplates);
                        templates = [...templates, action.payload.value];
                        state.requisitionTemplates = templates;

                        break;
                    case 'REMOVE_REQUISITIONS':
                        state.selectedOrders =
                            state.selectedOrders?.filter(
                                (order) =>
                                    !action.payload.value.includes(
                                        order.requisition_id
                                    )
                            ) ?? [];
                        state.itemsAvailable = state.itemsAvailable.filter(
                            (item: any) =>
                                !action.payload.value.includes(item.requisition)
                        );
                        state.itemsForEvents = state.itemsAvailable.map(
                            (item: any) => {
                                return {
                                    requisition_item_id:
                                        item.requisition_item_id,
                                    enterprise_item_id:
                                        item.item_information.item_id,
                                };
                            }
                        );
                        break;
                    case 'UPDATE_KEY_MAP':
                        state.keyMap = action.payload.value;
                        break;
                    case 'RESET_STATE':
                        state.allOrders = [];
                        state.fieldsToExport = {
                            customFields: [],
                            standardFields: [],
                        };
                        state.selectedOrders = null;
                        state.itemsAvailable = [];
                        state.itemsForEvents = [];
                        state.selectedReqId = '';
                        state.source = '';
                        state.requisitionTemplates = [];
                        break;

                    case 'RESET_ALL_ORDERS':
                        state.allOrders = [];
                }
            } catch (err) {}
        },
    },
});

export const { updateEventIntegrationStore } = EventIntegrationSlice.actions;
const EventIntegrationStore = EventIntegrationSlice.reducer;
export default EventIntegrationStore;
