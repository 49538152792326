import {
    Badge,
    Box,
    CircularProgress,
    Divider,
    Grid,
    Typography,
} from '@mui/material';
import { cloneDeep, isEmpty } from 'lodash';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { toast } from 'react-toastify';
import { FWTooltip } from '../../Common/FWCustomTooltip';
import FWIcon from '../../Common/FWIcon';
import { FWTypography } from '../../Common/FWTypograhy';
import { getFWDateTime } from '../../Common/Utils/DateUtils';
import { IFileResourceType } from '../../Components/Shared/UploadFile';
import EditAttachmentsButtonAndPopover from '../../Global/Containers/EditAttachmentsButtonAndPopover';
import { IBulkImport } from '../../Global/Interfaces/BulkImportInterface';
import { downloadFile } from '../../Global/Services/FileStorageService';
import { IAttachment } from '../../Models/RFQ.model';
import {
    CreateDocumentErrorDesc,
    DocumentStateEnum,
    IDocumentCustomField,
    IDocumentDetails,
    IDocumentDetailsErrors,
    INewDocument,
    updateCustomDocumentDetailsActionType,
} from '../Constants/Document.model';
import {
    IDocumentTemplate,
    ISectionField,
} from '../Constants/Interfaces/documentTemplate';
import { ITemplateCustomFieldOptions } from '../../Global/Interfaces/TemplateInterface';

export interface IDocumentCustomFieldProps {
    templateDetails: IDocumentTemplate;
    documentDetails: IDocumentDetails;
    sectionName: string;
    updateDocumentDetails: (
        field: updateCustomDocumentDetailsActionType,
        value: any
    ) => void;
    errors: IDocumentDetailsErrors;
    gridSize: number;
    sellerDocument: INewDocument;
    sellerDocumentDetails: IDocumentDetails;
}

export const SellerDocumentCustomFieldViewMode = ({
    documentDetails,
    templateDetails,
    sectionName,
    updateDocumentDetails,
    errors,
    gridSize,
    sellerDocument,
    sellerDocumentDetails,
}: IDocumentCustomFieldProps) => {
    const sectionNameInPayload =
        sectionName === 'DOCUMENT_DETAIL'
            ? templateDetails.sections.DOCUMENT_DETAIL.label
            : sectionName;

    const [localCopyOfCustomFieldNeg] = useState<{
        section_list: {
            name: string;
            fields: IDocumentCustomField[];
        }[];
    }>({
        section_list:
            documentDetails.custom_fields_negotiate.section_list.filter(
                (section) => section.name === sectionNameInPayload
            ) ?? [
                {
                    name: sectionNameInPayload,
                    fields: [],
                },
            ],
    });
    const [
        localCopyOfSellerCustomFieldNeg,
        setLocalCopyOfSellerCustomFieldNeg,
    ] = useState<{
        section_list: {
            name: string;
            fields: IDocumentCustomField[];
        }[];
    }>({
        section_list:
            sellerDocumentDetails.custom_fields_negotiate.section_list.filter(
                (section) => section.name === sectionNameInPayload
            ) ?? [
                {
                    name: sectionNameInPayload,
                    fields: [],
                },
            ],
    });

    const [localCopyOfCustomFieldNogNeg] = useState<{
        section_list: {
            name: string;
            fields: IDocumentCustomField[];
        }[];
    }>({
        section_list: documentDetails.custom_fields.section_list.filter(
            (section) => section.name === sectionNameInPayload
        ) ?? [
            {
                name: sectionNameInPayload,
                fields: [],
            },
        ],
    });

    const [
        localCopyOfSellerCustomFieldNogNeg,
        setLocalCopyOfSellerCustomFieldNonNeg,
    ] = useState<{
        section_list: {
            name: string;
            fields: IDocumentCustomField[];
        }[];
    }>({
        section_list: sellerDocumentDetails.custom_fields.section_list.filter(
            (section) => section.name === sectionNameInPayload
        ) ?? [
            {
                name: sectionNameInPayload,
                fields: [],
            },
        ],
    });

    const [docAttachments, setDocAttachments] = useState<{
        [customFieldName: string]: IAttachment[];
    }>({});

    const templateCF = templateDetails.sections[sectionName].fieldList?.filter(
        (field) => field.fieldType === 'CUSTOM'
    );

    const customSort = (a: ISectionField, b: ISectionField) => {
        const order: {
            [fieldType: string]: number;
        } = {
            LONGTEXT: 0,
            ATTACHMENT: 1,
            TEMPLATE: 2,
        };

        const typeA = templateDetails.fieldSettings.customFields[a.field]
            ?.constraints.fieldType as string;
        const typeB = templateDetails.fieldSettings.customFields[b.field]
            ?.constraints.fieldType as string;

        if (order[typeA] < order[typeB]) {
            return -1;
        } else if (order[typeA] > order[typeB]) {
            return 1;
        } else {
            return 0;
        }
    };

    const updateDocAttachment = useCallback(() => {
        setDocAttachments((prev) => {
            let newPrev = cloneDeep(prev);

            const updateFieldAttachments = (field: any) => {
                if (['ATTACHMENT', 'TEMPLATE'].includes(field.type)) {
                    const documentsFromValue =
                        documentDetails.attachments.filter((docAttachment) =>
                            ((field?.value ?? []) as string[]).includes(
                                docAttachment.attachment_id
                            )
                        );

                    const existingAttachments = newPrev[field.name] || [];
                    const uniqueSet = existingAttachments.filter(
                        (att) =>
                            !documentsFromValue.some(
                                (docAtt) =>
                                    docAtt.attachment_id === att.attachment_id
                            )
                    );

                    newPrev[field.name] = [...uniqueSet, ...documentsFromValue];
                }
            };

            const updateSectionFields = (sectionList: any[]) => {
                const section = sectionList.find(
                    (section) => section.name === sectionNameInPayload
                );

                section?.fields?.forEach(updateFieldAttachments);
            };

            updateSectionFields(localCopyOfCustomFieldNeg.section_list);
            updateSectionFields(localCopyOfCustomFieldNogNeg.section_list);

            return newPrev;
        });
    }, [
        documentDetails.attachments,
        localCopyOfCustomFieldNeg.section_list,
        localCopyOfCustomFieldNogNeg.section_list,
        sectionNameInPayload,
    ]);

    useEffect(() => {
        updateDocAttachment();
    }, [updateDocAttachment]);

    const updateCustomField = useCallback(
        async (
            newFieldDetails: IDocumentCustomField,
            isFieldNegotiable: boolean
        ) => {
            let fieldList = isFieldNegotiable
                ? localCopyOfSellerCustomFieldNeg
                : localCopyOfSellerCustomFieldNogNeg;
            const setFieldList = isFieldNegotiable
                ? setLocalCopyOfSellerCustomFieldNonNeg
                : setLocalCopyOfSellerCustomFieldNeg;

            const fieldIndex = fieldList?.section_list[0]?.fields?.findIndex(
                (payloadField) => payloadField.name === newFieldDetails.name
            );

            if (fieldIndex !== -1) {
                setFieldList((prev) => {
                    let newPrev = cloneDeep(prev);

                    newPrev.section_list[0].fields[fieldIndex].value =
                        newFieldDetails.value;

                    if (isFieldNegotiable) {
                        updateDocumentDetails(
                            'custom_fields_negotiate',
                            newPrev
                        );
                    } else {
                        updateDocumentDetails('custom_fields', newPrev);
                    }

                    return newPrev;
                });
            }
        },
        [
            localCopyOfSellerCustomFieldNeg,
            localCopyOfSellerCustomFieldNogNeg,
            updateDocumentDetails,
        ]
    );

    // const handleAttachmentUpload = useCallback(
    //     (files: IAttachment[], isFieldNegotiable) => {
    //         const attachmentIds = files.map((file) => file.attachment_id);
    //         setDocAttachments((prev) => {
    //             let newPrev = cloneDeep(prev);

    //             newPrev.push(...files);

    //             const allAttachmentIds = newPrev.map(
    //                 (file) => file.attachment_id
    //             );

    //             if (isFieldNegotiable) return newPrev;
    //         });
    //     },
    //     []
    // );

    const handleDownloadFile = async (attachmentId: string) => {
        try {
            const downloadUrl = await downloadFile(attachmentId);
            window.open(downloadUrl, '_self');
        } catch (error) {
            toast.error('Failed to download file');
        }
    };

    const returnFileId = (file: IAttachment | IBulkImport) => {
        return (file as IAttachment).attachment_id;
    };

    const checkIfValueIfUrl = (value: string) => {
        const newLocal = `((http|https)://)(www.)?[a-zA-Z0-9@:%._\\+~#?&//=]{2,256}\\.[a-z]{2,6}\\b([-a-zA-Z0-9@:%._\\+~#?&//=]*)`;
        const regex = new RegExp(newLocal);

        if (!(!isEmpty(value?.trim()) && regex.test(value?.trim() ?? ''))) {
            return false;
        }

        return true;
    };

    return (
        <>
            {templateCF?.map((field) => {
                const fieldInfoFromTemplate =
                    templateDetails.fieldSettings.customFields[field.field];

                const isFieldNegotiable =
                    fieldInfoFromTemplate.sellerSettings.negotiable;

                const fieldList = isFieldNegotiable
                    ? localCopyOfCustomFieldNeg
                    : localCopyOfCustomFieldNogNeg;

                const fieldInfoInDocumentDetails =
                    fieldList?.section_list[0].fields.find(
                        (payloadField) =>
                            payloadField.name === fieldInfoFromTemplate.label
                    );

                if (!Boolean(fieldInfoInDocumentDetails)) {
                    return <></>;
                }

                switch (fieldInfoFromTemplate.constraints.fieldType) {
                    case 'SHORTTEXT':
                        return fieldInfoInDocumentDetails?.value ? (
                            <Grid item xs={gridSize}>
                                <Grid
                                    sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        gap: '0.5rem',
                                    }}
                                >
                                    <FWTypography color={'text.secondary'}>
                                        {fieldInfoFromTemplate?.label}{' '}
                                    </FWTypography>
                                    {fieldInfoFromTemplate?.description && (
                                        <FWTooltip
                                            title={
                                                fieldInfoFromTemplate?.description
                                            }
                                            placement="right"
                                        >
                                            <Box color="primary.main">
                                                <i className="bi bi-question-circle" />
                                            </Box>
                                        </FWTooltip>
                                    )}
                                </Grid>
                                <FWTypography>
                                    {fieldInfoInDocumentDetails?.value
                                        ? fieldInfoInDocumentDetails?.value
                                        : '-'}
                                </FWTypography>
                            </Grid>
                        ) : (
                            <></>
                        );
                    case 'DATE':
                    case 'DATETIME':
                        return fieldInfoInDocumentDetails?.value ? (
                            <Grid item xs={gridSize}>
                                <Grid
                                    sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        gap: '0.5rem',
                                    }}
                                >
                                    <FWTypography color={'text.secondary'}>
                                        {fieldInfoFromTemplate?.label}{' '}
                                    </FWTypography>
                                    {fieldInfoFromTemplate?.description && (
                                        <FWTooltip
                                            title={
                                                fieldInfoFromTemplate?.description
                                            }
                                            placement="right"
                                        >
                                            <Box color="primary.main">
                                                <i className="bi bi-question-circle" />
                                            </Box>
                                        </FWTooltip>
                                    )}
                                </Grid>
                                <FWTypography>
                                    {fieldInfoInDocumentDetails?.value
                                        ? getFWDateTime(
                                              (fieldInfoInDocumentDetails?.value ??
                                                  '') as string
                                          )
                                        : '-'}
                                </FWTypography>
                            </Grid>
                        ) : (
                            <></>
                        );
                    case 'FLOAT':
                        return fieldInfoInDocumentDetails?.value ? (
                            <Grid item xs={gridSize}>
                                <Grid
                                    sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        gap: '0.5rem',
                                    }}
                                >
                                    <FWTypography color={'text.secondary'}>
                                        {fieldInfoFromTemplate?.label}{' '}
                                    </FWTypography>
                                    {fieldInfoFromTemplate?.description && (
                                        <FWTooltip
                                            title={
                                                fieldInfoFromTemplate?.description
                                            }
                                            placement="right"
                                        >
                                            <Box color="primary.main">
                                                <i className="bi bi-question-circle" />
                                            </Box>
                                        </FWTooltip>
                                    )}
                                </Grid>
                                <FWTypography>
                                    {fieldInfoInDocumentDetails?.value
                                        ? fieldInfoInDocumentDetails?.value
                                        : '-'}
                                </FWTypography>
                            </Grid>
                        ) : (
                            <></>
                        );
                    case 'INTEGER':
                        return fieldInfoInDocumentDetails?.value ? (
                            <Grid item xs={gridSize}>
                                <Grid
                                    sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        gap: '0.5rem',
                                    }}
                                >
                                    <FWTypography color={'text.secondary'}>
                                        {fieldInfoFromTemplate?.label}{' '}
                                    </FWTypography>
                                    {fieldInfoFromTemplate?.description && (
                                        <FWTooltip
                                            title={
                                                fieldInfoFromTemplate?.description
                                            }
                                            placement="right"
                                        >
                                            <Box color="primary.main">
                                                <i className="bi bi-question-circle" />
                                            </Box>
                                        </FWTooltip>
                                    )}
                                </Grid>
                                <FWTypography>
                                    {fieldInfoInDocumentDetails?.value
                                        ? fieldInfoInDocumentDetails?.value
                                        : '-'}
                                </FWTypography>
                            </Grid>
                        ) : (
                            <></>
                        );
                    case 'BOOLEAN':
                        return fieldInfoInDocumentDetails?.value?.toString() ? (
                            <Grid item xs={gridSize}>
                                <Grid
                                    sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        gap: '0.5rem',
                                    }}
                                >
                                    <FWTypography color={'text.secondary'}>
                                        {fieldInfoFromTemplate?.label}{' '}
                                    </FWTypography>
                                    {fieldInfoFromTemplate?.description && (
                                        <FWTooltip
                                            title={
                                                fieldInfoFromTemplate?.description
                                            }
                                            placement="right"
                                        >
                                            <Box color="primary.main">
                                                <i className="bi bi-question-circle" />
                                            </Box>
                                        </FWTooltip>
                                    )}
                                </Grid>
                                <FWTypography>
                                    {fieldInfoInDocumentDetails?.value?.toString()
                                        ? fieldInfoInDocumentDetails?.value
                                            ? 'yes'
                                            : 'no'
                                        : '-'}
                                </FWTypography>
                            </Grid>
                        ) : (
                            <></>
                        );
                    case 'CHOICE':
                        if (
                            fieldInfoFromTemplate?.constraints.choiceType ===
                            'DROPDOWN'
                        ) {
                            return Boolean(
                                fieldInfoInDocumentDetails?.value
                            ) ? (
                                <Grid item xs={gridSize}>
                                    <Grid
                                        sx={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            gap: '0.5rem',
                                        }}
                                    >
                                        <FWTypography color={'text.secondary'}>
                                            {fieldInfoFromTemplate?.label}{' '}
                                        </FWTypography>
                                        {fieldInfoFromTemplate?.description && (
                                            <FWTooltip
                                                title={
                                                    fieldInfoFromTemplate?.description
                                                }
                                                placement="right"
                                            >
                                                <Box color="primary.main">
                                                    <i className="bi bi-question-circle" />
                                                </Box>
                                            </FWTooltip>
                                        )}
                                    </Grid>
                                    <FWTypography>
                                        {Boolean(
                                            fieldInfoInDocumentDetails?.value
                                        )
                                            ? fieldInfoInDocumentDetails?.value
                                            : '-'}
                                    </FWTypography>
                                </Grid>
                            ) : (
                                <></>
                            );
                        } else {
                            return (
                                fieldInfoInDocumentDetails?.value as string[]
                            )?.length > 0 ? (
                                <Grid item xs={gridSize}>
                                    <Grid
                                        sx={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            gap: '0.5rem',
                                        }}
                                    >
                                        <FWTypography color={'text.secondary'}>
                                            {fieldInfoFromTemplate?.label}{' '}
                                        </FWTypography>
                                        {fieldInfoFromTemplate?.description && (
                                            <FWTooltip
                                                title={
                                                    fieldInfoFromTemplate?.description
                                                }
                                                placement="right"
                                            >
                                                <Box color="primary.main">
                                                    <i className="bi bi-question-circle" />
                                                </Box>
                                            </FWTooltip>
                                        )}
                                    </Grid>

                                    <FWTypography>
                                        {(
                                            fieldInfoInDocumentDetails?.value as string[]
                                        )?.length > 0
                                            ? (
                                                  fieldInfoInDocumentDetails?.value as string[]
                                              )?.map((val) => (
                                                  <span
                                                      style={{
                                                          marginRight: '5px',
                                                      }}
                                                  >
                                                      {val};
                                                  </span>
                                              ))
                                            : '-'}
                                    </FWTypography>
                                </Grid>
                            ) : (
                                <></>
                            );
                        }
                    default:
                        return <></>;
                }
            })}

            {templateCF?.sort(customSort)?.map((field) => {
                const fieldInfoFromTemplate =
                    templateDetails.fieldSettings.customFields[field.field];

                const isFieldNegotiable =
                    fieldInfoFromTemplate.sellerSettings.negotiable;

                const fieldList = isFieldNegotiable
                    ? localCopyOfCustomFieldNeg
                    : localCopyOfCustomFieldNogNeg;

                const fieldInfoInDocumentDetails =
                    fieldList?.section_list[0].fields.find(
                        (payloadField) =>
                            payloadField.name === fieldInfoFromTemplate.label
                    );

                if (!Boolean(fieldInfoInDocumentDetails)) {
                    return <></>;
                }

                const fieldError = isFieldNegotiable
                    ? errors.custom_fields_negotiate &&
                      errors.custom_fields_negotiate[
                          fieldInfoFromTemplate.label
                      ]
                    : errors.custom_fields &&
                      errors.custom_fields[fieldInfoFromTemplate.label];

                switch (fieldInfoFromTemplate.constraints.fieldType) {
                    case 'TEMPLATE':
                        return docAttachments[fieldInfoFromTemplate?.label]
                            ?.length > 0 ? (
                            <Grid item xs={11.6}>
                                <Grid
                                    sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        gap: '0.5rem',
                                    }}
                                >
                                    <FWTypography
                                        color={'text.secondary'}
                                        fontSize={24}
                                    >
                                        {fieldInfoFromTemplate?.label}{' '}
                                    </FWTypography>
                                    {fieldInfoFromTemplate?.description && (
                                        <FWTooltip
                                            title={
                                                fieldInfoFromTemplate?.description
                                            }
                                            placement="right"
                                        >
                                            <Box color="primary.main">
                                                <i className="bi bi-question-circle" />
                                            </Box>
                                        </FWTooltip>
                                    )}
                                </Grid>
                                <ViewTemplateList
                                    attachments={
                                        docAttachments[
                                            fieldInfoFromTemplate?.label
                                        ] ?? []
                                    }
                                    gridSize={2.5}
                                    fieldError={fieldError}
                                    updateCustomField={updateCustomField}
                                    fieldInfoFromTemplate={
                                        fieldInfoFromTemplate
                                    }
                                    sellerDocument={sellerDocument}
                                    sellerDocumentDetails={
                                        sellerDocumentDetails
                                    }
                                    isFieldNegotiable={isFieldNegotiable}
                                    fieldInfoInDocumentDetails={
                                        fieldInfoInDocumentDetails
                                    }
                                />
                            </Grid>
                        ) : (
                            <></>
                        );

                    case 'ATTACHMENT':
                        return docAttachments[fieldInfoFromTemplate?.label]
                            ?.length > 0 ? (
                            <Grid item xs={11.6}>
                                <Grid
                                    sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        gap: '0.5rem',
                                    }}
                                >
                                    <FWTypography color={'text.secondary'}>
                                        {fieldInfoFromTemplate?.label}{' '}
                                    </FWTypography>
                                    {/* {docAttachments[
                                        fieldInfoFromTemplate?.label
                                    ]?.length > 0 && (
                                        <FWTooltip
                                            title={'Download all attachments'}
                                        >
                                            <Box color="primary.main">
                                                <IconButton
                                                    onClick={() => {
                                                        bulkDownloadFiles(
                                                            docAttachments[
                                                                fieldInfoFromTemplate
                                                                    ?.label
                                                            ]?.map(
                                                                (attachment) =>
                                                                    attachment.attachment_id
                                                            )
                                                        );
                                                    }}
                                                >
                                                    <i className="bi bi-download" />
                                                </IconButton>
                                            </Box>
                                        </FWTooltip>
                                    )} */}
                                    {fieldInfoFromTemplate?.description && (
                                        <FWTooltip
                                            title={
                                                fieldInfoFromTemplate?.description
                                            }
                                            placement="right"
                                        >
                                            <Box color="primary.main">
                                                <i className="bi bi-question-circle" />
                                            </Box>
                                        </FWTooltip>
                                    )}
                                </Grid>

                                <Grid container>
                                    <AttachmentRender
                                        attachments={
                                            docAttachments[
                                                fieldInfoFromTemplate?.label
                                            ] ?? []
                                        }
                                        handleFileDownload={(
                                            attachment: IAttachment
                                        ) => {
                                            handleDownloadFile(
                                                returnFileId(attachment)
                                            );
                                        }}
                                        gridSize={5.5}
                                    />
                                </Grid>
                            </Grid>
                        ) : (
                            <></>
                        );

                    case 'LONGTEXT':
                        return fieldInfoInDocumentDetails?.value ? (
                            <Grid item xs={11}>
                                <Grid
                                    sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        gap: '0.5rem',
                                    }}
                                >
                                    <FWTypography color={'text.secondary'}>
                                        {fieldInfoFromTemplate?.label}{' '}
                                    </FWTypography>
                                    {fieldInfoFromTemplate?.description && (
                                        <FWTooltip
                                            title={
                                                fieldInfoFromTemplate?.description
                                            }
                                            placement="right"
                                        >
                                            <Box color="primary.main">
                                                <i className="bi bi-question-circle" />
                                            </Box>
                                        </FWTooltip>
                                    )}
                                </Grid>
                                {Boolean(fieldInfoInDocumentDetails?.value) &&
                                checkIfValueIfUrl(
                                    fieldInfoInDocumentDetails?.value as string
                                ) ? (
                                    <a
                                        href={
                                            (fieldInfoInDocumentDetails?.value as string) ??
                                            ''
                                        }
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        style={{ textDecoration: 'none' }}
                                    >
                                        <Typography
                                            color={'primary'}
                                            sx={{
                                                textDecoration: 'none',
                                                textOverflow: 'ellipsis',
                                                overflow: 'hidden',
                                            }}
                                        >
                                            {
                                                fieldInfoInDocumentDetails?.value as string
                                            }
                                        </Typography>
                                    </a>
                                ) : (
                                    <FWTypography>
                                        {fieldInfoInDocumentDetails?.value
                                            ? fieldInfoInDocumentDetails?.value
                                            : '-'}
                                    </FWTypography>
                                )}
                            </Grid>
                        ) : (
                            <></>
                        );

                    default:
                        return <></>;
                }
            })}
        </>
    );
};

export default SellerDocumentCustomFieldViewMode;

export interface IViewTemplateListProp {
    attachments: IAttachment[];
    updateCustomField: (
        newFieldDetails: IDocumentCustomField,
        isFieldNegotiable: boolean
    ) => Promise<void>;
    fieldError: CreateDocumentErrorDesc | undefined;
    fieldInfoFromTemplate: ITemplateCustomFieldOptions;
    fieldInfoInDocumentDetails: IDocumentCustomField | undefined;
    isFieldNegotiable: boolean;
    gridSize: number;
    sellerDocument: INewDocument;
    sellerDocumentDetails: IDocumentDetails;
    allowTemplateUpload?: boolean;
    customGridSizeForTemp?: number;
    allowFileUpload?: boolean;
    showRemoveButton?: boolean;
}

export const ViewTemplateList = ({
    attachments,
    updateCustomField,
    fieldError,
    fieldInfoFromTemplate,
    fieldInfoInDocumentDetails,
    isFieldNegotiable,
    gridSize,
    sellerDocument,
    sellerDocumentDetails,
    allowTemplateUpload = true,
    customGridSizeForTemp,
    allowFileUpload = true,
    showRemoveButton = true,
}: IViewTemplateListProp) => {
    const handleDownloadFile = async (attachmentId: string) => {
        try {
            const downloadUrl = await downloadFile(attachmentId);
            window.open(downloadUrl, '_self');
        } catch (error) {
            toast.error('Failed to download file');
        }
    };

    const returnFileId = (file: IAttachment | IBulkImport) => {
        return (file as IAttachment).attachment_id;
    };

    const [docAttachments, setDocAttachments] = useState<{
        [customFieldName: string]: IAttachment[];
    }>({});

    const getAttachmentsFromField = useCallback(
        (
            fieldName: string,
            sectionName: string,
            isFieldNegotiable: boolean
        ) => {
            let attachmentList: IAttachment[] = [];

            if (isFieldNegotiable) {
                const attachmentIds =
                    (sellerDocument.custom_fields_negotiate.section_list
                        .find((section) => section.name === sectionName)
                        ?.fields?.find((field) => field.name === fieldName)
                        ?.value as string[]) ?? [];

                attachmentList = sellerDocumentDetails.attachments.filter(
                    (attachment) =>
                        attachmentIds.some(
                            (id) => id === attachment.attachment_id
                        )
                );
            } else {
                const attachmentIds =
                    (sellerDocument.custom_fields.section_list
                        .find((section) => section.name === sectionName)
                        ?.fields?.find((field) => field.name === fieldName)
                        ?.value as string[]) ?? [];

                attachmentList = sellerDocumentDetails.attachments.filter(
                    (attachment) =>
                        attachmentIds.some(
                            (id) => id === attachment.attachment_id
                        )
                );
            }

            return attachmentList;
        },
        [
            sellerDocument.custom_fields.section_list,
            sellerDocument.custom_fields_negotiate.section_list,
            sellerDocumentDetails.attachments,
        ]
    );

    useEffect(() => {
        setDocAttachments((prev) => {
            let newPrev: {
                [customFieldName: string]: IAttachment[];
            } = prev;

            sellerDocument.custom_fields.section_list.forEach((section) => {
                section.fields.forEach((field) => {
                    if (field.type === 'TEMPLATE') {
                        if (newPrev[field.name]) {
                            newPrev[field.name].push(
                                ...getAttachmentsFromField(
                                    field.name,
                                    section.name,
                                    false
                                )
                            );
                            let finalValue: IAttachment[] = [];

                            newPrev[field.name].forEach((attachment) => {
                                if (
                                    !finalValue.some(
                                        (at) =>
                                            at.attachment_id ===
                                            attachment.attachment_id
                                    )
                                ) {
                                    finalValue.push(attachment);
                                }
                            });

                            newPrev[field.name] = finalValue;
                        } else {
                            newPrev[field.name] = [
                                ...getAttachmentsFromField(
                                    field.name,
                                    section.name,
                                    false
                                ),
                            ];
                            let finalValue: IAttachment[] = [];

                            newPrev[field.name].forEach((attachment) => {
                                if (
                                    !finalValue.some(
                                        (at) =>
                                            at.attachment_id ===
                                            attachment.attachment_id
                                    )
                                ) {
                                    finalValue.push(attachment);
                                }
                            });

                            newPrev[field.name] = finalValue;
                        }
                    }
                });
            });
            sellerDocument.custom_fields_negotiate.section_list.forEach(
                (section) => {
                    section.fields.forEach((field) => {
                        if (field.type === 'TEMPLATE') {
                            if (newPrev[field.name]) {
                                newPrev[field.name].push(
                                    ...getAttachmentsFromField(
                                        field.name,
                                        section.name,
                                        true
                                    )
                                );
                                let finalValue: IAttachment[] = [];

                                newPrev[field.name].forEach((attachment) => {
                                    if (
                                        !finalValue.some(
                                            (at) =>
                                                at.attachment_id ===
                                                attachment.attachment_id
                                        )
                                    ) {
                                        finalValue.push(attachment);
                                    }
                                });

                                newPrev[field.name] = finalValue;
                            } else {
                                newPrev[field.name] = [
                                    ...getAttachmentsFromField(
                                        field.name,
                                        section.name,
                                        true
                                    ),
                                ];
                                let finalValue: IAttachment[] = [];

                                newPrev[field.name].forEach((attachment) => {
                                    if (
                                        !finalValue.some(
                                            (at) =>
                                                at.attachment_id ===
                                                attachment.attachment_id
                                        )
                                    ) {
                                        finalValue.push(attachment);
                                    }
                                });

                                newPrev[field.name] = finalValue;
                            }
                        }
                    });
                }
            );

            return newPrev;
        });
    }, [getAttachmentsFromField, sellerDocument]);

    const getFileNameAndFileType = (fileNameAndType: string) => {
        const nameArr = fileNameAndType.split('.');

        const fileType = nameArr[nameArr?.length - 1]?.toUpperCase();
        const fileName = nameArr.slice(0, nameArr?.length - 1).join('.');

        const fileIcon = ['JPG', 'JPEG', 'PNG', 'WEBP', 'SVG'].includes(
            fileType.toUpperCase()
        )
            ? 'bi bi-file-earmark-image'
            : ['XLSV', 'XLSX'].includes(fileType.toUpperCase())
            ? 'bi bi-filetype-xlsx'
            : ['CSV'].includes(fileType.toUpperCase())
            ? 'bi bi-filetype-csv'
            : fileType === 'PDF'
            ? 'bi bi-filetype-pdf'
            : ['DOCX', 'DOC'].includes(fileType)
            ? 'bi bi-filetype-doc'
            : fileType === 'ZIP'
            ? 'bi bi-file-earmark-zip'
            : `bi bi-file-earmark-text`;

        return { fileName, fileType, fileIcon };
    };

    // 6.25;

    const showDownloadHighlight = useMemo(() => {
        return (
            (docAttachments[fieldInfoFromTemplate.label]?.length === 0 ||
                docAttachments[fieldInfoFromTemplate.label] === undefined) &&
            [DocumentStateEnum.DRAFT, DocumentStateEnum.REVISING].includes(
                sellerDocumentDetails.state
            )
        );
    }, [
        docAttachments,
        fieldInfoFromTemplate.label,
        sellerDocumentDetails.state,
    ]);

    return (
        <Grid container gap={2} rowGap={1}>
            {attachments?.map((template, index) => {
                return (
                    <Grid
                        item
                        xs={
                            customGridSizeForTemp
                                ? customGridSizeForTemp
                                : gridSize
                        }
                        // xs={8.5}
                        key={index}
                        sx={{
                            // background: 'white',
                            // border: '1px solid #C4C4C8',
                            // borderRadius: '16px',
                            // padding: '0.5rem',
                            display: 'flex',
                            lineHeight: '1.5rem',
                            alignItems: 'center',
                            gap: '20px',
                        }}
                        position={'relative'}
                    >
                        <Grid
                            container
                            className={
                                showDownloadHighlight ? 'pulse-primary' : ''
                            }
                            sx={{
                                // gap: '10px',
                                // #C4C4C8
                                border: '1px solid rgba(0, 122, 255,1)',
                                borderRadius: '24px',
                                padding: '12px',
                                display: 'flex',
                                lineHeight: '1.5rem',
                                alignItems: 'center',
                            }}
                        >
                            <Grid
                                item
                                xs={'auto'}
                                sx={{
                                    display: 'flex',
                                    alignItems: 'flex-end',
                                    justifyContent: 'space-between',
                                }}
                            >
                                <FWIcon
                                    size={22}
                                    name={
                                        getFileNameAndFileType(
                                            template.file_name
                                        ).fileIcon
                                    }
                                />
                            </Grid>
                            <Grid item xs={5.3}>
                                <FWTypography fontSize={16}>
                                    {
                                        getFileNameAndFileType(
                                            template.file_name
                                        ).fileName
                                    }
                                    .
                                    {getFileNameAndFileType(
                                        template.file_name
                                    ).fileType.toLowerCase()}
                                </FWTypography>
                            </Grid>
                            <Grid item xs={'auto'} marginLeft={'auto'}>
                                <Grid container>
                                    <Grid
                                        item
                                        xs={'auto'}
                                        sx={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            paddingRight: 2,
                                        }}
                                    >
                                        <Grid item xs={'auto'} marginX={'2px'}>
                                            <FWTooltip title="Download template">
                                                <Box>
                                                    <Typography
                                                        onClick={() =>
                                                            handleDownloadFile(
                                                                returnFileId(
                                                                    template
                                                                )
                                                            )
                                                        }
                                                        sx={{
                                                            '&:hover': {
                                                                cursor: 'pointer',
                                                            },
                                                        }}
                                                        color={'primary'}
                                                    >
                                                        Download template
                                                    </Typography>
                                                </Box>
                                            </FWTooltip>
                                        </Grid>
                                    </Grid>
                                    {allowTemplateUpload && (
                                        <Divider
                                            orientation="vertical"
                                            flexItem
                                        />
                                    )}
                                    {allowTemplateUpload && (
                                        <Grid
                                            item
                                            xs={'auto'}
                                            sx={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'center',
                                                paddingLeft: 2,
                                            }}
                                        >
                                            <Box
                                                sx={{
                                                    display: 'flex',
                                                    justifyContent: 'flex-end',
                                                    alignItems: 'center',
                                                }}
                                            >
                                                <EditAttachmentsButtonAndPopover
                                                    attachments={
                                                        docAttachments[
                                                            fieldInfoFromTemplate
                                                                .label
                                                        ] ?? []
                                                    }
                                                    showAddFileToolip={false}
                                                    allowFileUpload={
                                                        allowFileUpload
                                                    }
                                                    showRemoveButton={
                                                        showRemoveButton
                                                    }
                                                    resourceType={
                                                        IFileResourceType.DOCUMENT_TEMPLATE
                                                    }
                                                    onFileUpload={(
                                                        files: IAttachment[]
                                                    ) => {
                                                        setDocAttachments(
                                                            (prev) => {
                                                                let newPrev =
                                                                    cloneDeep(
                                                                        prev
                                                                    );

                                                                if (
                                                                    newPrev[
                                                                        fieldInfoFromTemplate
                                                                            .label
                                                                    ]
                                                                ) {
                                                                    newPrev[
                                                                        fieldInfoFromTemplate
                                                                            .label
                                                                    ].push(
                                                                        ...files
                                                                    );
                                                                } else {
                                                                    newPrev[
                                                                        fieldInfoFromTemplate.label
                                                                    ] = [
                                                                        ...files,
                                                                    ];
                                                                }

                                                                if (
                                                                    fieldInfoInDocumentDetails
                                                                ) {
                                                                    let newFieldDetails =
                                                                        cloneDeep(
                                                                            fieldInfoInDocumentDetails
                                                                        );

                                                                    newFieldDetails.value =
                                                                        newPrev[
                                                                            newFieldDetails
                                                                                .name
                                                                        ].map(
                                                                            (
                                                                                attachment
                                                                            ) =>
                                                                                attachment.attachment_id
                                                                        );

                                                                    updateCustomField(
                                                                        newFieldDetails,
                                                                        isFieldNegotiable
                                                                    );
                                                                }

                                                                return newPrev;
                                                            }
                                                        );
                                                    }}
                                                    CustomLoaderForFileUpload={() => (
                                                        <Grid>
                                                            <CircularProgress
                                                                sx={{
                                                                    fontSize:
                                                                        '16px',
                                                                    color: 'black',
                                                                }}
                                                                size={16}
                                                            />
                                                        </Grid>
                                                    )}
                                                    removeAttachment={async (
                                                        idx: number
                                                    ) => {
                                                        setDocAttachments(
                                                            (prev) => {
                                                                let newPrev =
                                                                    cloneDeep(
                                                                        prev
                                                                    );

                                                                if (
                                                                    newPrev[
                                                                        fieldInfoFromTemplate
                                                                            .label
                                                                    ]
                                                                ) {
                                                                    newPrev[
                                                                        fieldInfoFromTemplate
                                                                            .label
                                                                    ].splice(
                                                                        idx,
                                                                        1
                                                                    );
                                                                    if (
                                                                        fieldInfoInDocumentDetails
                                                                    ) {
                                                                        let newFieldDetails =
                                                                            cloneDeep(
                                                                                fieldInfoInDocumentDetails
                                                                            );

                                                                        newFieldDetails.value =
                                                                            (
                                                                                newPrev[
                                                                                    fieldInfoFromTemplate
                                                                                        .label
                                                                                ] ??
                                                                                []
                                                                            )?.map(
                                                                                (
                                                                                    attachment
                                                                                ) =>
                                                                                    attachment.attachment_id
                                                                            );

                                                                        updateCustomField(
                                                                            newFieldDetails,
                                                                            isFieldNegotiable
                                                                        );
                                                                    }
                                                                }

                                                                return newPrev;
                                                            }
                                                        );
                                                    }}
                                                    CustomComponentForFileUploadIcon={() => (
                                                        <>
                                                            <Grid
                                                                sx={{
                                                                    // border: '1px solid #C4C4C8',
                                                                    // borderRadius:
                                                                    //     '16px',
                                                                    // paddingX: 2,
                                                                    // paddingY: 1,
                                                                    color: 'primary.main',
                                                                    lineHeight:
                                                                        '1.5rem',
                                                                    '&:hover': {
                                                                        cursor: 'pointer',
                                                                    },
                                                                }}
                                                            >
                                                                <FWTooltip
                                                                    title={
                                                                        'Upload response'
                                                                    }
                                                                >
                                                                    <Badge
                                                                        badgeContent={
                                                                            docAttachments[
                                                                                fieldInfoFromTemplate
                                                                                    .label
                                                                            ]
                                                                                ?.length
                                                                        }
                                                                    >
                                                                        <Box>
                                                                            <Typography
                                                                                color={
                                                                                    'primary'
                                                                                }
                                                                            >
                                                                                Upload
                                                                                response
                                                                            </Typography>
                                                                            {/* <FWIcon
                                                                            name="bi bi-upload"
                                                                            size={
                                                                                16
                                                                            }
                                                                        /> */}
                                                                        </Box>
                                                                    </Badge>
                                                                </FWTooltip>
                                                            </Grid>
                                                        </>
                                                    )}
                                                />
                                            </Box>
                                        </Grid>
                                    )}
                                </Grid>
                            </Grid>
                        </Grid>
                        {/* <Grid item xs={5.25}>
                        </Grid> */}
                        {/* <Grid itemxs={4.5}></Grid> */}
                    </Grid>
                );
            })}
        </Grid>
    );
};

export interface IAttachmentRenderer {
    attachments: IAttachment[];
    gridSize: number;
    handleFileDownload: (attachment: IAttachment) => void;
}

export const AttachmentRender = ({
    attachments,
    gridSize,
    handleFileDownload,
}: IAttachmentRenderer) => {
    const getFileNameAndFileType = (fileNameAndType: string) => {
        const nameArr = fileNameAndType.split('.');

        const fileType = nameArr[nameArr?.length - 1]?.toUpperCase();
        const fileName = nameArr.slice(0, nameArr?.length - 1).join('.');

        const fileIcon = ['JPG', 'JPEG', 'PNG', 'WEBP', 'SVG'].includes(
            fileType.toUpperCase()
        )
            ? 'bi bi-file-earmark-image'
            : ['XLSV', 'XLSX'].includes(fileType.toUpperCase())
            ? 'bi bi-filetype-xlsx'
            : ['CSV'].includes(fileType.toUpperCase())
            ? 'bi bi-filetype-csv'
            : fileType === 'PDF'
            ? 'bi bi-filetype-pdf'
            : ['DOCX', 'DOC'].includes(fileType)
            ? 'bi bi-filetype-doc'
            : fileType === 'ZIP'
            ? 'bi bi-file-earmark-zip'
            : `bi bi-file-earmark-text`;

        return { fileName, fileType, fileIcon };
    };
    return (
        <>
            {attachments?.map((attachment, index) => {
                return (
                    <Grid
                        item
                        // xs={4.3}
                        xs={gridSize}
                        onClick={() => handleFileDownload(attachment)}
                        key={index}
                        sx={{
                            // border: '2px solid rgba(26, 26, 31, 0.3)',
                            borderRadius: '24px',
                            padding: '12px',
                            display: 'flex',
                            backgroundColor: 'rgba(35, 131, 226, 0.03)',
                            color: 'rgb(35, 131, 226)',
                            border: ' 1px solid rgba(35, 131, 226, 0.35)',
                            boxShadow: '0 1px 2px 0 rgb(0 0 0 / 0.05)',
                            lineHeight: '1.5rem',
                            alignItems: 'center',
                            marginRight: '10px',
                            gap: '10px',
                        }}
                    >
                        <Box sx={{ display: 'flex', gap: '4px' }}>
                            <FWIcon
                                size={22}
                                name={
                                    getFileNameAndFileType(attachment.file_name)
                                        .fileIcon
                                }
                            />
                        </Box>
                        <Grid item xs={11}>
                            <FWTypography fontSize={16}>
                                {
                                    getFileNameAndFileType(attachment.file_name)
                                        .fileName
                                }
                                .
                                {getFileNameAndFileType(
                                    attachment.file_name
                                ).fileType.toLowerCase()}
                            </FWTypography>
                        </Grid>
                    </Grid>
                );
            })}
        </>
    );
};
