import { Box, CircularProgress, Grid } from '@mui/material';
import { isEqual } from 'lodash';
import { useMemo, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { FWButton } from '../../Common/FWButton';
import { FWTooltip } from '../../Common/FWCustomTooltip';
import { FWPopup } from '../../Common/FWPopup';
import { HookStateValue } from '../../Common/Hooks/StateHook';
import LoadingBackDrop from '../../Components/Shared/LoadingBackdrop';
import TitleBar from '../../Components/Shared/TitleBar';
import { useDraftDocument } from '../../SellerProfile/Hooks/useDraftDocument';
import DraftDocumentDetails from '../Components/DraftDocumentDetails';
import { DocumentStateEnum } from '../Constants/Document.model';
import DocumentHeaderMenu from '../Containers/DocumentHeaderMenu';

export const STANDARD_GRID_SIZE = 2.31;
export const STANDARD_LONGFIELD_GRID_SIZE = 12;
export const STANDARD_GRID_COL_GAP = 2;
export const STANDARD_GRID_ROW_GAP = 2;
export const STANDARD_LOGO_BANNER_FILE_TYPES = [
    '.JPG',
    '.JPEG',
    '.PNG',
    '.SVG',
    '.jpg',
    '.png',
    '.jpeg',
    'jpeg',
    '.svg',
];

export default function DraftDocumentPage() {
    const params: { document_id: string } = useParams();

    const {
        documentDetails,
        documentEssentials,
        documentPayload,
        originalDocumentPayload,
        updateDocumentPayload,
        templateDetails,
        ENTITY_LEVEL_PERMISSION,
        errors,
        deleteDocumentById,
        hookState,
        loadingState,
        submitDocument,
    } = useDraftDocument(params.document_id);

    const [deleteDocumentPopup, setDeleteDocumentPopup] =
        useState<boolean>(false);

    const BUYER_DOCUMENTS_DASHBOARD = '/buyer/srm/documents';

    const history = useHistory();

    const disableContinueButton = useMemo(() => {
        // let disableButton = false;
        let buttonState: {
            disableButton: boolean;
            disableMessage: string;
        } = {
            disableButton: false,
            disableMessage: '',
        };

        if (
            !isEqual(errors, {}) ||
            loadingState.error ||
            !loadingState.saved ||
            (documentDetails.state !== DocumentStateEnum.DRAFT &&
                isEqual(originalDocumentPayload, documentPayload))
        ) {
            buttonState = {
                disableButton: true,
                disableMessage: 'Please fill the mandatory details',
            };
        }

        return buttonState;
    }, [
        documentDetails.state,
        documentPayload,
        errors,
        loadingState.error,
        loadingState.saved,
        originalDocumentPayload,
    ]);

    if (hookState.state === HookStateValue.LOADING) {
        return (
            <>
                <LoadingBackDrop />
            </>
        );
    }

    return (
        <>
            <Grid container>
                <TitleBar
                    title="Review document details"
                    gotoURL={BUYER_DOCUMENTS_DASHBOARD}
                >
                    <DocumentHeaderMenu
                        showMoreOptions={ENTITY_LEVEL_PERMISSION === 'EDIT'}
                        showShareVendors={false}
                        onClickShareVendors={() => {}}
                        showReviseDocument={false}
                        onClickReviseDocument={() => {}}
                        showDelete={ENTITY_LEVEL_PERMISSION === 'EDIT'}
                        onClickDelete={() => {
                            setDeleteDocumentPopup(true);
                        }}
                        documentDetails={documentDetails}
                        updateDocumentDetails={updateDocumentPayload}
                        errors={errors}
                        documentsPayload={documentPayload}
                        loadingState={loadingState}
                        documentEssentials={documentEssentials}
                        templateDetails={templateDetails}
                    />
                </TitleBar>
                <DraftDocumentDetails
                    documentDetails={documentDetails}
                    updateDocumentDetails={updateDocumentPayload}
                    errors={errors}
                    documentsPayload={documentPayload}
                    loadingState={loadingState}
                    documentEssentials={documentEssentials}
                    templateDetails={templateDetails}
                />

                <FWTooltip
                    title={
                        !loadingState.loading
                            ? disableContinueButton.disableButton
                                ? disableContinueButton.disableMessage
                                : ''
                            : ''
                    }
                >
                    <Box marginX={'auto'} marginY={4}>
                        <FWButton
                            variant="contained"
                            color={'primary'}
                            disabled={disableContinueButton.disableButton}
                            onClick={() => {
                                submitDocument();
                            }}
                        >
                            {loadingState.loading ? (
                                <CircularProgress size={20} color="secondary" />
                            ) : (
                                'Continue'
                            )}
                        </FWButton>
                    </Box>
                </FWTooltip>
            </Grid>
            <FWPopup
                open={deleteDocumentPopup}
                setOpen={() => {
                    setDeleteDocumentPopup(false);
                }}
                btnColor="error"
                onClickConfirm={() => {
                    deleteDocumentById(params.document_id).then(() => {
                        setDeleteDocumentPopup(false);
                        history.push(BUYER_DOCUMENTS_DASHBOARD);
                    });
                }}
                title={`Delete Document`}
                msg={`This document will be deleted and you won't be able to use it.`}
            />
        </>
    );
}
